import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as entitleAPI from '../lib/api/entitle';
import * as adminAPI from '../lib/api/admin';
// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import { ALERT } from '../lib/dataWord';
const INITIALIZE = 'docSort/INITIALIZE';
const CHANGE_VALUE = 'docSort/CHANGE_VALUE';
const CHANGE_UNDEFINE = 'docSort/CHANGE_UNDEFINE';
const INIT_TYPE = 'docSort/INIT_TYPE';
const COPY_DOC = 'docSort/COPY_DOC';
const CLEAN = 'docSort/CLEAN';
// const [ACTION, ACTION_SUCCESS, ACTION_FAILURE] = createRequestActionTypes('docSort/ACTION');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('docSort/REACTION');
const [DOC_SORT, DOC_SORT_SUCCESS, DOC_SORT_FAILURE] = createRequestActionTypes('docMgt/DOC_SORT');
const [GET_FILE, GET_FILE_SUCCESS, GET_FILE_FAILURE] = createRequestActionTypes('docMgt/GET_FILE');
const [GET_DOC_TYPE, GET_DOC_TYPE_SUCCESS, GET_DOC_TYPE_FAILURE] = createRequestActionTypes('docSort/GET_DOC_TYPE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({type,target_type,idx}) => ({type,target_type,idx}));
export const undefineValue = createAction(CHANGE_UNDEFINE, ({target_type,idx}) => ({target_type,idx}));
export const initType = createAction(INIT_TYPE);
export const copyDocData = createAction(COPY_DOC);
export const docClean = createAction(CLEAN);
export const fileSort = createAction(DOC_SORT, ({docData, doc_id}) => ({docData, doc_id}))
export const getFile = createAction(GET_FILE, (body) => (body))
export const getDocType = createAction(GET_DOC_TYPE)

// export const action = createAction(ACTION, data => data);

const fileSortSaga = createRequestSaga(DOC_SORT, entitleAPI.file_sort);
const getFileSaga = createRequestSaga(GET_FILE, entitleAPI.enfile_list);
const getDocTypeSaga = createRequestSaga(GET_DOC_TYPE, adminAPI.doc_search2);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* docSortSaga() {
    yield takeLatest(DOC_SORT, fileSortSaga);
    yield takeLatest(GET_FILE, getFileSaga);
    yield takeLatest(GET_DOC_TYPE, getDocTypeSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const initialState = {
    docSort: null,

    cboDoc: [],

    docData: [],

    // 사본
    docData_Copy: [],
    error: null
};

const docSort = handleActions(
    {
        [INITIALIZE]: () => initialState,
        // 기존
        // [CHANGE_VALUE]: (state, { payload: {type, target_type, idx}}) => {
        //     return({
        //         ...state,
        //         docData: {
        //             ...state.docData,
        //             [type]: state.docData[type] + 1,
        //             [target_type]: state.docData[target_type] - 1,
        //             doc: state.docData.doc.map((item,itemIdx) => itemIdx === idx? {...item, file_type: type, file_seq: state.docData[type] + 1} : item)
        //         }
        //     })
        // },
        // [CHANGE_UNDEFINE]: (state, {payload: {target_type, idx}}) => {
        //     return ({
        //         ...state,
        //         docData: {
        //             ...state.docData,
        //             [target_type]: state.docData[target_type] - 1,
        //             DOCUDF: state.docData.DOCUDF + 1,
        //             doc: state.docData.doc.map((item,itemIdx) => itemIdx === idx? {...item, file_type: 'DOCUDF'} : item)
        //         }
        //     })
        // },
        // [INIT_TYPE]: (state) => {
        //     return ({
        //         ...state,
        //         docData: {
        //             DOC000: 0,
        //             DOC001: 0,
        //             DOC002: 0,
        //             DOC003: 0,
        //             DOC004: 0,
        //             DOCUDF: state.docData.DOCUDF + state.docData.DOC000 + state.docData.DOC001 + state.docData.DOC002 + state.docData.DOC003 + state.docData.DOC004,
        //             doc: state.docData.doc.map((item, idx) => ({...item, file_type: 'DOCUDF', file_seq: idx+1}))
        //         }
        //     })
        // }

        [CHANGE_VALUE]: (state, { payload: {type, target_type, idx}}) => {

            // 타겟 찾고 삭제
            let target = null;
            let findTarget = state.docData.map((item1) => {
                return (item1.file_type === target_type? {
                    ...item1,
                    files: item1.files.filter((el, elIdx) => {
                        if(idx === elIdx) {
                            target = el;
                            return false;
                        }
                        else {
                            return true
                        }
                    })
                } : item1)
            })

            // 찾은 타겟을 다시 추가.
            // 에러 : DOC005 가 없는 경우 추가가 안됨. 
            // 이유 : 현재 배열에 DOC005가 없음
            // 해결 : 현재 선택 (type) 유형이 없으면 새로 추가 하는 과정이 필요함. 해결

            if(!findTarget.find((item) => item.file_type === type)) {
                findTarget.push({
                    file_type: type,
                    files: []
                })
            }
            let changed = findTarget.map((item1) => {
                return (item1.file_type === type? {
                    ...item1,
                    files: item1.files.concat(target)
                } : item1)
            })

            return({
                ...state,
                docData: changed,
                // docData_Copy: state.docData_Copy.map((item) => item.doc_id === id? {
                //     ...item,
                //     file_type: type
                // } : item)
            })
        },
        [CHANGE_UNDEFINE]: (state, {payload: {target_type, idx}}) => {
            return ({
                ...state,

            })
        },
        [INIT_TYPE]: (state) => {
            // 타겟 찾고 삭제
            let target = [];
            // let findTarget = state.docData.map((item1) => {
            //     return (item1.file_type !== "DOCUDF"? {
            //         ...item1,
            //         files: item1.files.filter((el, elIdx) => {
            //             target = el;
            //             return true
            //         })
            //     } : item1)
            // })
            let findTarget = state.docData.map((item) => {
                if(item.file_type !== "DOCUDF") {
                    target.push(...item.files)
                    return ({
                        ...item,
                        files: []
                    })
                }
                else {
                    return item;
                }
            })

            if(!findTarget.find((item) => item.file_type === "DOCUDF")) {
                findTarget.push({
                    file_type: "DOCUDF",
                    files: []
                })
            }

            return ({
                ...state,
                docData: findTarget.map((item1) => {
                    return ({
                        ...item1,
                        files: item1.file_type === "DOCUDF"? item1.files.concat(target) : item1.files
                    })
                })
                // docData: state.docData.map((item1) => {
                //     return ({
                //         ...item1,
                //         files: item1.files.map((item2) => {
                //             return 
                //         })
                //     })
                // })
            })
        },
        [COPY_DOC]: (state) => {
            let tempArr = [];
            state.docData.forEach((item1) => {
                item1.files.forEach((item2, idx) => {
                    tempArr.push({
                        file_type: item1.file_type,
                        file_seq: idx+1,
                        ...item2
                    })
                })
            })
            return ({
                ...state,
                docData_Copy: tempArr
            })
        },
        [DOC_SORT_SUCCESS]: (state) => {
            ALERT("현재 분류 상태를 저장했습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [DOC_SORT_FAILURE]: (state, {payload:{error}}) => ({
            ...state,
            error
        }),
        [GET_FILE_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                docData: payload,
                error: null,
            })
        },
        [GET_FILE_FAILURE]: (state, {payload}) => {
            return ({
            ...state,
            error: "error"
        })},
        [CLEAN]: (state) => ({
            ...state,
            docData: []
        }),
        [GET_DOC_TYPE_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                cboDoc: payload,
                error: null,
            })
        },
        [GET_DOC_TYPE_FAILURE]: (state, {payload: error}) => {
            return ({
            ...state,
            error: error
        })},
    },
    initialState,
);

export default docSort;


