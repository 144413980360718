import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EnWriteAgree from '../../components/Modal/EnWriteAgree';
import { ALERT } from '../../lib/dataWord';
import { certChk, certReq, changeRowValue, initAgreeWinner, initialize, saveAgree, sendSms } from '../../modules/Modal/enWriteAgree';

const EnWriteAgreeContainer = (props) => {
    const timeLoop = useRef([]);
    const cnt180 = useRef([]);
    const [remainSeconds, setRemainSeconds] = useState([]);

    const {
        doc_id,
        winner,
        func,
        onCloseUserAgree,
    } = props;
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        user,
    } = useSelector(({ user }) => ({
        user: user.user,
    }));
    const {
        isSendOK,
        agreeWinner,
    } = useSelector(({ enWriteAgree }) => ({
        isSendOK: enWriteAgree.isSendOK,
        agreeWinner: enWriteAgree.agreeWinner,
    }));

    const onRowChange = (item, e) => {
        dispatch(
            changeRowValue({
                phone: item.phone,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onCertReq = (item, idx, e) => {
        //  3분 이내에는 재발급 불가.
        if(remainSeconds[idx] > 0) {
            return;
        }

        //  Step1.  신규로 인증번호 발급한 index의 잔여 시간을 180으로 초기화.-------------------------
        cnt180.current[idx] = 180;

        let startRemain = Object.assign({}, remainSeconds);
        startRemain[idx] = 180;
        setRemainSeconds(startRemain);
        //  ------------------- Step1


        //  Step2.  setInterval 생성.-------------------------
        timeLoop.current[idx] = setInterval(() => {
            cnt180.current[idx] -= 1;

            let _remainSeconds = Object.assign({}, remainSeconds);  //  1. 잔여 시간 배열을 깊은 복사하고
            for(let i = 0; i < cnt180.current.length; i++) {
                if(i === idx) {
                    _remainSeconds[idx] = cnt180.current[idx];      //  2-1. 새로 인증번호 발급을 한 Index의 잔여 시간은 기존 시간에서 1을 뺀 수로 변경
                }
                else {
                    _remainSeconds[i] = cnt180.current[i];          //  2-2. 기존에 타이머가 돌고 있는 Index의 잔여 시간은 useRef의 현재 값으로 다시 변경
                }
            }
            setRemainSeconds(_remainSeconds);

            if(cnt180.current[idx] < 1) {
                clearInterval(timeLoop.current[idx]);
            }
        }, 1000)
        //  ------------------- Step2

        dispatch(certReq({phone: item.phone}));
    }
    const onCertChk = (item, e) => {
        dispatch(certChk({
            phone: item.phone,
            cert_no: item.agree_code,
        }))
    }
    const onAgreeComplete = () => {
        let _receiver = '';
        for(let i = 0; i < agreeWinner.length; i++) {
            const _agreeWinner = agreeWinner[i];
            if(!_agreeWinner.agree_complete) {
                ALERT(_agreeWinner.name + " 님의 휴대전화 인증을 완료해주십시오.");
                return;
            }
            _receiver += _agreeWinner.phone + ",";
        }

        //  모든 당첨자의 인증이 완료되었다면, setInterval을 clear시킨다.
        timeLoop.current.forEach(_timeLoop => {
            clearInterval(_timeLoop);
        });

        dispatch(saveAgree({doc_id: doc_id}));
        dispatch(sendSms({
            sender: "1600-4690",
            receiver: _receiver,
            msg: "전세임대지원 신청이 완료되었습니다.",
            msg_type: "SMS",
        }));
    }
    useEffect(() => {
        if(!agreeWinner || agreeWinner.length === 0) {
            dispatch(initAgreeWinner(winner));

            let _remainSeconds = [];
            winner.forEach(element => {
                timeLoop.current.push(null);
                cnt180.current.push(-1);
                _remainSeconds.push(-1);
            });
            setRemainSeconds(_remainSeconds);
        }
    }, [dispatch, winner, agreeWinner])

    useEffect(() => {
        if(isSendOK) {
            //  지원 신청의 SMS발송이 완료되었다면 리스트 페이지로 이동시킨다.
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0"+(date.getMonth() + 1)).slice(-2);
            const month_m = ("0"+date.getMonth()).slice(-2);

            const listValue = {
                all_search: func.some((el) => el.id==="FUNC061")? "Y" : "N",
                types: ""
            }

            navigate(`/list/apply?from_date=${`${year - 2}-${month_m}-01`}&to_date=${`${year}-${month}-${new Date(year, month, 0).getDate()}`}&type=${''}&status=${listValue.types}&user_type=${'WINNER'}&user=${''}&all_search=${listValue.all_search}&page=${'1'}&feedback_status=${''}&area=${''}`, {
                replace: true,
                state: {
                    func: func,
                    auth: user.user_auth,
                    listValue: listValue,
                    statusArr: [],
                    applyTypeArr: [],
                }
            })
        }
    }, [isSendOK, navigate, func, user])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])
    
    const propDatas = {
        agreeWinner,
        onCertReq,
        onCertChk,
        onCloseUserAgree,

        remainSeconds,
        
        onRowChange,
        onAgreeComplete,
    }
    return (
        <>
            <EnWriteAgree {...propDatas} />
        </>
    );
}

export default EnWriteAgreeContainer;