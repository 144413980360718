import React, { Fragment } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';

const UserMgtBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 30px 50px;
    box-sizing: border-box;

    .searchBar {
        display: flex;
        gap: 10px;
    }
    .searchBar label{
        margin-right: 4px;
    }
    .containerDiv {
        padding-top: 20px;
        display: flex;
        gap: 20px;
        height: 75vh;
    }
    .containerDiv > .userList {
        width: 40%;
        height: 100%;
        padding-bottom: 10px;
        overflow-y: auto;
        border: 1px solid #ccc;
    }
    .containerDiv > .userList table tbody tr:hover {
        background-color: #ccc;
        cursor: pointer;
    }
    .containerDiv > .modifyUser {
        width: 20%;
        height: 100%;
        padding: 0 10px 10px;
        border-radius: 5px;
        border: 1px solid #C5C5C5;
        background-color: #ffffff;
        overflow-y: auto;
    }
    .containerDiv > .setRegion {
        width: 40%;
        height: 100%;
        padding: 0 10px 10px;
        border-radius: 5px;
        border: 1px solid #C5C5C5;
        background-color: #ffffff;
        overflow-y: auto;
    }
    .ipt_item {
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        gap: 3px;
    }
    .ipt_item input[type=text] {
        width: 100%;
    }
    .with_btn {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    .squre-bdradius-btn {
        height: 28px;
    }
    .lblInputFile {
        color: #0B999C;
        padding: 2px 10px;
        border: 1px solid #0B999C;
        border-radius: 5px;
        background-color: #ffffff; 
        cursor: pointer;
    }
    .inputFile {
        display: none;
    }
    .save-btn {
        width: 100%;
        margin: 20px 0;
    }
    .regionList:first-child {
        margin-top: 10px;
    }
    .regionList hr {
        margin: 10px 0;
    }
    .sidoList h4 {
        margin-top: 10px;
    }
    .lblGunguChk {
        width: 70px;
    }
    .ipInput {
        display: flex;
        gap: 10px;
    }
    .ipInput button {
        min-width: 59px;
    }
`;

const UserMgt = (props) => {
    const {
        user,

        src_user_auth,
        src_company,
        src_user_id,
        src_user_name,

        login_date,
        user_id,
        user_pwd,
        user_pwd_check,
        user_phone,
        user_tel,
        user_name,
        user_position,
        // addr_no,
        addr,
        addr_dtl,
        owner,
        card,
        reg_no,
        user_company,
        business,
        deduction,
        ipAddr,
        use_flag,

        users,
        cboAuth,

        region,
        gungus,
        dataRegion,

        isShowDaumPost,
        handlePostCode,

        onChange,
        onFileChange,
        onRowChange,
        onUserSelect,
        onShowDaumPost,
        onAddIpRows,
        onRemoveIpRows,
        onChangeIP,
        onRegionChange,
        onGunguChange,
        onSearchClick,
        onSaveAuthClick,
        onSaveUserClick,
        onSaveRegionClick,
    } = props;

    return (
        <UserMgtBlock>
            <div className='searchBar'>
                <div>
                    <label>권한</label>
                    <select name="src_user_auth" id="src_user_auth" value={src_user_auth} onChange={onChange}>
                        {cboAuth.map((item) => (
                            //  시스템관리자 외에는 관리자 권한을 검색 및 부여 할 수 없다.
                            (
                                item.sub_code !== "ADMIN" ||
                                (
                                    item.sub_code === "ADMIN" &&
                                    user.user_auth === "ADMIN"
                                )
                            ) &&
                            <option value={item.sub_code} key={item.sub_code}>{item.code_name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>소속</label>
                    <input type="text" name="src_company" id="src_company" value={src_company} onChange={onChange} />
                </div>
                <div>
                    <label>사용자ID</label>
                    <input type="text" name="src_user_id" id="src_user_id" value={src_user_id} onChange={onChange} />
                </div>
                <div>
                    <label>사용자명</label>
                    <input type="text" name="src_user_name" id="src_user_name" value={src_user_name} onChange={onChange} />
                </div>
                <button onClick={onSearchClick} className="sch-btn">검색</button>
            </div>

            <div className='containerDiv'>
                <div className='userList'>
                    <table className='border-tt'>
                        <thead>
                            <tr>
                                <th>사용자ID</th>
                                <th>사용자명</th>
                                <th>소속</th>
                                <th>권한</th>
                                <th style={{width:'10%'}}>가입<br/>승인</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((item) => (
                                (
                                    //  접속한 유저가 시스템관리자가 아니라면, 권한이 시스템관리자인 유저는 리스트에서 제외한다.
                                    item.user_auth !== "ADMIN" ||
                                    (
                                        item.user_auth === "ADMIN" &&
                                        user.user_auth === "ADMIN"
                                    )
                                ) &&
                                <tr key={item.user_id} onClick={onUserSelect.bind(this, item)}>
                                    <td>{item.user_id}</td>
                                    <td>{item.user_name}</td>
                                    <td>{item.user_company}</td>
                                    <td>
                                        <select name="user_auth" id="user_auth" value={item.user_auth} onChange={onRowChange.bind(this, item)}>
                                            {cboAuth.map((auth) => (
                                                //  시스템관리자 외에는 관리자 권한을 검색 및 부여 할 수 없다.
                                                (
                                                    auth.sub_code !== "ADMIN" ||
                                                    (
                                                        auth.sub_code === "ADMIN" &&
                                                        user.user_auth === "ADMIN"
                                                    )
                                                ) &&
                                                <option value={auth.sub_code} key={auth.sub_code}>{auth.code_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td><button onClick={() => onSaveAuthClick(item)} className="fixed-bdradius-btn">승인</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='modifyUser'>
                    <form onSubmit={onSaveUserClick}>
                        <div className='ipt_item'>
                            <label htmlFor=''>최종 로그인 일시</label>
                            <input type={"text"} name='login_date' id='login_date' value={login_date} readOnly={true} disabled='disabled' />
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor=''>아이디</label>
                            <input type={"text"} name='user_id' id='user_id' value={user_id} readOnly={true} disabled='disabled' />
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor='user_pwd'>비밀번호</label>
                            <input type={"password"} name='user_pwd' id='user_pwd' value={user_pwd} onChange={onChange} autoComplete="off" />
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor='user_pwd_check'>비밀번호확인</label>
                            <input type={"password"} name='user_pwd_check' id='user_pwd_check' value={user_pwd_check} onChange={onChange} autoComplete="off" />
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor='user_phone'>핸드폰번호</label>
                            <input type={"text"} name='user_phone' id='user_phone' value={user_phone} onChange={onChange}/>
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor='user_tel'>사업장 전화번호</label>
                            <input type={"text"} name='user_tel' id='user_tel' value={user_tel} onChange={onChange}/>
                        </div>
                        
                        <div className='ipt_item'>
                            <label htmlFor='user_name'>성명</label>
                            <input type={"text"} name='user_name' id='user_name' value={user_name} onChange={onChange}/>
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor='user_position'>직책</label>
                            <input type={"text"} name='user_position' id='user_position' value={user_position} onChange={onChange}/>
                        </div>

                        <div className='ipt_item'>
                            <div className='with_btn'>
                                <label htmlFor=''>주소</label>
                                <button type="button" onClick={onShowDaumPost} className="squre-bdradius-btn">주소 찾기</button>
                            </div>
                            <input type={"text"} name='addr' id='addr' value={addr} readOnly={true} placeholder="도로명 주소" />
                            <input type={"text"} name='addr_dtl' id='addr_dtl' value={addr_dtl} placeholder="나머지 주소" onChange={onChange}/>
                        </div>

                        <div className='ipt_item'>
                            <div className='with_btn'>
                                <label htmlFor='card'>명함 업로드</label>
                                <label className='lblInputFile' htmlFor="card">파일 선택</label>
                                <input type="file" name="card" id="card" className='inputFile' accept="image/*" onChange={onFileChange} />
                            </div>
                            <img src={card.file_data} alt=''/>
                        </div>
                        
                        <div className='ipt_item'>
                            <label htmlFor=''>사업자등록번호</label>
                            <input type={"text"} name='reg_no' id='reg_no' value={reg_no} onChange={onChange}/>
                        </div>
                        
                        <div className='ipt_item'>
                            <label htmlFor='user_company'>소속</label>
                            <input type={"text"} name='user_company' id='user_company' value={user_company} onChange={onChange}/>
                        </div>

                        <div className='ipt_item'>
                            <label htmlFor='owner'>대표자</label>
                            <input type={"text"} name='owner' id='owner' value={owner} onChange={onChange}/>
                        </div>
                        
                        <div className='ipt_item'>
                            <div className='with_btn'>
                                <label htmlFor='card'>사업자등록증 업로드</label>
                                <label className='lblInputFile' htmlFor="business">파일 선택</label>
                                <input type="file" name="business" id="business" className='inputFile' accept="image/*" onChange={onFileChange} />
                            </div>
                            <img src={business.file_data} alt=''/>
                        </div>
                        
                        <div className='ipt_item'>
                            <div className='with_btn'>
                                <label htmlFor='card'>공제증서 업로드</label>
                                <label className='lblInputFile' htmlFor="deduction">파일 선택</label>
                                <input type="file" name="deduction" id="deduction" className='inputFile' accept="image/*" onChange={onFileChange} />
                            </div>
                            <img src={deduction.file_data} alt=''/>
                        </div>

                        <div className='ipt_item'>
                            <div className='with_btn'>
                                <label htmlFor=''>접속 허용 IP</label>
                                <button type="button" onClick={onAddIpRows} className="squre-bdradius-btn">추가</button>
                            </div>
                            {ipAddr.map((item) => (
                                <div key={item.idx} className="ipInput">
                                    <input type="text" name='addr' id={'addr' + item.idx} value={item.addr} placeholder="IP 주소 000.000.000.000" onChange={onChangeIP.bind(this, item)} />
                                    <button type="button" onClick={onRemoveIpRows.bind(this, item)} className="squre-bdradius-btn">삭제</button>
                                </div>
                            ))}
                        </div>

                        <div className='ipt_item'>
                            <label>사용여부</label>
                            <select name="use_flag" id="use_flag" value={use_flag} onChange={onChange}>
                                <option value="Y">사용</option>
                                <option value="N">미사용</option>
                            </select>
                        </div>
                        
                        <div className='button'>
                            <button className="save-btn">저장</button>
                        </div>
                    </form>
                </div>

                <div className='setRegion'>
                    {dataRegion.map((regions) => (
                        <div key={regions.idx} className="regionList">
                            <h3>
                                <input type="checkbox" name="region" id={"region" + regions.idx} onChange={onRegionChange.bind(this, regions)} checked={region.indexOf(regions.idx) > -1} />
                                <label htmlFor={"region" + regions.idx}>제 {regions.idx} 권역</label>
                            </h3>

                            <div className="sidoList">
                                {regions.region.map((item, idx) => {
                                    const currSido = item.sido;
                                    const prevSido = (idx === 0 ? null : regions.region[idx - 1].sido);

                                    return (
                                        <Fragment key={regions.idx + idx}>
                                            {currSido !== prevSido ?
                                                (
                                                    <>
                                                        <h4>
                                                            {/* <input type="checkbox" name="sido" id={"sido" + regions.idx + idx} onChange={onSidoClick.bind(this, item, regions.idx)} checked={gungus.indexOf(item.sido) > -1} />
                                                            <label htmlFor={"sido" + regions.idx + idx}>{item.sido}</label> */}
                                                            {item.sido}
                                                        </h4>
                                                    </>
                                                ): null
                                            }
                                            <input type="checkbox" name="gungu" id={"gungu" + regions.idx + idx} onChange={onGunguChange.bind(this, item)} checked={gungus.indexOf(item.gungu) > -1} />
                                            <label htmlFor={"gungu" + regions.idx + idx} className="lblGunguChk">{item.gungu}</label>
                                        </Fragment>
                                    )
                                })}
                            </div>
                            <hr/>
                        </div>
                    ))}
                    <div className='button'>
                        <button onClick={onSaveRegionClick} className="save-btn">저장</button>
                    </div>
                </div>
            </div>

            {isShowDaumPost &&
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={handlePostCode}/>
                    <button className='bdnone-cancel-btn' onClick={onShowDaumPost}>닫기</button>
                </div>
            }
        </UserMgtBlock>
    );
}

export default UserMgt;