import React from 'react';
import styled from 'styled-components';
import TermsModal from './Modal/TermsModal';

const LoginFormBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;

    .bg-gradient{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background: linear-gradient(45deg, #1EBF86 , #0B999C);
    }
    .loginCntr{
        width: 540px;
        padding: 24px 0;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 0.18rem 0.37rem rgba( 0, 8, 20, 0.16);
    }
    .loginHeader{
        display: flex;
        justify-content: space-between;
        margin: 0 20px 10px 30px;
    }
    h2{
        color: #545454;
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 45px;
        box-sizing: border-box;
    }
    .body{
        padding: 0 80px;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #ffffff;
    }
    .body>div:nth-child(1),
    .body>div:nth-child(2){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin: 12px 0;
    }
    label{
        color: #545454;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px; 
    }
    label::before{
        content: "";
        display: inline-block;
        width: 7px;
        height: 48px;
        position: absolute;
        top: 26px;
        left: 0;
        border-radius: 5px;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
    }
    input{
        width: 100%;
        height: 48px;
        color: #000000;
        font-size: 12px;
        padding: 17px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #BDBDBD;
    }
    input::placholder{
        color: #BDBDBD;
    }
    .right{
        display: flex;
        justify-content: end;
        // 아이폰으로 볼때 왼쪽으로 배치되어있음 ios 배치문제확인하기 
        margin-bottom: 34px;
    }
    .login{
        margin: 30px 0 0 0;
        border-radius: 5px;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }
    .login>div{
        width: 100%;
        height: 48px;
        color: #ffffff;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        cursor: pointer;
    }
    .btns{
        display: flex;
        margin-bottom: 25px;
        background-color: rgba(11, 153, 156, 0.1);
    }
    .btns div{
        width: 100%;
        height: 48px;
        color: #0B999C;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
    }
    .re>div{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: red;
        font-weight: 600;
    }

    .red {
        color: red;
    }

    .privacy {
        text-align: center;
    }

    .privacy button{
        color: #BDBDBD;
        border: none;
        background: none;
        cursor: pointer;
    }

    .winnerModal > div:last-child {
        width: 540px;
    }

    .divButtons{
        padding: 0 70px;
        box-sizing: border-box;
    }
    
    .pwdMsg {
        font-size: 13px;
        text-align: center;
    }

    .pwdRed {
        color: red;
    }
    .pwdGreen {
        color: green;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .bg-gradient{
            background: #ffffff;
            align-items: flex-start;
        }
        .loginCntr{
            width: 100%;
            padding: 15px 0;
            box-shadow: none;
        }
        .loginHeader{
            margin: 0 20px 50px 20px;
        }
        h2{
            margin-bottom: 70px;
        }
        .body{
            padding: 0 30px;
            box-sizing: border-box;
        }
        .right{
            margin-bottom: 44px;
        }
        label::before{
            top: 26.5px;
        }
        
        .winnerModal > div:nth-child(2){
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 0 30px;
            overflow: auto;
        }
        .winnerModal .header{
            color: #545454; 
            font-size: 26px; 
            text-align: center; 
            margin: 50px;
        }
        .winnerModal .body{
            margin-bottom: 50px;
        }
        .divButtons{
            padding: 0px;
            box-sizing: border-box;
        }
        .divButtons button{
            margin: 0;
            border-radius: 0;
        }

    }

    .loginType {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loginType > div {
        display: flex;
        gap: 50px;
    }
    .loginType > div > div {
        padding: 200px 100px;
        border: 1px solid black;
        cursor: pointer;
    }
    .loginType > div > div:hover {
        background-color: #ccc;
    }
`;

// const TermsPopup = styled.div`
//     width: 100%;
//     height: 100vh;
//     background-color: rgba(0, 0, 0, 0.3);
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 100;

//     .popupWrap{
//         width: 800px;
//         max-height: 80vh;
//         box-sizing: border-box;
//         padding: 20px;
//         overflow: scroll;
//     }

//     h4{
//         color: #545454;
//         font-size: 26px;
//         text-align: center;
//         margin-bottom: 15px;
//     }
// `;

const LoginForm = (props) => {
    const {
        login,
        setTimeStart,
        min, 
        sec,
        isTempPwd,
        changePwd,
        pwdRef,
        pwdMsgScs,
        pwdMsgErr,
        policyModal,
        errMsg,
        loginType,

        onKeyDownPwd,
        onChangeTempPwd,
        onRegister,
        onRegBroker,
        onLogin,
        onChange,
        onTempNum,
        onKeyDown,
        setPolicyModal,
    } = props;

    return (
        <LoginFormBlock>
            <div className='bg-gradient'>
                <div className='loginCntr'>
                    <div className='loginHeader'>
                        <div>Lhlogo</div>
                        <div>신세기법인사무소</div>
                    </div>
                    <h2>
                    {
                        loginType === 'STAFF' ? '관계자 로그인' :
                        loginType === 'BROKER' ? '중개사 로그인' :
                        '입주자 로그인'
                    }
                    </h2>
                    <div className='body'>
                        <div>
                            <label htmlFor="input_id">
                                {
                                    loginType === 'STAFF' ? '아이디' :
                                    loginType === 'BROKER' ? '아이디' :
                                    '입주자 핸드폰번호'
                                }
                            </label>
                            <input type="text" name="input_id" id="input_id" placeholder='아이디를 입력해주세요.' value={login.input_id} onChange={(e) => onChange(e, 'login')}/>
                        </div>
                        <div>
                            <label htmlFor="input_pw">비밀번호</label>
                            <input type="password" name="input_pw" id="input_pw" placeholder='비밀번호를 입력해주세요.' value={login.input_pw} onChange={(e) => onChange(e, 'login')} onKeyDown={onKeyDown} ref={pwdRef}/>
                        </div>
                        <div className='right'>
                            <button onClick={onTempNum} className="bdradius-btn">임시비밀번호 발급</button>
                        </div>
                        <div>
                            {setTimeStart? <span>임시 비밀번호 남은 시간 <span className={min === 0 && sec <= 30? 'red' : ''}>{min} : {('0'+sec).slice(-2)}</span></span> : null}
                        </div>
                        <div className='login'>
                            <div onClick={onLogin}>로그인</div>
                        </div>
                        <div className='btns'>
                            {loginType === 'STAFF' && <div onClick={onRegister}>관계자 회원가입</div>}
                            {loginType === 'BROKER' && <div onClick={onRegBroker}>중개사 회원가입</div>}
                        </div>
                        <div className='privacy'>
                            <button onClick={()=>setPolicyModal({show: true, type: 'privacy_policy'})}>개인정보처리방침</button>
                        </div>
                        <div className='re'>
                            {errMsg? <div>{errMsg}</div> : null}
                        </div>
                    </div>
                </div>
            </div>
            {isTempPwd? 
            // {false? 
            <div className='winnerModal'>
                <div className='backGround'/>
                <div>
                    <div className='header'>
                        <div />
                        <h2>비밀번호 변경</h2>
                    </div>
                    <div className='body'>
                        <div>
                            <label htmlFor='newPwd'>새로운 비밀번호</label>
                            <input type='password' name='newPwd' id='newPwd' value={changePwd.newPwd} onChange={(e) => onChange(e, 'changePwd')}/>
                        </div>
                        <div>
                            <label htmlFor='newPWd_check'>새로운 비밀번호확인</label>
                            <input type='password' name='newPWd_check' id='newPWd_check' value={changePwd.newPWd_check} onChange={(e) => onChange(e, 'changePwd')} onKeyDown={onKeyDownPwd}/>
                        </div>
                        {pwdMsgErr? <p className='pwdMsg pwdRed'>{pwdMsgErr}</p> : null}
                        {pwdMsgScs? <p className='pwdMsg pwdGreen'>{pwdMsgScs}</p> : null}
                        {!pwdMsgErr && !pwdMsgScs? <p className='pwdMsg'>{'*임시비밀번호 발급후에 비밀번호 변경을 반드시해야합니다.'}</p> : null}
                        {/* <p className={pwdMsgErr? 'pwdMsg pwdRed' : 'pwdMsg'}>{pwdMsgErr? pwdMsgErr : '*임시비밀번호 발급후에 비밀번호 변경을 반드시해야합니다.'}</p> */}
                    </div>
                    <div className='divButtons'>
                        <button className='divB-Cancel'>취소</button>
                        {/* 22.03.22 임의로 넣음 */}
                        <button onClick={onChangeTempPwd} className="divB-Save">변경하기</button>
                    </div>
                </div>
            </div>
            : null}
            {policyModal.show &&
                <TermsModal type={policyModal.type} setIsShowTermsModal={setPolicyModal}/>
            }
        </LoginFormBlock>
    );
}

export default LoginForm;