import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EntitleViewer from '../components/EntitleViewer';
import { getContent, initialize } from '../modules/entitleViewer';

const EntitleViewerContainer = (props) => {
    const {
        docNo,
        selectTxtFolder,
        isChangeApi,
        setIsChangeApi,
    } = props;

    const dispatch = useDispatch();

    const {
        winner,
        write,
        rentWrite,
        work,
        rentWork,
        doc014, //  등기부등본
        doc015, //  건축물관리대장
        doc016, //  주택가격
        doc017, //  신.구주소
        use_address,
    } = useSelector(({ entitleViewer }) => ({
        winner: entitleViewer.winner,
        write: entitleViewer.write,
        rentWrite: entitleViewer.rentWrite,
        work: entitleViewer.work,
        rentWork: entitleViewer.rentWork,
        doc014: entitleViewer.doc014,
        doc015: entitleViewer.doc015,
        doc016: entitleViewer.doc016,
        doc017: entitleViewer.doc017,
        use_address: entitleViewer.use_address,
    }));

    useEffect(() => {
        dispatch(getContent(docNo))
    }, [dispatch, docNo])

    useEffect(() => {
        if(isChangeApi) {
            dispatch(getContent(docNo))
            setIsChangeApi(false);
        }
    }, [dispatch, docNo, isChangeApi, setIsChangeApi])

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        selectTxtFolder,
        winner,
        write,
        rentWrite,
        work,
        rentWork,
        doc014,
        doc015,
        doc016,
        doc017,
        use_address,
    }
    return (
        <>
            <EntitleViewer {...propDatas} />
        </>
    );
}

export default EntitleViewerContainer;