import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';

const INITIALIZE = 'findUser/INITIALIZE';
const CHANGE_VALUE = 'findUser/CHANGE_VALUE';
const [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE] = createRequestActionTypes('codeMgt/GET_USERS');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getUsers = createAction(GET_USERS, (body) => (body));

const getUsersSaga = createRequestSaga(GET_USERS, entitleAPI.winner_search);

export function* findUserSaga() {
    yield takeLatest(GET_USERS, getUsersSaga);
}

const initialState = {
    userName: '',
    userNumber: '',

    users: [],
    isManualAdd: false,

    findUser: null,
    error: null
};

const findUser = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [GET_USERS_SUCCESS]: (state, {payload}) => {
            if(payload.length === 0) {
                if(window.confirm('검색 결과가 없습니다.\n입력하신 [이름, 생년월일]로 지원신청서를 작성하시겠습니까?')) {
                    return {
                        ...state,
                        isManualAdd: true,
                    }
                }
            }
            else {
                return {
                    ...state,
                    users: payload,
                }
            }
        },
        [GET_USERS_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default findUser;