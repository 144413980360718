import client from './client';

export const code_search = (body) =>
    client.post('/api/admin/code_search', body);

export const code_multi_search = (mainCode) =>
    client.post('/api/admin/code_multi_search', { main_code: mainCode });

export const code_reg = (payload) =>
    client.post('/api/admin/code_register', payload);

export const auth_menu_search = (authCode) =>
    client.post('/api/admin/auth_menu_search', { code: authCode });

export const auth_menu_reg = (body) => 
    client.post('/api/admin/auth_menu', body);

export const smsReq = (body) => 
    client.post('/api/admin/smsReq', body);

export const doc_search = (mainCode) =>
    client.post('/api/admin/doc_search', { main_code: mainCode });

export const doc_search2 = (body) =>
    client.post('/api/admin/doc_search2', body);

export const apiRequest = (body) =>
    client.post('/api/admin/apiRequest', body);

export const file_search = (body) =>
    client.post('/api/admin/file_search', body);

export const expense_summary = (body) =>
    client.post('/api/admin/expense_summary', body);

export const expense_search = (body) =>
    client.post('/api/admin/expense_search', body);

export const expense_confirm = (body) =>
    client.post('/api/admin/expense_confirm', body);

export const doc_print_search = () =>
    client.get('/api/admin/doc_print_search');

export const doc_print_set = (body) =>
    client.post('/api/admin/doc_print_set', body);

export const decide_date = (body) =>
    client.post('/api/admin/decide_date', body);