import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import dataMenu from '../lib/dataMenu';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'menuAuth/INITIALIZE';
const CHANGE_VALUE = 'menuAuth/CHANGE_VALUE';
const [GET_AUTH, GET_AUTH_SUCCESS, GET_AUTH_FAILURE] = createRequestActionTypes('admin/code_search');
const MENU_CHANGE_VALUE = 'menuAuth/MENU_CHANGE_VALUE';
const MENU_BINDING = 'menuAuth/MENU_BINDING';
const [GET_MENU, GET_MENU_SUCCESS, GET_MENU_FAILURE] = createRequestActionTypes('menuAuth/get_menu');
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('menuAuth/save');
// const [ACTION, ACTION_SUCCESS, ACTION_FAILURE] = createRequestActionTypes('menuAuth/ACTION');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('menuAuth/REACTION');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getAuth = createAction(GET_AUTH, (body) => (body));
export const menuChangeValue = createAction(MENU_CHANGE_VALUE, ({id, key, value}) => ({id, key, value}));
export const menuBinding = createAction(MENU_BINDING);
export const getMenu = createAction(GET_MENU, (code) => (code));
export const saveMenu = createAction(SAVE, (body) => (body));
// export const action = createAction(ACTION, data => data);

const getAuthSaga = createRequestSaga(GET_AUTH, adminAPI.code_search);
const getMenuSaga = createRequestSaga(GET_MENU, adminAPI.auth_menu_search);
const saveMenuSaga = createRequestSaga(SAVE, adminAPI.auth_menu_reg);
// const actionSaga = createRequestSaga(ACTION, actionAPI.action);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* menuAuthSaga() {
    yield takeLatest(GET_AUTH, getAuthSaga);
    yield takeLatest(GET_MENU, getMenuSaga);
    yield takeLatest(SAVE, saveMenuSaga);
    // yield takeLatest(ACTION, actionSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const initialState = {
    auth: [],
    menu: [],
    menuAuth: null,
    error: null
};

const menuAuth = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: { key, value } }) => ({
            ...state,
            [key]: value,
        }),
        [GET_AUTH_SUCCESS]: (state, { payload: auth }) => ({
            ...state,
            error: null,
            auth,
        }),
        [GET_AUTH_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [MENU_BINDING]: (state) => {
            return {
                ...state,
                menu: dataMenu.menu,
            };
        },
        [MENU_CHANGE_VALUE]: (state, { payload: { id, key, value } }) => {
            let _menu = state.menu;
            if(id.indexOf('MENU') > -1) {
                _menu = state.menu.map((item) =>
                    item.id === id ? { ...item, [key]: value } : item,
                );
            }
            else {
                _menu = state.menu.map((item) =>
                    {
                        let _func = [];
                        if(item.func.length > 0)
                        {
                            _func = item.func.map((data) =>
                                data.id === id ? { ...data, [key]: value } : data,
                            )
                        }

                        return {...item, func: _func}
                    }
                );
            }
            return {
                ...state,
                menu: _menu,
            };
        },
        [GET_MENU_SUCCESS]: (state, { payload: arrMenu }) => {
            let _menu = state.menu;
            arrMenu.forEach(menuId => {
                if(menuId.indexOf('MENU') > -1) {
                    _menu = _menu.map((item) =>
                        item.id === menuId ? { ...item, auth_yn: true } : item,
                    );
                }
                else {
                    _menu = _menu.map((item) =>
                        {
                            let _func = [];
                            if(item.func.length > 0)
                            {
                                _func = item.func.map((data) =>
                                    data.id === menuId ? { ...data, auth_yn: true } : data,
                                )
                            }

                            return {...item, func: _func}
                        }
                    );
                }
            });
            return {
                ...state,
                error: null,
                menu: _menu
            }
        },
        [GET_MENU_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default menuAuth;