import React from 'react';
import styled from 'styled-components';
import CreateBrokerContainer from '../containers/Modal/CreateBrokerContainer';
import EnwAdminUploadContainer from '../containers/Modal/EnwAdminUploadContainer';

const MainBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    position: relative;
  
    .mainCntr {
        // display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(30%, auto));
        display: flex;
        align-items: center;
        // justify-content: space-between;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 0 15px;
        margin-top: 20px;
    }
    .mainBox {
        min-width: 168px;
        height: 0;
        position: relative;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #DDDDDD;
        background-color: #ffffff;
        cursor: pointer;
    }
    .mainBox > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .mainBox>div>div:nth-child(1):not(.type3>div>div:nth-child(1)){
        position: absolute;
        top: 30px;
        color: #545454;
        font-size: 16px;
        font-weight: 600;
    }

    h3{
        color: #545454;
        font-size: 24px;
        margin: 10px 0 20px 0;
    }
    .type3 {
        width: 100%;
        //width: calc( 99% + 10px );
        height: 300px;
        margin: 30px 0 35px 0;
        //padding-bottom: 18%;
    }
    .userInfo{
        color: #545454;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        white-space: nowrap;
        margin-bottom: 20px;
        gap: 30px;
    }
    .userInfo div::after{
        content:"";
        display: inline-block;
        width: 1px;
        height: 80%;
        background-color: #BDBDBD;
        vertical-align: bottom;
        margin-left: 15px;
    }
    .userInfo div:last-child::after{
        display: none;
    }
    .userInfo .key{
        margin-right: 5px;
    }
    .userInfo .value{
        color: #0B999C;
    }
    .tyep3-text{
        color: #545454;
    }
    .type3 .detail{
        color: #808080;
        margin-top: 10px;
    }
    .type1, .type2{
        height: 210px;
        margin-bottom: 15px;
        //padding-bottom: 16%;
    }
    .statusInfo {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 40px;
        margin: 0px 0 20px 0;
    }
    .statusInfo > div{
        position: relative;
        width: 94px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        //padding: 5px 10px;
        background-color: #DDDDDD;
        border-radius: 40px;
        white-space: nowrap;
    }
    .statusInfo>div:not(:last-child):after{
        content: "⋯";
        color: #cccccc;
        font-size: 40px;
        position: absolute;
        right: -40px;
        display: inline-block;
        margin-top: -6px;
        white-space: nowrap;
    }
    .statusInfo .on{
        background: linear-gradient(to bottom, #3EC092 , #0A989D);
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }
    .count:not(.type3 .count){
        width: 64px;
        height: 64px;
        color: #0B999D;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(11, 153, 157, 0.1);
    }
    .detail:not(.type3 .detail){
        color: #808080;
        font-size: 14px;
        position: absolute;
        bottom: 14px;
        right: 20px;
    }
    .detail:not(.type3 .detail)::after{
        content: "→";
        color: #BDBDBD;
        font-size: 26px;
        line-height: 1rem;
        //width: 24px;
        //height: 13px;
        display: inline-block;
        vertical-align: top;
        margin: 2px 0 0 6px;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        padding: 15px;

        .mainCntr {
            gap: 0;
            justify-content: space-between;
        }
        .mainBox{
            width: 100%;
            min-width: 0px;
            // max-width: 180px;
            //padding-bottom: 40%;
        }
        
        h3{
            font-size: 16px;
            margin-bottom: 15px;
        }
        .type3 {
            margin: 15px 0 10px 0;
        }
        .type3>div{
            padding: 0 20px;
            box-sizing: border-box;
        }
        .type3 .detail{
            font-size: 12px;
        }
        .statusInfo{
            flex-wrap: wrap;
            margin-bottom: 15px;
            gap: 15px;
        }
        .statusInfo > div{
            width: 52px;
            height: 25px;
            font-size: 10px; 
        }
        .statusInfo>div:not(:last-child)::after{
            font-size: 12px;
            right: -13px;
            margin-top: 0px;
        }
        .userInfo{
            font-size: 10px;
            font-weight: 600;
            gap: 10px;
            margin-bottom: 20px;
        }
        
        .type1, .type2{
            width: 49%;
            height: 180px;
            margin-bottom: 15px;
            //padding-bottom: 16%;
        }
        .mainBox>div>div:nth-child(1):not(.type3>div>div:nth-child(1)){
            font-size: 13px;
        }
        .count:not(.type3 .count){
            width: 48px;
            height: 48px;
            font-size: 14px;
        }
        .detail:not(.type3 .detail){
            color: #BDBDBD;
            font-size: 9px;
            bottom: 12px;
            right: 12px;
        }
        .detail:not(.type3 .detail)::after{
            font-size: 12px;
            margin-top: -1px;
        }
        
    }

`;

const Main = (props) => {
    const {
        isShowUpload,
        isShowCreateBroker,
        menu,
        status,
        winnerWidget,

        setIsShowUpload,
        setIsShowCreateBroker,
        onClick
    } = props;

    const propDatas = {
        isShowUpload,
        setIsShowUpload,

        setIsShowCreateBroker,
    }

    return (
        <MainBlock>
            <div className='mainWrapper'>

                <div className='mainCntr'>
                    {menu? 
                        <>
                            {
                                menu.map((item,idx) => {
                                    if(item) {
                                        /*
                                        유형
                                        1. 제목, 숫자(길이 가변)
                                        2. 아이콘 (버튼형태)
                                        3. 상태만 보기
                                        */
                                        return (
                                            <div className={`mainBox ${item.type}`} onClick={() => onClick(item)} key={idx}>
                                                <div>
                                                    {item.type !== 'type3'? 
                                                    <div>{item.name}</div> 
                                                    : 
                                                    <>
                                                        <h3>지원 신청 정보</h3>
                                                        {winnerWidget.length > 0? 
                                                        <>
                                                            <div className='userInfo'>
                                                                <div>
                                                                    <span className='key'>성명:</span>
                                                                    <span className='value'>{winnerWidget[0].cust_name}</span>
                                                                </div>
                                                                <div>
                                                                    <span className='key'>당첨일: </span>
                                                                    <span className='value'>{winnerWidget[0].rec_date}</span>
                                                                </div>
                                                                <div>
                                                                    <span className='key'>지원유형: </span>
                                                                    <span className='value'>{winnerWidget[0].winner_type_name}</span>
                                                                </div>
                                                                <div>
                                                                    <span className='key'>메모(알림): </span>
                                                                    <span className='value'>{winnerWidget[0].remark || '메모(알림)이 없습니다.'}</span>
                                                                </div>
                                                            </div>
                                                            <div className='userInfo'>
                                                                <div>
                                                                    <span className='key'>계약일: </span>
                                                                    <span className='value'>{winnerWidget[0].contract_date}</span>
                                                                </div>
                                                                <div>
                                                                    <span className='key'>계약장소: </span>
                                                                    <span className='value'>{winnerWidget[0].contract_location}</span>
                                                                </div>
                                                            </div>
                                                            <div className='statusInfo'>
                                                                {status.map((item, idx) => {
                                                                    return (
                                                                        <div className={item.code.some((el) => el === winnerWidget[0].status)? "on" : ""}key={idx}>{item.name}</div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </> 
                                                        : 
                                                        <div className='tyep3-text'>진행 중인 정보가 없습니다.</div>}
                                                        
                                                    </>}
                                                    {item.type !== 'type3' && Number(item.count) >= 0? 
                                                    <>
                                                        <h2  className='count'>{item.count}</h2>
                                                        <div className='detail'>자세히</div>
                                                    </>
                                                    // : <div className='detail'>자세히</div>}
                                                    : <div className='detail'>자세히</div>}
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return null;
                                    }
                                })
                            }
                        </> 
                    : null}
                </div>
            </div>

            {isShowUpload && <EnwAdminUploadContainer {...propDatas}/>}
            {isShowCreateBroker && <CreateBrokerContainer {...propDatas}/>}
        </MainBlock>
    );
}

export default Main;