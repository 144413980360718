import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as adminAPI from '../lib/api/admin';
import * as entitleAPI from '../lib/api/entitle';
import * as approvalAPI from '../lib/api/approval';
import * as scheAPI from '../lib/api/sche';
import * as authAPI from '../lib/api/auth';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'entitleWork/INITIALIZE';
const CHANGE_VALUE = 'entitleWork/CHANGE_VALUE';
const CHANGE_NUMBER = 'entitleWork/CHANGE_NUMBER';
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('entitleWork/GET_COMBO');
const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('entitleWork/GET_CONTENT');
const [GET_FILES, GET_FILES_SUCCESS, GET_FILES_FAILURE] = createRequestActionTypes('entitleWork/GET_FILES');
const S1_ROW_CHANGE_VALUE = 'entitleWrite/S1_ROW_CHANGE_VALUE';

const [SET_STATUS_API, SET_STATUS_API_SUCCESS, SET_STATUS_API_FAILURE] = createRequestActionTypes('entitleWork/SET_STATUS_API');
const SET_STATUS = 'entitleWork/SET_STATUS';
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('entitleWork/SAVE');
const [SAVE2, SAVE2_SUCCESS, SAVE2_FAILURE] = createRequestActionTypes('entitleWork/SAVE2');
const [FEEDBACK, FEEDBACK_SUCCESS, FEEDBACK_FAILURE] = createRequestActionTypes('entitleWork/FEEDBACK');
const [FEEDBACK_CANCEL, FEEDBACK_CANCEL_SUCCESS, FEEDBACK_CANCEL_FAILURE] = createRequestActionTypes('entitleWork/FEEDBACK_CANCEL');
const [REQ_APPROVAL, REQ_APPROVAL_SUCCESS, REQ_APPROVAL_FAILURE] = createRequestActionTypes('entitleWork/REQ_APPROVAL');
const [SAVE_APPROVAL, SAVE_APPROVAL_SUCCESS, SAVE_APPROVAL_FAILURE] = createRequestActionTypes('entitleWork/SAVE_APPROVAL');
const [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE] = createRequestActionTypes('entitleWork/SEND_SMS');
const [REMOVE_ENTITLE, REMOVE_ENTITLE_SUCCESS, REMOVE_ENTITLE_FAILURE] = createRequestActionTypes('entitleWork/REMOVE_ENTITLE');

const DOC_SELECT_FOLDER = 'entitleWork/DOC_SELECT_FOLDER';
const DOC_SELECT_IMG = 'entitleWork/DOC_SELECT_IMG';
const UPLOAD_COMPLETE = 'entitleWork/UPLOAD_COMPLETE';

const [SAVE_PRIORITY, SAVE_PRIORITY_SUCCESS, SAVE_PRIORITY_FAILURE] = createRequestActionTypes('entitleWork/SAVE_PRIORITY');
const [PWD_CHECK, PWD_CHECK_SUCCESS, PWD_CHECK_FAILURE] = createRequestActionTypes('entitleWork/PWD_CHECK');
const [IMG_ROTATION, IMG_ROTATION_SUCCESS, IMG_ROTATION_FAILURE] = createRequestActionTypes('entitleWork/IMG_ROTATION');


export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const changeNumber = createAction(CHANGE_NUMBER, ({key, value}) => ({key, value}));
export const getCombo = createAction(GET_COMBO);
export const getContent = createAction(GET_CONTENT);
export const getFiles = createAction(GET_FILES);
export const s1_rowChangeValue = createAction(S1_ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));

export const setStatusApi = createAction(SET_STATUS_API, (body) => (body));
export const setStatus = createAction(SET_STATUS, (value) => (value));
export const save = createAction(SAVE, (body) => (body));
export const save2 = createAction(SAVE2, (body) => (body));
export const feedback = createAction(FEEDBACK, (body) => (body));
export const feedbackCancel = createAction(FEEDBACK_CANCEL, (body) => (body));
export const reqApproval = createAction(REQ_APPROVAL);
export const saveApproval = createAction(SAVE_APPROVAL);
export const sendSms = createAction(SEND_SMS, (body) => (body));
export const removeEntitle = createAction(REMOVE_ENTITLE);

export const doc_selectFolder = createAction(DOC_SELECT_FOLDER, (value) => value);
export const doc_selectImg = createAction(DOC_SELECT_IMG, (value) => value);
export const uploadComplete = createAction(UPLOAD_COMPLETE, (value) => value);
export const savePriority = createAction(SAVE_PRIORITY, (body) => (body));
export const pwdCheck = createAction(PWD_CHECK, (body) => (body));
export const imgRotation = createAction(IMG_ROTATION, (body) => (body));

const getContentSaga = createRequestSaga(GET_CONTENT, entitleAPI.enprocess_view);
const getFilesSaga = createRequestSaga(GET_FILES, entitleAPI.file_view);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_multi_search);
const setStatusApiSaga = createRequestSaga(SET_STATUS_API, entitleAPI.status);
const saveSaga = createRequestSaga(SAVE, entitleAPI.enprocess);
const save2Saga = createRequestSaga(SAVE2, entitleAPI.enprocess);
const feedbackSaga = createRequestSaga(FEEDBACK, entitleAPI.feedback_req);
const feedbackCancelSaga = createRequestSaga(FEEDBACK_CANCEL, entitleAPI.feedback_cancel);
const reqApprovalSaga = createRequestSaga(REQ_APPROVAL, approvalAPI.app_request);
const saveApprovalSaga = createRequestSaga(SAVE_APPROVAL, approvalAPI.approve);
const sendSmsSaga = createRequestSaga(SEND_SMS, scheAPI.smsSend);
const removeEntitleSaga = createRequestSaga(REMOVE_ENTITLE, entitleAPI.ennotused);
const savePrioritySaga = createRequestSaga(SAVE_PRIORITY, entitleAPI.senior_rent);
const pwdCheckSaga = createRequestSaga(PWD_CHECK, authAPI.pwdCheck);
const imgRotationSaga = createRequestSaga(IMG_ROTATION, entitleAPI.file_rotation);

// // savePriority_loadinfo
// function* change_sido(type) {
//     const {payload: {payload, docNo, savePayload, senior_sido}} = type;
//     // const {payload: {docNo}} = type;

//     /*
//     > 선순위 임대 관련 api 호출 순서
//     1. 선순위 저장하고
//     2. 검토서 전체 값 저장하고
//     3. 다시 조회힌다

//     참고
//     - 선순위 저장하고 검토서 전체를 다시 저장하는 이유는 선순위에 따라서 금액이 변경 되고, 선순위 주소가 바뀌기 때문

//     문제
//     - 선순위 저장하고, 검토서 전체 값 저장할때 채권금액 등등이 변경되고 저장 되어야 하는데
//     - 값 변경이 일어나기 전에 검토서를 저장하고 다시 조회하기 때문에 db와 실제 값에 차이가 발생.
//     - '2번 검토서 다시저장' 할때 채권 확보 검토의 각종 금액을 다시 계산해서 저장해야함.

//     현재상황
//     - 2 번 에서 값을 처음부터 다시 계산해야하기 때문에 2번을 생략하고, 1번 을 하면서
//     선순위 주소를 저장하려함. -> 이유) 선순위 등록 모달에서 값을 저장하면 채권 확보 검토 금액이 자동으로 바뀌기 때문.



//     > 검토파일 관련
//     fileDataAll 배열 값의 api_yn 으로 재요청, 미요청 등을 확인하는데,
//     검토파일 자동 받기 하면 fileDataAll 값이 실시간으로 바뀌지 않음. (api 요청한 파일이 fileDataAll 에 바로 담기지 않음)
   
//     ex) 
//     1. 최초 요청시에 fileDataAll 에 api_yn가 Y 인 값이 아예 존재 하지 않음.
//     2. 검토파일 자동받기를 하면 fileDataAll 에 기존 파일(신청서 파일)과 api로 신청한 파일이 담겨서 api_yn 을 비교 해서 재요청, 요청중, 요청완료를
//     비교 할수 있을것으로 예상하지만, 요청후 조회 할 시 fileDataAll 는 업데이트 되지 않음. 
//     (현재는 호출만 하고 값을 할당하지 않지만 요청 값을 확인해보면 업데이트된 리스트가 오지 않음.)
    
//     */

//      // 1. 선순위
//     try{
//         const response = yield call(entitleAPI.senior_rent, payload);
//         yield put({
//             type: SAVE_PRIORITY_SUCCESS,
//             payload: response.data,
//             meta: response,
//           });

//         //    //2.  저장
//         //   try {
//         //     const response2 = yield call(entitleAPI.enprocess, savePayload)
//         //     yield put({
//         //         type: SAVE_SUCCESS,
//         //         payload: response2.data,
//         //         meta: response2,
//         //     });
//         //     // window.location.reload();
//             // 3. 다시 불러오기
//             try {
//                 const response3 = yield call(entitleAPI.enprocess_view, docNo)
//                 yield put({
//                   type: GET_CONTENT_SUCCESS,
//                   payload: response3.data,
//                   meta: response3,
//                 });
//             } catch(e) {
//               yield put({
//                   type: GET_CONTENT_FAILURE,
//                   payload: e,
//                   error: true,
//                 });
//             }

//         //   } catch(e) {
//         //     yield put({
//         //         type: SAVE_FAILURE,
//         //         payload: e,
//         //         error: true,
//         //       });
//         //   }

//     }catch(e) {
//         yield put({
//             type: SAVE_PRIORITY_FAILURE,
//             payload: e,
//             error: true,
//           });
//     }
// }

export function* entitleWorkSaga() {
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(GET_CONTENT, getContentSaga);
    yield takeLatest(GET_FILES, getFilesSaga);
    yield takeLatest(SET_STATUS_API, setStatusApiSaga);
    yield takeLatest(SAVE, saveSaga);
    yield takeLatest(SAVE2, save2Saga);
    yield takeLatest(FEEDBACK, feedbackSaga);
    yield takeLatest(FEEDBACK_CANCEL, feedbackCancelSaga);
    yield takeLatest(REQ_APPROVAL, reqApprovalSaga);
    yield takeLatest(SAVE_APPROVAL, saveApprovalSaga);
    yield takeLatest(SEND_SMS, sendSmsSaga);
    yield takeLatest(REMOVE_ENTITLE, removeEntitleSaga);
    yield takeLatest(SAVE_PRIORITY, savePrioritySaga);
    yield takeLatest(PWD_CHECK, pwdCheckSaga);
    yield takeLatest(IMG_ROTATION, imgRotationSaga);
}

const initialState = {
    loginUserId: '',
    doc_status: '',
    app_user: '',
    appcheck_user: '',
    isFeedback: '',
    feedback_answer: '',
    call_memo: '',
    call_check: '',
    entitle_user: '',
    entitle_user_name: '',
    appr_complete_date: '',

    approval: [],
    reject_reason: '',

    //  지원 대상자
    winner: [],
    winner_type: '',
    winner_type_name: '',
    contract_type: '',
    contract_type_name: '',
    rent_type: '',
    // rent_type_name: '',
    req_cnt: '',
    end_date: '',
    wallpaper_flag: '',
    minor_child_cnt: '',

    // 권리조사 주택유형
    addr_duplicate: '',
    house_type: '',
    house_owner: '',
    req_duplicate: '',
    road_addr: '',
    road_addr_dtl: '',
    address_memo: '',
    jibun: '',
    sido: '',
    sigungu: '',
    town: '',
    house_area: '',
    rent_amt: '',
    support_amt: '',
    ground_area: '',
    share_area: '',
    winner_charges: '',
    winner_add_charges: '',
    small_room_support: '',
    monthly_rent: '',
    guarantee_monthly_rent: '',
    securing_bonds: '',
    broker_company: '',
    broker_telno: '',
    family_cnt: '',
    estate_no: '',
    room_count: '',
    senior_rent_yn: '',
    
    // 채권확보검토
    house_amt: '',
    decide_how: '',
    decide_date: '',
    decide_amt: '',
    possible_bond_amt: '',
    first_bond_amt: '',
    mortgage_amt: '',
    pre_deposit_total: '',
    pre_rent_loans: '',
    pre_charge_amt: '',
    pre_interest_free: '',
    rent_set_amt: '',
    seizure_amt: '',
    total_room_cnt: '',
    rent_room_cnt: '',
    already_rent_amt: '',
    empty_room_cnt: '',
    securing_bond_amt: '',
    remark: '',
    difference_amt: '',
    debt_ratio: '',
    suitable_flag: '',

    //  서류 이미지
    selectFoler: '',
    docFolder: [],
    selectImg: null,
    docImages: [],
    selectTxtFolder: 'TXT01',
    txtFolder: [
        {
            code: 'TXT01',
            name: '지원신청서',
        },
        // {
        //     code: 'TXT03',
        //     name: '등기부등본',
        // },
        {
            code: 'TXT04',
            name: '건축물관리대장',
        },
        {
            code: 'TXT05',
            name: '주택가격정보',
        },
        {
            code: 'TXT06',
            name: '신/구 주소',
        },
    ],

    //  API 요청 State
    addr_req: '',
    building_state_no: '',
    use_yn: '',

    //  검토서 변경요청 데이터
    work_change_date: '',
    work_change_req: '',
    work_change_user: '',

    //  콤보박스
    cboApply: [],   //지원유형 ex) 신혼부부,청년...

    fileDataAll: [],
    
    //  선순위 합계
    rent: [],
    senior_sido: '',
    rent_tot_amt: '',
    empty_tot_amt: '',

    //  저장완료 Flag
    isSaveOK: false,

    //  권리분석 패스워드 일치 여부
    user_pwd: '',
    pwdCheckResult: '',
    
    work: null,
    error: null,
};

const entitleWork = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [CHANGE_NUMBER]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [GET_COMBO_SUCCESS]: (state, {payload : {APPLY_TYPE}}) => {
            return {
                ...state,
                cboApply: APPLY_TYPE,
            }
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        //  서류이미지   =======================================================================================
        [DOC_SELECT_FOLDER]: (state, { payload }) => {
            return {
                ...state,
                selectFoler: payload.code,
            };
        },
        [DOC_SELECT_IMG]: (state, { payload }) => {
            return {
                ...state,
                selectImg: {
                    src: payload.imgSrc,
                    file_id: payload.file_id,
                    rotation: !payload.rotation ? 0 : payload.rotation,
                    api_yn: payload.api_yn,
                }
            };
        },
        [UPLOAD_COMPLETE]: (state, { payload }) => {
            //  Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;

            payload.forEach(file => {
                if(file.use_flag === "Y") {
                    if(file.file_data) {
                        //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                        if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                            _docFolder.push({
                                name: file.file_type_name,
                                code: file.file_type,
                            })
                        }

                        //  문서종류 버튼의 첫번째 버튼을 자동선택
                        if(!_selectFoler) {
                            _selectFoler = file.file_type;
                        }

                        file.file_data.forEach(item => {
                            _docImages.push({
                                docCode: file.file_type,
                                imgSrc: item.file_url,
                                file_id: file.file_id,
                                rotation: item.rotation,
                                api_yn: file.api_yn,
                            })
                        });

                        // const arrFile = file.file_data.split(',');
                        // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                        // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                        //     arrFile.forEach(item => {
                        //         _docImages.push({
                        //             docCode: file.file_type,
                        //             imgSrc: item,
                        //             file_id: file.file_id,
                        //             rotation: file.rotation,
                        //             api_yn: file.api_yn,
                        //         })
                        //     });
                        // }
                        // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                        // else {
                        //     _docImages.push({
                        //         docCode: file.file_type,
                        //         imgSrc: file.file_data,
                        //         file_id: file.file_id,
                        //         rotation: file.rotation,
                        //         api_yn: file.api_yn,
                        //     })
                        // }
                    }

                    if(_docImages.length > 0) {
                        _selectImg = {
                            src: _docImages[0].imgSrc,
                            file_id: _docImages[0].file_id,
                            rotation: _docImages[0].rotation,
                            api_yn: _docImages[0].api_yn,
                        }
                    }
                }
            });

            return ({
                ...state,
                docFolder: _docFolder,
                docImages: _docImages,
                selectFoler: _selectFoler,
                selectImg: _selectImg,
                fileDataAll: payload.map((item) =>
                                    true ? {
                                        ...item,
                                        file_id: 'tempId' + item.idx,
                                    } : item,
                                ),
            })
        },
        [IMG_ROTATION_SUCCESS]: (state, { payload }) => {
            ALERT("선택한 이미지의 방향이 저장되었습니다.");
            return {
                ...state,
            };
        },
        [IMG_ROTATION_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error,
            };
        },
        [GET_FILES_SUCCESS]: (state, { payload }) => {
            //  Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;

            payload.forEach(file => {
                if(file.use_flag === "Y") {
                    if(file.file_data) {
                        //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                        if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                            _docFolder.push({
                                name: file.file_type_name,
                                code: file.file_type,
                            })
                        }

                        //  문서종류 버튼의 첫번째 버튼을 자동선택
                        if(!_selectFoler) {
                            _selectFoler = file.file_type;
                        }

                        file.file_data.forEach(item => {
                            _docImages.push({
                                docCode: file.file_type,
                                imgSrc: item.file_url,
                                file_id: file.file_id,
                                rotation: item.rotation,
                                api_yn: file.api_yn,
                            })
                        });

                        // const arrFile = file.file_data.split(',');
                        // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                        // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                        //     arrFile.forEach(item => {
                        //         _docImages.push({
                        //             docCode: file.file_type,
                        //             imgSrc: item,
                        //             file_id: file.file_id,
                        //             rotation: file.rotation,
                        //             api_yn: file.api_yn,
                        //         })
                        //     });
                        // }
                        // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                        // else {
                        //     _docImages.push({
                        //         docCode: file.file_type,
                        //         imgSrc: file.file_data,
                        //         file_id: file.file_id,
                        //         rotation: file.rotation,
                        //         api_yn: file.api_yn,
                        //     })
                        // }
                    }

                    if(_docImages.length > 0) {
                        _selectImg = {
                            src: _docImages[0].imgSrc,
                            file_id: _docImages[0].file_id,
                            rotation: _docImages[0].rotation,
                            api_yn: _docImages[0].api_yn,
                        }
                    }
                }
            });

            return ({
                ...state,
                docFolder: _docFolder,
                docImages: _docImages,
                selectFoler: _selectFoler,
                selectImg: _selectImg,
                fileDataAll: payload.map((item) =>
                                    true ? {
                                        ...item,
                                        file_id: 'tempId' + item.idx,
                                    } : item,
                                ),
            })
        },
        [GET_FILES_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  Status 변경   =======================================================================================
        [SET_STATUS_API_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                doc_status: payload,
                error: null,
            })
        },
        [SET_STATUS_API_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error,
            }
        },
        [SET_STATUS]: (state, { payload }) => {
            return {
                ...state,
                doc_status: payload,
            };
        },

        //  검토서 조회   =======================================================================================
        [GET_CONTENT_SUCCESS]: (state, { payload }) => {

            //  Get폴더 & Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.files.forEach(file => {
                if(file.file_type !== "DOCUDF") {
                    if(file.file_data) {
                        //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                        if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                            _docFolder.push({
                                name: file.file_type_name,
                                code: file.file_type,
                            })
                        }
        
                        //  문서종류 버튼의 첫번째 버튼을 자동선택
                        if(!_selectFoler) {
                            _selectFoler = file.file_type;
                        }

                        file.file_data.forEach(item => {
                            _docImages.push({
                                docCode: file.file_type,
                                imgSrc: item.file_url,
                                file_id: file.file_id,
                                rotation: item.rotation,
                                api_yn: file.api_yn,
                            })
                        });
    
                        // const arrFile = file.file_data.split(',');
                        // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                        // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                        //     arrFile.forEach(item => {
                        //         _docImages.push({
                        //             docCode: file.file_type,
                        //             imgSrc: item,
                        //             file_id: file.file_id,
                        //             rotation: file.rotation,
                        //             api_yn: file.api_yn,
                        //         })
                        //     });
                        // }
                        // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                        // else {
                        //     _docImages.push({
                        //         docCode: file.file_type,
                        //         imgSrc: file.file_data,
                        //         file_id: file.file_id,
                        //         rotation: file.rotation,
                        //         api_yn: file.api_yn,
                        //     })
                        // }
    
                        if(_docImages.length > 0) {
                            _selectImg = {
                                src: _docImages[0].imgSrc,
                                file_id: _docImages[0].file_id,
                                rotation: _docImages[0].rotation,
                                api_yn: _docImages[0].api_yn,
                            }
                        }
                    }
                }
            });

            return {
                ...state,

                doc_status: payload.entitle[0].status,
                app_user: payload.entitle[0].app_user,
                appcheck_user: payload.entitle[0].appcheck_user,
                isFeedback: payload.entitle[0].feedback,
                feedback_answer: payload.entitle[0].feedback_answer,
                call_memo: payload.entitle[0].call_memo,
                call_check: payload.entitle[0].call_check,
                entitle_user: payload.entitle[0].entitle_user,
                entitle_user_name: payload.entitle[0].entitle_user_name,
                appr_complete_date: payload.entitle[0].appr_complete_date,

                approval: payload.approval,
                reject_reason: payload.entitle[0].reject_reason,

                //  지원 대상자
                winner: payload.winner,
                winner_type: payload.winner[0].winner_type,
                winner_type_name: payload.winner[0].winner_type_name,
                contract_type: payload.entitle[0].contract_type,
                contract_type_name: payload.entitle[0].contract_type_name,
                rent_type: payload.entitle[0].rent_type,
                // rent_type_name: payload.entitle[0].rent_type_name,
                req_cnt: payload.entitle[0].req_cnt,
                end_date: payload.entitle[0].end_date,
                wallpaper_flag: payload.entitle[0].wallpaper_flag,
                minor_child_cnt: payload.entitle[0].minor_child_cnt,

                // 권리조사 주택유형
                addr_duplicate: payload.entitle[0].addr_duplicate,
                house_type: payload.entitle[0].house_type,
                house_owner: payload.entitle[0].house_owner,
                req_duplicate: payload.entitle[0].req_duplicate,
                road_addr: payload.entitle[0].road_addr,
                road_addr_dtl: payload.entitle[0].road_addr_dtl,
                address_memo: payload.entitle[0].address_memo,
                jibun: payload.entitle[0].jibun,
                sido: payload.entitle[0].sido,
                sigungu: payload.entitle[0].sigungu,
                town: payload.entitle[0].town,
                house_area: payload.entitle[0].house_area,
                rent_amt: payload.entitle[0].rent_amt,
                support_amt: payload.entitle[0].support_amt,
                ground_area: payload.entitle[0].ground_area,
                share_area: payload.entitle[0].share_area,
                winner_charges: payload.winner[0].winner_charges / payload.winner.length,
                winner_add_charges: payload.winner[0].add_charges,
                small_room_support: payload.entitle[0].small_room_support,
                monthly_rent: payload.winner[0].monthly_amt,
                guarantee_monthly_rent: payload.entitle[0].monthly_rent_amt,
                securing_bonds: payload.entitle[0].securing_bonds,
                broker_company: payload.entitle[0].broker_company,
                broker_telno: payload.entitle[0].broker_phone,
                family_cnt: payload.entitle[0].family_cnt,
                estate_no: payload.entitle[0].estate_no,
                room_count: payload.entitle[0].room_count,
                senior_rent_yn: payload.entitle[0].senior_rent_yn,
                
                // 채권확보검토
                house_amt: payload.entitle[0].house_amt,
                decide_how: payload.entitle[0].decide_how,
                decide_date: payload.entitle[0].decide_date,
                decide_amt: payload.entitle[0].decide_amt,
                possible_bond_amt: payload.entitle[0].possible_bond_amt,
                first_bond_amt: payload.entitle[0].first_bond_amt,
                mortgage_amt: payload.entitle[0].mortgage_amt,
                pre_deposit_total: payload.entitle[0].pre_deposit_total,
                pre_rent_loans: payload.entitle[0].pre_rent_loans,
                pre_charge_amt: payload.entitle[0].pre_charge_amt,
                pre_interest_free: payload.entitle[0].pre_interest_free,
                rent_set_amt: payload.entitle[0].rent_set_amt,
                seizure_amt: payload.entitle[0].seizure_amt,
                total_room_cnt: payload.entitle[0].total_room_cnt,
                rent_room_cnt: payload.entitle[0].rent_room_cnt,
                already_rent_amt: payload.entitle[0].already_rent_amt,
                empty_room_cnt: payload.entitle[0].empty_room_cnt,
                securing_bond_amt: payload.entitle[0].securing_bond_amt,
                remark: payload.entitle[0].remark,
                difference_amt: payload.entitle[0].difference_amt,
                debt_ratio: payload.entitle[0].debt_ratio,
                suitable_flag: payload.entitle[0].suitable_flag,

                //  API 요청 State
                addr_req: payload.entitle[0].addr_req,
                building_state_no: payload.entitle[0].building_state_no,
                use_yn: payload.entitle[0].use_yn,

                //  검토서 변경요청 데이터
                work_change_date: payload.entitle[0].work_change_date,
                work_change_req: payload.entitle[0].work_change_req,
                work_change_user: payload.entitle[0].work_change_user,

                //  우측 이미지
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                selectImg: _selectImg,

                fileDataAll: payload.files, // 기등록된 파일정보
                
                //  선순위 합계
                rent: payload.rent,
                senior_sido: payload.entitle[0].senior_sido,
                rent_tot_amt: payload.entitle[0].rent_tot_amt,
                empty_tot_amt: payload.entitle[0].empty_tot_amt,

                //  등기부등본 주소
                use_address: payload.use_address && Array.isArray(payload.use_address) && payload.use_address.length > 0 ? payload.use_address[0] : null,

                //  저장완료 flag
                isSaveOK: false,
            }
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        //  입주자별 금액변경
        [S1_ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _winner = state.winner.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                winner: _winner,
            };
        },

        //  검토서 저장   =======================================================================================
        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("저장되었습니다.");
            return ({
                ...state,
                isSaveOK: true,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            isSaveOK: false,
            error,
        }),

        //  피드백요청   =======================================================================================
        [FEEDBACK_SUCCESS]: (state, { payload }) => {
            ALERT("피드백이 요청되었습니다.");
            return ({
                ...state,
                isFeedback: 'P',
                error: null,
            })
        },
        [FEEDBACK_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  피드백요청 취소   =======================================================================================
        [FEEDBACK_CANCEL_SUCCESS]: (state, { payload }) => {
            ALERT("피드백 요청이 취소되었습니다.");
            return ({
                ...state,
                isFeedback: 'N',
                error: null,
            })
        },
        [FEEDBACK_CANCEL_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  결재 요청   =======================================================================================
        [SAVE2_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                isSaveOK: true,
                error: null,
            })
        },
        [SAVE2_FAILURE]: (state, { payload: error }) => ({
            ...state,
            isSaveOK: false,
            error,
        }),
        [REQ_APPROVAL_SUCCESS]: (state, { payload }) => {
            ALERT("결재를 요청하였습니다.");
            return ({
                ...state,
                doc_status: 'ENAPVREQ',
                approval: payload,
                error: null,
            })
        },
        [REQ_APPROVAL_FAILURE]: (state, { payload: error }) => {
            ALERT('결재요청이 실패하였습니다.\n결재라인을 확인해주세요.');
            return ({
                ...state,
                error,
            })
        },

        //  결재 승인   =======================================================================================
        [SAVE_APPROVAL_SUCCESS]: (state, { payload }) => {
            ALERT("결재를 승인하였습니다.");

            //  해당 결재자의 결재일 표시
            const _approval = state.approval.map((item) =>
                item.user_id === state.loginUserId ? {
                    ...item,
                    app_date: new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10).slice(-5).replace('-', '.')
                } : item,
            );

            return ({
                ...state,
                doc_status: payload.split(',')[0],  //결재 후 Status
                app_user: payload.split(',')[1],    //다음 차례 결재자 id
                approval: _approval,
                error: null,
            })
        },
        [SAVE_APPROVAL_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        [SEND_SMS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SEND_SMS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  선순위 주소 변경   =======================================================================================
        [SAVE_PRIORITY_SUCCESS]: (state, { payload }) => {
            // ALERT("저장되었습니다.");
            return {
                ...state,
            }
        },
        [SAVE_PRIORITY_FAILURE]: (state, { payload: error }) => {
            ALERT("선순위 임대차 주소설정에 오류가 발생했습니다.");
            return {
                ...state,
                error
            }
        },

        //  권리분석 취소 패스워드 확인
        [PWD_CHECK_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                pwdCheckResult: payload
            })
        },
        [PWD_CHECK_FAILURE]: (state) => {
            return ({
                ...state,
                pwdCheckResult: "NG"
            })
        },

        //  검토서 삭제
        [REMOVE_ENTITLE_SUCCESS]: (state, { payload }) => {
            ALERT("권리분석 검토서가 삭제되었습니다.");
            return {
                ...state,
            }
        },
        [REMOVE_ENTITLE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default entitleWork;