import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import WinnerExcelWorkContainer from '../containers/WinnerExcelWorkContainer';

const WinnerExcelWorkPage = () => {
    return (
        <>
            <HeaderContainer />
            <WinnerExcelWorkContainer/>
        </>
    );
}

export default WinnerExcelWorkPage;