import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ReportListContainer from '../containers/ReportListContainer';

const ReportListPage = () => {
    return (
        <>
            <HeaderContainer />
            <ReportListContainer/>
        </>
    );
}

export default ReportListPage;