import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnCallerMemo from '../../components/Modal/EnCallerMemo';
import { changeValue, check, initialize, save } from '../../modules/Modal/enCallerMemo';

const EnCallerMemoContainer = (props) => {
    const {
        user,
        docNo,
        setIsShowMemu,
        call_memo,
        call_check,
        entitle_user,
        onMemoComplete,
    } = props;

    const dispatch = useDispatch();
    const {
        remark,
    } = useSelector(({ enCallerMemo }) => ({
        remark: !enCallerMemo.remark ? "" : enCallerMemo.remark,
    }));

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(
            changeValue({
                key: 'remark',
                value: call_memo,
            })
        );
    }, [dispatch, call_memo])

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onSave = () => {
        dispatch(save({
            doc_id: docNo,
            remark: remark,
        }))
        setIsShowMemu(null);
        onMemoComplete("Y", remark);
    }
    const onMemoCheck = () => {
        dispatch(check({
            doc_id: docNo,
        }))
        setIsShowMemu(null);
        onMemoComplete("N", remark);
    }
    const onClose = () => {
        setIsShowMemu(null);
    }

    const propDatas = {
        user,
        remark,
        call_memo,
        call_check,
        entitle_user,

        onChange,
        onSave,
        onMemoCheck,
        onClose,
    }
    return (
        <>
            <EnCallerMemo {...propDatas} />
        </>
    );
}

export default EnCallerMemoContainer;