import { createAction, handleActions } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as authAPI from '../lib/api/auth';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'auth/INITIALIZE';
const CHANGE_VALUE = 'auth/CHANGE_VALUE';
const CHANGE_FILE = 'auth/CHANGE_FILE';
const CHANGE_REGSUCCESS = 'auth/CHANGE_REGSUCCESS';
const SET_TIMESTART = 'auth/SET_TIMESTART';
const TEMP_PWD_LOGIN = 'auth/TEMP_PWD_LOGIN';
const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('auth/LOGIN');
const [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE] = createRequestActionTypes('auth/REGISTER');
const [TEMPPWD, TEMPPWD_SUCCESS, TEMPPWD_FAILURE] = createRequestActionTypes('auth/TEMPPWD');
const [PWD_CHANGE, PWD_CHANGE_SUCCESS, PWD_CHANGE_FAILURE] = createRequestActionTypes('auth/PWD_CHANGE');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('auth/GET_COMBO');

const [CERT_REQUEST, CERT_REQUEST_SUCCESS, CERT_REQUEST_FAILURE] = createRequestActionTypes('auth/CERT_REQUEST');
const [CERT_CHECK, CERT_CHECK_SUCCESS, CERT_CHECK_FAILURE] = createRequestActionTypes('auth/CERT_CHECK');
const [OVERLAP_CHECK, OVERLAP_CHECK_SUCCESS, OVERLAP_CHECK_FAILURE] = createRequestActionTypes('auth/OVERLAP_CHECK');

// const [ACTION, ACTION_SUCCESS, ACTION_FAILURE] = createRequestActionTypes('auth/ACTION');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('auth/REACTION');

export const initialize = createAction(INITIALIZE);
export const setTimeValue = createAction(SET_TIMESTART, (body) => (body));
export const changeFile = createAction(CHANGE_FILE, ({name, file_name, file_data, contenttype}) => ({name, file_name, file_data, contenttype}));
export const tempPwd = createAction(TEMPPWD, ({phone}) => ({phone}));
export const changeValue = createAction(CHANGE_VALUE, ({field, key, value}) => ({field, key, value}));
export const loginApi = createAction(LOGIN, ({ input_id, input_pw }) => ({ input_id, input_pw }));
export const regApi = createAction(REGISTER, (body) => (body));
export const changeRegSuccess = createAction(CHANGE_REGSUCCESS, (body) => (body));
export const getCombo = createAction(GET_COMBO);

export const certReq = createAction(CERT_REQUEST, (body) => (body));
export const tempPwdLogin = createAction(TEMP_PWD_LOGIN, (body) => (body));
export const certChk = createAction(CERT_CHECK, (body) => (body));
export const pwdChange = createAction(PWD_CHANGE, (body) => (body));
export const overlapChk = createAction(OVERLAP_CHECK, (input_id) => (input_id));

// export const action = createAction(ACTION, data => data);
const loginSaga = createRequestSaga(LOGIN, authAPI.login);
const registerSaga = createRequestSaga(REGISTER, authAPI.register);
const tempPwdSaga = createRequestSaga(TEMPPWD, authAPI.tempPwd);
const certReqSaga = createRequestSaga(CERT_REQUEST, authAPI.cert_req);
const certChkSaga = createRequestSaga(CERT_CHECK, authAPI.cert_chk);
const pwdChangeSaga = createRequestSaga(PWD_CHANGE, authAPI.pwd_change);
const overlapChkSaga = createRequestSaga(OVERLAP_CHECK, authAPI.idcheck);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_multi_search);
// const actionSaga = createRequestSaga(ACTION, actionAPI.action);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

function* tempPwdLoginSaga(type, request) {

    // 비밀번호 변경하고,
    try {
        const response = yield call(authAPI.pwd_change, {
            phone: type.payload.id,
            user_pwd: type.payload.pw
        })
        // 비밀번호 변경 성공하면
        yield put({
            type: PWD_CHANGE_SUCCESS,
            payload: response.data,
            meta: response,
          });
          // 로그인
          try {
            const response2 = yield call(authAPI.login, {
                input_id: type.payload.id,
                input_pw: type.payload.pw
            })
            yield put({
                type: LOGIN_SUCCESS,
                payload: response2.data,
                meta: response2,
              });
          }catch(e) {
            yield put({
                type: LOGIN_FAILURE,
                payload: e,
                error: true,
              });
          }
    } catch(e) {
        yield put({
            type: PWD_CHANGE_FAILURE,
            payload: e,
            error: true,
          });
    }
    // 로그인.
}

export function* authSaga() {
    yield takeLatest(LOGIN, loginSaga);
    yield takeLatest(REGISTER, registerSaga);
    yield takeLatest(TEMPPWD, tempPwdSaga);
    yield takeLatest(CERT_REQUEST, certReqSaga);
    yield takeLatest(CERT_CHECK, certChkSaga);
    yield takeLatest(PWD_CHANGE, pwdChangeSaga);
    yield takeLatest(TEMP_PWD_LOGIN, tempPwdLoginSaga);
    yield takeLatest(OVERLAP_CHECK, overlapChkSaga);
    yield takeLatest(GET_COMBO, getComboSaga);
    // yield takeLatest(ACTION, actionSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const initialState = {
    register: {
        user_name: '',       // 성명
        user_phone: '',      // 전화번호
        user_code_chk: '',   // 인증번호
        user_company: '',    // 소속
        user_position: '',        // 직책
        user_id: '',         // 아이디
        user_pwd: '',        // 비밀번호
        user_pwd_chk: '',    // 비밀번호 확인
        broker_yn: "N",      // 중개사 여부
        card: {              // 명함
            file_name: '',
            file_data: '',
            contenttype: ''
        },                   // 사업자등록증
        business: {
            file_name: '',
            file_data: '',
            contenttype: ''
        },                   // 공제증서(중개사전용)
        deduction: {
            file_name: '',
            file_data: '',
            contenttype: ''
        },
        terms_agree_all: false,//모두 동의
        terms_age: false,    //만14세 이상 동의
        terms_service: false,//서비스 이용 동의
        terms_privacy: false,//개인정보 수집 및 이용 동의
        reg_no:'',           // 사업자등록번호
        use_flag: 'Y',

        // 중개사 회원가입 전용 전달 값
        user_tel: '',       // 일반전화번호(핸드폰 X)
        addr_no: '',        // 우편번호
        addr: '',           // 주소
        addr_dtl: '',       // 상세주소
        owner: '',          // 중개소 대표 (중개사 전용)
        chk_overlap: 0,      // 아이디 중복확인 : 0 - 확인안됨, 1 - 확인 및 중복안됨, 2 - 확인 및 중복됨
    },

    login : {
        input_id: '',
        input_pw: '',
    },

    changePwd: {
        newPwd: '',
        newPWd_check: ''
    },

    tempPwd: '',
    reg: null,
    error: null,
    regsuccess: false,
    setTimeStart: false,

    loginInfo: null,
    loginSuccess: '',

    cboAllowText: [],

    certSuccess: '',
    pwdChangeSuccess: '',
};

const auth = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {field, key, value}}) => {
            const ret = {
                ...state,
                [field]:{...state[field], [key]:value}
            };

            if( key === 'user_id' ) {
                ret.register.chk_overlap = 0;

                //아이디 대문자 입력시 소문자 변환
                if(/[A-Z]/.test(value)){
                    ret.register.user_id = value.toLowerCase();
                }
            }

            return ret;
        },

        [LOGIN_SUCCESS]: (state, {payload}) =>{

        return ({
            ...state,
            error: null,
            loginInfo: payload[0],
            loginSuccess: 'success',
        })}, 
        [LOGIN_FAILURE]: (state, {payload: {response: {data}}}) =>  {
        ALERT(`로그인 실패\n${data}`)
        return ({
            ...state,
            error: '로그인실패',
            loginInfo: null,
            loginSuccess: 'fail',
        })},
        [REGISTER_SUCCESS]: (state, { payload: auth }) => {
            return ({
                ...state,
                error: null,
                auth,
                regsuccess: true,
            })
        },
        [REGISTER_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error: '회원가입실패',
                regsuccess: false,
            })
        },
        [CHANGE_REGSUCCESS]: (state, {payload: {value}}) => {
            return ({
                ...state,
                regsuccess: value,
            })
        },
        [TEMPPWD_SUCCESS]: (state, {payload}) => {
            ALERT('임시 비밀번호가 문자메세지로 발급되었습니다.(제한시간 3분)');
            return ({
                ...state,
                tempPwd: payload,
                setTimeStart: true,
            })
        },
        [TEMPPWD_FAILURE]: (state, payload) => {
            ALERT(payload.payload.response.data);
            // ALERT('임시비밀번호 발급에 실패했습니다.');

            return ({
                ...state,
                error: '발급실패. 당첨자 핸드폰번호를 확인하세요.',
                setTimeStart: false,
            })
        },
        [SET_TIMESTART]: (state, {payload: {value}}) => {
            return ({
                ...state,
                setTimeStart: value
            })
        },
        [CHANGE_FILE]: (state, {payload: {name, file_name, file_data, contenttype}}) => {
            return ({
                ...state,
                error: null,
                register: {
                    ...state.register,
                    [name]: {
                        file_name,
                        file_data: file_data.split(',')[1],
                        contenttype
                    }
                }
            })
        },
        [CERT_REQUEST_SUCCESS]: (state, {payload}) => {
            ALERT('인증번호가 문자메세지로 발급되었습니다.(제한시간 3분)');
            return ({
                ...state,
                setTimeStart: true,
            })
        },
        [CERT_REQUEST_FAILURE]: (state, {payload}) => {
            ALERT('인증번호 발급에 실패했습니다.')
            return ({
                ...state,
                setTimeStart: false,
            })
        },
        [CERT_CHECK_SUCCESS]: (state, {payload}) => {
            ALERT("인증되었습니다.");
            return ({
                ...state,
                certSuccess: 'success',
            })
        },
        [CERT_CHECK_FAILURE]: (state, {payload}) => {
            ALERT("인증에 실패했습니다.");
            return ({
                ...state,
                certSuccess: 'fail',
            })
        },
        [PWD_CHANGE_SUCCESS]: (state, {payload}) => {
            ALERT('비밀번호 변경에 성공했습니다.')
            return ({
                ...state,
                pwdChangeSuccess: 'success',
            })
        },
        [PWD_CHANGE_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                pwdChangeSuccess: 'fail',
            })
        },
        [OVERLAP_CHECK_SUCCESS]: (state, {payload: isOverlap}) => ({
            ...state,
            register: {
                ...state.register,
                chk_overlap: isOverlap ? 2 : 1,
            },
            error: null,
        }),
        [OVERLAP_CHECK_FAILURE]: (state) => ({
            ...state,
            register: {
                ...state.register,
                chk_overlap: 0,
            },
            error: '아이디 중복확인 오류',
        }),

        [GET_COMBO_SUCCESS]: (state, {payload : {ALLOW_TEXT}}) => {
            return {
                ...state,
                error: null,
                cboAllowText: state.cboAllowText.length > 0 ? state.cboAllowText : ALLOW_TEXT,
            }
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error: '기본정보 가져오기 실패',
            }
        },
    },
    initialState,
);

export default auth;