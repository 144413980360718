export const dataSystemCode = {
    LEASE_TYPE : [
        { 
            sub_code: '',
            code_name: '선택',
        },
        { 
            sub_code: 'NEW',
            code_name: '신규',
        },
        { 
            sub_code: 'MOVE',
            code_name: '이주',
        },
        { 
            sub_code: 'INCREASE',
            code_name: '증액',
        },
        { 
            sub_code: 'SAME',
            code_name: '동일',
        },
        { 
            sub_code: 'DECREASE',
            code_name: '감액',
        },
    ],
    HOUSING_TYPE : [
        { 
            sub_code: 'TYPE01',
            code_name: '아파트',
        },
        { 
            sub_code: 'TYPE02',
            code_name: '단독주택',
        },
        { 
            sub_code: 'TYPE03',
            code_name: '다가구주택',
        },
        { 
            sub_code: 'TYPE04',
            code_name: '다중주택',
        },
        { 
            sub_code: 'TYPE05',
            code_name: '다세대주택',
        },
        { 
            sub_code: 'TYPE06',
            code_name: '연립주택',
        },
        { 
            sub_code: 'TYPE07',
            code_name: '오피스텔',
        },
        { 
            sub_code: 'TYPE08',
            code_name: '도시형생활주택',
        },
        { 
            sub_code: 'TYPE09',
            code_name: '근생 및 다가구',
        },
        { 
            sub_code: 'TYPE10',
            code_name: '근생 및 단독',
        },
        { 
            sub_code: 'TYPE11',
            code_name: '다가구용단독',
        },
        { 
            sub_code: 'TYPE12',
            code_name: '협동주택',
        },
        { 
            sub_code: 'TYPE13',
            code_name: '점포주택등복합용도주택',
        },
    ],
    CRITERIA_PRICING : [
        { 
            sub_code: 'TYPE01',
            code_name: '주택공시가',
            price_rate: 1.7,
        },
        { 
            sub_code: 'TYPE02',
            code_name: '개별공시지가',
            price_rate: 1.7,
        },
        { 
            sub_code: 'TYPE03',
            code_name: 'KB시세',
            price_rate: 1.0,
        },
        { 
            sub_code: 'TYPE04',
            code_name: '가격조사',
            price_rate: 1.0,
        },
        { 
            sub_code: 'TYPE05',
            code_name: '오피스텔 기준시가',
            price_rate: 1.7,
        },
        { 
            sub_code: 'TYPE06',
            code_name: '거래가액',
            price_rate: 1.0,
        },
    ],
    RENT_TYPE : [
        { 
            sub_code: 'TYPE01',
            code_name: '전세',
        },
        { 
            sub_code: 'TYPE02',
            code_name: '보증부월세',
        },
    ],
    EMPTY_TYPE : [
        { 
            sub_code: 'HOUSE_RENT',
            code_name: '[주택] 임대중',
        },
        { 
            sub_code: 'HOUSE_WANT',
            code_name: '[주택] 입주희망',
        },
        { 
            sub_code: 'HOUSE_OWNER',
            code_name: '[주택] 주인거주',
        },
        { 
            sub_code: 'HOUSE_EMPTY',
            code_name: '[주택] 공실',
        },
        { 
            sub_code: 'STORE_RENT',
            code_name: '[상가] 임대중',
        },
        { 
            sub_code: 'STORE_OWNER',
            code_name: '[상가] 주인사용',
        },
        { 
            sub_code: 'STORE_EMPTY',
            code_name: '[상가] 공실',
        },
        { 
            sub_code: 'ETC_RENT',
            code_name: '[기타] 임대중',
        },
        { 
            sub_code: 'ETC_OWNER',
            code_name: '[기타] 주인사용',
        },
        { 
            sub_code: 'ETC_EMPTY',
            code_name: '[기타] 공실',
        },
    ],
    STATUS : [
        { 
            sub_code: 'ENWRITE',
            code_name: '작성중',
            memo: '작성중'
        },
        { 
            sub_code: 'ENSAVE',
            code_name: '신청서확인',
            memo: '신청서확인'
        },
        { 
            sub_code: 'ENREQ',
            code_name: '접수대기',
            memo: '접수대기'
        },
        { 
            sub_code: 'ENACK',
            code_name: '접수',
            memo: '처리중'
        },
        { 
            sub_code: 'ENCHGREQ',
            code_name: '담당자변경',
            memo: '처리중'
        },
        { 
            sub_code: 'ENDOCFAIL',
            code_name: '서류미비',
            memo: '서류미비'
        },
        { 
            sub_code: 'ENDOCADD',
            code_name: '서류보완',
            memo: '서류보완'
        },
        { 
            sub_code: 'ENPROCESS',
            code_name: '권리분석처리',
            memo: '처리중'
        },
        { 
            sub_code: 'ENAPVREQ',
            code_name: '결재요청',
            memo: '처리중'
        },
        { 
            sub_code: 'ENAPVFAIL',
            code_name: '결재반려',
            memo: '처리중'
        },
        { 
            sub_code: 'ENAPVOK',
            code_name: '결재완료',
            memo: '처리중'
        },
        { 
            sub_code: 'ENAPVCHK',
            code_name: '결재확인승인',
            memo: '분석완료'
        },
        { 
            sub_code: 'ENAPVCHKFAIL',
            code_name: '결재확인반려',
            memo: '처리중'
        },
        { 
            sub_code: 'CONTSTRT',
            code_name: '계약시작',
            memo: '계약시작'
        },
        { 
            sub_code: 'CONTOK',
            code_name: '계약완료',
            memo: '계약완료'
        },
        { 
            sub_code: 'CONTFAIL',
            code_name: '계약취소',
            memo: '계약취소'
        },
        { 
            sub_code: 'ENCANCEL',
            code_name: '권리분석취소',
            memo: '취소'
        },
        { 
            sub_code: 'CONFIRM',
            code_name: 'LH확인',
            memo: 'LH확인'
        },
        { 
            sub_code: 'FIELDCHK',
            code_name: '현장조사',
            memo: '현장조사'
        },
        { 
            sub_code: 'NONCONF',
            code_name: '부적합만료',
            memo: '부적합만료'
        },
        { 
            sub_code: 'ENMODIFY',
            code_name: '검토서 변경요청',
            memo: '처리중'
        },
    ],
    USE_YN : [
        { 
            sub_code: 'Y',
            code_name: 'Y',
            string_name: '예 / 아니오',
        },
        { 
            sub_code: 'N',
            code_name: 'N',
            string_name: '불가',
        },
    ],
    CYCLE_TYPE : [
        { 
            sub_code: 'WEEK',
            code_name: '매주',
        },
        { 
            sub_code: 'MONTH',
            code_name: '매월',
        },
    ],
}