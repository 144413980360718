import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import WinnerRegisterWorkContainer from '../containers/WinnerRegisterWorkContainer';

const WinnerRegisterWorkPage = () => {

    return (
        <>
            <HeaderContainer />
            <WinnerRegisterWorkContainer />
        </>
    );
}

export default WinnerRegisterWorkPage;