import { combineReducers } from "redux";
import { all } from 'redux-saga/effects';
import loading from './loading';
import auth, { authSaga } from './auth';
import list, { listSaga } from "./list";
import entitleWrite, { entitleWriteSaga } from "./entitleWrite";
import entitleWork, { entitleWorkSaga } from "./entitleWork";
import header from "./header";
import docMgt, { docMgtSaga } from "./docMgt";
import applyDocMgt, { applyDocMgtSaga } from "./applyDocMgt";
import menuAuth, { menuAuthSaga } from "./menuAuth";
import codeMgt, { codeMgtSaga } from "./codeMgt";
import applyMgt, { applyMgtSaga } from "./applyMgt";
import userMgt, { userMgtSaga } from "./userMgt";
import main, { mainSaga } from "./main";
import docSort, { docSortSaga } from "./docSort";
import WinnerWork, { WinnerWorkSaga } from "./WinnerWork";
import user, { userSaga } from "./user";
import findUser, { findUserSaga } from "./Modal/findUser";
import enWriteAgree, { enWriteAgreeSaga } from "./Modal/enWriteAgree";
import enWriteAdmin, { enWriteAdminSaga } from "./enWriteAdmin";
import findBroker, { findBrokerSaga } from "./Modal/findBroker";
import nextMaintain, { nextMaintainSaga } from "./nextMaintain";
import enDocUpload, { enDocUploadSaga } from "./Modal/enDocUpload";
import enwAdminUpload, { enwAdminUploadSaga } from "./Modal/enwAdminUpload";
import enDocFail, { enDocFailSaga } from "./Modal/enDocFail";
import contract, { contractSaga } from "./contract";
import contractProc, { contractProcSaga } from "./contractProc";
import coDocUpload, { coDocUploadSaga } from "./Modal/coDocUpload";
import enApprove, { enApproveSaga } from "./Modal/enApprove";
import Imply, { ImplySaga } from "./Imply";
import feedback, { feedbackSaga } from "./Modal/feedback";
import lhConfirm, { lhConfirmSaga } from "./Modal/lhConfirm";
import enCallerMemo, { enCallerMemoSaga } from "./Modal/enCallerMemo";
import enPriority, { enPrioritySaga } from "./Modal/enPriority";
import enChangeReq, { enChangeReqSaga } from "./Modal/enChangeReq";
import customer, { customerSaga } from "./customer";
import entitleViewer, { entitleViewerSaga } from "./entitleViewer";
import audit, { auditSaga } from "./audit";
import maintainRegister, { maintainRegisterSaga } from "./maintainRegister";
import uploadList, { uploadListSaga } from "./uploadList";
import chargeFee, { chargeFeeSaga } from "./chargeFee";
import fieldChk, { fieldChkSaga } from "./Modal/fieldChk";
import reportList, { reportListSaga } from "./reportList";
import printSort, { printSortSaga } from "./printSort";
import createBroker, { createBrokerSaga } from "./Modal/createBroker";
import optionMgt, { optionMgtSaga } from "./optionMgt";
import dobaeuserlist, { dobaeuserlistSaga } from "./dobaeuserlist";

const rootReducer = combineReducers({
    loading,
    auth,
    entitleWrite,
    entitleWork,
    list,
    header,
    codeMgt,
    docMgt,
    menuAuth,
    userMgt,
    main,
    docSort,
    WinnerWork,
    user,
    findUser,
    enWriteAgree,
    enWriteAdmin,
    findBroker,
    nextMaintain,
    enDocUpload,
    enwAdminUpload,
    contract,
    coDocUpload,
    enDocFail,
    enApprove,
    Imply,
    feedback,
    lhConfirm,
    enCallerMemo,
    applyMgt,
    applyDocMgt,
    enPriority,
    customer,
    entitleViewer,
    enChangeReq,
    audit,
    maintainRegister,
    uploadList,
    chargeFee,
    fieldChk,
    reportList,
    printSort,
    createBroker,
    contractProc,
    optionMgt,
    dobaeuserlist,
});

export function* rootSaga() {
    yield all([
        authSaga(),
        menuAuthSaga(),
        codeMgtSaga(),
        docMgtSaga(),
        userMgtSaga(),
        entitleWriteSaga(),
        entitleWorkSaga(),
        userSaga(),
        findUserSaga(),
        enWriteAgreeSaga(),
        enWriteAdminSaga(),
        findBrokerSaga(),
        WinnerWorkSaga(),
        nextMaintainSaga(),
        enDocUploadSaga(),
        enwAdminUploadSaga(),
        contractSaga(),
        coDocUploadSaga(),
        docSortSaga(),
        listSaga(),
        enDocFailSaga(),
        mainSaga(),
        enApproveSaga(),
        ImplySaga(),
        feedbackSaga(),
        lhConfirmSaga(),
        enCallerMemoSaga(),
        applyMgtSaga(),
        applyDocMgtSaga(),
        enPrioritySaga(),
        customerSaga(),
        entitleViewerSaga(),
        enChangeReqSaga(),
        auditSaga(),
        maintainRegisterSaga(),
        uploadListSaga(),
        chargeFeeSaga(),
        fieldChkSaga(),
        reportListSaga(),
        printSortSaga(),
        createBrokerSaga(),
        contractProcSaga(),
        optionMgtSaga(),
        dobaeuserlistSaga(),
    ]);
}

export default rootReducer;