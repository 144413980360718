import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as entitleAPI from '../lib/api/entitle';

const INITIALIZE = 'dobaeuserlist/INITIALIZE';
const CHANGE_VALUE = 'dobaeuserlist/CHANGE_VALUE';
const INIT_SEARCH_VALUE = 'dobaeuserlist/INIT_SEARCH_VALUE';
const [SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE] = createRequestActionTypes('dobaeuserlist/SEARCH');

export const dobaeuserlistInitialize = createAction(INITIALIZE);
export const dobaeuserlistChangeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const dobaeuserlistInitSearchValue = createAction(INIT_SEARCH_VALUE);
export const dobaeuserlistSearch = createAction(SEARCH, data => data);

const dobaeuserlistSearchSaga = createRequestSaga(SEARCH, entitleAPI.search_byCust);

export function* dobaeuserlistSaga() {
    yield takeLatest(SEARCH, dobaeuserlistSearchSaga);
}

const initialState = {
    cust_name: '',
    birth: '',
    dobaeuserlist: null,
    error: null
};

const dobaeuserlist = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [INIT_SEARCH_VALUE]: (state) => ({
            ...state,
            cust_name: '',
            birth: '',
        }),
        [SEARCH_SUCCESS]: (state, { payload: dobaeuserlist}) => ({
            ...state,
            dobaeuserlist,
            error: null,
        }),
        [SEARCH_FAILURE]: (state, { payload: error}) => ({
            ...state,
            dobaeuserlist: null,
            error,
        }),
    },
    initialState,
);

export default dobaeuserlist;