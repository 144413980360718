import React from 'react';
import styled from 'styled-components';

const DocSortBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 40px;

    h2{
        color: #494949;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .typeBtn{
        margin-bottom: 40px;
    }
    .typeBtn button {
        position: relative;
        width: 211px;
        height: 42px;
        color: #ffffff;
        font-size: 14px;
        margin: 0 10px 10px 0;
        border: none;
        border-radius: 21px;
        background-color: #BDBDBD;
        cursor: pointer;
    }
    .typeBtn button span{
        position: absolute;
        top: 6px;
        right: 6px;
        width: 30px;
        height: 31px;
        color: #BDBDBD;
        font-size: 16px;
        display: inline-block;
        padding-top: 5px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #ffffff;
    }
    .typeBtn .active{
        background-color: #1EBF86;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    }
    .typeBtn .active span{
        color: #95BC07;
    }

    .imgCntr {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 45px;
    }
    .imgCntr > div:not(.name){
        position: relative;
        width: 18.2%;
        height: 300px;
        margin: 0 24px 34px 0;
        border-radius: 5px;
        border: 1px solid #BDBDBD;
    }
    .imgCntr .img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        cursor: pointer;
    }
    .imgCntr .imgJson { 
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        word-break:break-all;
        background-color: #ffffff;
    }

    .imgCntr input {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 10px;
        top: 10px;
    }

    .imgCntr > div > .expand{
        position: absolute;
        left: 10px;
        bottom: 10px;
        width: 30px;
        height: 30px;
        border: 1px solid black;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
    }

    .name {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #32323275;
        //background-color: rgba(30, 191, 134, 0.2);
        border-radius: 5px;
        border: 2px solid #0A989D;
        cursor: pointer;
    }

    .seq {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 25px;
        color: #ffffff;
    }

    .type {
        position: absolute;
        top: 20px;
        left: 10px;
        color: #181818;
    }

    .none {
        color: red !important;
    }

    .on {
        display: flex;
    }

    .off {
        display: none;
    }

    .docModal {
        position: fixed;
        top: 20px;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .docModal .bg{
        width: 100%;
        height: 100%;
        background-color: #00000070;
        cursor: pointer;
    }

    .docModal .body{
        position: absolute;
        width: 60%;
        height: 90%;
        background-color: white;
    }
    .docModal .body .imgBody{
        overflow: scroll;
        height: 90%;
    }

    .docModal .body > div{
        // width: 100%;
        // height: 100%;
    }

    .docModal .body .imgBox{
        width: 100%;
        height: 80%;
        margin-bottom: 20px;
    }

    .docModal .body .imgBox img{
        width: 100%;
        height: 100%;
    }
    .close-btn{
        font-size: 24px;
        display: block;
        text-align: right;
        margin: 4px -20px 10px 0;
        cursor: pointer;
    }

  
`;

const DocSort = (props) => {
    const {
        docNo,
        cboDoc,
        docData,
        curDocType,
        isModal,
        selectedImgName,
        // docData_Copy,
        splitArr,

        openExpand,
        closeExpand,
        onDocBtn,
        onChange,
        onInit,
        // setIsModal,
        getCount,
        onSave,
        onEntitle,
        showImg,
        showType
    } = props

    return (
        <DocSortBlock>
            <p>문서번호 : {docNo}</p>
            <div>
                <h2>서류정렬 및 구분</h2>
            </div>
            <div className='typeBtn'>
                {cboDoc.map((item, idx) => {
                    if(item.sub_code && item.use_flag === "Y") {
                        return (
                            <button 
                                className={item.sub_code === curDocType? 'active' : ''} 
                                onClick={() => onDocBtn(item.sub_code)} 
                                key={idx}>
                                {item.code_name}
                                    <span>
                                        {getCount(item.sub_code)}
                                    </span>
                                </button>
                        )
                    }
                    return null;
                })}
                <button className={curDocType === "DOCUDF"? 'active' : ''} onClick={() => onDocBtn('DOCUDF')}>휴지통<span>{getCount("DOCUDF")}</span></button>
            {curDocType === "DOCUDF"? <p>*휴지통에 있는 이미지는 작업화면에서 볼 수 없습니다.</p> : null}
            </div>

            {/* 이미지 영역 */}
            <div className='imgCntr'>
                {docData.length > 0? 
                <>
                    {docData.map((doc,docIdx) => {
                        if(curDocType === doc.file_type) {
                            return (
                                <React.Fragment key={docIdx}>
                                    {
                                        doc.files.map((item, itemIdx) => {
                                            return (
                                                <div key={`${docIdx}${itemIdx}`}>
                                                        <label htmlFor={`img${docIdx}${itemIdx}`}>
                                                            {showImg(item)}
                                                            <div className={curDocType === doc.file_type? 'name on' : 'name off'}><p>{item.file_name}</p></div>
                                                            <div className={curDocType === doc.file_type? 'seq on' : 'seq off'}>{itemIdx + 1}</div>
                                                            {showType(doc)}

                                                        </label>
                                                        <input id={`img${docIdx}${itemIdx}`} type="checkbox" checked={curDocType === doc.file_type} onChange={(e) => onChange(e,curDocType,doc.file_type,itemIdx)}/>
                                                        <div className='expand' onClick={() => openExpand(item)}>확대</div>
                                                    </div>
                                                )
                                            })
                                    }
                                </React.Fragment>
                            )
                        }
                        return null;
                    })}
                </> :  null}
                {docData.map((doc,docIdx) => {
                    if(curDocType !== doc.file_type) {
                        return (
                            <React.Fragment key={docIdx}>
                                {
                                    doc.files.map((item, itemIdx) => {
                                        return (
                                            <div key={`${docIdx}${itemIdx}`}>
                                                    <label htmlFor={`img${docIdx}${itemIdx}`}>
                                                        {showImg(item)}
                                                        <div className={curDocType === doc.file_type? 'name on' : 'name off'}><p>{item.file_name}</p></div>
                                                        <div className={curDocType === doc.file_type? 'seq on' : 'seq off'}>{itemIdx + 1}</div>                                 {showType(doc)}
                                                        {showType(doc)}
                                                    </label>
                                                    <input id={`img${docIdx}${itemIdx}`} type="checkbox" checked={curDocType === doc.file_type} onChange={(e) => onChange(e,curDocType,doc.file_type,itemIdx)}/>
                                                    <div className='expand' onClick={() => openExpand(item)}>확대</div>
                                                </div>
                                            )
                                        })
                                }
                            </React.Fragment>
                        )
                    }
                    return null;
                })}
            </div>

            <div className='doc-btn-group'>
                <button onClick={onInit}>분류 초기화</button>
                <button onClick={onSave}>분류 저장</button>
                <button onClick={onEntitle}>작업진행하기</button>
            </div>

            {isModal? 
            <>
                <div className='docModal'>
                    <div className='body'>
                        <span onClick={closeExpand} className="close-btn">X</span>
                        <div className='imgBody'>
                            {splitArr.type === 'arr'? 
                            <>
                                {splitArr.data.map((item, idx) => {
                                    return (
                                        <div key={idx}>
                                            <div className='imgBox'>
                                                <img src={item} alt={'img'}/>
                                            </div>
                                            <h3>{selectedImgName}</h3>
                                        </div>
        
                                    )
                                })}
                            </>
                            :
                            <>
                                <div>
                                    <div className='imgBox'>
                                        <div>{splitArr.data}</div>
                                    </div>
                                    <h3>{selectedImgName}</h3>
                                </div>
                            </>}
                        </div>
                    </div>
                    <div className='bg' onClick={closeExpand}/>
                </div>
            </> 
            : null}
        </DocSortBlock>
    );
}

export default DocSort;