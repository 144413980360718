import React from 'react';
import styled from 'styled-components';
import EnWriteAgreeContainer from '../containers/Modal/EnWriteAgreeContainer';

const EntitleWriteStep4Block = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 50px;
    box-sizing: border-box;
    position: relative;

    .title h2{
        color: #545454;
        font-size: 18px;
    }
    .tblFiles {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .tblFiles > div {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding: 10px;
        border-bottom: 1px solid #BDBDBD;
    }
    .ctrlFiles > div {
        margin-bottom: 10px;
        text-align: right;
    }
    .ctrlFiles > div:last-child {
        margin-bottom: 0;
    }
    .ctrlFiles button {
        margin-left: 10px;
    }
    .tblFiles label{
        margin-left: 14px;
    }
    .add-btn{
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        padding-top: 2px;
        box-sizing: border-box;
    }

    .lblInputFiles {
        margin: 0 10px;
        border-radius: 4px;
        cursor: pointer;
    }
    .inputFiles {
        display: none;
    }

    .docFailRemark {
        margin-bottom: 25px;
    }
    .docFailRemark h2 {
        margin-bottom: 0;
        color: red;
    }
    .docFailRemark pre {
        font-size: 15px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif;

    }
    .StepButtons{
        display: flex;
        justify-content: center;
        margin-top:30px;
    }
    .StepButtons button{
        margin: 0 10px;
    }
    .agree-btn{
        width: 88px; 
        height: 34px; 
        color: #1EBF86; 
        font-size: 14px; 
        font-weight: 600; 
        display: flex; 
        align-items: center; 
        justify-content: center; 
        border-radius: 3px; 
        border: 1px solid #1EBF86; 
        background-color: #ffffff; 
        cursor: pointer;
    }

    .agree-btn:hover{
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        color: #fff;
    }
    .text-center{
        text-align: center;
    }
    .step4-text h2{
        color: #0B999C;
        font-size: 18px;
    }
    .text-h3{
        color: #545454;
        font-size: 16px;
    }
    
    .docFailRemark pre{
        white-space:normal;
        margin-top:10px
    }
    
    @media screen and (min-width: 360px) and (max-width: 1023px) {
        padding: 20px;
        .title h2{
            font-size: 14px !important;
        }
        .tblFiles > div{
            font-size: 14px;
            flex-wrap: wrap;
        }
        .step4-text h2{
            font-size: 13px;
        }
        .text-h3{
            font-size: 11px;
        }
        .mm{
            margin-bottom: 160px;
        }

        .docFailRemark{
            margin-bottom: 80px;
        }

        .docFailRemark pre{
            width: 100%;
            white-space:normal;
            margin-top:10px
        }
        .StepButtons{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            margin-top:160px;
        }
        .StepButtons button{
            width: 50%;
            font-size: 16px;
            margin: 0px !important;
        }
        .prev-btn, .cancel-btn{
            width: 50%; 
            height: 48px; 
            color: #ffffff; 
            font-size: 14px; 
            font-weight: 600; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 0px; 
            border: none; 
            background-color: #808080; 
        }
        .prev-btn::before{
            content:"<"; 
            width: 5px; 
            height: 10px; 
            color: #ffffff; 
            display: inline-block; 
            align-items: center; 
            margin: -4px 8px 0 0;
        }
        .agree-btn{ 
            width: 50%;
            height: 48px; 
            color: #ffffff; 
            font-size: 14px; 
            font-weight: 600; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 0px; 
            border: none;
            background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        }
       

        
    }
    fieldset {
        border: none;
    }
`;

const EntitleWriteStep4 = (props) => {
    const {
        doc_id,
        doc_status,

        createUser,
        user,
        func,

        winner,

        doc_type,
        fileList,
        lackReason,

        isShowUserAgree,

        cantModify,
        
        onFileChange,
        // onAddFile,
        onRemoveFile,
        onPrevClick,
        onFileAddClick,
        onShowUserAgree,
        onCloseUserAgree,
        onDeleteWrite,
        // onModifyMode,
    } = props;

    const propDatas = {
        doc_id,
        winner,
        func,
        onCloseUserAgree,
    }

    let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
    today = today.replace('-', '년 ').replace('-', '월 ') + '일';

    return (
        <EntitleWriteStep4Block>
            <fieldset disabled={(doc_status === "ENSAVE" || doc_status === "ENDOCFAIL") ? false : cantModify}>
                <div className='title'>
                    <h2>제출서류</h2>
                    {
                        (
                            doc_status !== 'ENWRITE' &&
                            doc_status !== 'ENSAVE' &&
                            doc_status !== 'ENDOCFAIL'
                        ) &&
                        <label style={{color:'red'}}>이미 처리중이므로 파일을 추가할 수 없습니다.</label>
                    }
                </div>
                <div className='tblFiles'>
                    {doc_type.map((doc, docIdx) => (
                        <div key={docIdx}>
                            <div>
                                <div>
                                    {doc.code_name} {doc.code_memo}
                                    {
                                        (
                                            !doc_status ||
                                            doc_status === 'ENWRITE' ||
                                            doc_status === 'ENSAVE' ||
                                            doc_status === 'ENDOCFAIL'
                                        ) &&
                                        <>
                                            <label className='lblInputFiles add-btn' htmlFor={"inputFiles" + docIdx}>+추가</label>
                                            <input type="file" id={"inputFiles" + docIdx} className='inputFiles' onChange={onFileChange.bind(this, doc)} accept=".jpg,.jpeg,.png,.pdf" multiple/>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='ctrlFiles'>
                                {fileList.map((files, idx) => (
                                    doc.sub_code === files.file_type && files.use_flag === "Y" &&
                                    <div key={idx}>
                                        {files.file_name}
                                        {
                                            files.file_id ?                                                         // 기등록된 파일은
                                            (
                                                createUser === user.user_id &&                                      // 신청서 작성자가 본인이라면
                                                (!doc_status || doc_status === 'ENWRITE' || doc_status === 'ENSAVE') // 최초, 작성중, 신청서확인 상태에서 항상 삭제가 가능하다.
                                                &&
                                                <button onClick={onRemoveFile.bind(this, files)} className="del-btn">삭제</button>
                                            )
                                            :
                                            <button onClick={onRemoveFile.bind(this, files)} className="del-btn">삭제</button>  //  신규 등록 파일은 항상 삭제가 가능하다.
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </fieldset>

            {
                doc_status === "ENDOCFAIL" && 
                <>
                    <div className='docFailRemark'>
                        <h2>서류미비 사유</h2>
                        <pre>{lackReason}</pre>
                    </div>

                    <hr/>
                </>
            }

            {(!doc_status || doc_status === 'ENWRITE') &&
                //  최초 또는 작성중 또는 접수대기 상태에서만 저장됨.
                <>
                    <div className='step4-text text-center'>
                        <h2>유의사항</h2>
                        권리조사 종료전 가계약을 할 경우 권리조사 과정에서 지원불가 주택으로 판명시 가계약금 등 모든 비용은 입주자 본인이 부담하게 됨
                    </div>

                    <h3 className='text-h3 text-center'>위와 같이 유의사항을 확인하고 전세임대주택 공급을 신청합니다.</h3>
                    <h3 className='text-h3 text-center mm'>{today}</h3>
                </>
            }

            <div className='StepButtons'>
                {   //  작성중 상태에서만 삭제가 가능.
                    doc_status === 'ENWRITE' && <button onClick={onDeleteWrite} className="cancel-btn">신청서 삭제</button>
                }
                {   //  신청서확인 상태에서만 취소가 가능. 
                    doc_status === 'ENSAVE' && <button onClick={onDeleteWrite} className="cancel-btn">신청서 취소</button>
                }
                {/* {(cantModify && doc_status === 'ENREQ') && <button onClick={onModifyMode} className="save-btn">내용 수정</button>} */}
                <button onClick={onPrevClick} className="prev-btn">이전 단계</button>

                {(!doc_status || doc_status === 'ENWRITE' || doc_status === 'ENSAVE') &&
                    //  최초 또는 작성중 또는 신청서확인 상태에서만 저장됨.
                    <button onClick={onShowUserAgree} className="agree-btn">
                        {doc_status === 'ENWRITE' && '동의 및 신청'}
                        {doc_status === 'ENSAVE' && '서류 추가'}
                    </button>
                }

                {
                    doc_status === 'ENDOCFAIL' &&
                    <button style={{minWidth: '100px'}} className="agree-btn" onClick={onFileAddClick}>서류보완 저장</button>
                }
            </div>

            {isShowUserAgree && <EnWriteAgreeContainer {...propDatas}/>}
        </EntitleWriteStep4Block>
    );
}

export default EntitleWriteStep4;