import React from 'react';
import styled from 'styled-components';

const ImplyAuditRequestListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 30px 50px;
    box-sizing: border-box;
    
    h1{
        color: #545454;
        font-size: 24px;
        margin: 0 0 20px 0;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }`
;

const ImplyAuditRequestList = (props) => {
    const {
        implyApproveSearch,
        implyApproveList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,

        onSubmit,
        onChangeSearch,
        onClickNum,
        onClickNext,
        onClickPrev,
        onClickList,
    } = props;
    return (
        <ImplyAuditRequestListBlock>
            <h1>접속되는 리스트</h1>
            <h2>검색조건</h2>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='searchipt'>
                            <div className='st'>
                                <label htmlFor='date_type'>날짜</label>
                                <input name='start_date' id='start_date' value={implyApproveSearch.start_date || ''} onChange={onChangeSearch} type='date'/>
                                <span>~</span>
                                <input name='end_date' id='end_date' value={implyApproveSearch.end_date || ''} onChange={onChangeSearch} type='date'/>
                            </div>
                            <div className='st'>
                                <label htmlFor='status'>진행상태</label>
                                <select name='status' id='status' value={implyApproveSearch.status} onChange={onChangeSearch}>
                                    <option value=''>전체</option>
                                    <option value='N'>결재요청</option>
                                    <option value='P'>결재진행중</option>
                                    <option value='Y'>결재완료</option>
                                </select>
                            </div>
                            <div className='st'>
                                <label htmlFor='req_user'>요청자이름</label>
                                <input name='req_user' id='req_user' value={implyApproveSearch.req_user || ''} onChange={onChangeSearch} type='text' className='input-user'/>
                            </div>
                        </div>

                        <div className='btn'>
                            <button className='green-sch-btn'>검색</button>
                        </div>
                    </div>
                </form>
            </div>
            <h2>검색결과</h2>
            <div className='resultTbl'>
                <table className='tt'>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>요청ID</th>
                            <th>요청건수</th>
                            <th>요청일시</th>
                            <th>요청자</th>
                            <th>요청자ID</th>
                            <th>요청인원수</th>
                            <th>진행상태</th>
                            <th>묵시 OK</th>
                            <th>묵시 NG</th>
                        </tr>
                    </thead>
                    <tbody>
                        {implyApproveList.length > 0? 
                        <>
                        {
                            implyApproveList.map((item, idx) => {
                                return (
                                    <tr key={idx} onClick={() => onClickList(item)}>
                                        <td>{(maxPageCount * (currentPage - 1)) + idx+1}</td>
                                        <td>{item.app_id}</td>
                                        <td>{item.req_count}</td>
                                        <td>{item.request_date}</td>
                                        <td>{item.req_user_name}</td>
                                        <td>{item.request_user}</td>
                                        <td>{item.people_count}</td>
                                        <td>{item.status}</td>
                                        <td>{item.ok_count}</td>
                                        <td>{item.ng_count}</td>
                                    </tr>
                                )
                            })
                        }
                        </>
                        : null}
                    </tbody>
                </table>
            </div>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
        </ImplyAuditRequestListBlock>
    );
}

export default ImplyAuditRequestList;