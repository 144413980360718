import React from 'react';
import styled from 'styled-components';
import { regAmt } from '../common';
import dataWORD from '../lib/dataWord';

const ImplyAuditRequestBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    
    #excelData {
        display: none
    }
`;

const ImplyAuditRequest = (props) => {
    const {
        // implySearch,
        impWorkList,
        // auditArr,
        // auditStr,

        // onSubmit,
        // onChange,
        onRequest,
        // onCheck,
        // onCheckAll,
        handleClickXLSX,
    } = props

    return (
        <ImplyAuditRequestBlock>
            <h3>등초본요청하기</h3>
            <button onClick={handleClickXLSX}>엑셀 다운로드</button>
            <button onClick={onRequest}>등초본 일괄 요청</button>

            {/* <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='searchipt'>
                            <div className='st'>
                                <label htmlFor='date_type'>날짜유형</label>
                                <select name='date_type' id='date_type' value={implySearch.date_type} onChange={onChange}>
                                    <option value={'REC_DATE '}>신청일자</option>
                                    <option value={'CONTRACT_END_DATE'}>계약종료일</option>
                                </select>
                                <input type='date' name='from_date' id='from_date' value={implySearch.from_date} onChange={onChange}/>
                                <span>~</span>
                                <input type='date' name='to_date' id='to_date' value={implySearch.to_date} onChange={onChange}/>
                            </div>
                            <div className='st'>
                                <label htmlFor='addr'>주소</label>
                                <input type='text' name='addr' id='addr' value={implySearch.addr} onChange={onChange}/>
                            </div>
                            <div className='st'>
                                <label htmlFor='name'>이름</label>
                                <input type='text' name='name' id='name' value={implySearch.name} onChange={onChange}/>
                            </div>
                        </div>

                        <div className='btn'>
                            <button className='green-sch-btn'>검색</button>
                        </div>
                    </div>
                </form>
            </div> */}
                
            <h4>요청할 데이터 미리보기</h4>
            <table className='tt' id='requestData'>
                <thead>
                    <tr>
                        {/* <th><input type={'checkbox'} checked={impWorkList.length === auditArr.length && impWorkList.length > 0} onChange={onCheckAll} id='checkAll'/><label htmlFor='checkAll'></label></th> */}
                        <th>번호</th>
                        <th>유형</th>
                        <th>입주자명</th>
                        <th>{dataWORD.BIRTHDATE}</th>
                        <th>전화번호</th>
                        <th>임대인</th>
                        <th>지원주택소재지</th>
                        <th>계약종료일</th>
                        <th>전세금</th>
                    </tr>
                </thead>
                <tbody>
                {impWorkList.length > 0?
                    <>
                    {impWorkList.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                {/* <td><input type={'checkbox'} checked={auditStr.some((el) => el === item.imply_id)} onChange={(e) => onCheck(e, item)} id='check'/><label htmlFor='check'></label></td> */}
                                <th>{idx + 1}</th>
                                <td className='t1'>{item.type_name || "-"}</td>
                                <td>{item.imply_name|| "-"}</td>
                                <td>{item.imply_no? `${item.imply_no.slice(0,6)}-${item.imply_no.slice(6)}` : "-"}</td>
                                <td>{item.phone? `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}` : "-"}</td>
                                <td>{item.house_owner|| "-"}</td>
                                <td>{item.addr|| "-"}</td>
                                <td>{item.contract_end_date|| "-"}</td>
                                <td>{item.rent_amt.replace(regAmt, ",")|| "-"} 원</td>
                            </tr>
                        )
                    })}
                    </>
                    :
                    <tr>
                        <td colSpan={'10'}>결과가 없습니다.</td>
                    </tr>
                }
                </tbody>
            </table>

            {/* 엑셀 추출용 체크박스 없는 테이블 (display: none) */}
            <table className='tt' id='excelData'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>유형</th>
                        <th>입주자명</th>
                        <th>{dataWORD.BIRTHDATE}</th>
                        <th>전화번호</th>
                        <th>임대인</th>
                        <th>지원주택소재지</th>
                        <th>계약종료일</th>
                        <th>전세금</th>
                    </tr>
                </thead>
                <tbody>
                {impWorkList.length > 0?
                    <>
                    {impWorkList.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <th>{idx + 1}</th>
                                <td className='t1'>{item.type_name || "-"}</td>
                                <td>{item.imply_name|| "-"}</td>
                                <td>{item.imply_no? `${item.imply_no.slice(0,6)}-${item.imply_no.slice(6)}` : "-"}</td>
                                <td>{item.phone? `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}` : "-"}</td>
                                <td>{item.house_owner|| "-"}</td>
                                <td>{item.addr|| "-"}</td>
                                <td>{item.contract_end_date|| "-"}</td>
                                <td>{item.rent_amt.replace(regAmt, ",")|| "-"} </td>
                            </tr>
                        )
                    })}
                    </>
                    :
                    null
                }
                </tbody>
            </table>
        </ImplyAuditRequestBlock>
    );
}

export default ImplyAuditRequest;