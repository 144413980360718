import React from 'react';
import ImplyAuditRequestListContainer from '../containers/ImplyAuditRequestListContainer';
import HeaderContainer from '../containers/HeaderContainer';

const ImplyAuditRequestListPage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyAuditRequestListContainer />
        </>
    );
}

export default ImplyAuditRequestListPage;