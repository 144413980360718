import React from 'react';
import styled from 'styled-components';
import { setComma } from '../common';
import dataWORD from '../lib/dataWord';

const ImplyRegisterWorkBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    .final-btn{
        position: absolute;
        top: 0px;
        right: 0;
    }

    table {
        font-size: 15px;
        white-space: nowrap;
        text-align: center;
    }
    .tt{
        table-layout : auto;
    }

    table th, table td{
        padding: 0 5px;
    }

.implyModal .body > div > div{
    display: flex;
    flex-direction: column;
}

.displayNone {
    display: none !important;
}

.readOnly {
    background-color: #d7d7d7;
}
    .implyModal .body > h4 {
        margin:10px 0 2px 0;
    }

    .implyModal .body > div > div{
        display: flex;
        flex-direction: column;
    }
`;


const ImplyRegisterWork = (props) => {
    const {
        isShowModal,
        cboApply,
        // search,
        // maxPageCount,
        pageCount,
        max,
        step,
        currentPage,
        ImplyList,
        isRequest,
        isApprove,
        id,
        isModi,
        input,
        checkedArr,

        value1,
        value2,
        value3,
        value4,
        value5,
        value6,
        value7,
        value8,
        value9,
        value10,

        closeModal,
        onClickNum,
        onClickNext,
        onClickPrev,
        // setIsShowModal,
        onClickList,
        onChangeInput,
        deleteList,
        saveClose,
        saveWrite,
        onClickModi,
        openAddModal,
        onChangeCheck,
        onCheck,
        status,
        onRequest,
        onApprove,
        onClickXl,
    } = props;
    return ( 
        <ImplyRegisterWorkBlock>
            <h1>묵시대상자 추가</h1>
            {/* 묵시 대상자 테이블 영역 */}

        <div className='btn-group'>{/* [Dev]22.02.16 btn-group div, class 추가 */}
            {id? 
            <>
                {isApprove? <button className='final-btn' onClick={onApprove}>최종반영</button> : null}
            </> 
            : 
            <>
                <button onClick={onClickXl}>엑셀 업로드</button>
                <button onClick={openAddModal}>추가하기</button>
                <button onClick={deleteList}>선택항목 삭제하기</button>
                {isRequest? <button onClick={onRequest}>선택항목 등록요청하기</button> : null}

            </>}
        </div>
            <div>
                <table className="tt">
                    <thead>
                        <tr>
                            {
                                id ? null :
                                <th>
                                    <input type="checkbox" id="chkAll" checked={(checkedArr.length === ImplyList.length && checkedArr.length > 0) || "" }  onChange={onCheck}/>
                                    <label htmlFor="chkAll" />
                                </th>
                            }
                            <th>번호</th>
                            <th>유형</th>
                            <th>입주자명</th>
                            <th>{dataWORD.BIRTHDATE}</th>
                            <th>전화번호</th>
                            <th>임대인</th>
                            <th>지역</th>
                            <th>지원주택소재지</th>
                            <th>계약종료일</th>
                            <th>전세금</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ImplyList.length > 0? 
                        <>
                            {ImplyList.map((item,idx) => {
                                return (
                                    <tr key={idx} onClick={(e) => onClickList(e, item, idx)}>
                                        {id? 
                                            null 
                                            : 
                                            <th>
                                                <input 
                                                    type="checkbox"
                                                    id={"chk" + item.imply_id}
                                                    checked={checkedArr.some((el) => el===item.imply_id) || false || ""} 
                                                    onChange={(e) => onChangeCheck(e, item)}/>
                                                <label htmlFor={"chk" + item.imply_id} />
                                            </th>
                                        }
                                        <td>{(30 * (currentPage - 1)) + idx+1}</td>
                                        <td>{item.type_name || "-"}</td>
                                        <td>{item.name}</td>
                                        <td>{item.imply_no.slice(0,6)} - {item.imply_no.slice(6)}</td>
                                        <td>{item.phone.slice(0,3)} - {item.phone.slice(3,7)} - {item.phone.slice(7)}</td>
                                        <td>{item.house_owner}</td>
                                        <td>{item.rec_area}</td>
                                        <td>{item.addr}</td>
                                        <td>{item.contract_end_date}</td>
                                        <td>{setComma(item.rent_amt)}</td>
                                        <td>{item.memo}</td>
                                    </tr>
                                )
                            })}
                        </> 
                        : null}
                    </tbody>
                </table>
            </div>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
                {/* 묵시 대상자 추가 모달 영역 */}
                {isShowModal? 
                <div className='winnerModal'>
                    <div className='backGround' onClick={closeModal}/>
                    <div>
                        <div className='header'>
                            <div />
                            <h2>{isModi? "수정하기" : "추가하기"}</h2>
                        </div>
                        <div className='body'>
                            <div>
                                <div>
                                    <label htmlFor='type'>유형</label>
                                    <select className={value8? 'iptErr' : ''} disabled={status === 'complete'? true : false} name='type' id='type' value={input.type || ""} onChange={onChangeInput}>
                                        <option>선택하세요</option>
                                        {cboApply.map((item) => (
                                            <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                            ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='name'>입주자명</label>
                                    <input className={value9? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='text' name='name' id='name' placeholder='입주자명' value={input.name || ""} onChange={onChangeInput}/>
                                </div>

                                <div className='displayNone'>
                                    <label htmlFor='birth'>생년월일</label>
                                    <input className={value2? 'iptErr readOnly' : 'readOnly'} readOnly type='number' name='birth' id='birth' placeholder='자동입력' value={input.imply_no.slice(0,6) || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='imply_no'>{dataWORD.BIRTHDATE}</label>
                                    <input className={value5? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='number' name='imply_no' id='imply_no' placeholder='주민등록번호' value={input.imply_no || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='phone'>전화번호</label>
                                    <input className={value6? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='number' name='phone' id='phone' placeholder='전화번호' value={input.phone.replaceAll('-','') || ""} onChange={onChangeInput}/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor='house_owner'>임대인</label>
                                    <input className={value4? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='text' name='house_owner' id='house_owner' placeholder='임대인' value={input.house_owner || ""} onChange={onChangeInput}/>
                                </div>
                                <div>
                                    <label htmlFor='rec_area'>지역</label>
                                    <input className={value10? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='text' name='rec_area' id='rec_area' placeholder='지역' value={input.rec_area || ""} onChange={onChangeInput}/>
                                </div>
                                <div>
                                    <label htmlFor='addr'>지원주택소재지</label>
                                    <input className={value1? 'iptErr width2' : 'width2'} readOnly={status === 'complete'? true : false} type='text' name='addr' id='addr' placeholder='지원주택소재지' value={input.addr || ""} onChange={onChangeInput}/>
                                </div>
                                <div>
                                    <label htmlFor='contract_end_date'>계약종료일</label>
                                    <input className={value3? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='date' name='contract_end_date' id='contract_end_date' placeholder='계약종료일' value={input.contract_end_date.split(" ")[0] || ""} onChange={onChangeInput}/>
                                </div>
                                <div>
                                    <label htmlFor='rent_amt'>전세금</label>
                                    <input className={value7? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type='number' name='rent_amt' id='rent_amt' placeholder='전세금' value={input.rent_amt || ""} onChange={onChangeInput}/>
                                    <div style={{
                                        fontSize: '14px'
                                    }}>{setComma(input.rent_amt)} 원</div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor='memo'>비고</label>
                                    <textarea className='width2 height2' readOnly={status === 'complete'? true : false} type='text' name='memo' id='memo' placeholder='비고' value={input.memo || ""} onChange={onChangeInput}/>
                                </div>
                            </div>
                        </div>
                        <div className="pop-group-btn">
                            <button onClick={closeModal}>닫기</button>
                            {isModi? 
                                <>
                                    {status === 'complete'? null : <button onClick={onClickModi}>수정하기</button>}
                                </>
                                : 
                                <>
                                    <button onClick={saveClose}>저장하고 닫기</button>
                                    <button onClick={saveWrite}>저장하고 계속등록하기</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
                : null}
        </ImplyRegisterWorkBlock>
    );
}

export default ImplyRegisterWork;