import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './print.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, createStore } from 'redux';
import rootReducer, { rootSaga } from './modules';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { check } from './modules/user';

//-------------------------------------------------------------------------------------
/* eslint-disable */
Date.prototype.format = function(f) {
  if( !this.valueOf() ) return ' ';

  const weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  const d = this;

  return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function($1) {
      switch( $1 ) {
          case 'yyyy':    return d.getFullYear();
          case 'yy':      return (d.getFullYear() % 100).zf(2);
          case 'MM':      return (d.getMonth() + 1).zf(2);
          case 'dd':      return d.getDate().zf(2);
          case 'E':       return weekName[d.getDay()];
          case 'HH':      return d.getHours().zf(2);
          case 'hh':      return ((d.getHours() % 12) ? (d.getHours() % 12) : 12).zf(2);
          case 'mm':      return d.getMinutes().zf(2);
          case 'ss':      return d.getSeconds().zf(2);
          case 'a/p':     return d.getHours() < 12 ? '오전' : '오후';
          default: return $1;
      }
  });
};

String.prototype.string = function(len) {
  let s = '';
  let i = 0;
  while( i++ < len ) {
      s += this;
  }
  return s;
}

String.prototype.zf = function(len) {
  return '0'.string(len - this.length) + this;
}

Number.prototype.zf = function(len) {
  return this.toString().zf(len);
}
/* eslint-enable */
//-------------------------------------------------------------------------------------

//--------------------------------------------
const sagaMiddleware = createSagaMiddleware();
const getMiddleware = () => {
  const isBuild = process.env.REACT_APP_BUILD_STATUS;
  if( isBuild === 'BUILD' ) {
    return applyMiddleware(sagaMiddleware);
  }
  return composeWithDevTools(applyMiddleware(sagaMiddleware));
}
const store = createStore(
    rootReducer,
    getMiddleware(),
);

//--------------------------------------------
/*
localStorage 사용 X
기존 토큰은 쿠키에 저장중, httpOnly 옵션이 있어서 접근 불가

매번 새로고침마다 조건없이 check 를 수행하도록 변경.
*/
function loadUser() {
      store.dispatch(check());
}
//--------------------------------------------

sagaMiddleware.run(rootSaga);
loadUser();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
