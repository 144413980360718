export const ShowTelDash = telnum => {
    const telStr = String(telnum);
    if( typeof(telStr) === typeof('') ) {
        const numOnly = telStr.replace(/\D/g, '');

        if( numOnly.length > 6 ) {
            let headNum = numOnly.substring(0, 2);
            if( headNum !== '02' ) {
                headNum = numOnly.substring(0, 3);            
            }
            switch (headNum) {
                case '02':  return (numOnly.length > 6) ?
                    `${headNum}-${numOnly.substring(2, numOnly.length-4)}-${numOnly.substring(numOnly.length-4, numOnly.length)}`
                    :`${headNum}-${numOnly.substring(2, numOnly.length)}`
            
                default:    return (numOnly.length > 7) ?
                `${headNum}-${numOnly.substring(3, numOnly.length-4)}-${numOnly.substring(numOnly.length-4, numOnly.length)}`
                :`${headNum}-${numOnly.substring(3, numOnly.length)}`                
            }
        } else {
            return telnum;
        }
    } else {
        return telnum;
    }
}

export const ConvRealEstateAddr2ShowRoadJibun = ({use_address, road_addr_dtl}) => {
    if( use_address && use_address.real_estate_address && use_address.building_type ) {
        const strtIdx = use_address.real_estate_address.indexOf('[');
        const endIdx = use_address.real_estate_address.indexOf(']');
        if( (strtIdx > -1) && (strtIdx < endIdx) ) {
            let showJibunArea = use_address.real_estate_address.substring(strtIdx, endIdx+1);
            let showRoadArea = use_address.real_estate_address.replace(showJibunArea, '');
            showJibunArea = showJibunArea.replace(/[\[\]]/g, '');//eslint-disable-line

            // 일반건물이면 상세주소 더하기
            if( use_address.building_type === '2' ) {
                showRoadArea += (', ' + road_addr_dtl);
            }

            return {showRoadArea, showJibunArea};
        }
    }

    return null;
}

export const ConvAddressMemo2ShowRoadJibun = address_memo => {
    if( address_memo && typeof(address_memo) === typeof('') ) {
        const strtIdx = address_memo.indexOf('[');
        const endIdx = address_memo.indexOf(']');
        if( (strtIdx > -1) && (strtIdx < endIdx) ) {
            let showJibunArea = address_memo.substring(strtIdx, endIdx+1);
            let showRoadArea = address_memo.replace(showJibunArea, '');
            showJibunArea = showJibunArea.replace(/[\[\]]/g, '');//eslint-disable-line

            return {showRoadArea, showJibunArea};
        } else {
            return {showRoadArea: null, showJibunArea: address_memo};
        }
    }

    return null;
}