import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ImplyRegisterWorkContainer from '../containers/ImplyRegisterWorkContainer';

const ImplyRegisterWorkPage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyRegisterWorkContainer />
        </>
    );
}

export default ImplyRegisterWorkPage;