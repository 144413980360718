import React, { Fragment } from 'react';
import styled from 'styled-components';
import EntitleViewerContainer from '../containers/EntitleViewrContainer';
// import EnApproveContainer from '../containers/Modal/EnApproveContainer';
import EnCallerMemoContainer from '../containers/Modal/EnCallerMemoContainer';
import EnDocFailContainer from '../containers/Modal/EnDocFailContainer';
import EnDocUploadContainer from '../containers/Modal/EnDocUploadContainer';
import EnPriorityContainer from '../containers/Modal/EnPriorityContainer';
import FeedbackContainer from '../containers/Modal/FeedbackContainer';
import WinnerInfoContainer from '../containers/Modal/WinnerInfoContainer';
import '../index.css';
import { ShowTelDash } from '../lib/dataUTIL';
import dataWORD, { ALERT } from '../lib/dataWord';
import rotate from '../rotation.png';
import savedisk from '../savedisk.png';

const EntitleWorkBlock = styled.div`
    width: 100%;    
    height: 93.5vh;
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 20px 40px 20px 35px;
    box-sizing: border-box;
    position:relative;

    
    .divContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        position:relative;
        // top:-30px
    }
    .board-form select, 
    .board-form input[type="date"], 
    .board-form input[type="text"],
    .board-form input[ype="number"]{
        border: none;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .board-form td{
        padding: 0;
    }
    .divContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
    }
     .divContainer h4 {
        color: #545454;
        font-size: 20px;
        display: flex;
        align-items: center;
        margin: 15px 0 5px 0;
    }
    .divContainer h4 button{
        margin-left: 5px;
    }

    // select,input[type="date"],input[type="text"],input[type="number"]{height: auto;padding: 0 3px; width:99%}


    .divWrite {
        width: 56%;
        overflow-y: auto;
        padding-right: 20px;
        padding-bottom: 20px !important;
    }
    .divWrite table {
        width: 100%;
        font-size: 12px;
    }
    .divWrite table th, .divWrite table td {
        min-width: 20px;
        text-align: center;
        //padding: 1.5px 0;
    }
    .divWrite table th {
        min-width: 100px;
        padding: 1.5px 0;
        box-sizing: border-box;
    }
    .divWrite table input {
        width: 99%;
    }
    .divWrite table .radioinput{
        width:10%;
    }
    .divWrite table .radioinput ~ label{
        margin : 0 10px
    }
    .divTop {
        display: grid;
        grid-template-columns: 4fr 1fr;
        column-gap: 10px;
        padding: 0 5px;
    }
    .tblDiv{
        display: flex;
    }
    .tblDiv:first-child {
        margin-bottom: 10px;
        border:0;
    }

    .tblDiv > div {
        width: 100%;
    }
    .tblDiv > div:first-child{
        display: flex;
        flex-wrap: wrap;
    }

    .tblDiv span {
        display: inline-block;
        width:66px;
        color:#fff;
        background-color:#ddd;
        border-radius :40px;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        position:relative;
        border:0;
        text-align: center;
        cursor: default;
        font-size: 11px;
        cursor: default;
        padding: 8px 0;
        margin-right : 20px;
        margin-bottom: 8px;
    }

    .tblDiv span:not(:last-child)::after{
        position: absolute;
        top:-27px;
        right: -21px;
        content: "..";
        font-size: 40px;
        display:inline-block;
        white-space: nowrap;
        color:#ccc;
    }

    .tblDiv .currentStep {
        background: linear-gradient(to bottom, #3EC092 , #0A989D);
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
        color: white;
    }

    
    .tblDiv .feedbackStep {
        background-color: mediumseagreen;
        color: white;
    }
    .tblAppr{
        border:1px solid #BDBDBD;
        // height:90%;
        margin-bottom:5px;
        border-radius : 5px;
        background-color: #ffffff;
    }

    .tblAppr table {
        width: 100%;
        height: 100%;
        position:relative;
        table-layout:fixed;
        border-collapse : collapse;
        border-radius : 3px;
    }
    .tblAppr table tbody tr{
        text-align: center;
        border-bottom: 0.5px solid #BDBDBD;
    }
    .tblAppr table tbody tr:first-child{
        border-bottom: 0.5px solid #0B999C;
    }
    .tblAppr table tbody tr:last-child{
        border-bottom: 0;
    }
    .tblAppr table tbody th{
        font-size: 12px;
        color : #0B999C
    }
    .tblAppr table tbody td{
        font-size: 12px;
        color : #545454;
        border-left: 0.5px solid #BDBDBD;
    }
   
    .flexDiv {
        display: flex;
        flex-wrap: nowrap;
    }
    .flexDiv table {
        margin: 5px 10px 0 0;
    }
    .flexDiv table:last-child {
        margin-right:0;
    }

    .folderBox .folder-on {
        color: #ffffff; 
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }

    .enReference {
        position: relative;
        width: 44%;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
    .refTab {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid black;
    }
    .refTab > div {
        color: #ffffff;
        padding: 5px 10px;
        cursor: pointer;
        background-color: #BDBDBD;
    }
    .refTab > div > span {
        line-height: 30px;
        margin: 0 10px;
    }
    .refTab .refreshIcon {
        margin-left: 0;
        padding: 4px 10px;
    }
    .refTab .refreshIcon:hover {
        color: yellow;
    }
    .refTabOn {
        color: #ffffff; 
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }
    .showRef {
        display: flex;
    }
    .hideRef {
        display: none;
    }
    .divRef {
        position: relative;
        width: 100%;
        height: 75vh;
        // display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color:#fff
    }
    .divRef .viewArea {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-y: auto;
    }
    .divRef .imgView {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden !important;
    }
    .divRef .selete-thumbnail {
        border: 3px solid red;
    }

    .isMemo {
        display: inline-block;
        background-color: red;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-left: 5px;
        padding: 2px;
    }
    .priorityBox {
        display: flex;
        gap: 10px;
        padding: 5px;
    }
    .addrDuplicate {
        color: red;
    }

    .divHeader-button .black { background-color: black; color: white; } //  정제주소 신청 안 한 경우
    .divHeader-button .gray { background-color: gray; color: white; }   //  정제주소 요청중
    .divHeader-button .red { background-color: red; color: white; }     //  정제주소 신청 했는데 5,6,7번 도로명 지명 내용 없음
    .divHeader-button .orange { background-color: orange; color: white; }   //  정제주소 선택을 안 한 경우
    .divHeader-button .blue { background-color: blue; color: white; }   //  주소는 정제되었으나 파일요청을 하지 않은 경우
    .divHeader-button .yellow { background-color: yellow; }             //  첨부파일 요청중
    .divHeader-button .green { background-color: green; color: white; } //  첨부파일 회신 완료

    .txtRemark {
        width: 100%;
        height: 140px;
        resize: none;
        border: none;
        padding: 10px;
        box-sizing: border-box;
    }

    .popupModal > div:nth-child(2){
        width: 620px;
        padding: 50px;
        box-sizing: border-box;
    }
    .popupModal .cancelCheck>div:nth-child(2){
        text-align: right;
        padding: 0 30px;
        box-sizing: borderbox;
        margin-bottom: 40px;
    }
    .popupModal .pwipt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;
    }
    .popupModal .pwipt input{
        height: 34px;
    }
    .divButtons {
        padding: 0 20px;
        box-sizing: border-box;
    }

    .popupModal .enmodify h2 {
        margin-bottom: 20px;
    }
    .popupModal .enmodify .reason {
        display: flex;
        flex-direction: column;
    }
    .popupModal .enmodify .divButtons {
        margin-top: 20px;
    }

    .rst-ok { color: green; }
    .rst-ng { color: red; }

    .feedbackTab:hover {
        background: linear-gradient(to bottom, coral, orangered);
        box-shadow: 0 0.18rem 0.37rem rgb(0 0 0 / 16%);
    }


    .board-form input[type="text"]{
        width:80%;
        background:transparent;
        padding-right:0;
    }
    .board-form td{
        padding-right :5px;
    }
    .board-form td span{
        width:15%;
        text-align:right;
        padding-left:5px;
    }

    .divContainer.cont{
        display:block;
        position:relative;
        overflow-x:auto;
    }
    .divContainer.wrap{
        min-width:1600px;
    }

    .icon-rotate, .icon-savedisk {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 20%;
        cursor: pointer;
        opacity: 0.2;
    }
    .icon-rotate {
        top: 10px;
        right: 155px;
    }
    .icon-savedisk {
        top: 50px;
        right: 155px;
    }
    .icon-rotate:hover, .icon-savedisk:hover {
        background-color: orange;
        opacity: 1;
    }
    #div-rotate {
        position: absolute;
        width: -webkit-calc(100% - 145px);
        height: 100%;
    }
    .rotate-0 { transform: rotate(0deg); }
    .rotate-90 { transform: rotate(90deg); }
    .rotate-180 { transform: rotate(180deg); }
    .rotate-270 { transform: rotate(270deg); }
`;

const EntitleWork = (props) => {
    const modalCallType = 'ENTITLE';

    const {
        user,

        docNo,
        doc_status,
        isCanChange,
        app_user,
        appcheck_user,
        isFeedback,
        // feedback_answer,
        call_memo,
        call_check,
        entitle_user,
        // entitle_user_name,
        // appr_complete_date,

        approval,
        reject_reason,

        winner,
        possibleRate,
        possibleAmt,

        winner_type,
        // winner_type_name,
        contract_type,
        // contract_type_name,
        rent_type,
        // rent_type_name,
        req_cnt,
        end_date,
        wallpaper_flag,
        minor_child_cnt,

        addr_duplicate,
        house_type,
        house_owner,
        req_duplicate,
        road_addr,
        road_addr_dtl,
        address_memo,
        jibun,
        house_area,
        rent_amt,
        support_amt,
        ground_area,
        share_area,
        small_room_support,
        guarantee_monthly_rent,
        securing_bonds,
        broker_company,
        broker_telno,
        family_cnt,
        estate_no,
        room_count,
        senior_rent_yn,

        house_amt,
        decide_how,
        decide_date,
        decide_amt,
        possible_bond_amt,
        first_bond_amt,
        mortgage_amt,
        pre_deposit_total,
        pre_rent_loans,
        pre_charge_amt,
        pre_interest_free,
        rent_set_amt,
        seizure_amt,
        total_room_cnt,
        rent_room_cnt,
        already_rent_amt,
        empty_room_cnt,
        securing_bond_amt,
        remark,
        difference_amt,
        debt_ratio,
        suitable_flag,

        //  서류폴더, 서류이미지
        refType,
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        selectTxtFolder,
        txtFolder,
        onWaiting,
        onImgRotation,
        onImgRotateSave,
        onRefreshImg,
        
        //  선순위 합계
        rent,
        cboRentSido,
        senior_sido,
        rent_tot_amt,
        empty_tot_amt,

        //  검토서 변경요청 데이터
        work_change_date,
        work_change_req,
        work_change_user,
        isShowEnModify,
        onEnModifyReasonClick,
        
        cboApply,
        cboContract,
        cboRent,
        cboHousing,
        cboUseYN,
        cboPricing,
        cboEmptyType,

        // 기능권한
        func,

        //  폴더 슬라이드 위치
        imgFolderDiv,
        imgFolderSlider,
        txtFolderDiv,
        txtFolderSlider,

        // 검토파일 자동받기 버튼 색상
        isNeedApiRequest,
        setIsNeedApiRequest,
        
        //  API Viewer
        isChangeApi,
        setIsChangeApi,

        //  모달
        isShowDocUpload,
        isShowDocFail,
        // isShowApprove,
        isShowMemo,
        isShowPriority,
        isShowPassword,
        isShowFeedback,
        isShowWinner,
        setIsShowDocFail,
        setIsShowMemu,
        setIsShowPriority,
        setIsShowFeedback,
        setIsShowWinner,
        onDocFailComplete,
        // onReqApproveComplete,
        onRejectComplete,
        onRejectCheckComplete,
        onMemoComplete,
        onPriorityComplete,
        onNonConfComplete,
        onUserChangeComplete,
        onFeedbackComplete,
        onRefreshList,

        //  권리분석 취소 패스워드 확인 관련
        user_pwd,
        pwdCheckResult,

        //  이벤트
        onChange,
        onChangeNum,
        
        onRefChange,
        onSelectFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,
        onSelectTxtFolder,
        onUploadComplete,

        onWinnerChange,
        onWinnerAmtChange,
        onCalcSupportAmt,

        onMemoClick,
        onRegistClick,
        onDocUploadClick,
        // onDocAutoReceiveClick,
        onDocSortClick,
        onDocFailClick,
        onUserChangeClick,
        onDocCheckClick,
        onFeedBackClick,
        onFeedBackCancel,
        onPriorityClick,
        onSaveClick,
        onReqApproveClick,
        onNonConfClick,
        onNonConfRecoverClick,
        onApproveClick,
        onRejectClick,
        onRejectCheckClick,
        onAppCheckRejectClick,
        onCancelClick,
        onCancelPwChk,
        onRemoveEntitle,
        onExecCancel,
        onRestartClick,

        use_address,
    } = props;

    const propDatas = {
        user,
        docNo,
        doc_status,
        approval,
        reject_reason,
        winner,
        winner_type,
        broker_company,
        road_addr,
        road_addr_dtl,
        address_memo,
        // onDocAutoReceiveClick,
        isShowDocFail,
        isShowFeedback,
        modalCallType,
        setIsShowDocFail,
        setIsShowMemu,
        setIsShowPriority,
        setIsShowFeedback,
        setIsShowWinner,
        onDocFailComplete,
        // onReqApproveClick,
        // onReqApproveComplete,
        onUploadComplete,
        onRejectComplete,
        onRejectCheckComplete,
        onMemoComplete,
        onPriorityComplete,
        onNonConfComplete,
        onUserChangeComplete,
        onFeedbackComplete,
        onRefreshList,
        call_memo,
        call_check,
        entitle_user,
        rent,
        senior_sido,
        rent_tot_amt,
        empty_tot_amt,
        cboEmptyType,
        setIsNeedApiRequest,
    }

    //  [DATA 정보]
    const viewerDatas = {
        docNo,
        selectTxtFolder,
        isChangeApi,
        setIsChangeApi,
    }

    return (
        <EntitleWorkBlock>
            <div className="divHeader-button"> {/* [Dev] 22.02.16 divHeader-button class변경*/}
                <button onClick={onMemoClick}>메모{call_check === "Y" && <div className='isMemo'/>}</button>
                {work_change_req && <button onClick={onEnModifyReasonClick}>검토서 변경 사유</button>}
                {func.find((el) => el.id === "FUNC071") && user ?
                <>
                    {
                        (
                            doc_status === 'ENREQ' ||
                            doc_status === 'ENCHGREQ' ||
                            doc_status === 'ENMODIFY'
                        ) &&
                        <button onClick={onRegistClick}>접수</button>}
                    {
                        (
                            doc_status === 'ENACK' ||
                            doc_status === 'ENDOCFAIL' ||
                            doc_status === 'ENDOCADD' ||
                            doc_status === 'ENPROCESS'
                        ) &&
                        entitle_user === user.user_id &&
                        <>
                            <button className={isNeedApiRequest} onClick={onDocUploadClick}>검토파일 업로드</button>
                            {/* <button className={isNeedApiRequest} onClick={onDocAutoReceiveClick}>검토파일 자동받기</button> */}
                            <button onClick={onDocSortClick}>서류분류</button>
                            <button onClick={onDocFailClick}>서류미비</button>
                            <button onClick={onUserChangeClick}>담당자 변경</button>
                        </>
                    }
                    {
                        (
                            doc_status === 'ENACK' ||
                            doc_status === 'ENDOCFAIL' ||
                            doc_status === 'ENDOCADD'
                        ) &&
                        entitle_user === user.user_id &&
                        <button onClick={onDocCheckClick}>서류확인</button>
                    }
                    {
                        doc_status === 'ENPROCESS' &&
                        entitle_user === user.user_id &&
                        <>
                            {senior_rent_yn === 'Y' && <button onClick={onPriorityClick}>선순위 등록</button>}
                            <button onClick={onSaveClick}>저장</button>
                            <button onClick={onNonConfClick}>부적합 만료</button>
                            {(isFeedback === 'N' || isFeedback === 'Y') && <button onClick={onFeedBackClick}>피드백 요청</button>}
                            {isFeedback === 'P' && <button onClick={onFeedBackCancel}>피드백 취소</button>}
                            {(isFeedback === 'N' || isFeedback === 'Y') && <button onClick={onReqApproveClick}>결재요청</button>}
                        </>
                    }
                    {
                        doc_status === 'NONCONF' &&
                        <button onClick={onNonConfRecoverClick}>부적합 만료 복원</button>
                    }
                    {
                        (
                            doc_status === 'ENACK' ||
                            doc_status === 'ENDOCFAIL' ||
                            doc_status === 'ENDOCADD' ||
                            doc_status === 'ENPROCESS' ||
                            doc_status === 'ENAPVREQ' ||
                            doc_status === 'ENAPVFAIL' ||
                            doc_status === 'ENAPVOK'
                        ) &&
                        entitle_user === user.user_id &&
                        <button onClick={onCancelClick}>권리분석 취소</button>
                    }
                    {
                        doc_status === 'ENCANCEL' &&
                        <button onClick={onRestartClick}>취소 복원</button>
                    }
                </> : null}
                {func.find((el) => el.id === "FUNC091") && user ?
                <>
                    {doc_status === 'ENAPVREQ' && senior_rent_yn === 'Y' && <button onClick={onPriorityClick}>선순위 등록</button>}
                    {
                        doc_status === 'ENAPVREQ' && app_user === user.user_id &&
                        <>
                            <button onClick={onSaveClick}>저장</button>
                            <button onClick={() => onApproveClick('APPROVAL')}>승인</button>
                            <button onClick={onRejectClick}>반려</button>
                        </>
                    }
                </> : null}
                {func.find((el) => el.id === "FUNC092") && user ?
                <>
                    {doc_status === 'ENAPVOK' && appcheck_user.indexOf(user.user_id) > -1 && <button onClick={() => onApproveClick('APPCHECK')}>결재 확인</button>}
                    {doc_status === 'ENAPVOK' && appcheck_user.indexOf(user.user_id) > -1 && <button onClick={onAppCheckRejectClick}>결재 반려</button>}
                </> : null}
                {reject_reason && <button onClick={onRejectCheckClick}>반려확인</button>}
                {user && user.user_auth === 'ENADMIN' && <button onClick={onRemoveEntitle}>검토서 삭제</button>}
            </div>

            <div className='divContainer cont'>
                <div className='divContainer wrap'>
                <div className='divWrite'>
                    {/* <div className='divTop'>
                        <div>
                            <div className='tblDiv'>
                                <div>
                                    <span className={doc_status === 'ENREQ' ? 'currentStep' : ''}>신청</span>
                                    <span className={doc_status === 'ENACK' ? 'currentStep' : ''}>접수</span>
                                    <span className={doc_status === 'ENDOCFAIL' ? 'currentStep' : ''}>서류미비</span>
                                    <span className={doc_status === 'ENDOCADD' ? 'currentStep' : ''}>서류보완</span>
                                    <span className={doc_status === 'ENPROCESS' ? 'currentStep' : ''}>분석중</span>
                                    <span className={isFeedback === "P" ? 'feedbackStep' : ''}>피드백요청</span>
                                    <span className={isFeedback === "Y" ? 'feedbackStep' : ''}>피드백응답</span>
                                    <span className={doc_status === 'ENAPVREQ' ? 'currentStep' : ''}>결재요청</span>
                                    <span className={doc_status === 'ENAPVOK' || doc_status === 'ENAPVFAIL' ? 'currentStep' : ''}>결재완료</span>
                                </div>
                            </div>
                            <div className='tblDiv'>
                                <div>피드백 내용</div>
                                <textarea value={feedback_answer} readOnly={true}/>
                            </div>
                        </div>
                        <div className='tblAppr'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th colSpan={approval.length + 1}>결재 상태</th>
                                    </tr>
                                    <tr>
                                        <th>성명</th>
                                        {approval.map((item, idx) => (
                                            <td key={idx}>{item.app_name}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th>결재일</th>
                                        {approval.map((item, idx) => (
                                            <td key={idx}>{item.app_date}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                    <div>
                        <h4>지원 대상자</h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                {winner.map((win, idx) => (
                                    <tr key={idx}>
                                        {idx === 0 && <th rowSpan={winner.length}>지원유형</th>}
                                        <td>
                                            <select name="winner_type" id="winner_type" value={win.winner_type} onChange={onWinnerChange.bind(this, win, 'winner_type')} disabled={isCanChange ? "disabled" : ""}>
                                                {cboApply.map((item) => (
                                                    <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                                ))}
                                            </select>
                                            {/* {win.winner_type_name} */}
                                        </td>
                                        {idx === 0 && <th rowSpan={winner.length}>계약유형</th>}
                                        {idx === 0 &&
                                            <td rowSpan={winner.length}>
                                                <select name="contract_type" id="contract_type" value={contract_type} onChange={onChange} disabled={isCanChange ? "disabled" : ""}>
                                                    {cboContract.map((item) => (
                                                        item.sub_code && <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                                    ))}
                                                </select>
                                                {/* {win.contract_type_name} */}
                                            </td>
                                        }
                                        {idx === 0 && <th rowSpan={winner.length}>전세유형</th>}
                                        {idx === 0 &&
                                            <td rowSpan={winner.length}>
                                                <select name="rent_type" id="rent_type" value={rent_type} onChange={onChange} disabled={isCanChange ? "disabled" : ""}>
                                                    {cboRent.map((item) => (
                                                        <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                                    ))}
                                                </select>
                                                {/* {rent_type_name} */}
                                            </td>
                                        }
                                    </tr>
                                ))}
                                {winner.map((item, idx) => (
                                    <tr key={idx}>
                                        {idx === 0 && <th rowSpan={winner.length}>성명</th>}
                                        <td>{item.winner_name}</td>
                                        {idx === 0 && <th rowSpan={winner.length}>{dataWORD.BIRTHDATE}</th>}
                                        <td>{item.winner_no}</td>
                                        {idx === 0 && <th rowSpan={winner.length}>입주자부담금 비율</th>}
                                        <td>
                                            {possibleRate.length === 0 && "해당 지원유형은 기본 부담금으로 적용됩니다."}
                                            {possibleRate.map((rate, rateIdx) => (
                                                <Fragment key={rateIdx}>
                                                    <input className="radioinput" type="radio" name={"charge_rate_" + rate} id={"charge_rate_" + rate} value={rate} checked={String(item.charge_rate) === String(rate)} onChange={onWinnerChange.bind(this, item, 'charge_rate')} />
                                                    <label htmlFor={"charge_rate_" + rate}>{rate}%</label>
                                                </Fragment>
                                            ))}
                                            {
                                                //  입주자부담금에 비율과 금액이 둘 다 있다면 사용자에게 선택권을 준다.
                                                //  금액을 선택했다면 charge_rate에는 -1을 저장한다.
                                                (possibleRate.length > 0 && +possibleAmt > 0) &&
                                                <Fragment>
                                                    <input type="radio" name="isBaseCharge" id="isBaseCharge" value="-1" checked={item.charge_rate === "-1"} onChange={onWinnerChange.bind(this, item, 'charge_rate')} />
                                                    <label htmlFor="isBaseCharge">기본부담금</label>
                                                </Fragment>
                                            }
                                        </td>
                                    </tr>
                                ))}
                                {winner.map((item, idx) => (
                                    <tr key={idx}>
                                        {idx === 0 &&
                                            <th rowSpan={winner.length}>
                                                주소
                                                {addr_duplicate === 'Y' && <><br/><span className='addrDuplicate'>중복확인</span></>}
                                            </th>
                                        }
                                        <td colSpan={3}>{item.winner_addr}</td>
                                        {idx === 0 && <th rowSpan={winner.length}>연락처</th>}
                                        <td>{ShowTelDash(item.winner_phone)}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th>지원회차</th>
                                    <td><input type="text" name="req_cnt" id="req_cnt" placeholder='지원회차' value={req_cnt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} style={{height:'38px'}} /></td>
                                    <th>계약만료일<br/>(재계약시)</th>
                                    <td><input type="date" name="end_date" id="end_date" value={end_date} onChange={onChange} disabled={isCanChange ? "disabled" : ""} style={{height:'38px'}} max="9999-12-31"/></td>
                                    <th>도배신청여부</th>
                                    <td>
                                        <select name="wallpaper_flag" id="wallpaper_flag" value={wallpaper_flag} onChange={onChange} disabled={isCanChange ? "disabled" : ""}>
                                            {cboUseYN.map((item) => (
                                                <option key={item.sub_code} value={item.sub_code}>{item.string_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={winner.length}>가구원수</th>
                                    <td rowSpan={winner.length}>
                                        <input type="text" name="family_cnt" id="family_cnt" placeholder='가구원수' value={family_cnt} onChange={onChange} disabled={isCanChange ? "disabled" : ""} maxLength="3" />
                                    </td>
                                    {
                                        winner && Array.isArray(winner) && winner.length === 1 && (winner[0].winner_type === 'TYPE011') && (    // 다자녀일 경우에만 보임
                                            <>
                                                <th rowSpan={winner.length}>미성년자녀수</th>
                                                <td rowSpan={winner.length}>
                                                    <input type="text" name="minor_child_cnt" id="minor_child_cnt" placeholder='미성년자녀수' value={minor_child_cnt} onChange={onChange} disabled={isCanChange ? "disabled" : ""} maxLength="3" />
                                                </td>
                                            </>
                                        )
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h4>
                            권리조사 대상주택현황
                            <button onClick={onCalcSupportAmt} className="calculation-btn">지원금 계산</button>
                            <button className="calculation-btn">중복여부 조회</button>
                        </h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>주택유형</th>
                                    <td>
                                        <select name="house_type" id="house_type" value={house_type} onChange={onChange} disabled={isCanChange ? "disabled" : ""}>
                                            {cboHousing.map((item) => (
                                                <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <th>주택소유자</th>
                                    <td><input type="text" name="house_owner" id="house_owner" placeholder='주택소유자' value={house_owner} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /></td>
                                    <th>중복지원여부</th>
                                    <td>
                                        <select name="req_duplicate" id="req_duplicate" value={req_duplicate} onChange={onChange} disabled={isCanChange ? "disabled" : ""}>
                                            {cboUseYN.map((item) => (
                                                <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>소재지</th>
                                    <td rowSpan={2} colSpan={3}>
                                        <p>{road_addr} ({jibun})</p>
                                        <input type="text" name="road_addr_dtl" id="road_addr_dtl" placeholder='상세 주소' value={road_addr_dtl} onChange={onChange} disabled={isCanChange ? "disabled" : ""} />
                                    </td>
                                    <th>대지면적</th>
                                    <td><input type="text" name="ground_area" className="text-r" id="ground_area" placeholder='대지면적' value={ground_area} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /><span>m²</span></td>
                                </tr>
                                <tr>
                                    <th>대지권비율</th>
                                    <td><input type="text" name="share_area" className="text-r" id="share_area" placeholder='대지권비율' value={share_area} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /></td>
                                </tr>
                                <tr>
                                    <th>등기부등본 주소</th>
                                    <td colSpan={5}>{use_address && use_address.real_estate_address ? use_address.real_estate_address : ''}</td>
                                </tr>
                                <tr>
                                    <th>대체 주소정보</th>
                                    <td colSpan={5}><input type="text" name="address_memo" id="address_memo" placeholder='대체 주소정보' value={address_memo} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /></td>
                                </tr>
                                <tr>
                                    <th>전세보증금</th>
                                    <td style={{padding:'0'}}><input type="text" name="rent_amt" className="text-r" id="rent_amt" placeholder='전세보증금' value={rent_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <th>LH지원금</th>
                                    <td><input type="text" name="support_amt" className="text-r" id="support_amt" placeholder='LH지원금' value={support_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <th>전용면적</th>
                                    <td><input type="text" name="house_area" className="text-r" id="house_area" placeholder='전용면적' value={house_area} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /><span>m²</span></td>
                                </tr>
                                {winner.map((item, idx) => (
                                    <tr key={idx}>
                                        <th>입주자부담금</th>
                                        <td className='text-r'>{item.winner_charges}<span>원</span></td>
                                        <th>입주자추가부담금</th>
                                        <td><input type="text" name="add_charges" className='text-r' id="add_charges" placeholder='입주자추가부담금' value={item.add_charges} onChange={onWinnerAmtChange.bind(this, item)} disabled={isCanChange ? "disabled" : ""} style={{height:'50px'}} /><span>원</span></td>
                                        {idx === 0 && <th rowSpan={winner.length}>쪽방등지원금</th>}
                                        {idx === 0 && <td rowSpan={winner.length}><input type="text" name="small_room_support" className='text-r' id="small_room_support" placeholder='쪽방지원금' value={small_room_support} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} style={{height:"50px"}}/><span>원</span></td>}
                                    </tr>
                                ))}
                                {winner.map((item, idx) => (
                                    <tr key={idx}>
                                        <th>월임대료</th>
                                        <td className='text-r'>{item.monthly_rent}<span>원</span></td>
                                        {idx === 0 && <th rowSpan={winner.length} >보증부월세액</th>}
                                        {idx === 0 && <td rowSpan={winner.length}><input type="text" name="guarantee_monthly_rent" className='text-r' id="guarantee_monthly_rent" placeholder='월세' value={guarantee_monthly_rent} onChange={onChangeNum} disabled={isCanChange || rent_type === "TYPE01" ? "disabled" : ""} style={{height:"50px"}}/><span>원</span></td>}
                                        {idx === 0 && <th rowSpan={winner.length}>채권확보방법</th>}
                                        {idx === 0 && <td rowSpan={winner.length}><input type="text" name="securing_bonds" id="securing_bonds" placeholder='채권확보방법' value={securing_bonds} onChange={onChange} disabled={isCanChange ? "disabled" : ""} style={{height:"50px"}} /></td>}
                                    </tr>
                                ))}
                                <tr>
                                    <th>공인중개사</th>
                                    <td colSpan={3}><input type="text" name="broker_company" id="broker_company" placeholder='공인중개사명' value={broker_company} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /></td>
                                    <th>연락처</th>
                                    <td><input type="text" name="broker_telno" id="broker_telno" placeholder='중개사 연락처' value={broker_telno} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /></td>
                                </tr>
                                <tr>
                                    <th>등기부등본 고유번호</th>
                                    <td><input type="text" name="estate_no" id="estate_no" placeholder='등기 고유번호' value={estate_no} onChange={onChange} disabled={isCanChange ? "disabled" : ""} /></td>
                                    <th>선순위 사용여부</th>
                                    <td>
                                        <div style={{position:'relative'}}>
                                            <input type="radio" name="senior_rent_yn" id="senior_rent_yn_Y" value={"Y"} checked={senior_rent_yn === 'Y'} onChange={onChange} disabled={isCanChange ? "disabled" : ""} />
                                            <label htmlFor="senior_rent_yn_Y">예</label>
                                            <input type="radio" name="senior_rent_yn" id="senior_rent_yn_N" value={"N"} checked={senior_rent_yn === 'N'} onChange={onChange} disabled={isCanChange ? "disabled" : ""} />
                                            <label htmlFor="senior_rent_yn_N" style={{marginLeft:'20px'}}>아니오</label>
                                        </div>
                                    </td>
                                    {
                                        senior_rent_yn === 'N' &&
                                        <>
                                            <th>방 개수</th>
                                            <td><input type="text" name="room_count" id="room_count" value={room_count} onChange={onChangeNum} placeholder='방 개수' disabled={isCanChange ? "disabled" : ""} /></td>
                                        </>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h4>채권확보검토</h4>
                    </div>
                    <div className="board-form">
                        <table>
                            <tbody>
                                <tr>
                                    <th className='text-l pl7' rowSpan={2}>1. 주택가격 ①</th>
                                    <td className="text-r" rowSpan={2}>{house_amt}<span>원</span></td>
                                    <th>가격결정방법</th>
                                    <td colSpan={3}>
                                        <select name="decide_how" id="decide_how" value={decide_how} onChange={onChange} disabled={isCanChange ? "disabled" : ""}>
                                            {cboPricing.map((item) => (
                                                <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>결정일자</th>
                                    <td><input type="date" name="decide_date" id="decide_date" value={decide_date} onChange={onChange} disabled={isCanChange ? "disabled" : ""} max="9999-12-31"/></td>
                                    <th>가격</th>
                                    <td className='text-r'><input type="text" name="decide_amt" id="decide_amt" placeholder='가격' value={decide_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>2. 채권확보가능금액<br/>(①x90%)</th>
                                    <td className="text-r">{possible_bond_amt}<span>원</span></td>
                                    <td className="text-l pl10" colSpan={4}>기지원현황(만료전이주&재계약만 해당)</td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>3. 선순위채권금액</th>
                                    <td className="text-r">{first_bond_amt}<span>원</span></td>
                                    <th>전세금 총액</th>
                                    <th>LH지원금</th>
                                    <th>입주자부담금</th>
                                    <th>보증부월세</th>
                                </tr>
                                <tr>
                                    <th className='text-l pl10'>1) 근저당설정금액</th>
                                    <td className='text-r'><input type="text" className='text-r' name="mortgage_amt" id="mortgage_amt" placeholder='근저당설정금액' value={mortgage_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <td className='text-r'><input type="text" className='text-r' name="pre_deposit_total" id="pre_deposit_total" placeholder='LH지원금' value={pre_deposit_total} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <td className='text-r'><input type="text" className='text-r' name="pre_rent_loans" id="pre_rent_loans" placeholder='LH지원금' value={pre_rent_loans} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <td className='text-r'><input type="text" className='text-r' name="pre_charge_amt" id="pre_charge_amt" placeholder='입주자부담금' value={pre_charge_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <td className='text-r'><input type="text" className='text-r' name="pre_interest_free" id="pre_interest_free" placeholder='보증부월세' value={pre_interest_free} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                </tr>
                                <tr>
                                    <th className='text-l pl10'>2) 전세권설정금액</th>
                                    <td className='text-r'><input type="text" className='text-r' name="rent_set_amt" id="rent_set_amt" placeholder='전세권설정금액' value={rent_set_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <td className="text-l pl10" colSpan={4}>{"<특이사항>"}</td>
                                </tr>
                                <tr>
                                    <th className='text-l pl10'>3) (가)압류금액</th>
                                    <td className='text-r'><input type="text" className='text-r' name="seizure_amt" id="seizure_amt" placeholder='가압류금액' value={seizure_amt} onChange={onChangeNum} disabled={isCanChange ? "disabled" : ""} /><span>원</span></td>
                                    <th>전체 방개수</th>
                                    <td className='text-r'>{total_room_cnt}<span>개</span></td>
                                    <th>임차 방개수</th>
                                    <td className='text-r'>{rent_room_cnt}<span>개</span></td>
                                </tr>
                                <tr>
                                    <th className='text-l pl10'>4) 기존임차인금액</th>
                                    <td className='text-r'>{already_rent_amt}<span>원</span></td>
                                    <th>공실 방개수</th>
                                    <td className='text-r'>{empty_room_cnt}<span>개</span></td>
                                    <td className="text-r" colSpan={2}>
                                        선순위 총 보증금 : {senior_rent_yn === 'Y' ? rent_tot_amt : '0'} 원<br/>
                                        공실 합계 : {senior_rent_yn === 'Y' ? empty_tot_amt : '0'} 원
                                    </td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>4. 채권확보금액(2-3)</th>
                                    <td className='text-r'>{securing_bond_amt}<span>원</span></td>
                                    <td rowSpan={5} colSpan={4}>
                                        <textarea name="remark" id="remark" value={remark} onChange={onChange} className="txtRemark" disabled={isCanChange ? "disabled" : ""} placeholder="내용이 없습니다." />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>5. 차액(4-6)</th>
                                    <td className='text-r'>{difference_amt}<span>원</span></td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>6. 전세보증금</th>
                                    <td className='text-r'>{rent_amt}<span>원</span></td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>7. 부채비율</th>
                                    <td className='text-r'>{debt_ratio}<span>%</span></td>
                                </tr>
                                <tr>
                                    <th className='text-l pl7'>8. 적합여부</th>
                                    <td>{suitable_flag}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h4>선순위 임대차 현황 주소 설정</h4>
                    </div>
                    <div className='priorityBox'>
                        <select name="senior_sido" id="senior_sido" value={senior_sido} onChange={onChange} style={{width: "99.5%"}}>
                            {cboRentSido.map((item, idx) => (
                                <option value={item.code} key={idx}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    {isShowPriority && <EnPriorityContainer {...propDatas}/>}

                    

                    {/* <div>
                        <h4>검토서 참조 내용</h4>
                    </div>
                    <div className="board-form">
                        <table>
                            <tbody>
                                <tr>
                                    <th>작성년월일</th>
                                    <th>작성자</th>
                                    <th colSpan={approval.filter(x => x.app_auth === 'APPROVAL').length}>검토자</th>
                                    <th colSpan={approval.filter(x => x.app_auth === 'APPCHECK').length}>최종결재자</th>
                                </tr>
                                <tr>
                                    <td>{appr_complete_date}</td>
                                    <td>{entitle_user_name}</td>
                                    {approval.map((item, idx) => {
                                        return (
                                            item.app_auth === 'APPROVAL' &&
                                            <td key={idx}>{item.app_name} {item.user_position}</td>
                                        )
                                    })}
                                    {approval.map((item, idx) => {
                                        return (
                                            item.app_auth === 'APPCHECK' &&
                                            <td key={idx}>{item.app_name} {item.user_position}</td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                </div>

                <div className='enReference'>
                    <div className='divTop'>
                        <div>
                            <div className='tblDiv'>
                                <div>
                                    <span className={doc_status === 'ENREQ' ? 'currentStep' : ''}>신청</span>
                                    <span className={doc_status === 'ENACK' ? 'currentStep' : ''}>접수</span>
                                    <span className={doc_status === 'ENDOCFAIL' ? 'currentStep' : ''}>서류미비</span>
                                    <span className={doc_status === 'ENDOCADD' ? 'currentStep' : ''}>서류보완</span>
                                    <span className={doc_status === 'ENPROCESS' ? 'currentStep' : ''}>분석중</span>
                                    <span className={isFeedback === "P" ? 'feedbackStep' : ''}>피드백요청</span>
                                    <span className={isFeedback === "Y" ? 'feedbackStep' : ''}>피드백응답</span>
                                    <span className={(doc_status === 'ENAPVREQ' || doc_status === 'ENAPVOK') ? 'currentStep' : ''}>결재요청</span>
                                    <span className={doc_status === 'ENAPVCHK' || doc_status === 'ENAPVFAIL' ? 'currentStep' : ''}>결재완료</span>
                                </div>
                            </div>
                            {/* <div className='tblDiv'>
                                <div>피드백 내용</div>
                                <textarea value={feedback_answer} readOnly={true}/>
                            </div> */}
                        </div>
                        <div className='tblAppr'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th colSpan={approval.length + 1}>결재 상태</th>
                                    </tr>
                                    <tr>
                                        <th>성명</th>
                                        {approval.map((item, idx) => (
                                            <td key={idx}>{item.app_name}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th>직책</th>
                                        {approval.map((item, idx) => (
                                            <td key={idx}>{item.user_position}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th>결재일</th>
                                        {approval.map((item, idx) => (
                                            <td key={idx}>{item.app_date}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='refTab'>
                        <div className={(refType ? 'refTabOn' : '')} onClick={() => onRefChange(true)}>
                            <span>첨부 이미지</span>
                            <span onClick={() => onRefreshImg()} className='refreshIcon'>새로고침</span>
                        </div>
                        <div className={(!refType ? 'refTabOn' : '')}>
                            <span onClick={() => onRefChange(false)}>DATA 정보</span>
                            <span onClick={() => setIsChangeApi(true)} className='refreshIcon'>새로고침</span>
                        </div>
                        <div className={isFeedback === 'Y' ? 'feedbackTab' : ''} onClick={() => isFeedback === 'Y' ? setIsShowFeedback(docNo) : ALERT('피드백 내용이 없습니다.')}>
                            <span>FEEDBACK</span>
                        </div>
                        <div className={'feedbackTab'} onClick={() => setIsShowWinner(!isShowWinner)}>
                            <span>당첨자 정보</span>
                        </div>
                    </div>
                    <div className={'divRef ' + (refType ? 'showRef' : 'hideRef')}>
                        <div className='folderBox'>
                            <button onClick={() => onPrevClick('IMG')} className="left-btn">◀</button>
                            <div ref={imgFolderDiv}>
                                <div className='folderSlider' ref={imgFolderSlider}>
                                    {docFolder.map((item) => (
                                        item.code !== "DOCUDF" &&   //미분류 폴더는 제외
                                        <div key={item.code} onClick={onSelectFolder.bind(this, item)} className={item.code === selectFoler ? 'folder-on' : ''}>{item.name}</div>
                                    ))}
                                </div>
                            </div>
                            <button onClick={() => onNextClick('IMG')}>▶</button>
                        </div>
                        <div className='viewArea'>
                            <div id='imgView' className='imgView'>
                                {selectImg && <img src={rotate} alt='방향 전환' title={'방향 전환'} className='icon-rotate' onClick={onImgRotation} />}
                                {selectImg && selectImg.api_yn === 'N' && <img src={savedisk} alt='방향 저장' title={'방향 저장'} className='icon-savedisk' onClick={onImgRotateSave} />}
                                {
                                    selectImg && 
                                    <div id='div-rotate' style={{transform:'rotate(' + selectImg.rotation + 'deg)'}}>
                                        <img id="selectImg" className='selectImg' src={selectImg.src} draggable={false} onError={onWaiting} alt={''} />
                                    </div>
                                }
                            </div>
                            <div className='navImgBox'>
                                {docImages.map((item, idx) => (
                                    item.docCode === selectFoler &&
                                    <div key={idx} className='navImg'>
                                        <img src={item.imgSrc} alt="" onClick={onSelectImg.bind(this, item)} onError={onWaiting} className={(item.imgSrc === selectImg.src ? "selete-thumbnail" : "") + (" rotate-" + item.rotation)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={'divRef ' + (!refType ? 'showRef' : 'hideRef')}>
                        <div className='folderBox'>
                            <button onClick={() => onPrevClick('TXT')}>◀</button>
                            <div ref={txtFolderDiv}>
                                <div className='folderSlider' ref={txtFolderSlider}>
                                    {txtFolder.map((item) => (
                                        <div key={item.code} onClick={onSelectTxtFolder.bind(this, item)} className={item.code === selectTxtFolder ? 'folder-on' : ''}>{item.name}</div>
                                    ))}
                                </div>
                            </div>
                            <button onClick={() => onNextClick('TXT')} className="right-btn">▶</button>
                        </div>
                        <div className='viewArea'>
                            <div className='selectTxt'>
                                <EntitleViewerContainer {...viewerDatas} />
                            </div>
                        </div>
                    </div>
                    {isShowFeedback && <FeedbackContainer {...propDatas}/>}
                </div>
                </div>
            </div>

            {isShowDocUpload && <EnDocUploadContainer {...propDatas}/>}
            {isShowDocFail && <EnDocFailContainer {...propDatas}/>}
            {/* {isShowApprove && <EnApproveContainer {...propDatas}/>} */}
            {isShowMemo && <EnCallerMemoContainer {...propDatas}/>}
            {/* {isShowPriority && <EnPriorityContainer {...propDatas}/>} */}
            {/* {isShowFeedback && <FeedbackContainer {...propDatas}/>} */}
            {isShowWinner && <WinnerInfoContainer {...propDatas}/>}

            {
                isShowPassword &&
                <div className='popupModal'>
                    <div />
                    <div className='cancelCheck'>
                        <h2>권리분석 취소 확인</h2>
                        <div>
                            <div className='pwipt'>
                                <label>접수자 비밀번호</label>
                                <input type="password" name="user_pwd" id="user_pwd" value={user_pwd} onChange={onChange} disabled={pwdCheckResult === 'OK' ? 'disabled' : ''} placeholder='비밀번호' />
                                <button onClick={onCancelPwChk} className="save-btn" disabled={pwdCheckResult === 'OK' ? 'disabled' : ''} style={{width:"120px"}}>비밀번호 확인</button>
                            </div>
                            <div>
                                {
                                    (pwdCheckResult === 'OK' && <span className='rst-ok'>비밀번호가 확인되었습니다.</span>) ||
                                    (pwdCheckResult === 'NG' && <span className='rst-ng'>비밀번호가 일치하지 않습니다.</span>)
                                }
                            </div>
                        </div>
                        <div className='divButtons'>
                            <button onClick={onExecCancel} className="divB-Save">권리분석 취소</button>
                            <button onClick={onCancelClick} className="divB-Cancel">닫기</button>
                        </div>
                    </div>
                </div>
            }
            {
                isShowEnModify &&
                <div className='popupModal'>
                    <div />
                    <div className='enmodify'>
                        <h2>검토서 변경요청 사유</h2>
                        <div className='reason'>
                            <div><label>변경 요청일 : </label>{work_change_date}</div>
                            <div><label>변경 요청자 : </label>{work_change_user}</div>
                            <div><label>변경 요청사유 : </label>{work_change_req}</div>
                        </div>
                        <div className='divButtons'>
                            <button onClick={onEnModifyReasonClick} className="divB-Cancel">닫기</button>
                        </div>
                    </div>
                </div>
            }
        </EntitleWorkBlock>
    );
}

export default EntitleWork;