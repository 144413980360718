import React from 'react';
import ImplyResultContainer from '../containers/ImplyResultContainer';
import HeaderContainer from '../containers/HeaderContainer';

const ImplyResultPage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyResultContainer />
        </>
    );
}

export default ImplyResultPage;