import React from 'react';
import AuditListContainer from '../containers/AuditListContainer';
import HeaderContainer from '../containers/HeaderContainer';

const AuditListPage = () => {
    return (
        <>
            <HeaderContainer />
            <AuditListContainer />
        </>
    );
}

export default AuditListPage;