import React from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';

const PrintSetPopup = styled.div`
    .popupModal > div:nth-child(2){
        width: 800px;
        max-height: 80vh;
        box-sizing: border-box;
        padding: 30px 50px;
        overflow-y: scroll;
    }
    .containerDiv{
        height: 100%;
        overflow-y: auto;
        color: #545454;
    }

    .divButtons{
        margin-top:50px;
    }
    .divButtons .print-btn{
        margin-right: 10px
    }
`

const ContractPrintSetModal = (props) => {
    const { onClickCancel,
            printList,
            printDocCodeList,
            handleBeforeGetContent,
            docPrintRef,
            onClickAllCheck,
        } = props;

    return (
        <PrintSetPopup>
            <div className='popupModal'>
                <div />
                <div>
                    <div className='containerDiv'>
                        <h4 className='text-c' style={{fontSize : '20px'}}>전체출력 설정</h4>
                        <div className='mt50'>
                            <div>
                                <input id='print-check-all' name="use_flag" type="checkbox" defaultChecked={true} onChange={onClickAllCheck}/>
                                <label htmlFor='print-check-all'>전체선택</label>
                            </div>
                            <div id="contract-print-list">
                                {printList.length > 0 &&
                                    printList.map((doc, idx) => (
                                        printDocCodeList.includes(doc.sub_code) && doc.use_flag === 'Y' &&
                                        <div key={idx}>
                                            <input id={'print-check' + idx} name="use_flag" type="checkbox" value={doc.use_flag} defaultChecked={true} data-display={doc.display} data-doc-code={doc.sub_code}/>
                                            <label htmlFor={'print-check' + idx}>{doc.code_name}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='divButtons'>
                            <ReactToPrint
                                trigger={()=><button className='divB-Save'>출력하기</button>}
                                content={()=>docPrintRef.current}
                                onBeforeGetContent={handleBeforeGetContent}
                            />
                            <button className="divB-Cancel" onClick={onClickCancel}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </PrintSetPopup>
    );
};

export default ContractPrintSetModal;