import React from 'react';
import ContractProcContainer from '../containers/ContractProcContainer';
import HeaderContainer from '../containers/HeaderContainer';

const ContractProcPage = () => {
    return (
        <>
            <HeaderContainer />
            <ContractProcContainer />
        </>
    );
}

export default ContractProcPage;