import React from 'react';
import RegisterContainer from '../containers/RegisterContainer';

const RegisterPage = () => {
    return (
        <>
            <RegisterContainer/>
        </>
    );
}

export default RegisterPage;