import client from './client';

export const login = ({ input_id, input_pw }) =>
    client.post('/api/auth/login', { input_id, input_pw });

export const register = (body) =>
    client.post('/api/auth/register', body);

export const getUsers = (body) =>
    client.post('/api/auth/search', body);

export const authUpdate = (body) =>
    client.post('/api/auth/authUpdate', body);

export const check = () =>
    client.get('/api/auth/check');

export const logout = () =>
    client.get('/api/auth/logout');

export const search = (body) =>
    client.post('/api/auth/search', body);

export const view = (user_id) => 
    client.get(`/api/auth/view/` + user_id);

export const idcheck = (user_id) => 
    client.get(`/api/auth/user_chk/` + user_id);

export const tempPwd = (body) => 
    client.post('/api/auth/temppwd', body);

export const mainCount = (body) => 
    client.post('/api/auth/main_count', body);

export const pwdCheck = (body) => 
    client.post('/api/auth/pwdCheck', body);

export const authArea = (body) => 
    client.post('/api/auth/authArea', body);

export const cert_req = (body) => 
    client.post('/api/auth/cert_req', body);

export const cert_chk = (body) => 
    client.post('/api/auth/cert_chk', body);

export const pwd_change = (body) => 
    client.post('/api/auth/pwd_change', body);
    
export const approver_search = (body) =>
    client.post('/api/auth/approver_search', body);