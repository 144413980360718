import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { logout } from '../modules/user';

const HeaderContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user, header, menu} = useSelector(({user, header}) => ({
        user: user.user,
        header: header.header,
        menu: user.menu,
    }))

    //  뒤로
    const onNavBackClick = () => {
        navigate(-1);
    }
    // 로그아웃
    const onClickLogout = () => {
        dispatch(logout());
    }

    const propDatas = {
        user,
        header,
        menu,

        onNavBackClick,
        onClickLogout,
    }

    return (
        <>
            <Header {...propDatas}/>
        </>
    );
}

export default HeaderContainer;