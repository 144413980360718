import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';

const INITIALIZE = 'findBroker/INITIALIZE';
const CHANGE_VALUE = 'findBroker/CHANGE_VALUE';
const [GET_BROKER, GET_BROKER_SUCCESS, GET_BROKER_FAILURE] = createRequestActionTypes('codeMgt/GET_BROKER');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getBroker = createAction(GET_BROKER, (body) => (body));

const getBrokerSaga = createRequestSaga(GET_BROKER, entitleAPI.broker_search);

export function* findBrokerSaga() {
    yield takeLatest(GET_BROKER, getBrokerSaga);
}

const initialState = {
    brokerName: '',
    brokerNumber: '',
    brokerTelno: '',

    brokers: [],

    findBroker: null,
    error: null
};

const findBroker = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [GET_BROKER_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                brokers: payload,
            }
        },
        [GET_BROKER_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default findBroker;