import React from 'react';
import styled from 'styled-components';

const ApplyMgtBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 20px 50px 0;
    box-sizing: border-box;

    .containerDiv {
        display: flex;
        align-items: flex-start;
        height: 78vh;
        gap: 45px;
        overflow-y: auto;
        border: 1px solid #ccc;
    }

    .dkgreen-btn{
        margin-bottom: 15px;
    }
    .up-btn, .down-btn {
        padding: 5px 10px;
    }
    .up-btn:hover, .down-btn:hover {
        border: 1px solid white;
        background-color: #ccc;
    }
`;

const ApplyMgt = (props) => {
    const {
        applyList,
        dataEntitleCalc,
        
        onRowChange,
        onAddRow,
        onRemoveRow,
        onRowSort,
        onSave,
    } = props;

    return (
        <ApplyMgtBlock>
            <button onClick={onSave} className="dkgreen-btn">저장</button>

            <div className='containerDiv'>
                <table className='border-tt'>
                    <thead>
                        <tr>
                            <th><button onClick={onAddRow} className="add-btn">추가</button></th>
                            <th>지원유형</th>
                            <th>지원금 산출 유형</th>
                            <th>순서</th>
                            <th>사용여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applyList.map((item, idx) => (
                            <tr key={idx}>
                                <td>{!item.sub_code && <button onClick={onRemoveRow.bind(this, item)} className="del-btn">삭제</button>}</td>
                                <td><input type="text" name="code_name" id="code_name" value={item.code_name || ""} onChange={onRowChange.bind(this, item)} /></td>
                                <td>
                                    <select name="memo" id="memo" value={item.memo} onChange={onRowChange.bind(this, item)}>
                                        {dataEntitleCalc.map((item) => (
                                            <option value={item.codeIdx} key={item.codeIdx}>{item.typeName}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button onClick={onRowSort.bind(this, item, 'UP')} className="up-btn">▲</button>
                                    <button onClick={onRowSort.bind(this, item, 'DOWN')} className="down-btn">▼</button>
                                </td>
                                <td>
                                    <select name="use_flag" id="use_flag" value={item.use_flag} onChange={onRowChange.bind(this, item)}>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </ApplyMgtBlock>
    );
}

export default ApplyMgt;