import React from 'react';
import styled from 'styled-components';
import FindUserContainer from '../containers/Modal/FindUserContainer';
import PrivacyTermsModal from './Modal/PrivacyTermsModal';

const EntitleWriteStep1Block = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 30px 50px;
    box-sizing: border-box;

    h2{
        color: #545454;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
        display: inline-block
    }
    .btn{
        text-align: center;
        margin: 20px 0;
    }
    div:first-child button{
        display:inline-block;
        margin-left: 5px;
        
    }    
    .tt.mobile .wrap{
        display: flex;
    }
    .tt.mobile .mo{
        display: none;
    }
    .tt.mobile .wrap > div {
        min-width: 20px;
        padding : 10px 0 ;
        box-sizing:border-box;
    }
    .tt.mobile .wrap >div:nth-child(1){
        width: 7%;
        min-width:80px
    }
    .tt.mobile .wrap >div:nth-child(2){
        width: 7%;
        min-width:80px
    }
    .tt.mobile .wrap >div:nth-child(3){
        width: 11%;
        min-width:80px
    }
    .tt.mobile .wrap >div:nth-child(4){
        width: 7%;
        min-width:50px
    }
    .tt.mobile .wrap >div:nth-child(5){
        width: 11%;
        min-width:100px
    }
    .tt.mobile .wrap >div:nth-child(6){
        width: 30%;
        
    }
    .tt.mobile .wrap >div:nth-child(7){
        width: 10%;
        
    }
    .tt.mobile .wrap >div:nth-child(8){
        width: 10%;
        
    }
    .tt.mobile .wrap >div:nth-child(9){
        width: 7%;
    }
    .pcMr5{
        margin-right: 5px;
    }

    // tbody tr{
    //     text-align: center;
    //     box-sizing: border-box;
    // }
    .tt.mobile select{
        height: 26px;
        border-radius: 2px;
        border: 1px solid #BDBDBD;
        box-sizing: border-box;
    }
    .StepButtons{
        display: flex;
        justify-content: center;
    }
    .StepButtons button{
        margin: 0 10px;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px) {
        position: relative;
        padding: 0;
        fieldset{
            padding: 0 50px;
        }
        h2{
            padding: 30px 50px 0 50px;
        }
        .tt{
            white-space: normal;
        }
        .tt.mobile .pc{
            display : none !important
        }
        .tt.mobile .mo{
            display :  block;
        }
        .tt>div{
            border-bottom:0;
        }
        .tt.mobile .thead .wrap:not(:first-child){
            border-top:5px solid #cfcfcf
        }
        .tt.mobile .tbody .wrap:not(:first-child){
            border-top:5px solid #cfcfcf
        }

        .tt.mobile .thead .wrap>div,
        .tt.mobile .tbody .wrap>div{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .tt.mobile .thead .wrap,
        .tt.mobile .tbody .wrap{
            display:block;
            height:auto
        }
        .tt.mobile .thead{
            // width: 13%;
            width:auto;
            display:block;
            float:left;
        }
        .tt.mobile .thead .wrap>div{
            width:auto;
            min-width: 120px;
            border-bottom:1px solid #cfcfcf;
            border-right:1px solid #cfcfcf;
            padding: 10px 10px;
            height:50px;
        }

        .tt.mobile .thead .wrap>div:nth-child(6){
            min-height:30px;
            height:65px;
            line-height:3
        }
        .tt.mobile .thead .wrap>div:last-child{
            border-bottom:0
        }
        .tt.mobile .tbody{
            // width: 87%;
            display: block;
            width:auto;
            // white-space:nowrap
        }
        .tt.mobile .tbody .wrap>div{
            width: auto;
            flex-wrap: wrap;
            padding: 10px 10px;
            height:50px;
            border-bottom: 1px solid #cfcfcf;
        }
        .tt.mobile .tbody .wrap>div:nth-child(6){
            min-height:30px;
            height:65px;
            font-size: 11px;
        }
        .tt.mobile .tbody .wrap>div:last-child{
            border-bottom:0
        }
        .tt .tbody .wrap:hover{
            background:none;
        }
        .pcMr5{
            margin-right: 0px;
        }
        .StepButtons{
            width: 100%;
            position: absoulte;
            bottom: 0;
            left: 0;
        }
        .StepButtons button{
            width: 100%;
            font-size: 16px;
            margin: 0px !important;
        }
        .cancel-btn{
            width: 50%; 
            height: 48px; 
            color: #ffffff; 
            font-size: 14px; 
            font-weight: 600; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 0px; 
            border: none; 
            background-color: #808080; 
        }
        .save-btn{
            width: 50%; 
            height: 48px;
            color: #ffffff;
            border-radius: 0px; 
            border: none; 
            background-color: #0B999C;
        }
        .next-btn{ 
            width: 50%; 
            height: 48px; 
            color: #ffffff; 
            font-size: 14px; 
            font-weight: 600; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 0px; 
            border: none;
            background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        }
        
    }

    fieldset {
        border: none;
    }
    .win-ipt {
        width: 100%;
    }
`;

const PrivacyTermsAgreeBlock = styled.div`
    margin-bottom: 50px;

    .text-with-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nameColor{
        color: #0B999C;
    }

    .nameUnderline{
        cursor: pointer;
        color: #0B999C;
        text-decoration: underline #0B99
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .fieldset{
            padding: 0 50px;
        }
        .nameUnderline{
            padding: 0 50px;
        }
    }
`;

const EntitleWriteStep1 = (props) => {
    const {
        doc_status,
        user,

        cboLease,
        cboApply,

        winner,

        isShowFindUser,

        cantModify,

        //개인정보 수집 및 이용 동의 내용보기 모달
        privacy_terms_agree,
        isShowPrivacyTerms,
        setIsShowPrivacyTerms,
        onChecked,

        onWinnerChange,
        onAddWinner,
        onRemoveWinner,
        onShowFindUser,
        onCloseFindUser,
        onSelectUser,
        onNextClick,
        onDeleteWrite,
        // onModifyMode,
    } = props;

    const propDatas = {
        onCloseFindUser,
        onSelectUser,
    }
    return (
        <EntitleWriteStep1Block>
            <PrivacyTermsAgreeBlock>
                <div className="text-with-btn">
                    <h2>개인정보 수집 및 이용 안내</h2>
                    <p className="nameUnderline" onClick={()=>setIsShowPrivacyTerms(true)}>내용보기</p>
                </div>
                <div className='fieldset'>
                    <div>
                        <input type="checkbox" id="privacy_terms_agree" name="privacy_terms_agree" onChange={onChecked} checked={privacy_terms_agree}/>
                        <label htmlFor="privacy_terms_agree"><span className='nameColor'>(필수)</span>개인정보 수집 및 이용하는 것에 동의합니다.<br />
                            (개인정보 수집 동의를 거부하실 수 있으며, 다만 이 경우 서비스 이용이 제한됩니다)</label>
                    </div>
                </div>
            </PrivacyTermsAgreeBlock>
            <div>
                <h2>지원 신청자 정보</h2>
            </div>
            <fieldset disabled={cantModify}>
                <div className='tt mobile'>
                    <div className='thead pc'>
                        <div className='wrap'>
                            {user.user_auth !== 'WINNER' && <div>당첨자</div>}
                            <div>계약유형</div>
                            <div>지원유형</div>
                            <div>성명</div>
                            <div>생년월일</div>
                            <div>주소</div>
                            <div>집전화</div>
                            <div>휴대전화</div>
                            {user.user_auth !== 'WINNER' && <div>삭제</div>}
                        </div>
                    </div>
                    <div className='thead mo'>
                    {winner.map((item, idx) => (
                        <div className="wrap" key={idx}>
                            {user.user_auth !== 'WINNER' && <div>당첨자</div>}
                            <div>계약유형</div>
                            <div>지원유형</div>
                            <div>성명</div>
                            <div>생년월일</div>
                            <div>주소</div>
                            <div>집전화</div>
                            <div>휴대전화</div>
                            {user.user_auth !== 'WINNER' && <div>삭제</div>}
                        </div>
                    ))}
                    </div>
                    <div className='tbody'>
                        {winner.map((item, idx) => (
                            <div className="wrap" key={idx}>
                                {
                                    user.user_auth !== 'WINNER' &&
                                    <div><button onClick={onShowFindUser.bind(this, item)} className='sch-btn'>검색</button></div>
                                }
                                <div>
                                    {
                                        (
                                            user.user_auth === 'WINNER' &&
                                            (item.contract_type === 'NEW' || item.contract_type === 'MOVE')
                                        ) ?
                                        item.contract_type_name
                                        :
                                        <select name="contract_type" id="contract_type" value={item.contract_type} onChange={onWinnerChange.bind(this, item)}>
                                            {cboLease.map((item, idx) => (
                                                <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                            ))}
                                        </select>
                                    }
                                </div>
                                <div>
                                    {/* {item.winner_type_name} */}
                                    {
                                        (item.select_win_type === 'Y' || item.info_yn === 'Y') ?
                                        <select name="winner_type" id="winner_type" value={item.winner_type} onChange={onWinnerChange.bind(this, item)} style={{width:'100%'}}>
                                            {cboApply.map((item, idx) => (
                                                <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                                ))}
                                        </select>
                                        :
                                        item.winner_type_name
                                    }
                                </div>
                                <div className='pcMr5'>{item.winner_name}</div>
                                <div>{item.winner_no}</div>
                                <div>{item.winner_addr}</div>
                                <div>{item.winner_tel}</div>
                                <div>{item.winner_phone}</div>
                                {
                                    (!doc_status || doc_status === 'ENWRITE') &&
                                    user.user_auth !== 'WINNER' &&
                                    <div><button onClick={onRemoveWinner.bind(this, item)} className='del-btn'>ㅡ삭제</button></div>
                                }
                            </div>
                        ))}
                    </div>
                </div>

                <div className='btn'>
                    {
                        (!doc_status || doc_status === 'ENWRITE') &&
                        user.user_auth !== 'WINNER' &&
                        <button onClick={onAddWinner} className='add-btn'>+추가</button>
                    }
                </div>
            </fieldset>
            
            <div className='StepButtons'>
                {   //  작성중 상태에서만 삭제가 가능. 
                    doc_status === 'ENWRITE' && <button onClick={onDeleteWrite} className="cancel-btn">신청서 삭제</button>
                }
                {   //  신청서확인 상태에서만 취소가 가능. 
                    doc_status === 'ENSAVE' && <button onClick={onDeleteWrite} className="cancel-btn">신청서 취소</button>
                }
                {/* {(cantModify && doc_status === 'ENREQ') && <button onClick={onModifyMode} className="save-btn">내용 수정</button>} */}
                <button onClick={onNextClick} className="next-btn">다음 단계</button>
            </div>

            {isShowFindUser && <FindUserContainer {...propDatas}/>}
            {isShowPrivacyTerms && <PrivacyTermsModal {...propDatas} setIsShowPrivacyTerms={setIsShowPrivacyTerms}/>}
        </EntitleWriteStep1Block>
    );
}

export default EntitleWriteStep1;