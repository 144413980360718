import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MenuAuth from '../components/MenuAuth';
import { menuChangeValue, getAuth, menuBinding, saveMenu, getMenu, initialize } from '../modules/menuAuth';

const MenuAuthMgtContainer = () => {
    const [authCode, setAuthCode] = useState();
    const dispatch = useDispatch();
    const location = useLocation();
    const {state: {id}} = location;
    const {
        auth,
        menu,

        menuAuth,
        error,
    } = useSelector(({ menuAuth }) => ({
        auth: menuAuth.auth,
        menu: menuAuth.menu,

        menuAuth: menuAuth.menuAuth,
        error: menuAuth.error,
    }));

    const onRowClick = (item) => {
        setAuthCode(item.sub_code);

        dispatch(
            menuBinding()
        );
        dispatch(
            getMenu(item.sub_code)
        );
    }
    const onAuthChange = (e, item) => {
        dispatch(
            menuChangeValue({
                id: item.id,
                key: e.target.name,
                value: e.target.checked,
            })
        );
    };
    const onSaveClick = () => {
        let arrAuth = "";
        
        menu.forEach(item => {
            if (item.auth_yn) {
                arrAuth += item.id + ",";
            }

            if (item.func.length > 0) {
                item.func.forEach(func => {
                    if(func.auth_yn) {
                        arrAuth += func.id + ",";
                    }
                });
            }
        });
        arrAuth = arrAuth.substring(0, arrAuth.length - 1);
        const body = {
            main_code: 'AUTH',
            sub_code: authCode,
            auth: arrAuth
        }
        dispatch(
            saveMenu(body)
        );
    }

    useEffect(() => {

        if(dispatch && id) {
            dispatch(
                getAuth({
                    main_code: 'AUTH',
                    admin_type: id==="MENU99"? "SYSTEM" : "USER"
                })
            );

            if(id === "MENU99") {
                dispatch(
                    menuBinding()
                );
            }
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    const propDatas = {
        auth,
        menu,
        
        menuAuth,
        error,
        onRowClick,
        onAuthChange,
        onSaveClick,
    }

    return (
        <>
            <MenuAuth {...propDatas}/>
        </>
    );
}

export default MenuAuthMgtContainer;