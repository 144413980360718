export const dataWORD = {
    BIRTHDATE:      '생년월일',
    COMPANYCODE:    '법인등록번호',
    INFOCOMMENT:    '문의 1600 – 4690, \u00a0\u00a0\u00a0카카오톡 플러스친구 : 신세기(LH서울)'
}

export const ALERT = cmt => {
    alert(cmt + '\n' + dataWORD.INFOCOMMENT);
}

export default dataWORD