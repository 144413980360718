import { createAction, handleActions } from 'redux-actions';
// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const INITIALIZE = 'header/INITIALIZE';
const SET_HEADER = 'header/SET_HEADER';
const CHANGE_VALUE = 'header/CHANGE_VALUE';
// const [ACTION, ACTION_SUCCESS, ACTION_FAILURE] = createRequestActionTypes('header/ACTION');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('header/REACTION');

export const initialize = createAction(INITIALIZE);
export const setHeader = createAction(SET_HEADER, (headerItem) => (headerItem));
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
// export const action = createAction(ACTION, data => data);

// const actionSaga = createRequestSaga(ACTION, actionAPI.action);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* headerSaga() {
    // yield takeLatest(ACTION, actionSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const initialState = {
    header: [],
    error: null
};

const header = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [SET_HEADER]: (state, {payload}) => {
            return ({
                ...state,
                header: state.header.concat(payload)
            })
        }
    },
    initialState,
);

export default header;