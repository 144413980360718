import React from 'react';
import { ShowTelDash } from '../../lib/dataUTIL';
import dataWORD from '../../lib/dataWord';
import { ToLocaleString } from '../../common';

const ApplicationFormView = ({write, winner, priority, work}) => {
    if(!write || !winner || !priority || !work ){
        return (
            <>
                API 정상동작 되지 않음
            </>
        )
    }else{
    return (
        <div className='tableWrap apply'>
            <div className='viewerContainer borderwrap bd0' id="printTXT01">
            <table>
                <thead>
                    <tr>
                        <th colSpan={7} style={{fontSize : '24px'}}>전세임대 지원신청서</th>
                    </tr>
                </thead>
                {
                    winner && Array.isArray(winner) && winner.length > 0 && (
                    (winner.length > 1) ? (
                        winner.map((item, idx) => (
                            <tbody key={item.idx}>
                                <tr>
                                    <th style={{width:"10%"}} rowSpan={4}>신청인{idx + 1}</th>
                                    <th style={{width:"10%"}}>성명</th>
                                    <td style={{width:"35%"}} colSpan={2}>{item.winner_name}</td>
                                    <th style={{width:"10%"}}>{dataWORD.BIRTHDATE}</th>
                                    <td colSpan={2}>{item.winner_no}</td>
                                </tr>
                                <tr>
                                    <th>주소</th>
                                    <td colSpan={5}>{item.winner_addr}</td>
                                </tr>
                                <tr>
                                    <th>전화번호</th>
                                    <th style={{width:"12%"}}>집전화</th>
                                    <td>{ShowTelDash(item.winner_tel)}</td>
                                    <th>휴대폰</th>
                                    <td colSpan={2}>{ShowTelDash(item.winner_phone)}</td>
                                </tr>
                                <tr>
                                    <th>신청유형</th>
                                    <td colSpan={5}>{item.winner_type_name}</td>
                                </tr>
                            </tbody>
                        ))
                    ):
                        <tbody>
                            <tr>
                                <th style={{width:"10%"}} rowSpan={4}>신청인1</th>
                                <th style={{width:"10%"}}>성명</th>
                                <td style={{width:"35%"}} colSpan={2}>{winner[0].winner_name}</td>
                                <th style={{width:"10%"}}>{dataWORD.BIRTHDATE}</th>
                                <td colSpan={2}>{winner[0].winner_no}</td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colSpan={5}>{winner[0].winner_addr}</td>
                            </tr>
                            <tr>
                                <th>전화번호</th>
                                <th style={{width:"12%"}}>집전화</th>
                                <td>{ShowTelDash(winner[0].winner_tel)}</td>
                                <th>휴대폰</th>
                                <td colSpan={2}>{ShowTelDash(winner[0].winner_phone)}</td>
                            </tr>
                            <tr>
                                <th>신청유형</th>
                                <td colSpan={5}>{winner[0].winner_type_name}</td>
                            </tr>
                            <tr>
                                <th style={{width:"10%"}} rowSpan={4}>신청인2</th>
                                <th style={{width:"10%"}}>성명</th>
                                <td style={{width:"35%"}} colSpan={2}></td>
                                <th style={{width:"10%"}}>{dataWORD.BIRTHDATE}</th>
                                <td colSpan={2}></td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td colSpan={5}></td>
                            </tr>
                            <tr>
                                <th>전화번호</th>
                                <th style={{width:"12%"}}>집전화</th>
                                <td></td>
                                <th>휴대폰</th>
                                <td colSpan={2}></td>
                            </tr>
                            <tr>
                                <th>신청유형</th>
                                <td colSpan={5}></td>
                            </tr>
                        </tbody>
                    )
                }
                {/* <tbody>
                    <tr>
                        <th style={{width:"10%"}} rowSpan={4}>신청인2</th>
                        <th style={{width:"10%"}}>성명</th>
                        <td style={{width:"35%"}} colSpan={2}></td>
                        <th style={{width:"10%"}}>주민등록번호</th>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <th>주소</th>
                        <td colSpan={5}></td>
                    </tr>
                    <tr>
                        <th>전화번호</th>
                        <th style={{width:"8%"}}>집전화</th>
                        <td></td>
                        <th>휴대폰</th>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <th>신청유형<br/>(ㅁ란 v표시)</th>
                        <td colSpan={5}></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th style={{width:"10%"}} rowSpan={4}>신청인3</th>
                        <th style={{width:"10%"}}>성명</th>
                        <td style={{width:"35%"}} colSpan={2}></td>
                        <th style={{width:"10%"}}>주민등록번호</th>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <th>주소</th>
                        <td colSpan={5}></td>
                    </tr>
                    <tr>
                        <th>전화번호</th>
                        <th style={{width:"8%"}}>집전화</th>
                        <td></td>
                        <th>휴대폰</th>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <th>신청유형<br/>(ㅁ란 v표시)</th>
                        <td colSpan={5}></td>
                    </tr>
                </tbody> */}
                <tbody>
                    <tr>
                        <th style={{width:"10%"}} rowSpan={13}>입주희망<br/>주택현황</th>
                        <th>주소</th>
                        <td colSpan={5}>{write.road_addr} {write.road_addr_dtl}({write.jibun_addr} {write.road_addr_dtl})</td>
                    </tr>
                    
                    <tr>
                        <th rowSpan={3}>주택유형</th>
                        <td rowSpan={3} colSpan={2}>{work.house_type_name}</td>
                        <th rowSpan={2}>면적</th>
                        <td colSpan={2}>{work.house_area} m²(방 {work.rent_room_cnt} 개)</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>※ 85m² 이하 (1인세대 60m² 이하)</td>
                    </tr>
                    <tr>
                        <th>매매중 여부</th>
                        <td colSpan={2}>예&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;아니오</td>
                    </tr>
                    <tr>
                        <th rowSpan={2}>전세유형</th>
                        <th>전세</th>
                        <td colSpan={4}>전세금액 : {write.rent_amt} <span>원</span></td>
                    </tr>
                    <tr>
                        <th>보증부월세</th>
                        <td colSpan={2}>전세금액 : <span>원</span></td>
                        <td colSpan={2}>월세금액 : {write.monthly_rent_amt} 원</td>
                    </tr>
                    <tr>
                        <th rowSpan={7} colSpan={2}>선순위 임대차현황 등<br/>(중개대상물 확인, 설명서 양식의<br/>⑨번항목에 이기하되 등기부상<br/>등재된 금액 제외</th>
                        <td className="text-l pl10" colSpan={4} style={{width:"20%"}}>
                            ＊지원대상 주택이 개별주택(단독, 다가구, 상가주택 등)인 경우에 한해 작성<br/>
                            ＊공부상 용도와 상관없이 실제 주거가 가능한 공간 모두 포함<br/>
                            ＊(입주희망 호수)는 임대중이든 공실이든 아래 표 작성 대상에서 제외<br/>
                            ＊(상가 및 주택 혼합건물)일 경우 상가 임차보증금 및 상가 공실도 포함
                        </td>
                    </tr>
                    <tr>
                        <th>구분</th>
                        <th>호수</th>
                        <th>방수</th>
                        <th>선순위 임차보증금</th>
                    </tr>
                    <tr>
                        <th>전체(① + ②)</th>
                        <td>{priority.length > 0 && (priority.length - 1)}</td>
                        <td>{priority.filter(item => item.empty_type2 !== 'HOUSE_WANT').reduce((sum, item) => (sum + item.room_cnt2), 0)}</td>
                        <td>{priority.length > 0 && ToLocaleString(parseInt(priority[0].rent_tot_amt?priority[0].rent_tot_amt:'') + parseInt(priority[0].empty_tot_amt?priority[0].empty_tot_amt:''))}<span>원</span></td>
                    </tr>
                    <tr>
                        <th>임대중①</th>
                        <td>{(priority.length > 0 && (priority.length - 1)) - (priority.length > 0 && (priority.filter(item => ((item.empty_type2 === 'HOUSE_OWNER') || (item.empty_type2 === 'HOUSE_EMPTY') || (item.empty_type2 === 'STORE_OWNER') || (item.empty_type2 === 'STORE_EMPTY'))).length))}</td>
                        <td>{(priority.filter(item => item.empty_type2 !== 'HOUSE_WANT').reduce((sum, item) => (sum + item.room_cnt2), 0)) - (priority.filter(item => ((item.empty_type2 === 'HOUSE_OWNER') || (item.empty_type2 === 'HOUSE_EMPTY') || (item.empty_type2 === 'STORE_OWNER') || (item.empty_type2 === 'STORE_EMPTY'))).reduce((sum, item) => (sum + item.room_cnt2), 0))}</td>
                        <td>{priority.length > 0 && ToLocaleString(priority[0].rent_tot_amt)}<span>원</span></td>
                    </tr>
                    <tr>
                        <th>공실②<br/>집주인거주 포함</th>
                        <td>{priority.length > 0 && (priority.filter(item => ((item.empty_type2 === 'HOUSE_OWNER') || (item.empty_type2 === 'HOUSE_EMPTY') || (item.empty_type2 === 'STORE_OWNER') || (item.empty_type2 === 'STORE_EMPTY'))).length)}</td>
                        <td>{priority.filter(item => ((item.empty_type2 === 'HOUSE_OWNER') || (item.empty_type2 === 'HOUSE_EMPTY') || (item.empty_type2 === 'STORE_OWNER') || (item.empty_type2 === 'STORE_EMPTY'))).reduce((sum, item) => (sum + item.room_cnt2), 0)}</td>
                        <td>{priority.length > 0 && ToLocaleString(priority[0].empty_tot_amt)}<span>원</span></td>
                    </tr>
                
                
                    <tr>
                        <td className="text-l pl10" colSpan={4}>＊공실 최우선변제보증금 = 공제 방개수 x 최우선변제보증금</td>
                    </tr>
                    <tr>
                        <td className="text-l pl10" colSpan={4}>＊입주주택 해당 층의 총 방 개수(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)개</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th rowSpan={2}>공인중개사</th>
                        <th rowSpan={2}>업체명</th>
                        <td rowSpan={2} colSpan={2}>{write.broker_company}</td>
                        <th style={{width:"15%"}}>전화번호</th>
                        <td colSpan={2}>{ShowTelDash(write.broker_phone)}</td>
                    </tr>
                    <tr>
                        <th>사업자등록번호</th>
                        <td colSpan={2}>{write.broker_reg_no}</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th>제출서류</th>
                        <td colSpan={7}></td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th>유의사항</th>
                        <td className="text-l pl10" colSpan={7}>권리분석 종료 전 가계약을 할 경우 권리분석 과정에서 지원불가 주택으로 판명 시 가계약금 등<br/>모든 비용은 입주자 본인이 부담하게 됨</td>
                    </tr>
                </tbody>
            </table>
            <div className='applySign'>
                <div className='txt'>위와 같이 유의사항을 확인하고 전세임대주택 공급을 신청합니다.</div>
                <div className='day'>{write.req_date}</div>
                {
                    winner.map((item, idx) => 
                        <div className='signBox' key={idx}>
                            <div className='title'>신청인{idx+1}</div>
                            <div className='name'>{item.winner_name}</div>
                            <div className='sign'>서명</div>
                        </div>
                    )
                }
                <div className='signBox'>
                    <div className='title'>공인중개사</div>
                    <div className='name'>{write.broker_company}</div>
                    <div className='sign'>서명</div>
                </div>
                <div>한국토지주택공사 서울지역본부장 귀하</div>
            </div>



            {/*  지원신청서 변경 전 */}
            {/* <h4>● 신청인 정보</h4>
            <table className='tt-list'>
                <thead>
                    <tr>
                        <td>No</td>
                        <td>성명</td>
                        <td>계약유형</td>
                        <td>주민등록번호</td>
                        <td>주소</td>
                        <td>핸드폰번호</td>
                        <td>유형</td>
                    </tr>
                </thead>
                <tbody>
                    {winner.map((item, idx) => (
                        <tr key={item.idx}>
                            <td>{idx + 1}</td>
                            <td>{item.winner_name}</td>
                            <td>{write.contract_type_name}</td>
                            <td>{item.winner_no}</td>
                            <td>{item.winner_addr}</td>
                            <td>{item.winner_phone}</td>
                            <td>{item.winner_type_name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h4>● 입주희망 주택현황</h4>
            <div className='flexDiv border-form-write'>
                <table style={{width:"100%"}} className="tth tth1">
                    <tbody>
                        <tr>
                            <th>도로명 주소</th>
                            <td>{write.road_addr} {write.road_addr_dtl}</td>
                        </tr>
                        <tr>
                            <th>지번 주소</th>
                            <td>{write.jibun_addr} {write.road_addr_dtl}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flexDiv border-form-write">
                <table style={{width:"30%"}} className="tth tth2" >
                    <tbody>
                        <tr>
                            <th>주택유형</th>
                            <td>{write.house_type_name}</td>
                        </tr>
                    </tbody>
                </table>
                <table style={{width:"40%"}} className="tth tth2">
                    <tbody>
                        <tr>
                            <th>면적</th>
                            <td>{write.house_area} m²</td>
                        </tr>
                        <tr>
                            <th>방 개수</th>
                            <td>{write.room_count} 개</td>
                        </tr>
                    </tbody>
                </table>
                <table className="tth tth2" style={{width:"30%"}}>
                    <tbody>
                        <tr>
                            <th>85m² 초과</th>
                            <td>
                                <input type="checkbox" name="house_area_over1" id="house_area_over1" checked={write.house_area_over1 === "Y" ? true : false} readOnly={true} />
                                <label htmlFor="house_area_over1"> 미성년자녀 3명 이상</label><br/>
                                <input type="checkbox" name="house_area_over2" id="house_area_over2" checked={write.house_area_over2 === "Y" ? true : false} readOnly={true} />
                                <label htmlFor="house_area_over2"> 가구원수 5명 이상</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flexDiv border-form-write">
                <table className="tth tth3" style={{width:"30%"}}>
                    <tbody>
                        <tr>
                            <th>전세유형</th>
                            <td>{write.rent_type_name}</td>
                        </tr>
                        <tr>
                            <th>매매중여부</th>
                            <td>{write.house_selling === "Y" ? "예" : "아니오"}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="tth tth3" style={{width:"40%"}}>
                    <tbody>
                        <tr>
                            <th>전세금액</th>
                            <td>{write.rent_amt} <span>원</span></td>
                        </tr>
                        <tr>
                            <th>월세금액</th>
                            <td>{write.monthly_rent_amt} <span>원</span></td>
                        </tr>
                    </tbody>
                </table>
                <table style={{width:"30%"}} className="tth tth3">
                    <tbody>
                        <tr>
                            <th>미성년자녀 수</th>
                            <td>{write.minor_child_cnt} 명</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h4>● 지원금</h4>
            <div className="flexDiv">
                <table className='tt-list'>
                    <thead>
                        <tr>
                            <th>지원유형</th>
                            <th>성명</th>
                            <th>주민등록번호</th>
                            <th>입주자부담금 비율</th>
                            <th>입주자추가부담금</th>
                            <th>입주자부담금</th>
                            <th>월임대료</th>
                            <th>LH지원금</th>
                        </tr>
                    </thead>
                    <tbody>
                        {winner.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.winner_type_name}</td>
                                <td>{item.winner_name}</td>
                                <td>{item.winner_no}</td>
                                <td>{item.charge_rate === "0" ? "없음" : item.charge_rate + "%"}</td>
                                <td>{item.add_charges}</td>
                                <td>{item.winner_charges}</td>
                                <td>{item.monthly_rent}</td>
                                <td>{item.lh_support_amt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            <h4>● 선순위 임대차현황</h4>
            <table className='tblFirst tt-list'>
                <thead>
                    <tr>
                        <th>공실구분</th>
                        <th>층수</th>
                        <th>호수</th>
                        <th>방개수</th>
                        <th>비고</th>
                    </tr>
                </thead>
                <tbody>
                    {rentWrite.map((item, idx) => (
                        item.use_flag === 'Y' &&
                        <tr key={idx}>
                            <td>{item.empty_type_name}</td>
                            <td>{item.rent_floor}</td>
                            <td>{item.room_no}</td>
                            <td>{item.room_cnt}</td>
                            <td>{item.remark}</td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={3} className="sum">신청한 호수를 제외한 총 보증금</td>
                        <td colSpan={2}>{write.rent_tot_amt}</td>
                    </tr>
                </tbody>
            </table>
            
            <h4>● 공인중개사 정보</h4>
            <div className='border-form-write'>
                <table>
                    <tbody>
                        <tr>
                            <th>업체명</th>
                            <td>{write.broker_company}</td>
                            <th>전화번호</th>
                            <td>{write.broker_telno}</td>
                            <th>사업자등록번호</th>
                            <td>{write.broker_reg_no}</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
            {/*  지원신청서 변경 전 */}
            </div>
        </div>
        );
    }
};

export default ApplicationFormView;