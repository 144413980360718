import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as adminAPI from '../../lib/api/admin';
import * as entitleAPI from '../../lib/api/entitle';

const INITIALIZE = 'coDocUpload/INITIALIZE';
const CHANGE_VALUE = 'coDocUpload/CHANGE_VALUE';
const ADD_ROW = 'coDocUpload/ADD_ROW';
const REMOVE_ROW = 'coDocUpload/REMOVE_ROW';
const ROW_CHANGE_VALUE = 'coDocUpload/ROW_CHANGE_VALUE';
const SET_ALREADY_FILELIST = 'coDocUpload/SET_ALREADY_FILELIST';
const [GET_DOC_TYPE, GET_DOC_TYPE_SUCCESS, GET_DOC_TYPE_FAILURE] = createRequestActionTypes('coDocUpload/GET_DOC_TYPE');
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('coDocUpload/SAVE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const addRow = createAction(ADD_ROW, ({subCode, codeName}) => ({subCode, codeName}));
export const removeRow = createAction(REMOVE_ROW, ({subCode, idx}) => ({subCode, idx}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, fileReader, fileName, fileExtension}) => ({idx, fileReader, fileName, fileExtension}));
export const setAlreadyFileList = createAction(SET_ALREADY_FILELIST, (body) => (body));
export const getDocType = createAction(GET_DOC_TYPE);
export const save = createAction(SAVE, (body) => (body));

const getDocTypeSaga = createRequestSaga(GET_DOC_TYPE, adminAPI.doc_search2);
const saveSaga = createRequestSaga(SAVE, entitleAPI.enwrite4);

export function* coDocUploadSaga() {
    yield takeLatest(GET_DOC_TYPE, getDocTypeSaga);
    yield takeLatest(SAVE, saveSaga);
}

const initialState = {
    doc_type: [],
    contract_doc_type: [],
    fileLastIdx: 0,
    fileList: [],

    coDocUpload: null,
    error: null
};

const coDocUpload = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, fileReader, fileName, fileExtension } }) => {
            const _fileList = state.fileList.map((item) =>
                item.idx === idx ? {
                    ...item,
                    file_name: fileName,
                    file_extension: fileExtension,
                    file_data: [{
                        file_url: fileReader.result,
                        rotation: 0,
                    }]
                } : item,
            );
            return {
                ...state,
                fileList: _fileList,
            };
        },
        [ADD_ROW]: (state, { payload }) => {
            const _fileLastIdx = state.fileLastIdx + 1;
            return {
                ...state,
                fileLastIdx: _fileLastIdx,
                fileList: state.fileList.concat({
                            idx: _fileLastIdx,
                            file_id: '',
                            file_type: payload.subCode,
                            file_type_name: payload.codeName,
                            file_name: '',
                            file_extension: '',
                            file_data: '',
                            use_flag: "Y",
                            api_yn: "N",
                            rotation: "0",
                        }),
            }
        },
        [REMOVE_ROW]: (state, { payload: {subCode, idx}}) => {
            const _fileList = state.fileList.map((item) =>
                item.file_type === subCode && item.idx === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );
            return {
                ...state,
                fileList: _fileList,
            };
        },
        [SET_ALREADY_FILELIST]: (state, { payload }) => {
            return {
                ...state,
                fileLastIdx: payload.length,
                fileList: payload,
            };
        },
        [GET_DOC_TYPE_SUCCESS]: (state, {payload}) => {
            let _payload = [];
            payload.forEach(item => {
                _payload.push({
                    code: item.sub_code,
                    name: item.code_name,
                })
            });

            return {
                ...state,
                doc_type: _payload,
            }
        },
        [GET_DOC_TYPE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [SAVE_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
    },
    initialState,
);

export default coDocUpload;