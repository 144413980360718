import React from 'react';
import CustomerListContainer from '../containers/CustomerListContainer';
import HeaderContainer from '../containers/HeaderContainer';

const CustomerListPage = () => {
    return (
        <>
            <HeaderContainer />
            <CustomerListContainer/>
        </>
    );
}

export default CustomerListPage;