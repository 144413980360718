import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'applyDocMgt/INITIALIZE';
const CHANGE_VALUE = 'applyDocMgt/CHANGE_VALUE';
const ROW_CHANGE_VALUE = 'applyDocMgt/ROW_CHANGE_VALUE';
const ADD_ROW = 'applyDocMgt/ADD_ROW';
const REMOVE_ROW = 'applyDocMgt/REMOVE_ROW';
const SORT_ROW = 'applyDocMgt/SORT_ROW';
const SET_MAIN_CODE = 'applyDocMgt/SET_MAIN_CODE';

const [GET_CLASS, GET_CLASS_SUCCESS, GET_CLASS_FAILURE] = createRequestActionTypes('applyDocMgt/GET_CLASS');
const [GET_CODE, GET_CODE_SUCCESS, GET_CODE_FAILURE] = createRequestActionTypes('applyDocMgt/GET_CODE');
const [SAVE_CODE, SAVE_CODE_SUCCESS, SAVE_CODE_FAILURE] = createRequestActionTypes('applyDocMgt/SAVE_CODE');
const [GET_CBO_DOC, GET_CBO_DOC_SUCCESS, GET_CBO_DOC_FAILURE] = createRequestActionTypes('applyDocMgt/GET_CBO_DOC');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const addRow = createAction(ADD_ROW);
export const removeRow = createAction(REMOVE_ROW, ({idx}) => ({idx}));
export const sortRow = createAction(SORT_ROW, ({display, type}) => ({display, type}));
export const setMainCode = createAction(SET_MAIN_CODE);

export const getClass = createAction(GET_CLASS, (body) => (body));
export const getCboDoc = createAction(GET_CBO_DOC, (body) => (body));
export const getCode = createAction(GET_CODE, (body) => (body));

export const saveCode = createAction(SAVE_CODE, (subcodes) => ({subcodes}));

const getClassSaga = createRequestSaga(GET_CLASS, adminAPI.code_search);
const getCodeSaga = createRequestSaga(GET_CODE, adminAPI.code_search);
const saveCodeSaga = createRequestSaga(SAVE_CODE, adminAPI.code_reg);
const getCboDocSaga = createRequestSaga(GET_CBO_DOC, adminAPI.code_search);

export function* applyDocMgtSaga() {
    yield takeLatest(GET_CLASS, getClassSaga);
    yield takeLatest(GET_CODE, getCodeSaga);
    yield takeLatest(SAVE_CODE, saveCodeSaga);
    yield takeLatest(GET_CBO_DOC, getCboDocSaga);
}

const initialState = {
    mainCode: '',
    classList: [],
    codeList: [],
    cboDoc: [],

    applyDocMgt: null,
    error: null
};

const applyDocMgt = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _codeList = state.codeList.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                codeList: _codeList,
            };
        },
        [SET_MAIN_CODE]: (state, { payload: { value }}) => ({
            ...state,
            mainCode: value,
        }),
        [ADD_ROW]: (state) => {
            let lastSort = 0;
            state.codeList.forEach(list => {
                if(list.display > lastSort) {
                    lastSort = list.display;
                }
            });
            return {
                ...state,
                codeList: state.codeList.concat({
                    idx: lastSort + 1,
                    main_code: state.mainCode,
                    sub_code: '',
                    code_name: '',
                    memo: '',
                    display: lastSort + 1,
                    use_flag: 'Y',
                    isNewRow: true,
                })
            };
        },
        [REMOVE_ROW]: (state, { payload: {idx}}) => {
            const _codeList = state.codeList.filter((item) => item.idx !== idx);
            return {
                ...state,
                codeList: _codeList,
            };
        },
        [SORT_ROW]: (state, { payload: {display, type}}) => {
            let _codeList = state.codeList;
            
            //  최상위에서 UP, 최하위에서 DOWN이면 Action 종료
            if((type === "UP" && display === 1) || (type === "DOWN" && display === state.codeList.length)) {
                return {
                    ...state,
                    codeList: _codeList,
                };
            }

            const alreadyList = state.codeList.find(x => x.display === display + (type === "UP" ? -1 : 1));
            const currList = state.codeList.find(x => x.display === display);

            let _beforeSort = _codeList.map((item) => {
                if(item.idx === currList.idx) {
                    return {
                        ...item,
                        display: item.display + (type === "UP" ? -1 : 1),
                    }
                }
                else if(item.idx === alreadyList.idx) {
                    return {
                        ...item,
                        display: item.display + (type === "UP" ? 1 : -1),
                    }
                }
                else {
                    return {
                        ...item
                    }
                }
            });
            _beforeSort.sort((a, b) => {
                return a.display - b.display;
            });
            _codeList = _beforeSort;

            return {
                ...state,
                codeList: _codeList,
            };
        },
        [GET_CLASS_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                classList: payload
            }
        },
        [GET_CLASS_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [GET_CODE_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                codeLastIdx: payload.length-1,
                codeList: payload
            }
        },
        [GET_CODE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [SAVE_CODE_SUCCESS]: (state, {payload}) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_CODE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [GET_CBO_DOC_SUCCESS]: (state, {payload}) => {
            payload = [{sub_code: '', code_name: '선택'}, ...payload]
            return {
                ...state,
                cboDoc: payload
            }
        },
        [GET_CBO_DOC_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default applyDocMgt;