// 고객관리 전용
import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as customerAPI from '../lib/api/customer';
import * as adminAPI from '../lib/api/admin';
import { takeLatest } from 'redux-saga/effects';
import { ALERT } from '../lib/dataWord';
// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const SET_PAGING = 'customer/SET_PAGING';
const INITIALIZE = 'customer/INITIALIZE';
const INITIALIZE_DETAIL = 'customer/INITIALIZE_DETAIL';
const CHANGE_VALUE = 'customer/CHANGE_VALUE';
const CHECKBOX = 'customer/CHECKBOX';
const CHECKBOX_ALL = 'customer/CHECKBOX_ALL';
const SET_SEARCH = 'customer/SET_SEARCH'
const CHANGE_SEARCH = 'customer/CHANGE_SEARCH';
const CHANGE_DETAIL = 'customer/CHANGE_DETAIL';
const [CUST_SEARCH, CUST_SEARCH_SUCCESS, CUST_SEARCH_FAILURE] = createRequestActionTypes('customer/CUST_SEARCH');
const [CUST_IMPLY_BATCH, CUST_IMPLY_BATCH_SUCCESS, CUST_IMPLY_BATCH_FAILURE] = createRequestActionTypes('customer/CUST_IMPLY_BATCH');
const [CUST_IMPLY_REG, CUST_IMPLY_REG_SUCCESS, CUST_IMPLY_REG_FAILURE] = createRequestActionTypes('customer/CUST_IMPLY_REG');
const [CUST_VIEW, CUST_VIEW_SUCCESS, CUST_VIEW_FAILURE] = createRequestActionTypes('customer/CUST_VIEW');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('customer/REACTION');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('customer/GET_COMBO');
const [CUST_UPDATE, CUST_UPDATE_SUCCESS, CUST_UPDATE_FAILURE] = createRequestActionTypes('customer/CUST_UPDATE');

const [NEXT_WORK_REG, NEXT_WORK_REG_SUCCESS, NEXT_WORK_REG_FAILURE] = createRequestActionTypes('customer/NEXT_WORK_REG');
const [NEXT_WORK_BATCH, NEXT_WORK_BATCH_SUCCESS, NEXT_WORK_BATCH_FAILURE] = createRequestActionTypes('customer/NEXT_WORK_BATCH');
const [LHNEXT_WORK_REG, LHNEXT_WORK_REG_SUCCESS, LHNEXT_WORK_REG_FAILURE] = createRequestActionTypes('customer/LHNEXT_WORK_REG');
const [LHNEXT_WORK_BATCH, LHNEXT_WORK_BATCH_SUCCESS, LHNEXT_WORK_BATCH_FAILURE] = createRequestActionTypes('customer/LHNEXT_WORK_BATCH');


// LH 고객리스트
const SET_EXCEL_LH = 'WinnerWork/SET_EXCEL_LH';
const [LHCUST_REG, LHCUST_REG_SUCCESS, LHCUST_REG_FAILURE] = createRequestActionTypes('winnerWork/LHCUST_REG')
const [LHCUST_SEARCH, LHCUST_SEARCH_SUCCESS, LHCUST_SEARCH_FAILURE] = createRequestActionTypes('customer/LHCUST_SEARCH');
const [LHCUST_IMPLY_BATCH, LHCUST_IMPLY_BATCH_SUCCESS, LHCUST_IMPLY_BATCH_FAILURE] = createRequestActionTypes('customer/LHCUST_IMPLY_BATCH');
const [LHCUST_IMPLY_REG, LHCUST_IMPLY_REG_SUCCESS, LHCUST_IMPLY_REG_FAILURE] = createRequestActionTypes('customer/LHCUST_IMPLY_REG');

export const initialize = createAction(INITIALIZE);
export const initializeDetail = createAction(INITIALIZE_DETAIL);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const custSearch = createAction(CUST_SEARCH, (body) => (body));
export const LHCustSearch = createAction(LHCUST_SEARCH, (body) => (body));
export const custImplyBatch = createAction(CUST_IMPLY_BATCH, (body) => (body));
export const custImplyReg = createAction(CUST_IMPLY_REG, (body) => (body));
export const LHcustImplyBatch = createAction(LHCUST_IMPLY_BATCH, (body) => (body));
export const LHcustImplyReg = createAction(LHCUST_IMPLY_REG, (body) => (body));
export const setSearch = createAction(SET_SEARCH, (body) => (body));
export const changeSearch = createAction(CHANGE_SEARCH, (body) => (body));
export const changeDetail = createAction(CHANGE_DETAIL, (body) => (body));
export const custView = createAction(CUST_VIEW, (body) => (body));
export const custUpdate = createAction(CUST_UPDATE, (body) => (body));
export const getCombo = createAction(GET_COMBO);

export const setPaging = createAction(SET_PAGING, (body) => (body));
export const checkBox = createAction(CHECKBOX, (body) => (body));
export const checkBoxAll = createAction(CHECKBOX_ALL, (body) => (body));

export const nextWorkReg = createAction(NEXT_WORK_REG, (body) => (body));
export const nextWorkBatch = createAction(NEXT_WORK_BATCH, (body) => (body));
export const LHnextWorkReg = createAction(LHNEXT_WORK_REG, (body) => (body));
export const LHnextWorkBatch = createAction(LHNEXT_WORK_BATCH, (body) => (body));


// LH 고객리스트
export const setExcel_LH = createAction(SET_EXCEL_LH, (body) => (body));
export const LHCustReg = createAction(LHCUST_REG, (body) => (body));

// export const action = createAction(ACTION, data => data);

const custSearchSaga = createRequestSaga(CUST_SEARCH, customerAPI.cust_search);
const LHCustSearchSaga = createRequestSaga(LHCUST_SEARCH, customerAPI.lhcust_search);
const custImplyBatchSaga = createRequestSaga(CUST_IMPLY_BATCH, customerAPI.cust_imp_batch);
const custImplyRegSaga = createRequestSaga(CUST_IMPLY_REG, customerAPI.cust_imp_reg);
const LHcustImplyBatchSaga = createRequestSaga(LHCUST_IMPLY_BATCH, customerAPI.lhcust_imp_batch);
const LHcustImplyRegSaga = createRequestSaga(LHCUST_IMPLY_REG, customerAPI.lhcust_imp_reg);
const custViewSaga = createRequestSaga(CUST_VIEW, customerAPI.cust_view);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_search);
const custUpdateSaga = createRequestSaga(CUST_UPDATE, customerAPI.cust_update);

const nextWorkRegSaga = createRequestSaga(NEXT_WORK_REG, customerAPI.nextwork_reg);
const nextWorkBatchSaga = createRequestSaga(NEXT_WORK_BATCH, customerAPI.nextwork_batch);
const LHnextWorkRegSaga = createRequestSaga(LHNEXT_WORK_REG, customerAPI.lh_nextwork_reg);
const LHnextWorkBatchSaga = createRequestSaga(LHNEXT_WORK_BATCH, customerAPI.h_nextwork_batch);

const LHCustRegSaga = createRequestSaga(LHCUST_REG, customerAPI.lhcust_reg);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* customerSaga() {
    yield takeLatest(CUST_SEARCH, custSearchSaga);
    yield takeLatest(LHCUST_SEARCH, LHCustSearchSaga);
    yield takeLatest(CUST_VIEW, custViewSaga);
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(CUST_UPDATE, custUpdateSaga);
    yield takeLatest(LHCUST_REG, LHCustRegSaga);
    yield takeLatest(CUST_IMPLY_BATCH, custImplyBatchSaga);
    yield takeLatest(CUST_IMPLY_REG, custImplyRegSaga);
    yield takeLatest(LHCUST_IMPLY_BATCH, LHcustImplyBatchSaga);
    yield takeLatest(LHCUST_IMPLY_REG, LHcustImplyRegSaga);
    yield takeLatest(NEXT_WORK_REG, nextWorkRegSaga);
    yield takeLatest(NEXT_WORK_BATCH, nextWorkBatchSaga);
    yield takeLatest(LHNEXT_WORK_REG, LHnextWorkRegSaga);
    yield takeLatest(LHNEXT_WORK_BATCH, LHnextWorkBatchSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const date = new Date();
const year = date.getFullYear();
const month = ("0"+(date.getMonth() + 1)).slice(-2);
const month_m = ("0"+date.getMonth()).slice(-2);
const initialState = {
    customer: null,
    error: null,


    LHMaxPageCount: 10, //  LH 고객 페이지 전용 가변 페이지 출력 개수 10, 30 50 100

    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    max: 5, 
    step: 1, 
    currentPage: 1, 
    pageCount: null,
    cboApply: [],

    customerList: [], // 조회된 리스트
    search: {
        date_type: "CONTRACT_END_DATE", // 계약 종료일
        start_date:`${year}-${month_m}-${new Date(year, month_m, 0).getDate()}`,
        end_date:`${year}-${month}-${new Date(year, month, 0).getDate()}`,
        phone: "",
        name: "",
        use_flag: "",
        page: "1",
        area: '',
        view_count: '10'
    }, // 검색



    checkedList: [], // 체크된 리스트,
    customerDetail: null, // 리스트클릭 -> 상세보기

    // LH
    customerList_LH: [],

    // 당첨자 엑셀 LH (아직 안씀 이유: 프론트에서 엑셀 크기 감당 X)
    winnerExcel_LH: [],

    // checkedId: [],   // 체크된 아이디 리스트
};

const customer = handleActions(
    {
        [INITIALIZE]: () => initialState,
            // SET_SEARCH_COUNT
        [INITIALIZE_DETAIL]: (state) => ({
            ...state,
            customerDetail: null,
        }),
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [CHANGE_SEARCH]: (state, { payload: {key, value}}) => ({
            ...state,
            search: {
                ...state.search,
                [key]: value,
            }
        }),
        [CHANGE_DETAIL]: (state, { payload: {key, value}}) => ({
            ...state,
            customerDetail: {
                ...state.customerDetail,
                [key]: value,
            }
        }),
        [SET_SEARCH]: (state, { payload: {search} }) => ({
            ...state,
            search: search === 'init'? initialState.search : search
        }),
        [CUST_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                customerList: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            })
        },
        [CUST_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                customerList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
            })
        },
        [LHCUST_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                customerList_LH: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.search.view_count),
            })
        },
        [LHCUST_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                customerList_LH: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
            })
        },
        [CUST_IMPLY_BATCH_SUCCESS]: (state, {payload}) => {
            ALERT("묵시대상자 일괄처리를 성공했습니다.")
            return ({
                ...state
            })
        },
        [CUST_IMPLY_BATCH_FAILURE]: (state, {payload}) => {
            ALERT("묵시대상자 일괄처리를 실패했습니다.")
            return ({
                ...state
            })
        },
        [CUST_IMPLY_REG_SUCCESS]: (state, {payload}) => {
            ALERT("묵시대상자 선택처리를 성공했습니다.")
            return ({
                ...state,
                checkedList: [],
            })
        },
        [CUST_IMPLY_REG_FAILURE]: (state, {payload}) => {
            ALERT("묵시대상자 선택처리를 실패했습니다.")
            return ({
                ...state,
            })
        },
        [LHCUST_IMPLY_BATCH_SUCCESS]: (state, {payload}) => {
            ALERT("묵시대상자 일괄처리를 성공했습니다.")
            return ({
                ...state
            })
        },
        [LHCUST_IMPLY_BATCH_FAILURE]: (state, {payload}) => {
            ALERT("묵시대상자 일괄처리를 실패했습니다.")
            return ({
                ...state
            })
        },
        [LHCUST_IMPLY_REG_SUCCESS]: (state, {payload}) => {
            ALERT("묵시대상자 선택처리를 성공했습니다.")
            return ({
                ...state,
                checkedList: [],
            })
        },
        [LHCUST_IMPLY_REG_FAILURE]: (state, {payload}) => {
            ALERT("묵시대상자 선택처리를 실패했습니다.")
            return ({
                ...state,
            })
        },
        [CUST_VIEW_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                customerDetail: payload[0],
            })
        },
        [CUST_VIEW_FAILURE]: (state, {payload}) => {
            ALERT("해당 대상자 조회를 실패했습니다.")
            return ({
                ...state,
                customerDetail: null,
            })
        },
        [CUST_UPDATE_SUCCESS]: (state) => {
            ALERT("사용자 정보를 수정했습니다.")
            return ({
                ...state
            })
        },
        [CUST_UPDATE_FAILURE]: (state) => {
            ALERT("사용자 정보 수정에 실패했습니다.")
            return ({
                ...state
            })
        },

        [NEXT_WORK_REG_SUCCESS]: (state) => {
            ALERT("사후대상자 처리에 성공했습니다.")
            return ({
                ...state
            })
        },
        [NEXT_WORK_REG_FAILURE]: (state) => {
            ALERT("사후대상자 처리에 실패했습니다.")
            return ({
                ...state
            })
        },
        [NEXT_WORK_BATCH_SUCCESS]: (state) => {
            ALERT("사후대상자 처리에 성공했습니다.")
            return ({
                ...state
            })
        },
        [NEXT_WORK_BATCH_FAILURE]: (state) => {
            ALERT("사후대상자 처리에 실패했습니다.")
            return ({
                ...state
            })
        },
        [LHNEXT_WORK_REG_SUCCESS]: (state) => {
            ALERT("사후대상자 처리에 성공했습니다.")
            return ({
                ...state
            })
        },
        [LHNEXT_WORK_REG_FAILURE]: (state) => {
            ALERT("사후대상자 처리에 실패했습니다.")
            return ({
                ...state
            })
        },
        [LHNEXT_WORK_BATCH_SUCCESS]: (state) => {
            ALERT("사후대상자 처리에 성공했습니다.")
            return ({
                ...state
            })
        },
        [LHNEXT_WORK_BATCH_FAILURE]: (state) => {
            ALERT("사후대상자 처리에 실패했습니다.")
            return ({
                ...state
            })
        },

        [GET_COMBO_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                cboApply: payload
            })
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                cboApply: [],
                error
            }
        },
        [CHECKBOX]: (state, {payload: {checked, item, target}}) => {
            if(target === 'normal') {
                return ({
                    ...state,
                    checkedList: checked? 
                        state.checkedList.concat(item) 
                        :
                        state.checkedList.filter((el) => el.cust_id !== item.cust_id)
                })
            }
            else if(target === 'lh') {
                return ({
                    ...state,
                    checkedList: checked? 
                        state.checkedList.concat(item) 
                        :
                        state.checkedList.filter((el) => el.lh_cust_id !== item.lh_cust_id)
                })
            }
        },
        [CHECKBOX_ALL]: (state, {payload: {checked, target}}) => {
            if(target === 'normal') {
                return ({
                    ...state,
                    checkedList: checked?
                    state.customerList
                    :
                    []
                })
            }
            else if (target === 'lh') {
                return ({
                    ...state,
                    checkedList: checked?
                    state.checkedList.concat(state.customerList_LH)
                    :
                    []
                })
            }
        },
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                        search: {
                            ...state.search,
                            page: (state.step + 1) * state.max - 4
                        }
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1,
                        search: {
                            ...state.search,
                            page: (state.step - 1) * state.max - 4
                        }
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },
        [LHCUST_REG_SUCCESS]: (state, {payload}) => {
            ALERT('업로드했습니다.')
            return ({
                ...state,
                // cboApply: payload
            })
        },
        [LHCUST_REG_FAILURE]: (state, { payload: error }) => {
            ALERT('업로드에 실패했습니다.')
            return {
                ...state,
                // cboApply: [],
                error
            }
        },
        [SET_EXCEL_LH]: (state, {payload: {value}}) => {
            return ({
                ...state,
                winnerExcel_LH: value
            })
        },
    },
    initialState,
);

export default customer;