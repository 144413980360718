import React from 'react';
import ImplyApprove from '../components/ImplyApprove';

const ImplyApproveContainer = () => {
    return (
        <>
            <ImplyApprove />
        </>
    );
}

export default ImplyApproveContainer;