import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import * as adminAPI from '../../lib/api/admin';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'lhConfirm/INITIALIZE';
const CHANGE_VALUE = 'lhConfirm/CHANGE_VALUE';
const DOC_SELECT_FOLDER = 'lhConfirm/DOC_SELECT_FOLDER';
const DOC_SELECT_IMG = 'lhConfirm/DOC_SELECT_IMG';
const [GET_FILE, GET_FILE_SUCCESS, GET_FILE_FAILURE] = createRequestActionTypes('lhConfirm/GET_FILE');
const [GET_DOC_TYPE, GET_DOC_TYPE_SUCCESS, GET_DOC_TYPE_FAILURE] = createRequestActionTypes('lhConfirm/GET_DOC_TYPE');
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('lhConfirm/SAVE');
const [SET_STATUS, SET_STATUS_SUCCESS, SET_STATUS_FAILURE] = createRequestActionTypes('lhConfirm/SET_STATUS');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const doc_selectFolder = createAction(DOC_SELECT_FOLDER, (value) => value);
export const doc_selectImg = createAction(DOC_SELECT_IMG, (value) => value);
export const getFile = createAction(GET_FILE, (body) => (body))
export const getDocType = createAction(GET_DOC_TYPE)
export const save = createAction(SAVE)
export const setStatus = createAction(SET_STATUS, (body) => (body));

const getFileSaga = createRequestSaga(GET_FILE, entitleAPI.enfile_list);
const getDocTypeSaga = createRequestSaga(GET_DOC_TYPE, adminAPI.doc_search2);
const saveSaga = createRequestSaga(SAVE, entitleAPI.lhconfirm);
const setStatusSaga = createRequestSaga(SET_STATUS, entitleAPI.status);

export function* lhConfirmSaga() {
    yield takeLatest(GET_FILE, getFileSaga);
    yield takeLatest(GET_DOC_TYPE, getDocTypeSaga);
    yield takeLatest(SAVE, saveSaga);
    yield takeLatest(SET_STATUS, setStatusSaga);
}

const initialState = {
    //  서류 이미지
    selectFoler: '',
    docFolder: [],
    selectImg: '',
    docImages: [],

    remark: '',

    lhConfirm: null,
    error: null
};

const lhConfirm = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [DOC_SELECT_FOLDER]: (state, { payload }) => {
            return {
                ...state,
                selectFoler: payload.sub_code,
            };
        },
        [DOC_SELECT_IMG]: (state, { payload }) => {
            return {
                ...state,
                selectImg: payload.imgSrc,
            };
        },

        [GET_FILE_SUCCESS]: (state, {payload}) => {
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.forEach(fileList => {
                fileList.files.forEach(file => {
                    if(file.file_data) {
                        //  업로드된 서류폴더 객체 구성
                        if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                            _docFolder.push({
                                sub_code: file.file_type,
                                code_name: file.file_type_name,
                            })
                        }

                        //  첫번째 서류폴더 지정
                        if(!_selectFoler) {
                            _selectFoler = file.file_type;
                        }

                        const arrFile = file.file_data.split(',');
                        //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                        if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                            arrFile.forEach(item => {
                                _docImages.push({
                                    docCode: file.file_type,
                                    imgSrc: item,
                                })
                            });
                        }
                        //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                        else {
                            _docImages.push({
                                docCode: file.file_type,
                                imgSrc: file.file_data,
                            })
                        }

                        //  첫번째 이미지 지정
                        _selectImg = _docImages.length > 0 ? _docImages[0].imgSrc : null;
                    }
                });
            });

            return ({
                ...state,
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                selectImg: _selectImg,
                error: null,
            })
        },
        [GET_FILE_FAILURE]: (state, {payload}) => {
            return ({
            ...state,
            error: "error"
        })},

        [GET_DOC_TYPE_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                docFolder: payload,
                error: null,
            })
        },
        [GET_DOC_TYPE_FAILURE]: (state, {payload: error}) => {
            return ({
            ...state,
            error: error
        })},

        [SAVE_SUCCESS]: (state, {payload}) => {
            ALERT("최종완료 되었습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, {payload: error}) => {
            return ({
            ...state,
            error: error
        })},
        //  Status 변경   =======================================================================================
        [SET_STATUS_SUCCESS]: (state, { payload }) => {
            ALERT("반려되었습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [SET_STATUS_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error,
            }
        },
    },
    initialState,
);

export default lhConfirm;