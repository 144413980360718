import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseNumber, regAmt } from '../../common';
import EnPriority from '../../components/Modal/EnPriority';
import { dataEmptyRoomCalc } from '../../lib/dataEmptyRoomCalc';
import { ALERT } from '../../lib/dataWord';
import { addRow, changeValue, initialize, removeRow, rowChangeValue, save } from '../../modules/Modal/enPriority';

const EnPriorityContainer = (props) => {
    const {
        docNo,
        doc_status,
        setIsShowPriority,
        rent,
        senior_sido,
        rent_tot_amt,
        empty_tot_amt,
        cboEmptyType,
        onPriorityComplete,
    } = props;

    const dispatch = useDispatch();
    const {
        priority,
        _rent_tot_amt,
        _empty_tot_amt,
        isSave,
    } = useSelector(({ enPriority }) => ({
        priority: enPriority.priority.map((item) => {
            return ({
                ...item,
                rent_deposit2: String(item.rent_deposit2).replace(regAmt, ","),
                priority_payment2: String(item.priority_payment2).replace(regAmt, ","),
            })
        }),
        _rent_tot_amt: !enPriority._rent_tot_amt ? "" : String(enPriority._rent_tot_amt).replace(regAmt, ","),
        _empty_tot_amt: !enPriority._empty_tot_amt ? "" : String(enPriority._empty_tot_amt).replace(regAmt, ","),
        isSave: enPriority.isSave,
    }));

    useEffect(() => {
        if(rent) {
            let lastSeq = 0;
            rent.forEach(item => {
                if(item.rent_seq > lastSeq) {
                    lastSeq = item.rent_seq;
                }
                // if(item.rent_floor2 && +item.rent_floor2 !== 0) {
                //     if(+item.rent_floor2 > 0) {
                //         item.floor_name = item.rent_floor2 + ' 층';
                //     }
                //     else {
                //         item.floor_name = '지하 ' + Math.abs(item.rent_floor2) + ' 층';
                //     }
                // }
            });
            
            dispatch(
                changeValue({
                    key: 'priority',
                    value: rent,
                })
            );
            dispatch(
                changeValue({
                    key: 'priorityLastIdx',
                    value: lastSeq,
                })
            );
            dispatch(
                changeValue({
                    key: '_rent_tot_amt',
                    value: rent_tot_amt,
                })
            );
            dispatch(
                changeValue({
                    key: '_empty_tot_amt',
                    value: empty_tot_amt,
                })
            );
        }
    }, [dispatch, rent, rent_tot_amt, empty_tot_amt])

    useEffect(() => {
        if(isSave) {
            onPriorityComplete(priority, _rent_tot_amt, _empty_tot_amt);
            setIsShowPriority(false);
        }
    }, [dispatch, isSave, priority, _rent_tot_amt, _empty_tot_amt, onPriorityComplete, setIsShowPriority])

    useEffect(() => {
        return () => {
            //  언마운트시 초기화
            dispatch(initialize());
        };
    }, [dispatch])

    const onAddRow = e => {
        dispatch(addRow());
    }
    const onRemoveRow = (item, e) => {
        dispatch(removeRow({rent_seq: item.rent_seq}));
    }
    const onRowChange = (item, e) => {
        dispatch(
            rowChangeValue({
                rent_seq: item.rent_seq,
                key: e.target.name,
                value: e.target.value,
            })
        );
        
        //  변경 대상 Record
        const _priority = priority.find(x => x.rent_seq === item.rent_seq);

        // //  층수 변경시, 지층 값도 변경
        // if(e.target.name === 'rent_floor2') {
        //     let _floor_name = '';
        //     if(e.target.value && +e.target.value !== 0) {
        //         if(+e.target.value > 0) {
        //             _floor_name = e.target.value + ' 층';
        //         }
        //         else {
        //             _floor_name = '지하 ' + Math.abs(e.target.value) + ' 층';
        //         }
        //     }

        //     dispatch(
        //         rowChangeValue({
        //             rent_seq: item.rent_seq,
        //             key: 'floor_name',
        //             value: _floor_name,
        //         })
        //     );
        // }

        //  타입 또는 방개수 변경시, 지역에 맞는 금액을 적용한다.
        if(e.target.name === "empty_type2" || e.target.name === "room_cnt2") {
            if(!_priority) return;

            const _roomType = e.target.name === "empty_type2" ? e.target.value : _priority.empty_type2;
            const _roomCount = e.target.name === "room_cnt2" ? e.target.value : _priority.room_cnt2;

            //  단, 공실 또는 집주인이 사용중인 경우에만 실행한다.
            if((_roomType.indexOf("EMPTY") > -1) || _roomType.indexOf("OWNER") > -1) {
                const priorityData = +(dataEmptyRoomCalc({
                    addressStr: senior_sido,
                    emptyType: (_roomType.indexOf("HOUSE") > -1 ? 'h' : 's'),
                }))

                //  주택이라면 방개수 * 금액
                if(_roomType.indexOf("HOUSE") > -1) {
                    dispatch(
                        rowChangeValue({
                            rent_seq: item.rent_seq,
                            key: 'priority_payment2',
                            value: priorityData * _roomCount,
                        })
                    );
                }
                //  주택이 아니라면 호실당 금액 적용 및 방개수 0개 처리
                else {
                    dispatch(
                        rowChangeValue({
                            rent_seq: item.rent_seq,
                            key: 'priority_payment2',
                            value: priorityData,
                        })
                    );
                    dispatch(
                        rowChangeValue({
                            rent_seq: item.rent_seq,
                            key: 'room_cnt2',
                            value: '0',
                        })
                    );
                }
            }
            else {
                dispatch(
                    rowChangeValue({
                        rent_seq: item.rent_seq,
                        key: 'priority_payment2',
                        value: '',
                    })
                );
                //  임대중 이면서 주택이 아니라면 방개수에 0을 적용
                if(_roomType.indexOf("HOUSE") === -1) {
                    dispatch(
                        rowChangeValue({
                            rent_seq: item.rent_seq,
                            key: 'room_cnt2',
                            value: '0',
                        })
                    );
                }
            }
        }
    }
    const onRowChangeNum = (item, e) => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                rowChangeValue({
                    rent_seq: item.rent_seq,
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const onChangeNum = e => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                changeValue({
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    const onCloseClick = () => {
        setIsShowPriority(false);
    }
    const onDepositSum = () => {
        let depositSum = 0;
        for(let i = 0; i < priority.length; i++) {
            const _priority = priority[i];

            if(_priority.use_flag === "Y" && _priority.empty_type2.indexOf('HOUSE_WANT') === -1) {
                depositSum += +(!_priority.rent_deposit2 ? "0" : _priority.rent_deposit2.replace(/,/g, ""))
            }
        }
        dispatch(
            changeValue({
                key: '_rent_tot_amt',
                value: depositSum,
            })
        );
    }
    const onSaveClick = () => {
        let _senior_rent = [];
        for(let i = 0; i < priority.length; i++) {
            const _priority = priority[i];

            if(_priority.use_flag === "Y" && _priority.empty_type2.indexOf('HOUSE') > -1) {
                if(!_priority.room_cnt2 || +_priority.room_cnt2 === 0) {
                    ALERT("방개수를 입력해주세요.");
                    return;
                }
            }

            _senior_rent.push({
                rent_seq: _priority.rent_seq,
                empty_type: _priority.empty_type2,
                rent_floor: _priority.rent_floor2,
                room_no: _priority.room_no2,
                room_cnt: !String(_priority.room_cnt2) ? "0" : String(_priority.room_cnt2).replace(/,/g, ""),
                rent_deposit: !_priority.rent_deposit2 ? "0" : _priority.rent_deposit2.replace(/,/g, ""),
                rent_fee: "0",
                priority_payment: !_priority.priority_payment2 ? "0" : _priority.priority_payment2.replace(/,/g, ""),
                remark: _priority.remark2,
                use_flag: _priority.use_flag,
            })
        }

        if(!window.confirm("저장하시겠습니까?")) {
            return;
        }

        dispatch(
            save({
                doc_id: docNo,
                rent_tot_amt: _rent_tot_amt.replace(/,/g, "") !== ''? _rent_tot_amt.replace(/,/g, "") : '0',
                empty_tot_amt: _empty_tot_amt.replace(/,/g, "") !== ''? _empty_tot_amt.replace(/,/g, "")  : '0',
                senior_rent: _senior_rent,
                senior_sido: senior_sido,
            })
        )
    }

    const propDatas = {
        doc_status,
        priority,
        _rent_tot_amt,
        _empty_tot_amt,

        cboEmptyType,

        onCloseClick,
        onDepositSum,
        onSaveClick,
        onAddRow,
        onRemoveRow,
        onRowChange,
        onRowChangeNum,
        onChangeNum,
    }
    return (
        <>
            <EnPriority {...propDatas} />
        </>
    );
}

export default EnPriorityContainer;