import React, { Fragment } from 'react';
import styled from 'styled-components';

const WinnerInfoBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 1300px !important;
        max-height: 90vh;
        padding: 30px 40px;
        overflow-y: auto;
    }
    input{
        width: 100%;
        border: none;
        text-align: center;
    }

    .winnerInfo {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
    .info-box {
        color: #545454;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
        margin: 5px;
    }
    .winnerInfo .info-box > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .winnerInfo .info-box > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .info-box div:first-child {
        height: 24px;
        font-size: 15px;
        background-color: #ccc;
    }
    .info-box div:last-child {
        font-size: 14px;
        padding: 2px 0;
        display: flex;
    }

    .info-tbl {
        margin: 3px;
    }
    .info-tbl th {
        background-color: #aaa;
        color: white;
    }
    .info-tbl td {
        border: 1px solid #ccc;
        text-align: center;
    }

    hr {
        margin: 50px 0;
    }
    hr:last-child {
        display: none;
    }
`;

const WinnerInfo = (props) => {
    const {
        winner,
        onClose,
    } = props;

    return (
        <WinnerInfoBlock>
            <div className='popupModal'>
                <div />
                <div>
                    <h2>당첨자 정보</h2>
                    <div className='winnerInfo'>
                        {winner.map((item, idx) => (
                            <Fragment key={idx}>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>성명</div>
                                    <div>{item.winner_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>생년월일</div>
                                    <div>{item.winner_no ? item.winner_no.slice(0, 6) : ""}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>주민등록번호</div>
                                    <div>{item.winner_no}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>휴대전화번호</div>
                                    <div>{item.winner_phone}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/6"}}>
                                    <div>신청유형</div>
                                    <div>{item.winner_type_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>접수일자(신청일)</div>
                                    <div>{item.rec_date ? item.rec_date.slice(0, 10) : ""}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/3"}}>
                                    <div>주소</div>
                                    <div>{item.addr}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/5"}}>
                                    <div>상세주소</div>
                                    <div>{item.addr_dtl}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/6"}}>
                                    <div>세부자격요건</div>
                                    <div>{item.dtl_qualification}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>수급자자격</div>
                                    <div>{item.recipient_qualify}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>최종결과</div>
                                    <div>{item.final_result}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>지원가능기간</div>
                                    <div>{item.support_end_date ? item.support_end_date.slice(0, 10) : ""}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>기금</div>
                                    <div>{item.audit_fund}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>주택</div>
                                    <div>{item.audit_house}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/6"}}>
                                    <div>타임대</div>
                                    <div>{item.audit_other_rent}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>타임대체크</div>
                                    <div>{item.audit_rent_chk}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/4"}}>
                                    <div>비고(대외용)</div>
                                    <div>{item.remark ? item.remark : "-"}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/7"}}>
                                    <div>메모(대내용)</div>
                                    <div>{item.memo ? item.memo : "-"}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>세대원(가구원수기준)</div>
                                    <div>{item.family_cnt}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>범정부</div>
                                    <div>{item.pan_government}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>혼인여부(신혼)</div>
                                    <div>{item.marital_status}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>퇴소일(보호종료)</div>
                                    <div>{item.protect_end_date ? item.protect_end_date.slice(0, 10) : ""}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/6"}}>
                                    <div>퇴소자격기한(보호종료)</div>
                                    <div>{item.protect_limit ? item.protect_limit.slice(0, 10) : ""}</div>
                                </div>
                                <table className='info-tbl' style={{gridColumn:"1/7"}}>
                                    <tbody>
                                        <tr>
                                            <th>순서</th>
                                            <th>성명</th>
                                            <th>생년월일</th>
                                            <th>신청인과의 관계</th>
                                        </tr>
                                        {
                                            item.family.length === 0 &&
                                            <tr>
                                                <td colSpan={4}>가구원이 없습니다.</td>
                                            </tr>
                                        }
                                        {
                                            item.family.map((item, fidx) => (
                                                <tr key={fidx}>
                                                    <td>{fidx + 1}</td>
                                                    <td>{item.fam_name}</td>
                                                    <td>{item.fam_no ? item.fam_no.slice(0, 6) : ""}</td>
                                                    <td>{item.fam_rel}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                <hr style={{gridColumn:"1/7"}} />
                            </Fragment>
                        ))}
                    </div>
                    <br/>
                    <div className='divButtons'>
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                    </div>
                </div>
            </div>
        </WinnerInfoBlock>
    );
}

export default WinnerInfo;