import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as authAPI from '../../lib/api/auth';
import * as scheAPI from '../../lib/api/sche';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'createBroker/INITIALIZE';
const CHANGE_VALUE = 'createBroker/CHANGE_VALUE';
const [OVERLAP_CHECK, OVERLAP_CHECK_SUCCESS, OVERLAP_CHECK_FAILURE] = createRequestActionTypes('createBroker/OVERLAP_CHECK');
const [REGIST_BROKER, REGIST_BROKER_SUCCESS, REGIST_BROKER_FAILURE] = createRequestActionTypes('createBroker/REGIST_BROKER');
const [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE] = createRequestActionTypes('createBroker/SEND_SMS');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const overlapChk = createAction(OVERLAP_CHECK, (input_id) => (input_id));
export const registBroker = createAction(REGIST_BROKER, (body) => (body));
export const sendSms = createAction(SEND_SMS, (body) => (body));

const overlapChkSaga = createRequestSaga(OVERLAP_CHECK, authAPI.idcheck);
const registBrokerSaga = createRequestSaga(REGIST_BROKER, authAPI.register);
const sendSmsSaga = createRequestSaga(SEND_SMS, scheAPI.smsSend);

export function* createBrokerSaga() {
    yield takeLatest(OVERLAP_CHECK, overlapChkSaga);
    yield takeLatest(REGIST_BROKER, registBrokerSaga);
    yield takeLatest(SEND_SMS, sendSmsSaga);
}

const initialState = {
    broker_id: '',
    broker_company: '',
    broker_phone: '',

    chk_overlap: 0,
    regsuccess: false,

    createBroker: null,
    error: null
};

const createBroker = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [OVERLAP_CHECK_SUCCESS]: (state, {payload: isOverlap}) => ({
            ...state,
            chk_overlap: isOverlap ? 2 : 1,
            error: null,
        }),
        [OVERLAP_CHECK_FAILURE]: (state) => ({
            ...state,
            chk_overlap: 0,
            error: '아이디 중복확인 오류',
        }),

        [REGIST_BROKER_SUCCESS]: (state, { payload: auth }) => {
            ALERT("중개사ID가 생성되었습니다.");
            return ({
                ...state,
                error: null,
                regsuccess: true,
            })
        },
        [REGIST_BROKER_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error: '회원가입실패',
                regsuccess: false,
            })
        },

        [SEND_SMS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SEND_SMS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default createBroker;