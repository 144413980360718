import React from 'react';
import styled from 'styled-components';
import dataWORD from '../lib/dataWord';

const AuditListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin: 20px 0;
    }

    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .resultTbl {
        overflow: auto;
        height: 80vh;
        border: 1px solid black;
        box-sizing: border-box;
    }

    .resultTbl table{
        white-space: nowrap;
        text-align: center;
        margin-bottom: 0px;
    }

    .resultTbl table th, 
    .resultTbl table td {
        padding: 0 5px;
    }

    .btn2{
        position: absolute;
        top: 50px;
        right: 50px;
    }

`;

const AuditList = (props) => {
    const {
        implyAuditSearch,
        impAuditList,
        pageCount,
        step,
        max,
        currentPage,
        maxPageCount,

        onSubmit,
        onChangeSearch,
        onClickPrev,
        onClickNum,
        onClickNext,
        onClickUpload
    } = props;

    return (
        <AuditListBlock>
        <h1>등초본 조회 리스트</h1>
        <div className='divFlex-btn'>
            <button onClick={onClickUpload} className="btn2">등초본 업로드</button> 
        </div>
        {/* 업로드 버튼은 있어야 할듯 */}

        <h2>검색조건</h2>
        <div className='search'>
            <form onSubmit={onSubmit}>
                <div className='searchBody'>
                    <div className='searchipt'>
                        <div className='st'>
                            <label htmlFor='date_type'>날짜유형</label>
                            <select name='date_type' id='date_type' value={implyAuditSearch.date_type || ''} onChange={onChangeSearch}>
                                <option value={''}>선택하세요</option>
                                <option value={'REQUEST'}>등본요청일자</option>
                                <option value={'RECEIVE'}>등본수신일자</option>
                            </select>
                            <input type='date' name='from_date' id='from_date' value={implyAuditSearch.from_date || ''} onChange={onChangeSearch}/>
                            <span>~</span>
                            <input type='date' name='to_date' id='to_date' value={implyAuditSearch.to_date || ''} onChange={onChangeSearch}/>
                        </div>
                        <div className='st'>
                            <label htmlFor='addr'>주소</label>
                            <input type='text' name='addr' id='addr' value={implyAuditSearch.addr || ''} onChange={onChangeSearch}/>
                        </div>
                        <div className='st'>
                            <label htmlFor='name'>이름</label>
                            <input type='text' name='name' id='name' value={implyAuditSearch.name || ''} onChange={onChangeSearch}/>
                        </div>
                        <div className='st'>
                            <label htmlFor='area'>지역</label>
                            <input type='text' name='area' id='area' value={implyAuditSearch.area || ''} onChange={onChangeSearch}/>
                        </div>
                    </div>

                    <div className='btn'>
                        <button className='green-sch-btn'>검색</button>
                    </div>
                </div>
            </form>
        </div>

        <div className='resultTbl'>
            <table className='tt'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>요청일</th>
                        <th>수신일</th>
                        <th>지자체</th>
                        <th>주소</th>
                        <th>성명</th>
                        <th>{dataWORD.BIRTHDATE}</th>
                        <th>세대주와의관계</th>
                        <th>요청일자</th>
                        <th>수신일자</th>
                        <th>공동주택명</th>
                        <th>공동주택동명</th>
                        <th>공동주택호명</th>
                        <th>세대구성사유</th>
                        <th>세대구성일자</th>
                        <th>세대주최종변동일자</th>
                        <th>세대주최종변동신고일자</th>
                        <th>세대주최종변동사유</th>
                        <th>전입일자</th>
                        <th>변동일자</th>
                        <th>변동사유</th>
                        <th>등록상태내용</th>
                    </tr>
                </thead>
                <tbody>
                    {impAuditList.length > 0? 
                    <>
                    {
                        impAuditList.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{(maxPageCount * (currentPage - 1)) + idx+1}</td>
                                    <td>{item.request_date || "-"}</td>
                                    <td>{item.receive_date || "-"}</td>
                                    <td>{item.resident_reg_area || "-"}</td>
                                    <td>{item.resident_reg_addr || "-"}</td>
                                    <td>{item.resident_reg_name || "-"}</td>
                                    <td>{item.jumin_num || "-"}</td>
                                    <td>{item.relation || "-"}</td>
                                    <td>{item.request_date || "-"}</td>
                                    <td>{item.receive_date || "-"}</td>
                                    <td>{item.joint_house_name || "-"}</td>
                                    <td>{item.joint_house_dong || "-"}</td>
                                    <td>{item.joint_house_ho || "-"}</td>
                                    <td>{item.composition_reason || "-"}</td>
                                    <td>{item.composition_date || "-"}</td>
                                    <td>{item.last_change_date || "-"}</td>

                                    <td>{item.last_change_report_date || "-"}</td>

                                    <td>{item.last_change_reason || "-"}</td>

                                    <td>{item.move_in_date || "-"}</td>
                                    <td>{item.change_date || "-"}</td>
                                    <td>{item.change_reason || "-"}</td>
                                    <td>{item.reg_status || "-"}</td>
                                </tr>
                            )
                        })
                    }
                    </>
                    : null}
                </tbody>
            </table>
        </div>
        <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
        </AuditListBlock>
    );
}

export default AuditList;