import React from 'react';
import styled from 'styled-components';

const NextMaintainBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;

    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .search{
            display : none;
        }
    }

    .search form {
        overflow: inherit;  // Mutiple Check ComboBox를 보여주기 위해서 overflow:hidden을 제거함.
    }

    // 검색 Multiple checkbox Combo
    .cboStDiv {
        position: relative;
        z-index: 999;
    }
    .txtSelectedValues {
        display: flex;
        width: 100%;
        height: 36px;
        padding: 5px;
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .txtSelectedValues div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }

    .cboMultiple {
        position: absolute;
        top: 36px;
        border: 1px solid black;
        list-style: none;
        max-height: 150px;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;
    }
    .cboMultiple li {
        background-color: white;
    }
    .cboMultiple li:hover {
        background-color: #ccc;
    }
    .cboMultiple li input[type="checkbox"] + label:before {
        top: 8px;
        left: 6px;
    }
    .cboMultiple li label {
        background-color: inherit;
        height: auto;
        padding: 5px 5px 5px 25px;
        width: 100%;
        text-align: left;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }
`;

const ViewerPopup = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .popupWrap{
        width: 1500px;
        box-sizing: border-box;
        padding: 20px;
    }
    h4{
        color: #545454;
        font-size: 26px;
        text-align: center;
        margin-bottom: 15px;
    }
    .divView {
        display: flex;
        gap: 20px;
    }
    .divView > div:first-child {
        width: 50%;
    }
    .divView > div:last-child {
        width: 50%;
    }
    .regInfo {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    .info-box {
        border: 1px solid black;
        margin: 5px;
    }
    .info-box:last-child {
        margin-bottom: 0;
    }
    .regInfo .info-box div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .info-box div:first-child {
        height: 40px;
        background-color: #ccc;
        border-bottom: 1px solid black;
    }
    .info-box div:last-child {
        padding: 3px;
    }
    .info-box input[type=text],
    .info-box input[type=number],
    .info-box input[type=date] {
        width: 100%;
    }
    .info-box .with-btn {
        display: flex;
        flex-direction: row !important;
        gap: 5px;
    }
    .info-box .with-btn button {
        width: 100px;
        cursor: pointer;
    }

    .modalDivImages {
        position: relative;
        border: 1px solid #ccc;
        width: 40%;
    }
    .modalDivImages .imgView {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .selectImg {
        width: 79%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: crosshair;
    }
    .navImgBox {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        overflow-y: auto;
        opacity: 0.5;
    }
    .navImgBox:hover {
        opacity: 1;
    }
    .navImg {
        width: 120px;
        height: 120px;
        border: 1px solid rgba(255, 0, 0, 0);
    }
    .navImg:hover {
        border: 1px solid red;
    }
    .navImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
    .navImgBox > div {
        position: relative;
    }
`;

const NextMaintain = (props) => {
    const {
        from_date,
        to_date,
        work_type,
        change_flag,

        list,

        //  선택 지역 검색 관련
        cboAreaData,
        isShowCboArea,
        selectedAreas,
        onAreaChange,
        onAreaAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,
        
        pageCount,
        max,
        step,
        currentPage,

        //  Modal Data
        work_name,
        work_addr,
        exec_date,
        change_yn,
        change_reason,
        cert_req_flag,
        api_req_date,
        api_rec_date,
        check_user_name,
        check_date,
        result_text,
        check_memo,
        selectImg,
        docImages,
        onSelectImg,
        
        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,

        //  모달    ---------------------
        isShowViewer,
        onModalOpen,
        onModalClose,
        onModalApiReq,
        onModalSave,
    } = props;

    return (
        <NextMaintainBlock>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt2'>                            
                            <div className='st2'>
                                <label htmlFor='from_date' className='bg'>기간</label>
                                <input type="date" name="from_date" id="from_date" value={from_date} onChange={onChange} max="9999-12-31"/>
                                <span>~</span>
                                <input type="date" name="to_date" id="to_date" value={to_date} onChange={onChange} max="9999-12-31"/>
                            </div>

                            <div className='st2'>
                                <label htmlFor='work_type'>구분</label>
                                <select name="work_type" id="work_type" value={work_type} onChange={onChange}>
                                    <option value="PREWORK">사전</option>
                                    <option value="NEXTWORK">사후</option>
                                </select>
                            </div>
                            
                            <div className='st2'>
                                <label htmlFor='change_flag'>변동유무</label>
                                <select name="change_flag" id="change_flag" value={change_flag} onChange={onChange}>
                                    <option value="Y,N">전체</option>
                                    <option value="Y">Y</option>
                                    <option value="N">N</option>
                                </select>
                            </div>
                            <div className='st2' tabIndex={0} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboArea'>지역</label>
                                <div className='cboStDiv' style={{width:"150px", height: "36px"}}>
                                    <div className='txtSelectedValues' onClick={() => onShowMultiCbo('AREA')}>
                                        <div>{selectedAreas}</div>
                                        ▼
                                    </div>
                                    {isShowCboArea &&
                                        <ul className="cboMultiple" id="cboArea">
                                            <li>
                                                <input type="checkbox" name="areaALL" id="areaALL" checked={cboAreaData.length === cboAreaData.filter(x => x.checked === true).length} onChange={onAreaAll} />
                                                <label htmlFor="areaALL" className='stinfont'>전체 선택</label>
                                            </li>
                                            {cboAreaData.map((item) => (
                                                <li key={item.area}>
                                                    <input type="checkbox" name={item.area} id={item.area} checked={item.checked} onChange={onAreaChange.bind(this, item)} />
                                                    <label htmlFor={item.area} className='stinfont'>{item.area}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* [DEV] 22.03.25 임의로 초기화버튼추가 */}
                            <div className='btn'>
                                <button className='green-sch-btn'>검색</button>
                                <button className='reset-btn'>검색조건 초기화</button>
                            </div>
                        </div>
                </form>
            </div>

            {/* <h2>검색결과</h2> */}
            <table className='tt'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>대상자</th>
                        <th>주소</th>
                        <th>변동유무</th>
                        <th>실행일</th>
                        <th>변동사유</th>
                        <th>등본 요청일</th>
                        <th>등본 회신일</th>
                        <th>확인 담당자</th>
                        <th>확인일</th>
                        <th>메모</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length === 0 &&
                        <tr>
                            <td colSpan={11}>검색 결과가 없습니다.</td>
                        </tr>
                    }
                    {list.map((item,idx) => {
                        return (
                            <tr key={idx} onClick={onModalOpen.bind(this, item)}>
                                <td>{(30 * (currentPage - 1)) + idx + 1}</td>
                                <td>{item.work_name}</td>
                                <td>{item.work_addr}</td>
                                <td>{item.change_yn}</td>
                                <td>{item.exec_date}</td>
                                <td>{item.change_reason}</td>
                                <td>{item.api_req_date}</td>
                                <td>{item.api_rec_date}</td>
                                <td>{item.check_user}</td>
                                <td>{item.check_date}</td>
                                <td>{item.check_memo}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                    {[...Array(pageCount)].map((item,idx) => {
                        if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                            return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                        }
                        else {
                            return null;
                        }
                    })}
                    </div>
                </div>
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>

            {isShowViewer &&
                <ViewerPopup>
                    <div className='popupWrap'>
                        <h4>변동사항 상세</h4>
                        <div className='divView'>
                            <div>
                                <div className='regInfo'>
                                    <div className='info-box' style={{gridColumn:"1/2"}}>
                                        <div>대상자</div>
                                        <div>{work_name}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"2/5"}}>
                                        <div>주소</div>
                                        <div>{work_addr}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"5/6"}}>
                                        <div>실행일</div>
                                        <div>{exec_date}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"1/2"}}>
                                        <div>변동 유무</div>
                                        <div>{change_yn}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"2/6"}}>
                                        <div>변동 사유</div>
                                        <div>{change_reason}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"1/2"}}>
                                        <div>등본 요청여부</div>
                                        <div>{cert_req_flag}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"2/3"}}>
                                        <div>등본 요청일</div>
                                        <div>{api_req_date}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"3/4"}}>
                                        <div>등본 회신일</div>
                                        <div>{api_rec_date}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"4/5"}}>
                                        <div>확임 담당자</div>
                                        <div>{check_user_name}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"5/6"}}>
                                        <div>확인일</div>
                                        <div>{check_date}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"1/6"}}>
                                        <div>변동여부 Json</div>
                                        <div>{result_text}</div>
                                    </div>
                                    <div className='info-box' style={{gridColumn:"1/6"}}>
                                        <div>메모</div>
                                        <div><textarea name="check_memo" id="check_memo" value={check_memo} onChange={onChange} rows={15} style={{resize:"none"}} /></div>
                                    </div>
                                </div>
                            </div>
                            <div className='modalDivImages'>
                                <div id='imgView' className='imgView'>
                                    {selectImg && <img id="selectImg" className='selectImg' src={selectImg} draggable={false} alt={''}/>}
                                </div>
                                <div className='navImgBox'>
                                    {docImages.map((item, idx) => (
                                        <div key={idx} className='navImg'>
                                            <img src={item.file_data} alt="" onClick={onSelectImg.bind(this, item)}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className='divButtons'>
                            <button className="save-btn" onClick={onModalApiReq}>등본요청</button>
                            <button className="save-btn" onClick={onModalSave}>저장</button>
                            <button className="cancel-btn" onClick={onModalClose}>닫기</button>
                        </div>
                    </div>
                </ViewerPopup>
            }
        </NextMaintainBlock>
    );
}

export default NextMaintain;