import React from 'react';
import styled from 'styled-components';

const AuditRequestListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 30px 50px;
    box-sizing: border-box;

    h1{
        color: #545454;
        font-size: 24px;
        margin: 0 0 20px 0;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }
`;

const AuditRequestList = (props) => {
    const {
        LH_implyAuditList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        LH_implyAuditSearch,

        onClickNum,
        onClickNext,
        onClickPrev,
        onSubmit,
        onChangeSearch,
        onClickList,
    } = props;

    return (
        <AuditRequestListBlock>
            <h1>접속되는 리스트</h1>
            <h2>검색조건</h2>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='searchipt'>
                            <div className='st'>
                                <label htmlFor='date_type'>요청일</label>
                                <input type='date' name='from_date' id='from_date' value={LH_implyAuditSearch.from_date} onChange={onChangeSearch}/>
                                <span>~</span>
                                <input type='date' name='to_date' id='to_date' value={LH_implyAuditSearch.to_date} onChange={onChangeSearch}/>
                            </div>
                        </div>

                        <div className='btn'>
                            <button className='green-sch-btn'>검색</button>
                        </div>
                    </div>
                </form>
            </div>
            <h2>검색결과</h2>
            <table className='tt'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>요청 ID</th>
                        <th>요청일시</th>
                        <th>요청자ID</th>
                        <th>요청자</th>
                        <th>요청자소속</th>
                        <th>요청인원수</th>
                    </tr>
                </thead>
                <tbody>
                {LH_implyAuditList.length > 0?
                <>
                    {LH_implyAuditList.map((item, idx) => {
                        return (
                            <tr key={idx} onClick={() => onClickList(item)}>
                                <td>{(maxPageCount * (currentPage - 1)) + idx+1}</td>
                                <td>{item.request_id}</td>
                                <td>{item.audit_req_date}</td>
                                <td>{item.audit_req_user}</td>
                                <td>{item.user_name}</td>
                                <td>{item.user_company}</td>
                                <td>{item.people_count}</td>
                            </tr>
                        )
                    })}
                </>
                : 
                <tr>
                    <td colSpan={7}>결과가 없습니다.</td>
                </tr>}
                </tbody>
            </table>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            else {
                                return null
                            }
                    })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
        </AuditRequestListBlock>
    );
}

export default AuditRequestList;