import React, { useState } from 'react';
import LHCustomerList from '../components/LHCustomerList';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeSearch, changeValue, checkBox, checkBoxAll, initialize, LHcustImplyBatch, LHcustImplyReg, LHCustReg, LHCustSearch, LHnextWorkBatch, LHnextWorkReg, setPaging, setSearch } from '../modules/customer';
import { useLocation, useNavigate } from 'react-router-dom';
import { qr } from '../common';
import { ALERT } from '../lib/dataWord';

const LHCustomerListContainer = () => {
    const {
        search,
        checkedList,
        winnerExcel_LH,
        customerList_LH,
        max, 
        step, 
        currentPage, 
        pageCount,
        LHMaxPageCount,
    } = useSelector(({customer}) => ({
        winnerExcel_LH: customer.winnerExcel_LH,
        checkedList: customer.checkedList,
        customerList_LH: customer.customerList_LH,
        max: customer.max, 
        step: customer.step, 
        currentPage: customer.currentPage, 
        pageCount: customer.pageCount,
        search: customer.search,
        LHMaxPageCount: customer.LHMaxPageCount,
    }))

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [changeUrl, setChangeUrl] = useState(false);
    const [imply, setImply] = useState(false);
    const [next, setNext] = useState(false);
    const [update, setUpdate] = useState(false);
    const [curIdx, setCurIdx] = useState(-1);

    // 저장하기
    const onClickSave = () => {
        if(window.confirm(`총 ${winnerExcel_LH.length}건을 업로드 하시겠습니까?`)) {
            dispatch(LHCustReg({
                cust_info: winnerExcel_LH
            }))
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 권한기능
    // 일괄 묵시 처리
    const onImplyAll = () => {
        if(window.confirm(`현재 검색 조건의 모든 대상자를 묵시 처리하시겠습니까?\n대상자: ${customerList_LH[0].tot_count}명`)) {
            dispatch(LHcustImplyBatch({
                ...search
            }))
            setUpdate(true);
        }
    }

    // 선택 묵시 처리
    const onImply = () => {
        if(checkedList.length < 1) {
            ALERT("선택된 항목이 없습니다.")
        }
        else {
            if(window.confirm(`총 ${checkedList.length}명을 묵시대상자로 처리 하시겠습니까?`)) {
                let payload = checkedList.map((item) => item.lh_cust_id);
                let valid = true;
                let el = null;

                for (let index = 0; index < checkedList.length; index++) {
                    el = checkedList[index];
                    if(el.imply_yn === "Y") {
                        valid = false;
                        break;
                    }
                }
                if(valid) {
                    dispatch(LHcustImplyReg({
                        cust_id: payload.toString(),
                    }))
                    setUpdate(true);
                }
                else {
                    ALERT("이미 묵시 대상자로 등록되어있는 사용자가 있습니다.");
                }
            }
        }
    }

    // 일괄 사후 처리
    const onNextWorkAll = () => {
        if(window.confirm(`현재 검색 조건의 모든 대상자를 사후관리 대상자로 처리하시겠습니까?\n대상자: ${customerList_LH[0].tot_count}명`)) {
            dispatch(LHnextWorkBatch(search))
            setUpdate(true);
        }
    }

    // 선택 사후 처리
    const onNextWork = () => {
        if(checkedList.length < 1) {
            ALERT("선택된 항목이 없습니다.")
        }
        else {
            if(window.confirm(`총 ${checkedList.length}명을 사후관리 대상자로 처리 하시겠습니까?`)) {
                let payload = checkedList.map((item) => item.lh_cust_id);
                dispatch(LHnextWorkReg({
                    cust_id: payload.toString()
                }))
                setUpdate(true)
            }
        }
    }

    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeSearch({
            key: name,
            value
        }))
        dispatch(changeSearch({
            key: 'page',
            value: '1'
        }))
    }

    const onChangeNum = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeValue({
            key: name,
            value
        }))
    }

    // 검색조건 초기화
    const onInitSearchvalue = () => {
        dispatch(setSearch({
            search: 'init'
        }))
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 체크박스
    // 체크박스 개별
    const onCheck = (e, item) => {
        const {target: {checked}} = e;
        dispatch(checkBox({
            checked, 
            item,
            target: 'lh'
        }))
    }

    // 체크박스 전체
    const onCheckAll = (e) => {
        const {target: {checked}} = e;
        dispatch(checkBoxAll({
            checked,
            target: 'lh',
        }))
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeSearch({key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------

    const onClickList = (idx) => {
        setCurIdx(idx);
    }

    useEffect(() => {
        // 초기에 들어온 경우 (쿼리스트링이 없는 경우에 해당)
        if(!location.search) {
            setChangeUrl(true)
        }
        // 쿼리스트링이 있으면 그 값을 search 에 할당
        else if(location.search && dispatch) {
            let tempObj = qr(location.search);
            dispatch(setSearch({
                search: tempObj
            }))
            dispatch(LHCustSearch({
                ...tempObj,

                // start_date: "",
                // end_date: "",
            }))
            dispatch(setPaging({
                key: "currentPage",
                value: Number(tempObj.page),
            }))
            dispatch(setPaging({
                key: "step",
                value: Number(tempObj.page),
            }))
        }
    }, [location, dispatch])

    // 쿼리 스트링 구성, 변경, 이동
    useEffect(() => {
        if(changeUrl && navigate) {
            navigate(`${location.pathname}?date_type=${search.date_type}&start_date=${search.start_date}&end_date=${search.end_date}&phone=${search.phone}&name=${search.name}&use_flag=${search.use_flag}&area=${search.area}&view_count=${search.view_count}&page=${search.page}`,
            {
                state: {
                    func: location.state.func
                },
                replace: location.search? false : true
            })
            setChangeUrl(false);
            setCurIdx(-1);
        }
    }, [changeUrl, navigate, location, search])
    
    // 권한설정
    useEffect(() => {
        if(location.state.func) {
            const {state: {func}} = location;
            if(func.some((el) => el.id === "FUNC961")) setImply(true);
            if(func.some((el) => el.id === "FUNC962")) setNext(true);
        }
        else {
            setImply(false);
            setNext(false);
        }
    }, [location]);

    useEffect(() => {
        if(update && dispatch && location) {
            let tempObj = qr(location.search);

            dispatch(LHCustSearch({
                ...tempObj,
            }));
            setUpdate(false);
            setCurIdx(-1);
        }
    }, [update, dispatch, location])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    const propDatas = {
        search,
        customerList_LH,
        checkedList,
        imply,
        next,
        max, 
        step, 
        currentPage, 
        pageCount,
        LHMaxPageCount,
        curIdx,

        onCheck,
        onCheckAll,
        onClickSave,
        onChangeSearch,
        onSubmit,
        onClickList,
        onClickNum,
        onClickNext,
        onClickPrev,
        onImplyAll,
        onImply,
        onNextWorkAll,
        onNextWork,
        onChangeNum,
        onInitSearchvalue,
    };

    return (
        <>
            <LHCustomerList {...propDatas}/>
        </>
    );
}

export default LHCustomerListContainer;