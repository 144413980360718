import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as workAPI from '../lib/api/work';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'nextMaintain/INITIALIZE';
const CHANGE_VALUE = 'nextMaintain/CHANGE_VALUE';
const SET_PAGING = 'nextMaintain/SET_PAGING';
const [GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE] = createRequestActionTypes('nextMaintain/GET_LIST');
const [GET_VIEW, GET_VIEW_SUCCESS, GET_VIEW_FAILURE] = createRequestActionTypes('nextMaintain/GET_VIEW');
const DOC_SELECT_IMG = 'nextMaintain/DOC_SELECT_IMG';
const [SAVE_VIEW, SAVE_VIEW_SUCCESS, SAVE_VIEW_FAILURE] = createRequestActionTypes('nextMaintain/SAVE_VIEW');
const [GET_API, GET_API_SUCCESS, GET_API_FAILURE] = createRequestActionTypes('nextMaintain/GET_API');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const setPaging = createAction(SET_PAGING, (payload) => payload);
export const getList = createAction(GET_LIST, (body) => (body));
export const getView = createAction(GET_VIEW);
export const doc_selectImg = createAction(DOC_SELECT_IMG, (value) => value);
export const saveView = createAction(SAVE_VIEW);
export const getApi = createAction(GET_API);

const getListSaga = createRequestSaga(GET_LIST, workAPI.change_search);
const getViewSaga = createRequestSaga(GET_VIEW, workAPI.change_view);
const saveViewSaga = createRequestSaga(SAVE_VIEW, workAPI.change_memo);
const getApiSaga = createRequestSaga(GET_API, adminAPI.apiRequest);

export function* nextMaintainSaga() {
    yield takeLatest(GET_LIST, getListSaga);
    yield takeLatest(GET_VIEW, getViewSaga);
    yield takeLatest(SAVE_VIEW, saveViewSaga);
    yield takeLatest(GET_API, getApiSaga);
}

const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10);

const initialState = {
    isSaveOk: false,    //  저장완료 여부

    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    pageCount: null, // 구성할 페이지 수
    max: 3, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1, // 1 2 3 -> 4 5 6 
    currentPage: 1, // 현재 페이지 (page 의 값이 됨.)
    
    //  검색조건
    from_date: oneMonthAgo,
    to_date: today,
    work_type: "NEXTWORK",
    change_flag: "Y,N",
    area: "",
    page: "1",

    list: [],
    
    //  Modal Data
    change_id: '',
    work_name: '',
    work_addr: '',
    exec_date: '',
    change_yn: '',
    change_reason: '',
    cert_req_flag: '',
    api_req_date: '',
    api_rec_date: '',
    check_user_name: '',
    check_date: '',
    result_text: '',
    check_memo: '',
    selectImg: null,
    docImages: [],

    nextMaintain: null,
    error: null
};

const nextMaintain = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [GET_LIST_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                list: payload,
                pageCount: payload.length > 0 ? Math.ceil(payload[0].tot_count / state.maxPageCount) : null,
                currentPage: state.currentPage ? state.currentPage : 1,
            }
        },
        [GET_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                list: [],
                pageCount: null,
                max: 3,
                step: 1,
                currentPage: null,
                error
            }
        },

        //  페이지 이동
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                        search: {
                            ...state.search,
                            page: (state.step + 1) * state.max - 2
                        }
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1,
                        search: {
                            ...state.search,
                            page: (state.step - 1) * state.max - 2
                        }
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },

        //  모달    =======================================================================================
        [GET_VIEW_SUCCESS]: (state, { payload }) => {
            let _docImages = [], _selectImg = null;
            const _file_url = payload[0].file_url ? payload[0].file_url.split(',') : [];
            _file_url.forEach(item => {
                if(!_selectImg) {
                    _selectImg = item;
                }
                _docImages.push(item);
            });

            return {
                ...state,
                change_id: payload[0].change_id,
                work_name: payload[0].work_name,
                work_addr: payload[0].work_addr,
                exec_date: payload[0].exec_date,
                change_yn: payload[0].change_yn,
                change_reason: payload[0].change_reason,
                cert_req_flag: payload[0].cert_req_flag,
                api_req_date: payload[0].api_req_date,
                api_rec_date: payload[0].api_rec_date,
                check_user_name: payload[0].check_user_name,
                check_date: payload[0].check_date,
                result_text: payload[0].result_text,
                check_memo: payload[0].check_memo,
                docImages: _docImages,
                selectImg: _selectImg,
            }
        },
        [GET_VIEW_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [DOC_SELECT_IMG]: (state, { payload }) => {
            return {
                ...state,
                selectImg: payload,
            };
        },
        [SAVE_VIEW_SUCCESS]: (state, { payload }) => {
            ALERT("저장되었습니다.");
            return {
                ...state,
            }
        },
        [SAVE_VIEW_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [GET_API_SUCCESS]: (state, { payload }) => {
            ALERT("등기부등본 API 요청이 완료되었습니다.");
            return {
                ...state,
                cert_req_flag: 'Y',
            }
        },
        [GET_API_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default nextMaintain;