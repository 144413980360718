import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import EntitleWorkContainer from '../containers/EntitleWorkContainer';

const EntitleWorkPage = () => {
    return (
        <>
            <HeaderContainer />
            <EntitleWorkContainer/>
        </>
    );
}

export default EntitleWorkPage;