import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as implyAPI from '../lib/api/Imply';
import * as adminAPI from '../lib/api/admin';
import { takeLatest } from 'redux-saga/effects';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'Imply/INITIALIZE';
const INIT_INPUT = 'Imply/INIT_INPUT';
const CHANGE_VALUE = 'Imply/CHANGE_VALUE';
const SET_PAGING = 'Imply/SET_PAGING';
const SET_SEARCH_VALUE = 'Imply/SET_SEARCH_VALUE';
const SET_SEARCH = 'Imply/SET_SEARCH';
const CHANGE_SEARCH_CHECKBOX = 'Imply/CHANGE_SEARCH_CHECKBOX';
const SET_INDEX = 'Imply/SET_INDEX';
const ADD_CHECKEDARR = 'Imply/ADD_CHECKEDARR';
const SET_CHECKALL = 'Imply/SET_CHECKALL';
const SET_RESULT = 'Imply/SET_RESULT';
const SET_IMPLY_SEARCH = 'Imply/SET_IMPLY_SEARCH';
const SET_AUDIT_SEARCH = 'Imply/SET_AUDIT_SEARCH';
const SET_IMPLY_MAIN_SEARCH = 'Imply/SET_IMPLY_MAIN_SEARCH';

// const SET_AUDIT_CHECK = 'Imply/SET_AUDIT_CHECK';
// const SET_AUDIT_CHECKALL = 'Imply/SET_AUDIT_CHECKALL';

const SET_IMPLY_MAIN_CHECK = 'Imply/SET_IMPLY_MAIN_CHECK';
const SET_IMPLY_MAIN_CHECKALL = 'Imply/SET_IMPLY_MAIN_CHECKALL';

const IMPLY_WORK_CHECK = 'Imply/IMPLY_WORK_CHECK';
const IMPLY_EXCEL_CHANGE_VALUE = 'Imply/IMPLY_EXCEL_CHANGE_VALUE'

const [IMPLY_WRITE, IMPLY_WRITE_SUCCESS, IMPLY_WRITE_FAILURE] = createRequestActionTypes('Imply/IMPLY_WRITE');      // 묵시대상자 등록
const [IMPLY_REQUEST, IMPLY_REQUEST_SUCCESS, IMPLY_REQUEST_FAILURE] = createRequestActionTypes('Imply/IMPLY_REQUEST');// 묵시대상자 등록요청
const [IMPLY_REQUEST_SEARCH, IMPLY_REQUEST_SEARCH_SUCCESS, IMPLY_REQUEST_SEARCH_FAILURE] = createRequestActionTypes('Imply/IMPLY_REQUEST_SEARCH'); // 묵시대상자 등록요청 조회
const [IMPLY_DETAIL_SEARCH, IMPLY_DETAIL_SEARCH_SUCCESS, IMPLY_DETAIL_SEARCH_FAILURE] = createRequestActionTypes('Imply/IMPLY_DETAIL_SEARCH'); // 묵시대상자 등록요청건 상세조회
const [IMPLY_VIEW, IMPLY_VIEW_SUCCESS, IMPLY_VIEW_FAILURE] = createRequestActionTypes('Imply/IMPLY_VIEW'); // 묵시대상자 상세 조회
const [IMPLY_REQUEST_APPROVE, IMPLY_REQUEST_APPROVE_SUCCESS, IMPLY_REQUEST_APPROVE_FAILURE] = createRequestActionTypes('Imply/IMPLY_REQUEST_APPROVE'); // 묵시대상자 최종반영
const [IMPLY_DELETE, IMPLY_DELETE_SUCCESS, IMPLY_DELETE_FAILURE] = createRequestActionTypes('Imply/IMPLY_DELETE'); // 묵시대상자 삭제요청
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('Imply/GET_COMBO');
const [IMPLY_RESULT_WRITE, IMPLY_RESULT_WRITE_SUCCESS, IMPLY_RESULT_WRITE_FAILURE] = createRequestActionTypes('Imply/IMPLY_RESULT_WRITE');
const [IMPLY_SEARCH, IMPLY_SEARCH_SUCCESS, IMPLY_SEARCH_FAILURE] = createRequestActionTypes('Imply/IMPLY_SEARCH');
const [IMPLY_SAVE, IMPLY_SAVE_SUCCESS, IMPLY_SAVE_FAILURE] = createRequestActionTypes('Imply/IMPLY_SAVE');
const [IMPLY_AUDIT_REQUEST, IMPLY_AUDIT_REQUEST_SUCCESS, IMPLY_AUDIT_REQUEST_FAILURE] = createRequestActionTypes('Imply/IMPLY_AUDIT_REQUEST');
const [IMPLY_AUDIT_CHECK, IMPLY_AUDIT_CHECK_SUCCESS, IMPLY_AUDIT_CHECK_FAILURE] = createRequestActionTypes('Imply/IMPLY_AUDIT_CHECK');
const [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAILURE] = createRequestActionTypes('Imply/API_REQUEST');
const [IMPLY_AUDIT_SEARCH, IMPLY_AUDIT_SEARCH_SUCCESS, IMPLY_AUDIT_SEARCH_FAILURE] = createRequestActionTypes('Imply/IMPLY_AUDIT_SEARCH');
const [IMPLY_MAIN, IMPLY_MAIN_SUCCESS, IMPLY_MAIN_FAILURE] = createRequestActionTypes('Imply/IMPLY_MAIN');
const [IMPLY_APPR_REQ, IMPLY_APPR_REQ_SUCCESS, IMPLY_APPR_REQ_FAILURE] = createRequestActionTypes('Imply/IMPLY_APPR_REQ');
const [IMPLY_APPR_LIST, IMPLY_APPR_LIST_SUCCESS, IMPLY_APPR_LIST_FAILURE] = createRequestActionTypes('Imply/IMPLY_APPR_LIST');
const [IMPLY_API, IMPLY_API_SUCCESS, IMPLY_API_FAILURE] = createRequestActionTypes('Imply/IMPLY_API');
const [IMPLY_APPR_DTL_LIST, IMPLY_APPR_DTL_LIST_SUCCESS, IMPLY_APPR_DTL_LIST_FAILURE] = createRequestActionTypes('Imply/IMPLY_APPR_DTL_LIST');
const [IMPLY_APPROVE, IMPLY_APPROVE_SUCCESS, IMPLY_APPROVE_FAILURE] = createRequestActionTypes('Imply/IMPLY_APPROVE');
const [IMPLY_UPLOAD, IMPLY_UPLOAD_SUCCESS, IMPLY_UPLOAD_FAILURE] = createRequestActionTypes('Imply/IMPLY_UPLOAD');
const [IMPLY_REPORT_REG, IMPLY_REPORT_REG_SUCCESS, IMPLY_REPORT_REG_FAILURE] = createRequestActionTypes('Imply/IMPLY_REPORT_REG');
const [IMPLY_AUDIT_REQ, IMPLY_AUDIT_REQ_SUCCESS, IMPLY_AUDIT_REQ_FAILURE] = createRequestActionTypes('Imply/IMPLY_AUDIT_REQ');
const [IMPLY_REQ_SEARCH, IMPLY_REQ_SEARCH_SUCCESS, IMPLY_REQ_SEARCH_FAILURE] = createRequestActionTypes('Imply/IMPLY_REQ_SEARCH');

export const initInput = createAction(INIT_INPUT);
export const impWrite = createAction(IMPLY_WRITE, (body) => (body));
export const setSearch = createAction(SET_SEARCH, (body) => (body));
export const impRequest = createAction(IMPLY_REQUEST, (body) => (body));
export const impRequestSearch = createAction(IMPLY_REQUEST_SEARCH, (body) => (body));
export const impDetailSearch= createAction(IMPLY_DETAIL_SEARCH, (body) => (body));
export const impView = createAction(IMPLY_VIEW, ({imply_id}) => ({imply_id}));
export const impRequestApprove = createAction(IMPLY_REQUEST_APPROVE, (body) => (body));
export const impDelete = createAction(IMPLY_DELETE, (body) => (body));
export const getCombo = createAction(GET_COMBO);
export const addCheckedArr = createAction(ADD_CHECKEDARR, (body) => (body));
export const setCheckAll = createAction(SET_CHECKALL, (body) => (body));
export const impResultWrite = createAction(IMPLY_RESULT_WRITE, (body) => (body));
export const setResult = createAction(SET_RESULT, (body) => (body));
export const impSearch = createAction(IMPLY_SEARCH, (body) => (body));
export const setAuditSearch = createAction(SET_AUDIT_SEARCH, (body) => (body));
export const setImplyMainSearch = createAction(SET_IMPLY_MAIN_SEARCH, (body) => (body));
export const impSave = createAction(IMPLY_SAVE, (body) => (body));
export const setImpSearch = createAction(SET_IMPLY_SEARCH, (body) => (body));
export const impAuditRequest = createAction(IMPLY_AUDIT_REQUEST, (body) => (body));
export const impAuditCheck = createAction(IMPLY_AUDIT_CHECK, (body) => (body));
export const impWorkCheck = createAction(IMPLY_WORK_CHECK, (body) => (body));
export const apiRequest = createAction(API_REQUEST, (body) => (body));
export const impAuditSearch = createAction(IMPLY_AUDIT_SEARCH, (body) => (body));
export const implyMain = createAction(IMPLY_MAIN, (body) => (body));
export const implyApi = createAction(IMPLY_API, (body) => (body));
export const implyExcelChangeValue = createAction(IMPLY_EXCEL_CHANGE_VALUE, (body) => (body));

// export const setAuditCheck = createAction(SET_AUDIT_CHECK, (body) => (body));
// export const setAuditCheckAll = createAction(SET_AUDIT_CHECKALL, (body) => (body));

export const setImplyMainCheck = createAction(SET_IMPLY_MAIN_CHECK, (body) => (body));
export const setImplyMainCheckAll = createAction(SET_IMPLY_MAIN_CHECKALL, (body) => (body));
export const apprReq = createAction(IMPLY_APPR_REQ, (body) => (body));
export const apprList = createAction(IMPLY_APPR_LIST, (body) => (body));
export const reportReg = createAction(IMPLY_REPORT_REG, (body) => (body));


export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, (body) => (body));
export const setPaging = createAction(SET_PAGING, (payload) => payload);
export const setSearchValue = createAction(SET_SEARCH_VALUE, (payload) => payload);
export const changeSearcheckBox = createAction(CHANGE_SEARCH_CHECKBOX, ({checked, key, value}) => ({checked, key, value}));
export const setIndex = createAction(SET_INDEX, ({idx}) => ({idx}))
export const implyApprDtlList = createAction(IMPLY_APPR_DTL_LIST, (body) => (body));
export const implyApprove = createAction(IMPLY_APPROVE, (body) => (body));
export const implyUpload = createAction(IMPLY_UPLOAD, (body) => (body));
export const implyAuditRequest = createAction(IMPLY_AUDIT_REQ, (body) => (body));
export const implyRequestSearch = createAction(IMPLY_REQ_SEARCH, (body) => (body));

const impWriteSaga = createRequestSaga(IMPLY_WRITE, implyAPI.imply_write);
const impRequestSaga = createRequestSaga(IMPLY_REQUEST, implyAPI.request);
const impRequestSearchSaga = createRequestSaga(IMPLY_REQUEST_SEARCH, implyAPI.req_search);
const impDetailSearchSaga = createRequestSaga(IMPLY_DETAIL_SEARCH, implyAPI.detail_search);
const impViewSaga = createRequestSaga(IMPLY_VIEW, implyAPI.imply_view);
const impRequestApproveSaga = createRequestSaga(IMPLY_REQUEST_APPROVE, implyAPI.req_approve);
const impDeleteSaga = createRequestSaga(IMPLY_DELETE, implyAPI.imply_delete);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_search);
const impResultWriteSaga = createRequestSaga(IMPLY_RESULT_WRITE, implyAPI.result_write);
const impSearchSaga = createRequestSaga(IMPLY_SEARCH, implyAPI.imply_search);
const impSaveSaga = createRequestSaga(IMPLY_SAVE, implyAPI.imply_save);
const impAuditRequestSaga = createRequestSaga(IMPLY_AUDIT_REQUEST, implyAPI.audit_req);
const impAuditCheckSaga = createRequestSaga(IMPLY_AUDIT_CHECK, implyAPI.audit_chk);
const apiRequestSaga = createRequestSaga(API_REQUEST, adminAPI.apiRequest);
const impAuditSearchSaga = createRequestSaga(IMPLY_AUDIT_SEARCH, implyAPI.result_search);
const implyMainSaga = createRequestSaga(IMPLY_MAIN, implyAPI.imply_main);
const apprReqSaga = createRequestSaga(IMPLY_APPR_REQ, implyAPI.appr_req);
const apprListSaga = createRequestSaga(IMPLY_APPR_LIST, implyAPI.appr_list);
const implyApiSaga = createRequestSaga(IMPLY_API, implyAPI.imply_api);
const implyApprDtlListSaga = createRequestSaga(IMPLY_APPR_DTL_LIST, implyAPI.appr_dtl_list);
const implyApproveSaga = createRequestSaga(IMPLY_APPROVE, implyAPI.approve);
const implyUploadSaga = createRequestSaga(IMPLY_UPLOAD, implyAPI.imply_upload);
const reportRegSaga = createRequestSaga(IMPLY_REPORT_REG, implyAPI.report_reg);
const implyAuditRequestSaga = createRequestSaga(IMPLY_AUDIT_REQ, implyAPI.lh_audit_req);
const implyRequestSearchSaga = createRequestSaga(IMPLY_REQ_SEARCH, implyAPI.imp_req_search);


export function* ImplySaga() {
    yield takeLatest(IMPLY_WRITE, impWriteSaga);
    yield takeLatest(IMPLY_REQUEST, impRequestSaga);
    yield takeLatest(IMPLY_REQUEST_SEARCH, impRequestSearchSaga);
    yield takeLatest(IMPLY_DETAIL_SEARCH, impDetailSearchSaga);
    yield takeLatest(IMPLY_VIEW, impViewSaga);
    yield takeLatest(IMPLY_REQUEST_APPROVE, impRequestApproveSaga);
    yield takeLatest(IMPLY_DELETE, impDeleteSaga);
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(IMPLY_RESULT_WRITE, impResultWriteSaga);
    yield takeLatest(IMPLY_SEARCH, impSearchSaga);
    yield takeLatest(IMPLY_AUDIT_REQUEST, impAuditRequestSaga);
    yield takeLatest(IMPLY_AUDIT_CHECK, impAuditCheckSaga);
    yield takeLatest(API_REQUEST, apiRequestSaga);
    yield takeLatest(IMPLY_SAVE, impSaveSaga);
    yield takeLatest(IMPLY_AUDIT_SEARCH, impAuditSearchSaga);
    yield takeLatest(IMPLY_MAIN, implyMainSaga);
    yield takeLatest(IMPLY_APPR_REQ, apprReqSaga);
    yield takeLatest(IMPLY_APPR_LIST, apprListSaga);
    yield takeLatest(IMPLY_API, implyApiSaga);
    yield takeLatest(IMPLY_APPR_DTL_LIST, implyApprDtlListSaga);
    yield takeLatest(IMPLY_APPROVE, implyApproveSaga);
    yield takeLatest(IMPLY_UPLOAD, implyUploadSaga);
    yield takeLatest(IMPLY_REPORT_REG, reportRegSaga);
    yield takeLatest(IMPLY_AUDIT_REQ, implyAuditRequestSaga);
    yield takeLatest(IMPLY_REQ_SEARCH, implyRequestSearchSaga);
}

const date = new Date();
const year = date.getFullYear();
const month = ("0"+(date.getMonth() + 1)).slice(-2);
const month_m = ("0"+date.getMonth()).slice(-2);
const initialState = {
    Imply: null,
    error: null,

    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    pageCount: null, // 구성할 페이지 수
    max: 5, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1, // 1 2 3 -> 4 5 6 
    currentPage: 1, // 현재 페이지 (page 의 값이 됨.)

    ImplyList: [],      // 묵시대상자 리스트
    ImplyReqList: [],   // 요청 리스트
    cboApply: [],
    modiIdx: null,

    checkedArr: [], // 체크한거 여기 담김 (id만)
    selectedType: [], // 선택된 타입 체크(동일 유형끼리 등록 가능하도록)

    results: [], // 묵시 등본요청 결과저장용 배열 (여기에 담아서 전달)

    search: { // 묵시 대상자 등록 검색
        date_type: "REQUEST",
        from_date: `${year - 2}-${month}-01`,
        to_date: `${year}-${month}-${new Date(year, month, 0).getDate()}`,
        type: '',
        req_user: '',
        prc_user: '',
        status: "",
        status1: "",
        status2: "",
        page: 1
    },

    // 묵시 작업페이지 (건별)
    implyListSearch: {
        date_type: 'REQ_DATE',
        from_date:`${year}-${month_m}-01`,
        to_date:`${year}-${month}-${new Date(year, month, 0).getDate()}`,
        appr_flag: '',
        report_flag: '',
        page: '1',
    },
    implyList: [],
    implyListCheck: [],

    // 묵시 작업페이지 (상세)
    implySearch: { // 묵시작업페이지 검색
        date_type:"", 
        from_date:`${year}-${month_m}-01`,
        to_date:`${year}-${month}-${new Date(year, month, 0).getDate()}`,
        addr:"", 
        name:"", 
        req_status:"",
        page:"1",
        request_id: '',
    },
    impWorkList: [], // 묵시작업 페이지에서 묵시대상자 조회하면 여기에담김

    // 등초본
    implyAuditSearch: { // 등초본조회 검색
        date_type:"REQUEST", 
        from_date:`${year}-${month_m}-01`,
        to_date:`${year}-${month}-${new Date(year, month, 0).getDate()}`,
        area: "", 
        addr:"", 
        name:"", 
        page:"1"
    },
    impAuditList: [], // 등초본 조회결과 리스트

    // 등초본 요청 리스트(LH)
    LH_implyAuditSearch: {
        from_date:`${year}-${month_m}-01`,
        to_date:`${year}-${month}-${new Date(year, month, 0).getDate()}`,
        page: "1"
    },
    LH_implyAuditList: [], // 건별
    implyAuditRequestList: [],


    // 묵시 결재 요청 리스트
    implyApproveSearch: {
        from_date:`${year}-${month_m}-01`,
        to_date:`${year}-${month}-${new Date(year, month, 0).getDate()}`,
        status:"", 
        req_user:"", 
        // all_search:"Y", 
        page:"1"
    },
    implyApproveList: [],
    implyApprove_implyList: [], // 데이터
    implyApprove_approvalList: [], // 결재라인
    implyApprove_memo: '', // 결재 메모
    implyApproveSuccess: '', // 결재 성공 여부
    
    input: {
        imply_id: "",
        type: "",
        rec_date: "",
        rec_area: '',
        name: "",
        birth: "",
        imply_no: "",
        phone: "",
        house_owner: "",
        addr: "",
        contract_end_date: "",
        rent_amt: "",
        use_flag: "",
        memo: ""
    },
    approveSuccess: false,

    // // 등초본 요청할 대상 리스트 (엑셀용)
    // auditArr: [],

    // //  등초본 요청할 대상 리스트 (api 요청용)
    // auditStr: [],

    //묵시대상자 엑셀 업로드
    implyExcel: [], // 엑셀 데이터
    implyExcelHeader: [], // 헤더
    
};

const Imply = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [SET_SEARCH]: (state, {payload: {search, value}}) => {
            if(value === null) {
                return ({
                    ...state,
                    [search]: initialState[search]
                })
            }
            else {
                return ({
                    ...state,
                    [search]: value,
                })
            }
        },
        [SET_IMPLY_MAIN_SEARCH]: (state, {payload: {value}}) => {
            return ({
                ...state,
                implyListSearch: value
            })
        },
        [SET_AUDIT_SEARCH]: (state, {payload: {value}}) => {
            return ({
                ...state,
                implyAuditSearch: value
            })
        },
        [CHANGE_VALUE]: (state, { payload: {field, key, value, idx = null}}) => {
            // 필드를 특정함.
            if(field) {
                // 인덱스를 찾아서 변경해야 할때
                if(idx !== null) {
                    return ({
                        ...state,
                        [field]: state[field].map((item, subIdx) => {
                            if(subIdx === idx) {
                                return ({
                                    ...item,
                                    [key]: value
                                })
                            }
                            else  {
                                return ({
                                    ...item
                                })
                            }
                        })
                    })
                }
                // 일반적인 값 변경
                else {
                    return ({
                        ...state,
                        [field]: {
                            ...state[field],
                            [key]: value
                        }
                    })
                }
            }
            // 필드 없음
            // 단순 변경
            else {
                return ({
                    ...state,
                    [key]: value
                })
            }
        },
        [IMPLY_WORK_CHECK]: (state, {payload:{name, checked, idx}}) => {
            // 체크할때
            if(checked) {
                return ({
                    ...state,
                    impWorkList: state.impWorkList.map((item, subIdx) => ({
                            ...item,
                            [name]: subIdx === Number(idx)? 'Y' : item[name]
                    }))
                })
            }
            else {
                return ({
                    ...state,
                    impWorkList: state.impWorkList.map((item, subIdx) => ({
                            ...item,
                            [name]: subIdx === Number(idx)? null : item[name]
                    }))
                })
            }
        },
        [SET_IMPLY_SEARCH]: (state, {payload: {value}}) => {
            return ({
                ...state,
                implySearch: value
            })
        },
        [IMPLY_WRITE_SUCCESS]: (state, {payload}) => {
            ALERT("묵시대상자 정보가 저장되었습니다.")
            return ({
                ...state
            })
        },
        [IMPLY_WRITE_FAILURE]: (state, {payload}) => {
            ALERT("묵시대상자 정보 저장을 실패했습니다.")

            return ({
                ...state
            })
        },
        [IMPLY_REQUEST_SUCCESS]: (state, {payload}) => {
            ALERT('등록요청에 성공했습니다.')
            return ({
                ...state,
                checkedArr: [],
                selectedType: [], 
            })
        },
        [IMPLY_REQUEST_FAILURE]: (state, {payload}) => {
            ALERT('등록요청에 실패했습니다.')
            return ({
                ...state
            })
        },
        [IMPLY_REQUEST_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                ImplyReqList: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            })
        },
        [IMPLY_REQUEST_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                ImplyReqList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
                error: payload
            })
        },
        [IMPLY_DETAIL_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                ImplyList: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            })
        },
        [IMPLY_DETAIL_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                ImplyList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
                error: payload
            })
        },
        [IMPLY_AUDIT_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                impAuditList: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            })
        },
        [IMPLY_AUDIT_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                impAuditList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
                error: payload
            })
        },

        [IMPLY_MAIN_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                implyList: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            })
        },
        [IMPLY_MAIN_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                implyList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
                error: payload
            })
        },
        
        [IMPLY_VIEW_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                input: payload[0]
            })
        },
        [IMPLY_VIEW_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                input: initialState.input
            })
        },
        [IMPLY_REQUEST_APPROVE_SUCCESS]: (state) => {
            ALERT("최종반영에 성공했습니다.");
            return ({
                ...state,
                approveSuccess: true
            })
        },
        [IMPLY_REQUEST_APPROVE_FAILURE]: (state, {payload}) => {
            ALERT("최종반영에 실패했습니다.");
            return ({
                ...state,
                error: payload,
                approveSuccess: false
            })
        },
        [IMPLY_DELETE_SUCCESS]: (state) => {
            ALERT("삭제했습니다.")
            return ({
                ...state,
                checkedArr: [],
                selectedType: [], 
            })
        },
        [IMPLY_DELETE_FAILURE]: (state, {payload}) => {
            ALERT("삭제 실패했습니다")
            return ({
                ...state,
                error: payload,
            })
        },
        [IMPLY_SAVE_SUCCESS]: (state) => {
            ALERT("저장했습니다.")
            return ({
                ...state,
            })
        },
        [IMPLY_SAVE_FAILURE]: (state, {payload}) => {
            ALERT("저장 실패했습니다")
            return ({
                ...state,
                error: payload,
            })
        },
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                const nextPage = (state.step + 1) * state.max - (state.max - 1)
                const prevPage = (state.step - 1) * state.max - (state.max - 1)
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                        search: {
                            ...state.search,
                            page: nextPage
                        },
                        implySearch: {
                            ...state.implySearch,
                            page: nextPage
                        },
                        implyAuditSearch: {
                            ...state.implyAuditSearch,
                            page: nextPage
                        },
                        implyListSearch: {
                            ...state.implyListSearch,
                            page: nextPage
                        },
                        LH_implyAuditSearch: {
                            ...state.LH_implyAuditSearch,
                            page: nextPage
                        }
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1,
                        search: {
                            ...state.search,
                            page: prevPage
                        },
                        implySearch: {
                            ...state.implySearch,
                            page: prevPage
                        },
                        implyAuditSearch: {
                            ...state.implyAuditSearch,
                            page: prevPage
                        },
                        implyListSearch: {
                            ...state.implyListSearch,
                            page: prevPage
                        }   ,
                        LH_implyAuditSearch: {
                            ...state.LH_implyAuditSearch,
                            page: prevPage
                        }                    
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },
        [SET_SEARCH_VALUE]: (state, {payload}) => ({
            ...state,
            search: payload
        }),
        [CHANGE_SEARCH_CHECKBOX]: (state, {payload: {value,key,checked}}) => ({
            ...state,
            search: {...state.search, [key]:checked? value : ""}
        }),
        [SET_INDEX]: (state, {payload: {idx}}) => {
            return ({
                ...state,
                modiIdx: idx,
            })
        },
        [INIT_INPUT]: (state) => ({
            ...state,
            input: initialState.input
        }),
        [GET_COMBO_SUCCESS]: (state, {payload}) => {
            let _APPLY_TYPE = [];
            payload.forEach(item => {
                _APPLY_TYPE.push({
                    ...item,
                    checked: true,
                })
            });
            return ({
                ...state,
                cboApply: _APPLY_TYPE
            })
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [ADD_CHECKEDARR]: (state, {payload: {isChecked, item}}) => {
            if(isChecked) {
                return ({
                    ...state,
                    checkedArr: state.checkedArr.concat(item.imply_id),
                    selectedType: 
                        // selectedType 에 입력값이 있는지
                        state.selectedType.some((el) => el.type === item.type)? 
                            state.selectedType.map((el) => ({
                                ...el,
                                count: el.type === item.type? el.count + 1 : el.count
                            })) // 있으면 해당 카운트 증가
                            : 
                            state.selectedType.concat({
                                count: 1,
                                name: item.type_name,
                                type: item.type
                            }) // 없으면 배열 추가
                })
                // checkedArr 에 아이디 추가한다.
            }
            else {
                return ({
                    ...state,
                    checkedArr: state.checkedArr.filter((el) => el !== item.imply_id),
                    selectedType: 
                        // 기존 체크 배열에 카운트를 뺄대
                        state.selectedType.some((el) => el.type === item.type && el.count - 1 === 0 )?
                            state.selectedType.filter((el) => el.type !== item.type) //  0이면 해당 배열 제거
                            :
                            state.selectedType.map((el) => ({
                                ...el,
                                count: el.type === item.type? el.count - 1 : el.count
                            })) // 0이 아니면 1 빼기
                })
            }
        },
        [SET_CHECKALL]: (state, {payload: {isChecked}}) => {
            let _checkedArr = [];
            let _selectedType = [];

            // 전체체크
            if(isChecked) {
                state.ImplyList.forEach((el) => {
                    _checkedArr.push(el.imply_id) // _checkedArr에 아이디 추가

                    // 첫번째 타입은  바로 추가한다
                    if(_selectedType.length < 1) { 
                        _selectedType.push({
                            count: 1,
                            name: el.type_name,
                            type: el.type
                        })
                    }
                    //  이후 비교시작
                    else {
                        // 기존에 있던거면 
                        if(_selectedType.some((el2) => el2.type === el.type)) {
                            _selectedType = _selectedType.map((el3) => { // 카운트를 증가시킨다.
                                if(el3.type === el.type) {
                                    return ({
                                        ...el3,
                                        count: el3.count + 1
                                    })
                                }
                                else {
                                    return ({
                                        ...el3
                                    })
                                }
                            })
                        }
                        // 없으면 새로 추가한다.
                        else {
                            _selectedType.push({
                                count: 1,
                                name: el.type_name,
                                type: el.type
                            })
                        }
                    }
                })
            }
            
            
            return ({
                ...state,
                checkedArr: _checkedArr,
                selectedType: _selectedType
            })
        },
        [IMPLY_RESULT_WRITE_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
            })
        },
        [IMPLY_RESULT_WRITE_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
            })
        },
        [IMPLY_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
                impWorkList: payload.map((item) => {
                    return ({
                        ...item,
                        phone: item.phone? item.phone.replaceAll('-','') : '',
                        fileArr: item.file_url? item.file_url.split(',') : [],
                    })
                })
            })
        },
        [IMPLY_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                impWorkList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
            })
        },
        [IMPLY_AUDIT_REQUEST_SUCCESS]: (state, {payload}) => {
            ALERT(payload + '에 성공 했습니다.')
            return ({
                ...state,
            })
        },
        [IMPLY_AUDIT_REQUEST_FAILURE]: (state, {payload}) => {
            ALERT('등본 요청에 실패 했습니다.')
            return ({
                ...state,
            })
        },
        [API_REQUEST_SUCCESS]: (state, {payload}) => {
            ALERT('등본 요청에 성공 했습니다.')
            return ({
                ...state,
            })
        },
        [API_REQUEST_FAILURE]: (state, {payload}) => {
            ALERT('등본 요청에 실패 했습니다.')
            return ({
                ...state,
            })
        },
        [IMPLY_AUDIT_CHECK_SUCCESS]: (state, {payload}) => {
            ALERT('등초본 확인에 성공 했습니다.\n'+payload)
            return ({
                ...state,
            })
        },
        [IMPLY_AUDIT_CHECK_FAILURE]: (state, {payload}) => {
            ALERT('등초본 확인에 실패 했습니다.')
            return ({
                ...state,
            })
        },
        [IMPLY_APPR_REQ_SUCCESS]: (state, {payload}) => {
            ALERT('결재요청에 성공 했습니다.');
            return ({
                ...state,
                implyListCheck: []
            })
        },
        [IMPLY_APPR_REQ_FAILURE]: (state, payload) => {
            ALERT(`결제요청에 실패 했습니다.\n[알림] ${payload.payload.response.data}`)
            return ({
                ...state,
            })
        },
        [IMPLY_API_SUCCESS]: (state, {payload}) => {
            ALERT('등기부등본일괄요청에 성공 했습니다.');
            return ({
                ...state,
            })
        },
        [IMPLY_API_FAILURE]: (state, {payload}) => {
            ALERT('등기부등본일괄요청에 실패 했습니다.')
            return ({
                ...state,
            })
        },
        [IMPLY_APPR_LIST_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
                implyApproveList:payload
            })
        },
        [IMPLY_APPR_LIST_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                implyApproveList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
            })
        },
        [IMPLY_APPR_DTL_LIST_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                implyApprove_implyList: payload.imply || [],
                implyApprove_approvalList: payload.approval|| [],
            })
        },
        [IMPLY_APPR_DTL_LIST_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                error: payload,
                implyApprove_implyList: [],
                implyApprove_approvalList: [],
            })
        },
        [IMPLY_APPROVE_SUCCESS]: (state) => {
            return ({
                ...state,
                implyApproveSuccess: 'Y'
            })
        },
        [IMPLY_APPROVE_FAILURE]: (state, payload) => {
            ALERT(`결재 실패했습니다.\n${payload.payload.response.data || ""}`);
            return ({
                ...state,
                error: payload,
                implyApproveSuccess: 'N'
            })
        },
        [IMPLY_UPLOAD_SUCCESS]: (state) => {
            ALERT('등록 성공했습니다.');
            return ({
                ...state,
                implyUploadSuccess: 'Y',
            })
        },
        [IMPLY_UPLOAD_FAILURE]: (state, payload) => {
            ALERT('등록 실패했습니다.');
            return ({
                ...state,
                error: payload,
                implyUploadSuccess: 'N',
            })
        },
        [IMPLY_REPORT_REG_SUCCESS]: (state) => {
            ALERT('보고서작성 요청에 성공했습니다.');
            return ({
                ...state,
                implyListCheck: []
            })
        },
        [IMPLY_REPORT_REG_FAILURE]: (state, payload) => {
            ALERT('보고서작성 요청에 실패했습니다.');
            return ({
                ...state,
                error: payload,
            })
        },
        [IMPLY_AUDIT_REQ_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                LH_implyAuditList: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            })
        },
        [IMPLY_AUDIT_REQ_FAILURE]: (state, payload) => {
            return ({
                ...state,
                error: payload,
                LH_implyAuditList: [],
                pageCount: null,
                max: 5,
                step: 1,
                currentPage: 1,
            })
        },
        [IMPLY_REQ_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                implyAuditRequestList: payload,
                impWorkList: payload,
            })
        },
        [IMPLY_REQ_SEARCH_FAILURE]: (state, payload) => {
            return ({
                ...state,
                error: payload,
                implyAuditRequestList: [],
                impWorkList: [],
            })
        },
        

        
        [SET_RESULT]: (state, {payload: {results}}) => {
            // area (지자체), 계약번호, 주소 가 병합 되어있어서 정제하는 과정이 필요
            let prevArea = '';
            let prevCtrNo = '';
            let prevAddr = '';
            let _results = results.map((item) => {
                // 지자체
                if(item.area && item.area !== '-') {
                    prevArea = item.area;
                    // return item;
                }

                // 계약번호
                if(item.contranct_no && item.contranct_no !== '-') {
                    prevCtrNo = item.contranct_no;
                    // return item;
                }

                // 주소
                if(item.addr && item.addr !== '-') {
                    prevAddr = item.addr;
                    // return item;
                }

                return ({
                    ...item,
                    area: prevArea,
                    contranct_no: prevCtrNo,
                    addr: prevAddr
                })
            })

            return ({
                ...state,
                results: _results
            })
        },

        // // 등초본요청 체크박스
        // [SET_AUDIT_CHECK]: (state, {payload: {checked, item}}) => {
        //     return ({
        //         ...state,
        //         auditArr: checked? state.auditArr.concat({
        //             '유형': item.type_name,
        //             '입주자명': item.imply_name,
        //             '주민등록번호': item.imply_no,
        //             '전화번호': `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}`,
        //             '임대인': item.house_owner,
        //             '지원주택소재지': item.addr,
        //             '계약종료일': item.contract_end_date,
        //             '전세금': item.rent_amt.replace(regAmt, ","),
        //         }) : state.auditArr.filter((el) => el['주민등록번호'] !== item.imply_no && el['입주자명'] !== item.imply_name),
        //         auditStr: checked? state.auditStr.concat(item.imply_id) : state.auditStr.filter((el) => el !== item.imply_id)
        //     })
        // },
        // [SET_AUDIT_CHECKALL]: (state, {payload: {checked}}) => {
        //     return ({
        //         ...state,
        //         auditArr: checked? state.impWorkList.map((el, idx) => ({
        //             '유형': el.type_name,
        //             '입주자명': el.imply_name,
        //             '주민등록번호': el.imply_no,
        //             '전화번호': `${el.phone.slice(0,3)}-${el.phone.slice(3,7)}-${el.phone.slice(7)}`,
        //             '임대인': el.house_owner,
        //             '지원주택소재지': el.addr,
        //             '계약종료일': el.contract_end_date,
        //             '전세금': el.rent_amt.replace(regAmt, ","),
        //         })) : [],
        //         auditStr: checked? state.impWorkList.map((el) => el.imply_id) : []
        //     })
        // },

        // 묵시작업 리스트 체크박스
        [SET_IMPLY_MAIN_CHECK]: (state, {payload: {checked, item}}) => {
            return ({
                ...state,
                implyListCheck: 
                    checked?
                        state.implyListCheck.concat(item)
                        : 
                        state.implyListCheck.filter((el) => el.request_id !== item.request_id)
            })
        },
        [SET_IMPLY_MAIN_CHECKALL]: (state, {payload: {checked}}) => {
            return ({
                ...state,
                implyListCheck: 
                    checked?
                        state.implyList
                        : 
                        []
            })
        },
        [IMPLY_EXCEL_CHANGE_VALUE]: (state, {payload: {key, value, idx}}) => {
            let headerTaget = state.implyExcelHeader.indexOf(key);

            return ({
                ...state,
                implyExcel: state.implyExcel.map((item, itemIdx) => {
                    if(itemIdx === idx) {
                        return item.map((subItem, subIdx) => {
                            if(subIdx === headerTaget) {
                                return value
                            }
                            else {
                                return subItem;
                            }
                        })
                    }
                    else {
                        return item;
                    }
                })
            })
        }
    },
    initialState,
);

export default Imply;