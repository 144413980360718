import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { qr } from '../common';
import ImplyWorkList from '../components/ImplyWorkList';
import dataURL from '../lib/dataURL';
import { ALERT } from '../lib/dataWord';
import { apprReq, changeValue, implyMain, initialize, reportReg, setImplyMainCheck, setImplyMainCheckAll, setImplyMainSearch, setPaging } from '../modules/Imply';

const ImplyWorkListContainer = () => {
    const {
        implyListSearch,
        implyList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        implyListCheck
    } = useSelector(({
        Imply
    }) => ({
        implyListSearch: Imply.implyListSearch,
        implyList: Imply.implyList,
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
        maxPageCount: Imply.maxPageCount,
        implyListCheck: Imply.implyListCheck,
    }))

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const {state: {func}} = location;
    const [update, setUpdate] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);

    const [implyWork, setImplyWork] = useState(false);
    const [requestApprove,setRequestApprove] = useState(false);
    const [writeReport,setWriteReport] = useState(false);
    const [approveWork,setApproveWork] = useState(false);

    //----------------------------------------------------------------------
    // 리스트

    // 리스트 클릭
    const onClickList = (e, item) => {
        const {target: {className, tagName}} = e;
        if(className === 'check' || tagName === 'LABEL' || tagName === 'INPUT') {
            // 넘어가고
        } else {
            navigate(dataURL.ImplyWorkPage, {
                state: {
                    func: location.state.func,
                    id: item.request_id,
                    implyWork,
                    app_flag: item.app_flag,
                    approveWork
                }
            })
        }
    }

    // 체크박스 - 개별
    const onClickCheck = (e, item) => {
        const {target: {checked}} = e;
        dispatch(setImplyMainCheck({
            checked,
            item
        }))
    }

    // 체크박스 - 전체
    const onClickCheckAll = (e) => {
        const {target: {checked}} = e;
        dispatch(setImplyMainCheckAll({
            checked
        }))
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 권한기능

    // 결재요청
    const onClickRequestApprove = () => {
        if(implyListCheck.length < 1) {
            ALERT("선택된 리스트가 없습니다.")
        }
        else {
            let valid = true;
            let el = null;
            let paylaod = null;

            for (let index = 0; index < implyListCheck.length; index++) {
                el = implyListCheck[index];
                if(el.app_flag === "Y" || el.app_flag === "P") {
                    valid = false;
                    break;
                }
            }

            paylaod = implyListCheck.map((item) => item.request_id)

            if(valid) {
                if(window.confirm(`총 ${implyListCheck.length}건을 결재요청 하시겠습니까?`)) {
                    dispatch(apprReq({
                        app_id: '',
                        req_id: paylaod.toString()
                    }))
                    setUpdate(true);
                }
            }
            else {
                ALERT('결재 미요청, 반려된 건만 결재요청할 수 있습니다.')
            }
        }
    }

    // 보고서작성
    const onClickWriteReport = () => {
        if(implyListSearch.appr_flag !== 'Y') {
            ALERT('결재 완료 된 건만 보고서 작성 요청을 할 수 있습니다.\n"결재유무" 검색조건을 다시 확인하세요');
        }
        else if(implyListSearch.report_flag !== "N") {
            ALERT('보고서 작성 미요청 건만 작성 요청을 할 수 있습니다.\n"보고서 작성 유무" 검색조건을 다시 확인하세요');
        }
        else {
            if(implyList.length < 1) {
                ALERT('요청할 항목이 없습니다.')
            } else {
                if(window.confirm('현재 검색 조건으로 보고서 작성을 요청하시겠습니까?')) {
                    dispatch(reportReg({
                        report_id: '',
                        req_id: '',
                        date_type: implyListSearch.date_type ,
                        from_date: implyListSearch.from_date ,
                        to_date: implyListSearch.to_date ,
                        appr_yn: implyListSearch.appr_flag ,
                        report_yn: implyListSearch.report_flag ,
                    }))
                    setUpdate(true);
                }
            }
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 검색
    
    // 등초본 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e
        dispatch(changeValue({
            field: 'implyListSearch',
            key: name,
            value
        }))
    }
    //----------------------------------------------------------------------



    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "implyListSearch", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------

    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.ImplyWorkListPage}?date_type=${implyListSearch.date_type}&from_date=${implyListSearch.from_date}&to_date=${implyListSearch.to_date}&appr_flag=${implyListSearch.appr_flag}&report_flag=${implyListSearch.report_flag}&page=${implyListSearch.page}`), 
            {
                state: location.state,
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, implyListSearch.date_type, implyListSearch.from_date, implyListSearch.to_date, implyListSearch.appr_flag, implyListSearch.report_flag, implyListSearch.page])


    // 변경된 url 로 조회.
    // 최초 한번 수행 후, location 이동에 따라 한번더 실행.
    useEffect(() => {
        if(dispatch && location) {
            if(!location.search) {
                setChangeUrl(true)
            }
            else if(location.search){
                const tempObj = qr(location.search);

                dispatch(setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                }))
                dispatch(setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                }))
                dispatch(implyMain({
                    ...tempObj
                }))
                dispatch(setImplyMainSearch({
                    value: tempObj
                }))
            }
        }
    }, [dispatch, location])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        if(update && dispatch) {
            dispatch(implyMain(implyListSearch))
            setUpdate(false)
        }
    }, [dispatch, update, implyListSearch])

    // 권한 분리
    useEffect(() => {
        if(func.some((el) => el.id === 'FUNC141')) {
            setImplyWork(true);
        }
        else {
            setImplyWork(false);
        }
        if(func.some((el) => el.id === 'FUNC142')) {
            setRequestApprove(true);
        }
        else {
            setRequestApprove(false);
        }
        if(func.some((el) => el.id === 'FUNC143')) {
            setWriteReport(true);
        }
        else {
            setWriteReport(false);
        }
        if(func.some((el) => el.id === 'FUNC144')) {
            setApproveWork(true);
        }
        else {
            setApproveWork(false);
        }
    }, [func])

    const propDatas = {
        implyListSearch,
        implyList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        implyListCheck,
        requestApprove,
        writeReport,

        onChangeSearch,
        onSubmit,
        onClickNum,
        onClickNext,
        onClickPrev,
        onClickList,
        onClickCheck,
        onClickCheckAll,
        onClickRequestApprove,
        onClickWriteReport,
    }
    return (
        <>
            <ImplyWorkList {...propDatas}/>
        </>
    );
}

export default ImplyWorkListContainer;