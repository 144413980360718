export const dataSTATUS = {
    APPLY:          'apply',
    ENTITLE:        'entitle',
    FEEDBACK:       'feedback',
    APPROVE:        'approve',
    PREMAINTAIN:    'premaintain',
    CONTRACT:       'contract',
    NEXTMAINTAIN:   'nextmaintain',
    CONFIRM:        'confirm',
    FIELDCHK:       'fieldchk',
    ALL:            '*',
}

const dataURL = {
    UserTypeSelectPage:         '/',
    LoginPage:                  '/login/:loginType',
    LoginPageMove:              '/login/',
    LoginStaffPage:             '/staff',
    RegisterPage:               '/reg',
    MainPage:                   '/main',
    ListPage:                   '/list',
    EntitleWritePage:           '/enwrite',
    EnWriteAdminPage:           '/enwradmin',
    EntitleWorkPage:            '/enwork',
    AdminPage:                  '/admin',
    Mypage:                     '/mypage/:id',
    WinnerRegisterWorkPage:     '/winregwork',
    DocSortPage:                '/docsort',
    WinnerRegisterListPage:     '/winreglist',
    ContractPage:               '/contract',
    ContractProcPage:           '/contractproc',
    EntitleViewerPage:          '/enview',
    ImplyRegisterWorkPage:      '/impregwork',
    ImplyRegisterListPage:      '/impreglist',
    ImplyWorkPage:              '/impwork',
    ImplyResultPage:            '/impresult',
    ImplyAuditRequestPage:      '/impauditreq',
    CustomerListPage:           '/customlist',
    WinnerExcelWorkPage:        '/winexcelwork',
    LHCustomerListPage:         '/lhcustomrlistwork',
    ImplyAuditRequestListPage:  '/implyauditrequestlistpage',
    AuditRequestListPage:       '/auditrequestlistpage',
    AuditRequestPage:           '/auditrequestpage',
    AuditListPage:              '/auditlistpage',
    MaintainRegisterPage:       '/maintainreg',
    UploadListPagePage:         '/uploadlist',
    ChargeFeePagePage:          '/chargefee',
    ImplyWorkListPage:          '/impworklist',
    ImplyApprovePage:           '/implyapprovepage',
    ImplyExcelWorkPage:         '/implyexcelwork',
    NextMaintainPage:           '/maintainlist',
    ImplyAuditWorkPage:         '/implyauditwork',
    ReportListPage:             '/rptlist',
    DobaeUserListPage:          '/dobaelist',
}

export default dataURL;