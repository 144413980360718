import React from 'react';
import styled from 'styled-components';
import { setComma } from '../common';

const ImplyAuditWorkBlock = styled.div`

max-width: 1920px;
min-width: 320px;
margin: 0 auto 40px auto;
padding: 50px;
box-sizing: border-box;
position: relative;


.winnerModal > div:last-child {
    width: 40%;
}
.winnerModal .body textarea {
    width: 100%;
    resize: none;
    height: 200px;
    font-size: 15px;
}

.winnerModal .header .green {
    color: green;
}

.winnerModal .header .red {
    color: red;
}

.utils {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.approveTableCover{
    border:1px solid #BDBDBD;
    height:90%;
    border-radius : 5px;
    background-color: #ffffff;
}

.approveTableCover table {
    // width: 100%;
    height: 100%;
    position:relative;
    table-layout:fixed;
    border-collapse : collapse;
    border-radius : 3px;
}
.approveTableCover table tbody tr{
    text-align: center;
    border-bottom: 0.5px solid #BDBDBD;
}

.approveTableCover table thead,
.approveTableCover table tbody tr:first-child{
    border-bottom: 0.5px solid #0B999C;
}
.approveTableCover table tbody tr:last-child{
    border-bottom: 0;
}

.approveTableCover table thead th,
.approveTableCover table tbody th{
    font-size: 15px;
    color : #0B999C;
    padding: 0 5px;
}
.approveTableCover table tbody td{
    font-size: 15px;
    color : #545454;
    border-left: 0.5px solid #BDBDBD;
    padding: 0 5px;
}

.tt thead {
    position: sticky;
    top: 60px;
    background-color: white;
    z-index: 10;
}
`;

const ImplyAuditWork = (props) => {
    const {
        implyApprove_implyList,
        implyApprove_approvalList,
        isModal,
        approveType,
        implyApprove_memo,
        isAuth,

        openMadal,
        closeModal,
        onApprove,
        onChange,
    } = props;
    return (
        <ImplyAuditWorkBlock>
            <div className='utils'>
                {/* 버튼 */}
                {isAuth?
                <div className='btn'>
                    <button onClick={() => openMadal('1')}>승인</button>
                    <button onClick={() => openMadal('2')}>반려</button>
                </div>
                : null}

                {/* 반려사유 */}
                <div className="approveTableCover">
                    <table className="approveTable">
                        <thead>
                            <tr>
                                <th>성명</th>
                                <th>결재일</th>
                                <th>반려사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {implyApprove_approvalList.map((item, idx) => {
                                if(item.app_status === "R" && item.app_opinion) {
                                    return (
                                        <tr key={idx}>
                                            <td>{item.app_name}</td>
                                            <td>{item.app_date}</td>
                                            <td>{item.app_opinion}</td>
                                        </tr>
                                    )
                                }
                                else {
                                    return null;
                                }
                            })}
                        </tbody>
                    </table>
                </div>
                {/*  결재라인 */}
                <div className="approveTableCover">
                    <table className="approveTable">
                        {implyApprove_approvalList.length > 0?
                        <tbody>
                            <tr>
                                <th colSpan={implyApprove_approvalList.length + 1}>결재 상태</th>
                            </tr>
                            <tr>
                                <th>성명</th>
                                {implyApprove_approvalList.map((item, idx) => {
                                    return (<td key={idx}>{item.app_name || '-'}</td>)
                                })}
                            </tr>
                            <tr>
                                <th>결재일</th>
                                {implyApprove_approvalList.map((item, idx) => {
                                    // 반려되면 app_status 가 R 이면 날짜 대신 '반려'라고 띄우기
                                    // 반려 사유 출력하기 어디에?
                                    if(item.app_status === "Y") {
                                        return (<td key={idx}>{item.app_date || '-'}</td>)
                                    }
                                    else if(item.app_status === "N" || !item.app_status) {
                                        return (<td key={idx}>{'-'}</td>)
                                    }
                                    else if(item.app_status === "R") {
                                        return (<td key={idx} style={{color: 'red'}}>{'반려'}</td>)
                                    }
                                    else {
                                        return null;
                                    }
                                })}
                            </tr>
                        </tbody>
                        : null}
                    </table>
                </div>
            </div>
            <table className="tt">
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>주소</th>
                        <th>지역</th>
                        <th>유형</th>
                        <th>입주자명</th>
                        <th>계약종료일</th>
                        <th>전세금</th>
                        <th>OK/NG</th>
                        <th>비고</th>
                    </tr>
                </thead>
                <tbody>
                    {implyApprove_implyList.length > 0? 
                    <>
                        {implyApprove_implyList.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{idx+1}</td>
                                    <td>{item.addr || '-'}</td>
                                    <td>{item.rec_area || '-'}</td>
                                    <td>{item.imply_type_name || '-'}</td>
                                    <td>{item.cust_name || '-'}</td>
                                    <td>{item.contract_end_date || '-'}</td>
                                    <td>{setComma(item.rent_amt) || '-'}</td>
                                    <td>{item.imply_ok || '-'}</td>
                                    <td>{item.remark || '-'}</td>
                                </tr>
                            )
                        })}
                    </> 
                    : null}
                </tbody>
            </table>
            {isModal?
            <div className='winnerModal'>
                <div onClick={closeModal}/>
                <div>
                    <div className='header'>
                        <div />
                        {approveType === '1'? <h2 className='green'>결재 - 승인</h2> : null}
                        {approveType === '2'? <h2 className='red'>결재 - 반려</h2> : null}
                    </div>
                    <div className='body'>
                        <textarea placeholder='사유를 입력하세요' name='implyApprove_memo' id='implyApprove_memo' value={implyApprove_memo} onChange={onChange}/>
                        <p>* '반려'시에는 사유를 반드시 입력해야 합니다.</p>
                    </div>
                    <div className="pop-group-btn">
                        <button onClick={closeModal}>닫기</button>
                        <button onClick={onApprove}>결재</button>
                    </div>
                </div>
            </div>
            : null}
        </ImplyAuditWorkBlock>
    );
}

export default ImplyAuditWork;