import React from 'react';
import NotFoundContainer from '../containers/NotFoundContainer';

const NotFoundPage = () => {
    return (
        <>
            <NotFoundContainer />
        </>
    );
}

export default NotFoundPage;