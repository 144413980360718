import React from 'react';
import styled from 'styled-components';

const BuildPriceViewBlock = styled.div`
`;

const BuildPriceView = ({ _doc016 }) => {
    if( !Array.isArray(_doc016) ) {
        return (
            <BuildPriceViewBlock>
                주택가격정보 - API 동작 오류 ({_doc016})
            </BuildPriceViewBlock>
        )
    }
    return (
        <BuildPriceViewBlock>
            <div className='tableWrap price'>
                <div className='viewerContainer borderwrap bd0'>
                    <div className='titleOpen'>
                        개별주택가격확인서(열람용)
                    </div>
                    
                    <table className="apiTable">
                        <colgroup>
                            <col width="90"></col>
                            {/* <col width="40"></col> */}
                            <col width="300"></col>
                            <col width="70"></col>
                            <col width="70"></col>
                            <col width="70"></col>
                            <col width="70"></col>
                            <col width="120"></col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th colSpan={6}>신 청 대 상 주 택</th>
                                <th className="last">확 인 내 용</th>
                            </tr>
                            <tr>
                                <th rowSpan={2}>가격기준연도<br/>(기준일)</th>
                                {/* <th rowSpan={2}>건물<br/>번호</th> */}
                                <th rowSpan={2}>주택소재지</th>
                                <th colSpan={2}>대지면적(㎡)</th>
                                <th colSpan={2}>건물연면적(㎡)</th>
                                <th rowSpan={2} className="last">개별주택가격<br/>(원)</th>
                            </tr>
                            <tr>
                                <th>전체</th>
                                <th>산정</th>
                                <th>전체</th>
                                <th>산정</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            _doc016 &&
                            (
                                _doc016.map((item, idx) => (	
                                    <tr key={idx}>
                                        <td>{item.PRICE_BASIC_DATE}</td>
                                        {/* <td>{item.JIBUN_BLDG_NO}</td> */}
                                        <td>{item.LOCATION}</td>
                                        <td>{item.LAND_AREA}</td>
                                        <td>{item.LAND_AREA_CALCULATION}</td>
                                        <td>{item.BUILDING_AREA}</td>
                                        <td>{item.BUILDING_AREA_CALCULATION}</td>
                                        <td>{item.OFFICIAL_LAND_PRICE}</td>
                                    </tr>
                                ))
                            )
                        }
                        </tbody>
                    </table>
                    {/* 주택가격정보 변경 전 */}
                        {/* {
                            _doc016 &&
                            (
                                _doc016.map((item, idx) => (
                                    <table key={idx} className="apiTable">
                                        <tbody>
                                            <tr>
                                                <th>LAND_AREA</th>
                                                <td>{item.LAND_AREA}</td>
                                            </tr>
                                            <tr>
                                                <th>BUILDING_AREA</th>
                                                <td>{item.BUILDING_AREA}</td>
                                            </tr>
                                            <tr>
                                                <th>LOCATION</th>
                                                <td>{item.LOCATION}</td>
                                            </tr>
                                            <tr>
                                                <th>BUILDING_AREA_CALCULATION</th>
                                                <td>{item.BUILDING_AREA_CALCULATION}</td>
                                            </tr>
                                            <tr>
                                                <th>LAND_AREA_CALCULATION</th>
                                                <td>{item.LAND_AREA_CALCULATION}</td>
                                            </tr>
                                            <tr>
                                                <th>JIBUN_BLDG_NO</th>
                                                <td>{item.JIBUN_BLDG_NO}</td>
                                            </tr>
                                            <tr>
                                                <th>PRICE_BASIC_DATE</th>
                                                <td>{item.PRICE_BASIC_DATE}</td>
                                            </tr>
                                            <tr>
                                                <th>OFFICIAL_DATEE</th>
                                                <td>{item.OFFICIAL_DATEE}</td>
                                            </tr>
                                            <tr>
                                                <th>BASIC_DATE</th>
                                                <td>{item.BASIC_DATE}</td>
                                            </tr>
                                            <tr>
                                                <th>OFFICIAL_LAND_PRICE</th>
                                                <td>{item.OFFICIAL_LAND_PRICE}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ))
                            )
                    } */}
                    {/* 주택가격정보 변경 전 */}
                </div>
            </div>
        </BuildPriceViewBlock>
    );
}

export default BuildPriceView;