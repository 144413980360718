import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import UploadListContainer from '../containers/UploadListContainer';

const UploadListPage = () => {
    return (
        <>
            <HeaderContainer />
            <UploadListContainer/>
        </>
    );
}

export default UploadListPage;