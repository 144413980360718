import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as entitleAPI from '../lib/api/entitle';

const INITIALIZE = 'contractProc/INITIALIZE';
const CHANGE_VALUE = 'contractProc/CHANGE_VALUE';
const [GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE] = createRequestActionTypes('contractProc/GET_LIST');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getList = createAction(GET_LIST);

const getListSaga = createRequestSaga(GET_LIST, entitleAPI.contract_search);

export function* contractProcSaga() {
    yield takeLatest(GET_LIST, getListSaga);
}

const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10);

const initialState = {
    status_ok: false,
    status_start: true,
    from_date: oneMonthAgo,
    to_date: today,
    
    list: [],

    contractProc: null,
    error: null
};

const contractProc = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        //  리스트 가져오기
        [GET_LIST_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                list: payload,
            }
        },
        [GET_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default contractProc;