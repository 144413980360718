import client from './client';

export const cust_search = (body) =>
    client.post('/api/cust/cust_search', body);

export const lhcust_search = (body) =>
    client.post('/api/cust/lhcust_search', body);

export const cust_imp_batch = (body) =>
    client.post('/api/cust/cust_imp_batch', body);

export const cust_imp_reg = (body) =>
    client.post('/api/cust/cust_imp_reg', body);

export const lhcust_imp_batch = (body) =>
    client.post('/api/cust/lhcust_imp_batch', body);

export const lhcust_imp_reg = (body) =>
    client.post('/api/cust/lhcust_imp_reg', body);

export const cust_view = ({cust_id}) =>
    client.get(`/api/cust/cust_view/${cust_id}`);

export const cust_update = (body) =>
    client.post('/api/cust/cust_update', body);

export const lhcust_reg = (body) =>
    client.post('/api/cust/lhcust_reg', body);

export const nextwork_reg = (body) =>
    client.post('/api/cust/nextwork_reg', body);

export const nextwork_batch = (body) =>
    client.post('/api/cust/nextwork_batch', body);

export const lh_nextwork_reg = (body) =>
    client.post('/api/cust/lh_nextwork_reg', body);

export const h_nextwork_batch = (body) =>
    client.post('/api/cust/lh_nextwork_batch', body);