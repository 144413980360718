import React from 'react';
import dataWORD from '../../lib/dataWord';
import sinsegiSign from '../../sinsegiSign.png';
import { ConvAddressMemo2ShowRoadJibun, ConvRealEstateAddr2ShowRoadJibun, ShowTelDash } from '../../lib/dataUTIL';

const checkDebtRatioMin = 1;

const RightsReviewPrint = ({work, winner, rentWork, contract, approval, use_address, write}) => {
    // let regAddress = null;
    // if( doc014
    //     && Array.isArray(doc014)
    //     && (doc014.length > 0)
    //     && doc014[0].RESP_DATA
    //     && Array.isArray(doc014[0].RESP_DATA)
    //     && (doc014[0].RESP_DATA.length > 0)
    //     && doc014[0].RESP_DATA[0].ADDRESS ) {
    //         regAddress = doc014[0].RESP_DATA[0].ADDRESS;
    // }
    //권리분석검토서
    if(!work || !winner || !rentWork || !contract || !approval || !write) {
        return (
            <>
            <div style={{pageBreakBefore:'always'}}>
                검토서 내용을 가져오지 못했습니다
            </div>
            </>
        )
    }
    else{
        let showRoadArea = work.road_addr + ' ' + work.road_addr_dtl;
        let showJibunArea = work.jibun + ' ' + work.road_addr_dtl;
        if( work.address_memo && work.address_memo !== '' ) {
            const retVal = ConvAddressMemo2ShowRoadJibun(work.address_memo);
            if( retVal && retVal.showRoadArea && retVal.showJibunArea ) {
                showRoadArea = retVal.showRoadArea;
                showJibunArea = retVal.showJibunArea;
            } else {
                showJibunArea = work.address_memo;
            }
        } else if( use_address && use_address.real_estate_address ) {
            const retVal = ConvRealEstateAddr2ShowRoadJibun({use_address, road_addr_dtl: work.road_addr_dtl});
            if( retVal ) {
                showRoadArea = retVal.showRoadArea;
                showJibunArea = retVal.showJibunArea;
            }
        }
        const aVals = approval.filter(item => (item.app_auth === 'APPROVAL') && (item.app_status === 'Y'));
        const aValName = aVals.length > 0 ? aVals[0].app_name : '';
        const aChks = approval.filter(item => (item.app_auth === 'APPCHECK') && (item.app_status === 'Y'));
        const aChkName = aChks.length > 0 ? aChks[0].app_name : '';
        const aChkDate = aChks.length > 0 ? aChks[0].check_date : '';
        return (
            <div className='tableWrap review' style={{pageBreakBefore:'always'}}>
                <div className='viewerContainer borderwrap'>
                    <h2 className='text-c'>전세임대주택 권리분석 검토서</h2>
                    <h3 style={{fontSize:'12px', fontWeight:'normal', marginBottom:'10px'}}>한국토지주택공사 서울지역본부 귀중</h3>   
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={4}>□ 지원대상자</td>
                            </tr>
                            {winner.map((item, idx) => (
                                <tr key={idx}>
                                    {idx === 0 && <th rowSpan={winner.length} style={{width:'16.6%'}}>지원유형</th>}
                                    <td style={{width:'16.6%'}}>{item.winner_type_name}</td>
                                    {idx === 0 && <th rowSpan={winner.length} style={{width:'16.6%'}}>계약유형</th>}
                                    <td style={{width:'16.6%'}}>{item.contract_type_name}</td>
                                    {idx === 0 && <th rowSpan={winner.length} style={{width:'16.6%'}}>전세유형</th>}
                                    {idx === 0 && <td rowSpan={winner.length} style={{width:'16.6%'}}>{work.rent_type_name}</td>}
                                </tr>
                            ))}
                            {winner.map((item, idx) => (
                                <tr key={idx}>
                                    {idx === 0 && <th rowSpan={winner.length}>성명</th>}
                                    <td>{item.winner_name}</td>
                                    {idx === 0 && <th rowSpan={winner.length}>{dataWORD.BIRTHDATE}</th>}
                                    <td>{item.winner_no}</td>
                                    {idx === 0 && <th rowSpan={winner.length}>가구원수</th>}
                                    {idx === 0 && <td rowSpan={winner.length}>{item.family_cnt}</td>}
                                </tr>
                            ))}
                            {winner.map((item, idx) => (
                                <tr key={idx}>
                                    {idx === 0 &&
                                        <th rowSpan={winner.length}>주소</th>
                                    }
                                    <td colSpan={3}>{item.winner_addr}</td>
                                    {idx === 0 && <th rowSpan={winner.length}>연락처</th>}
                                    <td>{ShowTelDash(item.winner_phone)}</td>
                                </tr>
                            ))}
                            <tr>
                                <th>지원차수</th>
                                <td>{work.req_cnt}</td>
                                <th>계약만료일(재계약시)</th>
                                <td>{work.end_date}</td>
                                <th>도배신청여부</th>
                                <td>{work.wallpaper_flag_name}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <h4>□ 권리분석 대상주택현황</h4>
                    <table className='current'>
                        <tbody>
                            <tr>
                                <th style={{width:'16.6%'}}>주택유형</th>
                                <td style={{width:'16.6%'}}>{work.house_type_name}</td>
                                <th style={{width:'16.6%'}}>주택소유자</th>
                                <td style={{width:'16.6%'}}>{work.house_owner}</td>
                                <th style={{width:'16.6%'}}>중복지원여부</th>
                                <td style={{width:'16.6%'}}>{work.req_duplicate}</td>
                            </tr>
                            <tr>
                                <th rowSpan={2}>소재지</th>
                                <td colSpan={3}>{showRoadArea}</td>
                                <th>대지면적</th>
                                <td className='text-r area'><span className='areaDetail'>{work.ground_area}</span><span>m²</span></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>{showJibunArea}</td>
                                <th>대지권비율</th>
                                <td className='text-r area' style={{paddingRight:'3px'}}><span className='areaDetail'>{work.share_area}</span></td>
                            </tr>
                            <tr>
                                <th>전세보증금</th>
                                <td className='text-r'>{work.rent_amt}<span>원</span></td>
                                <th>LH지원금</th>
                                <td className='text-r'>{work.support_amt}<span>원</span></td>
                                <th>전용면적</th>
                                <td className='text-r'>{work.house_area}<span>m²</span></td>
                            </tr>
                            {winner.map((item, idx) => (
                                <tr key={idx}>
                                    <th>입주자부담금</th>
                                    <td className='text-r'>{item.winner_charges}<span>원</span></td>
                                    <th>입주자추가부담금</th>
                                    <td className='text-r'>{item.add_charges}<span>원</span></td>
                                    {idx === 0 && <th rowSpan={winner.length}>쪽방등지원금</th>}
                                    {idx === 0 && <td className='text-r' rowSpan={winner.length}>{work.small_room_support}<span>원</span></td>}
                                </tr>
                            ))}
                            {winner.map((item, idx) => (
                                <tr key={idx}>
                                    <th title="변경기준 = [LH지원금]">월임대료</th>
                                    <td className='text-r'>{item.monthly_rent}<span>원</span></td>
                                    {idx === 0 && <th rowSpan={winner.length} title="신청시에 입력">보증부월세액</th>}
                                    {idx === 0 && <td className='text-r' rowSpan={winner.length}>{work.monthly_rent_amt}<span>원</span></td>}
                                    {idx === 0 && <th rowSpan={winner.length}>채권확보방법</th>}
                                    {idx === 0 && <td rowSpan={winner.length}>{work.securing_bonds}</td>}
                                </tr>
                            ))}
                            <tr>
                                <th>공인중개사</th>
                                <td colSpan={3}>{work.broker_company}</td>
                                <th>연락처</th>
                                <td>{ShowTelDash(work.broker_phone)}</td>
                            </tr>
                        </tbody>
                    </table>
        
                    <h4>□ 채권확보검토</h4>
                    <table className='secureReview'>
                        <tbody>
                            <tr>
                                <th style={{width:'16.6%'}} className="pl5" rowSpan={2}>1. 주택가격 ①</th>
                                <td style={{width:'16.6%'}} className='text-r' rowSpan={2}>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.house_amt}<span>원</span></>}</td>
                                <th className='text-c'>가격결정방법</th>
                                <td colSpan={3}>{work.decide_how_name}</td>
                            </tr>
                            <tr>
                                <th className="text-c" style={{width:'16.6%'}}>결정일자</th>
                                <td style={{width:'16.6%'}}>{work.decide_date}</td>
                                <th className="text-c" style={{width:'16.6%'}}>가격</th>
                                <td style={{width:'16.6%'}} className='text-r'>{work.decide_amt}<span>원</span></td>
                            </tr>
                            <tr>
                                <th className="pl5">2. 채권확보가능금액<span style={{fontSize:'10px'}}>(①x90%)</span></th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.possible_bond_amt}<span>원</span></>}</td>
                                <td colSpan={4} className="text-l" style={{fontWeight:'bold'}}>□ 기지원현황(만료전이주&재계약만 해당)</td>
                            </tr>
                            <tr>
                                <th className="pl5">3. 선순위채권금액</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.first_bond_amt}<span>원</span></>}</td>
                                <th className='text-c'>전세금 총액</th>
                                <th className='text-c'>LH지원금</th>
                                <th className='text-c'>입주자부담금</th>
                                <th className='text-c'>보증부월세</th>
                            </tr>
                            <tr>
                                <th className='pl10'>1) 근저당설정금액</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.mortgage_amt}<span>원</span></>}</td>
                                <td className='text-r'>{work.pre_deposit_total}<span>원</span></td>
                                <td className='text-r'>{work.pre_rent_loans}<span>원</span></td>
                                <td className='text-r'>{work.pre_charge_amt}<span>원</span></td>
                                <td className='text-r'>{work.pre_interest_free}<span>원</span></td>
                            </tr>
                            <tr>
                                <th className='pl10'>2) 전세권설정금액</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.rent_set_amt}<span>원</span></>}</td>
                                <td colSpan={4} className="text-l" style={{fontWeight : 'bold'}}>{"<특이사항>"}</td>
                            </tr>
                            <tr>
                                <th className='pl10'>3) (가)압류금액</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.seizure_amt}<span>원</span></>}</td>
                                <th className='text-c'>전체 방개수</th>
                                <td className='text-r'>{work.total_room_cnt}<span>개</span></td>
                                <th className='text-c'>임차 방개수</th>
                                <td className='text-r'>{work.rent_room_cnt}<span>개</span></td>
                            </tr>
                            <tr>
                                <th className='pl10'>4) 기존임차인금액</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.already_rent_amt}<span>원</span></>}</td>
                                <th className='text-c'>공실 방개수</th>
                                <td className='text-r'>{work.empty_room_cnt}<span>개</span></td>
                                <td className='text-r'style={{fontSize:'10px'}} colSpan={2}>
                                    선순위 총 보증금 : {work.rent_tot_amt}<span>원</span><br/>
                                    공실 합계 : {work.empty_tot_amt}<span>원</span>
                                </td>
                            </tr>
                            <tr>
                                <th className="pl5">4. 채권확보금액(2-3)</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.securing_bond_amt}<span>원</span></>}</td>
                                <td rowSpan={5} colSpan={4} style={{whiteSpace: 'pre-line', textAlign: 'left', verticalAlign: 'top'}}>
                                    {/* {regAddress ? regAddress + '\n' : ''} */}
                                    {work.remark}
                                </td>
                            </tr>
                            <tr>
                                <th className="pl5">5. 차액(4-6)</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.difference_amt}<span>원</span></>}</td>
                            </tr>
                            <tr>
                                <th className="pl5">6. 전세보증금</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.rent_amt}<span>원</span></>}</td>
                            </tr>
                            <tr>
                                <th className="pl5">7. 부채비율</th>
                                <td className='text-r'>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.debt_ratio}<span>%</span></>}</td>
                            </tr>
                            <tr>
                                <th className="pl5">8. 적합여부</th>
                                <td>{write.contract_type === 'SAME' && (work.debt_ratio && !isNaN(parseInt(work.debt_ratio)) && (parseInt(work.debt_ratio) < checkDebtRatioMin)) ? '' : <>{work.suitable_flag}</>}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='reviewSign'>
                        <div className='txt'>당사는 이 권리분석 검토서의 내용을 확인하였으며, 이를 승인합니다.</div>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: '1'}}></div>
                            <div style={{flex: '1'}}>
                                <div className='day'>{aChkDate}</div>
                                <div className='mt5'>법무법인 신세기</div>
                            </div>
                            <div className="text-l" style={{flex: '1'}}>
                                <img src={sinsegiSign} style={{objectFit: 'contain', width: '4em', height: '4em'}} alt='sinsegiSign' title={'sinsegiSign'} className='sinsegiSign'/>
                            </div>                            
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: '1'}}>작성자 : <span>{contract.entitle_user_name}</span></div>
                            <div style={{flex: '1'}}>검토자 : <span>{aValName}</span></div>
                            <div style={{flex: '1'}}>변호사 : <span>{aChkName}</span></div>
                        </div>
                    </div>
        
                    {/* <h4>● 선순위 임대차 현황</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>공실 구분</th>
                                <th>층수</th>
                                <th>호수</th>
                                <th>방개수</th>
                                <th>최우선 변제 보증금</th>
                                <th>비고</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rentWork.map((item, idx) => (
                                item.use_flag === 'Y' &&
                                <tr key={idx}>
                                    <td>{item.empty_type2_name}</td>
                                    <td>{item.rent_floor2}</td>
                                    <td>{item.room_no2}</td>
                                    <td>{item.room_cnt2}</td>
                                    <td>{item.priority_payment2}</td>
                                    <td>{item.remark2}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={2} className="sum">신청한 호수를 제외한 총 보증금</td>
                                <td colSpan={1}>{work.rent_tot_amt}</td>
                                <td colSpan={2} className="sum">공실 합계 금액</td>
                                <td colSpan={1}>{work.empty_tot_amt}</td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
            </div>
        );
    }
};

export default RightsReviewPrint;