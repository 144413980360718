import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';
import * as authAPI from '../lib/api/auth';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'userMgt/INITIALIZE';
const CHANGE_VALUE = 'userMgt/CHANGE_VALUE';
const CHANGE_FILE = 'userMgt/CHANGE_FILE';
const ROW_CHANGE_VALUE = 'userMgt/ROW_CHANGE_VALUE';
const ADD_IP_ROW = 'userMgt/ADD_IP_ROW';
const REMOVE_IP_ROW = 'userMgt/REMOVE_IP_ROW';
const CHANGE_IP_VALUE = 'userMgt/CHANGE_IP_VALUE';
const [GET_USER_VIEW, GET_USER_VIEW_SUCCESS, GET_USER_VIEW_FAILURE] = createRequestActionTypes('userMgt/GET_USER_VIEW');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('userMgt/GET_COMBO');
const [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE] = createRequestActionTypes('userMgt/GET_USERS');
const [SAVE_AUTH, SAVE_AUTH_SUCCESS, SAVE_AUTH_FAILURE] = createRequestActionTypes('userMgt/SAVE_AUTH');
const [SAVE_INFO, SAVE_INFO_SUCCESS, SAVE_INFO_FAILURE] = createRequestActionTypes('userMgt/SAVE_INFO');
const [SAVE_REGION, SAVE_REGION_SUCCESS, SAVE_REGION_FAILURE] = createRequestActionTypes('userMgt/SAVE_REGION');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const changeFile = createAction(CHANGE_FILE, ({key, fileReader, fileName, fileExtension}) => ({key, fileReader, fileName, fileExtension}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const addIpRow = createAction(ADD_IP_ROW);
export const removeIpRow = createAction(REMOVE_IP_ROW, ({idx}) => ({idx}));
export const changeIpValue = createAction(CHANGE_IP_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const getUserView = createAction(GET_USER_VIEW);
export const getCombo = createAction(GET_COMBO, (body) => (body));                    // 권한Combo 조회
export const getUsers = createAction(GET_USERS, (body) => (body));  // 사용자조회
export const saveAuth = createAction(SAVE_AUTH, (body) => (body));  // 가입승인
export const saveInfo = createAction(SAVE_INFO, (body) => (body));  // 정보수정
export const saveRegion = createAction(SAVE_REGION, (body) => (body));  // 권역저장

//saga생성
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_search);
const getUsersSaga = createRequestSaga(GET_USERS, authAPI.getUsers);
const getUserViewSaga = createRequestSaga(GET_USER_VIEW, authAPI.view);
const saveAuthSaga = createRequestSaga(SAVE_AUTH, authAPI.authUpdate);
const saveInfoSaga = createRequestSaga(SAVE_INFO, authAPI.register);
const saveRegionSaga = createRequestSaga(SAVE_REGION, authAPI.authArea);

export function* userMgtSaga() {
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(GET_USERS, getUsersSaga);
    yield takeLatest(GET_USER_VIEW, getUserViewSaga);
    yield takeLatest(SAVE_AUTH, saveAuthSaga);
    yield takeLatest(SAVE_INFO, saveInfoSaga);
    yield takeLatest(SAVE_REGION, saveRegionSaga);
}

const initialState = {
    src_user_auth: '',
    src_company: '',
    src_user_id: '',
    src_user_name: '',

    user_auth: '',
    login_date: '',
    user_id: '',
    user_pwd: '',
    user_pwd_check: '',
    user_phone: '',
    user_tel: '',
    user_name: '',
    user_position: '',
    addr_no: '',
    addr: '',
    addr_dtl: '',
    owner: '',
    card: '',
    reg_no: '',
    user_company: '',
    business: '',
    deduction: '',
    ipAddr: [{
        idx: 0,
        addr: '',
    }],
    use_flag: '',

    users: [],
    cboAuth: [],

    region: [],
    gungus: [],

    userMgt: null,
    error: null
};

const userMgt = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _users = state.users.map((item) =>
                item.user_id === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                users: _users,
            };
        },
        [GET_COMBO_SUCCESS]: (state, {payload}) => {
            payload = [{sub_code: '', code_name: '전체'}, ...payload]
            return {
                ...state,
                cboAuth: payload
            }
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [GET_USERS_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                users: payload
            }
        },
        [GET_USERS_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [ADD_IP_ROW]: (state) => {
            let _lastIdx = 0;
            state.ipAddr.forEach(item => {
                if(item.idx > _lastIdx) {
                    _lastIdx = item.idx;
                }
            });
            return {
                ...state,
                ipAddr: state.ipAddr.concat({
                            idx: _lastIdx + 1,
                            addr: '',
                        }),
            }
        },
        [REMOVE_IP_ROW]: (state, { payload: {idx} }) => {
            const _ipAddr = state.ipAddr.filter(x => x.idx !== idx);
            return {
                ...state,
                ipAddr: _ipAddr,
            };
        },
        [CHANGE_IP_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _ipAddr = state.ipAddr.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                ipAddr: _ipAddr,
            };
        },
        [SAVE_AUTH_SUCCESS]: (state, {payload}) => {
            ALERT("승인되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_AUTH_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [GET_USER_VIEW_SUCCESS]: (state, {payload}) => {
            const allow_ipArr = (payload[0].allow_ip ? payload[0].allow_ip.split(',') : []);
            let _allow_ip = [];
            for(let i = 0; i < allow_ipArr.length; i++) {
                if(!allow_ipArr[i]) {
                    continue;
                }
                _allow_ip.push({
                    idx: i,
                    addr: allow_ipArr[i],
                })
            }

            return ({
                ...state,
                user_auth: payload[0].user_auth,
                login_date: payload[0].login_date,
                user_id: payload[0].user_id,
                user_pwd: payload[0].user_pwd,
                user_pwd_check: payload[0].user_pwd,
                user_phone: payload[0].user_phone,
                user_name: payload[0].user_name,
                user_position: payload[0].user_position,
                addr: payload[0].addr,
                addr_dtl: payload[0].addr_dtl,
                card: {
                    file_name: '',
                    file_extension: '',
                    file_data: !payload[0].card ? "" : payload[0].card,
                },
                reg_no: payload[0].reg_no,
                user_company: payload[0].user_company,
                business: {
                    file_name: '',
                    file_extension: '',
                    file_data: !payload[0].business ? "" : payload[0].business,
                },
                deduction: {
                    file_name: '',
                    file_extension: '',
                    file_data: !payload[0].deduction ? "" : payload[0].deduction,
                },
                region: [],
                gungus: !payload[0].auth_area ? [] : payload[0].auth_area.split(','),
                ipAddr: _allow_ip,
                use_flag: payload[0].use_flag,
                error: null,
            })
        },
        [GET_USER_VIEW_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [CHANGE_FILE]: (state, { payload: { key, fileReader, fileName, fileExtension } }) => {
            const _file = {
                file_name: fileName,
                file_extension: fileExtension,
                file_data: fileReader.result,
            };
            return {
                ...state,
                [key]: _file,
            };
        },
        [SAVE_INFO_SUCCESS]: (state, {payload}) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_INFO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [SAVE_REGION_SUCCESS]: (state, {payload}) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_REGION_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default userMgt;