import dataURL, { dataSTATUS } from "./dataURL";

const dataMenu = {
    menu: [
        {
            id: "MENU00",
            name: "권리분석 상태보기",
            auth_yn: false,
            isHeader: true,
            count: "",
            router: dataURL.EntitleWritePage,
            type: "type3",
            desc: "현재 권리분석건 상태만 확인 할 수 있다.",
            func: [],
            sort: 1,
        },
        {
            id: "MENU01",
            name: "당첨자 등록",
            auth_yn: false,
            isHeader: true,
            router: dataURL.WinnerRegisterListPage,
            type: "type1",
            desc: "당첨자등록요청리스트 보기",
            func: [
                {
                    id: "FUNC011",
                    name: "등록요청",
                    desc: "",
                    auth_yn: "",
                },
                {
                    id: "FUNC012",
                    name: "최종반영",
                    desc: "등록요청건 최종반영",
                    auth_yn: "",
                },
            ],
            sort: 2,
        },
        {
            id: "MENU02",
            name: "묵시연장 대상자 등록",
            auth_yn: false,
            isHeader: true,
            router: dataURL.ImplyRegisterListPage,
            type: "type1",
            desc: "묵시대상자 요청리스트 보기",
            func: [
                {
                    id: "FUNC021",
                    name: "등록요청",
                    desc: "",
                    auth_yn: "",
                },
                {
                    id: "FUNC022",
                    name: "최종반영",
                    desc: "등록요청건 최종반영",
                    auth_yn: "",
                },
            ],
            sort: 3,
        },
        {
            id: "MENU03",
            name: "신청서 작성하기",
            auth_yn: false,
            isHeader: false,
            router: dataURL.EntitleWritePage,
            type: "type2",
            desc: "Client용",
            func: [],
            sort: 5,
        },
        {
            id: "MENU04",
            name: "신청서 전체작성",
            auth_yn: false,
            isHeader: false,
            router: dataURL.EnWriteAdminPage,
            type: "type2",
            desc: "신세기용 (한페이지)",
            func: [],
            sort: 6,
        },
        {
            id: "MENU05",
            name: "사진으로 신청 요청",
            auth_yn: false,
            isHeader: false,
            router: "",
            type: "type2",
            desc: "신세기용(한페이지)",
            func: [],
            sort: 7,
        },
        {
            id: "MENU06",
            name: "신청서현황 리스트",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.ListPage + "/" + dataSTATUS.APPLY,
            type: "type1",
            desc: "리스트 클릭시 기본 기능은 양식 조회",
            func: [
                {
                    id: "FUNC061",
                    name: "전체조회",
                    desc: "접속자 외 모든 사용자에 대한 정보를 조회",
                    auth_yn: "",
                },
                {
                    id: "FUNC062",
                    name: "전체수정",
                    desc: "선택한 신청서의 모든 내용을 수정할 수 있음",
                    auth_yn: "",
                },
                {
                    id: "FUNC063",
                    name: "첨부파일 추가",
                    desc: "서류미비 상태의 신청서에 첨부파일을 추가 할 수 있음",
                    auth_yn: "",
                },
            ],
            sort: 8,
        },
        {
            id: "MENU07",
            name: "권리분석 요청 리스트",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.ListPage + "/" + dataSTATUS.ENTITLE,
            type: "type1",
            desc: "리스트 확인만 가능",
            func: [
                {
                    id: "FUNC071",
                    name: "처리",
                    desc: "검토서 접수부터 결재요청까지",
                    auth_yn: "",
                },
                {
                    id: "FUNC072",
                    name: "조회",
                    desc: "검토서 조회만 가능",
                    auth_yn: "",
                },
            ],
            sort: 9,
        },

        {
            id: "MENU08",
            name: "요청 피드백 리스트",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.ListPage + "/" + dataSTATUS.FEEDBACK,
            type: "type1",
            desc: "내가 신청한 리스트는 Default로 볼 수 있음",
            func: [
                {
                    id: "FUNC081",
                    name: "전체조회",
                    desc: "모든 피드백요청 리스트를 볼 수 있다",
                    auth_yn: "",
                },
                {
                    id: "FUNC082",
                    name: "응답",
                    desc: "피드백 응답 처리",
                    auth_yn: "",
                },
            ],
            sort: 10,
        },
        {
            id: "MENU09",
            name: "결재 대기 리스트",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.ListPage + "/" + dataSTATUS.APPROVE,
            type: "type1",
            desc: "리스트 클릭시 기본기능은 검토서 화면으로 이동, 결재 가능",
            func: [
                {
                    id: "FUNC091",
                    name: "검토",
                    desc: "결재요청건에 대한 승인/반려",
                    auth_yn: "",
                },
                {
                    id: "FUNC092",
                    name: "결재확인",
                    desc: "결재완료건에 대한 결재확인",
                    auth_yn: "",
                },
            ],
            sort: 11,
        },
        // {
        //     id: "MENU10",
        //     name: "사전관리",
        //     auth_yn: false,
        //     isHeader: true,
        //     count: 0,
        //     router: dataURL.ListPage + '/' + dataSTATUS.PREMAINTAIN,
        //     type: "type1",
        //     desc: "결재완료되고 계약전까지의 사전관리",
        //     func: [
        //         {
        //             id: "FUNC101",
        //             name: "처리",
        //             desc: "알람 on/off 및 비고입력",
        //             auth_yn: ""
        //         }
        //     ],
        //     sort: 12,
        // },
        {
            id: "MENU11",
            name: "계약 대기 리스트",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.ListPage + "/" + dataSTATUS.CONTRACT,
            type: "type1",
            desc: "계약서 조회 및 출력만 가능",
            func: [
                {
                    id: "FUNC111",
                    name: "계약서 작성",
                    desc: "계약서 작성 저장 및 계약완료",
                    auth_yn: "",
                },
            ],
            sort: 13,
        },
        {
            id: "MENU22",
            name: "계약 처리 리스트",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.ContractProcPage,
            type: "type1",
            desc: "",
            func: [],
            sort: 14,
        },
        {
            id: "MENU23",
            name: "도배장판 사용자확인",
            auth_yn: false,
            isHeader: true,
            router: dataURL.DobaeUserListPage,
            type: "type2",
            desc: "",
            func: [],
            sort: 30,
        },
        {
            id: "MENU12",
            name: "사전/사후 관리",
            auth_yn: false,
            isHeader: true,
            count: 0,
            router: dataURL.NextMaintainPage,
            type: "type1",
            desc: "계약완료건 및 묵시연장대상자에 대한 사전/사후관리",
            func: [
                {
                    id: "FUNC121",
                    name: "처리",
                    desc: "알림on/off, 등본요청 기능, 비고입력",
                    auth_yn: "",
                },
            ],
            sort: 15,
        },
        {
            id: "MENU13",
            name: "LH최종확인",
            auth_yn: false,
            isHeader: false,
            count: 0,
            router: dataURL.ListPage + "/" + dataSTATUS.CONFIRM,
            type: "type1",
            desc: "지원신청에 대한 계약완료 최종 확인",
            func: [],
            sort: 16,
        },
        {
            id: "MENU14",
            name: "묵시작업페이지",
            auth_yn: false,
            isHeader: false,
            router: dataURL.ImplyWorkListPage,
            type: "type1",
            desc: "묵시 작업페이지, 기본기능은 조회만",
            func: [
                {
                    id: "FUNC141",
                    name: "묵시 작업",
                    desc: "비교, 업로드, 메모 등의 작업",
                    auth_yn: "",
                },
                {
                    id: "FUNC142",
                    name: "결재요청",
                    desc: "리스트에서 결재요청",
                    auth_yn: "",
                },
                {
                    id: "FUNC143",
                    name: "보고서 작성",
                    desc: "리스트에서 보고서 작성",
                    auth_yn: "",
                },
                {
                    id: "FUNC144",
                    name: "결재처리",
                    desc: "결재요청 건에 대한 처리작업",
                    auth_yn: "",
                },
            ],
            sort: 17,
        },
        {
            id: "MENU15",
            name: "현장조사 리스트",
            auth_yn: false,
            isHeader: false,
            router: dataURL.ListPage + "/" + dataSTATUS.FIELDCHK,
            type: "type1",
            desc: "현장조사 페이지",
            func: [],
            sort: 19,
        },
        {
            id: "MENU16",
            name: "사전/사후 대상자 등록",
            auth_yn: false,
            isHeader: false,
            router: dataURL.MaintainRegisterPage,
            type: "type1",
            desc: "사전/사후 관리 대상자 등록",
            func: [],
            sort: 3,
        },
        {
            id: "MENU17",
            name: "업로드 파일 리스트",
            auth_yn: false,
            isHeader: false,
            router: dataURL.UploadListPagePage,
            type: "type1",
            desc: "관리자가 시스템에 업로드된 파일 목록을 확인하는 페이지",
            func: [],
            sort: 20,
        },
        {
            id: "MENU18",
            name: "수수료 청구",
            auth_yn: false,
            isHeader: false,
            router: dataURL.ChargeFeePagePage,
            type: "type1",
            desc: "계약 완료된 건에 대해 수수료를 청구하는 페이지",
            func: [],
            sort: 21,
        },
        {
            id: "MENU19",
            name: "묵시작업결재페이지",
            auth_yn: false,
            isHeader: false,
            router: dataURL.AuditRequestListPage,
            type: "type1",
            desc: "묵시 작업 결재 페이지",
            func: [
                {
                    id: "FUNC191",
                    name: "결재",
                    desc: "결재 기능",
                    auth_yn: "",
                },
            ],
            sort: 18,
        },
        {
            id: "MENU20",
            name: "보고서 리스트",
            auth_yn: false,
            isHeader: false,
            router: dataURL.ReportListPage,
            type: "type1",
            desc: "보고서 리스트",
            func: [],
            sort: 22,
        },
        {
            id: "MENU94",
            name: "등초본 확인 리스트",
            auth_yn: false,
            isHeader: false,
            router: dataURL.AuditListPage,
            type: "type1",
            desc: "LH 로부터 받아서 DB에 업로드한 등초본 엑셀을 확인하는 리스트 (기본적으로 조회만 가능)",
            func: [],
            sort: 23,
        },
        {
            id: "MENU95",
            name: "등초본 요청 리스트(LH)",
            auth_yn: false,
            isHeader: false,
            router: dataURL.AuditRequestListPage,
            type: "type1",
            desc: "등초본 요청 확인, 처리 리스트",
            func: [],
            sort: 24,
        },
        {
            id: "MENU96",
            name: "고객리스트(LH)",
            auth_yn: false,
            isHeader: false,
            router: dataURL.LHCustomerListPage,
            type: "type1",
            desc: "LH 엑셀로 올라오는 14만건의 데이터관리",
            func: [
                {
                    id: "FUNC961",
                    name: "묵시 작업",
                    desc: "묵시대상자로 전환",
                    auth_yn: "",
                },
                {
                    id: "FUNC962",
                    name: "사후 작업",
                    desc: "사후대상자로 전환",
                    auth_yn: "",
                },
            ],
            sort: 25,
        },
        {
            id: "MENU21",
            name: "중개사ID 생성",
            auth_yn: false,
            isHeader: false,
            router: "CREATE_BROKER",
            type: "type2",
            desc: "권리분석가가 중개사ID를 생성함",
            func: [],
            sort: 26,
        },
        {
            id: "MENU97",
            name: "고객리스트",
            auth_yn: false,
            isHeader: false,
            router: dataURL.CustomerListPage,
            type: "type1",
            desc: "고객 리스트업로드, 존재하는 리스트에서 묵시 대상자 일괄 추가, 사후 대상자 일괄 추가",
            func: [
                {
                    id: "FUNC971",
                    name: "묵시 작업",
                    desc: "묵시대상자로 전환",
                    auth_yn: "",
                },
                {
                    id: "FUNC972",
                    name: "사후 작업",
                    desc: "사후대상자로 전환",
                    auth_yn: "",
                },
                {
                    id: "FUNC973",
                    name: "수정",
                    desc: "사용자 정보 수정작업",
                    auth_yn: "",
                },
            ],
            sort: 27,
        },
        {
            id: "MENU98",
            name: "신세기 관리자",
            auth_yn: false,
            isHeader: false,
            router: dataURL.AdminPage,
            type: "type2",
            desc: "코드관리, 권한관리, ",
            func: [],
            sort: 28,
        },
        {
            id: "MENU99",
            name: "시스템 관리자",
            auth_yn: false,
            isHeader: false,
            router: dataURL.AdminPage,
            type: "type2",
            desc: "모든 권한 부여",
            func: [],
            sort: 29,
        },
    ],
};

export default dataMenu;
