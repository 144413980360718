import { createAction, handleActions } from 'redux-actions';
// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const INITIALIZE = 'audit/INITIALIZE';
const CHANGE_VALUE = 'audit/CHANGE_VALUE';
// const [ACTION, ACTION_SUCCESS, ACTION_FAILURE] = createRequestActionTypes('audit/ACTION');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('audit/REACTION');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
// export const action = createAction(ACTION, data => data);

// const actionSaga = createRequestSaga(ACTION, actionAPI.action);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* auditSaga() {
    // yield takeLatest(ACTION, actionSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const initialState = {
    audit: null,
    error: null
};

const audit = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
    },
    initialState,
);

export default audit;