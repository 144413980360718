import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addZoom } from '../common';
import UploadList from '../components/UploadList';
import { ALERT } from '../lib/dataWord';
import { changeValue, getCombo, getList, initialize, setPaging } from '../modules/uploadList';

const UploadListContainer = () => {
    const [isLeaveMultiCbo, setIsLeaveMultiCbo] = useState(false);  //  Multipe ComboBox 공통 닫기 Event
    const [isShowCboStatus, setIsShowCboStatus] = useState(false);  //  파일종류 Multipe Combo Show/Hide
    const [selectedFileTypes, setSelectedFileTypes] = useState(''); //  파일종류 선택된 Text
    const [isShowViewer, setIsShowViewer] = useState(false);        //  업로드 file Modal Show/Hide
    const [viewerImg, setViewerImg] = useState('');                 //  업로드 file img src

    const dispatch = useDispatch();
    const {
        addr,
        user_name,
        file_type,

        cboDocType,

        fileList,

        pageCount,
        max,
        step,
        currentPage,
    } = useSelector(({ uploadList }) => ({
        addr: uploadList.addr,
        user_name: uploadList.user_name,
        file_type: uploadList.file_type,

        cboDocType: uploadList.cboDocType,

        fileList: uploadList.fileList,

        pageCount: uploadList.pageCount,
        max: uploadList.max,
        step: uploadList.step,
        currentPage: uploadList.currentPage,
    }));

    // 공통 Event -------------------------------------------
    useEffect(() => {
        dispatch(
            getCombo("DOC_TYPE")
        )

        return () => {
            dispatch(initialize());
        }
    }, [dispatch])
    
    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onSearch = useCallback((num) => {
        dispatch(getList({
            addr: addr,
            user: user_name,
            file_type: file_type,
            page: num ? num : currentPage
        }))
    }, [dispatch, addr, user_name, file_type, currentPage])

    const onSubmit = (e) => {
        e.preventDefault();
        if(!addr && !user_name) {
            ALERT("주소 또는 입주자명을 입력해주세요.");
            return;
        }
        onSearch(null);
    }
    //  ------------------------------------------- 공통 Event


    //  Multiple Check Combo 공통   --------------------------------------------
    const onShowMultiCbo = () => {
        setIsShowCboStatus(!isShowCboStatus);

        if(!isShowCboStatus) {
            setIsLeaveMultiCbo(false);
        }
    }
    const onHideMultiCbo = (e) => {
        if(e.type === "mouseleave") {
            setIsLeaveMultiCbo(true);
        }
        else if(isLeaveMultiCbo) {
            setIsShowCboStatus(false);
            setIsLeaveMultiCbo(false);
        }
    }
    const onEnterMultiCbo = () => {
        setIsLeaveMultiCbo(false);
    }
    //  --------------------------------------------    Multiple Check Combo 공통


    //  파일종류 Multiple Check Combo   --------------------------------------------
    const onStatusChange = (item, e) => {
        const selValues = cboDocType.map((data) => 
            data.sub_code === item.sub_code ? {...data, checked: e.target.checked} : data
        );
        dispatch(changeValue({key: 'cboDocType', value: selValues}))
    }
    const onStatusAll = (e) => {
        const selValues = cboDocType.map((data) => 
            {return {...data, checked: e.target.checked}}
        )
        dispatch(changeValue({key: 'cboDocType', value: selValues}))
    }
    useEffect(() => {
        let selTexts = "", selValues = "";

        const filterData = cboDocType.filter(x => x.checked === true);
        filterData.forEach(selected => {
            selTexts += selected.code_name + ",";
            selValues += selected.sub_code + ",";
        });

        if(filterData.length === 0) {
            selTexts = "";
            selValues = "NONE";
        }
        else if(cboDocType.length === filterData.length) {
            selTexts = "전체";
            selValues = "";
        }
        else {
            selTexts = selTexts.slice(0, -1);
        }

        setSelectedFileTypes(selTexts);
        dispatch(changeValue({key: 'file_type', value: selValues}))
    }, [dispatch, cboDocType])
    //  --------------------------------------------    파일종류 Multiple Check Combo


    // 페이징   --------------------------------------------
    const onClickNum = (num) => {
        if(num !== currentPage) {
            dispatch(setPaging({
                key: "currentPage",
                value: num,
            }))
            onSearch(num);
        }
    }
    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
        }
    }
    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
        }
    }
    //  --------------------------------------------    페이징

    const onShowViewer = (item, e) => {
        setIsShowViewer(true);
        setViewerImg(item.file_data);

        //  이미지에서 마우스 휠 동작시, 브라우저 스크롤이 움직이는 현상 제어
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        window.onscroll = function() {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }
    const onClose = () => {
        setIsShowViewer(false);

        //  모달 닫을 때 브라우저 스크롤 제어를 다시 원복.
        window.onscroll = function() {};
    }
    useEffect(() => {
        addZoom("")
    }, [viewerImg])

    const propDatas = {
        addr,
        user_name,

        cboDocType,

        //  진행상태 검색 관련
        isShowCboStatus,
        selectedFileTypes,
        onStatusChange,
        onStatusAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        fileList,
        
        pageCount,
        max,
        step,
        currentPage,

        //  File View modal
        isShowViewer,
        viewerImg,
        onShowViewer,
        onClose,

        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,
    }
    return (
        <>
            <UploadList {...propDatas}/>
        </>
    );
}

export default UploadListContainer;