import React from 'react';
import styled from 'styled-components';
import '../index.css'

const ListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    padding: 40px 50px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .cboMultiple ul li {
        background-color: #ffffff !important;
    }
    // .tt{
    //     table-layout: fixed
    // }
    // .tt thead tr th{
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     word-break: break-all;
    // }

    .dot {
        // width: 100%;
        // height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }

    .dot div {
        margin: auto;
    }
    .dot .red {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 100%;
    }
    .dot .green {
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 100%;
    }

    .apiDot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: auto;
    }
    .apiState1 { background-color: black; }     //  정제주소 신청 안 한 경우
    .apiState2 { background-color: red; }       //  정제주소 신청 했는데 6번 도로명 지명 내용 없음
    .apiState3 { background-color: orange; }    //  정제주소 선택을 안 한 경우
    .apiState4 { background-color: blue; }      //  주소는 정제되었으나 파일요청을 하지 않은 경우
    .apiState5 { background-color: yellow; }    //  첨부파일 요청중
    .apiState6 { background-color: green; }     //  첨부파일 회신 완료




    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .tt{ 
            table-layout: auto;
        }
        .search{
            display : none;
        }
        .tt thead tr th:nth-child(n+2):not(:nth-child(6)):not(:nth-child(8)):not(:nth-child(13)),
        .tt tbody tr td:nth-child(n+2):not(:nth-child(6)):not(:nth-child(8)):not(:nth-child(13)){
            display : none;
        }
        .tt thead tr th:nth-child(1),
        .tt tbody tr td:nth-child(1){
            min-width:28px;
        }
        .tt thead tr th:nth-child(6),
        .tt tbody tr td:nth-child(6){
            min-width:42px;
        }
        .tt thead tr th:nth-child(13),
        .tt tbody tr td:nth-child(13){
            min-width:55px;
        }
        .tt tbody tr td:nth-child(8){
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            padding:8px 0 0 0;
            white-space : normal
        }
    }

    .search form {
        overflow: inherit;  // Mutiple Check ComboBox를 보여주기 위해서 overflow:hidden을 제거함.
    }

    // 검색 Multiple checkbox Combo
    .cboStDiv {
        position: relative;
    }
    .txtSelectedValues {
        display: flex;
        width: 100%;
        height: 36px;
        padding: 5px 10px;
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .txtSelectedValues div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }
    .cboMultiple {
        position: absolute;
        top: 36px;
        border: 1px solid black;
        list-style: none;
        max-height: 150px;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 180;
    }
    .cboMultiple li {
        background-color: #ffffff !important;
    }
    .cboMultiple li:hover {
        background-color: #ccc;
    }
    .cboMultiple li input[type="checkbox"] + label:before {
        top: 8px;
        left: 6px;
    }
    .cboMultiple li label {
        background-color: inherit;
        height: auto;
        padding: 5px 5px 5px 25px;
        width: 100%;
        text-align: left;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }

    .status-icon span {
        padding: 5px 10px;
        color: white;
        border-radius: 20px;
        background-color: green;
        font-size:13px;
    }
    .status-icon span:first-child {
        margin-right : 3px;        
    }
    .status-icon .feedback-icon {
        background-color: navy;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px){
        padding: 10px 30px;

        .tt thead tr{
            font-size: 14px;
        }
        .tt tbody tr{
            font-size: 12px;
        }
        .status-icon span {
            font-size: 11px;   
        }
    }

    .change_req_item td {
        color: #307DF8;
    }    
`;

const List = (props) => {
    const {
        listArr,
        user,
        search,
        type,
        // statusAll,
        statusArr,
        pageCount,
        max,
        step,
        currentPage,
        feedbackArr,
        applyTypeArr,
        onInitSearchvalue,
        name,
        
        //  진행상태 검색 관련
        isShowCboStatus,
        selectedStatus,
        onStatusChange,
        onStatusAll,

        //  지역 검색 관련
        cboAreaData,
        isShowCboArea,
        selectedAreas,
        onAreaChange,
        onAreaAll,
        
        //  지원유형 검색 관련
        applyType,
        isShowCboType,
        selectedTypes,
        onApplyTypeChange,
        onApplyTypeAll,

        //  계약유형 검색 관련
        cboContData,
        isShowCboCont,
        selectedConts,
        onContChange,
        onContAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        onClickList,
        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,
    } = props;

    return (
        <ListBlock>
            {/* <h1>List</h1>
            <h3>권한 : {user? user.user_auth : ""}</h3>
            <h3>리스트 유형 : {type}</h3>
            <h3>활성 권한 : 
                {func.map((item,idx) => {
                    return <span key={idx}>{item.name}, </span>
                })}
            </h3> */}
          
            <h1>{name}</h1>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='searchipt'>
                            {
                                (type === "approve" || type === "contract") &&
                                <div className='st name-user'>
                                    <label htmlFor='date_type'>기간 구분</label>
                                    <select name='date_type' id='date_type' value={search.date_type} onChange={onChange}>
                                        <option value="REQ_DATE">신청일</option>
                                        <option value="APP_DATE">{type === 'contract' ? '전송일' : '결재완료일'}</option>
                                    </select>
                                </div>
                            }

                            <div className='st'>
                                <label htmlFor='from_date'>기간</label>
                                <input type={"date"} name='from_date' id='from_date' value={search.from_date} onChange={onChange}/>
                                <span>~</span>
                                <input type={"date"} name='to_date' id='to_date' value={search.to_date} onChange={onChange}/>
                            </div>

                            {type !== 'feedback'&&
                                <div className='st' tabIndex={0} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                    <label htmlFor='cboStatus'>진행상태</label>
                                    <div className='cboStDiv' style={{width:"192px", height:"36px", zIndex:"190"}}>
                                        <div className='txtSelectedValues' onClick={() => onShowMultiCbo('STATUS')}>
                                            <div>{selectedStatus}</div>
                                            ▼
                                        </div>
                                        {isShowCboStatus &&
                                            <ul className="cboMultiple" id="cboStatus">
                                                <li>
                                                    <input type="checkbox" name="statusALL" id="statusALL" onChange={onStatusAll}
                                                        checked={
                                                            type === 'apply' ? 
                                                            applyTypeArr.length === applyTypeArr.filter(x => x.checked === true).length :
                                                            statusArr.length === statusArr.filter(x => x.checked === true).length
                                                        }
                                                    />
                                                    <label htmlFor="statusALL" className='notlabel'>전체 선택</label>
                                                </li>
                                                {
                                                    type === 'apply' ?
                                                    applyTypeArr.map((item) => (
                                                        <li key={item.sub_code}>
                                                            <input type="checkbox" name={item.sub_code} id={item.sub_code} checked={item.checked} onChange={onStatusChange.bind(this, item)} />
                                                            <label htmlFor={item.sub_code} className='notlabel'>{item.code_name}</label>
                                                        </li>
                                                    ))
                                                    :
                                                    statusArr.map((item) => (
                                                        <li key={item.sub_code}>
                                                            <input type="checkbox" name={item.sub_code} id={item.sub_code} checked={item.checked} onChange={onStatusChange.bind(this, item)} />
                                                            <label htmlFor={item.sub_code}  className='notlabel'>{item.code_name}</label>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </div>
                                </div>
                            }
                            {/* <div className='st'>
                                <label htmlFor='status'>진행상태</label>
                                <select name='status' id='status' value={search.status} onChange={onChange}>
                                    <option value={statusAll}>전체</option>
                                    {
                                        type === 'apply'? 
                                        <>
                                            {applyTypeArr.map((item,idx) => {
                                                return (
                                                    <option value={item.sub_code} key={idx}>{item.code_name}</option>
                                                )
                                            })}
                                        </>
                                        : 
                                        <>
                                            {statusArr.map((item,idx) => {
                                                return (
                                                    <option value={item.sub_code} key={idx}>{item.code_name}</option>
                                                )
                                            })}
                                        </>
                                    }
                                </select>
                            </div> */}

                            <div className='st' tabIndex={1} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboTyep'>유형</label>
                                <div className='cboStDiv' style={{width:"192px"}}>
                                    <div className='txtSelectedValues' onClick={() => onShowMultiCbo('APPLY_TYPE')}>
                                        <div>{selectedTypes}</div>
                                        ▼
                                    </div>
                                    {isShowCboType &&
                                        <ul className="cboMultiple" id="cboType">
                                            <li>
                                                <input type="checkbox" name="applyTypeALL" id="applyTypeALL" checked={applyType.length === applyType.filter(x => x.checked === true).length} onChange={onApplyTypeAll} />
                                                <label htmlFor="applyTypeALL" className='notlabel'>전체 선택</label>
                                            </li>
                                            {applyType.map((item) => (
                                                <li key={item.sub_code}>
                                                    <input type="checkbox" name={item.sub_code} id={item.sub_code} checked={item.checked} onChange={onApplyTypeChange.bind(this, item)} />
                                                    <label htmlFor={item.sub_code} className='notlabel'>{item.code_name}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>

                            <div className='st name-user'>
                                <label htmlFor='user_type' id='label-user'>이름</label>
                                <select name='user_type' id='user_type' value={search.user_type} onChange={onChange}>
                                    <option value="WINNER">당첨자</option>
                                    {type !== 'feedback' && <option value="CREATOR">신청자</option>}
                                    {((type !== 'apply') && (type !== 'feedback')) && <option value="ENTITLE">권리분석담당자</option>}
                                    {((type !== 'contract') && (type !== 'apply') && (type !== 'entitle') && (type !== 'approve')) && <option value="LHUSER">LH담당자</option>}
                                </select>
                                <input name='user' id='user' value={search.user} onChange={onChange}/>
                            </div>

                            {type === 'feedback'? 
                                <>
                                    <div className='st'>
                                        <label htmlFor='feedback_status'>피드백 상태</label>
                                        <select name='feedback_status' id='feedback_status' value={search.feedback_status} onChange={onChange}>
                                            <option value="">전체</option>
                                            {feedbackArr.map((item,idx) => {
                                                return (
                                                    <option value={item.code} key={idx}>{item.name}</option>
                                                    )
                                                })}
                                        </select>
                                    </div>
                                    <div className='st' tabIndex={3} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                        <label htmlFor='cboCont'>계약유형</label>
                                        <div className='cboStDiv' style={{width:"192px"}}>
                                            <div className='txtSelectedValues' onClick={() => onShowMultiCbo('CONT_TYPE')}>
                                                <div>{selectedConts}</div>
                                                ▼
                                            </div>
                                            {isShowCboCont &&
                                                <ul className="cboMultiple" id="cboCont">
                                                    <li>
                                                        <input type="checkbox" name="contALL" id="contALL" checked={cboContData.length === cboContData.filter(x => x.checked === true).length} onChange={onContAll} />
                                                        <label htmlFor="contALL" className='notlabel'>전체 선택</label>
                                                    </li>
                                                    {cboContData.map((item) => (
                                                        <li key={item.sub_code}>
                                                            <input type="checkbox" name={item.sub_code} id={item.sub_code} checked={item.checked} onChange={onContChange.bind(this, item)} />
                                                            <label htmlFor={item.sub_code} className='notlabel'>{item.code_name}</label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                </>
                            : null}
                            
                            <div className='st' tabIndex={2} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboArea'>지역</label>
                                <div className='cboStDiv' style={{width:"192px"}}>
                                    <div className='txtSelectedValues' onClick={() => onShowMultiCbo('AREA')}>
                                        <div>{selectedAreas}</div>
                                        ▼
                                    </div>
                                    {isShowCboArea &&
                                        <ul className="cboMultiple" id="cboArea">
                                            <li>
                                                <input type="checkbox" name="areaALL" id="areaALL" checked={cboAreaData.length === cboAreaData.filter(x => x.checked === true).length} onChange={onAreaAll} />
                                                <label htmlFor="areaALL" className='notlabel'>전체 선택</label>
                                            </li>
                                            {cboAreaData.map((item) => (
                                                <li key={item.area}>
                                                    <input type="checkbox" name={item.area} id={item.area} checked={item.checked} onChange={onAreaChange.bind(this, item)} />
                                                    <label htmlFor={item.area} className='notlabel'>{item.area}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='btn'>
                            <button type='submit' className='green-sch-btn'>검색</button>
                            <button type='button' className='reset-btn' onClick={onInitSearchvalue}>검색조건 초기화</button>
                        </div>
                    </div>
                </form>
            </div>

            <div className='tableCover'>
                <table className='tt'>
                    <thead>
                        <tr>
                            <th>번호</th>
                            {type === 'feedback' && <th>입주유형</th>}
                            <th>문서ID</th>
                            {type === 'feedback' ? <th>계약유형</th> : <th>신청유형</th>}
                            <th>신청일</th>
                            {(type === 'approve' && <th>결재완료일</th>) || (type === 'contract' && <th>전송일</th>)}
                            <th>당첨지역</th>
                            <th>당첨자</th>
                            <th>전화번호</th>
                            <th>신청주소</th>
                            {type !== 'feedback' && <th>중개사</th>}
                            {((type !== 'apply') && (type !== 'feedback')) && <th>권리분석 담당자</th>}
                            {((type !== 'feedback') && (type !== 'approve')) && <th>계약일</th>}
                            {((type !== 'feedback') && (type !== 'approve')) && <th>계약장소</th>}
                            {type === 'feedback' && <th>요청일</th>}
                            {type === 'feedback' && <th>LH담당자</th>}
                            {type === 'feedback' && <th>전송일</th>}
                            <th>진행상태</th>
                            {type === 'entitle' ? <th style={{width : '60px'}}>메모확인</th> : null}
                            {/* {type === 'entitle' ? <th style={{width : '60px'}}>API상태</th> : null} */}
                            {type === 'feedback' && <th>피드백상태</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listArr.length === 0 &&
                            <tr>
                                <td colSpan={type === 'entitle' ? 15 : 13}>검색 결과가 없습니다.</td>
                            </tr>
                        }
                        {listArr.map((item,idx) => {
                            return (
                                <tr key={idx} className={item.change_req_date ? 'change_req_item' : ''} onClick={() => onClickList(item, user.user_auth)} title={item.change_req_reason}>
                                    <td>{(10 * (currentPage - 1)) + idx+1}</td>
                                    {type === 'feedback' && <td>{item.winner_type_name}</td>}
                                    <td>{item.doc_id}</td>
                                    {type === 'feedback' ? <td>{item.contract_type_name}</td> : <td>{item.winner_type_name}</td>}
                                    <td>{item.req_date}</td>
                                    {(type === 'approve' || type === 'contract') && <td>{item.app_complete_date}</td>}
                                    <td>
                                        {item.winner.map((winItem, idx) => {
                                            return (
                                                <div key={idx}>{winItem.rec_area}</div>
                                            )
                                        })}
                                    </td>
                                    <td>
                                        {item.winner.map((winItem, idx) => {
                                            return (
                                                <div key={idx}>{winItem.name}</div>
                                            )
                                        })}
                                    </td>
                                    <td>
                                        {item.winner.map((winItem, idx) => {
                                            return (
                                                <div key={idx}>{winItem.phone.slice(0,3)} - {winItem.phone.slice(3,7)} - {winItem.phone.slice(7)}</div>
                                            )
                                        })}
                                    </td>
                                    <td>{item.addr}</td>
                                    {type !== 'feedback' && <td className='broker'><span>{item.broker_company}</span> - <span>{item.broker_name}</span></td>}
                                    {((type !== 'apply') && (type !== 'feedback')) && <td>{item.entitle_user_name || "-"}</td>}
                                    {((type !== 'feedback') && (type !== 'approve')) && <td>{item.contract_date || "-"}</td>}
                                    {((type !== 'feedback') && (type !== 'approve')) && <td>{item.contract_location || "-"}</td>}
                                    {type === 'feedback' && <td>{item.feedback_req_date}</td>}
                                    {type === 'feedback' && <td>{item.lh_ans_user}</td>}
                                    {type === 'feedback' && <td>{item.lh_ans_date}</td>}
                                    <td>{
                                            (
                                                type==='apply' &&
                                                (
                                                    <div className='status-icon'>
                                                        <span>{item.user_status}</span>
                                                    </div>
                                                )
                                            )
                                            ||
                                            (
                                                (type==='entitle' || type==='approve') &&
                                                (
                                                    <div className='status-icon'>
                                                        <span>{item.status_name}</span>
                                                        {item.feedback_status && <span className='feedback-icon'>{item.feedback_status}</span>}
                                                    </div>
                                                )
                                            )
                                            ||
                                            (
                                                (type!=='apply' && type!=='entitle' && type!=='approve') &&
                                                (
                                                    <div className='status-icon'>
                                                        <span style={{backgroundColor: item.cont_status === null ? '' : item.cont_status}}>{item.status_name}</span>
                                                    </div>
                                                )
                                            )
                                        }
                                    </td>
                                    {type === 'entitle'? <td className='dot'><div className={item.call_check === "Y"? 'red' : 'green'}/></td> : null} 
                                    {/* {
                                        type === 'entitle'?
                                        <td title={
                                                (item.api_state === '1' && '정제주소 미신청') ||
                                                (item.api_state === '2' && '정제주소 신청 결과 내용 없음') ||
                                                (item.api_state === '3' && '정제주소 선택요망') ||
                                                (item.api_state === '4' && '파일 요청 전') ||
                                                (item.api_state === '5' && '파일 요청 중') ||
                                                (item.api_state === '6' && '파일 회신 완료')
                                            }
                                        ><div className={'apiDot apiState' + item.api_state}/></td> : null
                                    } */}
                                    {type === 'feedback' && <td>{item.feedback === 'Y' ? "응답" : "요청"}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pageNation">
                    <div className="page-prev-btn">
                        <span onClick={onClickPrev}>{"<"}</span>
                    </div>
                    <div className="num">
                        <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            else {
                                return null;
                            }
                        })}
                        </div>
                    </div>
                   <div className="page-next-btn">
                        <span onClick={onClickNext}>{">"}</span>
                   </div>
                </div>
        </ListBlock>
    );
}

export default List;