import React from 'react';
import AuditRequestLlistContainer from '../containers/AuditRequestLlistContainer';
import HeaderContainer from '../containers/HeaderContainer';

const AuditRequestListPage = () => {
    return (
        <>
            <HeaderContainer />
            <AuditRequestLlistContainer />
        </>
    );
}

export default AuditRequestListPage;