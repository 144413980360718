import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import MypageContainer from '../containers/MypageContainer';

const Mypage = () => {

    return (
        <>
            <HeaderContainer />
            <MypageContainer />
        </>
    );
}

export default Mypage;