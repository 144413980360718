import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { regAmt } from '../common';
import ContractProc from '../components/ContractProc';
import { changeValue, getList, initialize } from '../modules/contractProc';

const ContractProcContainer = () => {
    const [isPostback, setIsPostback] = useState(true); //  최초 조회

    const dispatch = useDispatch();
    const {
        status_ok,
        status_start,
        from_date,
        to_date,

        list,
    } = useSelector(({contractProc}) => ({
        status_ok: contractProc.status_ok,
        status_start: contractProc.status_start,
        from_date: contractProc.from_date,
        to_date: contractProc.to_date,
        
        list: contractProc.list.map((item) => {
            return ({
                ...item,
                lh_support_amt: String(item.lh_support_amt).replace(regAmt, ","),
                winner_charge: String(item.winner_charge).replace(regAmt, ","),
                monthly_amt: String(item.monthly_amt).replace(regAmt, ","),
                rent_amt: String(item.rent_amt).replace(regAmt, ","),
            })
        }),
    }))

    const onChange = (e) => {
        dispatch(changeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }
    const onChecked = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.checked,
            })
        );
    };

    const onSearch = useCallback(() => {
        const _status = (status_ok ? "CONTOK" : "") + (status_ok && status_start ? ',' : '') + (status_start ? "CONTSTRT" : "");
        dispatch(getList({
            from_date: from_date,
            to_date: to_date,
            status: _status,
        }))
    }, [dispatch, from_date, to_date, status_ok, status_start])
    
    const onSubmit = (e) => {
        e.preventDefault();
        
        onSearch();
    }

    useEffect(() => {
        if(isPostback) {
            setIsPostback(false);
            onSearch();
        }
    }, [isPostback, onSearch])

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        //  검색조건
        status_ok,
        status_start,
        from_date,
        to_date,

        //  List
        list,

        onChange,
        onChecked,
        onSubmit,
    }
    return (
        <>
            <ContractProc {...propDatas} />
        </>
    );
}

export default ContractProcContainer;