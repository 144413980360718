import React from 'react';
import styled from 'styled-components';
import dataWORD from '../lib/dataWord';

const UserTypeSelectBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    position: relative;

    .bg-gradient{
        background: linear-gradient(45deg, #1EBF86 , #0B999C);
        min-height: 100vh;
    }
    .bg-gradient .userTypeButton{
        min-height: 91vh;
    }
    .userHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        box-sizing: border-box;
        background-color: #fff;
    }
    .userHeader>div:nth-child(2){
        font-size: 20px;
        color: #6A6A6A;
    }
    .userTypeButton {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 92vh;
        position:relative;
    }
    .userTypeButton button{
        width: 350px;
        height: 380px;
        margin: 0 10px;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 5px;
        border: none;
        background-color: #ffffff;
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);
        cursor: pointer;
    }
    .userTypeButton>button>div>span{
        font-size:22px;
        color: #545454;
        display: block;
        margin: 28px 0
    }
    .userTypeButton>button>div::after{
        content: "로그인";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; 
        height: 45px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);
    }
    .manualdown{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; 
        height: 45px;
        color: #fff;
        font-size: 20px;
        border-radius: 5px;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);
        margin-top:10px;
        text-decoration : none;
    }


    // .userTypeButton:after{
    //     content : '이용문의(안내): 1600-4690 (집클릭 콜센터)';
    //     position:absolute;
    //     top: 650px;
    // }
    .userTypeButton .comment-area{
        min-width: 300px;
        position:absolute; 
        top:50%; 
        left:50%; 
        z-index:100; 
        transform: translateY(-50%) translateX(-50%); 
        -webkit-transform: translateY(850%) translateX(-50%); 
        backface-visibility: hidden; 
        -webkit-backface-visibility: hidden;
        color : #fff;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px){
        .bg-gradient{
            display: block;
            width: 100%;
            min-height: 92vh;
        }
        .userHeader>div:nth-child(2){
            font-size: 16px;
        }
        .bg-gradient .userTypeButton {
            display: block;
            min-height: 0vh;
            padding: 10px 14px;
            white-space: wrap;
        }
        .userTypeButton button{
            width: 100%;
            height: 8%;
            margin: 5px 0px;
            padding: 68px 30px;
        }
        .userTypeButton>button>div::after{
            height: 100%;
            padding: 2% 0;
        }
        .userTypeButton>button>div>span{
            font-size:15px;
            margin: 7px 0 12px 0;
        }
        .userTypeButton .comment-area{
            top:100%;
            min-width:300px;
            transform: translateY(-50%) translateX(-50%); 
            -webkit-transform: translateY(-50%) translateX(-50%); 
            font-size : 12px;
            text-align:center;
        }
        
    }

    #version-view {
        font-size: 0.5rem;
    }
`;

const UserTypeSelect = (props) => {
    const {
        onMovePage,
        version,
    } = props;
    return (
        <UserTypeSelectBlock>
            <div className='bg-gradient'>
                <div className='userHeader'>
                    <div>lhlogo</div>
                    <div>LH전세임대 권리분석 플랫폼</div>
                    <div>변호사협회로고</div>
                </div>
                <div id='version-view'>v{version}</div>
                <div className='userTypeButton'>
                    <button >
                        <div onClick={() => onMovePage('USER')}><span>입주자</span></div>
                        <a href='https://lhws.s3.ap-northeast-2.amazonaws.com/220907_LHWS_%EC%9E%85%EC%A3%BC%EC%9E%90%EB%A7%A4%EB%89%B4%EC%96%BC.pdf' className='manualdown' download="집클릭_입주자매뉴얼.pdf" target="_blank" rel='noreferrer'>입주자 매뉴얼 다운받기</a>
                    </button>
                    
                    <button >
                        <div onClick={() => onMovePage('BROKER')}><span>중개사</span></div>
                        <a href='https://lhws.s3.ap-northeast-2.amazonaws.com/220907_LHWS_%EC%A4%91%EA%B0%9C%EC%82%AC%EB%A7%A4%EB%89%B4%EC%96%BC.pdf' className='manualdown' download="집클릭_중개사매뉴얼.pdf" target="_blank" rel='noreferrer'>중개사 매뉴얼 다운받기</a>
                    </button>
                    {/* <button onClick={() => onMovePage('STAFF')}>
                        <div><span>관계자</span></div>
                    </button> */}
                    <div className='comment-area'>{dataWORD.INFOCOMMENT}</div>
                </div>
            </div>
        </UserTypeSelectBlock>
    );
}

export default UserTypeSelect;