import React from 'react';
import styled from "styled-components";

const TermsPopup = styled.div`
    .popupModal > div:nth-child(2){
        width: 800px;
        max-height: 80vh;
        box-sizing: border-box;
        padding: 30px 50px;
        overflow-y: scroll;
    }

    .popupModal > div:nth-child(2) h4{
        color: #545454;
        font-size: 26px;
        text-align: center;
        margin: 30px 0 50px 0;
    }

    .board-form table td{
        text-align: center;
    }
    .Buttons{
        text-align: center;
    }
    .cancel-btn{
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }
    .containerDiv{
        height: 100%;
        overflow-y: auto;
        color: #545454;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .popupModal > div:nth-child(2){
            width: 100%;
            max-height: 100%;
            border-radius: 0;
            padding: 20px 10px;
        }
    }
`;

const TermsModal = (props) => {
    const {type, setIsShowTermsModal} = props;
    return (
        <TermsPopup>
        <div className='popupModal'>
            <div />
            <div>
                <div className='containerDiv'>
                {type === 'service' && <>
                <h4>서비스 이용약관(필수)</h4>
                <div>
                    <b>[ 제 1 장 총칙 ]</b><br/>
                    제 1 조 (목적) 이 약관은 집클릭(이하 "서비스 제공자"라 합니다)이 운영하는 웹 사이트의 전체 서비스(이하 "서비스"라 합니다) 이용에 관하여 서비스 제공자와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.<br/>
                    <br />
                    제 2 조 (약관의 공지 및 준용)<br/>
                    1. 서비스 제공자는 이용자가 본 약관 내용에 동의하는 것을 조건으로 이용자에게 서비스를 제공하게 되며 이때 이 약관이 우선적으로 적용됩니다.<br/>
                    2. 서비스 제공자는 이 약관을 사전 고지 없이 변경할 수 있으며, 변경된 약관은 서비스 화면에 게재하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.<br/>
                    3. 이 서비스의 회원이 개정된 약관에 동의하지 않는 경우 회원은 회원가입을 취소할 수 있으며, 별도의 이의 없이 이 서비스를 계속 이용하는 경우에는 변경된 약관에 동의한 것으로 간주됩니다.<br/>
                    <br />
                    제 3 조 (약관 외 준칙)이 약관에 명시되지 않은 사항은 전기통신기본법,전기통신사업법,정보통신망이용촉진등에 관한 법률 및 기타 관련법령의 규정에 의합니다.<br/>
                    <br />
                    제 4 조 (용어의 정의)이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                    1. 이용자 : 이 약관에 따라 서비스 제공자가 제공하는 서비스를 받는 자<br/>
                    2. 가입 : 서비스 제공자가 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용 계약을 하는 행위<br/>
                    3. 회원 : 서비스 제공자에 개인정보를 제공하여 회원 등록을 한 자로서 본 약관에 따라 본원의 서비스를 이용할 수 있는 자<br/>
                    4. 아이디(ID) : 회원 식별과 회원의 서비스를 이용하기 위하여 회원이 신청하고 본원이 승인하는 문자 및 숫자의 조합<br/>
                    5. 비밀번호 : 회원과 회원ID가 일치하는지를 확인하여 회원의 비밀보호를 지키기 위하여 회원 자신이 선정한 문자와 숫자, 특수문자의 조합<br/>
                    6. 웹사이트 : 회원이 본원의 서비스 기능을 이용하여 작성하는 웹페이지 및 그 구성요소<br/>
                    7. 탈퇴 : 회원이 이용계약을 종료 시키는 행위<br/>
                    <br />
                    <b>[ 제 2 장 서비스 이용계약]</b><br/>
                    제 5 조 (이용계약의 성립)<br/>
                    1. "서비스 이용약관 동의" 라는 항목을 체크하시면 서비스 이용약관에 동의하는 것으로 간주됩니다.<br/>
                    2. 이용계약의 성립은 신청자가 온라인으로 본원의 홈페이지에 회원가입 신청양식의 요구사항을 기입하여 가입을 완료하여 승인받은 시점으로부터 입니다. 다만, 회원가입 없는 이용 신청자는 로그인에서 승인받은 시점으로부터 입니다<br/>
                    <br />
                    제 6 조 (이용신청의 승낙) 서비스 제공자는 이용자가 이용자로 가입 시 필수항목을 정확히 기재한 것을 전제로 이용신청을 승낙합니다. 추가(선택)항목은 이용자 가입 신청자의 자유선택에 따라 임의로 기재여부를 결정할 수 있습니다.<br/>
                    제 7 조 (계약사항의 변경)회원은 회원가입 시 기재한 사항이 변경되었을 경우에는 변경신청 및 수정을 해야 하고 이러한 변경사항에 대한 수정을 하지 않은 것이 서비스 제공자에 의해 판명된 경우 서비스 제공자는 회원의 회원가입을 취소할 수 있습니다.<br/>
                    제 8 조(수집하는 개인정보의 보유기간)서비스 제공자는 회원이 제공한 개인정보를 회원으로서의 자격이 부여된 시점부터 최신의 상태로 관리 보유하며 회원이 서비스 중지를 요청할 경우에는 수집된 개인정보를 이용하지 않고, 폐기하는 것을 원칙으로 합니다. 서비스 제공자가 합병, 이전, 주주의 변경 등의 경우에도 회원정보를 저장한 서버가 동일성을 유지하며 동일한 서비스를 계속 제공하는 한 이 서비스를 제공하는 서비스 제공자는 이를 계속 사용할 수 있습니다.<br/>
                    <br />
                    <b>[ 제 3 장 계약자의 의무 ]</b><br/>
                    제 9 조 (집클릭의 의무)<br/>
                    1. 서비스 제공자는 서비스 제공과 관련하여 취득한 회원 개인의 신상 정보를 당해 회원의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없습니다. 단 전기통신기본법, 기타 법률의 규정에 의한 국가기관 혹은 이에 준하는 기관의 요구가 있는 경우, 범죄 수사상 목적이 있거나 정보통신윤리위원회의 공익적 목적에 의한 요청이 있는 경우와 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우는 그러하지 아니합니다.<br/>
                    2. 서비스 제공자는 회원 데이터베이스의 관리 및 보수 그리고 보안에 책임을 집니다. 단 천재지변, 전쟁, 서비스 제공자의 충분한 보안노력에도 불구하고 고도의 기술적인 해킹 등에 의한 문제 발생 시에는 그러하지 아니합니다.<br/>
                    <br />
                    제 10 조 (회원의 의무와 권리)<br/>
                    1. 회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.<br/>
                    ㆍ다른 회원의 ID를 부정하게 사용하는 행위<br/>
                    ㆍ신청 또는 변경 시 허위 내용의 등록<br/>
                    ㆍ서비스에서 얻은 정보를 서비스 제공자의 사전 승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 또는 방송 등에 사용하거나 제3자에게 제공하는 행위<br/>
                    ㆍ미풍양속에 위반되는 내용이나 타인의 프라이버시를 침해할 수 있는 내용 등을 전송, 게시, 게재, 전자 메일 또는 기타의 방법으로 전송하는 행위<br/>
                    ㆍ타인의 저작권, 특허, 상표, 기타 지적 재산권을 침해하는 내용을 전송, 게시, 게재, 전자 메일 또는 기타의 방법으로 전송하는 행위<br/>
                    ㆍ다른 회원 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 행위<br/>
                    ㆍ범죄와 결부된다고 객관적으로 판단되는 행위<br/>
                    ㆍ기타 관계법령에 위배되는 행위<br/>
                    2. 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.<br/>
                    3. 회원은 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 유, 무상으로 양도, 증여, 대여할 수 없으며 이를 질권 기타 담보로 제공할 수 없습니다.<br/>
                    4.  회원은 서비스 제공자의 허락 없이 영리를 목적으로 하는 스팸성 또는 광고성의 내용을 메일로 발송하거나 자신 또는 다른 회원의 웹사이트에 게시할 수 없습니다. 이로 인하여 서비스 제공자 및 다른 회원이 입은 손해는 회원이 100% 부담해야 합니다.<br/>
                    <br />
                    <b>[ 제 4 장 서비스 이용 ]</b><br/>
                    제 11 조 (회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무)<br/>
                    1. 로그인에 사용되는 아이디와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다. <br/>
                    2. 자신의 아이디와 비밀번호가 부정하게 사용된 경우 회원은 반드시 서비스 제공자에게 그 사실을 통보해야 합니다.<br/>
                    제 12조 (정보의 제공) 서비스 제공자는 회원의 서비스 이용 중 필요하다고 인정되는 다양한 정보를 이메일이나 문자메시지 등의 방법으로 회원에게 제공할 수 있으며 만일 정상적인 방법으로 정보가 송달되지 않는 경우 서비스 제공자는 회원의 자격을 상실 시킬 수 있습니다.<br/>
                    제 13 조 (서비스 제공의 중지)<br/>
                    1. 서비스제공자는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.<br/>
                    ㆍ서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br/>
                    ㆍ전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br/>
                    ㆍ기타 불가항력적 사유가 있는 경우<br/>
                    2. 서비스제공자는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 있습니다.<br/>
                    <br />
                    <b>[ 제 5 장 계약해지 및 이용제한 ]</b><br/>
                    제 14 조 (계약해지 및 이용제한)<br/>
                    1. 서비스 제공자는 회원이 제3장 제10조 회원의 의무에 위배되는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.<br/>
                    2. 위의 1항의 경우에 서비스 제공자는 회원에 대하여 문자메시지를 통하여 해지통고를 함으로써 해지의 효력이 발생합니다.<br/>
                    3. 서비스 제공자의 사업상의 사정으로 인하여 부득이 서비스를 중단하게 될 경우 서비스 제공자는 서비스 메인 화면에 그 사항을 1주일 전의 기간부터 공지함으로써 전체 회원에 대한 해지통고에 갈음할 수 있습니다.<br/>
                    <br />
                    <b>[ 제 6 장 손해배상 등 ]</b><br/>
                    제 15 조 (면책조항)<br/>
                    1. 서비스 제공자는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br/>
                    2. 서비스 제공자는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다<br/>
                    3. 서비스 제공자는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.<br/>
                    4. 서비스 제공자는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.<br/>
                    5. 서비스 제공자는 회원과 회원 간에 발생하는 모든 법적 및 도의적 분쟁에 대하여 일체의 책임을 지지 않습니다.<br/>
                    <br />
                    [ 부칙 ]<br/>
                    (시행일) 이 약관은 202년 03월 02일 부터 시행합니다.
                </div>
                </>
                }
                { type === 'privacy' &&
                    <>
                        <h4>개인정보 수집 및 이용 동의(필수)</h4>
                        집클릭은 회원님의 개인정보를 중요시하며, 개인정보보호방침은 현행 [개인정보보호법]에 근거를 두고 있습니다. 개인정보처리방침을 통하여 회원님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.<br />
                        1. 목적<br />
                        - 회원에게 서비스 제공을 위한 목적으로 개인정보를 수집하며, 권리분석 등 회원제 서비스를 위한 부가목적이 있습니다.<br />
                        <br />
                        (개인) 권리분석 신청 시 신청 결과 등에 대하여 문자로 관련 정보 제공 등 고객편의 제공<br />
                        (중개업소) 전세임대 가능 물건 등록으로 중개 편의성 제공<br />
                        (법무법인) 권리분석 및 계약정보를 온라인으로 연계하여 정확한 데이터 관리 및 업무 편의성 제고<br />
                        <br />
                        2. 항목<br />
                        ① 수집항목(필수)<br />
                        - 개인/법무법인 : 회원구분, 성명, 아이디, 비밀번호, 전화번호<br />
                        - 중개업소 : 회원구분, 성명, 아이디, 비밀번호, 전화번호, 중개업소명, 중개업소 사업자등록번호, 중개업소 주소<br />
                        ② 개인정보 수집방법<br />
                        - 홈페이지 회원가입을 통한 개인정보수집<br />
                        <br />
                        3. 보유기간 : 회원탈퇴 시까지<br />
                        <br />
                        4. 정보제공의 거부에 따른 불이익 : 개인정보를 제공하는 본 동의에 거부할 수 있습니다. 개인정보 제공 거부 시 서비스의 사용에 제한을 받게 됩니다.
                    </>
                }
                { type === 'privacy_policy' &&
                    <>
                        <h4>개인정보처리방침</h4>
                        집클릭이 취급하는 모든 개인정보는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 수집·보유·처리하고 있습니다.<br/>
                        집클릭은 개인정보보호법 제30조에 따라 이용자의 개인정보 보호 및 권익을 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 두고 있습니다.<br/>
                        <br/>
                        <b>제1조(개인정보의 처리목적 및 개인정보 항목)</b><br/>
                        ① 집클릭은 다음의 목적을 위해 최소한의 개인정보를 수집하여 처리합니다.<br/>
                        <div className='board-form'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>파일명</td>
                                        <td>처리목적</td>
                                        <td colSpan={2}>개인정보의 항목</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>회원가입 정보</td>
                                        <td>집클릭 서비스 제공</td>
                                        <td>필수</td>
                                        <td>개인/법무법인 : 회원구분, 성명, 아이디, 비밀번호, 전화번호<br/>
                                            중개업소 : 회원구분, 성명, 아이디, 비밀번호, 전화번호, 중개업소명, 중개업소 사업자등록번호, 중개업소 주소
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={4}>
                                            권리분석<br/>
                                            신청 정보
                                        </td>
                                        <td rowSpan={4}>
                                            권리분석 및<br/>
                                            계약관리
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            필수
                                        </td>
                                        <td>
                                            신청인 성명, 생년월일, 주소, 전화번호, 가족관계증명서, 주민등록표등본
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            선택
                                        </td>
                                        <td>
                                            기타서류(수급자증명서, 차상위계층증명서, 한부모가족증명서, 장애인증명서 등)
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br/>
                        <b>제2조(개인정보의 처리 및 보유기간)</b><br/>
                        ①집클릭에서 처리하는 개인정보는 수집·이용 목적으로 명시한 범위 내에서 처리하며, 개인정보보호법 및 관련 법령에서 정하는 보유기간을 준용하여 이행하고 있습니다.<br/>
                        ② 각각의 개인정보 처리 및 보유기간은 다음과 같습니다.<br/>
                       <div className='board-form'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>파일명</td>
                                        <td>수집/이용 근거</td>
                                        <td>보유기간</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>회원가입 정보</td>
                                        <td>정보주체의 동의</td>
                                        <td>회원탈퇴 시까지</td>
                                    </tr>
                                    <tr>
                                        <td>권리분석 신청 정보</td>
                                        <td>정보주체의 동의</td>
                                        <td>제출서류 4개월, 기타 5년</td>
                                    </tr>
                                </tbody>
                            </table>
                       </div>
                        <br/>
                        <b>제3조(개인정보의 제3자 제공에 관한 사항)</b><br/>
                        ① 집클릭은 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로 명시한 범위 내에서 처리하며, 아래의 경우를 제외하고는 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. <br/>
                        가. 정보주체로부터 별도의 동의를 받는 경우<br/>
                        나. 법률에 특별한 규정이 있는 경우<br/>
                        다. 정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br/>
                        라. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우<br/>
                        마. 법원의 재판업무 수행을 위하여 필요한 경우<br/>
                        <br/>
                        <b>제4조(개인정보처리 위탁에 관한 사항)</b><br/>
                        ①집클릭은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁운영하고 있습니다.<br/>
                       <div className='board-form'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>수탁기관</td>
                                        <td>위탁목적</td>
                                        <td>위탁업무의 내용</td>
                                        <td>위탁기간</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>㈜온결</td>
                                        <td>시스템 유지관리</td>
                                        <td>홈페이지 유지관리, 점검 및 기술지원</td>
                                        <td>2022.01.06 ~
                                            2023.01.05</td>
                                    </tr>
                                </tbody>
                            </table>
                       </div>
                        <br/>
                        <b>제5조(정보주체와 법정대리인의 권리의무 및 그 행사방법에 관한 사항)</b><br/>
                        ① 정보주체(만 14세 미만인 경우에는 법정대리인을 말함)는 언제든지 개인정보 열람정정삭제처리정지 등의 권리를 행사할 수 있습니다.<br/>
                        ② 제1항에 따른 권리 행사는 개인정보처리방법에 관한 고시 제8호 서식에 따라 작성 후 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 이에 대해 지체 없이 조치하겠습니다.<br/>
                        ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br/>
                        ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보처리방법에 관한 고시 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>
                        ⑤ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br/>
                        ⑥ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
                        ⑦ 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다<br/>
                        <br/>
                        <b>제6조(개인정보 파기 절차 및 방법)</b><br/>
                        ① 집클릭은 원칙적으로 개인정보 처리목적이 달성된 개인정보는 지체 없이 파기합니다. 다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수 있습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br/>
                        가. 파기절차<br/>
                        불필요한 개인정보 및 개인정보파일은 개인정보책임자의 책임 하에 내부방침 절차에 따라 다음과 같이 처리하고 있습니다.<br/>
                        - 개인정보의 파기 : 보유기간이 경과한 개인정보는 종료일로부터 지체 없이 파기합니다.<br/>
                        - 개인정보파일의 파기 : 개인정보파일의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그 개인정보파일을 파기합니다.<br/>
                        나. 파기방법<br/>
                        - 전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br/>
                        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/>
                        <br/>
                        <br/>
                        <b>제7조(개인정보 안정성 확보 조치)</b><br/>
                        ① 집클릭은 「개인정보 보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 조치를 취하고 있습니다.<br/>
                        가. 내부관리계획의 수립 및 시행<br/>
                        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br/>
                        나. 개인정보 취급직원의 최소화 및 교육<br/>
                        개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여 지정·관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.<br/>
                        다. 개인정보에 대한 접근 제한<br/>
                        개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
                        라. 접속기록의 보관<br/>
                        개인정보처리시스템에 접속한 기록을 최소 2년 이상 보관·관리하고 있습니다.<br/>
                        마. 개인정보의 암호화<br/>
                        개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있으며 전송 시에도 별도의 보안기능을 사용하고 있습니다.<br/>
                        바. 비인가자에 대한 출입 통제<br/>
                        개인정보를 보관하고 있는 개인정보처리시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립·운영하고 있습니다.<br/>
                        <br/>
                        <b>제8조(권익침해 구제방법)</b><br/>
                        ① 개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고포털 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.<br/>
                         개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br/>
                         개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
                         대검찰청  : (국번없이) 1301 (www.spo.go.kr)<br/>
                         경찰청  : (국번없이) 182 (ecrm.cyber.go.kr)<br/>
                        <br/>
                        <b>제9조(개인정보보호 책임자 및 담당자 연락처)</b><br/>
                        ① 집클릭은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br/>
                        <div className='board-form'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>구분</td>
                                        <td>담당자</td>
                                        <td>연락처</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>개인정보 보호책임자</td>
                                        <td>오아람</td>
                                        <td>전 화 :02 6205-0850<br />
                                        팩스번호:02 6280-4690</td>
                                    </tr>
                                    <tr>
                                        <td>개인정보 보호담당자</td>
                                        <td>정효진</td>
                                        <td>전 화 :02 6205-0850<br />
                                        팩스번호:02 6280-4690</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <br/>
                        <b>제10조(개인정보처리방침 변경)</b><br/>
                        본 개인정보 처리방침은 2022. 03. 02일부터 적용됩니다.<br/>
                    </>
                }
                <div className='Buttons'>
                    <button className="cancel-btn" onClick={()=>setIsShowTermsModal({show: false})}>닫기</button>
                </div>
                </div>
            </div>
        </div>
        </TermsPopup>
    );
};

export default TermsModal;