import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enCallerMemo/INITIALIZE';
const CHANGE_VALUE = 'enCallerMemo/CHANGE_VALUE';
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('enCallerMemo/SAVE');
const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes('enCallerMemo/CHECK');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const save = createAction(SAVE, (body) => (body));
export const check = createAction(CHECK, (body) => (body));

const saveSaga = createRequestSaga(SAVE, entitleAPI.callmemo);
const checkSaga = createRequestSaga(CHECK, entitleAPI.callmemochk);

export function* enCallerMemoSaga() {
    yield takeLatest(SAVE, saveSaga);
    yield takeLatest(CHECK, checkSaga);
}

const initialState = {
    remark: '',

    enCallerMemo: null,
    error: null
};

const enCallerMemo = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("메모가 저장되었습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },

        [CHECK_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [CHECK_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default enCallerMemo;