import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as authAPI from '../../lib/api/auth';
import * as approvalAPI from '../../lib/api/approval';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enApprove/INITIALIZE';
const CHANGE_VALUE = 'enApprove/CHANGE_VALUE';
const [APPR_LIST, APPR_LIST_SUCCESS, APPR_LIST_FAILURE] = createRequestActionTypes('enApprove/APPR_LIST');
const [MY_APPR_LIST, MY_APPR_LIST_SUCCESS, MY_APPR_LIST_FAILURE] = createRequestActionTypes('enApprove/MY_APPR_LIST');
const ADD_MY_APPR = 'enApprove/ADD_MY_APPR';
const REMOVE_MY_APPR = 'enApprove/REMOVE_MY_APPR';
const SORT_MY_APPR = 'enApprove/SORT_MY_APPR';
const [REQ_APPR, REQ_APPR_SUCCESS, REQ_APPR_FAILURE] = createRequestActionTypes('enApprove/REQ_APPR');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getApprList = createAction(APPR_LIST);
export const getMyApprList = createAction(MY_APPR_LIST);
export const addMyAppr = createAction(ADD_MY_APPR, (item) => (item));
export const removeMyAppr = createAction(REMOVE_MY_APPR, (item) => (item));
export const sortMyAppr = createAction(SORT_MY_APPR, (item) => (item));
export const reqAppr = createAction(REQ_APPR);

const getApprListSaga = createRequestSaga(APPR_LIST, authAPI.search);
const getMyApprListSaga = createRequestSaga(MY_APPR_LIST, approvalAPI.appuser_list);
const reqApprSaga = createRequestSaga(REQ_APPR, approvalAPI.app_request);

export function* enApproveSaga() {
    yield takeLatest(APPR_LIST, getApprListSaga);
    yield takeLatest(MY_APPR_LIST, getMyApprListSaga);
    yield takeLatest(REQ_APPR, reqApprSaga);
}

const initialState = {
    isBaseLine: true,
    apprList: [],
    myApprList: [],

    enApprove: null,
    error: null
};

const enApprove = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [APPR_LIST_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                apprList: payload,
            }
        },
        [APPR_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [MY_APPR_LIST_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                myApprList: payload,
            }
        },
        [MY_APPR_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [ADD_MY_APPR]: (state, { payload }) => {
            let isDuplicated = false;
            let lastAppSeq = 0;
            state.myApprList.forEach(myAppr => {
                if (myAppr.user_id === payload.user_id) {
                    isDuplicated = true;
                }
                
                if(myAppr.app_seq > lastAppSeq) {
                    lastAppSeq = myAppr.app_seq;
                }
            });

            let _myApprList = state.myApprList;
            if(!isDuplicated) {
                _myApprList = state.myApprList.concat({
                    app_seq: lastAppSeq + 1,
                    user_id: payload.user_id,
                    user_name: payload.user_name,
                    user_phone: payload.user_phone,
                })
            }
            
            return {
                ...state,
                myApprList: _myApprList,
            }
        },
        [REMOVE_MY_APPR]: (state, { payload }) => {
            let _myApprList = state.myApprList.filter((item) => item.user_id !== payload.user_id);
            let cnt = 1;
            _myApprList.forEach(_myAppr => {
                _myAppr.app_seq = cnt++;
            });
            return {
                ...state,
                myApprList: _myApprList,
            }
        },
        [SORT_MY_APPR]: (state, { payload }) => {
            let _myApprList = state.myApprList;

            if(payload.app_seq > 1) {
                let _prevAppr = state.myApprList.find(x => x.app_seq === payload.app_seq - 1);
                let _currAppr = state.myApprList.find(x => x.app_seq === payload.app_seq);

                let _beforeSort = state.myApprList.map((item) => {
                    if(item.user_id === _prevAppr.user_id) {
                        return {
                            ...item,
                            app_seq: payload.app_seq,
                        }
                    }
                    else if(item.user_id === _currAppr.user_id) {
                        return {
                            ...item,
                            app_seq: payload.app_seq - 1,
                        }
                    }
                    return {
                        ...item,
                    }
                });

                _beforeSort.sort((a, b) => {
                    return a.app_seq - b.app_seq;
                });
                _myApprList = _beforeSort;
            }
            
            return {
                ...state,
                myApprList: _myApprList,
            }
        },

        [REQ_APPR_SUCCESS]: (state, {payload}) => {
            ALERT("결재가 요청되었습니다");
            return {
                ...state,
            }
        },
        [REQ_APPR_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default enApprove;