import React from 'react';
import EntitleViewrContainer from '../containers/EntitleViewrContainer';
import HeaderContainer from '../containers/HeaderContainer';

const EntitleViewerPage = () => {
    return (
        <>
            <HeaderContainer />
            <EntitleViewrContainer />
        </>
    );
}

export default EntitleViewerPage;