import client from './client';

export const write = (body) =>
    client.post('/api/winner/winner_write', body);

export const request = (body) =>
    client.post('/api/winner/request', body);

export const req_search = (body) =>
    client.post('/api/winner/req_search', body);

export const detail_search = (body) =>
    client.post('/api/winner/detail_search', body);

export const winner_view = ({winner_id}) => 
    client.get(`/api/winner/winner_view/${winner_id}`);

export const approve = (body) =>
    client.post('/api/winner/req_approve', body);

export const winner_delete = (body) =>
    client.post('/api/winner/winner_delete',body)

export const excel_upload = (body) =>
    client.post('/api/winner/excel_upload',body)

export const req_delete = ({request_id}) =>
    client.delete(`/api/winner/req_delete/${request_id}`)