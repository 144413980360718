import React from 'react';
import styled from 'styled-components';

const ImplyWorkListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

    // .resultTbl {
    //     overflow: auto;
    //     height: 80vh;
    //     border: 1px solid black;
    //     box-sizing: border-box;
    // }

    .resultTbl table{
        white-space: nowrap;
        text-align: center;
        margin-bottom: 0px;
    }

    .resultTbl table th, 
    .resultTbl table td {
        padding: 0 5px;
    }
`;

const ImplyWorkList = (props) => {
    const {
        implyListSearch,
        implyList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        implyListCheck,
        requestApprove,
        writeReport,

        onChangeSearch,
        onSubmit,
        onClickNum,
        onClickNext,
        onClickPrev,
        onClickList,
        onClickCheck,
        onClickCheckAll,
        onClickRequestApprove,
        onClickWriteReport,
    } = props
    return (
        <ImplyWorkListBlock>
            <h1>묵시작업 리스트 페이지</h1>
            {requestApprove? <button onClick={onClickRequestApprove}>결재요청</button> : null}
            {writeReport? <button onClick={onClickWriteReport}>보고서작성요청</button> : null}
         
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt3'>
                            <div className='st3'>
                                <label htmlFor='date_type'>날짜유형</label>
                                <select name='date_type' id='date_type' value={implyListSearch.date_type || ''} onChange={onChangeSearch}>
                                    <option value={''}>선택하세요</option>
                                    <option value={'REQ_DATE'}>요청일</option>
                                    <option value={'PROCESS_DATE'}>처리일</option>
                                </select>
                                <input type='date' name='from_date' id='from_date' value={implyListSearch.from_date || ''} onChange={onChangeSearch}/>
                                <span>~</span>
                                <input type='date' name='to_date' id='to_date' value={implyListSearch.to_date || ''} onChange={onChangeSearch}/>
                            </div>
                            <div className='st3'>
                                <label htmlFor='appr_flag'>결재유무</label>
                                <select name='appr_flag' id='appr_flag' value={implyListSearch.appr_flag || ''} onChange={onChangeSearch}>
                                    <option value={''}>전체</option>
                                    <option value={'N'}>미결재</option>
                                    <option value={'P'}>결재중</option>
                                    <option value={'Y'}>결재완료</option>
                                </select>
                            </div>
                            <div className='st3'>
                                <label htmlFor='report_flag'>보고서 작성 유무</label>
                                <select name='report_flag' id='report_flag' value={implyListSearch.report_flag || ''} onChange={onChangeSearch}>
                                    <option value={''}>전체</option>
                                    <option value={'Y'}>작성완료</option>
                                    <option value={'N'}>미작성</option>
                                </select>
                            </div>
                        </div>
                        {/* [DEV] 22.03.25 임의로 초기화버튼추가 */}
                        <div className='btn'>
                            <button className='green-sch-btn'>검색</button>
                            <button className='reset-btn'>검색조건 초기화</button>
                        </div>
                    </div>
                </form>
            </div>
         
            <div className='resultTbl'>
                <table className='tt'>
                    <thead>
                        <tr>
                            {requestApprove || writeReport? <th><input type={'checkbox'} id='checkAll' checked={implyListCheck.length === implyList.length && implyListCheck.length > 0? true : false} onChange={onClickCheckAll}/><label htmlFor='checkAll'></label></th> : null}
                            <th>번호</th>
                            <th>요청ID</th>
                            <th>요청일시</th>
                            <th>요청자</th>
                            <th>처리일시</th>
                            <th>처리자ID</th>
                            <th>처리자</th>
                            <th>유형</th>
                            <th>요청인원수</th>
                            <th>진행상태</th>
                            <th>묵시 OK</th>
                            <th>묵시 NG</th>
                            <th>묵시 처리중</th>
                            <th>결재유무</th>
                            <th> 보고서 작성 유무</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            implyList.length === 0 &&
                            <tr>
                                <td colSpan={requestApprove || writeReport ? 16 : 15}>검색 결과가 없습니다.</td>
                            </tr>
                        }
                        {implyList.length > 0? 
                        <>
                        {
                            implyList.map((item, idx) => {
                                return (
                                    <tr key={idx} onClick={(e) => onClickList(e, item)}>
                                        {requestApprove || writeReport? <td><input type={'checkbox'} id={idx} checked={implyListCheck.some((el) => el.request_id === item.request_id)? true : false} onChange={(e) => onClickCheck(e, item)}/><label htmlFor={idx}></label></td> : null}
                                        <td>{(maxPageCount * (currentPage - 1)) + idx+1}</td>
                                        <td>{item.request_id}</td>
                                        <td>{item.request_date}</td>
                                        <td>{item.request_name}</td>
                                        <td>{item.processing_date}</td>
                                        <td>{item.processor}</td>
                                        <td>{item.processor_name}</td>
                                        <td>{item.type_name}</td>
                                        <td>{item.people_count}</td>
                                        <td>{item.status}</td>
                                        <td>{item.ok_count}</td>
                                        <td>{item.ng_count}</td>
                                        <td>{item.n_count}</td>
                                        {item.app_flag === 'N'? <td>미요청</td> : null}
                                        {item.app_flag === 'P'? <td>요청중</td> : null}
                                        {item.app_flag === 'Y'? <td>결재완료</td> : null}
                                        {item.app_flag === 'R'? <td>반려</td> : null}
                                        <td>{item.report_flag}</td>
                                    </tr>
                                )
                            })
                        }
                        </>
                        : null}
                    </tbody>
                </table>
            </div>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
        </ImplyWorkListBlock>
    );
}

export default ImplyWorkList;