import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
import '../index.css'

const MaintainRegisterBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
    }

    .search-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .search form {
        overflow: inherit;  // Mutiple Check ComboBox를 보여주기 위해서 overflow:hidden을 제거함.
    }
    //  Mutiple Check Combo
    .cboStDiv {
        position: relative;
    }
    .txtSelectedValues {
        display: flex;
        width: 100%;
        padding: 5px;
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .txtSelectedValues div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .cboMultiple {
        position: absolute;
        top: 36px;
        border: 1px solid black;
        list-style: none;
        max-height: 150px;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;
    }
    .cboMultiple li {
        background-color: white;
    }
    .cboMultiple li:hover {
        background-color: #ccc;
    }
    .cboMultiple li input[type="checkbox"] + label:before {
        top: 8px;
        left: 6px;
    }
    .cboMultiple li label {
        background-color: inherit;
        height: auto;
        padding: 5px 5px 5px 25px;
        width: 100%;
        text-align: left;
    }
    .tt {
        margin-top: 10px;
    }
`;

const RegistPopup = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .popupWrap{
        width: 800px;
        box-sizing: border-box;
        padding: 20px;
    }
    h4{
        color: #545454;
        font-size: 26px;
        text-align: center;
        margin-bottom: 15px;
    }
    .regInfo {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    .info-box {
        border: 1px solid black;
        margin: 5px;
    }
    .regInfo .info-box div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .info-box div:first-child {
        height: 40px;
        background-color: #ccc;
        border-bottom: 1px solid black;
    }
    .info-box div:last-child {
        padding: 3px;
    }
    .info-box input[type=text],
    .info-box input[type=number],
    .info-box input[type=date] {
        width: 100%;
    }
    .info-box .with-btn {
        display: flex;
        flex-direction: row !important;
        gap: 5px;
    }
    .info-box .with-btn button {
        width: 100px;
        cursor: pointer;
    }
`;

const MaintainRegister = (props) => {
    const {
        //  검색조건
        date_type,
        from_date,
        to_date,
        name,

        list,

        //  Modal Show/Hide
        isShowReg,

        //  등록 Data
        nextwork_id,
        cboCycleType,
        start_date,
        work_name,
        work_birth,
        phone,
        exec_cycle,
        addr,
        addr_dtl,
        estate_no,
        house_owner,
        end_date,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,
        
        //  지역 검색 관련
        cboAreaData,
        isShowCboArea,
        selectedAreas,
        onAreaChange,
        onAreaAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        onChange,
        onSubmit,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,
        
        onClickNewReg,
        onClickRow,
        onClickNum,
        onClickNext,
        onClickPrev,
        onShowDaumPost,
        handlePostCode,
        onSave,
        onClose,
    } = props

    return (
        <MaintainRegisterBlock>
            <h1>사전/사후 관리 대상자 등록</h1>

            <div className='search-title'>
                <h2>검색조건</h2>
                <div className='request-btn' onClick={onClickNewReg}>신규등록</div>
            </div>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt'>
                            {/* <h4 className='bg'>기간</h4> */}
                            {/*[Dev] 22.02.15 h4 className bg 추가*/}
                            <div className='st'>
                                <label htmlFor='date_type' className='bg' style={{width:"200px"}}>관리 시작/종료</label>
                                <select name="date_type" id="date_type" value={date_type} onChange={onChange}>
                                    <option value="START_DATE">시작일</option>
                                    <option value="END_DATE">종료일</option>
                                </select>
                            </div>

                            {/*[Dev] 22.02.22 st클래스안에 div, class=bg 추가*/}
                            <div className='st'>
                                <div>
                                    <label htmlFor='from_date' className='bg'>시작일</label>
                                    <input type="date" name="from_date" id="from_date" value={from_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                                <div>
                                    <label htmlFor='to_date' className='bg'>종료일</label>
                                    <input type="date" name="to_date" id="to_date" value={to_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                            </div>

                            {/*[Dev] 22.02.22 st클래스안에 div, class=bg 추가*/}
                            {/* <h4 className='bg'>담당자</h4> */}
                            <div className='st'>
                                <div>
                                    <label htmlFor='name' className='bg'>대상자</label>
                                    <input type="text" name="name" id="name" value={name} onChange={onChange} />
                                </div>
                            </div>

                            <div className='st' style={{alignItems:"flex-start"}} tabIndex={0} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboArea' className='bg'>지역</label>
                                <div className='cboStDiv' style={{width:"150px"}}>
                                    <div className='txtSelectedValues' onClick={() => onShowMultiCbo('AREA')}>
                                        <div>{selectedAreas}</div>
                                        ▼
                                    </div>
                                    {isShowCboArea &&
                                        <ul className="cboMultiple" id="cboArea">
                                            <li>
                                                <input type="checkbox" name="areaALL" id="areaALL" checked={cboAreaData.length === cboAreaData.filter(x => x.checked === true).length} onChange={onAreaAll} />
                                                <label htmlFor="areaALL">전체 선택</label>
                                            </li>
                                            {cboAreaData.map((item) => (
                                                <li key={item.area}>
                                                    <input type="checkbox" name={item.area} id={item.area} checked={item.checked} onChange={onAreaChange.bind(this, item)} />
                                                    <label htmlFor={item.area}>{item.area}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>

                            {/* [DEV] 22.02.15 검색 버튼 이동 */}
                            <div className='btn'>
                                <button className='green-sch-btn'>검색</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            
            <h2>검색결과</h2>
            <table className='tt'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>대상자</th>
                        <th>휴대전화</th>
                        <th>생년월일</th>
                        <th>소유주</th>
                        <th>주소</th>
                        <th>시작일</th>
                        <th>종료일</th>
                        <th>실행주기</th>
                        <th>등본 고유번호</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length === 0 &&
                        <tr>
                            <td colSpan={10}>검색 결과가 없습니다.</td>
                        </tr>
                    }
                    {list.map((item, idx) => {
                        return (
                            <tr key={item.nextwork_id} onClick={onClickRow.bind(this, item)}>
                                <td>{(30 * (currentPage - 1)) + idx + 1}</td>
                                <td>{item.work_name}</td>
                                <td>{item.work_phone}</td>
                                <td>{item.work_birth}</td>
                                <td>{item.house_owner}</td>
                                <td>{item.addr} {item.addr_dtl}</td>
                                <td>{item.start_date}</td>
                                <td>{item.work_end_date}</td>
                                <td>{item.exec_cycle}</td>
                                <td>{item.road_estate_no}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>

            {isShowReg &&
                <RegistPopup>
                    <div className='popupWrap'>
                        {
                            nextwork_id ? <h4>사전/사후 관리 대상자 수정</h4> : <h4>사전/사후 관리 대상자 등록</h4>
                        }
                        <div className='regInfo'>
                            <div className='info-box' style={{gridColumn:"1/2"}}>
                                <div>대상자 성명</div>
                                <div><input type="text" name="work_name" id="work_name" value={work_name} onChange={onChange} /></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"2/3"}}>
                                <div>대상자 생년월일</div>
                                <div><input type="number" name="work_birth" id="work_birth" value={work_birth} onChange={onChange} /></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"3/4"}}>
                                <div>대상자 휴대전화</div>
                                <div><input type="text" name="phone" id="phone" value={phone} onChange={onChange} /></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"4/5"}}>
                                <div>관리 시작일</div>
                                <div><input type="date" name="start_date" id="start_date" value={start_date} onChange={onChange} max="9999-12-31"/></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"5/6"}}>
                                <div>관리 종료일</div>
                                <div><input type="date" name="end_date" id="end_date" value={end_date} onChange={onChange} max="9999-12-31"/></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"1/4"}}>
                                <div>주소</div>
                                <div className='with-btn'>
                                    <input type="text" name="addr" id="addr" value={addr} onChange={onChange} />
                                    <button className='save-btn' onClick={onShowDaumPost}>주소찾기</button>
                                </div>
                            </div>
                            <div className='info-box' style={{gridColumn:"4/6"}}>
                                <div>주택 소유주</div>
                                <div><input type="text" name="house_owner" id="house_owner" value={house_owner} onChange={onChange} /></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"1/4"}}>
                                <div>상세 주소</div>
                                <div><input type="text" name="addr_dtl" id="addr_dtl" value={addr_dtl} onChange={onChange} /></div>
                            </div>
                            <div className='info-box' style={{gridColumn:"4/5"}}>
                                <div>실행주기</div>
                                <div>
                                    <select name="exec_cycle" id="exec_cycle" value={exec_cycle} onChange={onChange}>
                                        {cboCycleType.map((item) => (
                                            <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='info-box' style={{gridColumn:"5/6"}}>
                                <div>등기부등본 고유번호</div>
                                <div><input type="text" name="estate_no" id="estate_no" value={estate_no} onChange={onChange} /></div>
                            </div>
                        </div>
                        <br/>
                        <div className='divButtons'>
                            <button className="save-btn" style={{width:"130px"}} onClick={() => onSave(true)}>저장 후 계속 등록</button>
                            <button className="save-btn" style={{width:"100px"}} onClick={() => onSave(false)}>저장 후 닫기</button>
                            <button className="cancel-btn" onClick={onClose}>닫기</button>
                        </div>
                    </div>

                    {isShowDaumPost &&
                        <div className='divDaumPostcode'>
                            <DaumPostcode onComplete={handlePostCode}/>
                            <button className='bdnone-cancel-btn' onClick={onShowDaumPost}>닫기</button>
                        </div>
                    }
                </RegistPopup>
            }
        </MaintainRegisterBlock>
    );
}

export default MaintainRegister;