export const dataEmptyRoomTable = [
    {
        housing: 55_000_000,
        store: 22_000_000,
        areaMain: '서울',
        areaStr: ['강남구', '강동구', '강북구', '강서구', '관악구', '광진구', '구로구', '금천구', '노원구', '도봉구',
                '동대문구', '동작구', '마포구', '서대문구', '서초구', '성동구', '성북구', '송파구', '양천구', '영등포구',
                '용산구', '은평구', '종로구', '중구', '중랑구']
    },
    {
        housing: 48_000_000,
        store: 19_000_000,
        areaMain: '경기도',
        areaStr: ['의정부시', '구리시', '하남시']
    },
    {
        housing: 25_000_000,
        store: 10_000_000,
        areaMain: '경기도',
        areaStr: ['동두천시', '연천군', '포천시', '양주시', '가평군', '양평군']
    },
    {
        housing: 48_000_000,
        store: 19_000_000,
        areaMain: '남양주시',
        areaStr: ['호평동', '평내동', '금곡동', '일패동', '이패동', '삼패동', '가운동', '수석동', '지금동', '도농동', '다산동']
    },
    {
        housing: 25_000_000,
        store: 10_000_000,
        areaMain: '남양주시',
        areaStr: []
    },
]

export const emptyRoomInputDataStru = {
    addressStr: '',
    emptyType: '',  // 'h' - housing, 's' - store
}

export const dataEmptyRoomCalc = inputData => {
    // 입력받은 데이터의 정상여부 확인
    if( inputData && inputData.addressStr && inputData.emptyType ) {
        
        // 데이터 구조체의 배열 수만큼 반복
        for(let i=0; i<dataEmptyRoomTable.length; i++) {

            // 주소에 Main 문자열이 있는지 확인
            if( (dataEmptyRoomTable[i].areaMain === '') || (inputData.addressStr.indexOf(dataEmptyRoomTable[i].areaMain) >= 0) ) {

                // 포함 문자열 배열이 있는 경우
                if( dataEmptyRoomTable[i].areaStr.length > 0 ) {

                    // 배열 수만큼 반복
                    for(let j=0; j<dataEmptyRoomTable[i].areaStr.length; j++) {

                        // 배열에 있는 문자열의 포함 여부
                        if( inputData.addressStr.indexOf(dataEmptyRoomTable[i].areaStr[j]) >= 0 ) {

                            // 배열에 있는 문자열이 있는 경우 공실 타입에 따른 값 반환
                            switch (inputData.emptyType) {
                                case 'h':
                                    return dataEmptyRoomTable[i].housing;
                            
                                case 's':
                                    return dataEmptyRoomTable[i].store;

                                default:
                                    break;
                            }
                        }
                    }
                } else {    // 문자열 배열에 항목이 없는 경우 (외 지역)
                    switch (inputData.emptyType) {
                        case 'h':
                            return dataEmptyRoomTable[i].housing;
                    
                        case 's':
                            return dataEmptyRoomTable[i].store;

                        default:
                            break;
                    }
                }
            }
        }    
    }
    return 0;
}

// export default dataEmptyRoomCalc;