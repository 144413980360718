import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ApplyMgt from '../components/ApplyMgt';
import { addRow, getCode, initialize, removeRow, rowChangeValue, saveCode, sortRow } from '../modules/applyMgt';
import { dataEntitleCalc } from '../lib/dataEntitleCalc';

const ApplyMgtContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {state: {id}} = location;

    const {
        applyList,
    } = useSelector(({ applyMgt }) => ({
        applyList: applyMgt.applyList,
    }));

    const onRowChange = (item, e) => {
        dispatch(
            rowChangeValue({
                idx: item.idx,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onAddRow = (e) => {
        dispatch(addRow());
    }
    const onRemoveRow = (item, e) => {
        dispatch(removeRow({idx: item.idx}));
    }
    const onRowSort = (item, type, e) => {
        dispatch(sortRow({
            display: item.display,
            type: type,
        }));
    }
    const onSave = () => {
        dispatch(saveCode(applyList));
        dispatch(getCode({
            main_code: 'APPLY_TYPE',
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }));
    }

    useEffect(() => {
        dispatch(getCode({
            main_code: 'APPLY_TYPE',
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }));

    }, [dispatch, id])
    
    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        applyList,
        dataEntitleCalc,

        onRowChange,
        onAddRow,
        onRemoveRow,
        onRowSort,
        onSave,
    }

    return (
        <>
            <ApplyMgt {...propDatas} />
        </>
    );
}

export default ApplyMgtContainer;