import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import EntitleWriteContainer from '../containers/EntitleWriteContainer';

const EntitleWritePage = () => {
    return (
        <>
            <HeaderContainer />
            <EntitleWriteContainer/>
        </>
    );
}

export default EntitleWritePage;