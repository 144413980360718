//  권역별 지역 Data
export const dataRegion = [
    {
        idx: '1',   //  권역 번호
        region: [
            { sido: '서울시', gungu: '강서구', },
            { sido: '서울시', gungu: '구로구', },
            { sido: '서울시', gungu: '금천구', },
            { sido: '서울시', gungu: '동작구', },
            { sido: '서울시', gungu: '마포구', },
            { sido: '서울시', gungu: '양천구', },
            { sido: '서울시', gungu: '영등포구' },
            { sido: '서울시', gungu: '용산구', },
            { sido: '서울시', gungu: '은평구', },
            { sido: '서울시', gungu: '중구', },
            { sido: '경기도', gungu: '양주시', },
            { sido: '경기도', gungu: '연천군', },
        ]
    },
    {
        idx: '2',
        region: [
            { sido: '서울시', gungu: '광진구', },
            { sido: '서울시', gungu: '노원구', },
            { sido: '서울시', gungu: '동대문구', },
            { sido: '서울시', gungu: '성북구', },
            { sido: '서울시', gungu: '중랑구', },
            { sido: '경기도', gungu: '가평군', },
            { sido: '경기도', gungu: '구리시', },
            { sido: '경기도', gungu: '남양주시', },
            { sido: '경기도', gungu: '양평군', },
            { sido: '경기도', gungu: '의정부시', },
        ]
    },
    {
        idx: '3',
        region: [
            { sido: '서울시', gungu: '강남구', },
            { sido: '서울시', gungu: '강동구', },
            { sido: '서울시', gungu: '강북구', },
            { sido: '서울시', gungu: '관악구', },
            { sido: '서울시', gungu: '도봉구', },
            { sido: '서울시', gungu: '서대문구', },
            { sido: '서울시', gungu: '서초구', },
            { sido: '서울시', gungu: '성동구', },
            { sido: '서울시', gungu: '송파구', },
            { sido: '서울시', gungu: '종로구', },
            { sido: '경기도', gungu: '동두천시', },
            { sido: '경기도', gungu: '포천시', },
            { sido: '경기도', gungu: '하남시', },
        ]
    }
]