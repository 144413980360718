import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { qr } from '../common';
import ImplyRegisterList from '../components/ImplyRegisterList';
import dataURL from '../lib/dataURL';
import { changeSearcheckBox, changeValue, impRequestSearch, initialize, setPaging, setSearch, setSearchValue } from '../modules/Imply';
import { changeTypeArr, getCombo } from '../modules/WinnerWork';

const ImplyRegisterListContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [changeUrl, setChangeUrl] = useState(false);
    
    const [isLeaveMultiCbo, setIsLeaveMultiCbo] = useState(false); //  Multipe ComboBox 공통 닫기 Event
    const [isShowCboType, setIsShowCboType] = useState(false); //  지원유형 Multipe Combo Show/Hide
    const [selectedTypes, setSelectedTypes] = useState(""); //  지원유형 선택된 Text

    const {
        cboApply,
        search,
        maxPageCount,
        pageCount,
        max,
        step,
        currentPage,
        ImplyReqList,
    } = useSelector(({WinnerWork, Imply}) => ({
        cboApply: WinnerWork.cboApply,
        search: Imply.search,
        maxPageCount: Imply.maxPageCount,
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
        ImplyReqList: Imply.ImplyReqList,
    })) 

    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    const onChange = (e, field) => {
        const {target: {name, value}} = e
        dispatch(changeValue({
            field,
            key: name,
            value
        }))
    }

    const onInitSearch = () => {
        dispatch(setSearch({
            search: 'search',
            value: null
        }))

        dispatch(
            changeTypeArr({
                key: 'cboApply',
                value: cboApply.map((data) => ({ ...data, checked: true }))
            })
        )
    }

    // 리스트 클릭
    const onClick = (id, status) => {
        navigate(dataURL.ImplyRegisterWorkPage, 
            {state: {
                func: location.state.func, 
                id: id,
                status: status === '요청완료'? 'complete' : 'request',
            }})
    }

    const onCheck = (e) => {
        const {target: {name, value, checked}} = e
        dispatch(changeSearcheckBox({checked, key: name, value}));
    }

    
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "search", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }

    //  Multiple Check Combo 공통   --------------------------------------------
    const onShowMultiCbo = (type) => {
        setIsShowCboType(!isShowCboType);

        if (!isShowCboType) {
            setIsLeaveMultiCbo(false);
        }
    };
    const onHideMultiCbo = (e) => {
        if (e.type === "mouseleave") {
            setIsLeaveMultiCbo(true);
        } else if (isLeaveMultiCbo) {
            setIsShowCboType(false);
            setIsLeaveMultiCbo(false);
        }
    };
    const onEnterMultiCbo = () => {
        setIsLeaveMultiCbo(false);
    };
    //  --------------------------------------------    Multiple Check Combo 공통

    //  지원유형 Multiple Check Combo   --------------------------------------------
    const onTypeChange = (item, e) => {
        dispatch(
            changeTypeArr({
                key: 'cboApply',
                value: cboApply.map((data) => 
                    data.sub_code === item.sub_code
                        ? { ...data, checked: e.target.checked }
                        : data
                )
            })
        )
    };
    const onTypeAll = (e) => {
        dispatch(
            changeTypeArr({
                key: 'cboApply',
                value: cboApply.map((data) => {
                    return { ...data, checked: e.target.checked }
                })
            })
        )
    };

    useEffect(() => {
        if (cboApply.length > 0) {
            let selTexts = "",
                selValues = "";

            const filterData = cboApply.filter((x) => x.checked === true);
            filterData.forEach((selected) => {
                selTexts += selected.code_name + ",";
                selValues += selected.sub_code + ",";
            });

            if (cboApply.length === filterData.length) {
                selTexts = "전체";
                // selValues = "";
            } else {
                selTexts = selTexts.slice(0, -1);
            }

            setSelectedTypes(selTexts);
            dispatch(changeValue({field: "search", key: "type", value: selValues}))
        }
    }, [dispatch, cboApply]);
    //  --------------------------------------------    지원유형 Multiple Check Combo

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    // 지원유형 조회
    useEffect(() => {
        if(cboApply.length < 1) {
            dispatch(getCombo({main_code: "APPLY_TYPE"}))
        }
    }, [dispatch, cboApply])

    // 쿼리 스트링 구성, 변경, 이동
    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.ImplyRegisterListPage}?date_type=${search.date_type}&from_date=${search.from_date}&to_date=${search.to_date}&req_user=${search.req_user}&prc_user=${search.prc_user}&type=${search.type}&status1=${search.status1}&status2=${search.status2}&page=${search.page}`), 
            {
                state: {
                    func: location.state.func, 
                },
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, search])

        // 변경된 url 로 조회.
        useEffect(() => {
            if(dispatch) {
                if(!location.search) {
                    setChangeUrl(true)
                }
                else {
                    const tempObj = qr(location.search);
    
                    dispatch(setSearchValue(tempObj))
    
                    dispatch(setPaging({
                        key: "currentPage",
                        value: Number(tempObj.page),
                    }))
                    dispatch(setPaging({
                        key: "step",
                        value: Number(tempObj.page),
                    }))
                    dispatch(impRequestSearch({
                        ...tempObj,
                        status: tempObj.status1 && tempObj.status2? "" : 
                        tempObj.status1 && !tempObj.status2? tempObj.status1 : 
                            !tempObj.status1 && tempObj.status2? tempObj.status2 : ""
                    }))
                }
            }
        }, [dispatch, location])

    const propDatas = {
        search,
        maxPageCount,
        pageCount,
        max,
        step,
        currentPage,
        ImplyReqList,
        func: location.state.func,

        //  지원유형 검색 관련
        cboApply,
        isShowCboType,
        selectedTypes,
        onTypeChange,
        onTypeAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,
        onCheck,
        onClick,
        onInitSearch,
    };
    return (
        <>
            <ImplyRegisterList {...propDatas}/>
        </>
    );
}

export default ImplyRegisterListContainer;