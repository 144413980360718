import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ListContainer from '../containers/ListContainer';

const ListPage = () => {
    return (
        <>
            <HeaderContainer />
            <ListContainer/>
        </>
    );
}

export default ListPage;