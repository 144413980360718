import React from 'react';
import styled from 'styled-components';

const EnChangeReqBlock = styled.div`
.popupModal > div:nth-child(2){
    width: 500px;
    box-sizing: border-box;
    padding: 50px;
}
.popupModal > div:nth-child(2) h4{
    font-size: 26px;
    color: #545454;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;
}
textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    box-sizing: border-box;
    resize: none;
}
span {
    float: right;
}


`;

const EnChangeReq = (props) => {
    const {
        doc_status,

        remark,

        onChange,
        onSave,
        onClose,
    } = props;

    return (
        <EnChangeReqBlock>
           <div className='popupModal'>
                <div />
                <div>
                    <h4>검토서 변경요청</h4>
                    <div>
                        <textarea name="remark" id="remark" value={remark} onChange={onChange} readOnly={doc_status !== 'ENMODIFY' ? false : true}></textarea>
                    </div>
                   <div className='divButtons'>
                        <button onClick={onSave} className="divB-Save">확인</button>
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                   </div>
                </div>
           </div>
        </EnChangeReqBlock>
    );
}

export default EnChangeReq;