import React from 'react';
import styled from 'styled-components';

const DobaeUserListSearchViewBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin: 20px 0;
    }
    h2{
        color: #545454;
        font-size: 20px;
    }

    .search-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .searchForm{
        overflow: inherit;
        box-sizing : border-box;
        padding: 20px;
    }
    .tt {
        margin-top: 10px;
    }

    .btn-area {
        display: flex;
        gap: 10px;
    }
`;

const DobaeUserListSearchView = (props) => {
    const {
        cust_name,
        birth,

        changeValue,
        searchDobaeUserList,
        onKeyPress,
    } = props;
    return (
        <DobaeUserListSearchViewBlock>
            <h1>도배장판 사용자확인</h1>

            <div className='search'>
                <div className='searchForm'>
                    <div className='searchBody'>
                        <div className='searchipt'>
                            <div className='st'>
                                <label htmlFor="cust_name">성명</label>
                                <input type="text" name="cust_name" id="cust_name" value={cust_name} onChange={changeValue} onKeyPress={onKeyPress}/>
                            </div>
                            <div className='st'>
                                <label htmlFor="birth">생년월일</label>
                                <input type="text" name="birth" id="birth" value={birth} onChange={changeValue} onKeyPress={onKeyPress}/>
                            </div>
                        </div>
                        <div className='btn btn-area'>
                            <button className='green-sch-btn' onClick={searchDobaeUserList}>검색</button>
                        </div>
                    </div>
                </div>
            </div>
        </DobaeUserListSearchViewBlock>
    );
}

export default DobaeUserListSearchView;