import React from 'react';
import styled from 'styled-components';

const CreateBrokerBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 620px;
        padding: 50px;
        box-sizing: border-box;
    }
    .popupModal .cancelCheck>div:nth-child(2){
        text-align: right;
        padding: 0 40px;
        box-sizing: borderbox;
        margin-bottom: 40px;
    }
    .popupModal .pwipt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;
    }
    .popupModal .pwipt input{
        height: 34px;
        width: 250px;
    }
    .popupModal .pwipt .idipt {
        display: flex;
        gap: 10px;
    }
    .popupModal .pwipt .idipt input {
        height: 38px;
        width: 154px;
    }
   
    .divButtons{
        padding: 0 30px;
        box-sizing: border-box;
    }
    .green, .red, .black{
        font-size: 11px;
        font-weight: 600;
    }
    .green {
        color: #95BC07;
    }
    .red {
        color: red;
    }
`;

const CreateBroker = (props) => {
    const {
        broker_id,
        broker_company,
        broker_phone,

        chk_overlap,

        onChange,
        onIdCheck,
        onSave,
        onClose,
    } = props;
    return (
        <CreateBrokerBlock>
            <div className='popupModal'>
                <div />
                <div className='cancelCheck'>
                    <h2>중개사 ID 생성</h2>
                    <div>
                        {
                            chk_overlap === 0 ? <p className='black'>아이디 중복확인 필요</p> :
                            chk_overlap === 1 ? <p className='green'>사용할 수 있는 아이디입니다</p> : <p className='red'>아이디가 중복됩니다</p>
                        }
                        <div className='pwipt'>
                            <label>중개사 ID</label>
                            <div className='idipt'>
                                <input type="text" name="broker_id" id="broker_id" value={broker_id} onChange={onChange} placeholder='중개사 ID' />
                                <button onClick={onIdCheck} className='squre-bdradius-btn'>중복확인</button>
                            </div>
                        </div>
                        <div className='pwipt'>
                            <label>중개사 상호명</label>
                            <input type="text" name="broker_company" id="broker_company" value={broker_company} onChange={onChange} placeholder='중개사 상호명' />
                        </div>
                        <div className='pwipt'>
                            <label>중개사 휴대전화번호</label>
                            <input type="number" name="broker_phone" id="broker_phone" value={broker_phone} onChange={onChange} placeholder='중개사 휴대전화번호' />
                        </div>
                    </div>
                    <div className='divButtons'>
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                        <button onClick={onSave} className="divB-Save">생성 후 문자발송</button>
                    </div>
                </div>
            </div>
        </CreateBrokerBlock>
    );
}

export default CreateBroker;