import React from 'react';
import styled from 'styled-components';

const EnWriteAgreeBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 620px;
        padding: 50px;
        box-sizing: border-box;
    }
    .divAgree {
        margin-bottom: 40px;
        text-align: center;
        white-space: pre-line;
    }
    .divAgree > div {
        margin: 20px 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 0 50px;
    }
    .divAgree > div:nth-child(2) input{
        width: 62%;
        height: 40px;
        text-align: center;
    }
    .divAgree button{
        width: 120px;
        height: 40px;
        color: #ffffff;
        font-size: 15px;
        margin: 0 10px;
        border-radius: 20px;
        border: none;
        cursor: pointer;
    }
    .divAgree > div:nth-child(1) button{
        background-color: #808080;
    }
    .divAgree > div:nth-child(2) button{
        background-color: #1EBF86;
    }
   
    .divButtons{
        padding: 0 30px;
        box-sizing: border-box;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {

        .popupModal > div:nth-child(2){
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 100px 30px;
            overflow: auto;
        }
        h2{
            font-size: 30px !important;
            margin-bottom: 50px;
        }

        .divAgree>div:nth-child(1){
            padding: 0 0px;
        }
        .divAgree>div:nth-child(2){
            padding: 0 0px;
        }
        .divAgree>div:nth-child(3){
            justify-content: center;
        }
        .divButtons{
            width: 100%;
        }
        .divAgree button{
            min-width: 120px;
            margin: 0 0 0 10px;
        }
        .divButtons button{
            width: 50%;
            font-size: 19px;
            margin: 0;
            border-radius: 0;
        }

    }
    .req-true-sample {
        color: white !important;
    }
    .req-false-sample {
        color: #545454 !important;
    }
    .chk-true-sample {
        color: white !important;
    }
    .chk-false-sample {
        color: #545454 !important;
    }
`;

const EnWriteAgree = (props) => {
    const {
        agreeWinner,
        onCloseUserAgree,

        remainSeconds,
        
        onRowChange,
        onCertReq,
        onCertChk,
        onAgreeComplete,
    } = props;

    return (
        <EnWriteAgreeBlock>
            <div className='popupModal'>
                <div />
                <div>
                    <h2>당첨자 동의</h2>
                    {agreeWinner.map((item, idx) => (
                        item.phone && 
                        <div className="divAgree" key={item.idx}>
                            <div>
                                <span>{item.name}</span><span>{item.phone}</span>
                                <button className={!item.agree_complete && (remainSeconds[idx] < 0 || remainSeconds[idx] === 0) ? "req-true-sample" : "req-false-sample"} disabled={item.agree_complete ? "disabled" : ""} onClick={onCertReq.bind(this, item, idx)}>인증번호발급</button>
                            </div>
                            <div>
                                <input type="text" name="agree_code" id="agree_code" value={item.agree_code} disabled={!item.agree_complete && remainSeconds[idx] > 0 ? "" : "disabled"} onChange={onRowChange.bind(this, item)} />
                                <button className={!item.agree_complete && remainSeconds[idx] > 0 ? "chk-true-sample" : "chk-false-sample"} disabled={!item.agree_complete && remainSeconds[idx] > 0 ? "" : "disabled"} onClick={onCertChk.bind(this, item)}>인증번호확인</button>
                            </div>
                            <div>
                                {!item.agree_complete && remainSeconds[idx] > 0 ? "인증번호 유효시간이 " + remainSeconds[idx] + "초 남았습니다." : ""}
                                {!item.agree_complete && remainSeconds[idx] === 0 ? "인증번호 입력 유효시간이 만료되었습니다." : ""}
                                {item.agree_complete ? "인증이 완료되었습니다." : ""}
                            </div>
                        </div>
                    ))}
                    <div className='divButtons'>
                        <button onClick={onCloseUserAgree} className="divB-Cancel">닫기</button>
                        <button onClick={onAgreeComplete} className="divB-Save">동의완료</button>
                    </div>
                </div>
            </div>
        </EnWriteAgreeBlock>
    );
}

export default EnWriteAgree;