import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UserMgt from '../components/UserMgt';
import { addIpRow, changeFile, changeIpValue, changeValue, getCombo, getUsers, getUserView, initialize, removeIpRow, rowChangeValue, saveAuth, saveInfo, saveRegion } from '../modules/userMgt';
import { dataRegion } from '../lib/dataRegion';
import { ALERT } from '../lib/dataWord';

const UserMgtContainer = () => {
    const [isPostback, setIsPostback] = useState(true);             //  최초 조회
    const [isShowDaumPost, setIsShowDaumPost] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const {state: {id}} = location;

    const {
        user,
    } = useSelector(({user}) => ({
        user: user.user,
    }));

    const {
        src_user_auth,
        src_company,
        src_user_id,
        src_user_name,

        user_auth,
        login_date,
        user_id,
        user_pwd,
        user_pwd_check,
        user_phone,
        user_tel,
        user_name,
        user_position,
        addr_no,
        addr,
        addr_dtl,
        owner,
        card,
        reg_no,
        user_company,
        business,
        deduction,
        ipAddr,
        use_flag,
        
        users,
        cboAuth,

        region,
        gungus,
    } = useSelector(({ userMgt }) => ({
        src_user_auth: userMgt.src_user_auth,
        src_company: userMgt.src_company,
        src_user_id: userMgt.src_user_id,
        src_user_name: userMgt.src_user_name,

        user_auth: !userMgt.user_auth ? "" : userMgt.user_auth,
        login_date: !userMgt.login_date ? "" : userMgt.login_date,
        user_id: !userMgt.user_id ? "" : userMgt.user_id,
        user_pwd: !userMgt.user_pwd ? "" : userMgt.user_pwd,
        user_pwd_check: !userMgt.user_pwd_check ? "" : userMgt.user_pwd_check,
        user_phone: !userMgt.user_phone ? "" : userMgt.user_phone,
        user_tel: !userMgt.user_tel ? "" : userMgt.user_tel,
        user_name: !userMgt.user_name ? "" : userMgt.user_name,
        user_position: !userMgt.user_position ? "" : userMgt.user_position,
        addr_no: !userMgt.addr_no ? "" : userMgt.addr_no,
        addr: !userMgt.addr ? "" : userMgt.addr,
        addr_dtl: !userMgt.addr_dtl ? "" : userMgt.addr_dtl,
        owner: userMgt.owner,
        card: userMgt.card,
        reg_no: !userMgt.reg_no ? "" : userMgt.reg_no,
        user_company: !userMgt.user_company ? "" : userMgt.user_company,
        business: userMgt.business,
        deduction: userMgt.deduction,
        ipAddr: userMgt.ipAddr,
        use_flag: userMgt.use_flag,

        users: userMgt.users,
        cboAuth: userMgt.cboAuth,

        region: userMgt.region,
        gungus: userMgt.gungus,
    }));

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowChange = (item, e) => {
        if(+item.app_count > 0) {
            ALERT("결재대기중인 문서가 " + item.app_count + "건 있습니다.\n결재완료 후 변경해주세요.");
            return;
        }

        dispatch(
            rowChangeValue({
                idx: item.user_id,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onFileChange = (e) => {
        const file = e.target.files[0];
        if(!file) return;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            dispatch(
                changeFile({
                    key: e.target.name,
                    fileReader: reader,
                    fileName: file.name,
                    fileExtension: file.type,
                })
            );
        }
    }
    const onRegionChange = (item, e) => {
        let _region = [];
        if(e.target.checked) {
            _region = region.concat(item.idx);
        }
        else {
            _region = region.filter(x => x !== item.idx);
        }

        dispatch(
            changeValue({
                key: 'region',
                value: _region,
            })
        );

        //  권역에 포함되는 군/구 전체 선택(해제)
        const targetRegion = dataRegion.find(x => x.idx === item.idx);
        let _gungus = gungus, isChecked = e.target.checked;
        
        targetRegion.region.forEach(target => {
            if(isChecked) {
                if(_gungus.indexOf(target.gungu) < 0) {
                    _gungus.push(target.gungu);
                }
            }
            if(!isChecked) {
                if(_gungus.indexOf(target.gungu) > -1) {
                    _gungus = _gungus.filter(x => x !== target.gungu);
                }
            }
        });
        dispatch(
            changeValue({
                key: 'gungus',
                value: _gungus,
            })
        );
    }
    const onGunguChange = (item, e) => {
        let _gungus = [];
        if(e.target.checked) {
            _gungus = gungus.concat(item.gungu);
        }
        else {
            _gungus = gungus.filter(x => x !== item.gungu);
        }

        dispatch(
            changeValue({
                key: 'gungus',
                value: _gungus,
            })
        );
    }
    const onSearch = useCallback(() => {
        dispatch(
            getUsers({
                user_auth: src_user_auth,
                company: src_company,
                user_id: src_user_id,
                user_name: src_user_name,
            })
        )
    }, [dispatch, src_user_auth, src_company, src_user_id, src_user_name])
    
    const onSearchClick = () => {
        onSearch();
    }
    //  회원가입 승인
    const onSaveAuthClick = (item) => {
        if (!item.user_auth || item.user_auth === 'NONE') {
            ALERT('적용할 권한을 선택해주세요.');
            return;
        }
        dispatch(
            saveAuth({
                user_auth: item.user_auth,
                user_id: item.user_id,
            })
        );
    }
    const onUserSelect = (item, e) => {
        dispatch(getUserView(item.user_id));
    }
    const onShowDaumPost = () => {
        setIsShowDaumPost(!isShowDaumPost);
    }
    const handlePostCode = (data) => {
        dispatch(changeValue({key: 'addr', value: data.roadAddress,}));
        dispatch(changeValue({key: 'addr_no', value: data.zonecode,}));

        setIsShowDaumPost(!isShowDaumPost);
    }
    const onAddIpRows = () => {
        dispatch(addIpRow());
    }
    const onRemoveIpRows = (item, e) => {
        dispatch(removeIpRow({idx:item.idx}));
    }
    const onChangeIP = (item, e) => {
        dispatch(changeIpValue({
            idx: item.idx,
            key: e.target.name,
            value: e.target.value,
        }))
    }
    //  회원정보 수정
    const onSaveUserClick = (e) => {
        e.preventDefault();

        if(!user_id) {
            ALERT("사용자를 선택해주세요.");
            return;
        }
        if(user_pwd || user_pwd_check) {
            if(!user_pwd) {
                ALERT("비밀번호를 입력해주세요.");
                return;
            }
            if(!user_pwd_check) {
                ALERT("비밀번호 확인을 입력해주세요.");
                return;
            }
            if(!user_pwd !== user_pwd_check) {
                ALERT("비밀번호가 다릅니다. 다시 확인해주세요.");
                return;
            }
        }
        if(!user_phone) {
            ALERT("핸드폰 번호를 입력해주세요.");
            return;
        }

        //  수정할 대상의 권한이 중개사라면 주소는 필수입력.
        if(user_auth === "BROKER") {
            if(!addr || !addr_dtl) {
                ALERT("주소를 모두 입력해주세요.");
                return;
            }
        }

        let ips = '';
        for(let i = 0; i < ipAddr.length; i++) {
            if(ipAddr[i].addr.indexOf(',') > -1) {
                ALERT("콤마(,)는 입력할 수 없습니다.");
                return;
            }
            ips += ipAddr[i].addr + ",";
        }
        ips = ips.slice(0, -1);

        if(!window.confirm("사용자 정보를 변경하시겠습니까?")) {
            return;
        }

        dispatch(saveInfo({
            user_id: user_id,
            user_name: user_name,
            position: user_position,
            user_pwd: user_pwd,
            user_phone: user_phone,
            broker_yn: "Y",
            user_tel: user_tel,
            addr_no: addr_no,
            addr: addr,
            addr_dtl: addr_dtl,
            user_company: user_company,
            reg_no: reg_no,
            owner: owner,
            card: !card.file_name ? card.file_data : card,
            business: !business.file_name ? business.file_data : business,
            deduction: !deduction.file_name ? deduction.file_data : deduction,
            user_auth: "",
            auth_area: "",
            allow_ip: ips,
            use_flag: use_flag,
        }))
    }
    const onSaveRegionClick = () => {
        if(!user_id) {
            ALERT("사용자를 선택해주세요.");
            return;
        }
        dispatch(saveRegion({
            user_id: user_id,
            auth_area: gungus.join(),
        }))
    }

    useEffect(() => {
        if(dispatch && id) {
            dispatch(getCombo({
                main_code: "AUTH",
                admin_type: id==="MENU99"? "SYSTEM" : "USER"
            }));
        }
    }, [dispatch, id])

    useEffect(() => {
        if(isPostback) {
            setIsPostback(false);
            onSearch();
        }
    }, [isPostback, onSearch])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        user,
        
        src_user_auth,
        src_company,
        src_user_id,
        src_user_name,

        login_date,
        user_id,
        user_pwd,
        user_pwd_check,
        user_phone,
        user_tel,
        user_name,
        user_position,
        addr_no,
        addr,
        addr_dtl,
        owner,
        card,
        reg_no,
        user_company,
        business,
        deduction,
        ipAddr,
        use_flag,

        users,
        cboAuth,

        region,
        gungus,
        dataRegion,

        isShowDaumPost,
        handlePostCode,

        onChange,
        onFileChange,
        onRowChange,
        onUserSelect,
        onShowDaumPost,
        onAddIpRows,
        onRemoveIpRows,
        onChangeIP,
        onRegionChange,
        onGunguChange,
        onSearchClick,
        onSaveAuthClick,
        onSaveUserClick,
        onSaveRegionClick,
    }

    return (
        <>
            <UserMgt {...propDatas}/>
        </>
    );
}

export default UserMgtContainer;