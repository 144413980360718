import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import LoginForm from '../components/LoginForm';
import { changeValue, initialize, loginApi, setTimeValue, tempPwd, tempPwdLogin } from '../modules/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import dataURL from '../lib/dataURL';
import { useRef } from 'react';
import { pwValidation } from '../common';
import { ALERT } from '../lib/dataWord';

const LoginContainer = () => {
    const { loginType } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [isTempPwd, setTempPwd] = useState(false);
    const [pwdMsgErr, setPwdMsgErr] = useState('');
    const [pwdMsgScs, setPwdMsgScs] = useState('');
    const [policyModal, setPolicyModal] = useState({show: false, type: 'privacy_policy'});
    const [errMsg, setErrMsg] = useState('');

    const time = useRef(180);
    const interval = useRef(null)
    const pwdRef = useRef();

    const { 
        login, 
        auth, 
        error, 
        loading, 
        setTimeStart,
        loginInfo,
        loginSuccess,
        changePwd,
        pwdChangeSuccess,
        checkSuccess
    } = useSelector(({ auth, loading, user }) => ({
        login: auth.login,
        auth: auth.user_auth,
        error: auth.error,
        loading: loading['auth/LOGIN'],
        setTimeStart: auth.setTimeStart,
        loginInfo: auth.loginInfo,
        loginSuccess: auth.loginSuccess,
        changePwd: auth.changePwd,
        pwdChangeSuccess: auth.pwdChangeSuccess,
        checkSuccess: user.checkSuccess
    }));
    const {input_id, input_pw} = login;

    // 회원가입 이벤트 처리 함수
    const onRegister = () => {
        navigate(dataURL.RegisterPage, {state : {isBroker: false}});
    }

    // 중개사 회원가입 처리 함수
    const onRegBroker = () => {
        navigate(dataURL.RegisterPage, {
            state : {
            isBroker:true
        }});
    }

    // 사용 안되고 있음
    const closeModal = () => {
        setTempPwd(false);
    }

    // 임시 비밀번호 표시를 위한 영역 시작
    const openModal = () => {
        setTempPwd(true);
    }

    /*
    로그인.
    아이디, 비밀번호 체크 후 전달.
    */
    const onLogin = () => {
        if( !input_id ) {
            setErrMsg('아이디를 입력하세요');
        } else if( !input_pw ) {
            setErrMsg('비밀번호를 입력하세요');
        } else {
            setErrMsg('');
            dispatch(loginApi({input_id, input_pw}));
        }
    }

    const onChange = (e, workString) => {
        const {name, value} = e.target
        dispatch(
            changeValue({
                field: workString,
                key: name,
                value
            })
        );
    }

    /*
    1. 입력은 했는지
    2. 값이 동일하긴 한지
    3. 비밀번호 형식이 맞는지
    */
    const onChangeTempPwd = () => {
        // 입력 O
        if(!changePwd.newPwd || !changePwd.newPWd_check) {
            setPwdMsgErr('임시비밀번호 와 임시 비밀번호를 모두 입력해주세요.')
            setPwdMsgScs('')
        }
        // 입력 X
        else {
            // 값 다름
            if(changePwd.newPwd !== changePwd.newPWd_check) {
                setPwdMsgErr('비밀번호와 임시 비밀번호가 동일하지 않습니다.')
                setPwdMsgScs('')
            }
            // 값 동일
            else {
                // 길이
                if(changePwd.newPwd.length < 8) {
                    setPwdMsgErr('비밀번호는 최소 8자리로 입력해야 합니다.')
                    setPwdMsgScs('')
                }
                // 형식
                else if(!pwValidation(changePwd.newPwd)) {
                    setPwdMsgErr('비밀번호는 영어, 숫자, 특수문자가 모두 포함되어야 합니다. 또는 사용할 수 없는 문자열이 포함되어 있습니다.')
                    setPwdMsgScs('')
                }
                else {
                    setPwdMsgErr('')
                    // setPwdMsgScs('비밀번호 변경에 성공했습니다.');
                    dispatch(tempPwdLogin({
                        id: input_id,
                        pw: changePwd.newPwd
                    }))
                    // 비밀번호 변경 및 로그인
                }
            }
        }
    }

    // 당첨자 임시 비밀번호 발급 함수
    const onTempNum = () => {
        // 숫자인지, 전화번호 형식이 맞는지 검사해서 전달.
        if(!input_id) {
            setErrMsg("아이디를 입력하세요")
        }
        //  22.03.25 임시비번 발급은 누가 가능하도록 온결 요청.
        // else if(isNaN(Number(input_id))) {
        //     setErrMsg('아이디에 당첨자 전화번호를 숫자만 입력해주세요')
        // }
        // else if(!isNaN(Number(input_id)) && (input_id.length !== 11 || input_id.slice(0,3) !== '010')) {
        //     setErrMsg('전화번호 형식이 잘못되었습니다')
        // }
        else {
            if(!setTimeStart) {
                dispatch(tempPwd({phone: input_id}));
                pwdRef.current.focus();
            }
            else {
                setErrMsg('3분이 지나고 다시 발급받을수 있습니다');
            }
        }
    }

    const onKeyDownPwd = (e) => {
        if(e.key === "Enter") {
        }
    }

    const onKeyDown = (e) => {
        if(e.key === "Enter") {
            onLogin();
        }
    }

    useEffect(() => {
        if(loginSuccess === 'success') {
            if(loginInfo.temp_pwd_ok) {
                openModal();
            }
            else if (loginInfo.pwd_ok) {
                navigate(dataURL.MainPage, {replace: true});
            }
        }
        else if (loginSuccess === 'fail') {
        }
    }, [loginInfo, loginSuccess, navigate])

    useEffect(() => {
    }, [error])    

    useEffect(() => {
    }, [loading])

    useEffect(() => {
    }, [loginInfo])

    useEffect(() => {
    }, [loginSuccess])
        
    useEffect(() => {
        if(setTimeStart) {
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
            interval.current = setInterval(() => {
                time.current -= 1;
                setMin(parseInt(time.current / 60));
                setSec(time.current % 60);
            }, 1000)
        }
    }, [setTimeStart])

    useEffect(() => {
        if(time.current < 0) {
            ALERT("임시비밀번호의 만료시간이 지났습니다. 다시 발급해주세요");
            setTempPwd(false);
            clearInterval(interval.current);
            dispatch(setTimeValue({
                value: false
            }))
            dispatch(
                changeValue({
                    field: 'changePwd',
                    key: 'newPwd',
                    value: ''
                })
            );
            dispatch(
                changeValue({
                    field: 'changePwd',
                    key: 'newPWd_check',
                    value: ''
                })
            );
            time.current = 180;
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
        }
    }, [sec, dispatch])

    useEffect(() => {
    }, [errMsg]);

    useEffect(() => {
        return () => clearInterval(interval.current);
    }, [])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        if(checkSuccess) {
            if(checkSuccess === "Y") {
                navigate('/main');
            }
        }
    }, [checkSuccess, navigate])

    const propDatas = {
        login,
        auth,
        setTimeStart,
        min, 
        sec,
        isTempPwd,
        changePwd,
        pwdRef,
        pwdMsgScs,
        pwdMsgErr,
        policyModal,
        errMsg : errMsg ? errMsg : error ? error : '',
        pwdChangeSuccess,   // 사용안됨
        loginType,

        onKeyDownPwd,
        onChangeTempPwd,
        closeModal,
        onRegister,
        onRegBroker,
        onLogin,
        onChange,
        onTempNum,
        onKeyDown,
        setPolicyModal,
    }

    return (
        <>
            <LoginForm {...propDatas}/>
        </>
    );
}

export default LoginContainer;