import React from 'react';
import styled from 'styled-components';
import dataWORD from '../lib/dataWord';

const WinnerRegisterWorkBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .tt{
        table-layout: auto
    }
    table {
        font-size: 15px;
        white-space: nowrap;
        text-align: center;
    }

    table th, table td{
        padding: 0 10px;
    }
    .search form{
        width: 100%;
        border: 1px solid black;
        box-sizing: border-box;
        
        padding: 0 20px;
    }
    
    .search .searchBody {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }

    .search .searchBody .ipt{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .search .searchBody .ipt > div {
        display: flex;
        flex-direction: column;
    }

    .search .checkbox {
        align-items: center
    }

    // .fmly_tbl > div {
    //     width: 100%;
    //     max-height: 100px;
    //     overflow-y: scroll;
    //     border: 1px solid black;
    //     position: relative;
    // }

    .fmly_tbl .addBtn {
        display: flex;
        justify-content: center;
        margin: 5px 0;
    }

    .fmly_tbl .addBtn button{
        width: 35px;
        height: 35px;
        font-size: 10px;
        border-radius: 100%;
        white-space: nowrap;
        opacity: 0.3;
    }

    .fmly_tbl .addBtn button:hover{
        opacity: 1;
    }

    .rdonly {
        background-color: #d7d7d7;
    }
    
    .winnerModal .body > div > div label{ color: #545454;}
    .winnerModal .body > div > div select, 
    .winnerModal .body > div > div input{ width: 176px; height: 26px;}

    // .tableCover table thead {
    //     position: sticky;
    //     top: 60px;
    // }
`;

const WinnerRegisterWork = (props) => {
    const {
        winnerList,
        isShow,
        input,
        isModi,
        checkedArr,
        cboApply,
        id,
        isApprove,
        isRequest,
        status,

        value1, 
        value2, 
        // value3, 
        value4, 
        value5, 
        value6, 
        value7, 
        // value8, 
        // value9, 
        value10,
        value11,
        value12,
        value13,
        value14,
        value15,
        value16,
        // value17,
        value18,
        value19,
        value20,
        value21,
        value22,
        value23,
        value24,
        value25,

        pageCount,
        step,
        max,
        currentPage,

        onClickPrev,
        onClickNum,
        onClickNext,
        onChangeInput,
        setIsShow,
        closeModal,
        deleteList,
        saveClose,
        saveWrite,
        onClickModi,
        onClickList,
        onRequest,
        onCheck,
        onChangeCheck,
        onApprove,
        onAddRow,
        onDelRow,
        onChangeRow,
        onClickXl,
        onDel,
    } = props
    return (
        <WinnerRegisterWorkBlock>
            <h1>당첨자 추가</h1>
            <div className='btn-group'>{/* [Dev]22.02.16 btn-group div, class 추가 */}
                {id? 
                    <>
                        {isApprove? <button onClick={onApprove}>최종반영</button> : null}
                        {isApprove? <button onClick={onDel}>삭제</button> : null}
                    </>   
                    :
                    <>
                        <button onClick={onClickXl} className="excel-btn">엑셀 업로드</button>
                        <button onClick={()=>setIsShow(true)}>직접추가하기</button>
                        <button onClick={deleteList}>선택항목 삭제하기</button>
                        {isRequest ? <button onClick={onRequest}>선택항목 등록요청하기</button> : null}
                    </> 
                }
            </div>
            <div className='tableCover'>
                <table className='tt mb0'>{/* [Dev] 21.22.16 mb0 추가 */}
                    <thead>
                        <tr>
                            {id? null : <th><input id='checkAll' type="checkbox" checked={(checkedArr.length === winnerList.length  && checkedArr.length > 0) || ""} onChange={onCheck}/><label htmlFor='checkAll'></label></th>}
                            <th>번호</th>
                            <th>신청유형</th>
                            <th>접수일자(신청일)</th>
                            <th>신청지역</th>
                            <th>성명</th>
                            <th>생년월일</th>
                            <th>주민등록번호</th>
                            <th>핸드폰번호</th>
                            <th>전화번호</th>
                            <th>우편번호</th>
                            <th>주소</th>
                            <th>상세주소</th>
                            <th>신청인유형</th>
                            <th>신청순위</th>
                            <th>세부자격요건</th>
                            <th>수급자자격</th>
                            <th>최종결과</th>
                            <th>지원기간종료일</th>
                            <th>자격심사-기금중복</th>
                            <th>자격심사-유주택</th>
                            <th>자격심사-타임대</th>
                            <th>자격심사-타임대체크</th>
                            <th>비고(대외용)</th>
                            <th>메모(대내용)</th>
                            <th>세대원수</th>
                            <th>범정부</th>
                            <th>혼인여부(신혼)</th>
                            <th>보호조치종료일</th>
                            <th>퇴소자격기한(보호조치)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {winnerList.length > 0? 
                        <>
                            {winnerList.map((item,idx) => {
                                return (
                                    <tr key={idx} onClick={(e) => {onClickList(e, item.winner_id, idx)}} >
                                        {id? 
                                            null 
                                            : 
                                            <td>
                                                <input 
                                                    type="checkbox" 
                                                    id={idx}
                                                    checked={checkedArr.find((el) => el===item.winner_id) || false} 
                                                    onChange={(e) => onChangeCheck(e, item)}/><label htmlFor={idx}></label>
                                            </td>
                                        }
                                        <td>{(30 * (currentPage - 1)) + idx+1}</td>
                                        <td>{item.type_name}</td>
                                        <td>{item.rec_date}</td>
                                        <td>{item.area}</td>
                                        <td>{item.name}</td>
                                        <td>{item.birth}</td>
                                        <td>{item.winner_no? `${item.winner_no.slice(0,6)}-${item.winner_no.slice(6)}` : ''}</td>
                                        {/* <td>{item.winner_no}</td> */}
                                        <td>{item.phone? `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}`: ''}</td>
                                        <td>{item.tel}</td>
                                        <td>{item.addr_no}</td>
                                        <td>{item.addr}</td>
                                        <td>{item.addr_dtl}</td>
                                        <td>{item.win_type1}</td>
                                        <td>{item.win_rank}</td>
                                        <td>{item.dtl_qualification}</td>
                                        <td>{item.recipient_qualify}</td>
                                        <td>{item.final_result}</td>
                                        <td>{item.support_end_date}</td>
                                        <td>{item.audit_fund}</td>
                                        <td>{item.audit_house}</td>
                                        <td>{item.audit_other_rent}</td>
                                        <td>{item.audit_rent_chk}</td>
                                        <td>{item.remark}</td>
                                        <td>{item.memo}</td>
                                        <td>{item.family_cnt}</td>
                                        <td>{item.pan_gov}</td>
                                        <td>{item.marital_status}</td>
                                        <td>{item.protect_end_date}</td>
                                        <td>{item.protect_limit}</td>
                                    </tr>
                                )   
                            })}
                        </> 
                        : <tr>
                            <td colSpan={22}>
                                당첨자가 없습니다.
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>

            {/* 테이블 페이징 */}
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            else {
                                return null;
                            }
                    })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>  

            {/* modal */}
            {isShow? 
                <div className='winnerModal'>
                    <div onClick={closeModal}/>
                    <div>
                        <div className='header'>
                            <div />
                            <h2>{isModi? "수정하기" : "추가하기"}</h2>
                        </div>
                        <div className='body'>
                            <h4 className='right'>필수입력 <span className='greenDot'/></h4>
                            <div>
                                <div>
                                    <label htmlFor='name'>성명<span className='greenDot'/></label>
                                    <input className={value4? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="name" id="name" placeholder="성명" value={input.name || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='birth'>생년월일<span className='greenDot'/></label>
                                    <input className={value5? 'iptErr rdonly' : 'rdonly'} readOnly type="number" name="birth" id="birth" placeholder="생년월일" value={input.winner_no? input.winner_no.slice(0,6) : ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='winner_no'>주민등록번호<span className='greenDot'/></label>
                                    <input className={value6? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="number" name="winner_no" id="winner_no" placeholder="주민등록번호" value={input.winner_no || ""} onChange={onChangeInput} />
                                </div>

                                <div>
                                    <label htmlFor='phone'>휴대전화번호<span className='greenDot'/></label>
                                    <input className={value7? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="number" name="phone" id="phone" placeholder="휴대전화번호" value={input.phone || ""} onChange={onChangeInput} />
                                </div>

                                <div>
                                    <label htmlFor='type'>신청유형<span className='greenDot'/></label>
                                    <select className={value1? 'iptErr' : ''} disabled={status === 'complete'? true : false} name='type' id='type' value={input.type} onChange={onChangeInput}>
                                        <option value={''}>선택하세요</option>
                                        {cboApply.length > 0? 
                                        <>
                                            {cboApply.map((item, idx) => {
                                                return <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                            })}
                                        </>
                                        : null}
                                    </select>
                                </div>

                                <div>
                                    <label htmlFor='win_type1'>신청유형(공급유형)<span className='greenDot'/></label>
                                    <input className={value12? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="win_type1" id="win_type1" placeholder="신청유형" value={input.win_type1 || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='win_rank'>신청순위</label>
                                    <input className={value13? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="win_rank" id="win_rank" placeholder="신청순위" value={input.win_rank || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='rec_date'>접수일자(신청일)</label>
                                    <input className={value2? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="date" name="rec_date" id="rec_date" placeholder="신청일" value={input.rec_date || ""} onChange={onChangeInput}/>
                                </div>

                                {/* <div>
                                    <label htmlFor='addr_no'>우편번호</label>
                                    <input className={value1? 'iptErr' : ''} readOnly={id? true : false} type="text" name="addr_no" id="addr_no" placeholder="우편번호" value={input.addr_no || ""} onChange={onChangeInput}/>
                                </div> */}

                                <div>
                                    <label htmlFor='addr'>주소<span className='greenDot'/></label>
                                    <input className={value10? 'iptErr width2' : 'width2'} readOnly={status === 'complete'? true : false} type="text" name="addr" id="addr" placeholder="주소" value={input.addr || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='addr_dtl'>상세주소</label>
                                    <input className={value11? 'iptErr width2' : 'width2'} readOnly={status === 'complete'? true : false} type="text" name="addr_dtl" id="addr_dtl" placeholder="상세주소" value={input.addr_dtl || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='dtl_qualify'>세부자격요건</label>
                                    <input className={value14? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="dtl_qualify" id="dtl_qualify" placeholder="세부자격요건" value={input.dtl_qualify || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='recipient'>수급자자격</label>
                                    <input className={value15? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="recipient" id="recipient" placeholder="수급자자격" value={input.recipient || ""} onChange={onChangeInput}/>
                                </div>



                                <div>
                                    <label htmlFor='final_result'>최종결과<span className='greenDot'/></label>
                                    <input className={value16? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="final_result" id="final_result" placeholder="최종결과" value={input.final_result || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='support_end_date'>지원가능기간<span className='greenDot'/></label>
                                    <input className={value18? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="date" name="support_end_date" id="support_end_date" placeholder="지원가능기간" value={input.support_end_date || ""} onChange={onChangeInput} max="9999-12-31"/>
                                </div>

                                <div>
                                    <label htmlFor='audit_fund'>기금<span className='greenDot'/></label>
                                    <select disabled={status === 'complete'? true : false} className={value20? 'iptErr' : ''} name="audit_fund" id="audit_fund" value={input.audit_fund || ""} onChange={onChangeInput}>
                                        <option value={''}>선택하세요</option>
                                        <option value={'Y'}>Y</option>
                                        <option value={'N'}>N</option>
                                    </select>
                                    {/* <input  readOnly={status === 'complete'? true : false} type="text" name="audit_fund" id="audit_fund" placeholder="기금" value={input.audit_fund || ""} onChange={onChangeInput}/> */}
                                </div>

                                <div>
                                    <label htmlFor='audit_house'>주택<span className='greenDot'/></label>
                                    <select disabled={status === 'complete'? true : false} className={value19? 'iptErr' : ''} name="audit_house" id="audit_house" value={input.audit_house || ""} onChange={onChangeInput}>
                                        <option value={''}>선택하세요</option>
                                        <option value={'Y'}>Y</option>
                                        <option value={'N'}>N</option>
                                    </select>
                                    {/* <input className={value19? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="audit_house" id="audit_house" placeholder="주택" value={input.audit_house || ""} onChange={onChangeInput}/> */}
                                </div>

                                <div>
                                    <label htmlFor='audit_other_rent'>타임대<span className='greenDot'/></label>
                                    <select disabled={status === 'complete'? true : false} className={value21? 'iptErr' : ''} name="audit_other_rent" id="audit_other_rent" value={input.audit_other_rent || ""} onChange={onChangeInput}>
                                        <option value={''}>선택하세요</option>
                                        <option value={'Y'}>Y</option>
                                        <option value={'N'}>N</option>
                                    </select>
                                    {/* <input className={value21? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="audit_other_rent" id="audit_other_rent" placeholder="타임대" value={input.audit_other_rent || ""} onChange={onChangeInput}/> */}
                                </div>

                                <div>
                                    <label htmlFor="audit_rent_chk">타임대체크</label>
                                    <input className={value22? 'iptErr' : ''} readOnly={status === 'complete'? true : false} type="text" name="audit_rent_chk" id="audit_rent_chk" placeholder="타임대체크" value={input.audit_rent_chk || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor="remark">비고(대외용)</label>
                                    <textarea className={value23? 'iptErr width2 height2' : 'width2 height2'} readOnly={status === 'complete'? true : false} type="text" name="remark" id="remark" placeholder="비고(대외용)" value={input.remark || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor="memo">메모(대내용)</label>
                                    <textarea className={value24? 'iptErr width2 height2' : 'width2 height2'}  readOnly={status === 'complete'? true : false} type="text" name="memo" id="memo" placeholder="비고" value={input.memo || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor="family_cnt">세대원(가구원수기준)<span className='greenDot'/></label>
                                    <input className={value25? 'iptErr rdonly' : 'rdonly'} readOnly type="text" name="family_cnt" id="family_cnt" placeholder="세대원수" value={input.family_cnt || ""}/> 
                                </div>
                            </div>

                            <div>
                                <div>
                                    <label htmlFor="pan_gov">범정부</label>
                                    <input readOnly={status === 'complete'? true : false} type="text" name="pan_gov" id="pan_gov" placeholder="범정부" value={input.pan_gov || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='marital_status'>혼인여부(신혼)</label>
                                    <input readOnly={status === 'complete'? true : false} type="text" name="marital_status" id="marital_status" placeholder="혼인여부(신혼)" value={input.marital_status || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor='protect_end_date'>퇴소일(보호종료)</label>
                                    <input readOnly={status === 'complete'? true : false} type="text" name="protect_end_date" id="protect_end_date" placeholder="퇴소일(보호종료)" value={input.protect_end_date || ""} onChange={onChangeInput}/>
                                </div>

                                <div>
                                    <label htmlFor="protect_limit">퇴소자격기한(보호종료)</label>
                                    <input readOnly={status === 'complete'? true : false} type="text" name="protect_limit" id="protect_limit" placeholder="퇴소자격기한(보호종료)" value={input.protect_limit || ""} onChange={onChangeInput}/> 
                                </div>
                            </div>
                            
                            <div className='fmly_tbl'>
                                <div>
                                    <table className="shadow-none-tt mb5">{/* [Dev] 21.22.16 mb5 추가 */}
                                        <thead>
                                            <tr>
                                                <th>번호</th>
                                                <th>성명</th>
                                                <th>{dataWORD.BIRTHDATE}</th>
                                                <th>신청인과의 관계</th>
                                                {id? null : <th>삭제</th>}
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {input.member_info.length > 0? 
                                            <>
                                                {input.member_info.map((item,idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td>{idx+1}</td>
                                                            <td><input className={value1? 'iptErr' : ''} readOnly={id? true: false} type="text" name="name" id="name" placeholder='이름' value={item.name} onChange={(e) => onChangeRow(e, idx)}/></td>
                                                            <td><input className={value1? 'iptErr' : ''} readOnly={id? true: false} type="number" name="no" id="no" placeholder='주민등록번호' value={item.no} onChange={(e) => onChangeRow(e, idx)}/></td>
                                                            <td><input className={value1? 'iptErr' : ''} readOnly={id? true: false} type="text" name="rel" id="rel" placeholder='신청인과의 관계' value={item.rel} onChange={(e) => onChangeRow(e, idx)}/></td>
                                                            {id? null : <td onClick={() => onDelRow(idx)}>X</td>}
                                                        </tr>
                                                    )
                                                })}
                                            </> : null}
                                        </tbody>
                                    </table>
                                    {status === 'complete'? 
                                        <>
                                            {input.member_info.length > 0? null : <div className='addBtn'>세대원 정보 없음</div>}
                                        </> 
                                        :                                     
                                        <div className='addBtn'>
                                            <button className="add-btn" onClick={onAddRow}>추가</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="pop-group-btn">
                            {/* {[Dev] 22.02.16 pop-group-btn 추가} */}
                            <button onClick={closeModal}>닫기</button>
                            {isModi? 
                                <>
                                    {status === 'complete'? null : <button onClick={onClickModi}>수정하기</button>}
                                </>
                                : 
                                <>
                                    <button onClick={saveClose}>저장하고 닫기</button>
                                    <button onClick={saveWrite}>저장하고 계속등록하기</button>
                                </>
                            }
                        </div>


                    </div>
                </div>
            : null}
        </WinnerRegisterWorkBlock>
    );
}

export default WinnerRegisterWork;