import React, { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { qr } from '../common';
import ImplyRegisterWork from '../components/ImplyRegisterWork';
import dataURL from '../lib/dataURL';
import { ALERT } from '../lib/dataWord';
import { addCheckedArr, changeValue, getCombo, impDelete, impDetailSearch, impRequest, impRequestApprove, impView, impWrite, initialize, initInput, setCheckAll, setIndex, setPaging } from '../modules/Imply';

const ImplyRegisterWorkContainer = () => {
    const [isShowModal, setIsShowModal] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);
    const [isRequest, setIsRequest] = useState(false); // 등록요청
    const [isApprove, setIsApprove] = useState(false) // 최종반영
    const [isModi, setIsModi] = useState(false) // 수정인지, 추가인지 구분.
    const [update, setUpdate] = useState(false) // 수정, 추가이후 새로 업데이트

    // 날짜 변수
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0"+(date.getMonth() + 1)).slice(-2);
    const dateString = `${year}-${month}-${new Date(year, month, 0).getDate()}` // yyyy-mm-dd

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        search,
        maxPageCount,
        pageCount,
        max,
        step,
        currentPage,
        ImplyList,
        input,
        cboApply,
        checkedArr,
        selectedType,
        approveSuccess
    } = useSelector(({Imply}) => ({
        search: Imply.search,
        maxPageCount: Imply.maxPageCount,
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
        ImplyList: Imply.ImplyList,
        input: Imply.input,
        cboApply: Imply.cboApply,
        checkedArr: Imply.checkedArr,
        selectedType: Imply.selectedType,
        approveSuccess: Imply.approveSuccess
    })) 


    // 필수 입력값
    const [value1, setvalue1] = useState(false);    // 지원주택 소재지
    const [value2, setvalue2] = useState(false);    // 생년월일
    const [value3, setvalue3] = useState(false);    // 계약 종료일
    const [value4, setvalue4] = useState(false);    // 임대인
    const [value5, setvalue5] = useState(false);    // 주민등록번호(생년월일우측입력값)
    const [value6, setvalue6] = useState(false);    // 전화번호
    const [value7, setvalue7] = useState(false);    // 전세금
    const [value8, setvalue8] = useState(false);    // 유형
    const [value9, setvalue9] = useState(false);    // 입주자명
    const [value10, setvalue10] = useState(false);  // 지역

    const {state: {func, status, id}} = location

    //----------------------------------------------------------------------
    // 모달

    // 입력값 확인
    const checkInput = () => {
        let valid = true;
        let msg = '';

        // 지원주택 소재지
        if(input.addr === '') {
            valid = false;
            setvalue1(true);
        }
        else {
            setvalue1(false);
        }

        // // 생년월일
        // if(input.birth === '') {
        //     valid = false;           
        //     setvalue2(true);
        // }
        // else {
        //     setvalue2(false);
        // }

        // 계약 종료일
        if(input.contract_end_date === '') {
            valid = false;           
            setvalue3(true);
        }
        else {
            setvalue3(false);
        }

        // 임대인
        if(input.house_owner === '') {
            valid = false;           
            setvalue4(true);
        }
        else {
            setvalue4(false);
        }

        // 주민등록번호(생년월일우측입력값)
        if(input.imply_no === '' || input.imply_no.length < 6) {
            valid = false;           
            setvalue5(true);
            msg += '주민등록번호는 최소 6자리(생년월일)를 입력해야 합니다.\n'
        }
        else {
            setvalue5(false);
        }

        // 전화번호
        if(input.phone === '' || input.phone.length < 11) {
            valid = false;           
            setvalue6(true);
            msg += '핸드폰번호는 11자리 모두 입력해야 합니다.\n'
        }
        else {
            setvalue6(false);
        }

        // 전세금
        if(input.rent_amt === '') {
            valid = false;           
            setvalue7(true);
        }    
        else {
            setvalue7(false);
        }

        // 유형
        if(input.type === '') {
            valid = false;           
            setvalue8(true);
        }
        else {
            setvalue8(false);
        }

        // 입주자명
        if(input.name === '') {
            valid = false;           
            setvalue9(true);
        }
        else {
            setvalue9(false);
        }

        // 지역
        if(input.rec_area === '') {
            valid = false;           
            setvalue10(true);
        }
        else {
            setvalue10(false);
        }

        if(!valid) msg += '필수 입력값이 모두 입력되지 않았습니다.';
        return {valid, msg};
    }

    // 모달이 닫힐때 변수 false, iupuut 값 초기화
    const closeModal = () => {
        setIsShowModal(false)
        dispatch(initInput());

        setvalue1(false)
        setvalue2(false)
        setvalue3(false)
        setvalue4(false)
        setvalue5(false)
        setvalue6(false)
        setvalue7(false)
        setvalue8(false)
        setvalue9(false)
        setvalue10(false)
    }

    // 추가 모달 열때
    const openAddModal = () => {
        setIsShowModal(true);
        setIsModi(false);
    }

    // 수정하기
    const onClickModi = () => {
        const {valid, msg} = checkInput();

        if(valid) {
            dispatch(impWrite({
                ...input,
                rec_date: dateString
            }))
            closeModal();
            setUpdate(true)
        }
        else {
            ALERT(msg)
        }
    }

    // 저장후 닫기
    const saveClose = () => {
        const {valid, msg} = checkInput();
        if(valid) {
            dispatch(impWrite({
                ...input,
                rec_date: dateString
            }))
            setUpdate(true)
            closeModal();
        }
        else {
            ALERT(msg)
        }
    }

    // 저장후 계속작성
    const saveWrite = () => {
        const {valid, msg} = checkInput();

        if(valid) {
            dispatch(impWrite({
                ...input,
                rec_date: dateString
            }))
            dispatch(initInput());
            setUpdate(true)
        }
        else {
            ALERT(msg)
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "search", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 리스트 클릭
    const onClickList = (e, item, idx) => {
        const {target: {children, tagName}} = e

        // 체크박스 제외한 리스트 영역 클릭
        if(tagName === 'TD' && children.length === 0) {
            setIsShowModal(true) // 1. 모달이 보이게.
            setIsModi(true); // 2. 추가가 아닌 수정상태로 변경.
            dispatch(impView({
                imply_id: item.imply_id
            })) // 3. 클릭한 리스트의 묵시대상자 아이디로 대상자 정보 조회.
           dispatch(setIndex({idx}))
            // 4. 인덱스 설정 -> 값 변경하려면 필요 (진짜 필요한지..?)
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 인풋 수정
    const onChangeInput = (e) => {
        const {name, value} = e.target

        let valid = true;

        if(name === 'phone' && value.length > 11) { valid = false; }
        else if(name === 'imply_no' && value.length > 13) { valid = false; }

        if(valid) {
            // 주민등록번호 입력시 생일 자동 저장
            if(name === "imply_no") {
                dispatch(
                    changeValue({
                        field: 'input',
                        key: 'birth',
                        value: value.slice(0,6)
                    })
                );
            }

            dispatch(changeValue({
                field: 'input',
                key: name,
                value
            }))
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 체크박스

    // 체크박스 개별 선택
    const onChangeCheck = (e, item) => {
        const {target: {checked}} = e; // 체크 || 체크 해제인지 확인
        dispatch(addCheckedArr({
            isChecked: checked,
            item: item
        }))
    }

    // 전체 체크 활성, 비활성
    const onCheck = (e) => {
        const {target: {checked}} = e;
        dispatch(setCheckAll({
            isChecked: checked,
        }))
    }

    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 기능 버튼

    // 등록 요청
    const onRequest = () => {
        if(selectedType.length > 1) {
            ALERT("동일한 유형끼리 신청 가능합니다.")
        }
        else if(checkedArr.length < 1){
            ALERT("항목을 선택해주세요")
        }
        else {
            if(window.confirm(`선택항목을 등록 요청하시겠습니까?`)) {
                dispatch(impRequest({
                    type: selectedType[0].type,
                    count: checkedArr.length,
                    imply_id: checkedArr.toString()
                }))
                setUpdate(true)
            }
        }
    }

    // 삭제 요청
    const deleteList = () => {
        if(checkedArr.length < 1){
            ALERT("항목을 선택해주세요")
        }
        else {
            if(window.confirm(`총 ${checkedArr.length}건을 삭제하시겠습니까?`)) {
                dispatch(impDelete({
                    imply_id: checkedArr.toString()
                }))
                setUpdate(true)
            }
        }
    }

    // 최종반영
    const onApprove = () => {
        if(window.confirm('최종등록 하시겠습니까?')) {
            dispatch(impRequestApprove({
                req_id: location.state.id
            }))
        }
    }

    // 엑셀 업로드
    const onClickXl = () => {
        navigate(dataURL.ImplyExcelWorkPage);
    }
    //----------------------------------------------------------------------
    
    // 언마운트
    useEffect(() => {
            return () => dispatch(initialize());
    }, [dispatch])

    // type 조회
    useEffect(() => {
        if(cboApply.length < 1) {
            dispatch(getCombo({main_code: "APPLY_TYPE"}))
        }
    }, [dispatch, cboApply])

    // 쿼리 스트링 구성, 변경, 이동
    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.ImplyRegisterWorkPage}?page=${search.page}`), 
            {
                state: {
                    func: location.state.func, 
                    id: location.state.id,
                    status: status
                },
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, search.page, status])


    // 변경된 url 로 조회.
    useEffect(() => {
        if(dispatch) {
            if(!location.search) {
                setChangeUrl(true)
            }
            else {
                const tempObj = qr(location.search);
                // dispatch(setSearchValue(tempObj))

                dispatch(setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                }))
                dispatch(setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                }))
                dispatch(impDetailSearch({
                    req_id: location.state.id || "",
                    page: tempObj.page
                }))
            }
        }
    }, [dispatch, location])

    // 버튼 구성
    useLayoutEffect(() => {
        if(func.some((el) => el.id === "FUNC021")) {
            setIsRequest(true)
        }
        
        // status 가 request 면 '등록 요청 건' 을 선택한 경우.
        // 이 값이 없으면 요청중인 리스트
        if(status === "request" && func.some((el) => el.id === "FUNC022")) {
            setIsApprove(true)
        }
    }, [status, func])

    // 추가, 수정 후 리스트 업데이트
    useEffect(() => {
        if(update) {
            const tempObj = qr(location.search);
            dispatch(impDetailSearch({
                req_id: location.state.id || "",
                page: tempObj.page
            }))
            setUpdate(false)
        }
    }, [update, location, dispatch])

    useEffect(() => {
        if(navigate) {
            if(approveSuccess) {
                navigate(-1)
            }
        }
    }, [approveSuccess, navigate])

    const propDatas = {
        isShowModal,
        cboApply,
        search,
        maxPageCount,
        pageCount,
        max,
        step,
        currentPage,
        ImplyList,
        isRequest,
        isApprove,
        id,
        status,
        isModi,
        input,
        checkedArr,

        value1,
        value2,
        value3,
        value4,
        value5,
        value6,
        value7,
        value8,
        value9,
        value10,


        closeModal,
        onClickNum,
        onClickNext,
        onClickPrev,
        setIsShowModal,
        onClickList,
        onChangeInput,
        openAddModal,
        saveWrite,
        onClickModi,
        saveClose,
        onChangeCheck,
        onCheck,
        onRequest,
        deleteList,
        onApprove,
        onClickXl,
    }
    return (
        <>
            <ImplyRegisterWork {...propDatas}/>
        </>
    );
}

export default ImplyRegisterWorkContainer;