import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as workAPI from '../lib/api/work';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'maintainRegister/INITIALIZE';
const CHANGE_VALUE = 'maintainRegister/CHANGE_VALUE';
const MODAL_CLEAR = 'maintainRegister/MODAL_CLEAR';
const [GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE] = createRequestActionTypes('maintainRegister/GET_CONTENT');
const SET_PAGING = 'maintainRegister/SET_PAGING';
const [GET_DETAIL, GET_DETAIL_SUCCESS, GET_DETAIL_FAILURE] = createRequestActionTypes('maintainRegister/GET_DETAIL');
const [SAVE_DETAIL, SAVE_DETAIL_SUCCESS, SAVE_DETAIL_FAILURE] = createRequestActionTypes('maintainRegister/SAVE_DETAIL');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const modalClear = createAction(MODAL_CLEAR);
export const getList = createAction(GET_LIST);
export const setPaging = createAction(SET_PAGING, (payload) => payload);
export const getDetail = createAction(GET_DETAIL);
export const saveDetail = createAction(SAVE_DETAIL);

const getListSaga = createRequestSaga(GET_LIST, workAPI.nextwork_search);
const getDetailSaga = createRequestSaga(GET_DETAIL, workAPI.nextwork_view);
const saveDetailSaga = createRequestSaga(SAVE_DETAIL, workAPI.nextwork_write);

export function* maintainRegisterSaga() {
    yield takeLatest(GET_LIST, getListSaga);
    yield takeLatest(GET_DETAIL, getDetailSaga);
    yield takeLatest(SAVE_DETAIL, saveDetailSaga);
}

const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10);

const initialState = {
    isSaveOk: false,    //  저장완료 여부

    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    pageCount: null, // 구성할 페이지 수
    max: 3, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1, // 1 2 3 -> 4 5 6 
    currentPage: 1, // 현재 페이지 (page 의 값이 됨.)
    
    //  검색조건
    date_type: "START_DATE",
    from_date: oneMonthAgo,
    to_date: today,
    name: "",
    area: "",
    page: "1",

    list: [],

    //  등록 Data 보이는 항목
    start_date: '',
    work_name: '',
    work_birth: '',
    phone: '',
    exec_cycle: '',
    addr: '',
    addr_dtl: '',
    estate_no: '',
    house_owner: '',
    end_date: '',
    //  등록 Data 숨겨진 항목
    jibun_addr: '',
    nextwork_id: '',
    sido: '',
    sigungu: '',
    town: '',

    maintainRegister: null,
    error: null
};

const maintainRegister = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        //  리스트 가져오기
        [GET_LIST_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                list: payload,
                isSaveOk: false,
                pageCount: payload.length === 0 ? 0 : Math.ceil(payload[0].tot_count / state.maxPageCount),
            }
        },
        [GET_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                pageCount: null,
                max: 3,
                step: 1,
                currentPage: 1,
                error
            }
        },

        //  페이지 이동
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                        search: {
                            ...state.search,
                            page: (state.step + 1) * state.max - 2
                        }
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1,
                        search: {
                            ...state.search,
                            page: (state.step - 1) * state.max - 2
                        }
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },

        //  등록 모달 초기화
        [MODAL_CLEAR]: (state) => ({
            ...state,
            start_date: '',
            work_name: '',
            work_birth: '',
            phone: '',
            exec_cycle: '',
            addr: '',
            addr_dtl: '',
            estate_no: '',
            house_owner: '',
            end_date: '',

            jibun_addr: '',
            nextwork_id: '',
            sido: '',
            sigungu: '',
            town: '',
        }),

        //  상세 정보 가져오기
        [GET_DETAIL_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                start_date: payload[0].start_date,
                work_name: payload[0].work_name,
                work_birth: payload[0].work_birth,
                phone: payload[0].work_phone,
                exec_cycle: payload[0].exec_cycle,
                addr: payload[0].addr,
                addr_dtl: payload[0].addr_dtl,
                estate_no: payload[0].road_estate_no,
                house_owner: payload[0].house_owner,
                end_date: payload[0].work_end_date,

                jibun_addr: payload[0].jibun_addr,
                nextwork_id: payload[0].nextwork_id,
                sido: payload[0].sido,
                sigungu: payload[0].sigungu,
                town: payload[0].town,
            }
        },
        [GET_DETAIL_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        //  상세 정보 저장하기
        [SAVE_DETAIL_SUCCESS]: (state, { payload }) => {
            ALERT("저장이 완료되었습니다.");
            return {
                ...state,
                isSaveOk: true,

                start_date: '',
                work_name: '',
                work_birth: '',
                phone: '',
                exec_cycle: '',
                addr: '',
                addr_dtl: '',
                estate_no: '',
                house_owner: '',
                end_date: '',

                jibun_addr: '',
                nextwork_id: '',
                sido: '',
                sigungu: '',
                town: '',
            }
        },
        [SAVE_DETAIL_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default maintainRegister;