import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { qr } from '../common';
import ImplyAuditRequestList from '../components/ImplyAuditRequestList';
import dataURL from '../lib/dataURL';
import { apprList, changeValue, initialize, setPaging, setSearch } from '../modules/Imply';

const ImplyAuditRequestListContainer = () => {
    const {
        implyApproveSearch,
        implyApproveList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        user
    } = useSelector(({
        Imply,user
    }) => ({
        implyApproveSearch: Imply.implyApproveSearch,
        implyApproveList: Imply.implyApproveList,
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
        maxPageCount: Imply.maxPageCount,
        user: user.user
    }))
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [update, setUpdate] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);
    const [isAllSearch, setIsAllSearch] = useState(null);

    //----------------------------------------------------------------------
    // 검색
    
    // 등초본 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e
        dispatch(changeValue({
            field: 'implyApproveSearch',
            key: name,
            value
        }))
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "implyApproveSearch", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 리스트 작업

    // 리스트 클릭
    const onClickList = (item) => {
        const {app_id} = item;
        navigate(dataURL.ImplyAuditWorkPage, {
            state: {
                id: app_id,
                status: item.status
            }
        })
    }
    //----------------------------------------------------------------------


    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.ImplyAuditRequestListPage}?from_date=${implyApproveSearch.from_date}&to_date=${implyApproveSearch.to_date}&status=${implyApproveSearch.status}&req_user=${implyApproveSearch.req_user}&page=${implyApproveSearch.page}`), 
            {
                state: {
                    func: location.state.func
                },
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, implyApproveSearch])


    // 변경된 url 로 조회.
    // 최초 한번 수행 후, location 이동에 따라 한번더 실행.
    useEffect(() => {
        if((dispatch && location) && typeof isAllSearch === 'boolean') {
            if(!location.search) {
                setChangeUrl(true)
            }
            else if(location.search){
                const tempObj = qr(location.search);

                dispatch(setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                }))
                dispatch(setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                }))
                dispatch(apprList({
                    ...tempObj,
                    all_search: isAllSearch? "Y" : "N"
                }))
                dispatch(setSearch({
                    search: 'implyApproveSearch',
                    value: tempObj
                }))
            }
        }
    }, [dispatch, location, isAllSearch])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        if(update && dispatch) {
            dispatch(apprList(implyApproveSearch))
            setUpdate(false)
        }
    }, [dispatch, update, implyApproveSearch])

    useEffect(() => {
        if(user) {
            if(user.user_auth === "ADMIN" || user.user_auth === "ENADMIN") {
                setIsAllSearch(true);
            }
            else {
                setIsAllSearch(false);
            }
        }
    }, [user])

    const propDatas = {
        implyApproveSearch,
        implyApproveList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,

        onSubmit,
        onChangeSearch,
        onClickNum,
        onClickNext,
        onClickPrev,
        onClickList,
    };
    return (
        <>
            <ImplyAuditRequestList {...propDatas}/>
        </>
    );
}

export default ImplyAuditRequestListContainer;