import React from 'react';
import styled from 'styled-components';

const LhConfirmBlock = styled.div`
    width: 700px;
    height: 90%;
    position: fixed;
    z-index: 200;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #C5C5C5;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);

    h4 {
        color: #545454;
        font-size: 26px;
        text-align: center;
        margin-bottom: 10px;
    }
    .lblInputFiles {
        padding: 6px 25px;
        background-color: orangered;
        border-radius: 4px;
        color: white;
        cursor: pointer;
    }
    #inputFiles {
        display: none;
    }
    .divImages {
        width: 100%;
        height: 78%;
    }
    .folderBox .folder-on {
        color: #ffffff; 
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }
   
    textarea {
        width: 100%;
        height: 70px;
        margin-top: 10px;
        resize: none;
        border: 1px solid #C5C5C5;
        border-radius: 3px;
    }

    .threeButtons {
        margin-top: 10px;
    }

`;

const LhConfirm = (props) => {
    const {
        folderDiv,
        folderSlider,

        selectFoler,
        docFolder,
        selectImg,
        docImages,
        remark,

        onChange,
        onSelectFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,
        onSaveClick,
        onRejectClick,
        onCloseClick,
    } = props;

    return (
        <LhConfirmBlock>
            <h4>LH 최종확인</h4>
            <div className='divImages'>
                <div id='imgView' className='imgView'>
                    {selectImg && <img id="selectImg" className='selectImg' src={selectImg} draggable={false} alt=''/>}
                    <div className='navImgBox'>
                        {docImages.map((item, idx) => (
                            item.docCode === selectFoler &&
                            <div key={idx} className='navImg'>
                                <img src={item.imgSrc} alt="" onClick={onSelectImg.bind(this, item)}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <div className='folderBox'>
                        <button onClick={onPrevClick}>◀</button>
                        <div ref={folderDiv}>
                            <div className='folderSlider' ref={folderSlider}>
                                {docFolder.map((item) => (
                                    item.sub_code !== "DOCUDF" &&   //미분류 폴더는 제외
                                    <div key={item.sub_code} onClick={onSelectFolder.bind(this, item)} className={item.sub_code === selectFoler ? 'folder-on' : ''}>{item.code_name}</div>
                                ))}
                            </div>
                        </div>
                        <button onClick={onNextClick}>▶</button>
                    </div>
                </div>
            </div>
            <div>
                <textarea name="remark" id="remark" value={remark} onChange={onChange} placeholder='최종 확인 내용을 입력해주세요.'></textarea>
            </div>
            <div className='threeButtons'>
                <button onClick={onSaveClick}>최종 완료</button>
                <button onClick={onRejectClick}>반려</button>
                <button onClick={onCloseClick}>닫기</button>
            </div>
        </LhConfirmBlock>
    );
}

export default LhConfirm;