import React, { useLayoutEffect, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addZoom, qr } from '../common';
import ImplyWork from '../components/ImplyWork';
import dataURL from '../lib/dataURL';
import { ALERT } from '../lib/dataWord';
import { apiRequest, changeValue, impAuditCheck, implyApi, impSave, impSearch, impWorkCheck, initialize, setImpSearch, setPaging } from '../modules/Imply';

const ImplyWorkContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        implySearch,
        impWorkList,
        pageCount,
        max,
        step,
        currentPage,
    } = useSelector(({Imply}) => ({
        implySearch: Imply.implySearch,
        impWorkList: Imply.impWorkList,
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
    }))

    const [target, setTarget] = useState('');
    const [update, setUpdate] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);

    const [selectedImg, setSelectedImg] = useState('');
    const [selectedArr, setSelectedArr] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [noImg, setNoImg] = useState(false);

    const tempId = useRef(null);

    const {state: {implyWork, app_flag}} = location;

    //----------------------------------------------------------------------
    // 기능 버튼

    // 등초본 업로드 (페이지 이동)
    const onAuditResult = () => {
        navigate(dataURL.ImplyResultPage);
    }

    // 등초본 요청 (페이지 이동)
    const onAuditRequest = () => {
        navigate(dataURL.ImplyAuditRequestPage, {
            state: {
                req_id: location.state.id
            }
        })
    }

    // 등초본확인(주소 비교)
    const onAuditCheck = () => {
        if(window.confirm('등초본확인을 하시겠습니까')) {
            dispatch(impAuditCheck({
                req_id: location.state.id
            }))
            setUpdate(true);
        }
    }

    // 등기부 등본 일괄요청
    const onRequestAll = () => {
        if(window.confirm('등기부등본 일괄요청을 하시겠습니까')) {
            dispatch(implyApi({
                api_type: '1',
                request_id: location.state.id
            }))
            setUpdate(true);
        }
    }

    // 등초본 조회
    const onAuditSearch = () => {
        navigate(dataURL.AuditListPage);
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 리스트 마우스 이벤트
    
    // 호버
    const onMouseOver = (e) => {
        const {target: {className}} = e;
        setTarget(className.slice(0,3));
    }

    // 아웃
    const onMouseLeave = () => {
        setTarget('');
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 검색
    
    // 묵시 대상자 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeValue({
            field: 'implySearch',
            key: name,
            value
        }));
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 리스트 작업
    
    // 묵시 대상자 값 변경
    const onChangeImply = (e, idx) => {
        const {target: {name, value}} = e;
        dispatch(changeValue({
            field: 'impWorkList',
            key: name,
            value,
            idx
        }));
    }

    // 대항력 상실, 소유자변동, 압류등 설정 체크박스 이벤트 
    const onCheck = (e, idx) => {
        const {target: {name, checked}} = e;
        dispatch(impWorkCheck({
            name,
            checked,
            idx
        }))
    }
    // 저장하기
    const onSave = (item) => {
        let addr_chk = '';

        if(item.addr_compare === '주소일치') {
            addr_chk = 'OK'
        }
        else if(item.addr_compare === '불일치') {
            if(item.confirm_addr) {
                addr_chk = 'OK'
            }
        }

        dispatch(impSave({
            imply_id: item.imply_id,
            confirm_addr: item.confirm_addr, // 확정주소 예외) 주소일치하면 지원주택소재지,
            addr_chk: addr_chk, // 주소확인 선택되면 OK 아니면? null 예외) 주소일치면 무조건 OK,
            issuing_copy_reg: item.issuing_copy_reg, // 등기부등본 발급여부(Y:발급완료, P:발급요청중, N:미요청)
            contract_type: '', // 계약유형(빈값 고정)
            resistance_loss :item.resistance_loss,	//-- 대항력상실 여부
            owner_change :item.owner_change,		//-- 소유자변경 여부
            seizure_setting :item.seizure_setting,	//-- 압류등설정 여부
            etc: !item.resistance_loss && !item.owner_change && !item.seizure_setting? 'Y' : null,
            imply_ok: item.imply_ok,
            remark: item.remark,
            use_flag: "Y" // -> 전달 받지 않음.
        }))
        setUpdate(true)
    }

    // 등기부등본 요청하기
    // 주소 일치하는건에 대해서만 + 불일치 해도 주소 선택한 건에 대해서만
    const onRequest = (item) => {
        let type = item.issuing_copy_reg;
        // 미요청 (최초 요청시)
        if(type === "N") {
            if(item.addr_compare === '주소일치') {
                // addr_chk = 'OK'
                if(window.confirm('등기부등본을 요청하시겠습니까?')) {
                    dispatch(apiRequest({
                        api_type: '1',
                        doc_id: item.imply_id,
                        doc_step: 'IMPLY',
                    }))
                    setUpdate(true);
                }
            }
            else if(item.addr_compare === '불일치') {
                if(item.confirm_addr) {
                    // addr_chk = 'OK'
                    if(window.confirm('등기부등본을 요청하시겠습니까?')) {
                        dispatch(apiRequest({
                            api_type: '1',
                            doc_id: item.imply_id,
                            doc_step: 'IMPLY',
                        }))
                        setUpdate(true);
                    }
                }
                else {
                    ALERT('주소가 불일치한경우 주소를 선택해야 요청이 가능합니다.')
                }
            }
        }
        // 요청중
        else if(type === "P") {
            ALERT("이미 요청중입니다.")
        }
        // 요청완료
        else if(type === "Y") {
            if(window.confirm('다시 요청하시겠습니까?')) {
                dispatch(apiRequest({
                    api_type: '1',
                    doc_id: item.imply_id,
                    doc_step: 'IMPLY',
                }))
                setUpdate(true);
            }
        }
    }
    //----------------------------------------------------------------------



    //----------------------------------------------------------------------
    // 리스트 우측 이미지 관련

    // 리스트 클릭 -> 등본 이미지 조회
    const onClickList = (item) => {
        // 다른 리스트 클릭하면 바꾼다.
        if(item.fileArr.length > 0) {
            setNoImg(false);
            if(tempId.current !== item.imply_id) {
                tempId.current = item.imply_id;
                if(item.fileArr.length > 0) {
                    setSelectedImg(item.fileArr[0]);
                    setSelectedArr(item.fileArr);
                }
                else {
                    setSelectedImg('');
                    setSelectedArr([]);
                }
            }
        }
        else {
            setNoImg(true);
        }
    }

    // 이미지 선택
    const onSelectImg = (item) => {
        setSelectedImg(item);
    }
    //----------------------------------------------------------------------



    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "implySearch", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------

    // 쿼리 스트링 구성, 변경, 이동
    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.ImplyWorkPage}?request_id=${location.state.id}&date_type=${implySearch.date_type}&from_date=${implySearch.from_date}&to_date=${implySearch.to_date}&name=${implySearch.name}&req_status=${implySearch.req_status}&addr=${implySearch.addr}&page=${implySearch.page}`), 
            {
                state: location.state,
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, location.state.id, implySearch.date_type, implySearch.from_date, implySearch.to_date, implySearch.name, implySearch.req_status, implySearch.addr, implySearch.page])


    // 변경된 url 로 조회.
    useEffect(() => {
        if(dispatch) {
            if(!location.search) {
                setChangeUrl(true)
            }
            else if(location.search.length > 0){
                const tempObj = qr(location.search);

                dispatch(setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                }))
                dispatch(setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                }))
                dispatch(impSearch({
                    ...tempObj,
                    request_id: location.state.id,
                    page: tempObj.page
                }))
                dispatch(setImpSearch({
                    value: tempObj
                }))
            }
        }
    }, [dispatch, location])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        addZoom("")
    }, [selectedImg])

    useEffect(() => {
        if(update && dispatch) {
            dispatch(impSearch(implySearch))
            setUpdate(false)
        }
    }, [dispatch, update, implySearch])

    // 수정권한 설정. (마운트 전에 설정)
    useLayoutEffect(() => {

        /*
        수정권한
        1. 묵시 작업 권한이 있거나
        2. 결재 요청 전이거나
        3. 반려된건이거나 -> 이건 아직 확인 불가능

        이외에는 모두 수정 불가능함.
        */
        if(!implyWork || app_flag === "P" || app_flag === "Y") {
            setIsAuthorized(false);
        }
        else {
            setIsAuthorized(true)
        }
    }, [implyWork,app_flag])

    const propDatas = {
        implySearch,
        impWorkList,
        target,
        pageCount,
        max,
        step,
        currentPage,
        selectedImg,
        selectedArr,
        isAuthorized,
        noImg,

        onSubmit,
        onChangeSearch,
        onAuditRequest,
        onAuditCheck,
        onMouseOver,
        onMouseLeave,
        onAuditResult,
        onClickNum,
        onClickNext,
        onClickPrev,
        onSave,
        onChangeImply,
        onCheck,
        onRequest,
        onClickList,
        onSelectImg,
        onRequestAll,
        onAuditSearch,
    }
    return (
        <>
            <ImplyWork {...propDatas}/>
        </>
    );
}

export default ImplyWorkContainer;