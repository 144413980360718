import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
const MypageBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 30px 160px;
    box-sizing: border-box;
    
    h1{
        color: #545454;
        font-size: 20px;
        margin-bottom: 15px;
    }
   
    .mypage {
        width: 100%;
        height: 87vh;
        display: flex;
        justify-content: center;
        gap: 50px;
    }

    .ipt_cntr, .approve {
        position: relative;
        display: flex;
        justify-content: center;
        border: 1px solid #C5C5C5;
        border-radius: 5px;
        padding: 20px 35px;
        box-sizing: border-box;
        background-color: #ffffff;
    }

    .mypage .ipt_cntr{
        position: relative;
        width: 40%;
        padding: 10px;
        border-radius: 3px;
    }
    .myPage_ipt_body {
        width: 100%;
        height: 88%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        overflow-y: scroll;
        border-bottom: 1px solid #C5C5C5;
        padding-bottom: 20px;
    }

    .myPage_ipt_body .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .myPage_ipt_body .item .ipt_item{
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .myPage_ipt_body .item .ipt_item .readOnly{
        background-color: #dbdbdb;;
    }


    .myPage_ipt_body .item .ipt_item .img{
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 5px; 
        border: 1px solid #0B999C;
    }

    .myPage_ipt_body .item .ipt_item .img img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .myPage_ipt_body .item .button{
        width: 27%;
        display: flex;
        // align-items: flex-start;
        flex-direction: column;
        justify-content: flex-end;
    }
    .myPage_ipt_body .item .file_button{
        width: 27%;
        display: flex;
        align-items: flex-start;
    }

    .myPage_ipt_body .item .file_button label {
        width: 100%;
        height: 38px;
        color: #0B999C;
        border: 1px solid #0B999C;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #ffffff; 
        cursor: pointer;
    }
    .myPage_ipt_body .item .file_button label:hover {
        color: #ffffff;
        background-color: #0B999C;;
    }
    .myPage_ipt_body .item .file_button label:active {
        background-color: #efefef;
    }
    .myPage_ipt_body .item .file_button input {
        display: none;
    }

    .saveBtn{
        height: 10%;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 22px;
        right: 20px;
    }
    .modify-btn{ 
        width: 85px; 
        height: 40px;
        color: #0B999C;
        font-size: 15px;
        display: flex; 
        align-items: center; 
        justify-content: center; 
        border-radius: 5px; 
        border: 1px solid #0B999C; 
        background-color: #ffffff; 
        cursor: pointer;
    }
    .modify-btn:hover{
        color: #ffffff; 
        background-color:#0B999C;
    }
    .ipt_cntr label{
        display: block;
        font-weight: 600;
        margin: 10px 0 3px 0;
    }
    .ipt_cntr input{
        width: 100%;
        height: 38px;
        font-size: 12px;
        border-radius: 5px;
        border: 1px solid #BDBDBD;
        padding: 10px;
        box-sizing: border-box;
    }

    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
    }
    table thead {
        position: sticky;
        top: 0;
        color: #0B9A9C;
        font-size: 15px;
        border-bottom: 1px solid #0B999C;
        background-color: white;
    }
    .searchResult table thead tr:nth-child(1){
        color: #ffffff;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
    }
    table tbody tr:hover {
        background-color: #d7d7d7;
        cursor: pointer;
    }
    table tr{
        height: 36px;
    }
    table td{
        color: #545454;
        font-size: 12px;
        border-bottom: 1px solid #C5C5C5;
    }
    table td:not(:last-child){
        border-right: 1px solid #C5C5C5;
    }

    .mypage .approve {
        width: 60%;
        display: inline;
        border-radius: 3px;
    }
    .approve  .approveHeader {
        width: 100%;
        height: 40px;
        color: #545454;
        font-size: 20px;
    }
    .approveHeader .app_tab:hover {
        text-decoration: underline;
        text-underline-position: under;
    } 

    .approveHeader .app_tab { 
        color: #c3c3c3;
        cursor: pointer;
    }
    .approveHeader .on {
        color: black;
        text-decoration: underline;
        text-underline-position: under;
    }
    .approve  .approveBody {
        // position: absolute;
        width: 100%;
        height: 40%;
        display: flex;
        box-sizing: border-box;
        gap: 15px;
    }
    .approve  .approveFooter {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
    }
    .approveFooter .btnrow1,
    .approveFooter .btnrow2{
        width: 50%;
    }
    .approveFooter .btnrow2{
        display: flex;
        justify-content: space-between;
    }
    .approveFooter .btnrow2 button{
        height: 40px; 
        font-size: 15px;
        border-radius: 5px;
        cursor: pointer;
    }
    .approveFooter .btnrow2>div>button{
        width: 85px;
        border: 1px solid #C5C5C5;
        margin: 0 10px;
        background-color: #ffffff;
    }
    .btnrow2>button:last-child{
        width: 110px;
        color: #ffffff;
        border: none;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
    }

    .approve .approveBody .searchSection ,
    .approve .approveBody .viewSection {
        width: 50%;
    }

    .approve .approveBody .searchSection {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .approve-search form{
        width: 300px;
        position: relative;
        margin-bottom: 10px;
    }
    .approve-search form input{
        width: 100%;
        height: 40px;
        font-size: 12px;
        border-radius: 20px;
        border: 1px solid #C5C5C5;
        padding: 10px;
        box-sizing: border-box;
    }
    .approve-search .sch-btn{
        position: absolute;
        top: 6px;
        right: 6px;
    }
    .searchSection .searchResult{
        height: 100%;
    }
    .searchResult, .viewSection{
        border: 1px solid #C5C5C5;
        border-radius: 5px;
        box-sizing: border-box;
        overflow-y: scroll;
    }
    .approve .approveBody .viewSection tbody .selected{
        background-color: #d7d7d7;
    }


    .winnerModal>div:nth-child(2){
        width: 500px;
    }
    .winnerModal .body{
        margin-bottom: 50px;
    }
    .winnerModal .body>div{
        margin-bottom: 10px;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .winnerModal .body>div>label{
        width: 30%;
        font-size: 14px;
    }
    .winnerModal .body>div>input{
        width: 60%;
    }

    .winnerModal .phoneDesc {
        flex-direction: column;
    }
    .winnerModal .phoneDesc p {
        font-size: 13px;
    }

    .winnerModal .body .right {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
    }

    .winnerModal .body .red{
        color: red;
    }
    .winnerModal .body .green{
        color: green;
    }

    .divButtons{
        padding: 0 20px;
        box-sizing: border-box;
    }
    
    

    @media screen and (min-width: 360px) and (max-width: 1023px){
        padding: 0;
        .mypage{
            height: 100%;
        }
        .mypage .ipt_cntr{
            width: 100%;
        }
        .myPage_ipt_body {
            height: 100%;
            padding-bottom: 100px;
            box-sizing: border-box;
            overflow: hidden;
            border-bottom: 0;
            // padding-bottom: 0px;
        }
        .ipt_cntr label{
            font-size: 14px;
        }
        .squre-bdradius-btn{
            font-size: 12px;
            padding: 0;
        }
        .saveBtn{
            bottom: 22px;
            right: 50%;
            margin-right: -43px;
        }
        .winnerModal > div:nth-child(2){
            width: 100%;
            height: 100%;
            border-radius: 0;
            // padding: 100px 0px 0 0px;
            overflow: hidden;
        }
        .winnerModal>div:nth-child(2) > .header{
            margin: 30px 0;
        }
        .winnerModal .body > div{
            justify-content: center;
        }
        .winnerModal .body>div>label{
            font-size: 11px;
            min-width: 100px;
        }
        .winnerModal .body .right{
            padding-right: 0;
        }
        .pop-group-btn{
            width: 100%;
        }
        .divButtons{
            padding: 0;
        }
        .divButtons button{
            width: 50%;
            font-size: 16px;
            margin: 0px !important;
            border-radius: 0px; 
        }
    }
    

`;

const Mypage = (props) => {
    const {
        user,
        userInfo,
        result,
        target,
        user_pwd,
        user_pwd_check,
        searchApprove,
        isAppAuth,
        loading,
        isPwdModal,
        user_pwd_prev,
        isPhoneModal,
        setTimeStart,
        min,
        sec,
        certSuccess,
        cboApply,
        app_check,
        app_normal,

        onOpen,
        onClose,
        onChange,
        onUp,
        onDown,
        onSaveUsrInfo,
        onSaveApvInfo,
        onAdd,
        onTarget,
        onChangePwd,
        onSearch,
        onFileChange,
        onChangeApp,
        // ChangePwd,
        checkPwd,
        onComplete,
        onCloseDaum,
        onOpenDaum,
        isShowDaum,
        opOpenPhoneModal,
        onClosePhoneModal,
        onClickRequestCert,
        onClickCompareCert,
        onClickSavePhone,
    } = props

    return (
        <MypageBlock>
            {userInfo ? 
                <div className='mypage'>
                    <div className='ipt_cntr'>
                        <div className='myPage_ipt_body'>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='user_id'>아이디</label>
                                    <input type={"text"} readOnly name='user_id' id='user_id' value={user.user_auth==='WINNER'? userInfo.user_phone || "" : userInfo.user_id || ""} onChange={onChange} className="readOnly"/>
                                </div>
                                <div className='button'>
                                    <button className="squre-bdradius-btn" onClick={onOpen}>비밀번호 변경</button>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='user_phone'>핸드폰번호</label>
                                    <input placeholder='숫자만입력하세요' className='readOnly' readOnly type={"number"} name='user_phone' id='user_phone' value={userInfo.user_phone || ""} onChange={onChange}/>
                                </div>
                                <div className='button'>
                                    <button className="squre-bdradius-btn" onClick={opOpenPhoneModal}>핸드폰번호 변경</button>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='user_name'>성명</label>
                                    <input placeholder='' type={"text"} name='user_name' id='user_name' value={userInfo.user_name || ""} onChange={onChange}/>
                                </div>
                            </div>

                            {/* 당첨자만 */}
                            {user.user_auth === "WINNER"?
                            <>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='cust_type'>유형</label>
                                    <select name='cust_type' id='cust_type' value={userInfo.cust_type || ''} onChange={onChange}>
                                        <option value={''}>선택하세요</option>
                                        {cboApply.length > 0? 
                                        <>
                                        {cboApply.map((item, idx) => {
                                            return <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                        })}
                                        </> : null}
                                    </select>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='user_tel'>전화번호</label>
                                    <input placeholder='숫자만입력하세요' type={"number"} name='user_tel' id='user_tel' value={userInfo.user_tel || ""} onChange={onChange}/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='account_no'>계좌번호</label>
                                    <input placeholder='숫자만입력하세요' type={"number"} name='account_no' id='account_no' value={userInfo.account_no || ""} onChange={onChange}/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='account_owner'>예금주</label>
                                    <input placeholder='숫자만입력하세요' type={"text"} name='account_owner' id='account_owner' value={userInfo.account_owner || ""} onChange={onChange}/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='bank_name'>은행</label>
                                    <input placeholder='숫자만입력하세요' type={"text"} name='bank_name' id='bank_name' value={userInfo.bank_name || ""} onChange={onChange}/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='addr_no'>우편번호</label>
                                    <input placeholder='주소검색을 해주세요' type={"text"} name='addr_no' className='readOnly' readOnly  id='addr_no' value={userInfo.addr_no || ""} onChange={onChange}/>
                                </div>
                                <div className='button'>
                                    <button className="squre-bdradius-btn" onClick={onOpenDaum}>주소검색</button>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='addr'>주소</label>
                                    <input placeholder='주소검색을 해주세요' type={"text"} name='addr' className='readOnly' readOnly  id='addr' value={userInfo.addr || ""} onChange={onChange}/>
                                </div>
                            </div>
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='addr_dtl'>상세주소</label>
                                    <input placeholder='' type={"text"} name='addr_dtl' id='addr_dtl' value={userInfo.addr_dtl || ""} onChange={onChange}/>
                                </div>
                            </div>
                            </> 
                            : null}
                            {user.user_auth !== "WINNER"? 
                            <>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='card'>명함업로드</label>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <div className='img'>
                                            <label htmlFor='card'>
                                                <img src={userInfo.card ? userInfo.card.file_data : ""} alt="card"/>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='file_button'>
                                        <label htmlFor='card'>파일선택</label>
                                        <input placeholder='' type="file" accept='image/*' name='card' id='card' onChange={onFileChange}/>
                                    </div>
                                </div>
                            </>
                            : null}
                            {user.user_auth === 'BROKER'? 
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='reg_no'>사업자등록번호</label>
                                        <input placeholder='숫자만입력하세요' type={"number"} name='reg_no' id='reg_no' value={userInfo.reg_no || ""} onChange={onChange}/>
                                    </div>
                                </div>
                            : null}
                            {user.user_auth !== "WINNER"?
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='user_company'>소속</label>
                                    <input placeholder='소속' type={"text"} name='user_company' id='user_company' value={userInfo.user_company || ""} onChange={onChange}/>
                                </div>
                            </div>
                            : null}
                            {user.user_auth === 'BROKER' || user.user_auth === "WINNER"?
                            null
                            :
                            <div className='item'>
                                <div className='ipt_item'>
                                    <label htmlFor='user_position'>직책</label>
                                    <input placeholder='소속' type={"text"} name='user_position' id='user_position' value={userInfo.user_position || ""} onChange={onChange}/>
                                </div>
                            </div>}
                            {user.user_auth === 'BROKER'? 
                            <>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='user_tel'>전화번호</label>
                                        <input placeholder='숫자만입력하세요' type={"number"} name='user_tel' id='user_tel' value={userInfo.user_tel || ""} onChange={onChange}/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='addr_no'>우편번호</label>
                                        <input placeholder='우편번호' type={"text"} name='addr_no' id='addr_no' value={userInfo.addr_no || ""} onChange={onChange}/>
                                    </div>
                                    <div className='button'>
                                        <button className="squre-bdradius-btn" onClick={onOpenDaum}>주소 검색</button>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='addr'>주소</label>
                                        <input readOnly placeholder='주소' type={"text"} name='addr' id='addr' value={userInfo.addr || ""} onChange={onChange}/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='addr_dtl'>상세주소</label>
                                        <input readOnly placeholder='상세주소' type={"text"} name='addr_dtl' id='addr_dtl' value={userInfo.user_company || ""} onChange={onChange}/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor='owner'>중개사대표</label>
                                        <input placeholder='중개사대표' type={"text"} name='owner' id='owner' value={userInfo.owner? userInfo.owner || "" : userInfo.user_name || ''} onChange={onChange}/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor=''>사업자등록증 업로드</label>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <div className='img'>
                                            <label htmlFor='business'>
                                                <img src={userInfo.business? userInfo.business.file_data : ""} alt="business"/>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='file_button'>
                                        <label htmlFor='business'>파일선택</label>
                                        <input type="file" accept='image/*' name='business' id='business' onChange={onFileChange}/>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <label htmlFor=''>공제증서 업로드</label>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='ipt_item'>
                                        <div className='img'>
                                            <label htmlFor='deduction'>
                                                <img src={userInfo.deduction? userInfo.deduction.file_data : ""} alt="deduction"/>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='file_button'>
                                        <label htmlFor='deduction'>파일선택</label>
                                        <input type="file" accept='image/*' name='deduction' id='deduction' onChange={onFileChange}/>
                                    </div>
                                </div>
                            </>
                            : null}
                        </div>
                        <div className='item saveBtn'>
                            <div className='ipt_item'>
                                
                            </div>
                            <div className='button'>
                                <button onClick={onSaveUsrInfo} className="modify-btn">수정</button>
                            </div>
                        </div>
                    </div>

                    {isAppAuth?                     
                        <div className='approve'>
                            <div className='approveHeader'>
                                <h4>결재라인 수정</h4>
                            </div>
                            <div className='approve-search'>
                                <form onSubmit={onSearch}>
                                    <input name='searchApprove' id='searchApprove' value={searchApprove} placeholder='성명' onChange={onChangeApp}/>
                                    <button className='sch-btn'>검색</button>
                                </form>
                            </div>
                            
                            <div className='approveBody' style={{marginBottom:'15px'}}>
                                <div className='searchSection'>
                                    <div className='searchResult'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={"2"}>검토자</th>
                                                </tr>
                                                <tr>
                                                    <th>권한</th>
                                                    <th>성명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.map((item, idx) => {
                                                    return (
                                                        item.app_auth === 'APPROVAL' &&
                                                        <tr key={idx} onClick={() => onAdd(item)}>
                                                            <td>{item.auth_name}</td>
                                                            <td>{item.user_name}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='viewSection'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>순서</th>
                                                <th>권한</th>
                                                <th>성명</th>
                                                <th>삭제</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading === true ? 
                                                <tr>
                                                    <td colSpan={"4"}>Loaing</td>
                                                </tr>
                                                :
                                                <>
                                                    {
                                                        app_normal.length === 0 &&
                                                        <tr>
                                                            <td colSpan={"4"}>등록된 결재라인이 없습니다.</td>
                                                        </tr>
                                                    }
                                                    {app_normal.map((item, idx) => {
                                                        return (
                                                            <tr className={target && target.targetType === 'APPROVAL' && idx===target.targetIdx? "selected" : ""} key={idx} onClick={(e) => onTarget(e, idx, item)}>
                                                                <td>
                                                                    {idx + 1}
                                                                </td>
                                                                <td>{item.auth_name}</td>
                                                                <td>{item.app_name}</td>
                                                                <td className='del'>{"X"}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='approveBody'>
                                <div className='searchSection'>
                                    <div className='searchResult'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={"2"}>결재확인자</th>
                                                </tr>
                                                <tr>
                                                    <th>권한</th>
                                                    <th>성명</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.map((item, idx) => {
                                                    return (
                                                        item.app_auth === 'APPCHECK' &&
                                                        <tr key={idx} onClick={() => onAdd(item)}>
                                                            <td>{item.auth_name}</td>
                                                            <td>{item.user_name}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='viewSection'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>순서</th>
                                                <th>권한</th>
                                                <th>성명</th>
                                                <th>삭제</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading === true ? 
                                                <tr>
                                                    <td colSpan={"4"}>Loaing</td>
                                                </tr>
                                                :
                                                <>
                                                    {
                                                        app_check.length === 0 &&
                                                        <tr>
                                                            <td colSpan={"4"}>등록된 결재확인자가 없습니다.</td>
                                                        </tr>
                                                    }
                                                    {app_check.map((item, idx) => {
                                                        return (
                                                            <tr className={target && target.targetType === 'APPCHECK' && idx===target.targetIdx? "selected" : ""} key={idx} onClick={(e) => onTarget(e, idx, item)}>
                                                                <td>
                                                                    {idx + 1}
                                                                </td>
                                                                <td>{item.auth_name}</td>
                                                                <td>{item.app_name}</td>
                                                                <td className='del'>{"X"}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div className='approveFooter'>
                                <div className='btnrow1' />
                                <div className='btnrow2'>
                                    <div>
                                        <button onClick={onUp}>위로</button>
                                        <button onClick={onDown}>아래로</button>
                                    </div>
                                    <button onClick={onSaveApvInfo}>저장하기</button>
                                </div>
                            </div>
                        </div>
                    : null}

                </div>
            : null}
            {isPwdModal?
                <div className='winnerModal'>
                    <div className='backGround' onClick={onClose}/>
                    <div>
                        <div className='header'>
                            <div />
                            <h2>비밀번호 변경</h2>
                        </div>
                        <div className='body'>
                            <div>
                                <label htmlFor='user_pwd_prev'>기존 비밀번호</label>
                                <input type={"password"} name='user_pwd_prev' id='user_pwd_prev' value={user_pwd_prev || ""} onChange={onChangePwd}/>
                            </div>
                            <div>
                                <label htmlFor='user_pwd'>새로운 비밀번호</label>
                                <input type={"password"} name='user_pwd' id='user_pwd' value={user_pwd || ""} onChange={onChangePwd}/>
                            </div>
                            <div>
                                <label htmlFor='user_pwd_check'>새로운 비밀번호확인</label>
                                <input type={"password"} name='user_pwd_check' id='user_pwd_check' value={user_pwd_check || ""} onChange={onChangePwd}/>
                            </div>
                        </div>
                        <div className='divButtons'>
                            <button onClick={onClose} className="divB-Cancel">닫기</button>
                            <button onClick={checkPwd} className="divB-Save">변경하기</button>
                        </div>
                    </div>
                </div>
            : null}

            {isPhoneModal?
            <div className='winnerModal'>
                <div className='backGround' onClick={onClosePhoneModal}/>
                <div>
                    <div className='header'>
                        <div />
                        <h2>핸드폰번호 변경</h2>
                    </div>
                    <div className='body'>
                        <div>
                            <label htmlFor='new_phone'>새 전화번호</label>
                            <input type={"number"} name='new_phone' id='new_phone' placeholder='숫자만입력하세요' value={userInfo.new_phone || ""} onChange={onChange}/>
                            <button className='save-btn' onClick={onClickRequestCert} style={{width:'120px'}}>인증번호 발급</button>
                        </div>
                        <div>
                            <label htmlFor='cert_num'>인증번호</label>
                            <input type={"text"} name='cert_num' id='cert_num' value={userInfo.cert_num || ""} onChange={onChange}/>
                            <button className='save-btn' onClick={onClickCompareCert} style={{width:'120px'}}>인증번호 확인</button>
                        </div>
                        {setTimeStart?
                            <div className='right'>
                                <p className={min === 0 && sec <= 30? 'red' : 'green'}>
                                    {
                                        (min <= 0 && sec <= 0) ?
                                            '인증번호 시간이 만료되었습니다' :
                                            '인증번호 남은시간 ' + min + ' : ' + ('0'+sec).slice(-2)
                                    }
                                </p>
                                {((min > 0 || sec > 0) && certSuccess === 'fail' && <p className='red'>인증번호가 일치하지 않습니다.</p>)}
                            </div>
                        : null}
                        {certSuccess === 'success' && <p className='right green'>인증번호 확인이 완료되었습니다.</p>}
                        <br/>
                        <div className='phoneDesc'>
                            <p>*핸드폰번호 변경은 새로 인증을 받아야 합니다.</p>
                            {user.user_auth === "WINNER"? <p style={{color: 'red'}}>*당첨자는 핸드폰 번호가 변경되면 변경된 핸드폰 번호로 로그인해야합니다.</p> : null}
                        </div>
                    </div>
                    <div className='divButtons'>
                        <button onClick={onClosePhoneModal} className="divB-Cancel">닫기</button>
                        <button onClick={onClickSavePhone}className="divB-Save">변경</button>
                    </div>
                </div>
            </div>
            : null}

            {isShowDaum? 
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={onComplete}/>
                    <button className='bdnone-cancel-btn' onClick={onCloseDaum}>닫기</button>
                </div>
            :null}
        </MypageBlock>
    );
}

export default Mypage;