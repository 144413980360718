import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'applyMgt/INITIALIZE';
const CHANGE_VALUE = 'applyMgt/CHANGE_VALUE';
const ROW_CHANGE_VALUE = 'applyMgt/ROW_CHANGE_VALUE';
const ADD_ROW = 'applyMgt/ADD_ROW';
const REMOVE_ROW = 'applyMgt/REMOVE_ROW';
const SORT_ROW = 'applyMgt/SORT_ROW';
const [GET_CODE, GET_CODE_SUCCESS, GET_CODE_FAILURE] = createRequestActionTypes('applyMgt/GET_CODE');
const [SAVE_CODE, SAVE_CODE_SUCCESS, SAVE_CODE_FAILURE] = createRequestActionTypes('applyMgt/SAVE_CODE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const addRow = createAction(ADD_ROW);
export const removeRow = createAction(REMOVE_ROW, ({idx}) => ({idx}));
export const sortRow = createAction(SORT_ROW, ({display, type}) => ({display, type}));
export const getCode = createAction(GET_CODE, (body) => (body));
export const saveCode = createAction(SAVE_CODE, (subcodes) => ({subcodes}));

const getCodeSaga = createRequestSaga(GET_CODE, adminAPI.code_search);
const saveCodeSaga = createRequestSaga(SAVE_CODE, adminAPI.code_reg);

export function* applyMgtSaga() {
    yield takeLatest(GET_CODE, getCodeSaga);
    yield takeLatest(SAVE_CODE, saveCodeSaga);
}

const initialState = {
    applyList: [],

    applyMgt: null,
    error: null
};

const applyMgt = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _applyList = state.applyList.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                applyList: _applyList,
            };
        },
        [ADD_ROW]: (state) => {
            let lastSort = 0;
            state.applyList.forEach(list => {
                if(list.display > lastSort) {
                    lastSort = list.display;
                }
            });
            return {
                ...state,
                applyList: state.applyList.concat({
                    idx: lastSort + 1,
                    main_code: 'APPLY_TYPE',
                    sub_code: '',
                    code_name: '',
                    memo: '0',
                    display: lastSort + 1,
                    use_flag: 'Y',
                })
            };
        },
        [REMOVE_ROW]: (state, { payload: {idx}}) => {
            const _applyList = state.applyList.filter((item) => item.idx !== idx);
            return {
                ...state,
                applyList: _applyList,
            };
        },
        [SORT_ROW]: (state, { payload: {display, type}}) => {
            let _applyList = state.applyList;
            
            //  최상위에서 UP, 최하위에서 DOWN이면 Action 종료
            if((type === "UP" && display === 1) || (type === "DOWN" && display === state.applyList.length)) {
                return {
                    ...state,
                    applyList: _applyList,
                };
            }

            const alreadyList = state.applyList.find(x => x.display === display + (type === "UP" ? -1 : 1));
            const currList = state.applyList.find(x => x.display === display);

            let _beforeSort = _applyList.map((item) => {
                if(item.idx === currList.idx) {
                    return {
                        ...item,
                        display: item.display + (type === "UP" ? -1 : 1),
                    }
                }
                else if(item.idx === alreadyList.idx) {
                    return {
                        ...item,
                        display: item.display + (type === "UP" ? 1 : -1),
                    }
                }
                else {
                    return {
                        ...item
                    }
                }
            });
            _beforeSort.sort((a, b) => {
                return a.display - b.display;
            });
            _applyList = _beforeSort;

            return {
                ...state,
                applyList: _applyList,
            };
        },
        [GET_CODE_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                applyList: payload,
            }
        },
        [GET_CODE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [SAVE_CODE_SUCCESS]: (state, {payload}) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_CODE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default applyMgt;