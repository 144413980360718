import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as adminAPI from '../../lib/api/admin';
import * as entitleAPI from '../../lib/api/entitle';
import * as addrdocAPI from '../../lib/api/addrdoc';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enDocUpload/INITIALIZE';
const CHANGE_VALUE = 'enDocUpload/CHANGE_VALUE';
const ADD_ROW = 'enDocUpload/ADD_ROW';
const REMOVE_ROW = 'enDocUpload/REMOVE_ROW';
const ROW_CHANGE_VALUE = 'enDocUpload/ROW_CHANGE_VALUE';
const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('enDocUpload/GET_CONTENT');
const [ADDR_WRITE, ADDR_WRITE_SUCCESS, ADDR_WRITE_FAILURE] = createRequestActionTypes('enDocUpload/ADDR_WRITE');
const [SAVE_ADDR_CHK, SAVE_ADDR_CHK_SUCCESS, SAVE_ADDR_CHK_FAILURE] = createRequestActionTypes('enDocUpload/SAVE_ADDR_CHK');
const [GET_DOC_TYPE, GET_DOC_TYPE_SUCCESS, GET_DOC_TYPE_FAILURE] = createRequestActionTypes('enDocUpload/GET_DOC_TYPE');
const [FILE_WRITE, FILE_WRITE_SUCCESS, FILE_WRITE_FAILURE] = createRequestActionTypes('enDocUpload/FILE_WRITE');
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('enDocUpload/SAVE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const addRow = createAction(ADD_ROW, ({subCode, codeName}) => ({subCode, codeName}));
export const removeRow = createAction(REMOVE_ROW, ({subCode, idx}) => ({subCode, idx}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, fileReader, fileName, fileExtension}) => ({idx, fileReader, fileName, fileExtension}));
export const getContent = createAction(GET_CONTENT);
export const addrWrite = createAction(ADDR_WRITE, (body) => (body));
export const saveAddrChk = createAction(SAVE_ADDR_CHK);
export const getDocType = createAction(GET_DOC_TYPE);
export const fileWrite = createAction(FILE_WRITE, (body) => (body));
export const save = createAction(SAVE, (body) => (body));

const getContentSaga = createRequestSaga(GET_CONTENT, entitleAPI.enfile_view);
const addrWriteSaga = createRequestSaga(ADDR_WRITE, addrdocAPI.addr_write);
const saveAddrChkSaga = createRequestSaga(SAVE_ADDR_CHK, addrdocAPI.useyn_update);
const getDocTypeSaga = createRequestSaga(GET_DOC_TYPE, adminAPI.doc_search2);
const fileWriteSaga = createRequestSaga(FILE_WRITE, addrdocAPI.file_write);
const saveSaga = createRequestSaga(SAVE, entitleAPI.enwrite4);

export function* enDocUploadSaga() {
    yield takeLatest(GET_CONTENT, getContentSaga);
    yield takeLatest(ADDR_WRITE, addrWriteSaga);
    yield takeLatest(SAVE_ADDR_CHK, saveAddrChkSaga);
    yield takeLatest(GET_DOC_TYPE, getDocTypeSaga);
    yield takeLatest(FILE_WRITE, fileWriteSaga);
    yield takeLatest(SAVE, saveSaga);
}

const initialState = {
    doc_type: [],
    contract_doc_type: [],
    fileLastIdx: 0,
    fileList: [],

    reqAddr: '',
    buildingStateNo: '',
    resultAddrs: [],
    buildingType: '',   // 정제주소의 건물 구분

    isFileWriteOK: false,   // 파일요청 완료 여부 (true시 내용 다시 호출)
    
    enDocUpload: null,
    error: null
};

const enDocUpload = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, fileReader, fileName, fileExtension } }) => {
            const _fileList = state.fileList.map((item) =>
                item.idx === idx ? {
                    ...item,
                    file_name: fileName,
                    file_extension: fileExtension,
                    file_data: fileReader.result,
                } : item,
            );
            return {
                ...state,
                fileList: _fileList,
            };
        },
        [ADD_ROW]: (state, { payload }) => {
            const _fileLastIdx = state.fileLastIdx + 1;
            return {
                ...state,
                fileLastIdx: _fileLastIdx,
                fileList: state.fileList.concat({
                            idx: _fileLastIdx,
                            file_id: '',
                            file_type: payload.subCode,
                            file_type_name: payload.codeName,
                            file_name: '',
                            file_extension: '',
                            file_data: '',
                            use_flag: "Y",
                            api_rec_date: null,
                            api_req_date: null,
                            api_yn: "N",
                            rotation: "0",
                        }),
            }
        },
        [REMOVE_ROW]: (state, { payload: {subCode, idx}}) => {
            const _fileList = state.fileList.map((item) =>
                item.file_type === subCode && item.idx === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );
            return {
                ...state,
                fileList: _fileList,
            };
        },
        [GET_CONTENT_SUCCESS]: (state, { payload }) => {
            let _fileList = [];
            payload.files.forEach(doc => {
                doc.files.forEach(item => {
                    _fileList.push({
                        ...item,
                        file_data: !item.file_data ? "" : item.file_data,
                    });
                });
            });

            return ({
                ...state,
                reqAddr: payload.search_addr[0].address,
                buildingStateNo: payload.search_addr[0].building_state_no,
                resultAddrs: payload.use_addr,
                buildingType: payload.use_addr.length === 0 ? "" : payload.use_addr.find(x => x.building_type === '2' || x.building_type === '3').building_type,
                fileLastIdx: _fileList.length,
                fileList: _fileList,
                isFileWriteOK: false,
                error: null,
            })
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                isFileWriteOK: false,
                error,
            })
        },
        [ADDR_WRITE_SUCCESS]: (state, { payload }) => {
            ALERT("주소정제가 요청되었습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [ADDR_WRITE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
        [SAVE_ADDR_CHK_SUCCESS]: (state, { payload }) => {
            const _resultAddrs = state.resultAddrs.map((item) =>
                item.id === payload.use_id ? { ...item, is_use_yn: payload.is_use_yn } : item,
            );
            return ({
                ...state,
                resultAddrs: _resultAddrs,
                error: null,
            })
        },
        [SAVE_ADDR_CHK_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
        [GET_DOC_TYPE_SUCCESS]: (state, {payload}) => {
            let _payload = [];
            payload.forEach(item => {
                if(item.use_flag === 'Y') {
                    _payload.push({
                        code: item.sub_code,
                        name: item.code_name,
                    })
                }
            });

            return {
                ...state,
                doc_type: _payload,
            }
        },
        [GET_DOC_TYPE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [FILE_WRITE_SUCCESS]: (state, { payload }) => {
            ALERT("문서가 요청되었습니다.");
            return ({
                ...state,
                isFileWriteOK: true,
                error: null,
            })
        },
        [FILE_WRITE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                isFileWriteOK: false,
                error,
            })
        },
        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT('저장되었습니다.');
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
    },
    initialState,
);

export default enDocUpload;