import client from './client';

export const imply_write = (body) =>
    client.post('/api/imply/imply_write', body);
    
export const request = (body) =>
    client.post('/api/imply/request', body);
    
export const req_search = (body) =>
    client.post('/api/imply/req_search', body);

export const detail_search = (body) =>
    client.post('/api/imply/detail_search', body);

export const imply_view = ({imply_id}) =>
    client.get(`/api/imply/imply_view/${imply_id}`);
    
export const req_approve = (body) =>
    client.post('/api/imply/req_approve', body);
    
export const imply_delete = (body) =>
    client.post('/api/imply/imply_delete', body);

export const result_write = (body) =>
    client.post('/api/imply/result_write', body);

export const result_search = (body) =>
    client.post('/api/imply/result_search', body);

export const imply_search = (body) =>
    client.post('/api/imply/imply_search', body);

export const audit_req = (body) =>
    client.post('/api/imply/audit_req', body);

export const audit_chk = ({req_id}) =>
    client.get(`/api/imply/audit_chk/${req_id}`);

export const imply_save = (body) =>
    client.post('/api/imply/imply_save', body);

export const imply_main = (body) =>
    client.post('/api/imply/imply_main', body);

export const appr_req = (body) =>
    client.post('/api/imply/appr_req', body);

export const appr_list = (body) =>
    client.post('/api/imply/appr_list', body);

export const imply_api = (body) =>
    client.post('/api/imply/imply_api', body);

export const appr_dtl_list = ({appr_id}) =>
    client.get(`/api/imply/appr_dtl_list/${appr_id}`);

export const approve = (body) =>
    client.post('/api/imply/approve', body);

export const imply_upload = (body) =>
    client.post('/api/imply/imply_upload', body);

export const rpt_search = (body) =>
    client.post('/api/imply/rpt_search', body);
    
export const report_reg = (body) =>
    client.post('/api/imply/report_reg', body);

export const rpt_print = (report_id) =>
    client.get(`/api/imply/rpt_print/${report_id}`);

export const lh_audit_req = (body) =>
    client.post('/api/imply/lh_audit_req', body);
    
export const imp_req_search = (body) =>
    client.post('/api/imply/imp_req_search', body);