import React from 'react';
import styled from 'styled-components';

const FindBrokerBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 800px;
        box-sizing: border-box;
        padding: 60px 50px;
    }

    .sbrokerinfo{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .sbrokerinfo>div{
        display: flex;
        align-items: center;
    }
    .sbrokerinfo>div>label{
        color: #545454;
        margin-right: 4px;
    }
    .sbrokerinfo>div>input{
        width: 130px;
        height: 26px;
        color: #545454;
    }
    
    .divTbl {
        height: 260px;
        overflow-y: auto;
        border: 1px solid #bdbdbd;
        border-radius : 3px;
    }
    .divTbl table {
        width: 100%;
        border-collapse: collapse;
        font-size: 12px;
    }
    .divTbl table th, .divTbl table td {
        text-align: center;
        min-width: 80px;
    }
    .divTbl tbody tr:hover {
        background-color: #ccc;
        cursor: pointer;
    }
    .bdnone-cancel-btn{
        position: absolute;
        top: 20px;
        right: 0;
        font-size: 24px;
    }
`;

const FindBroker = (props) => {
    const {
        brokerName,
        brokerNumber,
        brokerTelno,
        brokers,
        onCloseFindBroker,

        onChange,
        onSearch,
        onSelected,
    } = props;

    return (
        <FindBrokerBlock>
            <div className='popupModal'>
                <div />
                <div>
                    <h2>공인중개사 검색</h2>
                    <div className='sbrokerinfo'>
                        <div>
                            <label htmlFor="brokerName">업체명</label>
                            <input type="text" name="brokerName" id="brokerName" value={brokerName} onChange={onChange} />
                        </div>
                        <div>
                            <label htmlFor="brokerNumber">사업자등록번호</label>
                            <input type="text" name="brokerNumber" id="brokerNumber" value={brokerNumber} onChange={onChange} />
                        </div>
                        <div>
                            <label htmlFor="brokerTelno">전화번호</label>
                            <input type="text" name="brokerTelno" id="brokerTelno" value={brokerTelno} onChange={onChange} />
                        </div>

                        <button onClick={onSearch} className="pop-sch-btn">검색</button>
                    </div>

                    <div className='divTbl'>
                        <table className='tt'>
                            <thead>
                                <tr>
                                    <th>업체명</th>
                                    <th>대표자</th>
                                    <th>사업자등록번호</th>
                                    <th>전화번호</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    brokers.length === 0 &&
                                    <tr>
                                        <td colSpan={4}>검색 결과가 없습니다.</td>
                                    </tr>
                                }
                                {brokers.map((item, idx) => (
                                    <tr key={idx} onClick={onSelected.bind(this, item)}>
                                        <td>{item.comp_name}</td>
                                        <td>{item.name}</td>
                                        <td>{item.reg_no}</td>
                                        <td>{item.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button onClick={onCloseFindBroker} className="bdnone-cancel-btn">X</button>
                </div>
            </div>
        </FindBrokerBlock>
    );
}

export default FindBroker;