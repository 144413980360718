import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Main from '../components/Main';
import { dataSTATUS } from '../lib/dataURL';
import { ALERT } from '../lib/dataWord';
import { getStatus } from '../modules/main';
// import { getMenu } from '../modules/auth';
import { check, getMainCount, search } from '../modules/user';

const MainContainer = () => {
    const [isShowUpload, setIsShowUpload] = useState(null);
    const [isShowCreateBroker, setIsShowCreateBroker] = useState(null);
    const {
        user, 
        menu, 
        userInfo, 
        status, 
        getMenuSuccess,
        winnerWidget
    } = useSelector(({ main,user }) => ({ 
        user: user.user,
        menu: user.menu,
        userInfo: user.userInfo,
        status: main.status,
        getMenuSuccess: user.getMenuSuccess,
        winnerWidget: user.winnerWidget,
    }))
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 리스트 페이지 전체조회, 전체 검색 범위 설정 값.
    // const [listValue, setListValue] = useState(null); 

    // 검색 조건용 날짜
    const toDate = new Date();
    let fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 5); // 검색범위 최근3개월 -> 6개월로 수정

    const onReqPhotoClick = () => {
        setIsShowUpload('PHOTOREQUEST');
    }

    const onCreateBrokerClick = () => {
        setIsShowCreateBroker(true);
    }

    const onClick = (item) => {
        if(item.id === "MENU00") {
            if(winnerWidget.length > 0) {
                navigate(`${item.router}`, {
                    state: {
                        func: item.func,
                        auth: user.user_auth,
                        docNo: winnerWidget[0].doc_id
                    }
                })
            }
            else {
                ALERT('당첨정보가 없어 신청서로 이동할 수 없습니다.')
            }
        }
        else {
            if(item.router === 'CREATE_BROKER') {
                onCreateBrokerClick();
            }
            else if(item.router) {
                // 어드민
                if(item.router === "/admin") {
                    navigate(`${item.router}`, {
                        state: {
                            func: item.func,
                            id: item.id
                        }
                    })
                }
                // 일반 
                else {
                    const type = item.router.split('/')[2];
                    if(item.router.includes('/list/')) {
                        let listValue = null;
                        switch (type) {
                            // 신청서현황
                            case dataSTATUS.APPLY:
                                listValue = {
                                    all_search: item.func.some((el) => el.id==="FUNC061")? "Y" : "N",
                                    types: "ENWRITE,ENSAVE,ENREQ,ENACK/ENCHGREQ/ENPROCESS/ENAPVREQ/ENAPVFAIL/ENMODIFY,ENDOCFAIL,ENDOCADD,ENAPVOK,CONTSTRT"
                                }
                                break;
                
                            // 권리분석요청 현황
                            case dataSTATUS.ENTITLE:
                                listValue = { 
                                    all_search: "Y",
                                    types: "ENREQ,ENACK,ENCHGREQ,ENDOCFAIL,ENDOCADD,ENPROCESS,ENAPVREQ,ENAPVFAIL,ENAPVOK,ENMODIFY,NONCONF,ENCANCEL,ENAPVCHK,ENAPVCHKFAIL"
                                }
                                break;
                
                            // 피드백
                            case dataSTATUS.FEEDBACK:
                                listValue = {
                                    all_search: item.func.some((el) => el.id==="FUNC081")? "Y" : "N",
                                    types: "ENREQ,ENACK,ENCHGREQ,ENDOCFAIL,ENDOCADD,ENPROCESS",
                                    feedback_status: "P,Y"
                                }
                                break;
                                // 결재대기
                            case dataSTATUS.APPROVE:
                                listValue = {
                                    all_search: user.user_auth === "ADMIN" || user.user_auth === "ENADMIN"? "Y" : "N",
                                    // all_search: user.user_auth === "Y",
                                    types: "ENAPVREQ,ENAPVOK,ENAPVCHK"
                                }
                                break;
                            
                            // 계약대기
                            case dataSTATUS.CONTRACT:
                                listValue = {
                                    all_search: "Y",
                                    types: "ENAPVCHK,CONTSTRT,CONTOK,CONTFAIL"
                                }
                                break;
                                // 최종확인
                            case dataSTATUS.CONFIRM:
                                listValue = {
                                    all_search: "Y",
                                    types: "CONTOK"
                                }
                                break;
                                // 사전관리
                            case dataSTATUS.PREMAINTAIN:
                                listValue = {
                                    all_search: "Y",
                                    types: "NONE"
                                }
                                break;
                                // 사후관리
                            case dataSTATUS.NEXTMAINTAIN:
                                listValue = {
                                    all_search: "Y",
                                    types: "NONE"
                                }
                                break;
                                // 현장조사
                            case dataSTATUS.FIELDCHK:
                                listValue = {
                                    all_search: "Y",
                                    types: "CONFIRM"
                                }
                                break;
                            default:
                                break;
                        }
                        // 리스트면 검색 조건 쿼리스트링으로 여기서 전달.
                        // 날짜는 여기서 초기화
                        // all_search 와 status 는 여기서 초기화
                        const _areaArr = user.auth_area.split(',');
                        navigate(encodeURI(`${item.router}?date_type=${type === "contract" ? "APP_DATE" : type === "approve" ? "REQ_DATE" : ""}&from_date=${fromDate.format('yyyy-MM-dd')}&to_date=${toDate.format('yyyy-MM-dd')}&type=${''}&status=${type === 'approve'? 'ENAPVREQ,ENAPVOK,ENAPVCHK' : listValue.types}&user_type=${'WINNER'}&user=${''}&all_search=${listValue.all_search}&page=${'1'}&feedback_status=${'P'}&area=${user.auth_area}&contract_type=${''}&isfirst=${type === dataSTATUS.ENTITLE ? 'Y' : 'N'}`), {
                            state: {
                                func: item.func,
                                auth: user.user_auth,
                                listValue: listValue,
                                statusArr: [],
                                applyTypeArr: [],
                                cboAreaData: _areaArr.map((data) => ({
                                    area: data,
                                    checked: true
                                })),
                                name: item.name
                            }
                        })
                    }
                    else {
                        navigate(`${item.router}`, {
                            state: {
                                func: item.func,
                                auth: user.user_auth
                            }
                        })
                    }
                }
            }
            else {
                onReqPhotoClick() 
            }
        }
    }
    
    useEffect(() => {
        if(user) {
            // dispatch(getMenu());
            dispatch(search({
                user_id: user.user_id,
                user_name: "",
                user_auth: "",
            }))
        }
    }, [dispatch,user])
    
    useEffect(() => {
        dispatch(check());
        dispatch(getStatus("STATUS"));
    }, [dispatch])
    
    useEffect(() => {
        if(getMenuSuccess === true && dispatch) {
            if(menu.some((el) => el.id === "MENU06" && el.func.some((subEl) => subEl.id === "FUNC061"))) {
                dispatch(getMainCount({all_search: "Y"}));
            }
            else {
                dispatch(getMainCount({all_search: "N"}));
            }
        }
    }, [getMenuSuccess, dispatch, menu])

    const propDatas = {
        isShowUpload,
        isShowCreateBroker,
        menu,
        userInfo,
        status,
        winnerWidget,

        setIsShowUpload,
        onReqPhotoClick,
        setIsShowCreateBroker,
        onClick,
    }
    return (
        <>
            <Main {...propDatas}/>
        </>
    );
}

export default MainContainer;