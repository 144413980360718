import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnDocFail from '../../components/Modal/EnDocFail';
import { ALERT } from '../../lib/dataWord';
import { saveApproval, changeValue, initialize, setStatusApi } from '../../modules/Modal/enDocFail';

//  서류미비 사유입력
//  결재반려 사유입력
//  결재반려 확인
//  결재반려사유 확인
//  부적합 만료 사유입력
//  담당자 변경요청 사유입력
//  피드백 요청 내용 입력

const EnDocFailContainer = (props) => {
    const {
        docNo,
        doc_status,
        user,
        entitle_user,
        reject_reason,
        isShowDocFail,
        setIsShowDocFail,
        onDocFailComplete,
        onRejectComplete,
        onRejectCheckComplete,
        onNonConfComplete,
        onUserChangeComplete,
        onFeedbackComplete,
    } = props;

    const dispatch = useDispatch();
    const {
        lack_reason,
    } = useSelector(({ enDocFail }) => ({
        lack_reason: enDocFail.lack_reason,
    }));

    let rejectRemark;
    if (isShowDocFail === "REJECT_CHECK") {
        rejectRemark = reject_reason;
    }

    useEffect(() => {
        return () => {
            //  언마운트시 초기화
            dispatch(initialize());
        };
    }, [dispatch])
    
    useEffect(() => {
        dispatch(
            changeValue({
                key: 'lack_reason',
                value: rejectRemark,
            })
        );
    }, [dispatch, rejectRemark])

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onSave = () => {
        if (isShowDocFail === "ENDOCFAIL") {
            if(!lack_reason) {
                ALERT("서류 미비 사유를 입력해주세요.");
                return;
            }

            if (window.confirm("서류 미비를 저장하시겠습니까?")) {
                dispatch(
                    setStatusApi({
                        doc_id: docNo,
                        status: "ENDOCFAIL",
                        remark: "",
                        lack_reason: lack_reason,
                    })
                )
                onClose();
                onDocFailComplete(lack_reason);
            }
        }
        else if (isShowDocFail === "REJECT") {
            if(!lack_reason) {
                ALERT("결재 반려 사유를 입력해주세요.");
                return;
            }

            if (window.confirm("결재를 반려하시겠습니까?")) {
                dispatch(saveApproval({
                    doc_id: docNo,
                    app_auth: "APPROVAL",
                    app_status: "R",
                    app_opinion: lack_reason,
                }))
                onClose();
                onRejectComplete(lack_reason);
            }
        }
        else if (isShowDocFail === "APPREJECT") {
            if(!lack_reason) {
                ALERT("결재확인 반려 사유를 입력해주세요.");
                return;
            }

            if (window.confirm("결재를 반려하시겠습니까?")) {
                dispatch(saveApproval({
                    doc_id: docNo,
                    app_auth: "APPCHECK",
                    app_status: "R",
                    app_opinion: lack_reason,
                }))
                onClose();
                onRejectComplete(lack_reason);
            }
        }
        else if (isShowDocFail === "REJECT_CHECK") {
            if (window.confirm("반려사유를 확인하셨습니까?")) {
                dispatch(
                    setStatusApi({
                        doc_id: docNo,
                        status: "ENACK",
                        remark: "",
                        lack_reason: "",
                    })
                )
                onClose();
                onRejectCheckComplete();
            }
        }
        else if (isShowDocFail === "NONCONF") {
            if(!lack_reason) {
                ALERT("부적합 만료 사유를 입력해주세요.");
                return;
            }

            if (window.confirm("신청서를 부적합으로 만료하시겠습니까?")) {
                dispatch(
                    setStatusApi({
                        doc_id: docNo,
                        status: "NONCONF",
                        remark: lack_reason,
                        lack_reason: "",
                    })
                )
                onClose();
                onNonConfComplete();
            }
        }
        else if (isShowDocFail === "ENCHGREQ") {
            if(!lack_reason) {
                ALERT("담당자 변경 사유를 입력해주세요.");
                return;
            }
            if (window.confirm("권리분석 담당자 변경을 요청하시겠습니까?")) {
                dispatch(
                    setStatusApi({
                        doc_id: docNo,
                        status: "ENCHGREQ",
                        remark: lack_reason,
                        lack_reason: "",
                    })
                )
                onClose();
                onUserChangeComplete();
            }
        }
        else if (isShowDocFail === "FEEDBACK") {
            if (window.confirm("피드백을 요청하시겠습니까?")) {
                onClose();
                onFeedbackComplete(lack_reason);
            }
        }
    }
    const onClose = () => {
        setIsShowDocFail(null);
    }

    const propDatas = {
        doc_status,
        user,
        entitle_user,
        lack_reason,

        isShowDocFail,

        onChange,
        onSave,
        onClose,
    }
    return (
        <>
            <EnDocFail {...propDatas}/>
        </>
    );
}

export default EnDocFailContainer;