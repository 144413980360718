import React, { useState } from 'react';
import ExcelWork from '../components/WinnerExcelWork';
import * as XLSX from 'xlsx';
import { delNewLine } from '../common';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { changeExcel, getCombo, initialize, setExcel, setExcelReady, winnerWrite_Excel } from '../modules/WinnerWork';
import { useNavigate } from 'react-router-dom';
import { ALERT } from '../lib/dataWord';

const WinnerExcelWorkContainer = () => {
    const {
        winnerExcel,
        winnerExcelHeader,
        winnerExcelHeader_true,
        winnerExcelSend,
        excelReady,
        cboApply,
        winnerExcelUploadSuccess,
    } = useSelector(({WinnerWork}) => ({
        winnerExcel: WinnerWork.winnerExcel,
        winnerExcelHeader: WinnerWork.winnerExcelHeader,
        winnerExcelHeader_true: WinnerWork.winnerExcelHeader_true,
        winnerExcelSend: WinnerWork.winnerExcelSend,
        excelReady: WinnerWork.excelReady,
        cboApply: WinnerWork.cboApply,
        winnerExcelUploadSuccess: WinnerWork.winnerExcelUploadSuccess,
    }))

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fileState, setFileState] = useState(0);
    const [winnerExcelNodata, setwinnerExcelNodata] = useState({}); // 필수입력값의 위치가 담길 객체
    const [fileValue, setFileValue] = useState('');

    // 엑셀 업로드 설정값
    // 당첨자 엑셀은 첫번째 시트, 첫번째 행 첫번째 열 부터 읽는다고 가정함.
    const opt = {
        defval: "", 
        raw: false, 
        dateNF: "YYYY-MM-DD",
    }

    // db반영
    const onUpload = () => {
        if(winnerExcel.length < 1) {
            ALERT("업로드할 데이터가 없습니다.")
        }
        else {
            let data_error = dataCheck(winnerExcel, winnerExcelHeader_true); // 입력값 다시 확인

            // 입력값 확인되면
            if(!data_error) {
                // 데이터를 전송하기전 값 정제
                dispatch(setExcelReady({
                    excel: winnerExcel,
                    cboApply
                }))
            }
        }
    }

    // 엑셀값 변경
    const onChangeValue = (e, idx) => {
        const {target: {name, value}} = e;
        dispatch(changeExcel({
            key: name, 
            value,
            idx 
        }))
    }

    // 엑셀
    /*
    220308_[법]신세기_전산개발_테스트명단_실데이터_29732309 (1)
    내부 엑셀 파일 양식 참고
    */

    //----------------------------------------------------------------------
    // 값을 redux에 저장하기전 정제하는 작업
    // 기금, 주택, 타임태입력값 체크
    const checkType1 = (str) => {
        if(
            str === 'y' ||
            str === 'Y' ||
            str === 'n' ||
            str === 'N'
        ) {
            return true;
        }
        else {
            return false;
        }
    }
    //----------------------------------------------------------------------

    const onChange = (e) => {
        const input = e.target;
        setFileValue(e.target.value);

        let fileName = e.target.value;
        let fileLen = fileName.length;
        let lastDot = fileName.lastIndexOf('.');
        let fileExt = fileName.substring(lastDot + 1, fileLen);

        // 엑셀만 작업수행
        if(fileExt === 'xlsx' || fileExt === 'xls') {
            let temp_excelData = null;
            let temp_excelHeader = null;
            let temp_excelHeader_true = null;

            const reader = new FileReader();
            setFileState(reader.readyState);

            reader.readAsBinaryString(input.files[0]);
            setFileState(reader.readyState);

                reader.onload = () => {
                let fileData = reader.result;

                //Excel 작업 시작.
                let wb = null;

                try {
                    wb = XLSX.read(fileData, {type:'binary', cellDates:true, cellNF: false, cellText:false});
                } catch(e) {
                    // 비밀번호
                    if(e.message.indexOf('password-protected') !== -1) {
                        ALERT("엑셀파일이 비밀번호로 잠겨있어서 업로드할 수 없습니다.")
                    }
                    else {
                        ALERT('엑셀파일을 읽는데 에러가 발생했습니다. 엑셀을 확인해 주세요');
                    }
                    setFileValue('');
                    setFileState(0);
                }
                
                // 엑셀을 정상적으로 읽으면 wb 값이 존재함.
                if(wb) {
                    // 엑셀의 첫번째 시트만 읽는다고 가정함.
                    temp_excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], opt);
    
                    // 특정 값 정제작업
                    // 주민번호에서 생년월일 추출 -> 백엔드 처리로 변경
                    // 지원가능기간 yyyy-mm-dd~yyyy-mm-dd 에서 뒷부분만 추출
                    // 기금, 주택, 타임대 -> 대문자로 변환
                    temp_excelData = temp_excelData.map((item) => {
                        return ({
                            ...item,
                            // '주민번호\r\n(생년월일)': item['주민번호\r\n(생년월일)'].slice(0,6),
                            '지원가능기간': item['지원가능기간']? item['지원가능기간'].slice(-10) : '',
                            '기금': checkType1(item['기금'])? item['기금'].toUpperCase() : '',
                            '주택': checkType1(item['주택']) ? item['주택'].toUpperCase() : '',
                            '타임대': checkType1(item['타임대']) ? item['타임대'].toUpperCase() : '',
                        })
                    })
    
                    // 실제 값을 읽기위한 header(key) 값을 배열로 추출.
                    // '헤더' 로 읽은 데이터를 저장.
                    temp_excelHeader_true = Object.keys(temp_excelData[0]);
    
                    // 테이블에 값을 보여주기 위해 실제 엑셀값을 추출(첫번째 한줄만 읽는다).
                    // '헤더' 로 읽는게 아니기 때문에 같은 값이라도 _1, _2 가 붙지 않는다. -> 출력용으로 적합.
                    // \r\n 특수문자는 존재한다.
                    let wbH = XLSX.read(fileData, {type: 'binary', sheetRows: 1});
                    temp_excelHeader = XLSX.utils.sheet_to_json(wbH.Sheets[wbH.SheetNames[0]], {header: 1,});
    
                    // 실제 엑셀 헤더에서 특수문자를 제거해서 데이터테이블 출력용으로 변경
                    temp_excelHeader = temp_excelHeader[0].map((item) => {
                        return delNewLine(item);
                    })
    
                    // 당첨자 엑셀은 56개 항목을 가진다.
                    if(temp_excelHeader_true.length !== 56) {
                        ALERT('당첨자 엑셀의 형식이 아닙니다. 엑셀을 확인해주세요')
                        setFileState(0);
                    }
                    else {
                        // 리덕스에 값 저장.
                        dispatch(setExcel({
                            key: 'winnerExcel',
                            value: temp_excelData,
                            header: temp_excelHeader_true
                        }))
                        dispatch(setExcel({
                            key: 'winnerExcelHeader',
                            value: temp_excelHeader
                        }))
                        dispatch(setExcel({
                            key: 'winnerExcelHeader_true',
                            value: temp_excelHeader_true
                        }))
                        // 필수 입력값 확인
                        dataCheck(temp_excelData, temp_excelHeader_true);
                        setFileState(reader.readyState);
                    }
                }
            }

            reader.onerror = (err) => {
            }
        }
        else {
            ALERT('엑셀 파일만 업로드 할 수 있습니다.')
        }
    }

    // 날짜 데이터 확인
    /*
    길이가 10인지
    앞 4자리가 숫자인지
    중간 2자리가 숫자인지
    끝 2자리가 숫자인지
    --> 모두 통과해야한다
    */
    const is_correctDate = (dataValue) => {
        let valid = true;

        if( dataValue.length !== 10 ||
            isNaN(Number(dataValue.slice(0, 4))) === true ||
            isNaN(Number(dataValue.slice(5, 7))) === true ||
            isNaN(Number(dataValue.slice(8, 10))) === true) {
                valid = false;
        }
        return valid;
    }
    // 필수 입력값 유무 확인 (값이 있는지만 확인함)
    /*
    신청유형(공급유형), 신청순위(자격요건) 데이터를 이용해서
    실제 지원유형을 찾는데 없는경우 빈값이 할당된다.
    */
    const dataCheck = (excel, header) => {
        setwinnerExcelNodata({});
        let temp = {};
        let msg = '';

        let errNoValues = [];   // 오류가 있는 행의 index를 보관
        let errDateValids = [];   // 오류가 있는 행의 index를 보관
        let errFamCntValids = [];   // 오류가 있는 행의 index를 보관
        let errBirthValids = [];   // 오류가 있는 행의 index를 보관
        
        // 필수 데이터는 있는지
        excel.forEach((item, idx) => {
            // 필수 입력값
            // 신청유형(공급유형)
            if(item.type === '') {
                temp['type'] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 성명
            if(item[header[0]] === '') {
                temp[`${idx},0`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 생년월일 
            if(item[header[1]] === '') {
                temp[`${idx},1`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 생년월일 길이, 숫자 체크
            if(
                item[header[1]].length < 6 
                // || isNaN(Number(item[header[1]])) === true
                ) {
                temp[`${idx},1`] = 'value_error'
                errBirthValids.push(idx);   //  오류가 있었다면 에러 배열에 추가.
            }
            // 휴대전화번호
            if(item[header[4]] === '') {
                temp[`${idx},4`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // // 접수일자(신청일) - 값 유무
            // if(item[header[9]] === '') {
            //     temp[`${idx},9`] = 'value_error'
            //     no_value = true;
            // }
            // 접수일자(신청일) - 날짜형태 체크
            if(item[header[9]].length > 0) {
                if(is_correctDate(item[header[9]]) === false) {
                    temp[`${idx},9`] = 'value_error'
                    errDateValids.push(idx);    //  오류가 있었다면 에러 배열에 추가.
                }
            }
            // 주소
            if(item[header[2]] === '') {
                temp[`${idx},2`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 상세주소
            // if(item[header[3]] === '') {
            //     temp[`${idx},3`] = 'value_error'
            //     no_value = true;
            // }
            // 신청유형(공급유형)
            if(item[header[5]] === '') {
                temp[`${idx},5`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 세부자격요건
            // if(item[header[7]] === '') {
            //     temp[`${idx},7`] = 'value_error'
            //     no_value = true;
            // }
            // 수급자자격
            // if(item[header[8]] === '') {
            //     temp[`${idx},8`] = 'value_error'
            //     no_value = true;
            // }
            // 최종결과
            if(item[header[10]] === '') {
                temp[`${idx},10`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 지원가능기간 - 값 유무
            if(
                item[header[11]] === ''
                ) {
                temp[`${idx},11`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 지원가능기간 - 날짜형태 체크
            if(is_correctDate(item[header[11]].slice(-10)) === false) {
                temp[`${idx},11`] = 'value_error'
                errDateValids.push(idx);    //  오류가 있었다면 에러 배열에 추가.
            }
            // 기금
            if(item[header[12]] === '') {
                temp[`${idx},12`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 주택
            if(item[header[13]] === '') {
                temp[`${idx},13`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 타임대
            if(item[header[14]] === '') {
                temp[`${idx},14`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 세대원
            if(item[header[18]] === '') {
                temp[`${idx},18`] = 'value_error'
                errNoValues.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }
            // 세대원 값이 숫자인지
            if(isNaN(Number(item[header[18]])) === true) {
                temp[`${idx},18`] = 'value_error'
                errFamCntValids.push(idx);  //  오류가 있었다면 에러 배열에 추가.
            }

            // 추가 날짜 체크
            // 퇴소일, 퇴소자격 기한은 값이 있는 경우만 체크
            // 퇴소일
            if(item[header[21]].length > 0) {
                if(is_correctDate(item[header[21]]) === false) {
                    temp[`${idx},21`] = 'value_error'
                    errDateValids.push(idx);    //  오류가 있었다면 에러 배열에 추가.
                }
            }
            // 퇴소자격 기한
            if(item[header[22]].length > 0) {
                if(is_correctDate(item[header[22]]) === false) {
                    temp[`${idx},22`] = 'value_error'
                    errDateValids.push(idx);    //  오류가 있었다면 에러 배열에 추가.
                }
            }
        })
        setwinnerExcelNodata(temp);

        //  배열 내 중복항목 제거
        errNoValues = [...new Set(errNoValues)];
        errDateValids = [...new Set(errDateValids)];
        errFamCntValids = [...new Set(errFamCntValids)];
        errBirthValids = [...new Set(errBirthValids)];

        //  필수입력값 검즘
        if(errNoValues.length > 1) {
            msg += '# ' + (errNoValues[0] + 1) + '번째 행 외 ' + (errNoValues.length - 1) + '개 행에 필수입력값이 없습니다.\n\n';
        }
        else if(errNoValues.length > 0) {
            msg += '# ' + (errNoValues[0] + 1) + '번째 행에 필수입력값이 없습니다.\n\n';
        }

        //  날짜포맷 검증
        if(errDateValids.length > 1) {
            msg += '# ' + (errDateValids[0] + 1) + '번째 행 외 ' + (errDateValids.length - 1) + '개 행의 날짜 형식을 확인해주세요.\n\n';
        }
        else if(errDateValids.length > 0) {
            msg += '# ' + (errDateValids[0] + 1) + '번째 행의 날짜 형식을 확인해주세요.\n\n';
        }
        
        //  세대원 값 숫자타입 검증
        if(errFamCntValids.length > 1) {
            msg += '# ' + (errFamCntValids[0] + 1) + '번째 행 외 ' + (errFamCntValids.length - 1) + '개 행의 세대원 값이 숫자가 아닙니다.\n\n';
        }
        else if(errFamCntValids.length > 0) {
            msg += '# ' + (errFamCntValids[0] + 1) + '번째 행의 세대원 값이 숫자가 아닙니다.\n\n';
        }
        
        //  생년월일 검증
        if(errBirthValids.length > 1) {
            msg += '# ' + (errBirthValids[0] + 1) + '번째 행 외 ' + (errBirthValids.length - 1) + '개 행의 생년월일은 6자리 이상의 숫자여야 합니다.\n\n';
        }
        else if(errBirthValids.length > 0) {
            msg += '# ' + (errBirthValids[0] + 1) + '번째 행의 생년월일은 6자리 이상의 숫자여야 합니다.\n\n';
        }

        //  검증 메시지 띄움
        if(msg.length > 0) {
            ALERT(msg);
        }

        //  검증 값 반환
        if(errNoValues.length === 0 && errDateValids.length === 0 && errFamCntValids.length === 0 && errBirthValids.length === 0) {
            return false; // 문제없음
        }
        else {
            return true; // 문제있음
        }
    }

    // 엑셀값 정제가 끝나면 excelReady 값이 변한다
    // (setExcelReady 액션이 excelReady 값을 바꿈)
    /*
    업로드 클릭 -> onUpload 함수 호출 -> setExcelReady 액션 수행 -> excelReady 값 변경 -> useEffect 실행 -> 업로드
    */
    useEffect(() => {
        if(excelReady) { 
            if(window.confirm(`총 ${winnerExcelSend.length}명을 업로드 하시겠습니까?`)) {
                dispatch(winnerWrite_Excel({
                    winner_info: winnerExcelSend
                }))
            }
        }
    }, [excelReady, dispatch, winnerExcelSend])

    // 엑셀 업로드 성공 여부 판단
    useEffect(() => {
        if(winnerExcelUploadSuccess) {
            if(winnerExcelUploadSuccess === "Y") {
                navigate(-1)
            }
        }
    }, [winnerExcelUploadSuccess, navigate])

    useEffect(() => {
        if(cboApply.length < 1) {
            dispatch(getCombo({main_code: "APPLY_TYPE"}))
        }
    }, [dispatch, cboApply])

    useEffect(()=> {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
    }, [winnerExcel])

    //  불러온 Excel Row Data 중 validation에 벗어나는 항목이 있다면 Row 전체에 색깔을 칠해(className : errRow) 표시함.
    useEffect(() => {
        const trValid = document.getElementsByClassName("tr-valid");
        
        if(trValid.length === 0) return;

        setTimeout(() => {
            for(let i = 0; i < trValid.length; i++) {
                if(trValid[i].getElementsByClassName('value_error').length > 0) {
                    trValid[i].className += ' errRow';
                }
            }
        }, 100);
    }, [winnerExcelHeader_true])

    const propDatas = {
        winnerExcel,
        winnerExcelHeader,
        winnerExcelHeader_true,
        fileState,
        winnerExcelNodata,
        cboApply,
        fileValue,

        onChange,
        onChangeValue,
        onUpload,
    }
    return (
        <>
            <ExcelWork {...propDatas}/>
        </>
    );
}

export default WinnerExcelWorkContainer;