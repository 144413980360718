import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ListPage from './pages/ListPage';
import EntitleWritePage from './pages/EntitleWritePage';
import EnWriteAdminPage from './pages/EnWriteAdminPage';
import EntitleWorkPage from './pages/EntitleWorkPage';
import MainPage from './pages/MainPage';
import Mypage from './pages/Mypage';
import WinnerRegisterWorkPage from './pages/WinnerRegisterWorkPage';
import DocSortPage from './pages/DocSortPage';
import AdminPage from './pages/AdminPage';
import WinnerRegisterListPage from './pages/WinnerRegisterListPage';
import ContractPage from './pages/ContractPage';
import ContractProcPage from './pages/ContractProcPage';
import EntitleViewerPage from './pages/EntitleViewerPage';
import dataURL, { dataSTATUS } from './lib/dataURL';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { check, getMenu } from './modules/user';
import ImplyRegisterWorkPage from './pages/ImplyRegisterWorkPage';
import ImplyRegisterListPage from './pages/ImplyRegisterListPage';
import ImplyWorkpage from './pages/ImplyWorkpage';
import NotFoundPage from './pages/NotFoundPage';
import ImplyResultPage from './pages/ImplyResultPage';
import ImplyAuditRequestPage from './pages/ImplyAuditRequestPage';
import CustomerListPage from './pages/CustomerListPage';
import WinnerExcelWorkPage from './pages/WinnerExcelWorkPage';
import LHCustomerListPage from './pages/LHCustomerListPage';
import ImplyAuditRequestListPage from './pages/ImplyAuditRequestListPage';
import AuditListPage from './pages/AuditListPage';
import MaintainRegisterPage from './pages/MaintainRegisterPage';
import UploadListPagePage from './pages/UploadListPage';
import ChargeFeePagePage from './pages/ChargeFeePage';
import ImplyWorkListPage from './pages/ImplyWorkListPage';
import ImplyApprovePage from './pages/ImplyApprovePage';
import ImplyExcelWorkPage from './pages/ImplyExcelWorkPage';
import NextMaintainPage from './pages/NextMaintainPage';
import ImplyAuditWorkPage from './pages/ImplyAuditWorkPage';
import ReportListPage from './pages/ReportListPage';
import AuditRequestListPage from './pages/AuditRequestListPage';
import AuditRequestPage from './pages/AuditRequestPage';
import UserTypeSelectPage from './pages/UserTypeSelectPage';
import DobaeUserListPage from './pages/DobaeUserListPage';

function App() {
    const dispatch = useDispatch();
    const {
        user
    } = useSelector(({
        user
    }) => ({
        user: user.user
    }))

    // 새로고침 되면 메뉴가 비워지는거 방지.
    useEffect(() => {
        if(!user) {
            dispatch(check());
        }
        else if(user) {
            dispatch(getMenu());
        }
    }, [dispatch, user])
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<UserTypeSelectPage/>}      path={dataURL.UserTypeSelectPage} />
                <Route element={<LoginPage/>}               path={dataURL.LoginPage} />
                <Route element={<Navigate replace to={dataURL.LoginPageMove + 'STAFF'}/>}   path={dataURL.LoginStaffPage} />
                <Route element={<RegisterPage/>}            path={dataURL.RegisterPage}/>
                <Route element={<MainPage/>}                path={dataURL.MainPage}/>
                <Route element={<ListPage/>}                path={dataURL.ListPage + '/' + dataSTATUS.ALL}/>
                <Route element={<EntitleWritePage/>}        path={dataURL.EntitleWritePage}/>
                <Route element={<EnWriteAdminPage/>}        path={dataURL.EnWriteAdminPage}/>
                <Route element={<EntitleWorkPage/>}         path={dataURL.EntitleWorkPage}/>
                <Route element={<AdminPage/>}               path={dataURL.AdminPage}/>
                <Route element={<Mypage />}                 path={dataURL.Mypage}/>
                <Route element={<WinnerRegisterWorkPage/>}  path={dataURL.WinnerRegisterWorkPage}/>
                <Route element={<DocSortPage/>}             path={dataURL.DocSortPage}/>
                <Route element={<WinnerRegisterListPage/>}  path={dataURL.WinnerRegisterListPage}/>
                <Route element={<ContractPage/>}            path={dataURL.ContractPage}/>
                <Route element={<ContractProcPage/>}        path={dataURL.ContractProcPage}/>
                <Route element={<EntitleViewerPage/>}       path={dataURL.EntitleViewerPage}/>
                <Route element={<ImplyRegisterWorkPage/>}   path={dataURL.ImplyRegisterWorkPage}/>
                <Route element={<ImplyRegisterListPage/>}   path={dataURL.ImplyRegisterListPage}/>
                <Route element={<ImplyWorkpage/>}           path={dataURL.ImplyWorkPage}/>
                <Route element={<ImplyResultPage/>}         path={dataURL.ImplyResultPage}/>
                <Route element={<ImplyAuditRequestPage/>}   path={dataURL.ImplyAuditRequestPage}/>
                <Route element={<CustomerListPage/>}        path={dataURL.CustomerListPage}/>
                <Route element={<WinnerExcelWorkPage/>}     path={dataURL.WinnerExcelWorkPage}/>
                <Route element={<LHCustomerListPage/>}      path={dataURL.LHCustomerListPage}/>
                <Route element={<ImplyAuditRequestListPage/>}path={dataURL.ImplyAuditRequestListPage}/>
                <Route element={<AuditListPage/>}           path={dataURL.AuditListPage}/>
                <Route element={<MaintainRegisterPage/>}    path={dataURL.MaintainRegisterPage}/>
                <Route element={<UploadListPagePage/>}      path={dataURL.UploadListPagePage}/>
                <Route element={<ChargeFeePagePage/>}       path={dataURL.ChargeFeePagePage}/>
                <Route element={<ImplyWorkListPage/>}       path={dataURL.ImplyWorkListPage}/>
                <Route element={<ImplyApprovePage />}       path={dataURL.ImplyApprovePage}/>
                <Route element={<ImplyExcelWorkPage />}     path={dataURL.ImplyExcelWorkPage}/>
                <Route element={<NextMaintainPage />}       path={dataURL.NextMaintainPage}/>
                <Route element={<ImplyAuditWorkPage />}     path={dataURL.ImplyAuditWorkPage}/>
                <Route element={<ReportListPage />}         path={dataURL.ReportListPage}/>
                <Route element={<AuditRequestListPage />}   path={dataURL.AuditRequestListPage}/>
                <Route element={<AuditRequestPage />}       path={dataURL.AuditRequestPage}/>
                <Route element={<DobaeUserListPage />}      path={dataURL.DobaeUserListPage}/>
                <Route element={<NotFoundPage />}           path="*"/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
