import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ImplyAuditRequest from '../components/ImplyAuditRequest';
import { impAuditRequest, implyRequestSearch, initialize } from '../modules/Imply';
import ExcellentExport from 'excellentexport';
import { useLocation } from 'react-router-dom';

const ImplyAuditRequestContainer = () => {
    const [isPostBack, setIsPostBack] = useState(true);
    const {
        // implySearch,
        impWorkList,
        // auditArr,
        // auditStr
    } = useSelector(({Imply}) => ({
        // implySearch: Imply.implySearch,
        impWorkList: Imply.impWorkList,
        // auditArr: Imply.auditArr,
        // auditStr: Imply.auditStr
    }));
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(false);
    const location = useLocation();
    const { state: {req_id}} = location;

    const onSearch = useCallback(() => {
        dispatch(implyRequestSearch({
            req_id: req_id,
            // date_type: implySearch.date_type,
            // from_date: implySearch.from_date,
            // to_date: implySearch.to_date,
            // addr: implySearch.addr,
            // name: implySearch.name,
            // page: implySearch.page,
            // req_status: "N",
        }))
    }, [dispatch, req_id])//, implySearch.date_type, implySearch.from_date, implySearch.to_date, implySearch.addr, implySearch.name, implySearch.page])

    // 등초본 요청
    const onRequest = () => {
        dispatch(impAuditRequest({
            req_id: req_id,
        }))
    }

    // // 검색
    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     onSearch();
    // }


    // // 검색값변경
    // const onChange = (e) => {
    //     const {target: {name, value}} = e
    //     dispatch(changeValue({
    //         field: 'implySearch',
    //         key: name,
    //         value
    //     }))
    // }


    // // 체크박스 개별
    // const onCheck = (e, item) => {
    //     const {target: {checked}} = e;
    //     dispatch(setAuditCheck({
    //         checked,
    //         item
    //     }))

    // }

    // // 체크박스 전체
    // const onCheckAll = (e) => {
    //     const {target: {checked}} = e;
    //     dispatch(setAuditCheckAll({
    //         checked
    //     }))
    // }

    // 아이디가 excelData 인 테이블의 데이터를 엑셀로 변환
    const handleClickXLSX = () => {
        return ExcellentExport.convert(
            { anchor: this, filename: "등본요청자료", format: "xlsx", openAsDownload: true },
            [
                { name: "등본요청명단", from: { table: "excelData" } },
            ]
        );
    }

    useEffect(() => {
        if(isPostBack) {
            onSearch();
            setIsPostBack(false);
        }
    }, [isPostBack, onSearch])

    useEffect(() => {
        if(update) {
            onSearch();
            setUpdate(false);
        }
    }, [update, onSearch])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])
    
    const propDatas = {
        // implySearch,
        impWorkList,
        // auditArr,
        // auditStr,

        // onSubmit,
        // onChange,
        onRequest,
        // onCheck,
        // onCheckAll,
        handleClickXLSX,
    };
    return (
        <>
            <ImplyAuditRequest {...propDatas}/>
        </>
    );
}

export default ImplyAuditRequestContainer;