import React, { useEffect, useState } from 'react';
import ImplyExcelWork from '../components/ImplyExcelWork';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { changeValue, getCombo, implyExcelChangeValue, implyUpload, initialize } from '../modules/Imply';
import { useNavigate } from 'react-router-dom';
import { ALERT } from '../lib/dataWord';

const ImplyExcelWorkContainer = () => {
    const {
        implyExcel,
        implyExcelHeader,
        cboApply,
        implyUploadSuccess,
    } = useSelector(({
        Imply
    }) => ({
        implyExcel: Imply.implyExcel,
        implyExcelHeader: Imply.implyExcelHeader,
        cboApply: Imply.cboApply,
        implyUploadSuccess: Imply.implyUploadSuccess,
    }))
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [area, setArea] = useState();
    const [fileState, setFileState] = useState(0);

    // 엑셀 업로드 설정값
    /*
    실제 데이터 범위
    // 두번째 행부터
    // 두번째 열부터 9번쨰 열까지만 읽는다. (전세금까지)
    // 엑셀을 배열로 읽어서 당첨자보다 과정을 단순화함.

    참고파일 : 211228_묵시갱신세대_4-11월_계약3과_강북도봉하남포천연천_발송.xlsx
    
    지역구는 항상 첫번째열 두번째 행부터 '지역구', '**구' 형태로 존재한다고 가정한다..
    */
    const opt = {
        defval: "-", 
        raw: false, 
        dateNF: "YYYY-MM-DD",
        header:1, // 2차원 배열로 읽는다. // [0]에 헤더가 담긴다.
    }

    const onChange = (e) => {
        const input = e.target;

        let temp_excelData = null;          // 엑셀 값
        let temp_excelHeader = null;        // 엑셀 헤더 (배열로 읽기 때문에 당첨자처럼 헤더를 두개로 관리할 필요가 없음.)

        // 과정은 당첨자 엑셀 등록과 거의 동일함 WinnerExcelWorkContainer.js 참고.

        const reader = new FileReader();
        setFileState(reader.readyState);

        reader.readAsBinaryString(input.files[0]);
        setFileState(reader.readyState);

        reader.onload = () => {
            let fileData = reader.result;

            let wb = null;

            try {
                wb = XLSX.read(fileData, {type:'binary', cellDates:true, cellNF: false, cellText:false});
            } catch(e) {
                setFileState(0);
                ALERT('엑셀파일을 읽는데 에러가 발생했습니다. 엑셀을 확인해 주세요');
            }

            temp_excelData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], opt);

            // 지역구
            setArea(temp_excelData[0][2]);

            // 헤더 (순서대로 들어감)
            temp_excelHeader = temp_excelData[1].filter((item, idx) => item && (0 < idx && idx <= 8));
            dispatch(changeValue({
                filed: null,
                key: 'implyExcelHeader',
                value: temp_excelHeader
            }))
            
            // 데이터 (1 ~ 8 인덱스만)
            temp_excelData = temp_excelData.filter((el, idx) => el && idx >= 2)
            temp_excelData = temp_excelData.map((item) => {
                return item.filter((el, idx) => (0 < idx && idx <= 8))
            })
            dispatch(changeValue({
                filed: null,
                key: 'implyExcel',
                value: temp_excelData
            }))
            setFileState(reader.readyState);
        }

        reader.onerror = (err) => {
        }
    }

    // 엑셀 값 변경
    const onChangeValue = (e, idx) => {
        const {target: {name, value}} = e;
        dispatch(implyExcelChangeValue({
            key: name,
            value,
            idx
        }))
    }

    const getCode = (name) => {
        let value = '';
        let el = null;

        for (let index = 0; index < cboApply.length; index++) {
            el = cboApply[index];
            if(el.code_name === name) {
                value = el.sub_code;
                break;
            }
        }

        return value;
    }

    const onUpload = () => {
        if(implyExcel.length < 1) {
            ALERT('등록할 대상자가 없습니다.')
        }
        else {
            let payload = null;
    
            payload = implyExcel.map((item) => {
                return ({
                    imply_id: '',
                    type: getCode(item[0]),
                    rec_date: '',
                    rec_area: area,
                    name: item[1],
                    birth: item[2]? item[2].slice(0,6) : '',
                    imply_no: item[2],
                    phone: item[3],
                    house_owner: item[4],
                    addr: item[5],
                    contract_end_date: item[6],
                    rent_amt: item[7],
                    use_flag: 'Y',
                    memo: '',
                })
            })
    
            if(window.confirm(`${implyExcel.length}건을 묵시대상자로 등록하시겠습니까?`)) {
                dispatch(implyUpload({
                    imply_info: payload
                }))
            }
        }
    }

    useEffect(() => {
        dispatch(getCombo({main_code: "APPLY_TYPE"}));

        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        if(implyUploadSuccess) {
            if(implyUploadSuccess === "Y") {
                navigate(-1);
            }
        }
    }, [implyUploadSuccess, navigate])
    const propDatas = {
        implyExcelHeader,
        implyExcel,
        fileState,

        onChange,
        onChangeValue,
        onUpload,
    }
    return (
        <>
            <ImplyExcelWork {...propDatas}/>
        </>
    );
}

export default ImplyExcelWorkContainer;