import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as entitleAPI from '../lib/api/entitle';
import { ToLocaleString } from '../common';
import { dataSystemCode } from '../lib/dataSystemCode';

const INITIALIZE = 'entitleViewer/INITIALIZE';
const CHANGE_VALUE = 'entitleViewer/CHANGE_VALUE';

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('entitleViewer/GET_CONTENT');

export const getContent = createAction(GET_CONTENT);

const getContentSaga = createRequestSaga(GET_CONTENT, entitleAPI.doc_view);

export function* entitleViewerSaga() {
    yield takeLatest(GET_CONTENT, getContentSaga);
}

const initialState = {
    winner: [],
    write: [],
    rentWrite: [],
    work: [],
    rentWork: [],
    doc014: null, //  등기부등본
    doc015: null, //  건축물관리대장
    doc016: null, //  주택가격
    doc017: null, //  신.구주소

    entitleViewer: null,
    error: null
};

const entitleViewer = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        //  전체 조회   =======================================================================================
        [GET_CONTENT_SUCCESS]: (state, { payload }) => {

            let _write = payload.write[0], _work = payload.work[0];
            let _rentWrite = [], _rentWork = [];

            //  입주자 정보
            const _winner = payload.winner.map((item) => {
                return {
                    ...item,
                    lh_support_amt  : ToLocaleString(item.lh_support_amt    ),
                    winner_charges  : ToLocaleString(item.winner_charges    ),
                    add_charges     : ToLocaleString(item.add_charges       ),
                    monthly_rent    : ToLocaleString(item.monthly_rent      ),
                }
            })

            //  신청서 금액변환
            _write.empty_tot_amt        = !_write.empty_tot_amt     ? "0" : ToLocaleString(_write.empty_tot_amt     );
            _write.monthly_rent_amt     = !_write.monthly_rent_amt  ? "0" : ToLocaleString(_write.monthly_rent_amt  );
            _write.rent_amt             = !_write.rent_amt          ? "0" : ToLocaleString(_write.rent_amt          );
            _write.rent_tot_amt         = !_write.rent_tot_amt      ? "0" : ToLocaleString(_write.rent_tot_amt      );
            _write.support_amt          = !_write.support_amt       ? "0" : ToLocaleString(_write.support_amt       );
            
            //  검토서 금액변환
            _work.already_rent_amt      = !_work.already_rent_amt   ? "0" : ToLocaleString(_work.already_rent_amt   );
            _work.decide_amt            = !_work.decide_amt         ? "0" : ToLocaleString(_work.decide_amt         );
            _work.difference_amt        = !_work.difference_amt     ? "0" : ToLocaleString(_work.difference_amt     );
            _work.empty_tot_amt         = !_work.empty_tot_amt      ? "0" : ToLocaleString(_work.empty_tot_amt      );
            _work.first_bond_amt        = !_work.first_bond_amt     ? "0" : ToLocaleString(_work.first_bond_amt     );
            _work.house_amt             = !_work.house_amt          ? "0" : ToLocaleString(_work.house_amt          );
            _work.monthly_rent_amt      = !_work.monthly_rent_amt   ? "0" : ToLocaleString(_work.monthly_rent_amt   );
            _work.mortgage_amt          = !_work.mortgage_amt       ? "0" : ToLocaleString(_work.mortgage_amt       );
            _work.possible_bond_amt     = !_work.possible_bond_amt  ? "0" : ToLocaleString(_work.possible_bond_amt  );
            _work.pre_charge_amt        = !_work.pre_charge_amt     ? "0" : ToLocaleString(_work.pre_charge_amt     );
            _work.pre_deposit_total     = !_work.pre_deposit_total  ? "0" : ToLocaleString(_work.pre_deposit_total  );
            _work.pre_interest_free     = !_work.pre_interest_free  ? "0" : ToLocaleString(_work.pre_interest_free  );
            _work.pre_rent_loans        = !_work.pre_rent_loans     ? "0" : ToLocaleString(_work.pre_rent_loans     );
            _work.rent_amt              = !_work.rent_amt           ? "0" : ToLocaleString(_work.rent_amt           );
            _work.rent_set_amt          = !_work.rent_set_amt       ? "0" : ToLocaleString(_work.rent_set_amt       );
            _work.rent_tot_amt          = !_work.rent_tot_amt       ? "0" : ToLocaleString(_work.rent_tot_amt       );
            _work.securing_bond_amt     = !_work.securing_bond_amt  ? "0" : ToLocaleString(_work.securing_bond_amt  );
            _work.seizure_amt           = !_work.seizure_amt        ? "0" : ToLocaleString(_work.seizure_amt        );
            _work.small_room_support    = !_work.small_room_support ? "0" : ToLocaleString(_work.small_room_support );
            _work.support_amt           = !_work.support_amt        ? "0" : ToLocaleString(_work.support_amt        );
            _work.wallpaper_flag_name   = _work.wallpaper_flag && _work.wallpaper_flag === "Y" ? dataSystemCode.USE_YN[0].string_name : dataSystemCode.USE_YN[1].string_name; // 도배장판여부 이름

            //  선순위 정보
            payload.rent.forEach(item => {
                _rentWrite.push({
                    rent_seq: item.rent_seq,
                    empty_type: item.empty_type,
                    empty_type_name: item.empty_type_name,
                    priority_payment: ToLocaleString(item.priority_payment),
                    remark: item.remark,
                    rent_deposit: ToLocaleString(item.rent_deposit),
                    rent_fee: ToLocaleString(item.rent_fee),
                    rent_floor: item.rent_floor,
                    room_cnt: item.room_cnt,
                    room_no: item.room_no,
                    use_flag: item.use_flag,
                });
                _rentWork.push(item);
            });

            //  API Json Data
            let _doc014 = [], _doc015 = [], _doc016 = [], _doc017 = [];
            payload.files.forEach(item => {
                if(item.file_type === "DOC014" && item.file_json && item.file_json !== 'false') {
                    try {
                        _doc014.push(JSON.parse(item.file_json));
                    } catch (e) {
                        _doc014.push(item.file_json);
                    }
                }
                else if(item.file_type === "DOC015" && item.file_json && item.file_json !== 'false') {
                    try {
                        _doc015.push(JSON.parse(item.file_json));
                    } catch (e) {
                        _doc015.push({
                            ERRCODE: '99999999',
                            ERRMSG: 'API데이터 오류(JSON구문오류)'
                        });
                    }
                }
                else if(item.file_type === "DOC016" && item.file_json && item.file_json !== 'false') {
                    let jsonData = null;
                    try {
                        jsonData = JSON.parse(item.file_json).RESP_DATA;
                        if(jsonData) {
                            jsonData = jsonData.map((item) => {
                                return ({
                                    ...item,
                                    OFFICIAL_LAND_PRICE: ToLocaleString(item.OFFICIAL_LAND_PRICE),
                                })
                            })
                        } else {
                            jsonData = JSON.parse(item.file_json).RESULT_CD;
                        }
                    } catch (e) {
                        jsonData = item.file_json;
                    }
                    _doc016.push({
                        jsonData: jsonData,
                        sub_type: item.sub_type,                        
                    });
                }
                else if(item.file_type === "DOC017" && item.file_json && item.file_json !== 'false') {
                    try {
                        _doc017.push(JSON.parse(item.file_json));
                    } catch (e) {
                        _doc017.push({
                            ERRCODE: '99999999',
                            ERRMSG: 'API데이터 오류(JSON구문오류)'
                        });
                    }
                }
            });

            return {
                ...state,
                winner: _winner,
                write: _write,
                rentWrite: _rentWrite,
                work: _work,
                rentWork: _rentWork,
                doc014: _doc014,
                doc015: _doc015,
                doc016: _doc016,
                doc017: _doc017,
                use_address: payload.use_address && Array.isArray(payload.use_address) && payload.use_address.length > 0 ? payload.use_address[0] : null,
            }
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default entitleViewer;