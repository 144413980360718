import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
import TermsModal from './Modal/TermsModal';

const RegisterFormBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    position: relative;

    .bg-gradient{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background: linear-gradient(45deg, #1EBF86 , #0B999C);
    }
    .loginBox{
        width: 540px;
        padding: 20px 70px;
        margin: 10px 0;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }

    #user_id{
        text-transform: lowercase;
    }

    h2{
        color: #545454;
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 45px;
        box-sizing: border-box;
    }

    .log{
        position: relative;
        font-size: 14px;
    }
    label{
        display: block;
        color: #545454;
        font-weight: 600;
        margin: 10px 0 3px 0;
    }
    input{
        width: 100%;
        height: 38px;
        font-size: 12px;
        border-radius: 5px;
        border: 1px solid #BDBDBD;
        padding: 10px;
        box-sizing: border-box;
    }

    .squre-bdradius-btn{
        position: absolute;
        top: 20px;
        right: 0;
    }
    .green, .red, .black{
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 11px;
        font-weight: 600;
    }
    .green {
        color: #95BC07;
    }
    .red {
        color: red;
    }

    .file input::file-selector-button{ 
        display: none; 
    }
    input[type="file"]{
        cursor: pointer;
    }

    .img {
        position: relative;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        padding: 10px 0;
        box-sizing: border-box;
        border-radius: 5px; 
        border: 1px solid #0B999C;
    }

    .img label {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .img img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: contain;
    }

    .img .imgCancle {
        position: absolute;
        bottom: 0px;
        right: -20px;

        width: 15px;
        height: 15px;
        cursor: pointer;
        color: red;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .displayButtons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .displayButtons button{
        margin: 10px;
    }
    .re{
        text-align: center;
        margin-top: 20px;
    }
    .re>div:nth-child(1){
        color: #004593;
        font-weight: 600;
    }
    .re>div:nth-child(2){
        color: red;
        font-weight: 600;
    }

    .info {
        padding-right: 38px;
        text-align: right;
    }
    .greenDot {
        position: absolute; margin: 6px 3px 0 3px; width: 5px; height: 5px; background-color: #0fa396; border-radius: 100%;
    }

    .pwd p {
        position: relative;
        right: 0px;
    }

    /* 이용약관 css 추가 */
    .terms {
        width: 100%;
        padding: 20px 0;
        label {
            width: calc(100% - 30px);
        }
        input{
            width: 0;
        }
        input[type="checkbox"] + label:before{
            color: #BDBDBD;
            border: 1px solid #BDBDBD;
        }
    }
    .terms>div:nth-child(1){
        input[type="checkbox"]:checked + label:before{
            color: #ffffff;
            background-color: #0B999C;
            border: 1px solid #0B999C;
        }
    }
    .terms>div:nth-child(1)>div>label{
        font-size: 14px !important;
        font-weight: 500 !important;
    }
    
    .terms>div:nth-child(2){
        width: 100%;
        margin-left: 8px;
        label,div{
            font-weight: 400;
        }
        input[type="checkbox"]:checked + label:before{
            color: #0B999C;
            border: 1px solid #0B999C;
        }
    }

    .terms .text-with-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .terms label > div{
        display: inline-block;
    }
    .nameColor{
        color: #0B999C;
    }
    .nameUnderline{
        color: #0B999C;
        text-decoration: underline #0B999C;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .bg-gradient{
            background: #ffffff;
            align-items: flex-start;
        }
        .loginBox{
            width: 100%;
            padding: 70px 0;
            box-shadow: none;
        }
        h2{
            margin-bottom: 70px;
        }
        .body{
            padding: 0 30px;
            box-sizing: border-box;
        }
    }
`;

// 디자인에는 있지만 소스에 없는 기능?텍스트 들이있어서 임의로넣은것
// 인증번호받기, 인증번호확인, 취소 3가지

const RegisterForm = (props) => {
    
    const {
        register,
        error,
        isBroker,
        pwdMsg,
        isShowDaum,
        pwdRef,
        min,
        sec,
        setTimeStart,
        certSuccess,

        onRegister,
        onChange,
        onFileChange,
        onChangeAgree,
        onImgCancle,
        onNavBackClick, //헤더컴포넌트를 빼서 뒤로가기버튼이 없어서 임의로넣음
        onComplete,
        onCloseDaum,
        onOpenDaum,
        sendCode,
        checkCode,
        onIdCheck,

        isShowTermsModal,
        setIsShowTermsModal
    } = props;

    return (
        <RegisterFormBlock>
            <div className='bg-gradient'>
                <div className='loginBox'>
                    <h2>{isBroker? "중개사 " : ''}회원가입</h2>
                    <h4 className='info'>필수입력<span className='greenDot'/></h4>
                    <div className='body'>
                        <div className='log'>
                            <label htmlFor="user_id">아이디<span className='greenDot'/></label>
                            <input type="text" name="user_id" id="user_id" placeholder='아이디' value={register.user_id} onChange={onChange}/>
                            <button className="squre-bdradius-btn" onClick={onIdCheck}>중복확인</button>
                            {
                                register.chk_overlap === 0 ? <p className='black'>아이디 중복확인 필요</p> :
                                register.chk_overlap === 1 ? <p className='green'>사용할 수 있는 아이디입니다</p> : <p className='red'>아이디가 중복됩니다</p>
                            }
                        </div>
                        <div className='log'>
                            <label htmlFor="user_pwd">비밀번호<span className='greenDot'/></label>
                            <input type="password" name="user_pwd" id="user_pwd" placeholder='비밀번호(8자 이상, 영문, 숫자, 특수문자 포함)' value={register.user_pwd} onChange={onChange} ref={pwdRef}/>
                        </div>
                        <div className='log pwd'>
                            <label htmlFor="user_pwd_chk">비밀번호확인<span className='greenDot'/></label>
                            <input type="password" name="user_pwd_chk" id="user_pwd_chk" placeholder='비밀번호확인' value={register.user_pwd_chk} onChange={onChange} />
                            {pwdMsg.msg? <p className={pwdMsg.error? 'red' : 'green'}>{pwdMsg.msg}</p> : null}
                        </div>
                        <div className='log'>
                            <label htmlFor="user_phone">전화번호<span className='greenDot'/></label>
                            <input type="number" name="user_phone" id="user_phone" placeholder='숫자만입력하세요' value={register.user_phone} onChange={onChange} disabled={certSuccess === 'success' ? 'disabled' : ''} />
                            <button onClick={sendCode} className="squre-bdradius-btn" disabled={certSuccess === 'success' ? 'disabled' : ''}>인증번호 받기</button>
                            {setTimeStart? <p className={min === 0 && sec <= 30? 'red' : 'green'}>인증번호 남은시간 {min} : {('0'+sec).slice(-2)}</p> : null}
                        </div>
                        <div className='log'>
                            <label htmlFor="user_code_chk">인증번호<span className='greenDot'/></label>
                            <input type="number" name="user_code_chk" id="user_code_chk" placeholder='인증번호' value={register.user_code_chk} onChange={onChange} disabled={certSuccess === 'success' ? 'disabled' : ''} />
                            <button onClick={checkCode} className="squre-bdradius-btn" disabled={certSuccess === 'success' ? 'disabled' : ''}>인증번호 확인</button>
                            {certSuccess? <p className={certSuccess === 'success'? 'green' : 'red'}>{certSuccess === 'success'? '인증번호가 일치합니다.' : '인증번호가 일치하지 않습니다.'}</p> : null}
                        </div>

                        <div className='log file'>
                            <label htmlFor="card">명함 업로드</label>
                            <input type="file" accept='image/*' name="card" id="card" placeholder='명함 업로드' onChange={onFileChange} />
                        </div>
                        <div className='img'>
                            <label htmlFor="card">
                                {register.card.file_data? 
                                    <img src={`data:${register.card.contenttype};base64,${register.card.file_data}`} alt="card"/> 
                                    :
                                    <p>미리보기</p>
                                }
                            </label>
                            <div className='imgCancle' onClick={() => onImgCancle('card')}>X</div>
                        </div>
                        
                        <div className='log'>
                            <label htmlFor="user_name">{isBroker? '중개사 성명' : '성명'}<span className='greenDot'/></label>
                            <input type="text" name="user_name" id="user_name" placeholder='성명' value={register.user_name} onChange={onChange} />
                        </div>
                        {/* 추후 구현: 중개사 회원가입시 사업자 등록번호 입력하면 자동 검색해서 선택하면, 중개사 정보를 자동으로 입력되도록 */}
                        {isBroker? 
                        <div className='log'>
                            <label>사업자등록번호<span className='greenDot'/></label>
                            <input type="number" name="reg_no" id="reg_no" placeholder='숫자만입력하세요' value={register.reg_no} onChange={onChange} />
                        </div> : null}

                        <div className='log'>
                            <label htmlFor="user_company">{isBroker? '중개사 상호' : '소속'}<span className='greenDot'/></label>
                            <input type="text" name="user_company" id="user_company" placeholder='소속' value={register.user_company} onChange={onChange} />
                        </div>
                        {isBroker? 
                        null
                        :
                        <div className='log'>
                            <label htmlFor="user_position">직책</label>
                            <input type="text" name="user_position" id="user_position" placeholder='직책' value={register.user_position} onChange={onChange} />
                        </div>}
                        {isBroker?
                            <>
                                <div className='log'>
                                    <label htmlFor='user_tel'>{isBroker? '사무실 번호' : '전화번호'}<span className='greenDot'/></label>
                                    <input type='number' name='user_tel' id='user_tel' placeholder='숫자만입력하세요' value={register.user_tel} onChange={onChange} />
                                </div>
                                <div className='log'>
                                    <label htmlFor='addr_no'>우편번호<span className='greenDot'/></label>
                                    <input type='number' name='addr_no' id='addr_no' value={register.addr_no} onChange={onChange} readOnly placeholder='우편번호를 검색해주세요'/>
                                    <button className="squre-bdradius-btn" onClick={onOpenDaum}>주소검색</button>
                                </div>
                                <div className='log'>
                                    <label htmlFor='addr'>주소<span className='greenDot'/></label>
                                    <input type='text' name='addr' id='addr' value={register.addr} onChange={onChange} readOnly placeholder='우편번호를 검색해주세요'/>
                                </div>
                                <div className='log'>
                                    <label htmlFor='addr_dtl'>상세주소<span className='greenDot'/></label>
                                    <input type='text' name='addr_dtl' id='addr_dtl' placeholder='상세주소' value={register.addr_dtl} onChange={onChange} />
                                </div>

                                <div className='log'>
                                    <label htmlFor='owner'>중개사 대표</label>
                                    <input type='text' name='owner' id='owner' placeholder='중개소 대표' value={register.owner? register.owner : register.user_name} onChange={onChange} />
                                </div>

                                <div className='log file'>
                                    <label htmlFor="business">사업자등록증</label>
                                    <input type="file" accept='image/*' name="business" id="business" placeholder='사업자등록증'  onChange={onFileChange} />
                                </div>
                                <div className='img'>
                                    <label htmlFor="business">
                                        {register.business.file_data? 
                                            <img src={`data:${register.business.contenttype};base64,${register.business.file_data}`} alt="business"/> 
                                            :
                                            <p>미리보기</p>
                                        }
                                    </label>
                                    <div className='imgCancle' onClick={() => onImgCancle('business')}>X</div>
                                </div>
                                <div className='log file'>
                                    <label htmlFor="deduction">공제증서</label>
                                    <input type="file" accept='image/*' name="deduction" id="deduction" placeholder='공제증서' onChange={onFileChange} />
                                </div>
                                <div className='img'>
                                    <label htmlFor="deduction">
                                        {register.deduction.file_data? 
                                            <img src={`data:${register.deduction.contenttype};base64,${register.deduction.file_data}`} alt="deduction"/> 
                                            :
                                            <p>미리보기</p>
                                        }
                                    </label>
                                    <div className='imgCancle' onClick={() => onImgCancle('deduction')}>X</div>
                                </div>
                            </> : null
                        }
                        {/* 약관동의 시작 */}
                        <div className='terms'>
                            <div>
                                <input id="terms_agree_all" type="checkbox" name="terms_agree_all" onChange={onChangeAgree} checked={register.terms_agree_all}/>
                                <label htmlFor="terms_agree_all">모두 동의</label>
                            </div>
                            <div>
                                <div>
                                    <input id="terms_age" type="checkbox" name="terms_age" onChange={onChangeAgree} checked={register.terms_age}/>
                                    <label htmlFor='terms_age'><div className='nameColor'>(필수)</div> 만14세 이상입니다</label>
                                </div>
                                <div>
                                    <input id="terms_service" type="checkbox" name="terms_service" onChange={onChangeAgree} checked={register.terms_service}/>
                                    <label htmlFor='terms_service'>
                                        <div className="text-with-btn">
                                            <p><span className='nameColor'>(필수) </span>서비스 이용약관 동의</p>
                                            <p onClick={()=>setIsShowTermsModal({show: true, type:'service'})} className="nameUnderline">내용보기</p>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <input id="terms_privacy" type="checkbox" name="terms_privacy" onChange={onChangeAgree} checked={register.terms_privacy}/>
                                    <label htmlFor="terms_privacy">
                                        <div className="text-with-btn">
                                            <p><span className='nameColor'>(필수) </span>개인정보 수집 및 이용 동의</p>
                                            <p onClick={()=>setIsShowTermsModal({show: true, type:'privacy'})} className="nameUnderline">내용보기</p>
                                        </div>
                                    </label>
                                </div>
                            </div>

                        </div>
                        {/* 약관동의 종료 */}
                        <div className='displayButtons'>
                            <button onClick={onNavBackClick} className="cancel-btn">취소</button>
                            {/* 뒤로 버튼없어서 임의로넣음 */}
                            <button onClick={onRegister} className="save-btn">회원가입</button>
                        </div>
                        <div className="re">
                            <div>{register.reg && '회원가입 성공'}</div>
                            <div>{error ? error : ''}</div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowDaum? 
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={onComplete}/>
                    <button className='bdnone-cancel-btn' onClick={onCloseDaum}>닫기</button>
                </div>
            :null}

            {isShowTermsModal.show &&
                <TermsModal type={isShowTermsModal.type} setIsShowTermsModal={setIsShowTermsModal}/>
            }
        </RegisterFormBlock>
    );
}

export default RegisterForm;