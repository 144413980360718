import React from 'react';
import styled from 'styled-components';
import ExcellentExport from 'excellentexport';

const ContractProcBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin: 20px 0;
    }
    h2{
        color: #545454;
        font-size: 20px;
    }

    .search-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .tt {
        margin-top: 10px;
    }

    .btn-area {
        display: flex;
        gap: 10px;
    }
    .chk {
        background-color: white !important;
        margin-right: -20px !important;
    }
    .chk:before {
        top: 12px;
        left: 12px;
    }
`;

const ContractProc = (props) => {
    const {
        status_ok,
        status_start,
        from_date,
        to_date,

        list,

        onChange,
        onChecked,
        onSubmit,
    } = props

    const handleClickXLSX = (e) => {
        return ExcellentExport.convert(
            { anchor: this, filename: "계약처리", format: "xlsx", openAsDownload: true },
            [
                { name: "처리내용", from: { table: "tblList" } },
            ]
        );
    }

    return (
        <ContractProcBlock>
            <h1>계약처리 리스트</h1>

            <div className='search-title'>
                <h2>검색조건</h2>
            </div>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt'>
                            <div className='st'>
                                <label htmlFor='expense_flag' className='bg' style={{width:"120px"}}>계약상태</label>
                                <input type="checkbox" name="status_ok" id="status_ok" checked={status_ok} onChange={onChecked} />
                                <label htmlFor="status_ok" className='chk'>계약완료</label><br/>
                                <input type="checkbox" name="status_start" id="status_start" checked={status_start} onChange={onChecked} />
                                <label htmlFor="status_start" className='chk'>계약시작</label>
                            </div>

                            <div className='st'>
                                <div>
                                    <label htmlFor='from_date' className='bg'>시작일</label>
                                    <input type="date" name="from_date" id="from_date" value={from_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                                <div>
                                    <label htmlFor='to_date' className='bg'>종료일</label>
                                    <input type="date" name="to_date" id="to_date" value={to_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                            </div>

                            <div className='btn btn-area'>
                                <a className='save-btn' href="#!" onClick={handleClickXLSX} style={{textDecoration:"none"}}>Excel</a>
                                <button className='green-sch-btn'>검색</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <h2>검색결과</h2>
            <table id="tblList" className='tt'>
                <thead>
                    {/* Excel Down시 Colspan 미적용 현상 방지 */}
                    <tr>
                        <th>번호</th>
                        <th>담당법무법인</th>
                        <th>납품일</th>
                        <th>계약일</th>
                        <th>계약요일</th>
                        <th>잔금일</th>
                        <th>입주자</th>
                        <th>생년월일</th>
                        <th>임대인</th>
                        <th>계약구</th>
                        <th>주소</th>
                        <th>신규/재계약/묵시</th>
                        <th>지원유형</th>
                        <th>상세유형</th>
                        <th>LH지원금</th>
                        <th>본인부담금</th>
                        <th>월세</th>
                        <th>전세보증금</th>
                        <th>도배장판</th>
                        <th>비고</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{item.contract_company}</td>
                                <td>{item.delivery_date}</td>
                                <td>{item.contract_date}</td>
                                <td>{item.day_name}</td>
                                <td>{item.balance_payment_date}</td>
                                <td>{item.cust_name}</td>
                                <td>{item.cust_birth}</td>
                                <td>{item.lessor_name}</td>
                                <td>{item.sigungu}</td>
                                <td>{item.addr}</td>
                                <td>{item.contract_type_name}</td>
                                <td>{item.winner_type_name}</td>
                                <td>{item.winner_dtl_type}</td>
                                <td>{item.lh_support_amt}</td>
                                <td>{item.winner_charge}</td>
                                <td>{item.monthly_amt}</td>
                                <td>{item.rent_amt}</td>
                                <td>{item.wallpaper_flag}</td>
                                <td>{item.remark}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </ContractProcBlock>
    );
}

export default ContractProc;