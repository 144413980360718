import React from 'react';
import styled from 'styled-components';
import dataWORD from '../../lib/dataWord';

const FindUserBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 800px;
        padding: 60px 50px;
    }
    .popupModal > div:nth-child(2) h2{
        color: #545454;
        font-size: 26px;
        text-align: center;
        display: block;
        margin-bottom: 50px;
    }
    .userInfor{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .userInfor>div{
        display: flex;
        align-items: center;
    }
    .userInfor>div>label{
        color: #545454;
        margin-right: 4px;
    }
    .userInfor>div>input{
        width: 170px;
        height: 26px;
        color: #545454;
    }
    
    .divTbl {
        height: 260px;
        overflow-y: auto;
        border : 1px solid #bdbdbd;
        border-radius : 3px;
    }
    .divTbl table {
        width: 100%;
        border-collapse: collapse;
        font-size: 12px;
    }
    .divTbl table th, .divTbl table td {
        text-align: center;
        min-width: 80px;
    }
    .divTbl tbody tr:hover {
        background-color: #ccc;
        cursor: pointer;
    }
    .bdnone-cancel-btn{
        position: absolute;
        top: 20px;
        right: 0;
        font-size: 24px;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px){
        .popupModal > div:nth-child(2){
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 100px 30px;
            overflow: auto;
        }
        h2{
            font-size: 30px !important;
        }
        .userInfor{
            display: block;
            flex-wrap: wrap;
            margin-bottom: 40px;
        }
        .userInfor>div{
            width: 100%;
            margin-bottom: 5px;
        }
        .userInfor>div>label{
            width: 30%;
        }
        .userInfor>div>input{
            width: 70%;
        }
        .pop-sch-btn{ 
            width: 100%; 
            height: 40px;
            margin-top: 30px;
            font-size: 16px; 
        }
        .tt thead tr{
            font-size: 13px;
        }
        .tt tbody tr{
            font-size: 12px;
        }
        .bdnone-cancel-btn{
            font-size: 30px;
            right: -10px;
        }
    }
    
`;

const FindUser = (props) => {
    const {
        userName,
        userNumber,
        users,
        onCloseFindUser,

        onChange,
        onKeyDown,
        onSearch,
        onSelected,
    } = props;

    return (
        <FindUserBlock>
            <div className='popupModal'>{/* [Dev]22.02.17 popupWrap class 추가 */}
                <div />
                <div>
                    <h2>대상자 검색</h2>

                    <div className='userInfor'>
                        <div>
                            <label htmlFor="userName">이름</label>
                            <input type="text" name="userName" id="userName" value={userName} onChange={onChange} onKeyDown={onKeyDown} />
                        </div>
                        <div>
                            <label htmlFor="userNumber">생년월일(YYMMDD)</label>
                            <input type="number" name="userNumber" id="userNumber" value={userNumber} onChange={onChange} maxLength={6} onKeyDown={onKeyDown} />
                        </div>

                        <button onClick={onSearch} className="pop-sch-btn">검색</button>
                    </div>

                    <div className="divTbl">
                        <table className='tt'>
                            <thead>
                                <tr>
                                    <th>이름</th>
                                    <th>{dataWORD.BIRTHDATE}</th>
                                    <th>지원유형</th>
                                    <th>전화번호</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.length === 0 &&
                                    <tr>
                                        <td colSpan={5}>검색 결과가 없습니다.</td>
                                    </tr>
                                }
                                {users.map((item, idx) => (
                                    <tr key={idx} onClick={onSelected.bind(this, item)}>
                                        <td>{item.name}</td>
                                        <td>{item.winner_no}</td>
                                        <td>{item.type_name}</td>
                                        <td>{item.phone}</td>
                                        {/* <td>{item.addr}</td> */}
                                        <td></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <button onClick={onCloseFindUser} className="bdnone-cancel-btn">X</button>
                </div>
            </div>
        </FindUserBlock>
    );
}

export default FindUser;