import { createAction, handleActions } from 'redux-actions';
import * as adminAPI from '../lib/api/admin';
import * as entitleAPI from '../lib/api/entitle';
import * as scheAPI from '../lib/api/sche';
import { takeLatest, call, put } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'entitleWrite/INITIALIZE';
const CHANGE_VALUE = 'entitleWrite/CHANGE_VALUE';

const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('entitleWrite/GET_CONTENT');
const [DELETE_CONTENT, DELETE_CONTENT_SUCCESS, DELETE_CONTENT_FAILURE] = createRequestActionTypes('entitleWrite/DELETE_CONTENT');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('entitleWrite/GET_COMBO');
const SET_DOC_ID = 'entitleWrite/SET_DOC_ID';
const [SET_STATUS_API, SET_STATUS_API_SUCCESS, SET_STATUS_API_FAILURE] = createRequestActionTypes('entitleWrite/SET_STATUS_API');
const [GET_WINNER, GET_WINNER_SUCCESS, GET_WINNER_FAILURE] = createRequestActionTypes('entitleWrite/GET_WINNER');

const S1_ADD_ROW = 'entitleWrite/S1_ADD_ROW';
const S1_REMOVE_ROW = 'entitleWrite/S1_REMOVE_ROW';
const S1_SET_LAST_IDX = 'entitleWrite/S1_SET_LAST_IDX';
const S1_ROW_CHANGE_VALUE = 'entitleWrite/S1_ROW_CHANGE_VALUE';
const S1_SELECT_USER = 'entitleWrite/S1_SELECT_USER';
const [S1_SAVE, S1_SAVE_SUCCESS, S1_SAVE_FAILURE] = createRequestActionTypes('entitleWrite/S1_SAVE');

const [S2_SAVE, S2_SAVE_SUCCESS, S2_SAVE_FAILURE] = createRequestActionTypes('entitleWrite/S2_SAVE');

const S3_ADD_ROW = 'entitleWrite/S3_ADD_ROW';
const S3_REMOVE_ROW = 'entitleWrite/S3_REMOVE_ROW';
const S3_SET_LAST_IDX = 'entitleWrite/S3_SET_LAST_IDX';
const S3_ROW_CHANGE_VALUE = 'entitleWrite/S3_ROW_CHANGE_VALUE';
const S3_PRIORITY_CHANGED = 'entitleWrite/S3_PRIORITY_CHANGED';
const [S3_SAVE, S3_SAVE_SUCCESS, S3_SAVE_FAILURE] = createRequestActionTypes('entitleWrite/S3_SAVE');

const S4_ADD_ROW = 'entitleWrite/S4_ADD_ROW';
const S4_REMOVE_ROW = 'entitleWrite/S4_REMOVE_ROW';
const S4_ROW_CHANGE_VALUE = 'entitleWrite/S4_ROW_CHANGE_VALUE';
const [DOC_TYPE, DOC_TYPE_SUCCESS, DOC_TYPE_FAILURE] = createRequestActionTypes('entitleWrite/DOC_TYPE');
const [DOC_REQ, DOC_REQ_SUCCESS, DOC_REQ_FAILURE] = createRequestActionTypes('entitleWrite/DOC_REQ');
const [SET_STATUS, SET_STATUS_SUCCESS, SET_STATUS_FAILURE] = createRequestActionTypes('entitleWrite/SET_STATUS');
const [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE] = createRequestActionTypes('entitleWrite/SEND_SMS');
const [FILE_ADD, FILE_ADD_SUCCESS, FILE_ADD_FAILURE] = createRequestActionTypes('entitleWrite/FILE_ADD');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));

export const getContent = createAction(GET_CONTENT);
export const delContent = createAction(DELETE_CONTENT);
export const getCombo = createAction(GET_COMBO);
export const setDocID = createAction(SET_DOC_ID, (value) => (value));
export const setStatusApi = createAction(SET_STATUS_API, (body) => (body));

export const s1_addRow = createAction(S1_ADD_ROW, (idx) => idx);
export const s1_removeRow = createAction(S1_REMOVE_ROW, ({idx}) => ({idx}));
export const s1_setLastIdx = createAction(S1_SET_LAST_IDX, ({value}) => ({value}));
export const s1_rowChangeValue = createAction(S1_ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const s1_selectUser = createAction(S1_SELECT_USER, ({user, row}) => ({user, row}));
export const s1_getWinner = createAction(GET_WINNER, (body) => (body));
export const s1_save = createAction(S1_SAVE, (body) => (body));

export const s2_save = createAction(S2_SAVE, (body) => (body));

export const s3_addRow = createAction(S3_ADD_ROW, (idx) => idx);
export const s3_removeRow = createAction(S3_REMOVE_ROW, ({idx}) => ({idx}));
export const s3_setLastIdx = createAction(S3_SET_LAST_IDX, ({value}) => ({value}));
export const s3_rowChangeValue = createAction(S3_ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const s3_priorityChanged = createAction(S3_PRIORITY_CHANGED, ({idx, value}) => ({idx, value}));
export const s3_save = createAction(S3_SAVE, (body) => (body));

export const s4_addRow = createAction(S4_ADD_ROW, (subCode) => subCode);
export const s4_removeRow = createAction(S4_REMOVE_ROW, ({subCode, idx}) => ({subCode, idx}));
export const s4_rowChangeValue = createAction(S4_ROW_CHANGE_VALUE, ({idx, fileReader, fileName, fileExtension}) => ({idx, fileReader, fileName, fileExtension}));
export const s4_doc_type = createAction(DOC_TYPE);
export const docReq = createAction(DOC_REQ, ({doc_id, doc_type, doc_status, fileList, winner}) => ({doc_id, doc_type, doc_status, fileList, winner}));
export const setStatus = createAction(SET_STATUS, (body) => (body));
export const sendSms = createAction(SEND_SMS, (body) => (body));
export const fileAdd = createAction(FILE_ADD, ({doc_id, doc_type, fileList}) => ({doc_id, doc_type, fileList}));

const getContentSaga = createRequestSaga(GET_CONTENT, entitleAPI.enwrite_view);
const delContentSaga = createRequestSaga(DELETE_CONTENT, entitleAPI.endelete);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_multi_search);
const getDocTypeSaga = createRequestSaga(DOC_TYPE, adminAPI.doc_search);

const s1_getWinnerSaga = createRequestSaga(GET_WINNER, entitleAPI.winner_search);
const s1_saveSaga = createRequestSaga(S1_SAVE, entitleAPI.enwrite1);
const s2_saveSaga = createRequestSaga(S2_SAVE, entitleAPI.enwrite2);
const s3_saveSaga = createRequestSaga(S3_SAVE, entitleAPI.enwrite3);
const setStatusSaga = createRequestSaga(SET_STATUS, entitleAPI.enreq);
const sendSmsSaga = createRequestSaga(SEND_SMS, scheAPI.smsSend);

//  동의 및 신청하기
function* docRequest(body) {
    try {
        const docId = body.payload.doc_id;
        const docType = body.payload.doc_type;
        const files = body.payload.fileList;
        const winners = body.payload.winner;
        const doc_status = body.payload.doc_status;

        //  Step1. 파일 업로드 저장
        for(let k = 0; k < docType.length; k++) {
            const _doc = docType[k];

            for(let i = 0; i < files.length; i++) {
                const _file = files[i];

                if(!_file.file_id && _file.use_flag === "N") {
                    continue;   // 처음 등록시(file_id가 없는)에는 use_flag가 N인(삭제된) 파일을 제외하고 전송한다.
                }

                if(_doc.sub_code === _file.file_type && _file.file_name) {
                    // file_id가 있다면 file_data를 ''으로 넘겨도, contenttype이 비어있기에 백엔드에서 어떠한 조치도 하지 않는다.
                    const fileData = {
                        doc_id: docId,
                        files:
                        [
                            {
                                doc_type: 'EN_WRITE',
                                file_id: _file.file_id,
                                file_type: _file.file_type,
                                file_name: _file.file_name,
                                contenttype: _file.file_extension,
                                file_data: _file.file_id ? "" : _file.file_data.split(',').slice(-1)[0],
                                use_flag: _file.use_flag,
                            }
                        ]
                    }

                    yield call(entitleAPI.enwrite4, fileData);
                }
            };
        }
        yield put({
            type: DOC_REQ_SUCCESS,
            payload: doc_status,
        });

            //  최초신청(작성중) 문서에서 파일을 저장할 때만 STATUS를 변경하고 문자를 발송한다.
            if(doc_status !== 'ENSAVE') {

                //  Step2. 문서 상태를 ENSAVE로 변경
                try {
                    const statusData = {
                        doc_id: docId,
                        status: "ENSAVE",
                        remark: "",
                        lack_reason: "",
                    }
                    const status = yield call(entitleAPI.status, statusData);
                    yield put({
                        type: SET_STATUS_API_SUCCESS,
                        payload: status,
                    });

                        //  Step3. 당첨자에게 문자 발송
                        try {
                            let _receiver = '';
                            for(let i = 0; i < winners.length; i++) {
                                _receiver += winners[i].winner_phone ? winners[i].winner_phone + "," : "";
                            }
                            if(_receiver) {
                                const smsData = {
                                    sender: "1600-4690",
                                    receiver: _receiver,
                                    msg: "전세임대 지원신청이 완료되었습니다. https://cafe.naver.com/hongbj13/115 및 진행현황보기 www.2zip.co.kr 로그인",
                                    msg_type: "SMS",
                                }
                                yield call(scheAPI.smsSend, smsData);
                                yield put({
                                    type: SEND_SMS_SUCCESS,
                                });
                            }
                        }
                        catch(e) {
                            yield put({
                                type: SEND_SMS_FAILURE,
                                error: true,
                                payload: e
                            });
                        }

                }
                catch(e) {
                    yield put({
                        type: SET_STATUS_API_FAILURE,
                        error: true,
                        payload: e
                    });
                }
            }
    }
    catch(e) {
        yield put({
            type: DOC_REQ_FAILURE,
            error: true,
            payload: e
        });
    }
}

//  서류 보완
function* docFailAdd(body) {
    try {
        const docId = body.payload.doc_id;
        const docType = body.payload.doc_type;
        const files = body.payload.fileList;

        //  Step1. 파일 업로드 저장
        for(let k = 0; k < docType.length; k++) {
            const _doc = docType[k];

            for(let i = 0; i < files.length; i++) {
                const _file = files[i];

                if(!_file.file_id && _file.use_flag === "N") {
                    continue;   // 처음 등록시(file_id가 없는)에는 use_flag가 N인(삭제된) 파일을 제외하고 전송한다.
                }

                if(_doc.sub_code === _file.file_type && _file.file_name) {
                    // file_id가 있다면 file_data를 ''으로 넘겨도, contenttype이 비어있기에 백엔드에서 어떠한 조치도 하지 않는다.
                    const fileData = {
                        doc_id: docId,
                        files:
                        [
                            {
                                doc_type: 'EN_WRITE',
                                file_id: _file.file_id,
                                file_type: _file.file_type,
                                file_name: _file.file_name,
                                contenttype: _file.file_extension,
                                file_data: _file.file_id ? "" : _file.file_data.split(',').slice(-1)[0],
                                use_flag: _file.use_flag,
                            }
                        ]
                    }

                    yield call(entitleAPI.enwrite4, fileData);
                }
            };
        }
        yield put({
            type: FILE_ADD_SUCCESS,
            payload: [],
        });

            //  Step2. 문서 상태를 다음 단계로 변경
            try {
                const statusData = {
                    doc_id: docId,
                }
                const status = yield call(entitleAPI.enreq, statusData);
                yield put({
                    type: SET_STATUS_SUCCESS,
                    payload: status,
                });
            }
            catch(e) {
                yield put({
                    type: SET_STATUS_FAILURE,
                    error: true,
                    payload: e
                });
            }
    }
    catch(e) {
        yield put({
            type: FILE_ADD_FAILURE,
            error: true,
            payload: e
        });
    }
}

export function* entitleWriteSaga() {
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(GET_CONTENT, getContentSaga);
    yield takeLatest(DELETE_CONTENT, delContentSaga);
    yield takeLatest(DOC_TYPE, getDocTypeSaga);
    yield takeLatest(GET_WINNER, s1_getWinnerSaga);
    yield takeLatest(S1_SAVE, s1_saveSaga);
    yield takeLatest(S2_SAVE, s2_saveSaga);
    yield takeLatest(S3_SAVE, s3_saveSaga);
    yield takeLatest(DOC_REQ, docRequest);
    yield takeLatest(SET_STATUS, setStatusSaga);
    yield takeLatest(SEND_SMS, sendSmsSaga);
    yield takeLatest(FILE_ADD, docFailAdd);
}

const initialState = {
    doc_id: '',
    doc_status: '',

    // Common
    cboApply: [],
    createUser: '',

    // Step1.
    winnerLastIdx: 0,
    winner: [{
        idx: 0,
        contract_type: '',
        winner_type: '',
        winner_type_name: '',
        winner_id: '',
        winner_name: '',
        winner_no: '',
        winner_addr: '',
        winner_tel: '',
        winner_phone: '',
        family_cnt: 0,
        charge_rate: null,
        winner_charges: '',
        add_charges: '',
        monthly_rent: '',
        lh_support_amt: '',
        type_calc_code: '',
        is_duplicate: '',
        info_yn: '',
        writing_doc_id: '',
        select_win_type: '',
    }],
    privacy_terms_agree: '',
    winner_type_forS4: '',

    // Step2.
    broker_company: '',
    broker_telno: '',
    broker_reg_no: '',
    road_addr: '',
    road_addr_dtl: '',
    jibun_addr: '',
    sido: '',
    sigungu: '',
    town: '',
    road_name: '',
    building_num: '',
    building_name: '',
    house_type: '',
    house_area: '',
    room_count: '',
    house_selling: '',
    house_area_over1: '',
    house_area_over2: '',
    house_rent_type: '',
    rent_amt: '',
    monthly_rent_amt: '',
    minor_child_cnt: '',
    estate_no: '',
    wallpaper_flag: '',

    // Step3.
    priorityLastIdx: 0,
    priority: [{
        rent_seq: 0,
        empty_type: 'HOUSE_RENT',
        rent_floor: '',
        room_no: '',
        room_cnt: '',
        rent_deposit: '',
        rent_fee: '',
        priority_payment: '',
        remark: '',
        use_flag: 'Y',
    }],
    rent_tot_amt: '',

    // Step4.
    doc_type: [
        {
            admin_type: "",
            code_name: "기타",
            display: 3,
            idx: 0,
            main_code: "TYPE001",
            memo: "",
            sub_code: "DOC000",
            use_flag: "Y",
        }
    ],
    fileLastIdx: 0,
    fileList: [],
    lackReason: '',

    goList: false,
    
    write: null,
    error: null
};

const entitleWrite = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [GET_COMBO_SUCCESS]: (state, { payload: {APPLY_TYPE} }) => {
            //  지원유형 첫번째 item으로 [선택] 항목 추가
            const _APPLY_TYPE = APPLY_TYPE;
            _APPLY_TYPE.unshift({
                admin_type: null,
                code_name: "선택해주세요.",
                display: -1,
                idx: -1,
                main_code: "APPLY_TYPE",
                memo: "",
                sub_code: "",
                use_flag: "Y",
            });

            return {
                ...state,
                cboApply: _APPLY_TYPE,
            }
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [SET_DOC_ID]: (state, { payload }) => {
            return {
                ...state,
                doc_id: payload,
            }
        },

        [GET_CONTENT_SUCCESS]: (state, { payload }) => {
            if(payload.length === 0) {
                return;
            }

            let _winner = [];
            if(payload.winner.length === 0) {
                _winner.push({
                    idx: 0,
                    contract_type: payload.entitle[0].contract_type,
                    winner_type: '',
                    winner_type_name: '',
                    winner_id: '',
                    winner_name: payload.entitle[0].winner_name,
                    winner_no: payload.entitle[0].winner_birth,
                    winner_addr: '',
                    winner_tel: '',
                    winner_phone: '',
                    family_cnt: 0,
                    charge_rate: null,
                    winner_charges: '',
                    add_charges: '',
                    monthly_rent: '',
                    lh_support_amt: '',
                    type_calc_code: '',
                    is_duplicate: '',
                    info_yn: '',
                    writing_doc_id: '',
                    select_win_type: '',
                })
            }
            else {
                _winner = payload.winner;
            }
            const _winner_type_forS4 = _winner[_winner.length - 1].winner_type;
            
            //  선순위 Seq 계산
            let _priorityLastIdx = 0;
            payload.rent.forEach(item => {
                if(item.use_flag === 'Y') {
                    if(_priorityLastIdx < item.rent_seq) {
                        _priorityLastIdx = item.rent_seq;
                    }
                }
            });

            return {
                ...state,
                doc_status: payload.entitle[0].status,

                winnerLastIdx: _winner.length,
                winner: _winner,
                winner_type_forS4: _winner_type_forS4,

                broker_company: payload.entitle[0].broker_company,
                broker_telno: payload.entitle[0].broker_phone,
                broker_reg_no: payload.entitle[0].broker_reg_no,
                road_addr: payload.entitle[0].road_addr,
                road_addr_dtl: payload.entitle[0].road_addr_dtl,
                jibun_addr: payload.entitle[0].jibun_addr,
                sido: payload.entitle[0].sido,
                sigungu: payload.entitle[0].sigungu,
                town: payload.entitle[0].town,
                road_name: payload.entitle[0].road_name,
                building_num: payload.entitle[0].building_num,
                building_name: payload.entitle[0].building_name,
                house_type: payload.entitle[0].house_type,
                house_area: payload.entitle[0].house_area,
                room_count: payload.entitle[0].room_count,
                house_selling: payload.entitle[0].house_selling,
                house_area_over1: payload.entitle[0].house_area_over1 === "Y" ? true : false,
                house_area_over2: payload.entitle[0].house_area_over2 === "Y" ? true : false,
                house_rent_type: payload.entitle[0].rent_type,
                rent_amt: payload.entitle[0].rent_amt,
                monthly_rent_amt: payload.entitle[0].monthly_rent_amt,
                minor_child_cnt: payload.entitle[0].minor_child_cnt,
                estate_no: payload.entitle[0].estate_no,
                wallpaper_flag: payload.entitle[0].wallpaper_flag,
                createUser: payload.entitle[0].create_user,
                lackReason: payload.entitle[0].lack_reason,

                priorityLastIdx: _priorityLastIdx,
                priority: payload.rent,

                rent_tot_amt: payload.entitle[0].rent_tot_amt,

                fileLastIdx: payload.files.length,
                fileList: payload.files,
            }
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [DELETE_CONTENT_SUCCESS]: (state, { payload }) => {
            ALERT("신청서가 삭제되었습니다.");
            return {
                ...state,
            }
        },
        [DELETE_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        //  Step1   =======================================================================================
        [S1_ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _winner = state.winner.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                winner: _winner,
            };
        },
        [S1_SET_LAST_IDX]: (state, { payload: {value}}) => ({
            ...state,
            winnerLastIdx: value,
        }),
        [S1_ADD_ROW]: (state) => {
            return {
                ...state,
                winner: state.winner.concat({
                            idx: state.winnerLastIdx,
                            contract_type: '',
                            winner_type: '',
                            winner_type_name: '',
                            winner_id: '',
                            winner_name: '',
                            winner_no: '',
                            winner_addr: '',
                            winner_tel: '',
                            winner_phone: '',
                            family_cnt: 0,
                            charge_rate: null,
                            winner_charges: '',
                            add_charges: '',
                            monthly_rent: '',
                            lh_support_amt: '',
                            type_calc_code: '',
                            is_duplicate: '',
                            info_yn: '',
                            writing_doc_id: '',
                            select_win_type: '',
                        }),
            }
        },
        [S1_REMOVE_ROW]: (state, { payload: {idx}}) => {
            const _winner = state.winner.filter((item) => item.idx !== idx);
            return {
                ...state,
                winner: _winner,
            };
        },
        [S1_SELECT_USER]: (state, { payload: {user, row} }) => {
            const _winner = state.winner.map((item) =>
                item.idx === row.idx ? { ...item,
                    contract_type: '',
                    winner_type: !user.type ? '' : user.type,
                    winner_type_name: user.type_name,
                    winner_id: user.winner_id,
                    winner_name: user.name,
                    winner_no: user.winner_no,
                    winner_addr: user.addr,
                    winner_tel: '',
                    winner_phone: user.phone,
                    family_cnt: user.childrun_cnt,
                    charge_rate: null,
                    winner_charges: '',
                    add_charges: '',
                    monthly_rent: '',
                    lh_support_amt: '',
                    type_calc_code: user.type_calc_code,
                    is_duplicate: user.is_duplicate,
                    info_yn: user.info_yn,
                    writing_doc_id: user.writing_doc_id,
                    select_win_type: user.select_win_type,
                } : item,
            );
            return {
                ...state,
                winner: _winner,
                doc_id: user.writing_doc_id ? user.writing_doc_id : state.doc_id,
            };
        },
        [GET_WINNER_SUCCESS]: (state, {payload}) => {
            if(payload.length === 0) {
                ALERT("검색 결과가 없습니다.");
                return {
                    ...state,
                }
            }
            else {
                const user = payload[0];
                const _winner = state.winner.map((item) =>
                    item.idx === 0 ? { ...item,
                        contract_type: '',
                        winner_type: !user.type ? '' : user.type,
                        winner_type_name: user.type_name,
                        winner_id: user.winner_id,
                        winner_name: user.name,
                        winner_no: user.winner_no,
                        winner_addr: user.addr,
                        winner_tel: '',
                        winner_phone: user.phone,
                        family_cnt: user.childrun_cnt,
                        charge_rate: null,
                        winner_charges: '',
                        add_charges: '',
                        monthly_rent: '',
                        lh_support_amt: '',
                        type_calc_code: user.type_calc_code,
                        is_duplicate: user.is_duplicate,
                        info_yn: user.info_yn,
                        writing_doc_id: user.writing_doc_id,
                        select_win_type: user.select_win_type,
                    } : item,
                );
                return {
                    ...state,
                    winner: _winner,
                    doc_id: user.writing_doc_id ? user.writing_doc_id : state.doc_id,
                }
            }
        },
        [GET_WINNER_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [S1_SAVE_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
                doc_id: payload,
            })
        },
        [S1_SAVE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  Step2   =======================================================================================
        [S2_SAVE_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [S2_SAVE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  Step3   =======================================================================================
        [S3_ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _priority = state.priority.map((item) =>
                item.rent_seq === idx ? { ...item, [key]: value } : item,
            );

            return {
                ...state,
                priority: _priority,
            };
        },
        [S3_PRIORITY_CHANGED]: (state, { payload: { idx, value } }) => {
            const _priority = state.priority.map((item) =>
                item.rent_seq === idx ? {
                    ...item,
                    rent_deposit: "",
                    rent_fee: "",
                    priority_payment: String(value),
                } : item,
            );

            return {
                ...state,
                priority: _priority,
            };
        },
        [S3_SET_LAST_IDX]: (state, { payload: {value}}) => ({
            ...state,
            priorityLastIdx: value,
        }),
        [S3_ADD_ROW]: (state) => {
            return {
                ...state,
                priority: state.priority.concat({
                            rent_seq: state.priorityLastIdx,
                            empty_type: 'HOUSE_RENT',
                            rent_floor: '',
                            room_no: '',
                            room_cnt: '',
                            rent_deposit: '',
                            rent_fee: '',
                            priority_payment: '',
                            remark: '',
                            use_flag: 'Y',
                        }),
            }
        },
        [S3_REMOVE_ROW]: (state, { payload: {idx}}) => {
            // const _priority = state.priority.filter((item) => item.rent_seq !== idx);
            const _priority = state.priority.map((item) =>
                item.rent_seq === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );

            return {
                ...state,
                priority: _priority,
            };
        },
        [S3_SAVE_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [S3_SAVE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  Step4   =======================================================================================
        [S4_ROW_CHANGE_VALUE]: (state, { payload: { idx, fileReader, fileName, fileExtension } }) => {
            const _fileList = state.fileList.map((item) =>
                item.idx === idx ?
                {
                    ...item,
                    file_name: fileName,
                    file_extension: fileExtension,
                    file_data: fileReader.result,
                } : item,
            );
            return {
                ...state,
                fileList: _fileList,
            };
        },
        [S4_ADD_ROW]: (state, { payload }) => {
            const _fileLastIdx = state.fileLastIdx + 1;
            return {
                ...state,
                fileLastIdx: _fileLastIdx,
                fileList: state.fileList.concat({
                            idx: _fileLastIdx,
                            file_id: '',
                            file_type: payload.subCode,
                            file_name: '',
                            file_extension: '',
                            file_data: '',
                            use_flag: "Y",
                        }),
            }
        },
        [S4_REMOVE_ROW]: (state, { payload: {subCode, idx}}) => {
            const _fileList = state.fileList.map((item) =>
                item.file_type === subCode && item.idx === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );

            return {
                ...state,
                fileList: _fileList,
            };
        },
        [DOC_TYPE_SUCCESS]: (state, {payload}) => {
            let _doc_type = payload.filter(x => x.use_flag === "Y");
            if(_doc_type.length === 0) {
                _doc_type.push({
                    admin_type: "",
                    code_name: "기타",
                    display: 3,
                    idx: 0,
                    main_code: "TYPE001",
                    memo: "",
                    sub_code: "DOC000",
                    use_flag: "Y",
                })
            }
            
            // let lastIdx;
            
            // let _fileList = [];
            // payload.forEach(item => {
            //     lastIdx = item.idx;

            //     _fileList.push({
            //         idx: item.idx,
            //         file_id: '',
            //         file_type: item.sub_code,
            //         file_name: '',
            //         file_extension: '',
            //         file_data: '',
            //         use_flag: "Y",
            //     });
            // });
            return {
                ...state,
                doc_type: _doc_type,
                // fileLastIdx: lastIdx,
                // fileList: _fileList,
            }
        },
        [DOC_TYPE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [DOC_REQ_SUCCESS]: (state, { payload: doc_status }) => {
            if(doc_status === 'ENSAVE') {
                ALERT('서류가 추가되었습니다.');
            }
            return ({
                ...state,
                error: null,
                goList: doc_status === 'ENSAVE',
            })
        },
        [DOC_REQ_FAILURE]: (state, { payload: error }) => {
            ALERT("파일 업로드 중 오류가 발생하였습니다");
            return ({
                ...state,
                error,
            })
        },
        [SET_STATUS_API_SUCCESS]: (state, { payload }) => {
            ALERT("신청이 완료되었습니다.");
            return ({
                ...state,
                doc_status: payload,
                error: null,
                goList: true,
            })
        },
        [SET_STATUS_API_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error,
            }
        },
        [SEND_SMS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SEND_SMS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        [SET_STATUS_SUCCESS]: (state, { payload }) => {
            ALERT("서류가 보완되었습니다.");
            return ({
                ...state,
                error: null,
                goList: true,
            })
        },
        [SET_STATUS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default entitleWrite;