import React from 'react';
import styled from 'styled-components';

const MenuAuthBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0;
    padding: 30px 50px;
    box-sizing: border-box;

    .dkgreen-btn{
        margin-bottom: 15px;
    }

    .containerDiv {
        display: flex;
        align-items: flex-start;
        height: 78vh;
        gap: 45px;
    }
    .containerDiv > div {
        height: 100%;
        // overflow-y: auto;
        border: 1px solid #ccc;
    }
    .containerDiv > div:first-child{
        width: 30%;
    }
    .containerDiv > div:last-child{
        width: 70%;
    }


    .menuFunc {
        color: #545454;
        padding: 20px 25px;
        margin-bottom: 4px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #ffffff;
    }
    .menuFunc > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
`;

const MenuAuth = (props) => {
    const {
        auth,
        menu,
        
        // menuAuth,
        // error,
        onRowClick,
        onAuthChange,
        onSaveClick,
    } = props;

    return (
        <MenuAuthBlock>
            <button onClick={onSaveClick} className="dkgreen-btn">저장</button>

            <div className='containerDiv'>
                <div>
                    <table className='border-tt'>
                        <thead>
                            <tr>
                                <th>그룹코드</th>
                                <th>그룹명</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auth.map((item) => (
                                <tr key={item.sub_code} onClick={() => onRowClick(item)}>
                                    <td>{item.sub_code}</td>
                                    <td>{item.code_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div>
                    {menu.map((menu, menuIdx) => (
                        <div key={menuIdx} className='menuFunc'>
                            <label>
                                <span>메뉴 : </span>
                                <input type="checkbox" name="auth_yn" id={"m_auth_yn" + menuIdx} checked={menu.auth_yn} onChange={(e) => onAuthChange(e, menu)} />
                                <label htmlFor={"m_auth_yn" + menuIdx}>{menu.name}</label>
                            </label>
                            {menu.func.map((func, funcIdx) => (
                                <div key={funcIdx}>
                                    <label>
                                        <span>기능 : </span>
                                        <input type="checkbox" name="auth_yn" id={"f_auth_yn" + menuIdx + funcIdx} checked={func.auth_yn} onChange={(e) => onAuthChange(e, func)} />
                                        <label htmlFor={"f_auth_yn" + menuIdx + funcIdx}>{func.name}</label>
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </MenuAuthBlock>
    );
}

export default MenuAuth;