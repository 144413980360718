import React, { Fragment } from 'react';
import styled from 'styled-components';
import EnwAdminUploadContainer from '../containers/Modal/EnwAdminUploadContainer';
import FindBrokerContainer from '../containers/Modal/FindBrokerContainer';
import FindUserContainer from '../containers/Modal/FindUserContainer';
import DaumPostcode from "react-daum-postcode";
import dataWORD from '../lib/dataWord';
import rotate from '../rotation.png';
import savedisk from '../savedisk.png';

const EnWriteAdminBlock = styled.div`
    width: 100%;    
    height: 93.5vh;
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 20px 40px;
    box-sizing: border-box;
    position: relative;
   

    // 리스트 형식 테이블
    .tt-list{
        width: 100%; 
        border-collapse: collapse; 
        text-align: center;
        border-radius: 0.31rem; 
        background-color: #ffffff;
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);
    }
    .tt-list thead{ 
        border-bottom: 1px solid #0B999C;
    }
    .tt-list thead tr{
        color:#0B999C; 
        font-size: 13px;
        font-weight: 600;
    }
    .tt-list tbody td{
        color: #494949;
        font-size: 12px;
    }
    .tt-list tr{
        height: 2.75rem;
    }
    .tth th{
        width: 110px;
    }

    //표 테이블
    .border-form-write{
        width:100%;
    }
    .border-form-write table{
        width:100%;
        table-layout: fixed;
    }
    .border-form-write table th{
        font-size: 13px; 
        color:#545454; 
        vertical-align: middle; 
        word-break: break-all;
        text-align: center; 
        font-weight: normal;
    }
    .border-form-write table td{
        font-size: 13px; 
        color: #05141f; 
        vertical-align: middle; 
        word-break: break-all;
        border:1px solid #BDBDBD;
        border-radius: 3px; 
        background-color: #ffffff;
    }
    .border-form-write table tbody th{ 
        background:#DDDDDD; border-radius: 3px;
    }


    .divContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        // top:-30px;
    }
     .divContainer h4 {
        color: #545454;
        font-size: 20px;
        display: flex;
        align-items: center;
        margin: 15px 0 5px 0;
    }
    .divContainer h4 button{
        margin-left: 5px;
    }
    .divWrite.readonly {
        pointer-events: none;
    }
    .divWrite {
        width: 56%;
        padding-right: 20px;
        overflow-y: auto;
    }
    .divWrite table {
        width: 100%;
    }

    .flexDiv {
        display: flex;
        flex-wrap: nowrap;
    }
    .flexDiv table:last-child {
        margin-right:0;
    }

    .divImages {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .folderBox .folder-on {
        color: #ffffff; 
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }

    .calcResult {
        margin: 10px 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif;
    }
    .calcResult.calcPOSSIBLE h4{ color: green; }
    .calcResult.calcIMPOSSIBLE h4{ color: red; }
    .calcResult > * {
        margin: 0;
    }
    .mandatory {
        background-color: yellow;
    }
    .cantWrite {
        background-color: lightgray;
    }

    .divContainer.cont{
        display:block;
        position:relative;
        overflow-x:auto;
    }
    .divContainer.wrap{
        min-width:1600px;
    }

    .img-area {
        width: 44%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .refTabOn {
        color: #ffffff; 
        padding: 5px 10px;
        cursor: pointer;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
        margin-bottom: 10px;
    }
    .refTabOn > span {
        line-height: 30px;
        margin: 0 10px;
    }
    .imgView {
        padding: 0;
    }
    .selete-thumbnail {
        border: 3px solid red;
    }
    .icon-rotate, .icon-savedisk {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 20%;
        cursor: pointer;
        opacity: 0.2;
    }
    .icon-rotate {
        top: 10px;
        right: 155px;
    }
    .icon-savedisk {
        top: 50px;
        right: 155px;
    }
    .icon-rotate:hover, .icon-savedisk:hover {
        background-color: orange;
        opacity: 1;
    }
    #div-rotate {
        position: absolute;
        width: -webkit-calc(100% - 145px);
        height: 100%;
    }
    .rotate-0 { transform: rotate(0deg); }
    .rotate-90 { transform: rotate(90deg); }
    .rotate-180 { transform: rotate(180deg); }
    .rotate-270 { transform: rotate(270deg); }
`;

const WriteStep1 = styled.div`
    select{
        font-size: 12px;
    }
`;
const WriteStep2 = styled.div`
    .tth1 tbody tr{
        position: relative;
    }
    .tth1 input[type=text]{
        width: 100%;
        border: none;
    }
    .tth1 button:nth-child(2){
        position: absolute;
        top: 0px;
        right: 0;
    }
    .tth1 input[type=text]:nth-child(3){
        border-top: 1px solid #BDBDBD;
    }
    table input[type=text], table input[type=number] {
        width: 65%;
        border: none;
    }
    .tth2 td, .tth3 td{
        padding-left: 10px;
    }
    .tth3:nth-child(2) td{
        padding: 0;
        border: none;
        color: #ffffff;
        background-color: #BDBDBD;
    }
    .tth3:nth-child(2) td input{
        width: 90%;
    }
    .tth3:nth-child(2) td span{
        margin-left: 5px;
    }
    input[type=text]{
        height: 26px;
    }

`;
const WriteStep3 = styled.div`

`;
const WriteStep4 = styled.div`
    table{
        width: 100%;
        border-collapse: separate;
    }
    table th, table td {
        min-width: 20px;
        height: 36px;
        text-align: center;
        box-sizing: border-box;
    }
    table th {
        font-size: 14px;
    }
    table td{
        font-size: 12px;
    }
    table input[type=text], table input[type=number]{
        width: 100%;
        height: 26px;
        border-radius: 3px;
        border: 1px solid #BDBDBD;
        padding: 0 !important;
    }
    table tbody td:not(:first-child),
    table tbody td:not(:last-child){
        padding: 0 3px;
    }
    table tbody td:first-child{
        padding-left: 10px;
    }
    table tbody td:last-child{
        padding-right: 10px;
    }


`;
const WriteStep5 = styled.div`

    table {
        border-spacing: 4px 0;
    }
    .border-form-write table th{
       width: 110px;
    }
    .border-form-write table th, .border-form-write table td{
        height: 36px;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
    }
    .border-form-write table th{
        font-size: 14px;
    }
    .border-form-write table td{
        font-size: 12px;
    }

`;
const EnWriteAdmin = (props) => {
    const {
        doc_status,
        doc_id,
        auth,

        //  Common
        cboLease,
        cboHouseType,
        // cboEmpty,
        cboApply,
        
        //  Step1
        winner,
        possibleRate,
        possibleAmt,

        //  Step2
        road_addr,
        road_addr_dtl,
        house_type,
        house_area,
        room_count,
        house_selling,
        house_area_over1,
        house_area_over2,
        house_rent_type,
        rent_amt,
        monthly_rent_amt,
        minor_child_cnt,
        estate_no,
        wallpaper_flag,
        isCalcResult,       //  지원가능 여부
        calcComment,        //  지원가능 여부 코멘트

        //  Step3
        // priority,
        // rent_tot_amt,

        //  Step4
        broker_company,
        broker_telno,
        broker_reg_no,

        //  서류폴더, 서류이미지
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        onWaiting,
        onImgRotation,
        onImgRotateSave,
        onRefreshImg,

        //  사용자검색 모달
        isShowFindUser,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,

        //  중개사 모달
        isShowFindBroker,

        //  파일업로드 모달
        originFileList,
        isShowUpload,
        setIsShowUpload,

        //  폴더 슬라이드 위치
        folderDiv,
        folderSlider,

        onChange,
        onChecked,
        // onChangeNum,
        
        onAddWinner,
        onRemoveWinner,
        onWinnerChange,
        
        onShowDaumPost,
        handlePostCode,
        
        s2_onWinnerChange,
        s2_onChangeNum,
        onCalcSupportAmt,

        // onPriorityChange,
        // onAddPriority,
        // onRemovePriority,

        onShowFindUser,
        onCloseFindUser,
        onSelectUser,

        onShowFindBroker,
        onCloseFindBroker,
        onSelectBroker,
        
        onSelectFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,

        onFileUploadClick,
        onSaveFileUpload,
        onDocSortClick,
        onRequestClick,
        onSaveClick,
        onDeleteWrite,
    } = props;

    const propDatas = {
        onCloseFindUser,
        onSelectUser,

        onCloseFindBroker,
        onSelectBroker,

        doc_id,
        onFileUploadClick,
        isShowUpload,
        setIsShowUpload,
        onSaveFileUpload,
        originFileList,
    }

    // useEffect(() => {
    //     if(!winner || winner.length === 0 || !winner[0].winner_type) {
    //         return;
    //     }
    //     dataEntitleCalc.forEach(applyType => {
    //         if(String(applyType.codeIdx) === String(winner[0].type_calc_code)) {
    //             setPossibleRate(applyType.defaultPay.rate);
    //         }
    //     });
    // },[dataEntitleCalc, winner])
    
    return (
        <EnWriteAdminBlock>
            <div className="divHeader-button"> {/* [Dev] 22.02.16 divHeader-button class변경*/}
                {auth === "WINNER" ? null :
                <>
                    {(!doc_status || doc_status === 'ENWRITE' || doc_status === 'ENSAVE' || doc_status === 'ENDOCFAIL') &&
                        <button onClick={onFileUploadClick}>파일업로드</button>
                    }
                    <button onClick={onDocSortClick}>서류분류</button>
                    {(!doc_status || doc_status === 'ENWRITE' || doc_status === 'ENSAVE' || doc_status === 'ENDOCFAIL') &&
                        <>
                            <button onClick={onDeleteWrite}>신청서 삭제</button>
                            <button onClick={onRequestClick}>신청하기</button>
                            <button onClick={() => onSaveClick('N')}>저장</button>
                        </>
                    }
                </>
                }
            </div>

            <div className='divContainer cont'>
                <div className='divContainer wrap'>
                    <div className={'divWrite' + (auth === "WINNER" ? " readonly" : "")}>
                        <WriteStep1>
                        <h4>신청인 정보 {auth !== "WINNER" && <button onClick={onAddWinner} className="add-btn">+ 추가</button>}</h4>
                        <table className='tt-list'>
                            <thead>
                                <tr>
                                    <td>번호</td>
                                    <td>성명</td>
                                    <td>계약유형</td>
                                    <td>{dataWORD.BIRTHDATE}</td>
                                    <td>주소</td>
                                    <td>핸드폰번호</td>
                                    <td>지원유형</td>
                                    <td>당첨자</td>
                                    <td>삭제</td>
                                </tr>
                            </thead>
                            <tbody>
                                {winner.map((item, idx) => (
                                    <tr key={item.idx}>
                                        <td>{idx + 1}</td>
                                        <td>{item.winner_name}</td>
                                        <td>
                                            <select name="contract_type" id="contract_type" value={item.contract_type} onChange={onWinnerChange.bind(this, item)}>
                                                {cboLease.map((item, idx) => (
                                                    <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>{item.winner_no}</td>
                                        {
                                            item.winner_id ?
                                            <>
                                                <td>{item.winner_addr}</td>
                                                <td>{item.winner_phone}</td>
                                                <td>
                                                    {
                                                        (item.select_win_type === 'Y' || item.info_yn === 'Y') ?
                                                        <select name="winner_type" id="winner_type" value={item.winner_type} onChange={onWinnerChange.bind(this, item)} style={{width:'100%'}}>
                                                            {cboApply.map((item, idx) => (
                                                                <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                                                ))}
                                                        </select>
                                                        :
                                                        item.winner_type_name
                                                    }
                                                </td>
                                            </>
                                            :
                                            <>
                                                <td colSpan={3} style={{color:'red',fontWeight:'bolder'}}>당첨자를 다시 선택해주세요.</td>
                                            </>
                                        }
                                        <td><button onClick={onShowFindUser.bind(this, item)} className="sch-btn">검색</button></td>
                                        <td><button onClick={onRemoveWinner.bind(this, item)} className="x-del-btn">X</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </WriteStep1>

                        <WriteStep2>
                        <h4>입주희망 주택현황</h4>
                        <div className='flexDiv border-form-write'>
                            <table style={{width:"70%"}} className="tth tth1">
                                <tbody>
                                    <tr>
                                        <th>주소</th>
                                        <td>
                                            <input type="text" name="road_addr" id="road_addr" placeholder='주소' value={road_addr} onChange={onChange} readOnly={true} className="mandatory" />
                                            <button onClick={onShowDaumPost} className="address-btn">주소검색</button>
                                            <input type="text" name="road_addr_dtl" id="road_addr_dtl" placeholder='상세주소' value={road_addr_dtl} onChange={onChange} className="mandatory" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{width:"30%"}} className="tth tth1">
                                <tbody>
                                    <tr>
                                        <th>등기부등본<br/>고유번호</th>
                                        <td>
                                            <input type="text" name="estate_no" id="estate_no" placeholder='등본 고유번호' value={estate_no} onChange={onChange} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="flexDiv border-form-write">
                            <table style={{width:"30%"}} className="tth tth2" >
                                <tbody>
                                    <tr>
                                        <th>주택유형</th>
                                        <td>
                                            <select name="house_type" id="house_type" value={house_type} onChange={onChange} className="mandatory">
                                                {cboHouseType.map((item, idx) => (
                                                    <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{width:"40%"}} className="tth tth2">
                                <tbody>
                                    <tr>
                                        <th>면적</th>
                                        <td><input type="text" name="house_area" id="house_area" value={house_area} onChange={onChange} className="mandatory" /> m²</td>
                                        <td>방 <input type="text" name="room_count" id="room_count" value={room_count} onChange={onChange} className="mandatory" /> 개</td>
                                    </tr>
                                    <tr>
                                        <th>매매중여부</th>
                                        <td>
                                            <input type="radio" name="house_selling" id="house_selling_Y" value={"Y"} checked={house_selling === "Y"} onChange={onChange} />
                                            <label htmlFor="house_selling_Y">예</label>
                                        </td>
                                        <td>
                                            <input type="radio" name="house_selling" id="house_selling_N" value={"N"} checked={house_selling === "N"} onChange={onChange} />
                                            <label htmlFor="house_selling_N">아니오</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tth tth2" style={{width:"30%"}}>
                                <tbody>
                                    <tr>
                                        <th>85m² 초과</th>
                                        <td>
                                            <input type="checkbox" name="house_area_over1" id="house_area_over1" checked={house_area_over1} onChange={onChecked} />
                                            <label htmlFor="house_area_over1">미성년자녀 3명 이상</label><br/>
                                            <input type="checkbox" name="house_area_over2" id="house_area_over2" checked={house_area_over2} onChange={onChecked} />
                                            <label htmlFor="house_area_over2">가구원수 5명 이상</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="flexDiv border-form-write">
                            <table className="tth tth3" style={{width:"30%"}}>
                                <tbody>
                                    <tr>
                                        <th rowSpan={2}>전세유형</th>
                                        <td>
                                            <input type="radio" name="house_rent_type" id="house_rent_type_year" value={"TYPE01"} checked={house_rent_type === "TYPE01"} onChange={onChange} />
                                            <label htmlFor="house_rent_type_year">전세</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="radio" name="house_rent_type" id="house_rent_type_month" value={"TYPE02"} checked={house_rent_type === "TYPE02"} onChange={onChange} />
                                            <label htmlFor="house_rent_type_month">부분전세(보증부월세)</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="tth tth3" style={{width:"40%"}}>
                                <tbody>
                                    <tr>
                                        <th>전세금액</th>
                                        <td><input type="text" name="rent_amt" id="rent_amt" value={rent_amt} onChange={s2_onChangeNum} className="mandatory" /><span>원</span></td>
                                    </tr>
                                    <tr>
                                        <th>월세금액</th>
                                        <td><input type="text" name="monthly_rent_amt" id="monthly_rent_amt" value={monthly_rent_amt} onChange={s2_onChangeNum} disabled={house_rent_type === "TYPE01" ? "disabled" : ""} readOnly={house_rent_type === "TYPE01" ? true : false} className={house_rent_type === "TYPE01" ? "" : "mandatory"} /><span>원</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{width:"30%"}} className="tth tth3">
                                <tbody>
                                    <tr>
                                        <th>미성년자녀 수</th>
                                        <td><input type="text" name="minor_child_cnt" id="minor_child_cnt" value={minor_child_cnt} onChange={s2_onChangeNum} className={winner.length > 0 && winner[0].type_calc_code === "5" ? "mandatory" : ""} />명</td>
                                    </tr>
                                    <tr>
                                        <th>도배장판 신청</th>
                                        <td>
                                            <input type="radio" name="wallpaper_flag" id="wallpaper_flag_Y" value={"Y"} checked={wallpaper_flag === "Y"} onChange={onChange} />
                                            <label htmlFor="wallpaper_flag_Y" style={{marginRight:'10px'}}>예</label>
                                            <input type="radio" name="wallpaper_flag" id="wallpaper_flag_N" value={"N"} checked={wallpaper_flag === "N"} onChange={onChange} />
                                            <label htmlFor="wallpaper_flag_N">아니오</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </WriteStep2>

                        <WriteStep3>
                        <h4>지원금 계산 {auth !== "WINNER" && <button onClick={onCalcSupportAmt} className="calculation-btn">계산</button>}</h4>
                        <div className="flexDiv">
                            <table className='tt-list'>
                                <thead>
                                    <tr>
                                        <th>지원유형</th>
                                        <th>성명</th>
                                        <th>{dataWORD.BIRTHDATE}</th>
                                        <th>입주자부담금 비율</th>
                                        <th>입주자추가부담금</th>
                                        <th>입주자부담금</th>
                                        <th>월임대료</th>
                                        <th>LH지원금</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {winner.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>{item.winner_type_name}</td>
                                            <td>{item.winner_name}</td>
                                            <td>{item.winner_no}</td>
                                            <td>
                                                {possibleRate.length === 0 && "해당 지원유형은 기본 부담금으로 적용됩니다."}
                                                {possibleRate.map((rate, rateIdx) => (
                                                    <Fragment key={rateIdx}>
                                                        <input type="radio" name={"charge_rate_" + rate} id={"charge_rate_" + rate} value={rate} checked={String(item.charge_rate) === String(rate)} onChange={s2_onWinnerChange.bind(this, item, 'charge_rate')} />
                                                        <label htmlFor={"charge_rate_" + rate}>{rate}%</label>
                                                    </Fragment>
                                                ))}
                                                {
                                                    //  입주자부담금에 비율과 금액이 둘 다 있다면 사용자에게 선택권을 준다.
                                                    //  금액을 선택했다면 charge_rate에는 -1을 저장한다.
                                                    (possibleRate.length > 0 && +possibleAmt > 0) &&
                                                    <Fragment>
                                                        <input type="radio" name="isBaseCharge" id="isBaseCharge" value="-1" checked={item.charge_rate === "-1"} onChange={s2_onWinnerChange.bind(this, item, 'charge_rate')} />
                                                        <label htmlFor="isBaseCharge">기본부담금</label>
                                                    </Fragment>
                                                }
                                            </td>
                                            <td><input type="text" name="add_charges" id="add_charges" placeholder='입주자추가부담금' value={item.add_charges} onChange={s2_onWinnerChange.bind(this, item, '')} /></td>
                                            <td>{item.winner_charges}</td>
                                            <td>{item.monthly_rent}</td>
                                            <td>{item.lh_support_amt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={"calcResult calc" + isCalcResult}>
                            <h4>
                                {isCalcResult === "POSSIBLE" && "■ 지원가능"}
                                {isCalcResult === "IMPOSSIBLE" && "■ 지원불가"}
                            </h4>
                            <pre>{calcComment}</pre>
                        </div>
                        </WriteStep3>

                        <WriteStep4>
                        {/* <h4>선순위 임대차현황 {auth !== "WINNER" && <button onClick={onAddPriority} className="add-btn">+ 추가</button>}</h4>
                        <table className='tblFirst tt-list'>
                            <thead>
                                <tr>
                                    <th>층수</th>
                                    <th>호수</th>
                                    <th>공실구분</th>
                                    <th>방개수</th>
                                    <th>비고</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {priority.map((item, idx) => (
                                    (item.use_flag === 'Y' && item.empty_type) &&
                                    <tr key={idx}>
                                        <td><input type="text" name="rent_floor" id="rent_floor" placeholder='층수' value={item.rent_floor} onChange={onPriorityChange.bind(this, item)} autoComplete="off" className="mandatory" /></td>
                                        <td><input type="number" name="room_no" id="room_no" placeholder='호수' value={item.room_no} onChange={onPriorityChange.bind(this, item)} autoComplete="off" className="mandatory" /></td>
                                        <td>
                                            <select name="empty_type" id="empty_type" value={item.empty_type} onChange={onPriorityChange.bind(this, item)}>
                                                {cboEmpty.map((cbo, idx) => (
                                                    <option key={idx} value={cbo.sub_code}>{cbo.code_name}</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <input type="number" name="room_cnt" id="room_cnt" placeholder='방개수' value={item.room_cnt} onChange={onPriorityChange.bind(this, item)}
                                                autoComplete="off" className={item.empty_type.indexOf('HOUSE') > -1 ? "mandatory" : "cantWrite"} readOnly={item.empty_type.indexOf('HOUSE') > -1 ? false : true} />
                                        </td>
                                        <td><input type="text" name="remark" id="remark" placeholder='비고' value={item.remark} onChange={onPriorityChange.bind(this, item)} autoComplete="off" /></td>
                                        <td><button onClick={onRemovePriority.bind(this, item)} className="minus-del-btn">ㅡ</button></td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3} className="sum">신청한 호수를 제외한 총 보증금</td>
                                    <td colSpan={3}><input type="text" name="rent_tot_amt" id="rent_tot_amt" value={rent_tot_amt} onChange={onChangeNum} /></td>
                                </tr>
                            </tbody>
                        </table> */}
                        </WriteStep4>

                        <WriteStep5>
                        <h4>공인중개사 정보 {auth !== "WINNER" && <button onClick={onShowFindBroker} className="sch-btn">검색</button>}</h4>
                        <div className='border-form-write'>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>업체명</th>
                                        <td>{broker_company}</td>
                                        <th>전화번호</th>
                                        <td>{broker_telno}</td>
                                        <th>사업자등록번호</th>
                                        <td>{broker_reg_no}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </WriteStep5>
                    </div>
                    <div className='img-area'>
                        <div className='refTabOn'><span onClick={() => onRefreshImg()}>새로고침</span></div>
                        <div className='divImages'>
                            <div id='imgView' className='imgView'>
                                {selectImg && <img src={rotate} alt='방향 전환' title={'방향 전환'} className='icon-rotate' onClick={onImgRotation} />}
                                {selectImg && <img src={savedisk} alt='방향 저장' title={'방향 저장'} className='icon-savedisk' onClick={onImgRotateSave} />}
                                {
                                    selectImg && 
                                    <div id='div-rotate' style={{transform:'rotate(' + selectImg.rotation + 'deg)'}}>
                                        {selectImg && <img id="selectImg" className='selectImg' src={selectImg.src} draggable={false} onError={onWaiting} alt=''/>}
                                    </div>
                                }
                            </div>
                            <div className='navImgBox'>
                                {docImages.map((item, idx) => (
                                    item.file_type === selectFoler &&
                                    <div key={idx} className='navImg'>
                                        <img src={item.file_data} alt="" onClick={onSelectImg.bind(this, item)} onError={onWaiting} className={(item.file_data === selectImg.src ? "selete-thumbnail" : "") + (" rotate-" + item.rotation)} />
                                    </div>
                                ))}
                            </div>
                            <div>
                                <div className='folderBox'>
                                    <button onClick={onPrevClick}>◀</button>
                                    <div ref={folderDiv}>
                                        <div className='folderSlider' ref={folderSlider}>
                                            {docFolder.map((item) => (
                                                item.code !== 'DOCUDF' &&   // 미분류 폴더 제외
                                                <div key={item.code} onClick={onSelectFolder.bind(this, item)} className={item.code === selectFoler ? 'folder-on' : ''}>{item.name}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <button onClick={onNextClick}>▶</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowDaumPost &&
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={handlePostCode}/>
                    <button className='bdnone-cancel-btn' onClick={onShowDaumPost}>닫기</button>
                </div>
            }
            {isShowFindUser && <FindUserContainer {...propDatas}/>}
            {isShowFindBroker && <FindBrokerContainer {...propDatas}/>}
            {isShowUpload && <EnwAdminUploadContainer {...propDatas}/>}
        </EnWriteAdminBlock>
    );
}

export default EnWriteAdmin;