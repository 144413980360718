import React, { useEffect } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas'
import LandPriceView from './DocViewer/LandPriceView';
import BuildPriceView from './DocViewer/BuildPriceView';
import MultiBPriceView from './DocViewer/MultiBPriceView';
import BuildingMngView from './DocViewer/BuildingMngView';
import AddressHistoryView from './DocViewer/AddressHistoryView';
import CopyRegisterView from './DocViewer/CopyRegisterView';
import RightsReviewView from './DocViewer/RightsReviewView';
import ApplicationFormView from './DocViewer/ApplicationFormView';
import BuildsMngView from './DocViewer/BuildsMngView';

const EntitleViewerBlock = styled.div`

#test{
    // position: relative;
}
#test canvas{
    position: absolute;
    width: 100% !important;
    top:-10px;
    height:1126px !important;
    
    //height: 69vh;
    //overflow-y: scroll;
}
.tableWrap{
    padding: 10px 10px;
    height:100%;
    // position:relative;
}



.viewerContainer {
    width: 100%;
}

.viewerContainer table {
    width: 100%;
    font-size: 12px;
    border-collapse : collapse;
}
.viewerContainer table th,
.viewerContainer table td {
    min-width: 20px;
    text-align: center;
    border: 1px solid #ccc;
}
.apiTable {
    margin-bottom: 10px;
}
.apiTable th {
    background-color: #eee;
}
.viewerImg {
    width: 100%;
}

.sign{
    border:1px solid #ccc;
}
.sign tr td{
    border:none;
}
.bgnone{
    background-color: none;
}

/* 지원신청서 */
.tableWrap.apply .viewerContainer.bd0 table tr th{
    background-color : transparent;
    padding : 5px 0;
}

.tableWrap.apply .applySign{
    border: 1px solid #000;
    border-top:0;
    padding: 0 15%;
    text-align: center;
}
.tableWrap.apply .applySign .txt, .day{
    letter-spacing: 2px;
    font-size: 12px;
}

.tableWrap.apply .applySign .signBox{
    border:0;
    margin-bottom:10px;
    margin:0 50px
}
.tableWrap.apply .applySign .signBox > div{
    display: inline-block;
    font-size: 12px;
}
.tableWrap.apply .applySign .signBox .title{
    text-align: left;
    width: 25%;

}
.tableWrap.apply .applySign .signBox .title > div:first-child{
    letter-spacing: 12px;
}
.tableWrap.apply .applySign .signBox .name{
    text-align: center;
    width: 50%;
}
.tableWrap.apply .applySign .signBox .sign{
    text-align: right;
    width: 25%;;
    border : 0;
}

.tableWrap.apply .applySign >div:last-child{
    font-size: 13px;
    font-weight: bold;
}




/* 권리분석 검토서 */
.tableWrap .viewerContainer.borderwrap{
    border: 1px solid #000;
    height: 100%;
}
.tableWrap .viewerContainer table tbody tr th{
    background-color: #ddd;
    padding : 5px 0;
    font-weight: normal;
}

.tableWrap.review .viewerContainer table tbody tr:first-child td:first-child{
    border:0;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}
.tableWrap.review .viewerContainer table tbody tr:first-child td{
    font-weight: normal;
}

.tableWrap.review .viewerContainer table td span{
    float: right;
}
.tableWrap.review .viewerContainer table td span::before{
    content: '';
    padding-left: 5px;
}
.tableWrap.review .viewerContainer table td span::after{
    content: '';
    padding-right: 5px;
}
.tableWrap .viewerContainer table tbody tr td{
    padding : 5px 0;
}

.review .viewerContainer table{
    margin-bottom: 10px
}
.review .viewerContainer table:last-child{
    margin-bottom: 0;
}
.review .viewerContainer .reviewSign{
    text-align: center;
    margin-top: 20px;
    font-size: 13px;
}
.review .viewerContainer .reviewSign .txt{
    margin-bottom: 10px;
}
.review .viewerContainer .reviewSign .sign{
    margin-top: 15px;
    border : none;
    display: flex;
    justify-content: center;
    justify-items: baseline;
    margin-bottom: 15px;
}
.review .viewerContainer .reviewSign .sign>div:first-child{
    font-size: 15px;
}
.review .viewerContainer .reviewSign .sign>div:last-child{
    margin-left: 100px;
    letter-spacing: 3px;
}
.tableWrap.review .viewerContainer table tbody tr th:first-child{
    border-left:0
}
.tableWrap.review .viewerContainer table tbody tr td:last-child{
    border-right: 0;
}
.tableWrap.review .viewerContainer .secureReview th{
    text-align: left;
}


/* 주택가격정보 - 개별 */
.tableWrap.price .viewerContainer .titleOpen{
    width:100%;
    padding: 5px 0;
    border:1px solid #DDB195;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom:5px;
    color:#DDB195;
    font-size: 13px;
}

.tableWrap.price .viewerContainer .titleAd{
    display: flex;
    margin-bottom: 5px;
    text-align: center;
    font-size: 12px;
    
}
.tableWrap.price .viewerContainer .titleAd>div:first-child{
    width: 20%;
    border:1px solid #DDB195;
    border-right: 0;
    padding : 5px 0;
    color: #DDB195;
}
.tableWrap.price .viewerContainer .titleAd>div:last-child{
    width: 80%;
    border:1px solid #DDB195;
    padding : 5px 0;
}

.tableWrap.price .viewerContainer table th{
    background-color: transparent;
}
.tableWrap.price .viewerContainer table th,
.tableWrap.price .viewerContainer table td{
    border : 1px solid #DDB195;
    padding : 5px 0
}
.tableWrap.price .viewerContainer table th{
    color: #DDB195;
    font-weight: normal;
}
.tableWrap.price .viewerContainer .exp1{
    font-size: 12px;
    margin-top:-5px
}
.tableWrap.price .viewerContainer .exp2{
    display: flex;
    border: 1px solid #000;
    height: auto;
    padding: 10px 5px ;

}
.tableWrap.price .viewerContainer .exp2 div:first-child{
    width: 30%;
    font-size: 12px;
}
.tableWrap.price .viewerContainer .exp2 div:last-child{
    width: 70%;
    font-size: 12px;
}


/* 주택가격정보 - 공통 */
.tableWrap.price.all h2{
    text-align: center;
    margin-bottom: 30px;
}
.tableWrap.price.all .viewerContainer .address{
    display: flex;
    margin-bottom: 10px;
}
.tableWrap.price.all .viewerContainer .address div:first-child{
    color:#38167E;
    min-width:90px
}
.tableWrap.price.all .viewerContainer .address div:last-child{
    padding-left: 25px;
}
.tableWrap.price.all .viewerContainer table{
    border : 2px solid #8DADD5
}

.tableWrap.price.all .viewerContainer table th{
    background-color: transparent;
    color:#38167E
}

.tableWrap.price.all .viewerContainer table th,
.tableWrap.price.all .viewerContainer table td{
    border : 1px solid #ccc;
    padding : 5px 0;
}

.tableWrap.price.all .openName{
    margin-top:15px;
    color:#38167E;
    margin-bottom:10px;
}

.tableWrap.price.all .useType{
    text-align: center;
    border: 2px solid #8DADD5;
}
.tableWrap.price.all .useType div{
    padding : 5px 0
}
.tableWrap.price.all .useType div:first-child{
    border-bottom: 1px solid #8DADD5;
}
.tableWrap.price.all .precaWrap{
    margin-top:30px;
    text-align: center;
    position: relative;
}

.tableWrap.price.all .precaWrap div:first-child::before{
    content: '<';
    width: 20px;
    margin-right: 10px;
}

.tableWrap.price.all .precaWrap div:first-child{
    position: absolute;
    width: 100px;
    background-color: #fff;
    left: 50%;
    -webkit-transform: translate3d(0,0,0) translateZ(1);
    transform: translate3d(0,0,0) translateZ(1);
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.tableWrap.price.all .precaWrap div:first-child::after{
    content: '>';
    width: 20px;
    margin-left: 10px;
}


.tableWrap.price.all .precaWrap .preca{
    border: 1px solid #ccc;
    padding: 15px 0;;
    font-size: 13px;    
}

`;

const EntitleViewer = (props) => {
    const {
        selectTxtFolder,
        winner,
        write,
        // rentWrite,
        work,
        rentWork,
        // doc014,
        doc015,
        doc016,
        doc017,
        use_address,
    } = props;

   
    useEffect(() => {
        html2canvas(document.getElementById('myCanvas'))
        .then(function (canvas) {
            document.getElementById("test").appendChild(canvas);
        });
    }, []);

    // 권리분석 세부페이지
    return (
        <EntitleViewerBlock>
            <div id="test"></div>
            <div id="myCanvas">
            {/* 지원 신청서 */}
            {(!selectTxtFolder || selectTxtFolder === "TXT01") &&
                <ApplicationFormView winner={winner} write={write} priority={rentWork} work={work}/>
            }

            {/* 권리분석 검토서 */}
            {(!selectTxtFolder || selectTxtFolder === "TXT02") &&
                <RightsReviewView work={work} winner={winner} use_address={use_address} write={write}/>
            }

            {/* 등기부등본 */}
            {selectTxtFolder === "TXT03" &&
                <CopyRegisterView />
            }

            {/* 건축물관리대장 */}
            {selectTxtFolder === "TXT04" && (doc015 !== null) && Array.isArray(doc015) && (doc015.length > 0) && doc015[0] &&
                (doc015[0].HO_COUNT === '' ?
                    <BuildsMngView _doc015={doc015[0]} />
                    :
                    <BuildingMngView _doc015={doc015[0]} />)
            }

            {/* 주택가격정보 */}
            {selectTxtFolder === "TXT05" &&
                doc016 && doc016.map((_doc016, idx) => {
                    if( _doc016 && _doc016.sub_type ) {
                        switch (_doc016.sub_type) {
                            case '3':
                                return <MultiBPriceView _doc016={_doc016.jsonData} work={work} key={idx}/>
                                
                            case '4':
                                return <BuildPriceView _doc016={_doc016.jsonData} work={work} key={idx}/>
                                
                            case '5':
                                return <LandPriceView _doc016={_doc016.jsonData} work={work} key={idx}/>
                            
                            default:
                                return '';
                        }
                    }
                    return '';
                })
            }

            {/* 신/구 주소 */}
            {selectTxtFolder === "TXT06" && 
                doc017 && doc017.map((_doc017, idx) => (
                    <AddressHistoryView _doc017={_doc017} key={idx}/>
                ))
            }
            </div>
        </EntitleViewerBlock>
    );
}

export default EntitleViewer;