import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnDocUpload from '../../components/Modal/EnDocUpload';
import { ALERT } from '../../lib/dataWord';
import { addRow, removeRow, rowChangeValue, save, initialize, getDocType, addrWrite, getContent, saveAddrChk, fileWrite } from '../../modules/Modal/enDocUpload';

//  검토서 참조파일 업로드

const EnDocUploadContainer = (props) => {
    const {
        docNo,
        onUploadComplete,
        setIsNeedApiRequest,
    } = props;

    const [cantApiReq, setCantApiReq] = useState(false);

    const dispatch = useDispatch();
    const {
        doc_type,
        fileLastIdx,
        fileList,
        reqAddr,
        buildingStateNo,
        resultAddrs,
        buildingType,
        isFileWriteOK,
    } = useSelector(({ enDocUpload }) => ({
        doc_type: enDocUpload.doc_type,
        fileLastIdx : enDocUpload.fileLastIdx,
        fileList: enDocUpload.fileList,
        reqAddr: enDocUpload.reqAddr,
        buildingStateNo: enDocUpload.buildingStateNo,
        resultAddrs: enDocUpload.resultAddrs,
        buildingType: enDocUpload.buildingType,
        isFileWriteOK: enDocUpload.isFileWriteOK,
    }));

    const onReqAddr = () => {
        dispatch(addrWrite({
            doc_id: docNo,
        }))
        setIsNeedApiRequest('gray');
    }
    const onAddrChecked = (item, e) => {
        dispatch(saveAddrChk({
            use_id: item.id,
            is_use_yn: e.target.checked ? "Y" : "N",
        }))
    }
    const onFileChange = (item, e) => {
        for (let idx = 0; idx < e.target.files.length; idx++) {
            let fileIdx = fileLastIdx + idx + 1;
            const file = e.target.files[idx];
            if(!file) return;

            dispatch(addRow({subCode: item.code, codeName: item.name}));

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                dispatch(
                    rowChangeValue({
                        idx: fileIdx,
                        fileReader: reader,
                        fileName: file.name,
                        fileExtension: file.type,
                    })
                );
            }
        }
    }
    const onRequestApi = (doc) => {
        for(let i = 0; i < fileList.length; i++) {
            const _fileList = fileList[i];

            if(_fileList.api_yn === "N" && !_fileList.file_id && _fileList.use_flag === "Y") {
                ALERT("수동 추가한 파일을 먼저 저장하신 후 요청해주세요.");
                return;
            }
        }

        const {code} = doc;
        
        let el = null;
        let valid = true;

        // 요청중인게 있는지 검사
        for (let index = 0; index < fileList.length; index++) {
            el = fileList[index];
            if(el.file_type === code) {
                // 요청중인게 하나라도 있으면.
                if(el.api_req_date && !el.api_rec_date) {
                    valid = false;
                    break;
                }
            }
            else {
                continue;
            }
        }

        //  FileWrite에 필요한 변수 초기화
        let arrEstateId = '', buildingType;
        resultAddrs.filter(x => x.is_use_yn === 'Y').forEach(item => {
            arrEstateId += item.estate_id + ',';

            if(item.building_type !== '1') {
                if(!buildingType) {
                    buildingType = item.building_type;
                }
            }
        });
        arrEstateId = arrEstateId.slice(0, -1);

        // 요청중인게 없으면 요청, 있으면 못함.
        if(valid) {
            if(window.confirm(`${doc.name} 파일을 요청하시겠습니까?`)) {

                //  요청은 같은 날에 한 번으로 제한.
                // const targetDoc = fileList.find(x => x.file_type === code);
                // if(targetDoc) {
                //     const reqDate = new Date(targetDoc.api_req_date);
                //     const today = new Date();
                //     if(
                //         reqDate.getFullYear() === today.getFullYear() &&
                //         reqDate.getMonth() === today.getMonth() &&
                //         reqDate.getDate() === today.getDate()
                //     ) {
                //         ALERT("오늘은 이미 요청이 완료되었습니다.");
                //         return;
                //     }
                // }

                //  등기부등본
                if(code === 'DOC014') {
                    dispatch(
                        fileWrite({
                            estate_id: arrEstateId,
                            file_type: '1',
                        })
                    );
                }
                // 건축물관리대장
                else if(code === 'DOC015') {
                    dispatch(
                        fileWrite({
                            estate_id: arrEstateId,
                            file_type: '2',
                        })
                    );
                }
                // 주택가격평가
                else if(code === 'DOC016') {
                    // buildingType : 1 = 토지, 2 = 건물, 3 = 집합건물
                    dispatch(
                        fileWrite({
                            estate_id: arrEstateId,
                            file_type: buildingType === '2' ? '4' : '3',    //  건물이라면 '개별공시', 집합건물이라면 '표준공시'
                        })
                    );
                }
                // 신/구 주소
                else if(code === 'DOC017') {
                    dispatch(
                        fileWrite({
                            estate_id: arrEstateId,
                            file_type: '7'
                        })
                    );
                }
            }
        }
        else {
            ALERT("이미 요청중인 파일이 있으면 요청할수 없습니다.")
        }
    }
    //  검토파일 일괄요청
    const onDocAutoReceiveClick = () => {
        let isTodayReq = false; // 금일 요청된 파일의 존재 여부
        let isPrevReq = false;  // 오늘 이전에 요청되어 수신완료한 파일의 존재 여부

        for(let i = 0; i < fileList.length; i++) {
            const _fileList = fileList[i];

            if(_fileList.api_yn === "N" && !_fileList.file_id && _fileList.use_flag === "Y") {
                ALERT("수동 추가한 파일을 먼저 저장하신 후 요청해주세요.");
                return;
            }

            if(_fileList.api_yn === 'Y') {
                if(_fileList.api_req_date && !_fileList.api_rec_date) {
                    ALERT("요청 중인 파일이 있습니다.\n요청 완료 후 다시 시도해 주십시오.");
                    return;
                }
            
                //  1. 금일 요청된 파일의 존재여부 확인
                //  2. 오늘 이전에 요청되어 수신한 파일의 존재여부 확인
                const reqDate = new Date(_fileList.api_req_date);
                const today = new Date();
                if(
                    reqDate.getFullYear() === today.getFullYear() &&
                    reqDate.getMonth() === today.getMonth() &&
                    reqDate.getDate() === today.getDate()
                ) {
                    isTodayReq = true;
                }
                else {
                    isPrevReq = true;
                }
            }
        }

        if(isTodayReq) {
            ALERT("오늘 요청된 문서를 제외한 나머지 문서가 모두 요청됩니다.");
        }
        if(isPrevReq) {
            if(!window.confirm('이전에 이미 요청완료된 파일이 있습니다. 다시 요청하시겠습니까?')) {
                return;
            }
        }
        else {
            //  FileWrite에 필요한 변수 초기화
            let arrEstateId = '';
            resultAddrs.filter(x => x.is_use_yn === 'Y').forEach(item => {
                arrEstateId += item.estate_id + ',';
            });
            arrEstateId = arrEstateId.slice(0, -1);

            if(window.confirm('검토파일을 일괄 요청하시겠습니까?')) {
                dispatch(
                    fileWrite({
                        estate_id: arrEstateId,
                        file_type: '1,2,3,4,7',
                    })
                );
            }
        }
    }
    // 삭제
    const onRemoveFile = (item) => {
        // api 요청 아닌것만 삭제
        const {api_yn} = item;
        if(api_yn === "N") {
            // 삭제
            if(window.confirm(`해당 파일을 삭제하시겠습니까?\n파일명: ${item.file_name}`)) {
                dispatch(
                    removeRow({
                        subCode: item.file_type, idx: item.idx
                    })
                );
            }
        }
    }
    const onSave = () => {
        doc_type.forEach(_doc => {
            fileList.forEach(_file => {
                if(_doc.code === _file.file_type && _file.file_name) {
                    dispatch(
                        save({
                            doc_id: docNo,
                            files:
                            [
                                {
                                    doc_type: 'ENTITLE',
                                    file_id: _file.file_id,
                                    file_type: _file.file_type,
                                    file_name: _file.file_name,
                                    contenttype: _file.file_extension,
                                    file_data: _file.file_data.split(',').slice(-1)[0],
                                    use_flag: _file.use_flag,
                                }
                            ]
                        })
                    )
                }
            });
        });
        onCloseClick();
    }
    const onCloseClick = () => {
        //  부모에게 전달 할 파일목록 :: file_data 변경
        let _fileList = [];
        fileList.forEach(_file => {
            //  업로드된 파일에 대해 방향을 저장한 적이 없다면 rotation값이 null이고,
            //  방향을 저장한 적이 있다면 rotation값은 '파일URL#방향값'으로 온다.
            //  업로드된 파일이 PDF라면 rotaion값이 '파일URL#방향값,파일URL#방향값,파일URL#방향값...' 으로 오므로 rotation값을 파싱하여 사용하여야 한다.

            const splitUrl = _file.file_data.indexOf('data:') > -1 ? [_file.file_data] : _file.file_data.split(',');  //  file_data의 값이 'data:'로 시작한다면 신규업로드 파일으므로 split하지 않는다.
            const splitRotate = (!_file.rotation || _file.rotation === '0' ? [] : _file.rotation.split(','));       //  file_rotation값이 없다면 빈배열, 있다면 ','로 split한다.

            let _fileData = [];
            splitUrl.forEach(url => {
                let _rotation = 0;
                for(let i = 0; i < splitRotate.length; i++) {
                    const _rotate = splitRotate[i];
                    if(_rotate.indexOf(url) > -1) {
                        _rotation = _rotate.split('#')[1];  // 파일에 저장된 rotation값이 있다면 rotation값을 추출하고 break;
                        break;
                    }
                };

                _fileData.push({
                    file_url: url,
                    rotation: _rotation,
                })
            });

            _fileList.push({
                api_rec_date: _file.api_rec_date,
                api_req_date: _file.api_req_date,
                api_yn: _file.api_yn,
                file_data: _fileData,
                file_extension: _file.file_extension,
                file_id: _file.file_id,
                file_name: _file.file_name,
                file_type: _file.file_type,
                file_type_name: _file.file_type_name,
                idx: _file.idx,
                use_flag: _file.use_flag,
            })
        });
        onUploadComplete(_fileList);
    }
    useEffect(() => {
        //  모달 Open시 데이터 검색
        dispatch(getDocType({doc_type: 'ENTITLE'}));
        dispatch(getContent(docNo));
    }, [dispatch, docNo])

    useEffect(() => {
        //  선택된 정제주소에 따라 [요청 버튼] 비/활성화
        const _cantApiReq = resultAddrs.filter(x => x.is_use_yn === "Y").length;
        setCantApiReq(!_cantApiReq > 0);

        if(resultAddrs.length > 0) {
            setIsNeedApiRequest(_cantApiReq > 0 ? "blue" : "orange");
        }
    }, [resultAddrs, setIsNeedApiRequest])

    useEffect(() => {
        if(!buildingStateNo) return;

        if(buildingStateNo === 5 || buildingStateNo === 6 ||buildingStateNo === 7) {
            setIsNeedApiRequest('red');
        }
    }, [buildingStateNo, setIsNeedApiRequest])

    useEffect(() => {
        //  파일 요청 후 재검색
        if(isFileWriteOK) {
            dispatch(getContent(docNo));
        }
    }, [dispatch, isFileWriteOK, docNo])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        doc_type,
        fileList,
        reqAddr,
        resultAddrs,
        buildingType,

        cantApiReq,

        onReqAddr,
        onAddrChecked,
        onCloseClick,
        onDocAutoReceiveClick,
        onFileChange,
        onRemoveFile,
        onSave,
        onRequestApi,
    }
    return (
        <>
            <EnDocUpload {...propDatas}/>
        </>
    );
}

export default EnDocUploadContainer;