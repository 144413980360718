import React, { useCallback, useEffect, useRef, useState } from 'react';
import EntitleWriteStep1 from '../components/EntitleWriteStep1';
import EntitleWriteStep2 from '../components/EntitleWriteStep2';
// import EntitleWriteStep3 from '../components/EntitleWriteStep3';
import EntitleWriteStep4 from '../components/EntitleWriteStep4';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { parseNumber, regAmt } from '../common';
import {
    changeValue, 
    s1_rowChangeValue, s1_setLastIdx, s1_addRow, s1_removeRow, s1_selectUser, s1_save,
    s2_save,
    s4_rowChangeValue, s4_addRow, s4_removeRow, getContent, setDocID, initialize, delContent, docReq, fileAdd, getCombo, s1_getWinner, s4_doc_type,
} from '../modules/entitleWrite';
import { useLocation, useNavigate } from 'react-router-dom';
import { funEntitleCalc, dataEntitleCalc } from '../lib/dataEntitleCalc';
// import { dataEmptyRoomCalc } from '../lib/dataEmptyRoomCalc';
import { search } from '../modules/user';
import { dataSystemCode } from '../lib/dataSystemCode';
import { ALERT } from '../lib/dataWord';

const EntitleWriteContainer = () => {
    const [step, setStep] = useState(1);
    const [isShowFindUser, setIsShowFindUser] = useState(null);
    const [isShowDaumPost, setIsShowDaumPost] = useState(false);
    const [isShowUserAgree, setIsShowUserAgree] = useState(false);
    const [isCalcResult, setIsCalcResult] = useState("WAIT") // 지원가능 여부 : WAIT=대기, POSSIBLE=가능, IMPOSSIBLE=불가능
    const [calcComment, setCalcComment] = useState("") // 지원가능 여부 코멘트
    const [cantModify, setCantModify] = useState(true) // 접수대기 상태에서 단계 이동시 저장가능 여부 변수
    const [isShowPrivacyTerms, setIsShowPrivacyTerms] = useState(false); //개인정보 이용 및 수집 동의 모달
    const [possibleRate, setPossibleRate] = useState([]);   // 입주자부담금 비율
    const [possibleAmt, setPossibleAmt] = useState(0);   // 입주자부담금 금액
    
    const [cboLease, setCboLease] = useState(dataSystemCode.LEASE_TYPE);      //계약유형 ex) 신규,이주...
    const cboHousing = dataSystemCode.HOUSING_TYPE;     //주택유형 ex) 아파트,다가구주택...
    // const cboEmpty = dataSystemCode.EMPTY_TYPE;     //공실 구분
    const cboRent = dataSystemCode.RENT_TYPE;       //전세or보증부월세

    // const [workDone, setWorkDone] = useState(0); // 작업 완료 여부
    const workState = useRef(0)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {state: {
        docNo,
        // winner_type,
        func,
    }} = location;
    
    const {
        user,
        userInfo,
    } = useSelector(({user}) => ({
        user: user.user,
        userInfo: user.userInfo,    // 2단계 중개사 정보
    }))
    const {
        doc_id,
        doc_status,

        //  Common
        cboApply,
        createUser,
        
        //  Step1
        winnerLastIdx,
        winner,
        privacy_terms_agree,
        winner_type_forS4,

        //  Step2
        broker_company,
        broker_telno,
        broker_reg_no,
        road_addr,
        road_addr_dtl,
        jibun_addr,
        sido,
        sigungu,
        town,
        road_name,
        building_num,
        building_name,
        house_type,
        house_area,
        room_count,
        house_selling,
        house_area_over1,
        house_area_over2,
        house_rent_type,
        rent_amt,
        monthly_rent_amt,
        minor_child_cnt,
        estate_no,
        wallpaper_flag,

        // //  Step3
        // priorityLastIdx,
        // priority,
        // rent_tot_amt,

        //  Step4
        doc_type,
        fileLastIdx,
        fileList,
        lackReason,

        goList,

        write,
        error,
    } = useSelector(({ entitleWrite }) => ({
        doc_id: entitleWrite.doc_id,
        doc_status: entitleWrite.doc_status,

        cboApply: entitleWrite.cboApply,
        createUser: entitleWrite.createUser,

        winnerLastIdx: entitleWrite.winnerLastIdx,
        winner: entitleWrite.winner.map((item) => {
            return ({
                ...item,
                winner_charges: item.winner_charges === "0" ? "" : String(item.winner_charges).replace(regAmt, ","),
                add_charges: item.add_charges === "0" ? "" : String(item.add_charges).replace(regAmt, ","),
                monthly_rent: item.monthly_rent === "0" ? "" : String(item.monthly_rent).replace(regAmt, ","),
                lh_support_amt: item.lh_support_amt === "0" ? "" : String(item.lh_support_amt).replace(regAmt, ","),
            })
        }),
        privacy_terms_agree: entitleWrite.privacy_terms_agree,
        winner_type_forS4: entitleWrite.winner_type_forS4,

        broker_company: !entitleWrite.broker_company ? "" : entitleWrite.broker_company,
        broker_telno: !entitleWrite.broker_telno ? "" : entitleWrite.broker_telno,
        broker_reg_no: !entitleWrite.broker_reg_no ? "" : entitleWrite.broker_reg_no,
        road_addr: !entitleWrite.road_addr ? "" : entitleWrite.road_addr,
        road_addr_dtl: !entitleWrite.road_addr_dtl ? "" : entitleWrite.road_addr_dtl,
        jibun_addr: !entitleWrite.jibun_addr ? "" : entitleWrite.jibun_addr,
        sido: !entitleWrite.sido ? "" : entitleWrite.sido,
        sigungu: !entitleWrite.sigungu ? "" : entitleWrite.sigungu,
        town: !entitleWrite.town ? "" : entitleWrite.town,
        road_name: !entitleWrite.road_name ? "" : entitleWrite.road_name,
        building_num: !entitleWrite.building_num ? "" : entitleWrite.building_num,
        building_name: !entitleWrite.building_name ? "" : entitleWrite.building_name,
        house_type: !entitleWrite.house_type ? "TYPE01" : entitleWrite.house_type,
        house_area: !entitleWrite.house_area ? "" : String(entitleWrite.house_area),
        room_count: !entitleWrite.room_count ? "" : entitleWrite.room_count,
        house_selling: entitleWrite.house_selling,
        house_area_over1: entitleWrite.house_area_over1,
        house_area_over2: entitleWrite.house_area_over2,
        house_rent_type: !entitleWrite.house_rent_type ? "TYPE01" : entitleWrite.house_rent_type,
        rent_amt: !entitleWrite.rent_amt ? "" : String(entitleWrite.rent_amt).replace(regAmt, ","),
        monthly_rent_amt: !entitleWrite.monthly_rent_amt ? "" : String(entitleWrite.monthly_rent_amt).replace(regAmt, ","),
        minor_child_cnt: !entitleWrite.minor_child_cnt ? "" : entitleWrite.minor_child_cnt,
        estate_no: !entitleWrite.estate_no ? "" : entitleWrite.estate_no,
        wallpaper_flag: !entitleWrite.wallpaper_flag ? "N" : entitleWrite.wallpaper_flag,

        // priorityLastIdx: entitleWrite.priorityLastIdx,
        // priority: entitleWrite.priority.map((item) => {
        //     return ({
        //         ...item,
        //         rent_deposit: String(item.rent_deposit).replace(regAmt, ","),
        //         rent_fee: String(item.rent_fee).replace(regAmt, ","),
        //         priority_payment: String(item.priority_payment).replace(regAmt, ","),
        //     })
        // }),
        // rent_tot_amt: !entitleWrite.rent_tot_amt ? "" : String(entitleWrite.rent_tot_amt).replace(regAmt, ","),

        doc_type: entitleWrite.doc_type,
        fileLastIdx : entitleWrite.fileLastIdx,
        fileList: entitleWrite.fileList,
        lackReason: entitleWrite.lackReason,

        goList: entitleWrite.goList,

        wirte: entitleWrite.wirte,
        error: entitleWrite.error,
    }));
    /*
    새로고침 잡기 테스트
    */

    const listener = e => {
        e.preventDefault();
        e.returnValue = "";
    }

    useEffect(() => {
        window.addEventListener("beforeunload", listener); // 신청후 페이지 이동시에는 발동하지 않음.



        return () =>  window.removeEventListener("beforeunload", listener); 
    }, [dispatch])

    useEffect(() => {
        let _step = localStorage.getItem('step')
        let _doc_id = localStorage.getItem('doc_id')
        let _privacy_terms_agree = localStorage.getItem('privacy_terms_agree')

        // 새로고침 했는데 step, 이랑 doc_id 가 있으면? 해당 스텝과 doc_id의 데이터를 조회한다.
        if(_step && _doc_id) {
            setStep(Number(_step));
            dispatch(setDocID(_doc_id));
            dispatch(getContent(_doc_id));
            dispatch(
                changeValue({
                    key: 'privacy_terms_agree',
                    value: _privacy_terms_agree === 'true'? true : false,
                })
            );
        }

        dispatch(getCombo("APPLY_TYPE"));

        return () => {
            localStorage.removeItem('step')
            localStorage.removeItem('doc_id')
            localStorage.removeItem('privacy_terms_agree')
            //  언마운트시 초기화
            dispatch(initialize());
        };
    }, [dispatch])

    //  지원유형별 제출서류 항목
    useEffect(() => {
        if(winner_type_forS4) {
            dispatch(s4_doc_type(winner_type_forS4));
        }
    }, [dispatch, winner_type_forS4])

    useEffect(() => {
        if(docNo) {
            dispatch(setDocID(docNo));
        }
    }, [dispatch, docNo])

    useEffect(() => {
        if(doc_id) {
            localStorage.setItem('doc_id',doc_id)
            dispatch(getContent(doc_id));
        }
    }, [dispatch, doc_id])

    useEffect(() => {
        //  Status가 서류미비라면 바로 3단계로 이동.
        if(doc_status === 'ENDOCFAIL') {
            setStep(3);
        }
        
        setCantModify(doc_status && doc_status !== 'ENWRITE');
    }, [dispatch, doc_status])

    //  당첨자 접속시 당첨자 정보 불러오기 (단, doc_id가 없을 경우에만 실행);
    const onLoadWinner = useCallback(() => {
        dispatch(
            s1_getWinner({
                name: user.user_name,
                tel: user.user_id,
            })
        )
    }, [dispatch, user])
    useEffect(() => {
        //  로그인 유저의 중개사명 가져오기
        if(user) {
            dispatch(search({
                user_id: user.user_id,
                user_name: "",
                user_auth: "",
            }))

            //  접속자가 당첨자(WINNER)라면 계약유형에 [신규, 이주]는 제거한다.
            if(user.user_auth === 'WINNER') {
                if(cboLease.filter(x => x.sub_code === 'NEW' || x.sub_code === 'MOVE').length > 0) {
                    const _cboLease = cboLease.filter(x => x.sub_code !== 'NEW' && x.sub_code !== 'MOVE');
                    setCboLease(_cboLease);
                }

                if(!doc_id) {
                    onLoadWinner();
                }
            }
        }
    }, [dispatch, user, cboLease, doc_id, onLoadWinner])

    // 공통 Event -------------------------------------------
    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onChecked = e => {
        if(e.target.name === 'house_area_over1' || e.target.name === 'house_area_over2') {
            if(+house_area < 85) {
                ALERT("면적이 85m² 이상일 경우에만 체크해주세요.");
                return;
            }
        }

        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.checked,
            })
        );
    };
    const onModifyMode = () => {
        if(window.confirm("신청서의 접수신청을 중지하고 내용을 수정하시겠습니까?\n확인시 당첨자 휴대전화 인증을 받아야 다시 접수신청이 가능합니다.")) {
            ALERT("지금부터 신청서의 내용 수정이 가능합니다.");
            setCantModify(false);
        }
    }
    const onPrevClick = () => {
        localStorage.setItem('step', step-1);
        setStep(step - 1);
    }
    const onNextClick = () => {
        if(step < 3) {
            //  최초, 작성중, 접수대기 상태이면서, cantModify(수정불가 상태)가 false인 경우에만 저장됨, 아니라면 단계 이동만 가능.
            if((!doc_status || doc_status === 'ENWRITE' || doc_status === 'ENREQ') && !cantModify) {
                if(step === 1) {
                    let _winner_info = [];
                    let contractType, calcType, winnerType;

                    if(!privacy_terms_agree){
                        ALERT('개인정보 수집 및 이용 안내에 동의해주세요.');
                        return;
                    }

                    for(let i = 0; i < winner.length; i++) {
                        const _winner = winner[i];

                        if(!_winner.winner_name || !_winner.winner_no) {
                            ALERT("당첨자를 선택해주세요.");
                            return;
                        }

                        //  직접 추가한 당첨자가 아니라면 당첨자 정보 배열에 추가.
                        if(_winner.winner_id) {
                            _winner_info.push({
                                winner_id: _winner.winner_id,
                                winner_type: _winner.winner_type,
                            })
                        }

                        if(!_winner.contract_type) {
                            ALERT("계약유형을 선택해주세요.");
                            return;
                        }

                        if(_winner.contract_type === "NEW" && _winner.is_duplicate === 'Y') {
                            ALERT(_winner.winner_name + " 님께서는 기지원자로 중복 신청이 불가합니다.");
                            return;
                        }

                        if(_winner.contract_type === "NEW" && _winner.info_yn === 'Y') {
                            ALERT(_winner.winner_name + " 님께서는 기존 고객으로 신규 신청이 불가합니다.\n재계약(이주/증액/동일/감액)으로 신청해주세요.");
                            return;
                        }

                        if(_winner.contract_type !== "NEW" && _winner.info_yn === 'N') {
                            ALERT(_winner.winner_name + " 님께서는 신규 지원자로\n재계약(이주/증액/동일/감액)을 신청할 수 없습니다.")
                            return;
                        }

                        // 생년월일이 6자리 인지
                        if(_winner.winner_no.length !== 6) {
                            ALERT('신청자의 생년월일을 확인해주세요');
                            return;
                        }

                        //  당첨자들의 계약유형이 다른지 확인.
                        if (contractType) {
                            if (contractType !== _winner.contract_type) {
                                ALERT("지원 신청자들의 계약유형이 다릅니다.");
                                return;
                            }
                        }
                        contractType = _winner.contract_type;

                        //  당첨자의 지원유형이 있는지 확인.
                        if (!_winner.winner_type) {
                            //  단, 당첨자를 직접입력한 경우(winner_id가 없는)는 제외
                            if(_winner.winner_id) {
                                ALERT("지원 유형을 선택해주세요.");
                                return;
                            }
                        }

                        //  당첨자들의 지원유형이 다른지 확인.
                        if (calcType) {
                            if(calcType === "8" || calcType === "9") {
                                if(_winner.type_calc_code !== "8" && _winner.type_calc_code !== "9") {
                                    ALERT("지원유형이 [청년]인 경우만 셰어가 가능합니다.");
                                    return;
                                }
                            }
                            else {
                                ALERT("지원유형이 [청년]인 경우만 셰어가 가능합니다.");
                                return;
                            }
                        }
                        winnerType = _winner.winner_type;
                        calcType = _winner.type_calc_code;
                    }

                    
                    for(let i = 0; i < winner.length; i++) {
                        const _winner = winner[i];
                        //  당첨자 정보를 LH에서 받아오지 않았으면서 지원유형이 없다면 최초 한번만 지원유형 수정이 가능하다.
                        if(_winner.select_win_type === 'Y' && _winner.info_yn === 'N') {
                            if(!window.confirm('당첨자 ' + _winner.winner_name + ' 님의 지원유형을 확인하셨습니까?\n다음 단계 이동시 다시 변경할 수 없습니다.')) {
                                return;
                            }
                        }
                    }
                                        
                    if(_winner_info.length > 0) {
                        dispatch(
                            s1_save({
                                winner_info: _winner_info,
                                contract_type: contractType,
                                doc_id: doc_id,
                            })
                        )
                    }
                    else {
                        dispatch(
                            s1_save({
                                winner_name: winner[0].winner_name,
                                winner_birth: winner[0].winner_no,
                                contract_type: contractType,
                                doc_id: doc_id,
                            })
                        )
                    }

                    if(doc_type.length === 0) {
                        dispatch(s4_doc_type(winnerType));
                    }
                }
                else if(step === 2) {
                    workState.current = 1; //1단게에서 넘어가면 저장이 되었기때문에 작업 진행중
                    if(!road_addr || !road_addr_dtl) {
                        ALERT("주소를 모두 입력해주세요.");
                        return;
                    }
                    // if(!house_area) {
                    //     ALERT("면적을 입력해주세요.");
                    //     return;
                    // }
                    // if(!room_count) {
                    //     ALERT("방 개수를 입력해주세요.");
                    //     return;
                    // }
                    // if(!house_selling) {
                    //     ALERT("매매중여부를 선택해주세요.");
                    //     return;
                    // }
                    // if(house_area > 85) {
                    //     if(!house_area_over1 && !house_area_over2) {
                    //         ALERT("주택 면적 85m² 초과사유를 선택해주세요.");
                    //         return;
                    //     }
                    // }
                    // if(!rent_amt) {
                    //     ALERT("전세금액을 입력해주세요.");
                    //     return;
                    // }
                    // if(house_rent_type === "TYPE02") {  //보증부월세
                    //     if(!monthly_rent_amt) {
                    //         ALERT("전세유형이 보증부월세인 경우 월세금액은 필수입력입니다.");
                    //         return;
                    //     }
                    // }

                    let totLhSupport = 0;   // 모든 당첨자가 받는 실지원금 합계액
                    winner.forEach(_winner => {
                        totLhSupport += +(_winner.lh_support_amt.replace(/,/g, ""));
                    });

                    dispatch(
                        s2_save({
                            doc_id: doc_id,
                            status: "ENWRITE",
                            road_addr: road_addr,
                            road_addr_dtl: road_addr_dtl,
                            jibun_addr: jibun_addr,
                            sido: sido,
                            sigungu: sigungu,
                            town: town,
                            road_name: road_name,
                            building_num: building_num,
                            building_name: building_name,
                            house_type: house_type,
                            house_area: !house_area ? "0" : house_area.replace(/,/g, ""),
                            room_cnt: !room_count ? "0" : room_count,
                            house_selling: house_selling,
                            house_area_over1: !house_area_over1 ? "N" : "Y",
                            house_area_over2: !house_area_over2 ? "N" : "Y",
                            rent_type: house_rent_type,
                            rent_amt: !rent_amt ? "0" : rent_amt.replace(/,/g, ""),
                            monthly_amt: !monthly_rent_amt ? "0" : monthly_rent_amt.replace(/,/g, ""),
                            minor_child_cnt: !minor_child_cnt ? "0" : minor_child_cnt,
                            estate_no: estate_no,
                            wallpaper_flag: wallpaper_flag,
                            support_amt: totLhSupport,
                            broker_id: user.user_id,
                            use_flag: "Y",
                            winner_info: winner.map((item) => {
                                return {
                                    ...item,
                                    lh_support_amt: !item.lh_support_amt ? "0" : item.lh_support_amt.replace(/,/g, ""),
                                    winner_charges: !item.winner_charges ? "0" : item.winner_charges.replace(/,/g, ""),
                                    add_charges: !item.add_charges ? "0" : item.add_charges.replace(/,/g, ""),
                                    monthly_rent: !item.monthly_rent ? "0" : item.monthly_rent.replace(/,/g, ""),
                                }
                            }),
                        })
                    )
                }
                // else if(step === 3) {
                //     let senior_rent_data = [], _empty_tot_amt = 0;
                //     for(let i = 0; i < priority.length; i++) {
                //         const item = priority[i];

                //         if(item.use_flag !== 'Y') {
                //             continue;
                //         }

                //         if(!item.rent_floor) {
                //             ALERT("층수를 입력해주세요.");
                //             return;
                //         }
                //         if(!item.room_no) {
                //             ALERT("호수를 입력해주세요.");
                //             return;
                //         }
                //         if(item.empty_type.indexOf('HOUSE') > -1) {
                //             if(!item.room_cnt) {
                //                 ALERT("방개수를 입력해주세요.");
                //                 return;
                //             }
                //         }

                //         const _senior_rent_data = {
                //             rent_seq: item.rent_seq,
                //             empty_type: item.empty_type,
                //             rent_floor: item.rent_floor,
                //             room_no: item.room_no,
                //             room_cnt: !item.room_cnt ? "0" : item.room_cnt,
                //             rent_deposit: !item.rent_deposit ? "0" : item.rent_deposit.replace(/,/g, ""),
                //             rent_fee: !item.rent_fee ? "0" : item.rent_fee.replace(/,/g, ""),
                //             priority_payment: !item.priority_payment ? "0" : item.priority_payment.replace(/,/g, ""),
                //             remark: item.remark,
                //             use_flag: item.use_flag,
                //         }
                //         senior_rent_data.push(_senior_rent_data);
                        
                //         _empty_tot_amt += +(!item.priority_payment ? "0" : item.priority_payment.replace(/,/g, ""));
                //     }
                    
                //     dispatch(
                //         s3_save({
                //             doc_id: doc_id,
                //             rent_tot_amt: !rent_tot_amt ? "0" : rent_tot_amt.replace(/,/g, ""),
                //             empty_tot_amt: _empty_tot_amt,
                //             senior_rent: senior_rent_data,
                //         })
                //     )
                // }
            }
            
            localStorage.setItem('step', step+1);
            localStorage.setItem('privacy_terms_agree', privacy_terms_agree);
            setStep(step + 1);
        }
    }
    const onDeleteWrite = () => {
        if(window.confirm("작성중인 신청서를 정말 삭제(취소)하시겠습니까?\n확인 후 되돌릴 수 없습니다.")) {
            if(doc_id) {
                dispatch(delContent(doc_id));
            }

            //  지원 신청서 삭제 후 리스트 페이지로 이동시킨다.
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0"+(date.getMonth() + 1)).slice(-2);
            const month_m = ("0"+date.getMonth()).slice(-2);

            const listValue = {
                all_search: func.some((el) => el.id==="FUNC061")? "Y" : "N",
                types: 'ENWRITE,ENSAVE,ENREQ,ENACK,ENCHGREQ,ENDOCFAIL,ENDOCADD,ENPROCESS,ENAPVREQ,ENAPVFAIL,ENAPVOK,CONTSTRT,ENMODIFY,',
            }

            navigate(`/list/apply?from_date=${`${year - 2}-${month_m}-01`}&to_date=${`${year}-${month}-${new Date(year, month, 0).getDate()}`}&type=${''}&status=${listValue.types}&user_type=${'WINNER'}&user=${''}&all_search=${listValue.all_search}&page=${'1'}&feedback_status=${''}&area=${''}`, {
                replace: true,
                state: {
                    func: func,
                    auth: user.user_auth,
                    listValue: listValue,
                    applyTypeArr: [],
                }
            })
        }
    }
    // //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    // const onChangeNum = e => {
    //     const re = /^[0-9\b]+$/;
    //     const value = parseNumber(e.target.value);

    //     if (value === '' || re.test(value)) {
    //         dispatch(
    //             changeValue({
    //                 key: e.target.name,
    //                 value: value.replace(regAmt, ","),
    //             })
    //         );
    //     }
    // }
    // ------------------------------------------- 공통 Event



    //  Step1 Event -------------------------------------------
    const onWinnerChange = (item, e) => {
        const {target: {name, value}} = e;

        let valid = true;

        // 지원자 생년월일 길이 6개 제한
        if(name === 'winner_no') {
            if(value.length > 6) {
                valid = false;
            }
        }

        if(valid) {
            dispatch(
                s1_rowChangeValue({
                    idx: item.idx,
                    key: name,
                    value: value,
                })
            );
        }

        if(e.target.name === 'winner_type') {
            const selectApply = cboApply.find(x => x.sub_code === e.target.value);
            if(selectApply) {
                dispatch(
                    s1_rowChangeValue({
                        idx: item.idx,
                        key: 'type_calc_code',
                        value: selectApply.memo,
                    })
                );
            }
        }
    }
    const onAddWinner = e => {
        if(winner.length > 0) {
            if(!winner[winner.length - 1].winner_id && !winner[winner.length - 1].winner_name) {
                ALERT("당첨자 선택 후 추가해주세요.");
                return;
            }
            else if(!winner[winner.length - 1].winner_id && winner[winner.length - 1].winner_name) {
                ALERT("당첨자 직접 입력의 경우 1인 이상 추가할 수 없습니다.");
                return;
            }
        }

        dispatch(s1_setLastIdx({value: winnerLastIdx + 1}));
        dispatch(s1_addRow());
    }
    const onRemoveWinner = (item, e) => {
        dispatch(s1_removeRow({idx: item.idx}));
    }
    const onShowFindUser = (item, e) => {
        if (!isShowFindUser) {
            setIsShowFindUser(item);
        };
    }
    const onCloseFindUser = () => {
        setIsShowFindUser(null);
    }
    const onSelectUser = (item) => {
        if(!item.winner_id) {
            if(winner.filter(x => x.idx !== isShowFindUser.idx).length > 0) {
                ALERT("직접입력의 경우 2인 이상 지원신청할 수 없습니다.");
                return;
            }
        }

        dispatch(
            s1_selectUser({
                user: item,
                row: isShowFindUser,
            })
        );
        setIsShowFindUser(null);
    }
    //  ------------------------------------------- Step1 Event

    //  Step2 Event -------------------------------------------
    const onShowDaumPost = () => {
        setIsShowDaumPost(!isShowDaumPost);
    }
    const handlePostCode = (data) => {
        let _building_num = data.roadAddress;
        _building_num = _building_num.replace(data.sido, '');
        _building_num = _building_num.replace(data.sigungu, '');
        _building_num = _building_num.replace(data.roadname, '').trim();

        dispatch(changeValue({key: 'road_addr', value: data.roadAddress,}));
        dispatch(changeValue({key: 'jibun_addr', value: (data.jibunAddress ? data.jibunAddress : data.autoJibunAddress),}));
        dispatch(changeValue({key: 'sido', value: data.sido,}));
        dispatch(changeValue({key: 'sigungu', value: data.sigungu,}));
        dispatch(changeValue({key: 'town', value: data.bname,}));
        dispatch(changeValue({key: 'road_name', value: data.roadname,}));
        dispatch(changeValue({key: 'building_num', value: _building_num,}));
        dispatch(changeValue({key: 'building_name', value: data.buildingName,}));

        setIsShowDaumPost(!isShowDaumPost);
    }
    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const s2_onChangeNum = e => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                changeValue({
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    const s2_onWinnerChange = (item, key, e) => {
        if(key === "charge_rate") {
            dispatch(
                s1_rowChangeValue({
                    idx: item.idx,
                    key: 'charge_rate',
                    value: e.target.value,
                })
            );
        }
        else {
            const re = /^[0-9\b]+$/;
            const value = parseNumber(e.target.value);

            if (value === '' || re.test(value)) {
                dispatch(
                    s1_rowChangeValue({
                        idx: item.idx,
                        key: e.target.name,
                        value: value.replace(regAmt, ","),
                    })
                );
            }
        }
    }
    const onCalcSupportAmt = () => {
        if(!winner || winner.length === 0 || !winner[0].winner_type) {
            ALERT("신청자의 지원유형이 특정되지 않았습니다.");
            return;
        }
        if(!rent_amt || rent_amt === "0") {
            ALERT("전세금액을 입력해주세요.");
            return;
        }
        if(house_rent_type === "TYPE02") {  //보증부월세
            if(!monthly_rent_amt || monthly_rent_amt === "0") {
                ALERT("전세유형이 보증부월세인 경우 월세금액은 필수입력입니다.");
                return;
            }
        }

        let _codeIdx = +winner[0].type_calc_code;
        if(house_area_over1 || house_area_over2) {
            // _codeIdx = 4;   // 85m 이상 사유에 체크가 되어있다면 계산유형을 변경.
        }
        const applyType = dataEntitleCalc.find(x => String(x.codeIdx) === String(_codeIdx));
        if(applyType.defaultPay.rate.length > 0) {
            if(!winner[0].charge_rate || winner[0].charge_rate === "0") {
                ALERT("입주자부담금 비율을 선택해주세요.");
                return;
            }
        }

        let _persons = [], totalExtraPay = 0;
        winner.forEach(item => {
            totalExtraPay += +(item.add_charges.replace(/,/g, ""));

            _persons.push({
                codeIdx: +(_codeIdx === 4 ? _codeIdx : item.type_calc_code),    // 85m 이상 사유에 체크가 되어있다면 무조건 4번 유형
                defaultPayRate: (!item.charge_rate || item.charge_rate === '0' ? 0 : +item.charge_rate),
                extraPay: (!item.add_charges || item.add_charges === "0") ? 0 : item.add_charges.replace(/,/g, ""),
                winner_idx: item.idx,
            })
        });

        const retCalcAmt = funEntitleCalc({
            total: rent_amt.replace(/,/g, ""),                      // 전세금액총액(전세보증금)
            totlaExtraPay: totalExtraPay,                           // 추가부담금총액
            guaranteeMonthPay: monthly_rent_amt.replace(/,/g, ""),  // 보증부월세액
            persons: _persons,                                      // 개인별 정보
            childCount: minor_child_cnt,                            // 자식 수
        });

        if(!retCalcAmt) {
            ALERT("죄송합니다. 계산 중 오류가 발생하였습니다.");
            return;
        }
        
        if(retCalcAmt.errorSupportMoney) {
            setIsCalcResult("IMPOSSIBLE");
            setCalcComment(retCalcAmt.errorMessage);
            
        }
        else {
            let isOverCharge = false;   //  부담금이 전세금을 초과하는지

            for(let i = 0; i < retCalcAmt.persons.length; i++) {
                const person = retCalcAmt.persons[i];

                if(
                    (!person.monthPay || +person.monthPay <= 0) ||
                    (!person.supportMoney || +person.supportMoney <= 0)
                ) {
                    isOverCharge = true;
                }

                //  추가부담금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'add_charges',
                        value: String(person.extraPay).replace(regAmt, ","),
                    })
                );

                //  입주자부담금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'winner_charges',
                        value: String(person.defaultPay).replace(regAmt, ","),
                    })
                );

                //  월임대료
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'monthly_rent',
                        value: String(person.monthPay).replace(regAmt, ","),
                    })
                );

                //  LH지원금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'lh_support_amt',
                        value: String(person.supportMoney).replace(regAmt, ","),
                    })
                );
            };

            if(!isOverCharge) {
                setIsCalcResult("POSSIBLE");
                setCalcComment("");
            }
            else {
                setIsCalcResult("IMPOSSIBLE");
                setCalcComment("입주자 추가부담금과 입주자 부담금의 합계가 전세금보다 작아야하며, 월임대료가 1원 이상이어야 합니다.");
            }
        }
    }
    //  선택된 지원유형에서 선택가능한 입주자부담금 비율
    useEffect(() => {
        if(!winner || winner.length === 0 || !winner[0].winner_type) {
            return;
        }
        dataEntitleCalc.forEach(applyType => {
            if(String(applyType.codeIdx) === String(winner[0].type_calc_code)) {
                setPossibleRate(applyType.defaultPay.rate);
                setPossibleAmt(applyType.defaultPay.static);
            }
        });
    },[winner])
    //  전세라면 보증부월세 초기화
    useEffect(() => {
        if(house_rent_type === "TYPE01") {
            dispatch(
                changeValue({
                    key: 'monthly_rent_amt',
                    value: '',
                })
            );
        }
    }, [dispatch, house_rent_type])
    //  ------------------------------------------- Step2 Event

    // //  Step3 Event -------------------------------------------
    // const onPriorityChange = (item, e) => {
    //     dispatch(
    //         s3_rowChangeValue({
    //             idx: item.rent_seq,
    //             key: e.target.name,
    //             value: e.target.value,
    //         })
    //     );

    //     //  타입 또는 방개수 변경시, 지역에 맞는 금액을 적용한다.
    //     if(e.target.name === "empty_type" || e.target.name === "room_cnt") {
    //         const _priority = priority.find(x => x.rent_seq === item.rent_seq);
    //         if(!_priority) return;

    //         const _roomType = e.target.name === "empty_type" ? e.target.value : _priority.empty_type;
    //         const _roomCount = e.target.name === "room_cnt" ? e.target.value : _priority.room_cnt;

    //         //  단, 공실 또는 집주인이 사용중인 경우에만 실행한다.
    //         if((_roomType.indexOf("EMPTY") > -1) || _roomType.indexOf("OWNER") > -1) {
    //             const priorityData = +(dataEmptyRoomCalc({
    //                 addressStr: jibun_addr,
    //                 emptyType: (_roomType.indexOf("HOUSE") > -1 ? 'h' : 's'),
    //             }))

    //             //  타입이 주택이라면 방개수 * 금액을 적용
    //             if(_roomType.indexOf("HOUSE") > -1) {
    //                 dispatch(
    //                     s3_rowChangeValue({
    //                         idx: item.rent_seq,
    //                         key: 'priority_payment',
    //                         value: priorityData * _roomCount,
    //                     })
    //                 );
    //             }
    //             //  타입이 주택이 아니라면 호실당 금액을 적용
    //             else {
    //                 dispatch(
    //                     s3_rowChangeValue({
    //                         idx: item.rent_seq,
    //                         key: 'priority_payment',
    //                         value: priorityData,
    //                     })
    //                 );
    //             }
    //         }
    //         else {
    //             dispatch(
    //                 s3_rowChangeValue({
    //                     idx: item.rent_seq,
    //                     key: 'priority_payment',
    //                     value: '',
    //                 })
    //             );
    //             //  임대중 이면서 주택이 아니라면 방개수에 0을 적용
    //             if(_roomType.indexOf("HOUSE") === -1) {
    //                 dispatch(
    //                     s3_rowChangeValue({
    //                         idx: item.rent_seq,
    //                         key: 'room_cnt',
    //                         value: '0',
    //                     })
    //                 );
    //             }
    //         }
    //     }
    // }
    // const onPriorityChangeNum = (item, e) => {
    //     const re = /^[0-9\b]+$/;
    //     const value = parseNumber(e.target.value);

    //     if (value === '' || re.test(value)) {
    //         dispatch(
    //             s3_rowChangeValue({
    //                 idx: item.rent_seq,
    //                 key: e.target.name,
    //                 value: value.replace(regAmt, ","),
    //             })
    //         );
    //     }
    // }
    // const onAddPriority = e => {
    //     dispatch(s3_setLastIdx({value: priorityLastIdx + 1}));
    //     dispatch(s3_addRow());
    // }
    // const onRemovePriority = (item, e) => {
    //     dispatch(s3_removeRow({idx: item.rent_seq}));
    // }
    // //  ------------------------------------------- Step3 Event

    //  Step4 Event -------------------------------------------
    const onFileChange = (item, e) => {
        
        for (let idx = 0; idx < e.target.files.length; idx++) {
            let fileIdx = fileLastIdx + idx + 1;
            const file = e.target.files[idx];
            if(!file) return;

            dispatch(s4_addRow({subCode: item.sub_code}));

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                dispatch(
                    s4_rowChangeValue({
                        idx: fileIdx,
                        fileReader: reader,
                        fileName: file.name,
                        fileExtension: file.type,
                    })
                );
            }
        }
    }
    const onRemoveFile = (item, e) => {
        dispatch(
            s4_removeRow({
                subCode: item.file_type, idx: item.idx
            })
        );
    }
    const onShowUserAgree = e => {
        let msg;
        if(doc_status === 'ENWRITE') {
            msg = "신청하시겠습니까?";
        }
        else if(doc_status === 'ENSAVE') {
            msg = "서류를 추가하시겠습니까?";
        }

        if(!msg) return;

        if(window.confirm(msg)) {
            dispatch(
                docReq({
                    doc_id: doc_id,
                    doc_type: doc_type,
                    doc_status: doc_status,
                    fileList: fileList,
                    winner: winner,
                })
            )
        }
    }
    const onFileAddClick = () => {
        dispatch(
            fileAdd({
                doc_id: doc_id,
                doc_type: doc_type,
                fileList: fileList,
            })
        )
    }
    const onCloseUserAgree = () => {
        setIsShowUserAgree(false);
    }
    //  ------------------------------------------- Step4 Event

    useEffect(() => {
        if(goList) {
            //  신청 또는 서류보완 이후 리스트 페이지로 이동시킨다.
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0"+(date.getMonth() + 1)).slice(-2);
            const month_m = ("0"+date.getMonth()).slice(-2);

            const listValue = {
                all_search: func.some((el) => el.id==="FUNC061")? "Y" : "N",
                types: 'ENWRITE,ENSAVE,ENREQ,ENACK,ENCHGREQ,ENDOCFAIL,ENDOCADD,ENPROCESS,ENAPVREQ,ENAPVFAIL,ENAPVOK,CONTSTRT,ENMODIFY,',
            }

            navigate(`/list/apply?from_date=${`${year - 2}-${month_m}-01`}&to_date=${`${year}-${month}-${new Date(year, month, 0).getDate()}`}&type=${''}&status=${listValue.types}&user_type=${'WINNER'}&user=${''}&all_search=${listValue.all_search}&page=${'1'}&feedback_status=${''}&area=${''}&isfirst=${'N'}`, {
                replace: true,
                state: {
                    func: func,
                    auth: user.user_auth,
                    listValue: listValue,
                    applyTypeArr: [],
                }
            })
        }
    }, [goList, func, navigate, user])

    const propDatas = {
        doc_id,
        doc_status,
        userInfo,
        
        // //  Common
        cboLease,
        cboApply,
        cboHousing,
        cboRent,
        // cboEmpty,
        createUser,
        user,
        func,

        //  Step1
        winner,
        privacy_terms_agree,
        isShowPrivacyTerms,
        setIsShowPrivacyTerms,

        //  Step2
        broker_company,
        broker_telno,
        broker_reg_no,
        road_addr,
        road_addr_dtl,
        house_type,
        house_area,
        room_count,
        house_selling,
        house_area_over1,
        house_area_over2,
        house_rent_type,
        rent_amt,
        monthly_rent_amt,
        minor_child_cnt,
        estate_no,
        wallpaper_flag,
        possibleRate,       //  지원유형별 지원가능한 부담금비율
        possibleAmt,
        isCalcResult,       //  지원가능 여부
        calcComment,        //  지원가능 여부 코멘트

        // //  Step3
        // priority,
        // rent_tot_amt,

        //  Step4
        doc_type,
        fileList,
        lackReason,

        cantModify,

        //  사용자검색 모달
        isShowFindUser,

        //  다음 주소찾기 API 모달
        isShowDaumPost,

        //  당첨자 동의 모달
        isShowUserAgree,

        write,
        error,
        onChange,
        // onChangeNum,
        onChecked,
        onWinnerChange,
        onAddWinner,
        onRemoveWinner,
        onShowDaumPost,
        handlePostCode,
        s2_onWinnerChange,
        s2_onChangeNum,
        onCalcSupportAmt,
        // onPriorityChange,
        // onPriorityChangeNum,
        // onAddPriority,
        // onRemovePriority,
        onFileChange,
        // onAddFile,
        onRemoveFile,
        onFileAddClick,
        onShowFindUser,
        onCloseFindUser,
        onSelectUser,
        onShowUserAgree,
        onCloseUserAgree,
        onPrevClick,
        onNextClick,
        onDeleteWrite,
        onModifyMode,
    }

    return (
        <>
            {step === 1 && <EntitleWriteStep1 {...propDatas}/>}
            {step === 2 && <EntitleWriteStep2 {...propDatas}/>}
            {/* {step === 3 && <EntitleWriteStep3 {...propDatas}/>} */}
            {step === 3 && <EntitleWriteStep4 {...propDatas}/>}
        </>
    );
}

export default EntitleWriteContainer;