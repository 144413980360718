import { decimalAdjust, regAmt } from "../common"

export const dataEntitleCalc = [
    {
        codeIdx: 0,
        supportMoney: [120000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [2, 5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '일반,고령자',
    }, // [기존주택] 일반,고령자
    {
        codeIdx: 1,
        supportMoney: [120000000],
        supportMaxRate: [200],
        defaultPay: {
            static: 0,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[소년소녀가정] 아동1인',
    }, // [소년소녀가정] 아동1인
    {
        codeIdx: 2,
        supportMoney: [135000000],
        supportMaxRate: [200],
        defaultPay: {
            static: 0,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[소년소녀가정] 아동2인이상',
    }, // [소년소녀가정] 아동2인이상
    {
        codeIdx: 3,
        supportMoney: [120000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [2, 5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[기존주택] 공동생활85이하',
    }, // [기존주택] 공동생활85이하
    {
        codeIdx: 4,
        supportMoney: [150000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [2, 5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[기존주택] 공동생활85초과',
    }, // [기존주택] 공동생활85초과
    {
        codeIdx: 5,
        supportMoney: [135000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [2]
        },
        childAdv: [0, 0, 0, 20000000, 40000000, 60000000, 80000000, 100000000, 120000000, 140000000, 160000000],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '다자녀',
    }, // 다자녀
    {
        codeIdx: 6,
        supportMoney: [135000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[신혼부부] 1형',
    }, // [신혼부부] 1형
    {
        codeIdx: 7,
        supportMoney: [240000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [20]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[신혼부부] 2형',
    }, // [신혼부부] 2형
    {
        codeIdx: 8,
        supportMoney: [120000000, 150000000, 200000000],
        supportMaxRate: [150, 200, 200],
        defaultPay: {
            static: 1000000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[청년] 1순위',
    }, // [청년] 1순위
    {
        codeIdx: 9,
        supportMoney: [120000000, 150000000, 200000000],
        supportMaxRate: [150, 200, 200],
        defaultPay: {
            static: 2000000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[청년] 2순위',
    }, // [청년] 2순위
    {
        codeIdx: 10,
        supportMoney: [120000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 500000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[비주택거주자] 주거취약',
    }, // [비주택거주자] 주거취약
    {
        codeIdx: 11,
        supportMoney: [120000000],
        supportMaxRate: [150],
        defaultPay: {
            static: 3000000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[만19~39세] 300만원',
    }, // [만19~39세] 300만원
    {
        codeIdx: 12,
        supportMoney: [120000000],
        supportMaxRate: [150],
        defaultPay: {
            static: 4000000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[만19~39세] 400만원',
    }, // [만19~39세] 400만원
    {
        codeIdx: 13,
        supportMoney: [120000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 500000,
            rate: [5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[주거취약계층] 고시원,쪽방,범죄피해',
    }, // [주거취약계층] 고시원,쪽방,범죄피해
    {
        codeIdx: 14,
        supportMoney: [120000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[범죄피해] 주거취약',
    }, // [범죄피해] 주거취약
    {
        codeIdx: 15,
        supportMoney: [120000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 1000000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '기본금100만Only',
    }, // 기본금100만Only
    {
        codeIdx: 16,
        supportMoney: [130000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [2, 5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '일반고령자(2023)',
    }, // 일반고령자(2023)
    {
        codeIdx: 17,
        supportMoney: [130000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 500000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '주거취약50만(2023)',
    }, // 주거취약50만(2023)
    {
        codeIdx: 18,
        supportMoney: [130000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '주거취약5프로(2023)',
    }, // 주거취약5프로(2023)
    {
        codeIdx: 19,
        supportMoney: [145000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '신혼부부1형(2023)',
    }, // 신혼부부1형(2023)
    {
        codeIdx: 20,
        supportMoney: [155000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [2]
        },
        childAdv: [0, 0, 0, 20000000, 40000000, 60000000, 80000000, 100000000, 120000000, 140000000, 160000000],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '다자녀(2023)',
    }, // 다자녀(2023)
    {
        codeIdx: 21,
        supportMoney: [130000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 1000000,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '기본금100만(2023)',
    }, // 기본금100만(2023)
    {
        codeIdx: 22,
        supportMoney: [160000000],
        supportMaxRate: [250],
        defaultPay: {
            static: 0,
            rate: [5]
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '공동생활85초과(2023)',
    }, // 공동생활85초과(2023)
    {
        codeIdx: 23,
        supportMoney: [130000000],
        supportMaxRate: [200],
        defaultPay: {
            static: 0,
            rate: []
        },
        childAdv: [],
        yearRate: { underRate: 1, underRange: 40000000, betweenRate: 1.5, overRange: 60000000, overRate: 2 },
        typeName: '[소년소녀가정]아동1인(2023)',
    }, // [소년소녀가정]아동1인(2023)
]

// 입력값 개인별 정보 구조
export const entitleCalcInputPersonStru = {
    codeIdx: 0,             // 유형코드
    defaultPayRate: 0,      // 입주자부담금 비율값
    extraPay: 0,            // 입주자추가부담금
    winner_idx: null,
}

// 입력값 구조
export const entitleCalcInputDataStru = {
    total: 0,               // 전세금액총액(전세보증금)
    totlaExtraPay: 0,       // 추가부담금총액
    guaranteeMonthPay: 0,   // 보증부월세액
    persons: [],            // 개인별 정보
    childCount: 0,          // 자식 수
}

// 반환값 개인별 정보 구조
const entitleCalcOutputPersonStru = {
    defaultPay: 0,          // 입주자기본부담금
    extraPay: 0,            // 입주자추가부담금
    monthPay: 0,            // 월임대료
    supportMoney: 0,        // LH지원금
    winner_idx: null,
}

// 반환값 구조
const entitleCalcOutputDataStru = {
    total: 0,                   // 전세금액총액(전세보증금)
    supportMoney: 0,            // LH지원금액(전세임대대여금)
    guaranteeMonthPay: 0,       // 보증부월세액
    persons: [],                // 개인별 정보
    errorSupportMoney: false,   // LH지원금액 오류여부
    errorMessage: '',           // 계산 코멘트
}

export const funEntitleCalc = inputData => {
    //  초기화
    entitleCalcOutputPersonStru.defaultPay = 0;         // 입주자기본부담금
    entitleCalcOutputPersonStru.extraPay = 0;           // 입주자추가부담금
    entitleCalcOutputPersonStru.monthPay = 0;           // 월임대료
    entitleCalcOutputPersonStru.supportMoney = 0;       // LH지원금
    entitleCalcOutputPersonStru.winner_idx = null;

    entitleCalcOutputDataStru.total = 0;                    // 전세금액총액(전세보증금)
    entitleCalcOutputDataStru.supportMoney = 0;             // LH지원금액(전세임대대여금)
    entitleCalcOutputDataStru.guaranteeMonthPay = 0;        // 보증부월세액
    entitleCalcOutputDataStru.persons = [];                 // 개인별 정보
    entitleCalcOutputDataStru.errorSupportMoney = false;    // LH지원금액 오류여부
    entitleCalcOutputDataStru.errorMessage = '';            // 계산 코멘트

    

    if( inputData && inputData.persons && (inputData.persons.length > 0) ) {    // 입력값이 정상적으로 있는지 확인
        let codeError = false;
        let totalSupportLimit = 0;

        // 입력값 중 구성 사람의 첫번째 사람의 유형코드에 따른 구분
        switch (inputData.persons[0].codeIdx) {
            case 8:
            case 9: {
                // 청년인 경우 다른 사람들도 청년이어야 함
                for(let i=1; i<inputData.persons.length; i++) {
    
                    switch (inputData.persons[i].codeIdx) {
                        case 8:
                        case 9:
                            break;
                    
                        default: {
                            codeError = true;
                            break;
                        }
                    }

                    if( codeError ) {
                        break;
                    }
                }

                break;
            }
        
            default: {
                // 대상 지원유형의 입주자부담금 비율 배열에 값이 있다면 입력된 비율값에 대한 검사
                if( inputData.persons[0].codeIdx < dataEntitleCalc.length ) {
                    if( dataEntitleCalc[inputData.persons[0].codeIdx].defaultPay.rate.length > 0 ) {
                        let isOK = false;
                        
                        if(dataEntitleCalc[inputData.persons[0].codeIdx].defaultPay.rate.indexOf(inputData.persons[0].defaultPayRate) > -1) {
                            isOK = true;
                        }

                        //  단, 입주자부담금 비율과 금액이 둘 다 존재하는 경우 사용자가 비율과 금액 중 선택한다.
                        //  defaultPayRate의 값이 -1 이라면 금액을 선택한 것이므로 isOK에 true를 저장하여 무조건 검사를 통과시킨다.
                        if(inputData.persons[0].defaultPayRate === -1) {
                            isOK = true;
                        }

                        if( isOK === false ) {
                            codeError = true;
                        }
                    }
                }
                break;
            }
        }

        // 문제가 없어야 진행
        if( codeError === false ) {
            const retVal = Object.assign({}, entitleCalcOutputDataStru);
            
            retVal.total = inputData.total;                             // [전세보증금액 #1]
            retVal.guaranteeMonthPay = inputData.guaranteeMonthPay;     // [보증부월세액 #2]
            retVal.supportMoney = 0;                                    // [지원금합계 #3]
            
            // [최대 지원 가능금액 #4] 계산
            if( (dataEntitleCalc[inputData.persons[0].codeIdx].supportMoney.length >= inputData.persons.length) // [셰어 인원] 보다 [셰어 지원금액 배열]이 더 크거나
             || (dataEntitleCalc[inputData.persons[0].codeIdx].childAdv.length >= inputData.childCount) ) {     // [자녀 수] 보다 [자녀 인원별 지원금액 배열]이 더 크다면
                totalSupportLimit = (
                                        dataEntitleCalc[inputData.persons[0].codeIdx].supportMoney[inputData.persons.length - 1] +  //  기본지원금에
                                        (!dataEntitleCalc[inputData.persons[0].codeIdx].childAdv[inputData.childCount] ? 0 : dataEntitleCalc[inputData.persons[0].codeIdx].childAdv[inputData.childCount])  //  다자녀 추가지원금을 더함
                                    )
            }

            // [전세보증금액 #1]이 [지원한도액 #5](유형별 최대지원금액 * ooo%)보다 적은 금액이어야 함
            let maxDepositAmt = dataEntitleCalc[inputData.persons[0].codeIdx].supportMoney[inputData.persons.length - 1] * (dataEntitleCalc[inputData.persons[0].codeIdx].supportMaxRate[inputData.persons.length - 1] / 100);
            if( retVal.total > maxDepositAmt) {
                retVal.errorSupportMoney = true;
                retVal.errorMessage = "전세금액이 한도액(" + String(maxDepositAmt).replace(regAmt, ",") + " 원)을 초과합니다.\n전세금액을 조정해주세요.";
                return retVal;
            }

            const personBurdenMoney = retVal.total / inputData.persons.length;  // 개인별 부담 금액

            for(let i=0; i<inputData.persons.length; i++) {
                let _entitleCalcOutputPersonStru = Object.assign({}, entitleCalcOutputPersonStru);
                
                // 입주자부담금을 금액으로 계산
                if( inputData.persons[0].defaultPayRate <= 0 ) {
                    _entitleCalcOutputPersonStru.defaultPay = dataEntitleCalc[inputData.persons[i].codeIdx].defaultPay.static;     // 개개인의 입주자부담금
                }
                // 입주자부담금을 비율로 계산
                else if( inputData.persons[0].defaultPayRate > 0 ) {
                    //  전세금액이 유형별 [최대 지원 가능금액 #4]이 크다면, 입주자부담금은 [최대 지원 가능금액 #4] * 비율로 계산
                    if(retVal.total > totalSupportLimit) {
                        _entitleCalcOutputPersonStru.defaultPay = totalSupportLimit * (inputData.persons[i].defaultPayRate / 100);    // 개개인의 입주자부담금
                    }
                    //  전세금액이 유형별 [최대 지원 가능금액 #4]보다 작다면, 입주자부담금은 전세금액 * 비율로 계산
                    else {
                        _entitleCalcOutputPersonStru.defaultPay = retVal.total * (inputData.persons[i].defaultPayRate / 100);    // 개개인의 입주자부담금
                    }
                }
                
                // 보증부월세액이 있는 경우 [3개월분의 월세액]과 [입주자추가부담금]을 비교하여 최종 입주자추가부담금을 계산
                // 단, 보증부월세액이 60만원을 초과하는 경우 12개월분으로 계산.
                let guaranteePay = 0;   // 3개월 or 12개월 월세액(지급보증)
                if(retVal.guaranteeMonthPay > 600000) {
                    guaranteePay = retVal.guaranteeMonthPay / inputData.persons.length * 12
                }
                else if (retVal.guaranteeMonthPay > 0) {
                    guaranteePay = retVal.guaranteeMonthPay / inputData.persons.length * 3
                }
                if(inputData.persons[i].extraPay > guaranteePay) {
                    _entitleCalcOutputPersonStru.extraPay = inputData.persons[i].extraPay;
                }
                else {
                    _entitleCalcOutputPersonStru.extraPay = guaranteePay;
                }
                
                // 개인별 실제 지원금액
                const personSupportMoney = personBurdenMoney - _entitleCalcOutputPersonStru.extraPay - _entitleCalcOutputPersonStru.defaultPay
                _entitleCalcOutputPersonStru.supportMoney = decimalAdjust('floor', personSupportMoney, 0);  // 1원자리 내림처리 -> 220713 내림처리 안함

                // 월임대료 계산
                let monthPay = 0;
                if( dataEntitleCalc[inputData.persons[i].codeIdx].yearRate.overRange < _entitleCalcOutputPersonStru.supportMoney ) {
                    monthPay = +(_entitleCalcOutputPersonStru.supportMoney * (dataEntitleCalc[inputData.persons[i].codeIdx].yearRate.overRate / 100) / 12);
                } else if( dataEntitleCalc[inputData.persons[i].codeIdx].yearRate.underRange < _entitleCalcOutputPersonStru.supportMoney ) {
                    monthPay = +(_entitleCalcOutputPersonStru.supportMoney * (dataEntitleCalc[inputData.persons[i].codeIdx].yearRate.betweenRate / 100) / 12);
                } else {
                    monthPay = +(_entitleCalcOutputPersonStru.supportMoney * (dataEntitleCalc[inputData.persons[i].codeIdx].yearRate.underRate / 100) / 12);
                }
                monthPay = decimalAdjust('floor', monthPay, 1);   // 1원자리 내림처리
                
                _entitleCalcOutputPersonStru.monthPay = monthPay;
                _entitleCalcOutputPersonStru.winner_idx = inputData.persons[i].winner_idx;

                retVal.persons.push(_entitleCalcOutputPersonStru);

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////
                // 지원금총액 계산
                retVal.supportMoney += _entitleCalcOutputPersonStru.supportMoney + _entitleCalcOutputPersonStru.defaultPay;
            }

            // [필요한 지원금액 #3]이 [최대 지원 가능금액 #4]보다 적은 금액이어야 함
            if( retVal.supportMoney > totalSupportLimit ) {
                const minimumAddCharge = retVal.total - totalSupportLimit;

                retVal.errorSupportMoney = true;
                retVal.errorMessage = "최대 지원 가능금액은 입주자부담금을 포함하여 " + String(totalSupportLimit).replace(regAmt, ",") + "원 이므로\n" +
                "입주자 추가부담금의 합계 금액이 최소한 " + String(minimumAddCharge).replace(regAmt, ",") + "원 이상이어야 합니다.\n" + 
                "입주자 추가부담금을 조정해주세요.";
                return retVal;
            }
                
            return retVal;
        }
    }
}

export default dataEntitleCalc;