import React from 'react';
import { ShowTelDash } from '../../lib/dataUTIL';

const FeedbackPrint = ({feedback}) => { 
    // const {
    //     account_no,
    //     account_owner,
    //     add_charge,
    //     bank_name,
    //     cont_end_date,
    //     contract_area,
    //     contract_type_name,
    //     current_cont_end_date,
    //     cust_addr,
    //     cust_birth,
    //     cust_name,
    //     dedicated_area,
    //     dup_cust_name,
    //     dup_cust_phone,
    //     dup_cust_type,
    //     duplication_yn,
    //     feedback_date,
    //     fund_loan_yn,
    //     guarantee_mon_amt,
    //     house_addr,
    //     house_member,
    //     house_owner_yn,
    //     lawsuit_yn,
    //     lh_add_charge,
    //     lh_support_amt,
    //     lh_win_charge,
    //     owner_name,
    //     owner_tel,
    //     remark,
    //     renewal_delay_yn,
    //     rent_amt,
    //     rent_surcharge,
    //     req_cnt,
    //     sigungu,
    //     support_amt,
    //     to_lh,
    //     unpaid_amt,
    //     wallpaper_yn,
    //     winner_charge,
    //     winner_type_name,
    // } = feedback;
    const item = feedback;

    return (
        <div className='tableWrap feedback' style={{pageBreakBefore:'always'}}>
            <div className='viewerContainer borderwrap bd0'>
                <h2 className='text-c'>피드백</h2>  
                <div className='feedInfo'>
                {/* {feedback_user.map((item, idx) => ( */}
                    {/* <Fragment key={idx}> */}
                    <div style={{gridColumn:"1/3", paddingLeft:'5px'}}> 권리분석담당자 : {item.create_user_name}</div>
                    {/* {item.update_user_name && (<div style={{gridColumn:"3/5", paddingLeft:'5px'}}> 피드백 담당자 : {item.update_user_name}</div>)} */}
                    <div className='info-box' style={{gridColumn:"1/5"}}>
                        <div>피드백 요청 Comment</div>
                        <div>{item.to_lh}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div>의뢰접수일</div>
                        <div>{item.feedback_date}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div>입주유형</div>
                        <div>{item.winner_type_name}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div>계약유형</div>
                        <div>{item.contract_type_name}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div>성명</div>
                        <div>{item.cust_name}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div>생년월일</div>
                        <div>{item.cust_birth}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div>현거주 지역구</div>
                        <div>{item.sigungu}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div>세대원</div>
                        <div>{item.house_member || ''}</div>
                    </div>


                    <div className='info-box' style={{gridColumn:"1/3"}}>
                        <div>이주시 신청주택 주소(도로명)</div>
                        <div>{item.house_addr}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/5"}}>
                        <div>현 주소</div>
                        <div>{item.cust_addr}</div>
                    </div>

                    <div className="mt8" style={{gridColumn:"1/5"}}></div>
                    <div className='info-box ' style={{gridColumn:"1/2"}}>
                        <div className='focusY'>계약가능여부</div>
                        <div className='rbtn'>
                            <input type="radio" id="contract_yn_Y" checked={item.contract_yn === "Y"}/>
                            <label htmlFor="contract_yn_Y">예</label>
                            <input type="radio" id="contract_yn_N" checked={item.contract_yn === "N"}/>
                            <label htmlFor="contract_yn_N">아니오</label>
                        </div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div className='focusY'>세대원 확인</div>
                        <div><input type="text" name="house_member" id="house_member" value={item.house_member || ''} placeholder='세대원' onChange={() => {}}/></div>
                    </div>
                    {/* New lh_add_charge*/}
                    {/* <div className='info-box' style={{gridColumn:"6/7"}}>
                        <div>입주자추가부담금</div>
                        <div><input type="text" name="lh_add_charge" id="lh_add_charge" value={item.lh_add_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자추가부담금' /></div>
                    </div> */}
                    {/* New */}
                    {/* <div className='info-box' style={{gridColumn:"7/8"}}>
                        <div>보증부월세</div>
                        <div><input type="text" name="lh_guarantee_mon_amt" id="lh_guarantee_mon_amt" value={item.lh_guarantee_mon_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='보증부월세' /></div>
                    </div> */}
                        <div className='info-box' style={{gridColumn:"1/2"}}>
                            <div className='focusY'>주택소유여부</div>
                            <div className='rbtn'>
                                <input type="radio" id="house_owner_yn_Y" checked={item.house_owner_yn === "Y"}/>
                                <label htmlFor="house_owner_yn_Y">예</label>
                                <input type="radio" id="house_owner_yn_N" checked={item.house_owner_yn === "N"}/>
                                <label htmlFor="house_owner_yn_N">아니오</label>
                            </div>
                        </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div className='focusY'>타임대여부</div>
                        <div className='rbtn'>
                            <input type="radio" id="other_rent_yn_Y" checked={item.other_rent_yn === "Y"}/>
                            <label htmlFor="other_rent_yn_Y">예</label>
                            <input type="radio" id="other_rent_yn_N" checked={item.other_rent_yn === "N"}/>
                            <label htmlFor="other_rent_yn_N">아니오</label>
                        </div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div className='focusY'>기금대출여부</div>
                        <div className='rbtn'>
                            <input type="radio" id="fund_loan_yn_Y" checked={item.fund_loan_yn === "Y"}/>
                            <label htmlFor="fund_loan_yn_Y">예</label>
                            <input type="radio" id="fund_loan_yn_N" checked={item.fund_loan_yn === "N"}/>
                            <label htmlFor="fund_loan_yn_N">아니오</label>
                        </div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div className='focusY'>갱신유예유무</div>
                        <div className='rbtn'>
                            <input type="radio" id="renewal_delay_yn_Y" checked={item.renewal_delay_yn === "Y"}/>
                            <label htmlFor="renewal_delay_yn_Y">예</label>
                            <input type="radio" id="renewal_delay_yn_N" checked={item.renewal_delay_yn === "N"}/>
                            <label htmlFor="renewal_delay_yn_N">아니오</label>
                        </div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div className='focusY'>소송</div>
                        <div className='rbtn'>
                            <input type="radio" id="lawsuit_yn_Y" checked={item.lawsuit_yn === "Y"}/>
                            <label htmlFor="lawsuit_yn_Y">예</label>
                            <input type="radio" id="lawsuit_yn_N" checked={item.lawsuit_yn === "N"}/>
                            <label htmlFor="lawsuit_yn_N">아니오</label>
                        </div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div className='focusY'>LH미납이자</div>
                        <div><input type="text" name="unpaid_amt" id="unpaid_amt_Y" value={item.unpaid_amt || ''} placeholder='LH미납이자' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div className='focusY'>임대료할증</div>
                        <div><input type="text" name="rent_surcharge" id="rent_surcharge" value={item.rent_surcharge || ''} placeholder='임대료할증' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div className='focusY'>도배지원가능여부</div>
                        <div className='rbtn'>
                            <input type="radio" id="wallpaper_yn_Y" checked={item.wallpaper_yn === "Y"}/>
                            <label htmlFor="wallpaper_yn_Y">예</label>
                            <input type="radio" id="wallpaper_yn_N" checked={item.wallpaper_yn === "N"}/>
                            <label htmlFor="wallpaper_yn_N">아니오</label>
                        </div>
                    </div>
                    
                    {/* New */}
                    <div className="mt8" style={{gridColumn:"1/5"}}></div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div>현재지원차수</div>
                        <div><input type="text" name="req_cnt" id="req_cnt" value={item.req_cnt || ''} placeholder='지원회차' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div>현재계약종료일</div>
                        <div><input type="date" max="9999-12-31" name="current_cont_end_date" id="current_cont_end_date" value={item.current_cont_end_date} onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div>Wise상 임대인</div>
                        <div><input type="text" name="owner_name" id="owner_name" value={item.owner_name || ''} placeholder='Wise상 임대인' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div>임대인 연락처</div>
                        <div><input type="text" name="owner_tel" id="owner_tel" value={ShowTelDash(item.owner_tel) || ''} placeholder='임대인 연락처' onChange={() => {}}/></div>
                    </div>
                    {/* New */}
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div>계약 / 전용면적</div>
                        <div>{`${item.contract_area || '-'} / ${item.dedicated_area || '-'}`}</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div>총금액</div>
                        <div><input type="text" name="rent_amt" id="rent_amt" value={item.rent_amt || ''} placeholder='총금액' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div>LH지원금</div>
                        <div><input type="text" name="support_amt" id="support_amt" value={item.support_amt || ''} placeholder='LH지원금' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div>입주자 부담금</div>
                        <div><input type="text" name="winner_charge" id="winner_charge" value={item.winner_charge || ''}  placeholder='입주자 부담금' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div>입주자 추가부담금</div>
                        <div><input type="text" name="add_charge" id="add_charge" value={item.add_charge || ''} placeholder='입주차 추가부담금' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div>보증부월세액</div>
                        <div><input type="text" name="guarantee_mon_amt" id="guarantee_mon_amt" value={item.guarantee_mon_amt || ''} placeholder='보증부월세' onChange={() => {}}/></div>
                    </div>
                    
                    <div className="mt8" style={{gridColumn:"1/5"}}></div>
                    <div style={{gridColumn:"1/8"}}>
                        <div className='ml5' style={{color:"#545454", fontSize:"13px"}}>보증금 반환 계좌</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div>은행명</div>
                        <div><input type="text" name="bank_name" id="bank_name" value={item.bank_name || ''} placeholder='은행명' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/4"}}>
                        <div>가상계좌</div>
                        <div><input type="text" name="account_no" id="account_no" value={item.account_no || ''} placeholder='계좌번호' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div>예금주</div>
                        <div><input type="text" name="account_owner" id="account_owner" value={item.account_owner || ''}  placeholder='예금주' onChange={() => {}}/></div>
                    </div>
                    
                    <div className="mt8" style={{gridColumn:"1/5"}}></div>
                    <div style={{gridColumn:"1/5"}}>
                        <div className='ml5' style={{color:"#545454", fontSize:"13px"}}>중복 내역</div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div className='focusO'>입주주택중복여부</div>
                        <div className='rbtn'>
                            <input type="radio" id="duplication_yn_Y" checked={item.duplication_yn === "Y"}/>
                            <label htmlFor="duplication_yn_Y">예</label>
                            <input type="radio" id="duplication_yn_N" checked={item.duplication_yn === "N"}/>
                            <label htmlFor="duplication_yn_N">아니오</label>
                        </div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div className='focusO'>입주유형</div>
                        <div><input type="text" name="dup_cust_type" id="dup_cust_type" value={item.dup_cust_type || ''}  placeholder='입주유형' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div className='focusO'>입주자</div>
                        <div><input type="text" name="dup_cust_name" id="dup_cust_name" value={item.dup_cust_name || ''}  placeholder='입주자' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div className='focusO'>전화번호</div>
                        <div><input type="text" name="dup_cust_phone" id="dup_cust_phone" value={item.dup_cust_phone || ''}  placeholder='전화번호' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/2"}}>
                        <div className='focusO'>총금액</div>
                        <div><input type="text" name="lh_rent_amt" id="lh_rent_amt" value={item.lh_rent_amt || ''}  placeholder='총금액' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"2/3"}}>
                        <div className='focusO'>LH지원금</div>
                        <div><input type="text" name="lh_support_amt" id="lh_support_amt" value={item.lh_support_amt || ''}  placeholder='LH지원금' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"3/4"}}>
                        <div className='focusO'>입주자부담금</div>
                        <div><input type="text" name="lh_win_charge" id="lh_win_charge" value={item.lh_win_charge || ''}  placeholder='입주자부담금' onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"4/5"}}>
                        <div className='focusO'>계약만료일</div>
                        <div><input type="date" name="cont_end_date" id="cont_end_date" value={item.cont_end_date} max="9999-12-31" onChange={() => {}}/></div>
                    </div>
                    <div className='info-box' style={{gridColumn:"1/5"}}>
                        <div>특이사항 입력</div>
                        <div className='remark'><textarea name="remark" id="remark" value={item.remark} placeholder='피드백 내용을 입력해주세요.' onChange={() => {}}/></div>
                    </div>
                    {/* </Fragment> */}
                {/* ))} */}
            </div>
        </div>
    </div>
    );
};

export default FeedbackPrint;