import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ChargeFeeContainer from '../containers/ChargeFeeContainer';

const ChargeFeePage = () => {
    return (
        <>
            <HeaderContainer />
            <ChargeFeeContainer/>
        </>
    );
}

export default ChargeFeePage;