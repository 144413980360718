import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ShowTelDash } from '../../lib/dataUTIL';

const FeedbackBlock = styled.div`
    position: absolute;
    width: 98%;
    height: 100%;
    top:0;
    .popupModal > div:nth-child(2){
        width: 1300px !important;
        padding: 30px 40px;
        // overflow-x: auto;
        z-index: 999999;
    }
    input{
        width: 100%;
        border: none;
        text-align: center;
    }
  
    .feedInfo {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        max-height: 70vh;
        overflow-y: auto;
    }
    .info-box {
        color: #545454;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
        margin: 5px;
    }
    .feedInfo .info-box > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .feedInfo .info-box > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .info-box div:first-child {
        height: 24px;
        font-size: 15px;
        background-color: #ccc;
    }
    .info-box div:last-child {
        font-size: 14px;
        padding: 2px 0;
        display: flex;
    }
    .info-box input[type=text] {
        width: 100%;
    }
    .info-box input[type=date] {
        width: 145px;
        height: 27px;
    }
    .info-box span {
        font-size: 12px;
    }
    .feedInfo .info-box .rbtn {
        padding-top: 6px;
    }
    .feedInfo .info-box .remark {
        padding: 0;
    }
    .feedInfo .info-box .remark textarea {
        margin: 0;
        border: none;
        width: 100%;
        height: 100px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
    }
    .focusY div:first-child{
        background-color: yellow;
    }
    .focusO div:first-child{
        background-color: orange;
    }

    .popupModal.Check{
        background-color: rgba(161, 161, 161, 0.6);
        height: 100%;
        left: 0px;
        position: absolute;
        top: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }
    .popupModal.Check .popupDetatil{
        width:100%;
        padding: 30px 0px;
        background: rgb(255, 255, 255);
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 16%) 0px 8px 20px;
        height:76vh;
        margin-top:25px;
    }
    .popupModal.Check .popupDetatil h2{
        color: #545454;
        font-size: 26px;
        text-align: center;
        margin-bottom:20px;
    }
    .popupModal.Check .popupDetatil .feedInfo{
        max-height:65vh;
        position:relative;
        padding: 0 10px;
        grid-template-columns: repeat(4,1fr);
        overflow-x : hidden
    }

    .popupModal.Check .popupDetatil .divButtons{
        margin-top:-10px;
    }  
`;

const FeedDonTouch = styled.div`
    width: 1066px;
    position: fixed;
    top: 9px;
    z-index: 1000;
    height: 75px;
    margin-left:-847px;
`

const Feedback = (props) => {
    const {
        feedback_user,
        modalCallType,
        feedback_ans,

        onRowChange,
        onRowChangeCheck,
        onRowChangeNum,
        onSave,
        onClose,
    } = props;

    return (
        <FeedbackBlock>
        { 
          modalCallType === 'LIST' &&
        //   feedback_ans === 'P' &&
            <div className='popupModal'>
                <div />
                <div>
                    <h2 style={{marginBottom:'20px'}}>피드백</h2>
                    <div className='feedInfo'>
                        {feedback_user.map((item, idx) => (
                            <Fragment key={idx}>
                                <div style={{gridColumn:"1/5", paddingLeft:'5px'}}>권리분석담당자 : {item.create_user_name}</div>
                                {item.update_user_name && (<div style={{gridColumn:"6/9", paddingLeft:'5px'}}> 피드백 담당자 : {item.update_user_name}</div>)}
                                <div className='info-box' style={{gridColumn:"1/9"}}>
                                    <div>피드백 요청 Comment</div>
                                    <div>{item.to_lh}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>의뢰접수일</div>
                                    <div>{item.feedback_date}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/4"}}>
                                    <div>입주유형</div>
                                    <div>{item.winner_type_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>계약유형</div>
                                    <div>{item.contract_type_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/6"}}>
                                    <div>성명</div>
                                    <div>{item.cust_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>생년월일</div>
                                    <div>{item.cust_birth}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"7/8"}}>
                                    <div>현거주 지역구</div>
                                    <div>{item.sigungu}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"8/9"}}>
                                    <div>세대원</div>
                                    <div>{item.house_member || ''}</div>
                                </div>


                                <div className='info-box' style={{gridColumn:"1/5"}}>
                                    <div>이주시 신청주택 주소(도로명)</div>
                                    <div>{item.house_addr}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/9"}}>
                                    <div>현 주소</div>
                                    <div>{item.cust_addr}</div>
                                </div>

                                <div className="mt15" style={{gridColumn:"1/9"}}></div>
                                <div className='info-box focusY' style={{gridColumn:"1/2"}}>
                                    <div>계약가능여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"contract_yn" + idx} id={"contract_yn_Y" + idx} value={"Y"} checked={item.contract_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"contract_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"contract_yn" + idx} id={"contract_yn_N" + idx} value={"N"} checked={item.contract_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"contract_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"2/3"}}>
                                    <div>세대원 확인</div>
                                    <div><input type="text" name="house_member" id="house_member" value={item.house_member || ''} onChange={onRowChange.bind(this, item)} placeholder='세대원' /></div>
                                </div>
                                {/* New lh_add_charge*/}
                                {/* <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>입주자추가부담금</div>
                                    <div><input type="text" name="lh_add_charge" id="lh_add_charge" value={item.lh_add_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자추가부담금' /></div>
                                </div> */}
                                {/* New */}
                                {/* <div className='info-box' style={{gridColumn:"7/8"}}>
                                    <div>보증부월세</div>
                                    <div><input type="text" name="lh_guarantee_mon_amt" id="lh_guarantee_mon_amt" value={item.lh_guarantee_mon_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='보증부월세' /></div>
                                </div> */}
                                 <div className='info-box focusY' style={{gridColumn:"1/2"}}>
                                     <div>주택소유여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"house_owner_yn" + idx} id={"house_owner_yn_Y" + idx} value={"Y"} checked={item.house_owner_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"house_owner_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"house_owner_yn" + idx} id={"house_owner_yn_N" + idx} value={"N"} checked={item.house_owner_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"house_owner_yn_N" + idx}>아니오</label>
                                    </div>
                                    
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"2/3"}}>
                                     <div>타임대여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"other_rent_yn" + idx} id={"other_rent_yn_Y" + idx} value={"Y"} checked={item.other_rent_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"other_rent_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"other_rent_yn" + idx} id={"other_rent_yn_N" + idx} value={"N"} checked={item.other_rent_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"other_rent_yn_N" + idx}>아니오</label>
                                    </div>
                                    
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"3/4"}}>
                                    <div>기금대출여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"fund_loan_yn" + idx} id={"fund_loan_yn_Y" + idx} value={"Y"} checked={item.fund_loan_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"fund_loan_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"fund_loan_yn" + idx} id={"fund_loan_yn_N" + idx} value={"N"} checked={item.fund_loan_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"fund_loan_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"4/5"}}>
                                    <div>갱신유예유무</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"renewal_delay_yn" + idx} id={"renewal_delay_yn_Y" + idx} value={"Y"} checked={item.renewal_delay_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"renewal_delay_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"renewal_delay_yn" + idx} id={"renewal_delay_yn_N" + idx} value={"N"} checked={item.renewal_delay_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"renewal_delay_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"5/6"}}>
                                    <div>소송</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"lawsuit_yn" + idx} id={"lawsuit_yn_Y" + idx} value={"Y"} checked={item.lawsuit_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"lawsuit_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"lawsuit_yn" + idx} id={"lawsuit_yn_N" + idx} value={"N"} checked={item.lawsuit_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"lawsuit_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"6/7"}}>
                                    <div>LH미납이자</div>
                                    <div><input type="text" name="unpaid_amt" id="unpaid_amt_Y" value={item.unpaid_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='LH미납이자' /></div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"7/8"}}>
                                    <div>임대료할증</div>
                                    <div><input type="text" name="rent_surcharge" id="rent_surcharge" value={item.rent_surcharge || ''} onChange={onRowChange.bind(this, item)} placeholder='임대료할증' /></div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"8/9"}}>
                                    <div>도배장판지원가능여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"wallpaper_yn" + idx} id={"wallpaper_yn_Y" + idx} value={"Y"} checked={item.wallpaper_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"wallpaper_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"wallpaper_yn" + idx} id={"wallpaper_yn_N" + idx} value={"N"} checked={item.wallpaper_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"wallpaper_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                
                                {/* New */}
                                <div className="mt15" style={{gridColumn:"1/9"}}></div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>현재지원차수</div>
                                    <div><input type="text" name="req_cnt" id="req_cnt" value={item.req_cnt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='지원회차' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>현재계약종료일</div>
                                    <div><input type="date" max="9999-12-31" name="current_cont_end_date" id="current_cont_end_date" value={item.current_cont_end_date} onChange={onRowChange.bind(this, item)} /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>Wise상 임대인</div>
                                    <div><input type="text" name="owner_name" id="owner_name" value={item.owner_name || ''} onChange={onRowChange.bind(this, item)} placeholder='Wise상 임대인' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>임대인 연락처</div>
                                    <div><input type="text" name="owner_tel" id="owner_tel" value={ShowTelDash(item.owner_tel) || ''} onChange={onRowChange.bind(this, item)} placeholder='임대인 연락처' /></div>
                                </div>
                                {/* New */}
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>계약 / 전용면적</div>
                                    <div>{`${item.contract_area || '-'} / ${item.dedicated_area || '-'}`}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>총금액</div>
                                    <div><input type="text" name="rent_amt" id="rent_amt" value={item.rent_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='총금액' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>LH지원금</div>
                                    <div><input type="text" name="support_amt" id="support_amt" value={item.support_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='LH지원금' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>입주자 부담금</div>
                                    <div><input type="text" name="winner_charge" id="winner_charge" value={item.winner_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자 부담금' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"5/6"}}>
                                    <div>입주자 추가부담금</div>
                                    <div><input type="text" name="add_charge" id="add_charge" value={item.add_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주차 추가부담금' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>보증부월세액</div>
                                    <div><input type="text" name="guarantee_mon_amt" id="guarantee_mon_amt" value={item.guarantee_mon_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='보증부월세' /></div>
                                </div>
                                
                                <div className="mt15" style={{gridColumn:"1/9"}}></div>
                                <div style={{gridColumn:"1/9"}}>
                                    <div className='ml5' style={{color:"#545454", fontSize:"16px"}}>보증금 반환 계좌</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>은행명</div>
                                    <div><input type="text" name="bank_name" id="bank_name" value={item.bank_name || ''} onChange={onRowChange.bind(this, item)} placeholder='은행명' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/4"}}>
                                    <div>가상계좌</div>
                                    <div><input type="text" name="account_no" id="account_no" value={item.account_no || ''} onChange={onRowChange.bind(this, item)} placeholder='계좌번호' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>예금주</div>
                                    <div><input type="text" name="account_owner" id="account_owner" value={item.account_owner || ''} onChange={onRowChange.bind(this, item)} placeholder='예금주' /></div>
                                </div>
                                
                                <div className="mt15" style={{gridColumn:"1/9"}}></div>
                                <div style={{gridColumn:"1/5"}}>
                                    <div className='ml5' style={{color:"#545454", fontSize:"16px"}}>중복 내역</div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"1/2"}}>
                                    <div>입주주택중복여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"duplication_yn" + idx} id={"duplication_yn_Y" + idx} value={"Y"} checked={item.duplication_yn === "Y"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"duplication_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"duplication_yn" + idx} id={"duplication_yn_N" + idx} value={"N"} checked={item.duplication_yn === "N"} onChange={onRowChangeCheck.bind(this, item)} />
                                        <label htmlFor={"duplication_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"2/3"}}>
                                    <div>입주유형</div>
                                    <div><input type="text" name="dup_cust_type" id="dup_cust_type" value={item.dup_cust_type || ''} onChange={onRowChange.bind(this, item)} placeholder='입주유형' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"3/4"}}>
                                    <div>입주자</div>
                                    <div><input type="text" name="dup_cust_name" id="dup_cust_name" value={item.dup_cust_name || ''} onChange={onRowChange.bind(this, item)} placeholder='입주자' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"4/5"}}>
                                    <div>전화번호</div>
                                    <div><input type="text" name="dup_cust_phone" id="dup_cust_phone" value={item.dup_cust_phone || ''} onChange={onRowChange.bind(this, item)} placeholder='전화번호' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"5/6"}}>
                                    <div>총금액</div>
                                    <div><input type="text" name="lh_rent_amt" id="lh_rent_amt" value={item.lh_rent_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='총금액' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"6/7"}}>
                                    <div>LH지원금</div>
                                    <div><input type="text" name="lh_support_amt" id="lh_support_amt" value={item.lh_support_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='LH지원금' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"7/8"}}>
                                    <div>입주자부담금</div>
                                    <div><input type="text" name="lh_win_charge" id="lh_win_charge" value={item.lh_win_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자부담금' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"8/9"}}>
                                    <div>계약만료일</div>
                                    <div><input type="date" name="cont_end_date" id="cont_end_date" value={item.cont_end_date} onChange={onRowChange.bind(this, item)} max="9999-12-31"/></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/9"}}>
                                    <div>특이사항 입력</div>
                                    <div className='remark'><textarea name="remark" id="remark" value={item.remark} onChange={onRowChange.bind(this, item)} placeholder='피드백 내용을 입력해주세요.' /></div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                    <br/>
                    <div className='divButtons'>
                        {
                            modalCallType === 'LIST' &&
                            feedback_ans === 'P' &&
                            <>
                                <button onClick={() => onSave('temp_save')} className="divB-Save">저장</button>
                                <button onClick={() => onSave('answer')} className="divB-Save">응답</button>
                            </>
                        }
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                    </div>
                </div>
            </div>
        }
        <FeedDonTouch/>
        {
            modalCallType !== 'LIST' &&
            // feedback_ans !== 'P' &&
            <div className='popupModal Check'>
                <div className='popupDetatil'>
                    <h2 style={{marginBottom:'20px'}}>피드백</h2>
                    <div className='feedInfo'>
                        {feedback_user.map((item, idx) => (
                            <Fragment key={idx}>
                                <div style={{gridColumn:"1/3", paddingLeft:'5px'}}> 권리분석담당자 : {item.create_user_name}</div>
                                {item.update_user_name && (<div style={{gridColumn:"3/5", paddingLeft:'5px'}}> 피드백 담당자 : {item.update_user_name}</div>)}
                                <div className='info-box' style={{gridColumn:"1/5"}}>
                                    <div>피드백 요청 Comment</div>
                                    <div>{item.to_lh}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>의뢰접수일</div>
                                    <div>{item.feedback_date}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>입주유형</div>
                                    <div>{item.winner_type_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>계약유형</div>
                                    <div>{item.contract_type_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>성명</div>
                                    <div>{item.cust_name}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>생년월일</div>
                                    <div>{item.cust_birth}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>현거주 지역구</div>
                                    <div>{item.sigungu}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>세대원</div>
                                    <div>{item.house_member || ''}</div>
                                </div>


                                <div className='info-box' style={{gridColumn:"1/3"}}>
                                    <div>이주시 신청주택 주소(도로명)</div>
                                    <div>{item.house_addr}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/5"}}>
                                    <div>현 주소</div>
                                    <div>{item.cust_addr}</div>
                                </div>

                                <div className="mt15" style={{gridColumn:"1/5"}}></div>
                                <div className='info-box focusY' style={{gridColumn:"1/2"}}>
                                    <div>계약가능여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"contract_yn" + idx} id={"contract_yn_Y" + idx} value={"Y"} checked={item.contract_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"contract_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"contract_yn" + idx} id={"contract_yn_N" + idx} value={"N"} checked={item.contract_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"contract_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"2/3"}}>
                                    <div>세대원 확인</div>
                                    <div><input type="text" name="house_member" id="house_member" value={item.house_member || ''} onChange={() => {}} placeholder='세대원' /></div>
                                </div>
                                {/* New lh_add_charge*/}
                                {/* <div className='info-box' style={{gridColumn:"6/7"}}>
                                    <div>입주자추가부담금</div>
                                    <div><input type="text" name="lh_add_charge" id="lh_add_charge" value={item.lh_add_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자추가부담금' /></div>
                                </div> */}
                                {/* New */}
                                {/* <div className='info-box' style={{gridColumn:"7/8"}}>
                                    <div>보증부월세</div>
                                    <div><input type="text" name="lh_guarantee_mon_amt" id="lh_guarantee_mon_amt" value={item.lh_guarantee_mon_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='보증부월세' /></div>
                                </div> */}
                                <div className='info-box focusY' style={{gridColumn:"1/2"}}>
                                    <div>주택소유여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"house_owner_yn" + idx} id={"house_owner_yn_Y" + idx} value={"Y"} checked={item.house_owner_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"house_owner_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"house_owner_yn" + idx} id={"house_owner_yn_N" + idx} value={"N"} checked={item.house_owner_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"house_owner_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"2/3"}}>
                                    <div>타임대여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"other_rent_yn" + idx} id={"other_rent_yn_Y" + idx} value={"Y"} checked={item.other_rent_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"other_rent_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"other_rent_yn" + idx} id={"other_rent_yn_N" + idx} value={"N"} checked={item.other_rent_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"other_rent_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"3/4"}}>
                                    <div>기금대출여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"fund_loan_yn" + idx} id={"fund_loan_yn_Y" + idx} value={"Y"} checked={item.fund_loan_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"fund_loan_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"fund_loan_yn" + idx} id={"fund_loan_yn_N" + idx} value={"N"} checked={item.fund_loan_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"fund_loan_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"4/5"}}>
                                    <div>갱신유예유무</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"renewal_delay_yn" + idx} id={"renewal_delay_yn_Y" + idx} value={"Y"} checked={item.renewal_delay_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"renewal_delay_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"renewal_delay_yn" + idx} id={"renewal_delay_yn_N" + idx} value={"N"} checked={item.renewal_delay_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"renewal_delay_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"1/2"}}>
                                    <div>소송</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"lawsuit_yn" + idx} id={"lawsuit_yn_Y" + idx} value={"Y"} checked={item.lawsuit_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"lawsuit_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"lawsuit_yn" + idx} id={"lawsuit_yn_N" + idx} value={"N"} checked={item.lawsuit_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"lawsuit_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"2/3"}}>
                                    <div>LH미납이자</div>
                                    <div><input type="text" name="unpaid_amt" id="unpaid_amt_Y" value={item.unpaid_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='LH미납이자' /></div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"3/4"}}>
                                    <div>임대료할증</div>
                                    <div><input type="text" name="rent_surcharge" id="rent_surcharge" value={item.rent_surcharge || ''} onChange={() => {}} placeholder='임대료할증' /></div>
                                </div>
                                <div className='info-box focusY' style={{gridColumn:"4/5"}}>
                                    <div>도배장판지원가능여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"wallpaper_yn" + idx} id={"wallpaper_yn_Y" + idx} value={"Y"} checked={item.wallpaper_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"wallpaper_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"wallpaper_yn" + idx} id={"wallpaper_yn_N" + idx} value={"N"} checked={item.wallpaper_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"wallpaper_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                
                                {/* New */}
                                <div className="mt15" style={{gridColumn:"1/5"}}></div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>현재지원차수</div>
                                    <div><input type="text" name="req_cnt" id="req_cnt" value={item.req_cnt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='지원회차' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>현재계약종료일</div>
                                    <div><input type="date" max="9999-12-31" name="current_cont_end_date" id="current_cont_end_date" value={item.current_cont_end_date} onChange={() => {}} /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>Wise상 임대인</div>
                                    <div><input type="text" name="owner_name" id="owner_name" value={item.owner_name || ''} onChange={() => {}} placeholder='Wise상 임대인' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>임대인 연락처</div>
                                    <div><input type="text" name="owner_tel" id="owner_tel" value={ShowTelDash(item.owner_tel) || ''} onChange={() => {}} placeholder='임대인 연락처' /></div>
                                </div>
                                {/* New */}
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>계약 / 전용면적</div>
                                    <div>{`${item.contract_area || '-'} / ${item.dedicated_area || '-'}`}</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>총금액</div>
                                    <div><input type="text" name="rent_amt" id="rent_amt" value={item.rent_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='총금액' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/4"}}>
                                    <div>LH지원금</div>
                                    <div><input type="text" name="support_amt" id="support_amt" value={item.support_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='LH지원금' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>입주자 부담금</div>
                                    <div><input type="text" name="winner_charge" id="winner_charge" value={item.winner_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자 부담금' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/3"}}>
                                    <div>입주자 추가부담금</div>
                                    <div><input type="text" name="add_charge" id="add_charge" value={item.add_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주차 추가부담금' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"3/5"}}>
                                    <div>보증부월세액</div>
                                    <div><input type="text" name="guarantee_mon_amt" id="guarantee_mon_amt" value={item.guarantee_mon_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='보증부월세' /></div>
                                </div>
                                
                                <div className="mt15" style={{gridColumn:"1/5"}}></div>
                                <div style={{gridColumn:"1/8"}}>
                                    <div className='ml5' style={{color:"#545454", fontSize:"16px"}}>보증금 반환 계좌</div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/2"}}>
                                    <div>은행명</div>
                                    <div><input type="text" name="bank_name" id="bank_name" value={item.bank_name || ''} onChange={() => {}} placeholder='은행명' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"2/4"}}>
                                    <div>가상계좌</div>
                                    <div><input type="text" name="account_no" id="account_no" value={item.account_no || ''} onChange={() => {}} placeholder='계좌번호' /></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"4/5"}}>
                                    <div>예금주</div>
                                    <div><input type="text" name="account_owner" id="account_owner" value={item.account_owner || ''} onChange={() => {}} placeholder='예금주' /></div>
                                </div>
                                
                                <div className="mt15" style={{gridColumn:"1/5"}}></div>
                                <div style={{gridColumn:"1/5"}}>
                                    <div className='ml5' style={{color:"#545454", fontSize:"16px"}}>중복 내역</div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"1/2"}}>
                                    <div>입주주택중복여부</div>
                                    <div className='rbtn'>
                                        <input type="radio" name={"duplication_yn" + idx} id={"duplication_yn_Y" + idx} value={"Y"} checked={item.duplication_yn === "Y"} onChange={() => {}} />
                                        <label htmlFor={"duplication_yn_Y" + idx}>예</label>
                                        <input type="radio" name={"duplication_yn" + idx} id={"duplication_yn_N" + idx} value={"N"} checked={item.duplication_yn === "N"} onChange={() => {}} />
                                        <label htmlFor={"duplication_yn_N" + idx}>아니오</label>
                                    </div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"2/3"}}>
                                    <div>입주유형</div>
                                    <div><input type="text" name="dup_cust_type" id="dup_cust_type" value={item.dup_cust_type || ''} onChange={() => {}} placeholder='입주유형' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"3/4"}}>
                                    <div>입주자</div>
                                    <div><input type="text" name="dup_cust_name" id="dup_cust_name" value={item.dup_cust_name || ''} onChange={() => {}} placeholder='입주자' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"4/5"}}>
                                    <div>전화번호</div>
                                    <div><input type="text" name="dup_cust_phone" id="dup_cust_phone" value={item.dup_cust_phone || ''} onChange={() => {}} placeholder='전화번호' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"1/2"}}>
                                    <div>총금액</div>
                                    <div><input type="text" name="lh_rent_amt" id="lh_rent_amt" value={item.lh_rent_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='총금액' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"2/3"}}>
                                    <div>LH지원금</div>
                                    <div><input type="text" name="lh_support_amt" id="lh_support_amt" value={item.lh_support_amt || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='LH지원금' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"3/4"}}>
                                    <div>입주자부담금</div>
                                    <div><input type="text" name="lh_win_charge" id="lh_win_charge" value={item.lh_win_charge || ''} onChange={onRowChangeNum.bind(this, item)} placeholder='입주자부담금' /></div>
                                </div>
                                <div className='info-box focusO' style={{gridColumn:"4/5"}}>
                                    <div>계약만료일</div>
                                    <div><input type="date" name="cont_end_date" id="cont_end_date" value={item.cont_end_date} onChange={() => {}} max="9999-12-31"/></div>
                                </div>
                                <div className='info-box' style={{gridColumn:"1/5"}}>
                                    <div>특이사항 입력</div>
                                    <div className='remark'><textarea name="remark" id="remark" value={item.remark} onChange={() => {}} placeholder='피드백 내용을 입력해주세요.' /></div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                    <br/>
                    <div className='divButtons'>
                        {
                            modalCallType === 'LIST' &&
                            feedback_ans === 'P' &&
                            <>
                                <button onClick={() => onSave('temp_save')} className="divB-Save">저장</button>
                                <button onClick={() => onSave('answer')} className="divB-Save">응답</button>
                            </>
                        }
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                    </div>
                </div>
            </div>
        }
        </FeedbackBlock>
    );
}

export default Feedback;