import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OptionMgt from '../components/OptionMgt';
import { changeValue, getDecide, saveDecide } from '../modules/optionMgt';

const OptionMgtContainer = () => {
    const dispatch = useDispatch();
    const {
        decide_date,
    } = useSelector(({optionMgt}) => ({
        decide_date: optionMgt.decide_date,
    }))

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onDecideDateSave = () => {
        dispatch(saveDecide({decide_date: decide_date}));
    }

    useEffect(() => {
        dispatch(getDecide({main_code: 'DECIDE_DATE',}))
    }, [dispatch]);

    const propDatas = {
        decide_date,
        onDecideDateSave,

        onChange,
    }
    return (
        <>
            <OptionMgt {...propDatas} />
        </>
    );
}

export default OptionMgtContainer;