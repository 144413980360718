import client from './client';

export const nextwork_write = (body) =>
    client.post('/api/work/nextwork_write', body);

export const nextwork_search = (body) =>
    client.post('/api/work/nextwork_search', body);

export const nextwork_view = (body) =>
    client.post('/api/work/nextwork_view', body);

export const change_search = (body) =>
    client.post('/api/work/change_search', body);

export const change_view = (change_id) => 
    client.get(`/api/work/change_view/` + change_id);

export const change_memo = (body) => 
    client.post('/api/work/change_memo', body);