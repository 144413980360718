import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import EnWriteAdminContainer from '../containers/EnWriteAdminContainer';

const EnWriteAdminPage = () => {
    return (
        <>
            <HeaderContainer />
            <EnWriteAdminContainer/>
        </>
    );
}

export default EnWriteAdminPage;