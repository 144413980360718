import React from 'react';
import styled from 'styled-components';

const EnDocFailBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 500px;
        box-sizing: border-box;
        padding: 50px;
    }
    .popupModal > div:nth-child(2) h4{
        font-size: 26px;
        color: #545454;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
    }
    textarea {
        width: 100%;
        height: 150px;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #BDBDBD;
        border-radius: 10px;
        box-sizing: border-box;
        resize: none;
    }
    span {
        float: right;
    }

`;

const EnDocFail = (props) => {
    const {
        doc_status,
        user,
        entitle_user,
        lack_reason,

        isShowDocFail,

        onChange,
        onSave,
        onClose,
    } = props;

    return (
        <EnDocFailBlock>
            <div className='popupModal'>
                <div />
                <div>
                    <h4>
                        {isShowDocFail === "ENDOCFAIL" && "서류 미비 사유"}
                        {isShowDocFail === "REJECT" && "결재 반려"}
                        {isShowDocFail === "APPREJECT" && "결재확인 반려"}
                        {isShowDocFail === "REJECT_CHECK" && "결재 반려 사유"}
                        {isShowDocFail === "NONCONF" && "부적합 만료 사유"}
                        {isShowDocFail === "ENCHGREQ" && "담당자 변경 사유"}
                        {isShowDocFail === "FEEDBACK" && "피드백 요청 내용"}
                    </h4>
                    <div>
                        <textarea name="lack_reason" id="lack_reason" value={lack_reason} onChange={onChange} readOnly={isShowDocFail === "REJECT_CHECK"} placeholder='사유를 입력해주세요.'></textarea>
                    </div>
                    <div className='divButtons'>
                        {
                            //  서류미비, 결재반려, 결재확인반려, 부적합만료 시에는 항상 [확인] 버튼 노출.
                            //  결재반려사유 확인 시에는 문서 상태가 ENAPVFAIL 또는 ENAPVCHKFAIL이면서, 로그인 유저가 권리분석 담당자일 때 노출.
                            (
                                (isShowDocFail === "ENDOCFAIL" || isShowDocFail === "REJECT" || isShowDocFail === "APPREJECT" || isShowDocFail === "NONCONF" || isShowDocFail === "ENCHGREQ" || isShowDocFail === "FEEDBACK") ||
                                (isShowDocFail === "REJECT_CHECK" && entitle_user === user.user_id && (doc_status === "ENAPVFAIL" || doc_status === "ENAPVCHKFAIL"))
                            ) &&
                            <button onClick={onSave} className="divB-Save">확인</button>
                        }
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                    </div>
                </div>
            </div>
        </EnDocFailBlock>
    );
}

export default EnDocFail;