import React from 'react';
import styled from 'styled-components';

const EnCallerMemoBlock = styled.div`
    .popupModal > div:nth-child(2){
        box-sizing: border-box;
        padding: 40px;
    }
    textarea {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #BDBDBD;
        border-radius: 10px;
        box-sizing: border-box;
        resize: none;
        margin: 20px 0;
    }

`;

const EnCallerMemo = (props) => {
    const {
        user,
        remark,
        call_memo,
        call_check,
        entitle_user,

        onChange,
        onSave,
        onMemoCheck,
        onClose,
    } = props;

    return (
        <EnCallerMemoBlock>
           <div className='popupModal'>
               <div />
                <div>
                    <h2>콜센터 기재내용</h2>
                    <div>
                        <textarea name="remark" id="remark" value={remark} onChange={onChange} readOnly={(user.user_auth === "CALL" || !call_memo) ? false : true} placeholder='내용이 없습니다.'></textarea>
                    </div>
                    <div className='divButtons'>
                        {user.user_auth === "CALL" && <button onClick={onSave} className="divB-Save">저장</button>}
                        {
                            (user.user_id === entitle_user && call_check === "Y") &&
                            <button onClick={onMemoCheck} className="save-btn">메모 확인</button>
                        }
                        <button onClick={onClose} className="divB-Cancel">취소</button>
                    </div>
                </div>
           </div>
        </EnCallerMemoBlock>
    );
}

export default EnCallerMemo;