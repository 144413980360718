import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FindUser from '../../components/Modal/FindUser';
import { ALERT } from '../../lib/dataWord';
import { changeValue, getUsers, initialize } from '../../modules/Modal/findUser';

const FindUserContainer = (props) => {
    const {
        onCloseFindUser,
        onSelectUser,
    } = props;

    const dispatch = useDispatch();
    const {
        userName,
        userNumber,
        users,
        isManualAdd,
    } = useSelector(({ findUser }) => ({
        userName: findUser.userName,
        userNumber: findUser.userNumber,
        users: findUser.users,
        isManualAdd: findUser.isManualAdd,
    }));

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onKeyDown = (e) => {
        if(e.key === "Enter") {
            onSearch();
        }
    }
    const onSearch = () => {
        if(!userName) {
            ALERT("대상자 성명을 입력해주세요.");
            return;
        }
        if(!userNumber || userNumber.length !== 6) {
            ALERT("대상자의 생년월일을 입력해주세요.");
            return;
        }

        dispatch(
            getUsers({
                name: userName,
                winner_no: userNumber,
            })
        )
    }
    const onSelected = (item, e) => {
        onSelectUser(item);
    }
    useEffect(() => {
        //  수동입력시
        if(isManualAdd) {
            onSelectUser({
                type: '',
                type_name: '',
                winner_id: '',
                name: userName,
                winner_no: userNumber,
                addr: '',
                phone: '',
                childrun_cnt: '',
                type_calc_code: '',
                is_duplicate: '',
                info_yn: '',
                writing_doc_id: '',
            });
            dispatch(
                changeValue({
                    key: 'isManualAdd',
                    value: false,
                })
            )
        }
    }, [dispatch, isManualAdd, onSelectUser, userName, userNumber])

    const propDatas = {
        userName,
        userNumber,
        users,
        onCloseFindUser,

        onChange,
        onKeyDown,
        onSearch,
        onSelected,
    }
    return (
        <>
            <FindUser {...propDatas}/>
        </>
    );
}

export default FindUserContainer;