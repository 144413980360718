import React, { Fragment } from 'react';
import styled from 'styled-components';
import loader from '../loading.gif'

const WinnerExcelWorkBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 20px 50px 50px 50px; 
    box-sizing: border-box;
    position: relative;

    .divFlex-btn{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .tableCover {
        height: 80vh;
        position: relative;
    }

    .loaderCover {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    // input#file-upload-button{
    //     background-color:#fff;color:#0B999C;border: 1px solid #0B999C;
    // }
    .loaderLabel {
        cursor: pointer;
    }

    .loaderCover img{
        width: 64px;
        height: 64px;
    }

    table {
        white-space: nowrap;
    }
    table td{
        max-width: 100%;
    }
    table td input {
        padding: 5px;
    }

    table .wide {
        width: 460px;
    }

    .value_error {
        border: 2px solid red;
        color: red;
        font-weight: 600;
    }

    .excelDesc {
        margin: 10px 0;
    }
    .excelDesc summary {
        cursor: pointer
    }
    .excelDesc p {
        font-size: 14px;
    }

    .errRow {
        background-color: wheat !important;
    }
`;

const WinnerExcelWork = (props) => {
    const {
        winnerExcel,
        winnerExcelHeader,
        winnerExcelHeader_true,
        fileState,
        winnerExcelNodata,
        cboApply,
        fileValue,

        onChange,
        onChangeValue,
        onUpload,
    } = props;

    return (
        <WinnerExcelWorkBlock>
            <div className='divFlex-btn'>
                <label htmlFor="results" className="btn2"><span>파일 업로드</span></label>
                {/* <div class="upload-name"><span class="txt">imgsample.jpg</span><button type="button" class="btn-del-xs ui-file-delete" title="삭제"><i class="ico-x-white"></i><span class="blind">삭제</span></button></div> */}
                <input className='xlUploadIpt' name='results' id='results' type='file' onChange={onChange} value={fileValue}/>
                <div>
                    <button onClick={onUpload} className="btn1">데이터 업로드</button>
                </div>
            </div>
            {/* 주의사항 */}
            <div className='excelDesc'>
                <details>
                    <summary>
                        엑셀 업로드시 주의사항
                    </summary>
                    <p>* '실제 신청유형'은 엑셀 데이터 신청유형(공급유형), 신청순위(자격요건)으로 찾은 데이터상의 신청유형 값입니다.</p>
                    <p>*  등록된 데이터상에서 '실제 신청유형'을 찾지 못한경우 직접 설정이 필요합니다.</p>
                    <p>*  날짜 형식을 yyyy-mm-dd 로 맞추지 않으면 업로드 할 수 없습니다.(중간 문자종류(. / -)는 상관없음)</p>
                    <p>*  '지원 가능 기간'은 전체 값 중에서 뒤 10자리만 처리됩니다.</p>
                </details>
            </div>
            <div className='tableCover'>
                {fileState === 1?
                <div className='loaderCover'>
                    <img src={loader} alt='loader'/>
                    <p>엑셀 용량에 따라 업로드 완료까지 시간이 걸릴 수 있습니다.</p>
                </div>
                : null}
                {fileState === 0 && winnerExcelHeader.length === 0 && winnerExcel.length === 0?
                    <label htmlFor='results' className='loaderCover loaderLabel'>파일을 업로드 하세요</label>
                :null}
                <table className='tt'>
                    <thead>
                        <tr>
                            {winnerExcelHeader.length > 0? 
                            <>
                                <th>번호</th>
                                {winnerExcelHeader.map((item, idx) => {
                                    return (
                                        <Fragment key={`${idx}`}>  
                                            {idx === 5? <th>실제 신청유형</th> : null}
                                            <th key={idx}>{item}</th>
                                        </Fragment>
                                    )
                                })}
                            </>
                            : null}
                        </tr>
                    </thead>
                    <tbody>
                        {winnerExcel.length > 0?
                        <>
                            {winnerExcel.map((item, idx) => {
                                return  (
                                    <tr key={idx} className='tr-valid'>
                                        <td>{idx+1}</td>
                                        {winnerExcelHeader_true.map((hitem, idx2) => {
                                            return (
                                                <Fragment key={`${idx}${idx2}`}>
                                                    {/* 실제 유형으로 데이터상의 유형을 찾는 부분 */}
                                                    {idx2 === 5? 
                                                        <td>
                                                            <select name='type' value={item.type} onChange={(e) => onChangeValue(e, idx)} className={item.type === ''? 'value_error' : ''}>
                                                                <option value={''}>유형을 찾지 못했습니다.</option>
                                                                {cboApply.map((item, idx) => {
                                                                    return (
                                                                        <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </td>
                                                    : null}
                                                    <>
                                                    {/* 주소만 입력칸 길게 */}
                                                    {idx2 === 2?
                                                    <td key={idx2}>
                                                        <input placeholder='값이 없습니다' name={hitem} className={item[hitem] === ''? 'wide value_error' : 'wide'} value={item[hitem]} onChange={(e) => onChangeValue(e, idx)}/>
                                                    </td>
                                                    :
                                                    // 기금, 주택, 타임대 -> select
                                                    idx2 === 12 || idx2 === 13 || idx2 === 14?
                                                    <td>
                                                        <select value={item[hitem]} name={hitem} onChange={(e) => onChangeValue(e, idx)} className={item[hitem] === '' || (winnerExcelNodata[`${idx},${idx2}`] === 'value_error')? 'value_error' : ''}>
                                                            <option value={''}>값을 선택해주세요</option>
                                                            <option value={'Y'}>Y</option>
                                                            <option value={'N'}>N</option>
                                                        </select>
                                                    </td>
                                                    :
                                                    // 나머지 일반 text input
                                                    <td key={idx2}>
                                                        <input placeholder='값이 없습니다' name={hitem} 
                                                            className={
                                                                // 입력값이 있는지
                                                                ((
                                                                    idx2 === 0 ||      // 성명
                                                                    idx2 === 1 ||      // 생년월일
                                                                    idx2 === 4 ||      // 휴대전화번호
                                                                    // idx2 === 9 ||      // 접수일자(신청일)
                                                                    idx2 === 2 ||      // 주소
                                                                    // idx2 === 3 ||      // 상세주소
                                                                    idx2 === 5 ||      // 신청유형(공급유형)
                                                                    // idx2 === 7 ||      // 세부자격요건
                                                                    // idx2 === 8 ||      // 수급자자격
                                                                    idx2 === 10 ||     // 최종결과
                                                                    idx2 === 11||      // 지원가능기간
                                                                    idx2 === 12 ||     // 기금
                                                                    idx2 === 13 ||     // 주택
                                                                    idx2 === 14 ||     // 타임대
                                                                    idx2 === 18        // 세대원
                                                                )  && item[hitem] === '')
                                                                || 
                                                                // 입력값이 형식에 맞는지
                                                                (winnerExcelNodata[`${idx},${idx2}`] === 'value_error')
                                                                ? 'value_error' : ''} value={item[hitem]} onChange={(e) => onChangeValue(e, idx)
                                                            }
                                                        />
                                                    </td>
                                                    }
                                                    </>
                                                </Fragment>
                                            ) 
                                        })}
                                    </tr>
                                )
                            })}
                        </>
                        :null}
                    </tbody>
                </table>
            </div>
        </WinnerExcelWorkBlock>
    );
}

export default WinnerExcelWork;