import React from 'react';
import AuditRequestContainer from '../containers/AuditRequestContainer';
import HeaderContainer from '../containers/HeaderContainer';

// AuditRequestListPage 각 리스트의 상세 페이지
const AuditRequestPage = () => {
    return (
        <>
            <HeaderContainer />
            <AuditRequestContainer />
        </>
    );
}

export default AuditRequestPage;