import React from 'react';

const CopyRegisterView = ({_doc014}) => {
    if(!_doc014) {
        return (
            <>
                API 정상동작 되지 않음 ({_doc014})
            </>
        )
    }else{
        return (
            // doc014.map((_doc014, idx) => (
                <div className='viewerContainer'>
                <h4>등기부등본</h4>
                {/* {
                    (_doc014 && _doc014.JPG_URNS) &&
                    (
                        _doc014.JPG_URNS.map((item, jpgIdx) => (
                            <tr key={jpgIdx}>
                                <td colSpan={9}>
                                    <img src={item} className="viewerImg" alt=''/>
                                </td>
                            </tr>
                        ))
                    )
                } */}
    
                {/* 등기부등본 변경 전 */}
                {/* <table className="apiTable"> */}
                    {/* <tbody>
                        <tr>
                            <th>문서세부사항</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].DOC_DETAILS}</td>
                        </tr>
                        <tr>
                            <th>출력물 파일명</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].OUTPUT_FILENAME}</td>
                        </tr>
                        <tr>
                            <th>명의인 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].HOLDER_COUNT}</td>
                        </tr>
                        <tr>
                            <th>집합건물 표제부 1 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].COLLATERAL1_COUNT}</td>
                        </tr>
                        <tr>
                            <th>집합건물 표제부 4 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].COLLATERAL4_COUNT}</td>
                        </tr>
                        <tr>
                            <th>갑구 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].KAP_COUNT}</td>
                        </tr>
                        <tr>
                            <th>매매목록 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].SALE_COUNT}</td>
                        </tr>
    
                        {   //LAND_DATA
                            (_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].LAND_DATA.length > 0)
                            &&
                            <tr>
                                <th rowSpan={_doc014.RESP_DATA[0].LAND_DATA.length + 1}>토지 표제부 반복부</th>
                                <th>문서구분</th>
                                <th>면적</th>
                                <th>표시번호</th>
                                <th>소재지번</th>
                                <th>지목</th>
                                <th>등기원인 및 기타사항</th>
                                <th>접수</th>
                                <th>행 구분</th>
                            </tr>
                        }
                        {
                            (_doc014 && _doc014.RESP_DATA) &&
                            (
                                _doc014.RESP_DATA[0].LAND_DATA.map((item, landIdx) => (
                                <tr key={landIdx}>
                                    <td>{item.LAND_DOC_TYPE}</td>
                                    <td>{item.LAND_AREA}</td>
                                    <td>{item.LAND_NO}</td>
                                    <td>{item.LAND_ADDRESS}</td>
                                    <td>{item.LAND_CATEGORY}</td>
                                    <td>{item.LAND_ETC}</td>
                                    <td>{item.LAND_ACCEPT}</td>
                                    <td>{item.LAND_SEPARATOR}</td>
                                </tr>
                            )
                        ))}
    
                        <tr>
                            <th>문서종류</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].DOC_TYPE}</td>
                        </tr>
                        <tr>
                            <th>열람시간</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].ISSUE_TIME}</td>
                        </tr>
                        <tr>
                            <th>건물 표제부 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].BUILD_COUNT}</td>
                        </tr>
                        <tr>
                            <th>토지 표제부 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].LAND_COUNT}</td>
                        </tr>
                        <tr>
                            <th>공동담보목록 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].COLLATERAL_COUNT}</td>
                        </tr>
                        <tr>
                            <th>관할등기소</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].REGISTER_OFFICE}</td>
                        </tr>
                        <tr>
                            <th>주소</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].ADDRESS}</td>
                        </tr>
                        <tr>
                            <th>열람일자</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].ISSUE_DATE}</td>
                        </tr>
                        <tr>
                            <th>고유번호</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].REAL_ESTATE_NO}</td>
                        </tr>
                        <tr>
                            <th>집합건물 표제부 3 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].COLLATERAL3_COUNT}</td>
                        </tr>
                        <tr>
                            <th>을구 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].EUL_COUNT}</td>
                        </tr>
    
                        {   //KAP_DATA
                            (_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].KAP_DATA.length > 0)
                            &&
                            <tr>
                                <th rowSpan={_doc014.RESP_DATA[0].KAP_DATA.length + 1}>갑구 반복건수</th>
                                <th>접수</th>
                                <th>행 구분</th>
                                <th>문서구분</th>
                                <th>등기목적</th>
                                <th colSpan={2}>권리자 및 기타사항</th>
                                <th>등기원인</th>
                                <th>순위번호</th>
                            </tr>
                        }
                        {
                            (_doc014 && _doc014.RESP_DATA) &&
                            (
                                _doc014.RESP_DATA[0].KAP_DATA.map((item, kapIdx) => (
                                <tr key={kapIdx}>
                                    <td>{item.KAP_ACCEPT}</td>
                                    <td>{item.KAP_SEPARATOR}</td>
                                    <td>{item.KAP_DOC_TYPE}</td>
                                    <td>{item.KAP_PURPOSE}</td>
                                    <td colSpan={2}>{item.KAP_ETC}</td>
                                    <td>{item.KAP_REASON}</td>
                                    <td>{item.KAP_NO}</td>
                                </tr>
                            )
                        ))}
    
                        {   //EUL_DATA
                            (_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].EUL_DATA.length > 0)
                            &&
                            <tr>
                                <th rowSpan={_doc014.RESP_DATA[0].EUL_DATA.length + 1}>을구 반복부</th>
                                <th>등기목적</th>
                                <th>행 구분</th>
                                <th>순위번호</th>
                                <th colSpan={2}>권리자 및 기타사항</th>
                                <th>등기원인</th>
                                <th>문서구분</th>
                                <th>접수</th>
                            </tr>
                        }
                        {
                            (_doc014 && _doc014.RESP_DATA) &&
                            (
                                _doc014.RESP_DATA[0].EUL_DATA.map((item, eulIdx) => (
                                <tr key={eulIdx}>
                                    <td>{item.EUL_PURPOSE}</td>
                                    <td>{item.EUL_SEPARATOR}</td>
                                    <td>{item.EUL_NO}</td>
                                    <td colSpan={2}>{item.EUL_ETC}</td>
                                    <td>{item.EUL_REASON}</td>
                                    <td>{item.EUL_DOC_TYPE}</td>
                                    <td>{item.EUL_ACCEPT}</td>
                                </tr>
                            )
                        ))}
    
                        <tr>
                            <th>집합건물 표제부 2 반복건수</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].COLLATERAL2_COUNT}</td>
                        </tr>
                        <tr>
                            <th>문서명</th>
                            <td colSpan={8}>{_doc014 && _doc014.RESP_DATA && _doc014.RESP_DATA[0].TITLE}</td>
                        </tr>
    
                        {
                            (_doc014 && _doc014.JPG_URNS) &&
                            (
                                _doc014.JPG_URNS.map((item, jpgIdx) => (
                                <tr key={jpgIdx}>
                                    <td colSpan={9}>
                                        <img src={item} className="viewerImg" alt=''/>
                                    </td>
                                </tr>
                            )
                        ))}
                    </tbody> */}
                    {/* </table> */}
                    {/* 등기부등본 변경 전 */}                        
            </div>
        // ))
        );
    }
};

export default CopyRegisterView;