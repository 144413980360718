import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as ImplyAPI from '../lib/api/Imply';

const INITIALIZE = 'reportList/INITIALIZE';
const CHANGE_VALUE = 'reportList/CHANGE_VALUE';
const [GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE] = createRequestActionTypes('reportList/GET_LIST');
const SET_PAGING = 'reportList/SET_PAGING';
const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('reportList/GET_CONTENT');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getList = createAction(GET_LIST);
export const setPaging = createAction(SET_PAGING, (payload) => payload);
export const getContent = createAction(GET_CONTENT);

const getListSaga = createRequestSaga(GET_LIST, ImplyAPI.rpt_search);
const getContentSaga = createRequestSaga(GET_CONTENT, ImplyAPI.rpt_print);

export function* reportListSaga() {
    yield takeLatest(GET_LIST, getListSaga);
    yield takeLatest(GET_CONTENT, getContentSaga);
}

const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10);

const initialState = {
    isSaveOk: false,    //  저장완료 여부

    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    pageCount: null, // 구성할 페이지 수
    max: 3, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1, // 1 2 3 -> 4 5 6 
    currentPage: 1, // 현재 페이지 (page 의 값이 됨.)
    
    //  검색조건
    from_date: oneMonthAgo,
    to_date: today,
    page: "1",

    list: [],

    //  Modal Data
    rptMain: {
        etc: '',
        from_date: '',
        ng_count: '',
        ok_count: '',
        owner_change: '',
        report_date: '',
        report_id: '',
        resistance_loss: '',
        seizure_setting: '',
        to_date: '',
        total_count: '',
    },
    rptDtlOwner: [],
    rptDtlSeizure: [],
    rptDtlResistance: [],
    rptDtlEtc: [],

    reportList: null,
    error: null
};

const reportList = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        //  리스트 가져오기
        [GET_LIST_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                list: payload,
                isSaveOk: false,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            }
        },
        [GET_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                pageCount: null,
                max: 3,
                step: 1,
                currentPage: 1,
                error
            }
        },

        //  페이지 이동
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                        search: {
                            ...state.search,
                            page: (state.step + 1) * state.max - 2
                        }
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1,
                        search: {
                            ...state.search,
                            page: (state.step - 1) * state.max - 2
                        }
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },

        //  출력 내용 가져오기
        [GET_CONTENT_SUCCESS]: (state, { payload }) => {
            let _rptDtlOwner = [], _rptDtlSeizure= [], _rptDtlResistance = [], _rptDtlEtc = [];

            payload.ng_detail.forEach(item => {
                if(item.print_type === "OWNER_CHANGE") {
                    _rptDtlOwner.push(item);
                }
                else if(item.print_type === "SEIZURE_SETTING") {
                    _rptDtlSeizure.push(item);
                }
                else if(item.print_type === "RESISTANCE_LOSS") {
                    _rptDtlResistance.push(item);
                }
                else if(item.print_type === "ETC") {
                    _rptDtlEtc.push(item);
                }
            });

            return {
                ...state,
                rptMain: payload.main[0],
                rptDtlOwner: _rptDtlOwner,
                rptDtlSeizure: _rptDtlSeizure,
                rptDtlResistance: _rptDtlResistance,
                rptDtlEtc: _rptDtlEtc,
            }
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default reportList;