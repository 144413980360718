import React, { useEffect, useState } from 'react';
// import client from '../lib/api/client';
import AuditList from '../components/AuditList';
import { useDispatch, useSelector } from 'react-redux';
import { changeValue, impAuditSearch, initialize, setAuditSearch, setPaging } from '../modules/Imply';
import { qr } from '../common';
import dataURL from '../lib/dataURL';
import { useLocation, useNavigate } from 'react-router-dom';

const AuditListContainer = () => {
    const {
        implyAuditSearch,
        impAuditList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
    } = useSelector(({
        Imply
    }) => ({
        implyAuditSearch: Imply.implyAuditSearch,
        impAuditList: Imply.impAuditList,
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
        maxPageCount: Imply.maxPageCount,
    }))

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [update, setUpdate] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);

    //----------------------------------------------------------------------
    // 상단 버튼

    // 업로드로 이동
    const onClickUpload = () => {
        navigate(dataURL.ImplyResultPage)
    }
    //----------------------------------------------------------------------



    //----------------------------------------------------------------------
    // 검색
    
    // 등초본 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e
        dispatch(changeValue({
            field: 'implyAuditSearch',
            key: name,
            value
        }))
    }
    //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "implyAuditSearch", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------

    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.AuditListPage}?date_type=${implyAuditSearch.date_type}&from_date=${implyAuditSearch.from_date}&to_date=${implyAuditSearch.to_date}&name=${implyAuditSearch.name}&area=${implyAuditSearch.area}&addr=${implyAuditSearch.addr}&page=${implyAuditSearch.page}`), 
            {
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, implyAuditSearch.date_type, implyAuditSearch.from_date, implyAuditSearch.to_date, implyAuditSearch.name, implyAuditSearch.area, implyAuditSearch.addr, implyAuditSearch.page])


    // 변경된 url 로 조회.
    useEffect(() => {
        if(dispatch) {
            if(!location.search) {
                setChangeUrl(true)
            }
            else {
                const tempObj = qr(location.search);

                dispatch(setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                }))
                dispatch(setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                }))
                dispatch(impAuditSearch({
                    ...tempObj,
                    page: tempObj.page
                }))
                dispatch(setAuditSearch({
                    value: tempObj
                }))
            }
        }
    }, [dispatch, location])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        if(update && dispatch) {
            dispatch(impAuditSearch(implyAuditSearch))
            setUpdate(false)
        }
    }, [dispatch, update, implyAuditSearch])

    const propDatas = {
        implyAuditSearch,
        impAuditList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,

        onSubmit,
        onChangeSearch,
        onClickNum,
        onClickNext,
        onClickPrev,
        onClickUpload,
    }

    return (
        <>
            <AuditList {...propDatas}/>
        </>
    );
}

export default AuditListContainer;