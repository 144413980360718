import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DocSort from '../components/DocSort';
import { changeValue, docClean, fileSort, getDocType, getFile, initType } from '../modules/docSort';

const DocSortContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {cboDoc, docData, docData_Copy} = useSelector(({docSort}) => ({
        cboDoc: docSort.cboDoc,
        docData: docSort.docData,
        docData_Copy: docSort.docData_Copy
    }))
    const [splitArr, setSplitArr] = useState([]); // 이미지가 담길 배열(확대하는 경우사용함)

    // 현재 선택된 서류 유형
    const [curDocType, setCurDocType] = useState('DOC000');

    // 서류 확대해서 보기 모달
    const [isModal, setIsModal] = useState(false);
    const [selectedImgName, setSelectedImgName] = useState('');

    // 현재 문서 아이디
    const {state : { docNo, docType }} = location;
    const dispatch = useDispatch();


    // 서류 유형 변경 버튼
    const onDocBtn = (type) => {
        setCurDocType(type);
    }

    // 확대
    // 이미지 여러개인 경우도 고려해야함.
    const openExpand = (item) => {
        const {file_data, file_json, file_name} = item;

        if(file_data) {
            setSplitArr({type: 'arr', data: file_data.split(',')});
        } else if(!file_data && file_json){
            setSplitArr({type: 'json', data:file_json})
        }
        

        setIsModal(true);
        setSelectedImgName(file_name)
    }

    // 확대 닫기
    const closeExpand = () => {
        setIsModal(false);
        setSplitArr([]);
        setSelectedImgName('');
    }

    // 아이템 클릭
    const onChange = (e,type,target_type,idx) => {
        // 체크하는경우
        if(e.target.checked) {
            dispatch(changeValue({type,target_type,idx}))
        }
        // 체크 해제 하는 경우
        else {
            // 미분류 제외 체크 해제 하면
            if(type !== "DOCUDF") {
                // 미분류로
                dispatch(changeValue({type:"DOCUDF",target_type,idx}))
            }
        }
    }

    // 분류 초기화
    const onInit = () => {
        if(window.confirm('모든 서류가 미분류 상태로 초기화 됩니다. 실행하시겠습니까?')) {
            dispatch(initType());
        }
    }

    // 서류 개수 출력
    const getCount = (type) => {
        const target = docData.find((element) => {
            if(element.file_type === type) {
                return element;
            }
            return null;
        })

        if(target) {
            return target.files.length;
        }
        else
            return 0;
    }

    // 저장
    const onSave = () => {
        dispatch(fileSort({
            docData: docData,
            doc_id: docNo
        }));
    }

    const onEntitle = () => {
        navigate(-1)
    }

    /*
        3가지 경우의 수가 있다.
        1. file_data 에 하나만 있는 경우
        2. file_data 에 ',' 로 구분된 문자열이 있는 경우
        3. file_data 가 비어있고, file_json 만 있는 경우
    */
    // 이미지 처리 함수
    const showImg = (item) => {
        let imgSrc = '';
        let splitArr = [];

        const {file_data, file_json} = item;

        // data 에 있음
        if(file_data) {
            splitArr = file_data.split(',')
            imgSrc = splitArr[0];

            return (<img className='img' src={imgSrc} alt='imgData'/>)
        }
        // json 에 있음
        // 데이터를 div 로 감싸서 일정 글자수만 보이도록 조치함.
        else if(!file_data && file_json){

            return (<div className='imgJson'>{file_json.slice(0,100)}...</div>)
        }
    }

    const showType = (doc) => {

        for (let index = 0; index < cboDoc.length; index++) {
            const element = cboDoc[index];

            if(element.sub_code === doc.file_type) {
                return <div className="type">{element.code_name}</div>
            }
            else if(doc.file_type === 'DOCUDF') {
                return <div className="type none">휴지통</div>
            }
        }
    }

    // 서류 유형 조회
    useEffect(() => {
        if(cboDoc.length < 1) {
            dispatch(getDocType({
                doc_type: docType,
            }));
        }
    }, [cboDoc, dispatch, docType])

    // 이미지 조회
    useEffect(() => {
        if(docNo) {
            dispatch(getFile({
                doc_id: docNo,
                doc_type: docType,
            }))
        }
    }, [dispatch, docNo, docType])

    useEffect(() => {
        return () => dispatch(docClean())
    }, [dispatch])

    const propDatas = {
        docNo,
        cboDoc,
        docData,
        curDocType,
        isModal,
        selectedImgName,
        docData_Copy,
        splitArr,

        openExpand,
        closeExpand,
        onDocBtn,
        onChange,
        onInit,
        setIsModal,
        getCount,
        onSave,
        onEntitle,
        showImg,
        showType
    }

    return (
        <>

            <DocSort {...propDatas}/>
        </>
    );
}

export default DocSortContainer;