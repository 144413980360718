import React from 'react';
import WinnerInfo from '../../components/Modal/WinnerInfo';

const WinnerInfoContainer = (props) => {
    const {
        winner,
        setIsShowWinner,
    } = props;

    const onClose = () => {
        setIsShowWinner(false);
    }

    const propDatas = {
        winner,
        onClose,
    }
    return (
        <>
            <WinnerInfo {...propDatas} />
        </>
    );
}

export default WinnerInfoContainer;