import React from 'react';
import styled from 'styled-components';
import loader from '../loading.gif'

const ImplyExcelWorkBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;
    position: relative;

    .divFlex-btn{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .tableCover {
        height: 80vh;
        position: relative;
    }

    .loaderCover {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loaderLabel {
        cursor: pointer;
    }

    .loaderCover img{
        width: 64px;
        height: 64px;
    }

    table {
        white-space: nowrap;
    }

    table td input {
        padding: 5px;
    }

    table .wide {
        width: 460px;
    }
`;

const ImplyExcelWork = (props) => {
    const {
        implyExcelHeader,
        implyExcel,
        fileState,

        onChange,
        onChangeValue,
        onUpload,
    } = props;
    return (
        <ImplyExcelWorkBlock>
           <div className='divFlex-btn'>
                <input className='xlUploadIpt' name='results' id='results' type='file' onChange={onChange}/>
                <div>
                    <button onClick={onUpload} className="btn1">데이터 업로드</button>
                </div>
           </div>
            <div className='tableCover'>
                {fileState === 1?
                <div className='loaderCover'>
                    <img src={loader} alt='loader'/>
                </div>
                : null}
                {fileState === 0 && implyExcelHeader.length === 0 && implyExcel.length === 0?
                    <label htmlFor='results' className='loaderCover loaderLabel'>파일을 업로드 하세요</label>
                :null}
                <table className='tt'>
                    <thead>
                        <tr>
                            {implyExcelHeader.length > 0? 
                            <>
                                <th>no</th>
                                {implyExcelHeader.map((item, idx) => {
                                    return <th key={idx}>{item}</th>
                                })}
                            </>
                            : null}
                        </tr>
                    </thead>
                    <tbody>
                        {implyExcel.length > 0?
                        <>
                            {implyExcel.map((item, idx) => {
                                return  (
                                    <tr key={idx}>
                                        <td>{idx+1}</td>
                                        {item.map((hitem, idx2) => {
                                            return (
                                                <td key={idx2}>
                                                    <input placeholder='값이 없습니다' name={implyExcelHeader[idx2]} className={idx2 === 5? 'wide' : ''} value={hitem} onChange={(e) => onChangeValue(e, idx)}/>
                                                </td>
                                            ) 
                                        })}
                                    </tr>
                                )
                            })}
                        </>
                        :null}
                    </tbody>
                </table>
            </div>
        </ImplyExcelWorkBlock>
    );
}

export default ImplyExcelWork;