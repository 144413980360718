import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaintainRegister from '../components/MaintainRegister';
import { changeValue, getDetail, getList, initialize, modalClear, saveDetail, setPaging } from '../modules/maintainRegister';
import { dataSystemCode } from '../lib/dataSystemCode';
import { ALERT } from '../lib/dataWord';

const MaintainRegisterContainer = () => {
    const [isPostback, setIsPostback] = useState(true);             //  최초 조회
    const [isLeaveMultiCbo, setIsLeaveMultiCbo] = useState(false);  //  Multipe ComboBox 공통 닫기 Event
    const [cboAreaData, setCboAreaData] = useState([]);             //  지역 Static Data
    const [isShowCboArea, setIsShowCboArea] = useState(false);      //  지역 Multipe Combo Show/Hide
    const [selectedAreas, setSelectedAreas] = useState('');         //  지역 선택된 Text
    const [isShowReg, setIsShowReg] = useState(false);              //  등록 Modal Show/Hide
    const [isShowDaumPost, setIsShowDaumPost] = useState(false);

    const cboCycleType = dataSystemCode.CYCLE_TYPE;      //실행주기 ex) 매주,매월

    const dispatch = useDispatch();
    const {
        user
    } = useSelector(({user}) => ({
        user: user.user
    }))
    const {
        isSaveOk,

        //  검색조건
        date_type,
        from_date,
        to_date,
        name,
        area,
        page,

        list,

        start_date,
        work_name,
        work_birth,
        phone,
        exec_cycle,
        addr,
        addr_dtl,
        estate_no,
        house_owner,
        end_date,
        jibun_addr,
        nextwork_id,
        sido,
        sigungu,
        town,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,
     } = useSelector(({ maintainRegister }) => ({
        isSaveOk: maintainRegister.isSaveOk,

        date_type: maintainRegister.date_type,
        from_date: maintainRegister.from_date,
        to_date: maintainRegister.to_date,
        name: maintainRegister.name,
        area: maintainRegister.area,
        page: maintainRegister.page,

        list: maintainRegister.list,

        start_date: !maintainRegister.start_date ? "" : maintainRegister.start_date,
        work_name: !maintainRegister.work_name ? "" : maintainRegister.work_name,
        work_birth: !maintainRegister.work_birth ? "" : maintainRegister.work_birth,
        phone: !maintainRegister.phone ? "" : maintainRegister.phone,
        exec_cycle: !maintainRegister.exec_cycle ? "" : maintainRegister.exec_cycle,
        addr: !maintainRegister.addr ? "" : maintainRegister.addr,
        addr_dtl: !maintainRegister.addr_dtl ? "" : maintainRegister.addr_dtl,
        estate_no: !maintainRegister.estate_no ? "" : maintainRegister.estate_no,
        house_owner: !maintainRegister.house_owner ? "" : maintainRegister.house_owner,
        end_date: !maintainRegister.end_date ? "" : maintainRegister.end_date,
        jibun_addr: !maintainRegister.jibun_addr ? "" : maintainRegister.jibun_addr,
        nextwork_id: !maintainRegister.nextwork_id ? "" : maintainRegister.nextwork_id,
        sido: !maintainRegister.sido ? "" : maintainRegister.sido,
        sigungu: !maintainRegister.sigungu ? "" : maintainRegister.sigungu,
        town: !maintainRegister.town ? "" : maintainRegister.town,

        //  페이징
        pageCount: maintainRegister.pageCount,
        max: maintainRegister.max,
        step: maintainRegister.step,
        currentPage: maintainRegister.currentPage,
    }))

    const onChange = (e) => {
        dispatch(changeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const onSearch = useCallback(() => {
        dispatch(getList({
            date_type: date_type,
            from_date: from_date,
            to_date: to_date,
            name: name,
            area: area,
            page: page,
        }))
    }, [dispatch, date_type, from_date, to_date, name, area, page])
    
    const onSubmit = (e) => {
        e.preventDefault();
        
        onSearch();
    }

    // 페이징   --------------------------------------------
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({key: "page", value: num}));
            dispatch(changeValue({key: "currentPage", value: num}));
            
            onSearch();
        }
    }
    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
        }
    }
    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
        }
    }
    //  --------------------------------------------    페이징

    //  Multiple Check Combo 공통   --------------------------------------------
    const onShowMultiCbo = (type) => {
        if(type === "STATUS") {
            setIsShowCboArea(false);
        }
        else if(type === "AREA") {
            setIsShowCboArea(!isShowCboArea);
        }

        if(!isShowCboArea) {
            setIsLeaveMultiCbo(false);
        }
    }
    const onHideMultiCbo = (e) => {
        if(e.type === "mouseleave") {
            setIsLeaveMultiCbo(true);
        }
        else if(isLeaveMultiCbo) {
            setIsShowCboArea(false);
            setIsLeaveMultiCbo(false);
        }
    }
    const onEnterMultiCbo = () => {
        setIsLeaveMultiCbo(false);
    }
    //  --------------------------------------------    Multiple Check Combo 공통
    
    //  지역 Multiple Check Combo   --------------------------------------------
    useEffect(() => {
        if(user) {
            // null 처리
            if(user.auth_area) {
                let _areas = []
                user.auth_area.split(',').forEach(area => {
                    _areas.push({
                        area: area,
                        checked: true,
                    })
                });

                setCboAreaData(_areas);
            }
            else {
                setCboAreaData([]);
            }
            //  로그인 유저에 할당된 지역
        }
    }, [user])
    const onAreaChange = (item, e) => {
        setCboAreaData(
            cboAreaData.map((data) => 
                data.area === item.area ? {...data, checked: e.target.checked} : data
            )
        )
    }
    const onAreaAll = (e) => {
        setCboAreaData(
            cboAreaData.map((data) => 
                {return {...data, checked: e.target.checked}}
            )
        )
    }
    useEffect(() => {
        let selTexts = "", selValues = "";

        const filterData = cboAreaData.filter(x => x.checked === true);
        filterData.forEach(selected => {
            selTexts += selected.area + ",";
            selValues += selected.area + ",";
        });

        if(filterData.length === 0) {
            selTexts = "";
            selValues = "NONE";
        }
        else if(cboAreaData.length === filterData.length) {
            selTexts = "전체";
            selValues = "";
        }
        else {
            selTexts = selTexts.slice(0, -1);
        }

        setSelectedAreas(selTexts);
        dispatch(changeValue({key: 'area', value: selValues}))

    }, [dispatch, cboAreaData])
    //  --------------------------------------------    지역 Multiple Check Combo

    const onClickNewReg = () => {
        setIsShowReg(true);
    }
    const onClickRow = (item, e) => {
        if(!isShowReg) {
            dispatch(getDetail({work_id: item.nextwork_id}));

            setIsShowReg(true);
        }
    }
    const onClose = () => {
        setIsShowReg(false);
        dispatch(modalClear());
    }
    const onShowDaumPost = () => {
        setIsShowDaumPost(!isShowDaumPost);
    }
    const handlePostCode = (data) => {
        dispatch(changeValue({key: 'addr', value: data.roadAddress,}));
        dispatch(changeValue({key: 'jibun_addr', value: (data.jibunAddress ? data.jibunAddress : data.autoJibunAddress),}));
        dispatch(changeValue({key: 'sido', value: data.sido,}));
        dispatch(changeValue({key: 'sigungu', value: data.sigungu,}));
        dispatch(changeValue({key: 'town', value: data.bname,}));

        setIsShowDaumPost(!isShowDaumPost);
    }
    const onSave = (type) => {
        if(!work_name) {
            ALERT("대상자 성명을 입력해주세요.");
            return;
        }
        if(!work_birth || work_birth.length !== 6) {
            ALERT("대상자 생년월일을 입력해주세요.");
            return;
        }
        if(!phone) {
            ALERT("대상자 휴대전화를 입력해주세요.");
            return;
        }
        if(!start_date) {
            ALERT("관리 시작일을 입력해주세요.");
            return;
        }
        if(!end_date) {
            ALERT("관리 종료일을 입력해주세요.");
            return;
        }
        if(!addr) {
            ALERT("주소를 입력해주세요.");
            return;
        }

        dispatch(saveDetail({
            work_id: nextwork_id,
            start_date: start_date,
            work_name: work_name,
            work_birth: work_birth,
            phone: phone,
            exec_cycle: exec_cycle,
            addr: addr,
            addr_dtl: addr_dtl,
            sido: sido,
            sigungu: sigungu,
            town: town,
            jibun_addr: jibun_addr,
            estate_no: estate_no,
            house_owner: house_owner,
            end_date: end_date,
            use_flag: "Y",
        }))

        if(!type) {
            setIsShowReg(false);
        }
    }

    useEffect(() => {
        if(isSaveOk) {
            onSearch();
        }
    }, [dispatch, isSaveOk, onSearch])

    useEffect(() => {
        if(isPostback) {
            setIsPostback(false);
            onSearch();
        }
    }, [isPostback, onSearch])

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        //  검색조건
        date_type,
        from_date,
        to_date,
        name,

        //  List
        list,

        //  Modal Show/Hide
        isShowReg,

        //  등록 Data
        nextwork_id,
        cboCycleType,
        start_date,
        work_name,
        work_birth,
        phone,
        exec_cycle,
        addr,
        addr_dtl,
        estate_no,
        house_owner,
        end_date,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,
        
        //  지역 검색 관련
        cboAreaData,
        isShowCboArea,
        selectedAreas,
        onAreaChange,
        onAreaAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,

        onChange,
        onSubmit,
        
        onClickNewReg,
        onClickRow,
        onClickNum,
        onClickNext,
        onClickPrev,
        onShowDaumPost,
        handlePostCode,
        onSave,
        onClose,
    }
    return (
        <>
            <MaintainRegister {...propDatas}/>
        </>
    );
}

export default MaintainRegisterContainer;