import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { pwValidation, qr } from '../common';
import CustomerList from '../components/CustomerList';
import { ALERT } from '../lib/dataWord';
import { changeDetail, changeSearch, checkBox, checkBoxAll, custImplyBatch, custImplyReg, custSearch, custUpdate, custView, getCombo, initialize, initializeDetail, nextWorkBatch, nextWorkReg, setPaging, setSearch } from '../modules/customer';

const CustomerListContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        customerList,
        checkedList,
        max, 
        step, 
        currentPage, 
        pageCount,
        search,
        customerDetail,
        cboApply,
        user
    } = useSelector(({customer, user}) => ({
        customerList: customer.customerList,
        checkedList: customer.checkedList,
        max: customer.max, 
        step: customer.step, 
        currentPage: customer.currentPage, 
        pageCount: customer.pageCount,
        search: customer.search,
        customerDetail: customer.customerDetail,
        cboApply: customer.cboApply,
        user: user.user,
    }))

    const [update, setUpdate] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);

    const [imply, setImply] = useState(false);
    const [next, setNext] = useState(false);
    const [change, setChange] = useState(false);

    const [isModal, setIsModal] = useState(false);
    const [isDaumModal, setIsDaumModal] = useState(false);

    const [pwdCheck, setPwdCheck] = useState(false);
    // const [isShowCboArea, setIsShowCboArea] = useState(false);
    // const [cboAreaData, setCboAreaData] = useState([]);             //  지역 Static Data
    // const [isLeaveMultiCbo, setIsLeaveMultiCbo] = useState(false);  //  Multipe ComboBox 공통 닫기 Event
    // const [selectedAreas, setSelectedAreas] = useState('');         //  지역 선택된 Text



    // //----------------------------------------------------------------------
    // // 지역 검색 관련
    // const onShowMultiCbo = () => {
    //     setIsShowCboArea(!isShowCboArea);

    //     if(!isShowCboArea) {
    //         setIsLeaveMultiCbo(false);
    //     }
    // }
    // const onHideMultiCbo = (e) => {
    //     if(e.type === "mouseleave") {
    //         setIsLeaveMultiCbo(true);
    //     }
    //     else if(isLeaveMultiCbo) {
    //         setIsShowCboArea(false);
    //         setIsLeaveMultiCbo(false);
    //     }
    // }
    // const onEnterMultiCbo = () => {
    //     setIsLeaveMultiCbo(false);
    // }

    // const onAreaChange = (item, e) => {
    //     setCboAreaData(
    //         cboAreaData.map((data) => 
    //             data.area === item.area ? {...data, checked: e.target.checked} : data
    //         )
    //     )
    // }
    // const onAreaAll = (e) => {
    //     setCboAreaData(
    //         cboAreaData.map((data) => 
    //             {return {...data, checked: e.target.checked}}
    //         )
    //     )
    // }
    // //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeSearch({
            key: name,
            value
        }))
    }

    // 검색값 초기화
    const onInitSearch = () => {
        // setCboAreaData(
        //     cboAreaData.map((data) => ({
        //         ...data,
        //         checked: true,
        //     }))
        // )

        dispatch(setSearch({
            search: 'init'
        }))
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 체크박스
    // 체크박스 개별
    const onCheck = (e, item) => {
        const {target: {checked}} = e;
        dispatch(checkBox({
            checked, 
            item,
            target: 'normal'
        }))
    }

    // 체크박스 전체
    const onCheckAll = (e) => {
        const {target: {checked}} = e;
        dispatch(checkBoxAll({
            checked,
            target: 'normal'
        }))
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 권한기능
    // 일괄 묵시 처리
    const onImplyAll = () => {
        if(window.confirm(`현재 검색 조건의 모든 대상자를 묵시 처리하시겠습니까?\n대상자: ${customerList[0].tot_count}명`)) {
            dispatch(custImplyBatch({
                ...search,
                start_date: "",
                end_date: "",
            }))
            setUpdate(true);
        }
    }

    // 선택 묵시 처리
    const onImply = () => {
        if(checkedList.length < 1) {
            ALERT("선택된 항목이 없습니다.")
        }
        else {
            if(window.confirm(`총 ${checkedList.length}명을 묵시대상자로 처리 하시겠습니까?`)) {
                let payload = checkedList.map((item) => item.cust_id);
                let valid = true;
                let el = null;

                for (let index = 0; index < checkedList.length; index++) {
                    el = checkedList[index];
                    if(el.imply_yn === "Y") {
                        valid = false;
                        break;
                    }
                }
                if(valid) {
                    dispatch(custImplyReg({
                        cust_id: payload.toString(),
                    }))
                    setUpdate(true);
                }
                else {
                    ALERT("이미 묵시 대상자로 등록되어있는 사용자가 있습니다.");
                }
            }
        }
    }

    // 일괄 사후 처리
    const onNextWorkAll = () => {
        if(window.confirm(`현재 검색 조건의 모든 대상자를 사후관리 대상자로 처리하시겠습니까?\n대상자: ${customerList[0].tot_count}명`)) {
            dispatch(nextWorkBatch(search))
            setUpdate(true);
        }
    }

    // 선택 사후 처리
    const onNextWork = () => {
        if(checkedList.length < 1) {
            ALERT("선택된 항목이 없습니다.")
        }
        else {
            if(window.confirm(`총 ${checkedList.length}명을 사후관리 대상자로 처리 하시겠습니까?`)) {
                let payload = checkedList.map((item) => item.cust_id);
                // setUpdate(true);
                // dispatch(implyBatch({
                //     cust_id: payload.toString()
                // }))
                dispatch(nextWorkReg({
                    cust_id: payload.toString(),
                }))
                setUpdate(true)
            }
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 리스트 클릭
    const onClickList = (e, item) => {
        const {target: {className, tagName}} = e;
        // 체크박스
        if(className === 'check' || tagName === 'LABEL' || tagName === 'INPUT') {
            // 넘어가고
        }
        // 일반 리스트 클릭
        else {
            if(!change) return;
            openModal();
            dispatch(custView({
                cust_id: item.cust_id,
            }))
        }
    }

    const openModal = () => {
        setIsModal(true);
    }

    const closeModal = () => {
        setIsModal(false);
        setIsDaumModal(false);
        setPwdCheck(false);
        dispatch(initializeDetail());
    }

    const onChangeDetail = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeDetail({
            key: name,
            value,
        }))
    }

    // 수정하기
    const onClickModi = () => {
        // 필수입력값?

        // 비밀번호 확인 부분
        let valid = true;

        // 체크 한 경우
        if(pwdCheck) {
            // 하나라도 입력이 안된 경우
            if(!customerDetail.pwd || !customerDetail.pwd_check) {
                valid = false;
                ALERT('비밀번호, 비밀번호 확인 모두 입력해야 합니다.')
            }
            // 입력은 다 했는데 일치 하지 않는 경우
            else if (customerDetail.pwd !== customerDetail.pwd_check) {
                valid = false;
                ALERT("비밀번호와 비밀번호 확인이 일치하지 않습니다.")
            }
            // 일치는 하는데 비밀번호 형식이 안맞는 경우
            else if (!pwValidation(customerDetail.pwd)) {
                valid = false;
                ALERT('비밀번호는 영어 대소문자, 숫자, 특수문자로 조합되어야 합니다.\n동일한 문자, 연속된 숫자는 세자리 이상 사용할 수 없습니다.')
            }
        }
        // 체크 안한 경우
        else {
            // 체크 안했는데 비밀번호 입력 한 경우
            if(customerDetail.pwd || customerDetail.pwd_check) {
                valid = false;
                ALERT("비밀번호를 변경 하려면 비밀번호 변경 확인에 체크해야합니다.")
            }
        }

        if(valid) {
            if(window.confirm('사용자 정보를 변경하시겠습니까?')) {
                dispatch(custUpdate({
                    cust_id: customerDetail.cust_id || '',
                    cust_name: customerDetail.cust_name || '',
                    phone: customerDetail.cust_phone || '',
                    tel: customerDetail.cust_tel || '',
                    type: customerDetail.cust_type || '',
                    pwd: customerDetail.pwd || '',
                    addr: customerDetail.addr || '',
                    addr_dtl: customerDetail.addr_dtl || '',
                    sido: customerDetail.sido || '',
                    sigungu: customerDetail.sigungu || '',
                    town: customerDetail.town || '',
                    jibun_addr: customerDetail.jibun_addr || '',
                    contract_end_date: customerDetail.contract_end_date || '',
                    bank_name: customerDetail.bank_name || '',
                    account_no: customerDetail.account_no || '',
                    account_owner: customerDetail.account_owner || '',
                    use_flag: customerDetail.use_flag || '',
                }))
                setUpdate(true);
                closeModal();
            }
        }
    }

    const onChangePwdCheck = (e) => {
        const {target: {checked}} = e;
        setPwdCheck(checked);
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 다음모달
    const onComplete = (data) => {
        //data.zonecode 우편번호 
        //data.roadAddress // 도로명 주소
        dispatch(changeDetail({
            key: 'addr',
            value: data.roadAddress,
        }))
        dispatch(changeDetail({
            key: 'addr_no',
            value: data.zonecode,
        }))
        dispatch(changeDetail({
            key: 'sido',
            value: data.sido,
        }))
        dispatch(changeDetail({
            key: 'sigungu',
            value: data.sigungu,
        }))
        dispatch(changeDetail({
            key: 'town',
            value: data.bname,
        }))
        dispatch(changeDetail({
            key: 'jibun_addr',
            value: data.jibunAddress ? data.jibunAddress : data.autoJibunAddress,
        }))
        setIsDaumModal(false)
    }

    const onOpenDaum = () => {
        setIsDaumModal(true)
    }

    const onCloseDaum = () => {
        setIsDaumModal(false)
    }
    //----------------------------------------------------------------------

    
    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeSearch({key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------

    // 권한 조회 범위
    /*
    당첨자: WINNER
    */

    useEffect(() => {
        // 초기에 들어온 경우 (쿼리스트링이 없는 경우에 해당)
        if(!location.search) {
            setChangeUrl(true)
        }
        // 쿼리스트링이 있으면 그 값을 search 에 할당
        else if(location.search && dispatch) {
            let tempObj = qr(location.search);
            dispatch(setSearch({
                search: tempObj
            }))
            dispatch(custSearch({
                ...tempObj,
                area: "",
                start_date: "",
                end_date: "",
            }))
            dispatch(setPaging({
                key: "currentPage",
                value: Number(tempObj.page),
            }))
            dispatch(setPaging({
                key: "step",
                value: Number(tempObj.page),
            }))
        }
    }, [location, dispatch])

    // 쿼리 스트링 구성, 변경, 이동
    useEffect(() => {
        if(changeUrl && navigate) {
            navigate(`${location.pathname}?date_type=${search.date_type}&start_date=${search.start_date}&end_date=${search.end_date}&phone=${search.phone}&name=${search.name}&use_flag=${search.use_flag}&page=${search.page}`,
            {
                state: {
                    func: location.state.func
                },
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [changeUrl, navigate, location, search, user])
    
    // 불필요해서 일단 주석처리만
    // useEffect(() => {
    //     //날짜는 일단 빈값으로 전달
    //     if(dispatch && search) {
    //         dispatch(custSearch({
    //             ...search,
    //             start_date: "",
    //             end_date: "",
    //         }))
    //     }
    // }, [dispatch, search])

    // 권한설정
    useEffect(() => {
        if(location.state.func) {
            const {state: {func}} = location;
            if(func.some((el) => el.id === "FUNC971")) setImply(true);
            if(func.some((el) => el.id === "FUNC972")) setNext(true);
            if(func.some((el) => el.id === "FUNC973")) setChange(true);
        }
        else {
            setImply(false);
            setNext(false);
            setChange(false);
        }
    }, [location])

    useEffect(() => {
        if(update && dispatch && location) {
            let tempObj = qr(location.search);

            dispatch(custSearch({
                ...tempObj,
                area: "",
                start_date: "",
                end_date: "",
            }));
            setUpdate(false);
        }
    }, [update, dispatch, location])

    useEffect(() => {
        dispatch(getCombo({main_code: "APPLY_TYPE"}));

        return () => dispatch(initialize());
    }, [dispatch])

    // useEffect(() => {
    //     if(user) {
    //         // null 처리
    //         if(user.auth_area.length > 0) {
    //             let _areas = []
    //             user.auth_area.split(',').forEach(area => {
    //                 _areas.push({
    //                     area: area,
    //                     checked: true,
    //                 })
    //             });
    //             setCboAreaData(_areas);
    //         }
    //         else {
    //             setCboAreaData([]);
    //         }
    //         //  로그인 유저에 할당된 지역
    //     }
    // }, [user])

    // useEffect(() => {
    //     if(cboAreaData.length > 0) {
    //         let selTexts = "", selValues = "";
    
    //         const filterData = cboAreaData.filter(x => x.checked === true);
    //         filterData.forEach((selected, idx )=> {
    //             if(idx !== filterData.length -1) {
    //                 selTexts += selected.area + ",";
    //                 selValues += selected.area + ",";
    //             }
    //             else {
    //                 selTexts += selected.area;
    //                 selValues += selected.area;
    //             }
    //         });
    
    //         if(cboAreaData.length === filterData.length) {
    //             selTexts = "전체";
    //             // selValues = "";
    //         }
    //         else {
    //             // selTexts = selTexts.slice(0, -1);
    //         }
    
    //         setSelectedAreas(selTexts);
    //         // dispatch(changeValue({key: 'area', value: selValues}))
    //         dispatch(changeSearch({
    //             key: 'area',
    //             value: selValues
    //         }))
    //     }

    // }, [dispatch, cboAreaData])
    
    const propDatas = {
        customerList,
        checkedList,
        max, 
        step, 
        currentPage, 
        pageCount,
        search,
        imply,
        next,
        isModal,
        customerDetail,
        cboApply,
        isDaumModal,
        pwdCheck,
        // isShowCboArea,
        // cboAreaData,
        // selectedAreas,

        onSubmit,
        onCheck,
        onCheckAll,
        onChangeSearch,
        onImply,
        onNextWork,
        onClickPrev,
        onClickNum,
        onClickNext,
        onClickList,
        closeModal,
        onImplyAll,
        onNextWorkAll,
        onChangeDetail,
        onComplete,
        onCloseDaum,
        onOpenDaum,
        onClickModi,
        onChangePwdCheck,
        // onShowMultiCbo,
        // onHideMultiCbo,
        // onEnterMultiCbo,
        // onAreaChange,
        // onAreaAll,
        onInitSearch,
    }
    return (
        <>
            <CustomerList {...propDatas}/>
        </>
    );
}

export default CustomerListContainer;