import React from 'react';
import styled from 'styled-components';

const CoDocUploadBlock = styled.div`
    .popupWrap{
        width: 700px;
        padding: 55px 60px;
    }
    .popupWrap h4 {
        font-size: 26px;
        color: #545454;
        text-align: center;
        margin-bottom: 26px;
    }
    .divScroll {
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #BDBDBD;
        border-radius: 10px;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .filesBox {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: space-between;
    }
    .filesBox > div {
        position: relative;
        padding-bottom: 30px;
    }
    .filesBox .req-btn{
        position: absolute;
        right: 0px;
        top: 30px;
        color: #0B999C;
        padding: 1px 10px;
        margin-left: 4px;
        border: 1px solid #0B999C;
        background-color: white;
        border-radius: 4px;
        cursor: pointer;
        height: 28px;
        font-weight: 600;
    }

    .filesBox .req-btn:hover,
    .lblInputFiles:hover{
        color: white;
        background-color: #0B999C;
    }
    .filesBox:not(:last-child){
        border-bottom: 1px solid #BDBDBD;
    }

    .filesBox .filesList {
        display: flex;
        flex-direction: column;
        flex-wrap: no-wrap;
        align-items: flex-end
    }
    .filesBox .filesList > div {
        color: #545454;
        margin-bottom: 5px;
        display: flex;
        // justify-content: space-between;
        justify-content: flex-end;
        gap: 5px;
        width: 100%;
    }
    .filesBox .filesList > div .del-btn{
        margin-left: 5px;
    }
    .filesBox .filesList .red{
        border-color: red;
        color: red;
    }
    .filesBox .filesList .red:hover{
        background-color: red;
        color: white;
    }

    .filesBox .filesList .green{
        border-color: green;
        color: green;
    }
    .filesBox .filesList .green:hover{
        background-color: white;
        color: green;
        cursor: default;
    }
    .filesBox .filesList .gray:hover{
        background-color: white;
        color: #808080;
        cursor: default;
    }
    .filesBox .filesList .red:hover{
        color: white;
    }
    .filesBox .filesList .requestMsg {
        color: blue;
    }

    .filesBox .filesList .filesDate {
        display: flex;
        gap: 5px;
        font-size: 13px;
        align-items: center;
        color: #afafaf;
    }

    .lblInputFiles {
        color: #0B999C;
        padding: 1px 10px;
        margin-left: 4px;
        border: 1px solid #0B999C;
        border-radius: 4px;
        cursor: pointer;
    }

    .ctrlFile {
        display: none;
    }

    .divButtons {
        margin-top: 40px;
    }
    
`;

const CoDocUpload = (props) => {
    const {
        doc_type,
        fileList,
        
        onDocUploadClick,
        onFileChange,
        onRemoveFile,
        onSave,
    } = props;

    return (
        <CoDocUploadBlock>
            <div className='popupWrap'>
                <h4>참조 파일 업로드</h4>
                <div className='divScroll'>
                    {doc_type.map((doc, docIdx) => (
                        <div key={docIdx} className='filesBox'>
                            <div>
                                {doc.name}
                                <label className='lblInputFiles' htmlFor={'file'+docIdx}>파일 추가</label>
                                <input type="file" name="file" id={"file"+docIdx} className='ctrlFile' onChange={onFileChange.bind(this, doc)} accept=".jpg,.jpeg,.png,.pdf" multiple/>
                            </div>

                            <div className='filesList'>
                                {fileList.map((files, idx) => (
                                    doc.code === files.file_type && files.use_flag === "Y" &&
                                    <div key={idx}>
                                        <p>{files.file_name}</p>
                                        <button onClick={() => onRemoveFile(files)} className='del-btn red'>삭제</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='divButtons'>
                    <button onClick={onSave} className="divB-Save">저장</button>
                    <button onClick={onDocUploadClick.bind(this, 'onoff', null)}  className="divB-Cancel">취소</button>
                </div>
            </div>
        </CoDocUploadBlock>
    );
}

export default CoDocUpload;