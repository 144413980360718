import React from 'react';
import styled from 'styled-components';

const PrintSortBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 20px 50px 0;
    box-sizing: border-box;

    .containerDiv {
        display: flex;
        align-items: flex-start;
        height: 78vh;
        gap: 45px;
        overflow-y: auto;
        border: 1px solid #ccc;
    }

    .dkgreen-btn{
        margin-bottom: 15px;
    }
    .up-btn, .down-btn {
        padding: 5px 10px;
    }
    .up-btn:hover, .down-btn:hover {
        border: 1px solid white;
        background-color: #ccc;
    }
`;

const PrintSort = (props) => {
    const {
        printList,

        onRowChange,
        onRowSort,
        onSave,
    } = props;

    return (
        <PrintSortBlock>
            <button onClick={onSave} className="dkgreen-btn">저장</button>

            <div className='containerDiv'>
                <table className='border-tt'>
                    <thead>
                        <tr>
                            <th>서류명</th>
                            <th>순서</th>
                            <th>출력여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {printList.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.code_name}</td>
                                <td>
                                    <button onClick={onRowSort.bind(this, item, 'UP')} className="up-btn">▲</button>
                                    <button onClick={onRowSort.bind(this, item, 'DOWN')} className="down-btn">▼</button>
                                </td>
                                <td>
                                    <select name="use_flag" id="use_flag" value={item.use_flag} onChange={onRowChange.bind(this, item)}>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>
                                    </select>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PrintSortBlock>
    );
}

export default PrintSort;