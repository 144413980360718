import React, { Fragment } from 'react';
import styled from 'styled-components';
import DaumPostcode from "react-daum-postcode";

const EntitleWriteStep2Block = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 30px 50px;
    box-sizing: border-box;
    position:relative;

    h2{
        color: #545454;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    select{
        color: #494949;
        text-align: center;
    }
    input{
        color: #545454;
    }

    

    .calcResult {
        margin: 10px 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif;
    }
    .calcResult.calcPOSSIBLE { color: #1EBE86; }
    .calcResult.calcIMPOSSIBLE { color: red; }
    .calcResult > * {
        margin: 0;
    }

    
    .calc-center{
        text-align: center;
        margin-bottom: 35px;
    }
    .address-btn{
        margin-right: 15px;
    }
    .StepButtons{
        display: flex;
        justify-content: center;
    }
    .StepButtons button{
        margin: 0 10px;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .tt.mobile{
            width:100%;
        }
        .tt.mobile thead tr th,
        .tt.mobile tbody tr td{
            display: block;
        }
        .tt.mobile thead tr,
        .tt.mobile tbody tr{
            display: block;
            height: 100%;   
        }
        .tt.mobile thead{
            display:block;
            float:left;
            border-bottom: 0;
            //min-width: 130px;
        }
        .tt.mobile thead tr th{
            border-right: 1px solid #0B999C;
            padding: 10px;
            //box-sizing: border-box;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .tt.mobile thead tr th:last-child{
            border-bottom: 0;
        }
        .tt.mobile tbody{
            display:block;
        }
        .tt.mobile tbody tr td{
            padding : 10px 0px;
            border-bottom: 1px solid #cfcfcf;
            width: auto;
            height:30px;
            //display: flex;
            // align-items: center;
            // justify-content: center;
            text-align: center;
        }
        .tt.mobile tbody tr td:last-child{
            border-bottom: 0;
        }
        .tt tbody tr:hover{
            background:none;
        }
        .StepButtons{
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .StepButtons button{
            width: 50%;
            font-size: 16px;
            margin: 0px !important;
        }
        .prev-btn,.cancel-btn{
            width: 50%; 
            height: 48px; 
            color: #ffffff; 
            font-size: 14px; 
            font-weight: 600; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 0px; 
            border: none; 
            background-color: #808080; 
        }
        .prev-btn::before{
            content:"<"; 
            width: 5px; 
            height: 10px; 
            color: #ffffff; 
            display: inline-block; 
            align-items: center; 
            margin: -4px 8px 0 0;
        }
        .save-btn{
            width: 50%; 
            height: 48px;
            color: #ffffff;
            border-radius: 0px; 
            border: none; 
            background-color: #0B999C;
        }
        .next-btn{ 
            width: 50%; 
            height: 48px; 
            color: #ffffff; 
            font-size: 14px; 
            font-weight: 600; 
            display: flex; 
            align-items: center; 
            justify-content: center; 
            border-radius: 0px; 
            border: none;
            background: linear-gradient(to bottom, #1EBF86 , #0B999C);
        }
    }

    fieldset {
        border: none;
    }
`;

const Step2Box1 = styled.div`
    margin-bottom: 40px;

    table{
        width: 60%;
    }
    table tbody tr{
        font-size: 12px;
    }
    table tr{
        height: 40px;
    }
    table tr td,table tr th{
        width: 33% !important;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px) {
        table{
            width: 100%;
        }
        table tr td,table tr th{
            width: 100% !important;
        }
        .tt.mobile.step2 thead{
            overflow:initial;
        }

        .tt.mobile.step2 thead tr th{
            border-right: 0.5px solid #bdbdbd;
            border-bottom: 0.5px solid #bdbdbd;
            z-index:1
        }
        .tt.mobile.step2 thead tr th:last-child{
            border-bottom: 0
        }
        .tt.mobile.step2 thead tr th:after{
            border-bottom:0;
        }
        .tt.mobile.step2 tbody td{
            border-bottom: 0.5px solid #bdbdbd;
        }
        .tt.mobile.step2 tbody td:last-child{
            border-bottom:0;
        }
    }
`;
const Step2Box2 = styled.div`
    .table{
        width: 100%; 
        border-collapse: collapse; 
        text-align: center;
        border-radius: 0.31rem; 
        background-color: #ffffff;
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);
        margin-bottom: 3.75rem;
    }
    .table-layout{
        width: 100%;
        position:relative;
    }
    .table-layout .title-wrap{
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
        width:100%;
    }
    .table-layout .title-wrap div{
        color:#0B999B;
        font-weight:bold;
        display: inline-block;
        width:100%;
    }
    // .table-layout:first-child .title-wrap div:nth-child(1),
    // .table-layout:first-child .tr-top>div:nth-child(1){
    //   width: 40%;
    // }
    // .table-layout:first-child .title-wrap div:nth-child(2),
    // .table-layout:first-child .tr-top>div:nth-child(2){
    //     width: 20%;
    // }
    // .table-layout:first-child .title-wrap div:nth-child(3),
    // .table-layout:first-child .tr-top>div:nth-child(3){
    //     width: 20%;
    // }
    // .table-layout:first-child .tr-top>div:nth-child(3){
    //     vertical-align:super
    // }
    // .table-layout:first-child .title-wrap div:nth-child(4),
    // .table-layout:first-child .tr-top>div:nth-child(4){
    //     width: 20%;
    //     vertical-align:text-bottom
    // }
    // .table-layout:first-child .tr-top>div:nth-child(4)>div{
    //     margin-bottom:5px;
    // }

    
    .tr-top .td-box:nth-child(4) div label{
        min-width:60px;
        margin-right: 5px;   
    }

    .table-layout .tr-top{
        display: flex;
        justify-content: space-around;
        width: 100%;
        border-top: 0.5px solid #0B999C;
    }

    .table-layout .tr-top .td-box{
        width: 100%;
        display:block;
        padding : 10px 10px;
    }
    // .table-layout .tr-top .td-box div:not(:first-child){
    //     width: 100%;
    //     gap: 10px;
    //     display: flex;
    //     align-items: center;
    // }

    .table-layout .tr-top{
        border-bottom: 0.5px solid #BDBDBD
    }

    .table-layout .tr-top .td-box{
        border-right : 0.5px solid #BDBDBD
    }
    .table-layout .tr-top .td-box:last-child{
        border-right: 0
    }

    .table-layout .tr-top .td-box.address div:first-child{
        display: inline-block;
        width: 25%;
        vertical-align : top;
    }
    .table-layout .tr-top .td-box.address div:nth-child(2){
        display: inline-block;
        width: 75%;
    }
    .table-layout .tr-top .td-box.address div:nth-child(2) input:first-child{
        margin-bottom:8px;
    }

    .table-layout .tr-top .td-box.num div input{
        width: 100%;
    }

    .table-layout .tr-top .td-box.people input{
        width: 80%;
        margin-right:10px;
    }
    .table-layout .tr-top .td-box.apply label{
        margin-right: 10px;
        vertical-align: middle;
        color: #494949;
    }


    .table-layout .tr-top .td-box:last-child{
        border-right:0
    }
    .tr-top .td-box:first-child div:first-child button{
        min-width:90px;
    }
    // .table-layout:first-child .tr-top .td-box:first-child div:last-child:before{
    //     content:'';
    //     // margin-left: 105px;
    // }
    .tr-top .td-box:nth-child(1){
        padding: 10px 10px !important;
    }

    .tr-top .td-box:nth-child(2){
        padding: 10px 10px !important;
    }
    .tr-top .td-box:nth-child(2) div{
        display: flex;
        flex-wrap:nowrap
    }
    .tr-top .td-box:nth-child(2) div label{
        min-width:60px;
        margin-right: 5px;   
    }
    .tr-top .td-box:nth-child(2) div:first-child{
        margin-bottom:8px;
    }
    .tr-top .td-box:nth-child(2) div label{
        margin-right: 5px;
    }
    .tr-top .td-box:nth-child(2) div:last-child label{
        margin-right: 7px;
    }
    // .table-layout:last-child .title-wrap div,
    // .table-layout:last-child .tr-top > .td-box{
    //   width: 14.2%;
    // }
    .table-layout:last-child .tr-top .td-box:nth-child(5) label{
        margin-right: 10px;
        vertical-align : middle;
        color: #494949
    }

    .ft-color{
        color: #0B999C;
        font-size: 14px;
        font-weight: 600;
    }
    .table tr td{
        height: 40px;
    }
    input[type="text"],input[type="number"],select{
        width: 100%;
    }
    // .detail-address input{
    //     margin-left: 14%;
    // }
    .text-left{
        font-size: 15px;
        text-align: left;
        padding: 0 30px;
    }
    .text-left label{
        margin-left: 10px;
        color:#494949;
    }
    // input[type="radio"]{
    //     color: #BDBDBD;
    // }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
    //     input[type="text"],input[type="number"],select{
    //         width: 100%;
    //     }
     
    //     .td-box{
    //         padding: 0;
    //         overflow: auto;
    //     }
    //     .td-box div{
    //         dispaly: block;
    //         margin-bottom: 5px;
    //     }
    //     .tr-top{
    //         border-top:0;
    //     }
    //     .table-layout:last-child thead tr{
    //         border-top:0
    //     }
    //     .td-right>td:not(:last-child) {
    //         border-right:0
    //     }
    //     .td-r{
    //         border-right:0;
    //     }
    //     .table.mobile>.table-layout>.title-wrap div:nth-child(4) span{
    //         width:75px;
    //         display:inline-block;
    //     }
    //     .table.mobile .table-layout .title-wrap div,
    //     .table.mobile .table-layout tr-top td-box div{
    //         display: block;
    //         padding: 10px 0;
    //     }
    //     .table.mobile .table-layout .title-wrap div{
    //         //width: 30%;
    //     }
    //     .table-layout{
    //         display: block;
    //     }
    //     .table-layout .tr-top .td-box div:not(:first-child){
    //         width:100%;
    //         gap: 0;
    //     }
    //     .address-btn{
    //         width: 100%;
    //     }
    //     .table-layout .tr-top .td-box div{
    //         gap: 0;
    //     }
    //     .address-btn{
    //         width: 100%;
    //         margin-right: 0;
    //     }
    //     .table-layout .tr-top .td-box{
    //         width: 100%;
    //         display: block;

    //     }
    //     .tr-top .td-box:nth-child(1){
    //         padding: 10px 0 !important;
    //     }

    //     .table.mobile .table-layout .title-wrap{
    //         // width:20%;
    //         display:block;
    //         // float:left;
    //         width:auto;
    //         padding:0;
    //         border-bottom:1px solid #0B999C;
    //         // min-width: 130px;
    //         margin-bottom: 10px;
    //     }   
    //     .table.mobile .table-layout .tr-top{
    //         // width:80%;
    //         display:block;
    //         width:auto;
    //         border:0;
    //         min-width: 130px;
    //     }
    //     .table.mobile .table-layout .tr-top .td-box:first-child div:last-child:before {
    //         content:'';
    //         margin-left: 0;
    //     }
    //     .table.mobile .table-layout .tr-top .td-box:first-child div:last-child input{
    //         width: 100%;
    //     }
    //     .table.mobile .table-layout .title-wrap div{
    //         width: 100%;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     }
    //     .table.mobile .table-layout:first-child .title-wrap div{
    //         height: 70px
    //     }
    //     .table.mobile .table-layout:first-child .tr-top >div{
    //         height: 93px;
    //         border-right: 0;
    //     }
    //     .table.mobile .table-layout:first-child .tr-top > div:nth-child(3){
    //         height:91px;
    //     }
    //     .table.mobile .table-layout:first-child .title-wrap div:last-child{
    //         height: 100%;
    //     }
    //     .table.mobile .table-layout:first-child .tr-top .td-box div button{
    //         min-width: 84px;
    //     }
    //     .table.mobile .table-layout:first-child .tr-top >div:nth-child(2){
    //         height: 90.5px
    //     }
    //     .table.mobile .table-layout:first-child .tr-top .td-box:last-child{
    //         height: 120px;
    //         padding: 0 0 !important; 
    //     }
        
    //     .table.mobile .table-layout .tr-top .td-box{
    //         display: block;
    //         width: 100%;
    //         border-bottom: 1px solid #CFCFCF
    //     }

    //     // .table.mobile .table-layout .tr-top .td-box:nth-child(2) div{
    //     //     display: flex;
    //     //     flex-wrap:nowrap;
    //     //     padding : 0 10px;
    //     // }
    //     .table.mobile .table-layout .tr-top .td-box:nth-child(2) div label{
    //         min-width: 57px;
    //         margin-right: 5px;
    //     }

    //     .table.mobile .table-layout .tr-top .td-box:first-child div{
    //         display: flex;
    //         padding: 0 10px !important;
    //     }

    //     .table.mobile .table-layout .tr-top .td-box:first-child div:first-child input{
    //         margin-bottom: 8px;
    //     }
        
    //     .table.mobile .table-layout .tr-top .td-box:nth-child(3) div{
    //         display: flex;
    //         flex-wrap:nowrap;
    //         padding : 0 10px;
    //     }

    //     .table.mobile .table-layout:last-child .title-wrap div{
    //         height: 41px
    //     }
    //     .table.mobile .table-layout:last-child .tr-top .td-box{
    //         height: 61px;
    //         border-right:0;
    //     }
    //     .table.mobile .table-layout:last-child .tr-top .td-box:last-child > div{
    //         width: 93%;
    //         display: flex;
    //         padding : 0 10px;
    //     }
    //     .table.mobile .table-layout:last-child .tr-top .td-box:nth-child(5){
    //         height: 62px;
    //     }

    //     .table.mobile .table-layout .tr-top .td-box:nth-child(4) div{
    //         display: flex;
    //         flex-wrap:nowrap;
    //         padding : 0 10px;
    //     }
    //     .table.mobile .table-layout .tr-top .td-box:nth-child(4) div label{
    //         min-width: 57px;
    //         margin-right: 5px;
    //     }
    // }
    // .important-placeholder::placeholder {
    //     color: red;
    // }

        .table.mobile{
            height:1000px;
        }
        .table.mobile .table-layout.table1{
            display : flex;  
            height: 400px;
        }
        .table.mobile .table-layout.table1>div{
            display: block;
            height:100%;
        }
        .table.mobile .table-layout.table1 .title-wrap{
            width:30%;
            border-right: 0.5px solid #bdbdbd;
            vertical-align : middle;
        }

        .table.mobile .table-layout.table1 .title-wrap div{
            height: 35%;
            border-bottom : 0.5px solid #bdbdbd;
            padding:15px 0;
            box-sizing:border-box;
        }
        .table.mobile .table-layout.table1 .title-wrap .he23{
            height: 23%
        }
        .table.mobile .table-layout.table1 .title-wrap .he20{
            height: 20%
        }

        .table.mobile .table-layout.table1 .tr-top{
            width:70%;
            padding:10px 0;
            border:0;
            
        }
        .table.mobile .table-layout.table1 .tr-top>div{
            width:auto;
            height:35%;
            padding:15px 10px !important;
            border:0;
            border-bottom:0.5px solid #bdbdbd;
            box-sizing:border-box;
        }
        .table.mobile .table-layout.table1 .tr-top .he20{
            height:20%;
        }
        .table.mobile .table-layout.table1 .tr-top .he23{
            height:23%;
        }

        .table.mobile .table-layout.table1 .tr-top .td-box.address div{
            display: block;
        }
        .table.mobile .table-layout.table1 .tr-top .td-box.address .addSearch{
            margin : 0 auto 5px;
        }
        .table.mobile .table-layout.table1 .tr-top .td-box.address .addSearchDetail{
            width:100%;
        }


        
        .table.mobile .table-layout.table2{
            display : flex;  
            height: 590px;
        }
        .table.mobile .table-layout.table2>div{
            display: block;
            height:100%;
        }
        .table.mobile .table-layout.table2 .title-wrap{
            width:30%;
            border-right: 0.5px solid #bdbdbd;
            vertical-align : middle;
            padding-bottom: 0
        }

        .table.mobile .table-layout.table2 .title-wrap div{
            height: 14.28%;
            border-bottom : 0.5px solid #bdbdbd;
            padding:15px 0;
            box-sizing:border-box;
        }
        .table.mobile .table-layout.table2 .title-wrap div:last-child{
            padding-bottom:0;
            border-bottom:0;
        }
        .table.mobile .table-layout.table2 .tr-top{
            width:70%;
            padding:10px 0;
            border:0;
            
        }
        .table.mobile .table-layout.table2 .tr-top>div{
            width:auto;
            height:14.28%;
            padding:15px 10px !important;
            border:0;
            border-bottom:0.5px solid #bdbdbd;
            box-sizing:border-box;
        }
        .table.mobile .table-layout.table2 .tr-top>div:last-child{
            padding-bottom: 0 !important; 
            border:0;
        }

        .table.mobile .table-layout.table1 .tr-top .td-box.address div{
            display: block;
        }
        .table.mobile .table-layout.table1 .tr-top .td-box.address .addSearch{
            margin : 0 auto 5px;
        }
        .table.mobile .table-layout.table1 .tr-top .td-box.address .addSearchDetail{
            width:100%;
        }







    }

`;
const Step2Box3 = styled.div`
    .table{
        width: 100%; 
        border-collapse: collapse; 
        text-align: center;
        border-radius: 0.31rem; 
        background-color: #ffffff;
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16);
        margin-bottom: 3.75rem;
    }
    .tr-top{
        border-top: 0.5px solid #0B999C;
    }
    .td-right>td:not(:last-child){
        border-right: 0.5px solid #BDBDBD;
    }
    .td-r{
        border-right: 0.5px solid #BDBDBD;
    }
    .table tr th{
        height: 40px;
        color: #0B999B;
        font-size: 15px;
    }
    .ft-color{
        color: #0B999C;
        font-size: 14px;
        font-weight: 600;
    }
    .table tr td{
        height: 40px;
    }
    input[type="text"],input[type="number"],select{
        width: 80%;
    }
    .detail-address input{
        margin-left: 14%;
    }
    .text-left{
        font-size: 15px;
        text-align: left;
        padding: 0 30px;
    }
    .text-left label{
        margin-left: 10px;
    }
    input[type="radio"]{
        color: #BDBDBD;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px) {
        input[type="text"],input[type="number"],select{
            width: 100%;
        }
        .table.mobile thead{
            display:block;
            float:left;
        }
        .table.mobile tbody{
            display:block;
        }
        .table.mobile thead th{
            height:40px;
            border-bottom:0.5px solid #bdbdbd;
        }
        .table.mobile thead th:last-child{
            border-bottom:0;            
        }
        .table.mobile tbody tr{
            border-top:0;
        }
        .table.mobile tbody tr .inputbox input{
            width:45%
        }
        .table.mobile tbody td{
            height:40px;
            padding: 10px 10px;
            border-bottom:0.5px solid #bdbdbd;
        }
        .table.mobile tbody td:last-child{
            border-bottom:0;
        }
        .td-right>td:not(:last-child){
            border-right:0;
        }
        .table.mobile thead tr th,
        .table.mobile tbody tr td{
            display: block;
        }
        .table.mobile thead tr,
        .table.mobile tbody tr{
            display: block;
            height:auto;   
        }
        .table-layout .tr-top .td-box div{
            width: 100%;
        }
        .table.mobile thead tr th{
            border-right: 1px solid #bdbdbd;
            padding: 10px 10px;
        }
    
        .table tbody tr:hover{
            background:none;
        }
        
        
        
        // .table.mobile thead{
        //     display:inline-block;
        //     width:30%;
        // }
        // .table.mobile thead tr{
        //     height: 100%;
        //     border-right: 0.5px solid #bdbdbd;
            
        // }
        // .table.mobile thead tr th{
        //     display: block;
        //     height: 40px;
        //     padding: 10px 10px;
        //     border-bottom: 0.5px solid #bdbdbd;
        // }
        // .table.mobile thead tr th:last-child{
        //     border-bottom:0;
        // }
        // .table.mobile tbody{
        //     display:inline-block;
        //     width:70%;
        // }
        // .table.mobile tbody tr{
        //     height: 100%;
        //     border-top:0;
        // }
        // .table.mobile tbody tr td{
        //     display: block;
        //     height: 40px;
        //     padding: 10px 10px;
        //     border:0;
        //     border-bottom: 0.5px solid #bdbdbd;
        // }
        // .table.mobile tbody tr td:last-child{
        //     border-bottom: 0
        // }
        



    }
`;

const EntitleWriteStep2 = (props) => {
    const {
        doc_status,

        userInfo,   // 중개사 정보

        cboHousing,
        cboRent,

        broker_company,
        broker_telno,
        broker_reg_no,
        road_addr,
        road_addr_dtl,
        house_type,
        house_area,
        room_count,
        house_selling,
        house_area_over1,
        house_area_over2,
        house_rent_type,
        rent_amt,
        monthly_rent_amt,
        minor_child_cnt,
        estate_no,
        wallpaper_flag,
        possibleRate,       //  지원유형별 지원가능한 부담금비율
        possibleAmt,
        isCalcResult,       //  지원가능 여부
        calcComment,        //  지원가능 여부 코멘트

        winner,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,

        cantModify,

        onChange,
        onChecked,
        s2_onWinnerChange,
        s2_onChangeNum,
        onCalcSupportAmt,
        onPrevClick,
        onNextClick,
        onShowDaumPost,
        handlePostCode,
        onDeleteWrite,
        // onModifyMode,
    } = props;

    return (
        <EntitleWriteStep2Block>
            <fieldset disabled={cantModify}>
                

                <Step2Box2>
                <div>
                    <h2>입주 희망 주택정보</h2>
                </div>
                {/* [Dev] 22.02.22 div태그로 변경 */}
                <div className='table mobile'>
                    {/* table-laout */}
                    <div className='table-layout table1'>
                        <div className="title-wrap">
                            <div>주소</div>
                            <div className="he23" style={{display:'none'}}>면적</div>
                            <div className="he20" style={{display:'none'}}>85m² 초과</div>
                            <div className="he20" style={{display:'none'}}><span>등기부등본 고유번호</span></div>
                        </div>
                        
                        <div className='tr-top'>
                            <div className='td-box address'>
                                <div className='addSearch'>
                                    <button onClick={onShowDaumPost} className="address-btn">주소검색</button>
                                </div>
                                <div className='addSearchDetail'>
                                    <input type="text" name="road_addr" id="road_addr" placeholder='주소는 등기부에 있는 주소로 정확히 작성 요망' value={road_addr} onChange={onChange} readOnly={true} className="mandatory important-placeholder" />
                                    <input type="text" name="road_addr_dtl" id="road_addr_dtl" placeholder='상세주소' value={road_addr_dtl} onChange={onChange} className="mandatory" />
                                </div>
                            </div>
                            <div className='td-box he23 width' style={{display:'none'}}>
                                <div>
                                    <label htmlFor="house_area" className='ft-color'><span>면적(m²)</span></label>
                                    <input type="text" name="house_area" id="house_area" value={house_area} onChange={onChange} className="mandatory"/>
                                </div>
                                <div>
                                    <label htmlFor="room_count" className='ft-color'><span>방(개수)</span></label>
                                    <input type="number" name="room_count" id="room_count" value={room_count} onChange={onChange} className="mandatory"/>
                                </div>
                            </div>
                            <div className="td-box he20 text-left" style={{display:'none'}}>
                                <div>
                                    <input type="checkbox" name="house_area_over1" id="house_area_over1" checked={house_area_over1} onChange={onChecked} />
                                    <label htmlFor="house_area_over1">미성년자녀 3명 이상</label><br/>
                                </div>
                                <div>
                                    <input type="checkbox" name="house_area_over2" id="house_area_over2" checked={house_area_over2} onChange={onChecked} />
                                    <label htmlFor="house_area_over2">가구원수 5명 이상</label>
                                </div>
                            </div>
                            <div className='td-box he20 num' style={{display:'none'}}>
                                <div>
                                    <label htmlFor="estate_no" className='ft-color'><span>고유번호</span></label>
                                    <input type="text" name="estate_no" id="estate_no" value={estate_no} onChange={onChange}/>
                                </div>
                            </div>
                        </div>
                    </div> {/* table-laout */}

                    {/* table-laout */}
                    {/* <div className='table-layout'>
                        <div className="title-wrap">
                            <div>면적</div>
                            <div>85m² 초과</div>
                            <div><span>등기부등본 고유번호</span></div>
                        </div>
                        
                        <div className='tr-top'>
                            <div className='td-box'>
                                <div>
                                    <label htmlFor="house_area" clazssName='ft-color'><span>면적(m²)</span></label>
                                    <input type="text" name="house_area" id="house_area" value={house_area} onChange={onChange} className="mandatory" style={{width:'100px'}} />
                                </div>
                                <div>
                                    <label htmlFor="room_count" className='ft-color'><span>방(개수)</span></label>
                                    <input type="number" name="room_count" id="room_count" value={room_count} onChange={onChange} className="mandatory" style={{width:'100px'}} />
                                </div>
                            </div>
                            <div className="td-box text-left">
                                <div>
                                    <input type="checkbox" name="house_area_over1" id="house_area_over1" checked={house_area_over1} onChange={onChecked} />
                                    <label htmlFor="house_area_over1">미성년자녀 3명 이상</label><br/>
                                </div>
                                <div>
                                    <input type="checkbox" name="house_area_over2" id="house_area_over2" checked={house_area_over2} onChange={onChecked} />
                                    <label htmlFor="house_area_over2">가구원수 5명 이상</label>
                                </div>
                            </div>
                            <div className='td-box'>
                                <div>
                                    <label htmlFor="estate_no" className='ft-color'><span>고유번호</span></label>
                                    <input type="text" name="estate_no" id="estate_no" value={estate_no} onChange={onChange} style={{width:'150px'}} />
                                </div>
                            </div>
                        </div>
                    </div>  */}
                    {/* table-laout */}
                    
                    {/* table-laout */}
                    <div className='table-layout table2' style={{display:'none'}}> 
                        <div className="title-wrap">
                            <div><span className='in-tb'>주택유형</span></div>
                            <div><span className='in-tb'>전세유형</span></div>
                            <div><span className='in-tb'>전세금액</span></div>
                            <div><span className='in-tb'>월세금액</span></div>
                            <div><span className='in-tb'>매매중여부</span></div>
                            <div><span className='in-tb'>미성년자녀 수</span></div>
                            <div><span className='in-tb'>도배장판 신청</span></div>
                        </div>
                        <div className='tr-top'>
                            <div className='td-box'>
                                <div>
                                    <select name="house_type" id="house_type" value={house_type} onChange={onChange}>
                                        {cboHousing.map((item, idx) => (
                                            <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='td-box'>
                                <div>
                                    <select name="house_rent_type" id="house_rent_type" value={house_rent_type} onChange={onChange}>
                                        {cboRent.map((item, idx) => (
                                            <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='td-box'>
                                <div>
                                    <input type="text" name="rent_amt" id="rent_amt" placeholder='전세금액' value={rent_amt} onChange={s2_onChangeNum} className="mandatory" />
                                </div>
                            </div>
                            <div className='td-box'>
                                <div>
                                    <input type="text" name="monthly_rent_amt" id="monthly_rent_amt" placeholder='월세금액' value={monthly_rent_amt} onChange={s2_onChangeNum} disabled={house_rent_type === "TYPE01" ? "disabled" : ""} readOnly={house_rent_type === "TYPE01" ? true : false} className={house_rent_type === "TYPE01" ? "mandatory" : ""}/>
                                </div>
                            </div>
                            <div className='td-box'>
                                <input type="radio" name="house_selling" id="house_selling_Y" value={"Y"} checked={house_selling === "Y"} onChange={onChange} />
                                <label htmlFor="house_selling_Y">예</label>
                                <input type="radio" name="house_selling" id="house_selling_N" value={"N"} checked={house_selling === "N"} onChange={onChange} />
                                <label htmlFor="house_selling_N">아니오</label>
                            </div>
                            <div className='td-box people'>
                                <input type="text" name="minor_child_cnt" id="minor_child_cnt" value={minor_child_cnt} onChange={s2_onChangeNum}/>명
                            </div>
                            <div className='td-box apply'>
                                <input type="radio" name="wallpaper_flag" id="wallpaper_flag_Y" value={"Y"} checked={wallpaper_flag === "Y"} onChange={onChange} />
                                <label htmlFor="wallpaper_flag_Y">예</label>
                                <input type="radio" name="wallpaper_flag" id="wallpaper_flag_N" value={"N"} checked={wallpaper_flag === "N"} onChange={onChange} />
                                <label htmlFor="wallpaper_flag_N">아니오</label>
                                {
                                    wallpaper_flag === 'Y' &&
                                    <p style={{color:'red'}}>도배장판은 최초 1회만 지원되며<br/>지원 내역 존재시 지원이 불가합니다.</p>
                                }
                            </div>
                        </div>
                    </div>
                    {/* table-laout */}
                </div>
                {/* [Dev] 22.02.22 div태그로 변경 */}
                </Step2Box2>

                <Step2Box1>
                <div>
                    <h2>공인중개사 정보</h2>
                </div>
                <table className='tt mobile step2'>
                    <thead>
                        <tr>
                            <th>업체명</th>
                            <th>전화번호</th>
                            <th>사업자등록번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{!broker_company ? (userInfo.length > 0 ? userInfo[0].user_company : "") : broker_company}</td>
                            <td>{!broker_telno ? (userInfo.length > 0 ? userInfo[0].user_phone : "") : broker_telno}</td>
                            <td>{!broker_reg_no ? (userInfo.length > 0 ? userInfo[0].reg_no : "") : broker_reg_no}</td>
                        </tr>
                    </tbody>
                </table>
                </Step2Box1>

                <Step2Box3 style={{display:'none'}}>
                <table className='table mobile'>
                    <thead>
                        <tr>
                            <th>지원유형</th>
                            <th>성명</th>
                            <th>생년월일</th>
                            <th><span>입주자부담금 비율</span></th>
                            <th>입주자추가부담금</th>
                            <th>입주자부담금</th>
                            <th>월임대료</th>
                            <th>LH지원금</th>
                        </tr>
                    </thead>
                    <tbody>
                        {winner.map((item, idx) => (
                            <tr key={idx} className='tr-top td-right'>
                                <td>{item.winner_type_name}</td>
                                <td>{item.winner_name}</td>
                                <td>{item.winner_no}</td>
                                <td>
                                        {possibleRate.length === 0 && "해당 지원유형은 기본 부담금으로 적용됩니다."}
                                        {possibleRate.map((rate, rateIdx) => (
                                            <Fragment key={rateIdx}>
                                                <input type="radio" name={"charge_rate_" + rate} id={"charge_rate_" + rate} value={rate} checked={String(item.charge_rate) === String(rate)} onChange={s2_onWinnerChange.bind(this, item, 'charge_rate')} />
                                                <label htmlFor={"charge_rate_" + rate}>{rate}%</label>
                                            </Fragment>
                                        ))}
                                        {
                                            //  입주자부담금에 비율과 금액이 둘 다 있다면 사용자에게 선택권을 준다.
                                            //  금액을 선택했다면 charge_rate에는 -1을 저장한다.
                                            (possibleRate.length > 0 && +possibleAmt > 0) &&
                                            <Fragment>
                                                <input type="radio" name="isBaseCharge" id="isBaseCharge" value="-1" checked={item.charge_rate === "-1"} onChange={s2_onWinnerChange.bind(this, item, 'charge_rate')} />
                                                <label htmlFor="isBaseCharge">기본부담금</label>
                                            </Fragment>
                                        }
                                </td>
                                <td className="inputbox"><input type="text" name="add_charges" id="add_charges" placeholder='입주자추가부담금' value={item.add_charges} onChange={s2_onWinnerChange.bind(this, item, '')} /></td>
                                <td>{item.winner_charges}</td>
                                <td>{item.monthly_rent}</td>
                                <td>{item.lh_support_amt}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </Step2Box3>

                <div className='calc-center' style={{display:'none'}}>
                    <div className={"calcResult calc" + isCalcResult}>
                        <h4>
                            {isCalcResult === "POSSIBLE" && "■ 지원가능"}
                            {isCalcResult === "IMPOSSIBLE" && "■ 지원불가"}
                        </h4>
                        <pre>{calcComment}</pre>
                    </div>
                    <button onClick={onCalcSupportAmt} className="support-cc-btn">지원금 계산</button>
                </div>
            </fieldset>

            {isShowDaumPost &&
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={handlePostCode}/>
                    <button className='bdnone-cancel-btn' onClick={onShowDaumPost}>닫기</button>
                </div>
            }

            <div className='StepButtons'>
                {   //  작성중 상태에서만 삭제가 가능.
                    doc_status === 'ENWRITE' && <button onClick={onDeleteWrite} className="cancel-btn">신청서 삭제</button>
                }
                {   //  신청서확인 상태에서만 취소가 가능. 
                    doc_status === 'ENSAVE' && <button onClick={onDeleteWrite} className="cancel-btn">신청서 취소</button>
                }
                {/* {(cantModify && doc_status === 'ENREQ') && <button onClick={onModifyMode} className="save-btn">내용 수정</button>} */}
                <button onClick={onPrevClick} className="prev-btn">이전 단계</button>
                <button onClick={onNextClick} className="next-btn">다음 단계</button>
           </div>
        </EntitleWriteStep2Block>
    );
}

export default EntitleWriteStep2;