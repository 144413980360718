import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';

const INITIALIZE = 'enChangeReq/INITIALIZE';
const CHANGE_VALUE = 'enChangeReq/CHANGE_VALUE';
const [SET_STATUS, SET_STATUS_SUCCESS, SET_STATUS_FAILURE] = createRequestActionTypes('enChangeReq/SET_STATUS');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const setStatus = createAction(SET_STATUS, (body) => (body));

const setStatusSaga = createRequestSaga(SET_STATUS, entitleAPI.status);

export function* enChangeReqSaga() {
    yield takeLatest(SET_STATUS, setStatusSaga);
}

const initialState = {
    isSave: false,
    remark: '',

    enChangeReq: null,
    error: null
};

const enChangeReq = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        //  Status 변경   =======================================================================================
        [SET_STATUS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                isSave: true,
                error: null,
            })
        },
        [SET_STATUS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default enChangeReq;