import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserTypeSelect from '../components/UserTypeSelect';
import dataURL from '../lib/dataURL';

const UserTypeSelectContainer = () => {
    const version = process.env.REACT_APP_VERSION;
    const navigate = useNavigate();
    const onMovePage = loginType => {
        navigate(dataURL.LoginPageMove + loginType);
    }

    const propDatas = {
        version,
        onMovePage,
    }
    return (
        <>
            <UserTypeSelect {...propDatas}/>
        </>
    );
}

export default UserTypeSelectContainer;