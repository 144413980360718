import React from 'react';
import { ToLocaleString } from '../../common';

const MultiBPricePrint = ({ _doc016, work }) => {
    if( !Array.isArray(_doc016) ) {
        return (
            <>
                <div style={{pageBreakBefore:'always'}}>
                    API 정상동작 되지 않음 ({_doc016})
                </div>
            </>
        )
    }
    return (
        <div className='tableWrap price all' style={{pageBreakBefore:'always'}}>
            <div className='viewerContainer borderwrap bd0'>
                <h2>공동주택 공시가격</h2>
                <div className='address'>
                    <div>물건소재지 : </div>
                    <div>{work && work.jibun_addr ? work.jibun_addr : ''}</div>
                </div>
                <table className="apiTable">
                    <colgroup>
                        <col width=""></col>
                        <col width=""></col>
                        <col width=""></col>
                        <col width="120"></col>
                        <col width=""></col>
                        <col width=""></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>공시기준</th>
                            <th>단지명</th>
                            <th>동명</th>
                            <th>호명</th>
                            <th>전용면적(㎡)</th>
                            <th className="last">공동주택가격(원)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _doc016 &&
                        (
                            _doc016.map((item, idx) => (	
                                <tr key={idx}>
                                    <td>{item.NOTICE_DATE}</td>
                                    <td>{item.RESULT_APT_NAME}</td>
                                    <td>{item.DONG_NAME}</td>
                                    <td>{item.HO_NAME}</td>
                                    <td>{item.USE_AREA}</td>
                                    <td>{ToLocaleString(item.APT_PRICE)}</td>
                                </tr>
                            ))
                        )
                    }
                    </tbody>
                </table>
                {/* <div className='openName'>열람인</div>
                <div className='useType'>
                    <div>용도</div>
                    <div>공시가격 열람용</div>
                </div>
                <div className='precaWrap'>
                    <div>주의사항</div>
                    <div className='preca'>
                        본 출력물은 열람용이므로 법적인 효력이 없으며, 위 주택과 열람인과 이혜관계를 증빙 할 수 없습니다.<br/>
                        공적용도의 발급을 원하시면 시ㆍ군ㆍ구청장이 발급하는 「공동주택가격 확인서」를 발급받으시기 바랍니다.
                    </div>
                </div> */}
                {/* 주택가격정보 변경 전 */}
                    {/* {
                        _doc016 &&
                        (
                            _doc016.map((item, idx) => (
                                <table key={idx} className="apiTable">
                                    <tbody>
                                        <tr>
                                            <th>LAND_AREA</th>
                                            <td>{item.LAND_AREA}</td>
                                        </tr>
                                        <tr>
                                            <th>BUILDING_AREA</th>
                                            <td>{item.BUILDING_AREA}</td>
                                        </tr>
                                        <tr>
                                            <th>LOCATION</th>
                                            <td>{item.LOCATION}</td>
                                        </tr>
                                        <tr>
                                            <th>BUILDING_AREA_CALCULATION</th>
                                            <td>{item.BUILDING_AREA_CALCULATION}</td>
                                        </tr>
                                        <tr>
                                            <th>LAND_AREA_CALCULATION</th>
                                            <td>{item.LAND_AREA_CALCULATION}</td>
                                        </tr>
                                        <tr>
                                            <th>JIBUN_BLDG_NO</th>
                                            <td>{item.JIBUN_BLDG_NO}</td>
                                        </tr>
                                        <tr>
                                            <th>PRICE_BASIC_DATE</th>
                                            <td>{item.PRICE_BASIC_DATE}</td>
                                        </tr>
                                        <tr>
                                            <th>OFFICIAL_DATEE</th>
                                            <td>{item.OFFICIAL_DATEE}</td>
                                        </tr>
                                        <tr>
                                            <th>BASIC_DATE</th>
                                            <td>{item.BASIC_DATE}</td>
                                        </tr>
                                        <tr>
                                            <th>OFFICIAL_LAND_PRICE</th>
                                            <td>{item.OFFICIAL_LAND_PRICE}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))
                        )
                } */}
                {/* 주택가격정보 변경 전 */}
            </div>
        </div>
    );
}

export default MultiBPricePrint;