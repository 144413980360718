import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'optionMgt/INITIALIZE';
const CHANGE_VALUE = 'optionMgt/CHANGE_VALUE';
const [GET_DECIDE, GET_DECIDE_SUCCESS, GET_DECIDE_FAILURE] = createRequestActionTypes('optionMgt/GET_DECIDE');
const [SAVE_DECIDE, SAVE_DECIDE_SUCCESS, SAVE_DECIDE_FAILURE] = createRequestActionTypes('optionMgt/SAVE_DECIDE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getDecide = createAction(GET_DECIDE, (body) => (body));
export const saveDecide = createAction(SAVE_DECIDE, (body) => (body));

const getDecideSaga = createRequestSaga(GET_DECIDE, adminAPI.code_search);
const saveDecideSaga = createRequestSaga(SAVE_DECIDE, adminAPI.decide_date);

export function* optionMgtSaga() {
    yield takeLatest(GET_DECIDE, getDecideSaga);
    yield takeLatest(SAVE_DECIDE, saveDecideSaga);
}

const initialState = {
    decide_date: '',

    optionMgt: null,
    error: null
};

const optionMgt = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [GET_DECIDE_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                decide_date: payload[0].code_name,
            }
        },
        [GET_DECIDE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [SAVE_DECIDE_SUCCESS]: (state, {payload}) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_DECIDE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default optionMgt;