import React, { Fragment } from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';
import EntitleViewerContainer from '../containers/EntitleViewrContainer';
//import ContractPrintContainer from '../containers/Modal/ContractPrintContainer';
import EnChangeReqContainer from '../containers/Modal/EnChangeReqContainer';
import CoDocUploadContainer from '../containers/Modal/CoDocUploadContainer';
import ContractDocPrintContainer from '../containers/ContractDocPrintContainer';
import dataWORD from '../lib/dataWord';
import rotate from '../rotation.png';
import savedisk from '../savedisk.png';
import { ShowTelDash } from '../lib/dataUTIL';

const ContractBlock = styled.div`
    width: 100%;
    height: 93.5vh;
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 20px 40px 20px 35px;
    box-sizing: border-box;
    position:relative;

    .divContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        position:relative;
        // top:-30px
    }
    .divContainer h4 {
        color: #545454;
        font-size: 20px;
        display: flex;
        align-items: center;
        margin: 15px 0 5px 0;
    }
    .divContainer h4 button{
        margin-left: 5px;
    }
    .divContainer > div {
        
    }
    .board-form select,  
    .board-form input[type="text"],
    .board-form input[type="date"],
    .board-form input[type="datetime-local"]{
        border: none;
        width: 100%;
        height: 100%;
    }
    input[type="date"],
    .board-form input[type="datetime-local"]{
        text-align: center;
        width: 100%;
        height: 33px;
    }
    .board-form td{
        padding: 0;
    }
    .divWrite {
        width: 56%;
        padding-right: 20px;
        overflow-y: auto;
        padding-bottom: 20px !important;
    }
    .divWrite table {
        font-size: 12px;
        table-layout : auto;
    }
    .divWrite table th, .divWrite table td {
        height: 25px;
        min-width: 20px;
        text-align: center;
    }
    .divWrite table th {
        min-width: 100px;
    }
    .divWrite table input {
        width: 85%;
    }
    .divWrite table input[type="radio"] {
        width: 10px;
        margin-right: 10px;
    }
    .flexDiv {
        display: flex;
        flex-wrap: nowrap;
    }
    .flexDiv table {
        margin: 5px 10px 0 0;
    }
    .flexDiv table:last-child {
        margin-right:0;
    }
    .divCollateral {
        display: flex;
        flex-direction: column;
        padding: 5px;
    }
    .divCollateral > div {
        text-align: left;
    }
    .tdLeft {
        text-align: left;
    }

    .folderBox .folder-on {
        color: #ffffff; 
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }

    .enReference {
        position: relative;
        width: 44%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .refTab {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid black;
    }
    .refTab > div {
        color: #ffffff;
        padding: 5px 10px;
        cursor: pointer;
        background-color: #BDBDBD;
    }
    .refTabOn {
        color: #ffffff;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C); 
        box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 
    }
    .refTab .refreshIcon {
        margin-left: 0;
        padding: 4px 10px;
    }
    .refTab .refreshIcon:hover {
        color: yellow;
    }
    .showRef {
        display: flex;
    }
    .hideRef {
        display: none;
    }
    .divRef {
        position: relative;
        width: 100%;
        height: 71vh;
        // display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-color:#fff
    }

    .divRef .viewArea {
        width: 100%;
        // height: 100%;
        height:67vh;
        position: relative;
        overflow-y: auto;
    }
    .divRef .imgView {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden !important;
    }
    .divRef .selete-thumbnail {
        border: 3px solid red;
    }
    .selectTxt {
        width: 100%;
        height: 100%;
    }

    .add-btn{
        min-width:80px;
        margin-left:5px;
    }
    .board-form +.add-btn{
        min-width: 150px;
    }
    .board-form table tr td label{
        margin-right: 3px;
    }
    .board-form table td{
        position: relative;
    }
    .board-form table input[type="text"]{
        position: absolute;
        top: 0;
        left: 0;
    }
    .dkgreen-btn{
        position: absolute;
        top: -1px;
        right: -1px;
        font-size: 12px;
        font-weight: 500;
        height: 35px;
        box-sizing: border-box;
        margin-left: 5px;
    }
    .cancelContract {
        color: white;
        font-size: 25px;
        line-height: 32px;
    }

    .divContainer.cont{
        display:block;
        position:relative;
        overflow-x:auto;
    }
    .divContainer.wrap{
        min-width:1600px;
    }

    .icon-rotate, .icon-savedisk {
        position: absolute;
        z-index: 1;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 20%;
        cursor: pointer;
        opacity: 0.2;
    }
    .icon-rotate {
        top: 10px;
        right: 155px;
    }
    .icon-savedisk {
        top: 50px;
        right: 155px;
    }
    .icon-rotate:hover, .icon-savedisk:hover {
        background-color: orange;
        opacity: 1;
    }
    #div-rotate {
        position: absolute;
        width: -webkit-calc(100% - 145px);
        height: 100%;
    }
    .rotate-0 { transform: rotate(0deg); }
    .rotate-90 { transform: rotate(90deg); }
    .rotate-180 { transform: rotate(180deg); }
    .rotate-270 { transform: rotate(270deg); }
`;

const Contract = (props) => {
    const {
        user,
        docNo,
        doc_status,

        lessor,

        lessee_name,
        lessee_addr,
        lessee_reg_no,
        lessee_tel,

        winner,

        broker1_name,
        broker1_owner,
        broker1_addr,
        broker1_reg_no,
        broker1_tel,
        broker2_name,
        broker2_owner,
        broker2_addr,
        broker2_reg_no,
        broker2_tel,

        contract_date,
        // contract_location,
        real_cont_date,
        cont_status,

        confirm_date,
        against_date,
        delivery_date,
        cont_break,
        wallpaper_flag,

        road_addr,
        jibun_addr,
        house_type,
        house_area,
        ground_area,
        share_area,
        room_count,
        welfare_type,
        collateral_flag,
        collateral_type,
        collateral_amt,
        collateral_date,

        rent_amt,
        rent_amt_Han,
        monthly_rent_amt,
        monthly_rent_amt_Han,
        // month_amt_pay_day,
        lease_start_date,
        lease_end_date,
        down_payment,
        down_payment_Han,
        interim_payment,
        interim_payment_Han,
        interim_payment_date,
        balance1,
        balance1_Han,
        balance2,
        balance2_Han,
        balance_payment_date,
        account_num,
        bank_name,
        account_holder,

        remark,

        //  중개사2 Show/Hide
        isShowBroker2,
        
        //  모달
        isShowDocUpload,
        onUploadComplete,
        
        //  서류폴더, 서류이미지
        refType,
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        selectTxtFolder,
        txtFolder,
        alreadyFileList,
        onWaiting,
        onImgRotation,
        onImgRotateSave,
        onRefreshImg,

        //  폴더 슬라이드 위치
        imgFolderDiv,
        imgFolderSlider,
        txtFolderDiv,
        txtFolderSlider,

        //전체출력
        contractPrintModal,
        setContractPrintModal,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,

        onChange,
        onChangeSave,
        // onChangeNum,

        onAddRessor,
        onRemoveRessor,
        onChangeRessor,
        onShowDaumPost,
        handlePostCode,

        // onShowBroker2,

        //  검토서 변경요청
        isShowEnChange,
        setIsShowEnChange,
        onEnChangeComplete,

        // onChangeWinner,

        onStartClick,
        onPrintClick,
        onDocUploadClick,
        onSaveClick,
        onCancelClick,
        onCompleteClick,
        onEnChangeClick,
        onRemoveEntitle,

        onRefChange,
        onSelectFolder,
        onSelectTxtFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,
    } = props;

    const propDatas = {
        docNo,
        
        lessor,

        lessee_name,
        lessee_addr,
        lessee_reg_no,
        lessee_tel,

        winner,

        broker1_name,
        broker1_owner,
        broker1_addr,
        broker1_reg_no,
        broker1_tel,
        broker2_name,
        broker2_owner,
        broker2_addr,
        broker2_reg_no,
        broker2_tel,
        isShowBroker2,

        contract_date,

        road_addr,
        jibun_addr,
        house_type,
        house_area,
        ground_area,
        share_area,
        room_count,
        welfare_type,
        collateral_flag,
        collateral_type,
        collateral_amt,
        collateral_date,

        rent_amt,
        rent_amt_Han,
        monthly_rent_amt,
        monthly_rent_amt_Han,
        lease_start_date,
        lease_end_date,
        down_payment,
        down_payment_Han,
        interim_payment,
        interim_payment_Han,
        interim_payment_date,
        balance1,
        balance1_Han,
        balance2,
        balance2_Han,
        balance_payment_date,
        account_num,
        bank_name,
        account_holder,

        onDocUploadClick,
        onUploadComplete,
        alreadyFileList,

        contractPrintModal,
        setContractPrintModal,
    }

    const enChangeDatas = {
        //  검토서 변경요청
        docNo,
        doc_status,
        setIsShowEnChange,
        onEnChangeComplete,
    }

    //  [DATA 정보]
    const viewerDatas = {
        docNo,
        selectTxtFolder,
        onPrintClick,
    }

    return (
        <ContractBlock>
            <div className="divHeader-button"> {/* [Dev] 22.02.16 divHeader-button class변경*/}
                {(doc_status === "ENAPVCHK" || doc_status === "CONTFAIL") &&
                    <button onClick={onStartClick}>계약진행시작</button>
                }
                {(doc_status === "CONTSTRT" || doc_status === "CONTOK" || doc_status === "CONTFAIL") &&
                    <button onClick={onPrintClick}>전체출력</button>
                }
                {doc_status === "CONTSTRT" && <>
                    <button onClick={onDocUploadClick}>업로드</button>
                    <button onClick={onSaveClick}>저장</button>
                </>}
                {doc_status === "CONTSTRT" && <>
                    <button onClick={onCancelClick}>계약취소</button>
                    <button onClick={onCompleteClick}>계약완료</button>
                </>}
                {(doc_status === "CONTSTRT" || doc_status === "CONTOK" || doc_status === "CONTFAIL") &&
                    <button onClick={onEnChangeClick}>검토서 변경요청</button>
                }
                {doc_status === "CONTFAIL" &&
                    <span className='cancelContract'>취소된 계약건입니다</span>
                }
                {user && user.user_auth === 'ENADMIN' && <button onClick={onRemoveEntitle}>검토서 삭제</button>}
                <select id='cont_status' name='cont_status' value={cont_status} onChange={onChangeSave}>
                    <option value="">Default</option>
                    <option value="crimson">RED</option>
                    <option value="mediumblue">BLUE</option>
                    <option value="darkorange">ORANGE</option>
                    <option value="mediumAquaMarine">AQUA</option>
                    <option value="orchid">VIOLET</option>
                </select>
            </div>

            <div className='divContainer cont'>
                <div className='divContainer wrap'>
                <div className='divWrite'>
                    <div>
                        <h4>계약당사자 <button className="add-btn"onClick={onAddRessor}>임대인 추가</button></h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                {lessor.map((item, idx) => (
                                    item.use_flag === "Y" &&
                                    <Fragment key={idx}>
                                        <tr>
                                            <td style={{width: '150px'}}>
                                                임대인{item.sort + 1}
                                                <button className="del-btn ml5"onClick={onRemoveRessor.bind(this, item)}>삭제</button>
                                            </td>
                                            <th style={{width: '200px'}}>성명(법인명)</th>
                                            <td colSpan={3}>
                                                <input type="text" name="lessor_name" id="lessor_name" value={item.lessor_name} onChange={onChangeRessor.bind(this, item)} />
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))}

                                {winner.map((item, idx) => (
                                    <Fragment key={idx}>
                                        <tr>
                                            <td rowSpan={3}>입주자</td>
                                            <th>성명(법인명)</th>
                                            <td colSpan={3}>{item.winner_name}</td>
                                        </tr>
                                        <tr>
                                            <th>주소(대표사무소 소재지)</th>
                                            <td colSpan={3}>{item.winner_addr}</td>
                                        </tr>
                                        <tr>
                                            <th>{dataWORD.BIRTHDATE}({dataWORD.COMPANYCODE})</th>
                                            <td>{item.winner_no}</td>
                                            <th>전화번호</th>
                                            <td>{ShowTelDash(item.winner_phone)}</td>
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* <div>
                        <h4>2. 개업공인중개사 (*개업공인중개사가 계약서를 작성하는 경우에 해당)</h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                <tr>
                                    <td rowSpan={4}>개업공인<br/>중개사1</td>
                                    <th>사무소 명칭</th>
                                    <td colSpan={3}>{broker1_name}</td>
                                </tr>
                                <tr>
                                    <th>대표자 성명</th>
                                    <td colSpan={3}>{broker1_owner}</td>
                                </tr>
                                <tr>
                                    <th>사무소 소재지</th>
                                    <td colSpan={3}>{broker1_addr}</td>
                                </tr>
                                <tr>
                                    <th>등록번호</th>
                                    <td>{broker1_reg_no}</td>
                                    <th>전화번호</th>
                                    <td>{broker1_tel}</td>
                                </tr>
                                {isShowBroker2 && 
                                <>
                                    <tr>
                                        <td rowSpan={4}>개업공인<br/>중개사2</td>
                                        <th>사무소 명칭</th>
                                        <td colSpan={3}>
                                            <input type="text" name="broker2_name" id="broker2_name" value={broker2_name} onChange={onChange} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>대표자 성명</th>
                                        <td colSpan={3}>
                                            <input type="text" name="broker2_owner" id="broker2_owner" value={broker2_owner} onChange={onChange} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사무소 소재지</th>
                                        <td colSpan={3}>
                                            <input type="text" name="broker2_addr" id="broker2_addr" value={broker2_addr} onChange={onChange} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>등록번호</th>
                                        <td>
                                            <input type="text" name="broker2_reg_no" id="broker2_reg_no" value={broker2_reg_no} onChange={onChange} />
                                        </td>
                                        <th>전화번호</th>
                                        <td>
                                            <input type="text" name="broker2_tel" id="broker2_tel" value={broker2_tel} onChange={onChange} />
                                        </td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                    </div>
                    <button className="add-btn" onClick={onShowBroker2}>개업공인중개사2 {isShowBroker2 ? "삭제" : "추가"}</button> */}

                    <div>
                        <h4>계약 예정</h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>계약일</th>
                                    <th>계약예정일시</th>
                                </tr>
                                <tr>
                                    <td style={{width:"50%"}}>
                                        {/* <input type="text" name="contract_location" id="contract_location" value={contract_location} style={{width:"83%"}} onChange={onChange} />
                                        <button onClick={onShowDaumPost.bind(this, null)} className="dkgreen-btn">주소찾기</button> */}
                                        <input type="date" name="real_cont_date" id="real_cont_date" value={real_cont_date} style={{width:"30%"}} onChange={onChange} className="ml10" max="9999-12-31"/>
                                    </td>
                                    <td style={{width:"50%"}}><input type="datetime-local" name="contract_date" id="contract_date" value={contract_date} onChange={onChange} max="9999-12-31"/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* <div>
                        <h4>계약일
                            <input type="date" name="real_cont_date" id="real_cont_date" value={real_cont_date} style={{width:"30%"}} onChange={onChange} className="ml10" />
                        </h4>
                    </div> */}

                    <div>
                        <h4>확인 정보</h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>확정일자 검인일</th>
                                    <th>대항력조사일</th>
                                    <th>납품일자</th>
                                    <th>계약파기</th>
                                    <th>도배장판</th>
                                </tr>
                                <tr>
                                    <td><input type="date" name="confirm_date" id="confirm_date" value={confirm_date}  onChange={onChange} max="9999-12-31"/></td>
                                    <td><input type="date" name="against_date" id="against_date" value={against_date}  onChange={onChange} max="9999-12-31"/></td>
                                    <td><input type="date" name="delivery_date" id="delivery_date" value={delivery_date} onChange={onChange} max="9999-12-31"/></td>
                                    <td>
                                        <select name="cont_break" id="cont_break" value={cont_break} onChange={onChange}>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select name="wallpaper_flag" id="wallpaper_flag" value={wallpaper_flag} onChange={onChange}>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>메모</th>
                                    <td colSpan={4}><input type="text" name="remark" id="remark" value={remark} onChange={onChange} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h4>주택의 표시</h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}>주택 소재지</th>
                                    <td colSpan={3}>{road_addr}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>{jibun_addr}</td>
                                </tr>
                                {/* <tr>
                                    <th>주택 유형</th>
                                    <td colSpan={3}>
                                        <label htmlFor="house_type_1">아파트</label>
                                        <input type="radio" name="house_type" id="house_type_1" value={"TYPE01"} readOnly={true} checked={house_type === "TYPE01"} />
                                        <label htmlFor="house_type_2">연립</label>
                                        <input type="radio" name="house_type" id="house_type_2" value={"TYPE06"} readOnly={true} checked={house_type === "TYPE06"} />
                                        <label htmlFor="house_type_3">다세대</label>
                                        <input type="radio" name="house_type" id="house_type_3" value={"TYPE05"} readOnly={true} checked={house_type === "TYPE05"} />
                                        <label htmlFor="house_type_4">단독</label>
                                        <input type="radio" name="house_type" id="house_type_4" value={"TYPE02"} readOnly={true} checked={house_type === "TYPE02"} />
                                        <label htmlFor="house_type_5">다가구</label>
                                        <input type="radio" name="house_type" id="house_type_5" value={"TYPE03"} readOnly={true} checked={house_type === "TYPE03"} />
                                        <label htmlFor="house_type_6">오피스텔</label>
                                        <input type="radio" name="house_type" id="house_type_6" value={"TYPE07"} readOnly={true} checked={house_type === "TYPE07"} />
                                        <label htmlFor="house_type_99">그 밖의 주택</label>
                                        <input type="radio" name="house_type" id="house_type_99" value={"TYPE99"} readOnly={true} checked={house_type === "TYPE99"} />
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>주택 면적</th>
                                    <td>주택면적 (전용)</td>
                                    <td>대지면적 (공유지분)</td>
                                    <td>방의 수</td>
                                </tr>
                                <tr>
                                    <td>
                                        {house_area}
                                        <span> m²</span>
                                    </td>
                                    <td>
                                        {ground_area}
                                        <span> m² {'\u00A0\u00A0\u00A0\u00A0\u00A0'} ( </span>
                                        {share_area}
                                        <span> )m²</span>
                                    </td>
                                    <td>
                                        {room_count}
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>주택에 딸린 부대시설·복리시설의 종류</th>
                                    <td colSpan={2}>
                                        <input type="text" name="welfare_type" id="welfare_type" value={welfare_type} onChange={onChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>담보물권<br/>설정 여부</th>
                                    <td>
                                        <label htmlFor="collateral_flag_N">없음</label>
                                        <input type="radio" name="collateral_flag" id="collateral_flag_N" value={"N"} onChange={onChange} checked={collateral_flag === "N"} />
                                    </td>
                                    <td colSpan={2}>
                                        <div className='divCollateral'>
                                            <div>
                                                <label htmlFor="collateral_flag_Y">있음</label>
                                                <input type="radio" name="collateral_flag" id="collateral_flag_Y" value={"Y"} onChange={onChange} checked={collateral_flag === "Y"} />
                                            </div>
                                            <div>
                                                <span>- 담보물권의 종류:</span> <input type="text" name="collateral_type" id="collateral_type" value={collateral_type} style={{width:"25%",marginRight:"10px"}} onChange={onChange} />
                                                <span>- 설정금액:</span> <input type="text" name="collateral_amt" id="collateral_amt" value={collateral_amt} style={{width:"25%"}} onChange={onChangeNum.bind(this, null)} />
                                            </div>
                                            <div>
                                                <span>- 설정일자:</span> <input type="date" name="collateral_date" id="collateral_date" value={collateral_date} style={{width:"25%"}} onChange={onChange} />
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h4>계약조건</h4>
                    </div>
                    <div className='board-form'>
                        <table>
                            <tbody>
                                <tr>
                                    <th>구분</th>
                                    <th colSpan={2}>전세보증금</th>
                                    <th colSpan={3}>월 임차료</th>
                                </tr>
                                <tr>
                                    <th>금액</th>
                                    <td colSpan={2}>
                                        <div>
                                            <span>금</span>
                                            {rent_amt_Han}
                                            <span>원정</span>
                                        </div>
                                        <div>
                                            <span>(￦</span>
                                            {rent_amt}
                                            <span>)</span>
                                        </div>
                                    </td>
                                    <td colSpan={3}>
                                        <div>
                                            <span>금</span>
                                            {monthly_rent_amt_Han}
                                            <span>원정</span>
                                        </div>
                                        <div>
                                            <span>(￦</span>
                                            {monthly_rent_amt}
                                            <span>)</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>임대차<br/>계약기간</th>
                                    <td colSpan={5}>
                                        <input type="date" name="lease_start_date" id="lease_start_date" value={lease_start_date} style={{width:"48.5%"}} onChange={onChange} max="9999-12-31"/>
                                        <span style={{margin:"0 5px"}}>~</span>
                                        <input type="date" name="lease_end_date" id="lease_end_date" value={lease_end_date} style={{width:"48.5%"}} onChange={onChange} max="9999-12-31"/>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <th>계약금</th>
                                    <td colSpan={5} className='tdLeft'>
                                        <span>금 </span>
                                        <input type="text" style={{width:"20%"}} value={down_payment_Han} readOnly={true} />
                                        <span> 원정(￦ </span>
                                        <input type="text" name="down_payment" id="down_payment" value={down_payment} style={{width:"20%"}} onChange={onChangeNum.bind(this, 'down_payment_Han')} />
                                        <span> )은 계약 시에 지불</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>중도금</th>
                                    <td colSpan={5} className='tdLeft'>
                                        <span>금 </span>
                                        <input type="text" style={{width:"20%"}} value={interim_payment_Han} readOnly={true} />
                                        <span> 원정(￦ </span>
                                        <input type="text" name="interim_payment" id="interim_payment" value={interim_payment} style={{width:"20%"}} onChange={onChangeNum.bind(this, 'interim_payment_Han')} />
                                        <span> )은 </span>
                                        <input type="date" name="interim_payment_date" id="interim_payment_date" value={interim_payment_date} style={{width:"20%"}} onChange={onChange} />
                                        <span>에 지불</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th rowSpan={2}>잔금</th>
                                    <td colSpan={4} className='tdLeft'>
                                        <span>금 </span>
                                        <input type="text" style={{width:"35%"}} value={balance1_Han} readOnly={true} />
                                        <span> 원정(￦ </span>
                                        <input type="text" name="balance1" id="balance1" value={balance1} style={{width:"35%"}} onChange={onChangeNum.bind(this, 'balance1_Han')} />
                                        <span> )은 </span>
                                    </td>
                                    <td rowSpan={2}>
                                        <input type="date" name="balance_payment_date" id="balance_payment_date" value={balance_payment_date} style={{width:"75%"}} onChange={onChange} />
                                        <span>에 지불</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} className='tdLeft'>
                                        <span>금 </span>
                                        <input type="text" style={{width:"35%"}} value={balance2_Han} readOnly={true} />
                                        <span> 원정(￦ </span>
                                        <input type="text" name="balance2" id="balance2" value={balance2} style={{width:"35%"}} onChange={onChangeNum.bind(this, 'balance2_Han')} />
                                        <span> )은 </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>월임차료</th>
                                    <td colSpan={5} className='tdLeft'>
                                        <span>금 </span>
                                        {monthly_rent_amt_Han}
                                        <span> 원정(￦ </span>
                                        {monthly_rent_amt}
                                        <span> )은 매월 </span>
                                        <input type="text" name="month_amt_pay_day" id="month_amt_pay_day" value={month_amt_pay_day} style={{width:"10%"}} onChange={onChange} />
                                        <span>일에 지불</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>계좌번호</th>
                                    <td><input type="text" name="account_num" id="account_num" value={account_num} onChange={onChange} /></td>
                                    <th>은행</th>
                                    <td><input type="text" name="bank_name" id="bank_name" value={bank_name} onChange={onChange} /></td>
                                    <th>예금주</th>
                                    <td><input type="text" name="account_holder" id="account_holder" value={account_holder} onChange={onChange} /></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>

                    {/* <div>
                        <h4>6. 입주자 계약조건</h4>
                    </div>
                    {winner.map((item, idx) => (
                        <div key={idx} className='board-form'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>입주자 : {item.winner_name}</td>
                                    </tr>
                                    <tr>
                                        <th>구분</th>
                                        <th>금액</th>
                                        <th colSpan={2} style={{width:"50%"}}>지급기한</th>
                                    </tr>
                                    <tr>
                                        <th>전세보증금</th>
                                        <td>{rent_amt_Han} ( {rent_amt} ) 원</td>
                                        <td colSpan={2}>
                                            <input type="date" name="rent_amt_deadline" id="rent_amt_deadline" value={item.rent_amt_deadline} style={{width:"40%"}} onChange={onChangeWinner.bind(this, item)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>전세임대대여금</th>
                                        <td>{item.lh_support_amt_Han} ( {item.lh_support_amt} ) 원</td>
                                        <td colSpan={2}>
                                            <input type="date" name="support_amt_deadline" id="support_amt_deadline" value={item.support_amt_deadline} style={{width:"40%"}} onChange={onChangeWinner.bind(this, item)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th rowSpan={2}>입주자 부담금</th>
                                        <td>기본부담금: {item.winner_charges_Han} ( {item.winner_charges} ) 원</td>
                                        <td colSpan={2}>
                                            <input type="date" name="winner_charge_deadline" id="winner_charge_deadline" value={item.winner_charge_deadline} style={{width:"40%"}} onChange={onChangeWinner.bind(this, item)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>추가부담금: {item.add_charges_Han} ( {item.add_charges} ) 원</td>
                                        <td colSpan={2}>
                                            <input type="number" name="add_charge_deadline" id="add_charge_deadline" value={item.add_charge_deadline} style={{width:"20%"}} onChange={onChangeWinner.bind(this, item)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>임대차 계약기간</th>
                                        <td colSpan={3}>{lease_start_date} ~ {lease_end_date}</td>
                                    </tr>
                                    <tr>
                                        <th>월임대료</th>
                                        <td>{item.monthly_rent_Han} ( {item.monthly_rent} ) 원</td>
                                        <td>납부기한</td>
                                        <td>매월 <input type="text" name="month_amt_pay_day" id="month_amt_pay_day" value={item.month_amt_pay_day} style={{width:"20%"}} onChange={onChangeWinner.bind(this, item)} /> 일</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))} */}
                </div>

                    <div className='enReference'>
                        <div className='refTab'>
                            <div className={(refType ? 'refTabOn' : '')} onClick={() => onRefChange(true)}>
                                <span>첨부 이미지</span>
                                <span onClick={() => onRefreshImg()} className='refreshIcon'>새로고침</span>
                            </div>
                            <div className={(!refType ? 'refTabOn' : '')} onClick={() => onRefChange(false)}>DATA 정보</div>
                        </div>
                        <div className={'divRef ' + (refType ? 'showRef' : 'hideRef')}>
                            <div className='folderBox'>
                                <button onClick={() => onPrevClick('IMG')}>◀</button>
                                <div ref={imgFolderDiv}>
                                    <div className='folderSlider' ref={imgFolderSlider}>
                                        {docFolder.map((item) => (
                                            item.code !== "DOCUDF" &&   //미분류 폴더는 제외
                                            <div key={item.code} onClick={onSelectFolder.bind(this, item)} className={item.code === selectFoler ? 'folder-on' : ''}>{item.name}</div>
                                        ))}
                                    </div>
                                </div>
                                <button onClick={() => onNextClick('IMG')}>▶</button>
                            </div>
                            <div className='viewArea'>
                                <div id='imgView' className='imgView'>
                                    {selectImg && <img src={rotate} alt='방향 전환' title={'방향 전환'} className='icon-rotate' onClick={onImgRotation} />}
                                    {selectImg && selectImg.api_yn === 'N' && <img src={savedisk} alt='방향 저장' title={'방향 저장'} className='icon-savedisk' onClick={onImgRotateSave} />}
                                    {
                                        selectImg && 
                                        <div id='div-rotate' style={{transform:'rotate(' + selectImg.rotation + 'deg)'}}>
                                            {selectImg && <img id="selectImg" className='selectImg' src={selectImg.src} draggable={false} onError={onWaiting} alt=''/>}
                                        </div>
                                    }
                                </div>
                                <div className='navImgBox'>
                                    {docImages.map((item, idx) => (
                                        item.docCode === selectFoler &&
                                        <div key={idx} className='navImg'>
                                            <img src={item.imgSrc} alt="" onClick={onSelectImg.bind(this, item)} onError={onWaiting} className={(item.imgSrc === selectImg.src ? "selete-thumbnail" : "") + (" rotate-" + item.rotation)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={'divRef ' + (!refType ? 'showRef' : 'hideRef')}>
                            <div className='folderBox'>
                                <button onClick={() => onPrevClick('TXT')}>◀</button>
                                <div ref={txtFolderDiv}>
                                    <div className='folderSlider' ref={txtFolderSlider}>
                                        {txtFolder.map((item) => (
                                            <div key={item.code} onClick={onSelectTxtFolder.bind(this, item)} className={item.code === selectTxtFolder ? 'folder-on' : ''}>{item.name}</div>
                                        ))}
                                    </div>
                                </div>
                                <button onClick={() => onNextClick('TXT')}>▶</button>
                            </div>
                            <div className='viewArea'>
                                <div className='selectTxt'>
                                    <EntitleViewerContainer {...viewerDatas} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowDaumPost &&
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={handlePostCode}/>
                    <button className='bdnone-cancel-btn' onClick={onShowDaumPost}>닫기</button>
                </div>
            }
            {isShowDocUpload && <CoDocUploadContainer {...propDatas}/>}
            {isShowEnChange && <EnChangeReqContainer {...enChangeDatas}/>}
            {/* <ContractPrintContainer {...propDatas} /> */}
            <ContractDocPrintContainer {...propDatas}/>
        </ContractBlock>
    );
}

export default Contract;