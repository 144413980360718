import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CoDocUpload from '../../components/Modal/CoDocUpload';
import { addRow, removeRow, rowChangeValue, save, setAlreadyFileList, initialize, getDocType } from '../../modules/Modal/coDocUpload';

const CoDocUploadContainer = (props) => {
    const {
        docNo,
        alreadyFileList,
        onDocUploadClick,
        onUploadComplete,
    } = props;

    const dispatch = useDispatch();
    const {
        doc_type,
        fileLastIdx,
        fileList,
    } = useSelector(({ coDocUpload }) => ({
        doc_type: coDocUpload.doc_type,
        fileLastIdx : coDocUpload.fileLastIdx,
        fileList: coDocUpload.fileList,
    }));

    const onFileChange = (item, e) => {
        for (let idx = 0; idx < e.target.files.length; idx++) {
            let fileIdx = fileLastIdx + idx + 1;
            const file = e.target.files[idx];
            if(!file) return;

            dispatch(addRow({subCode: item.code, codeName: item.name}));

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                dispatch(
                    rowChangeValue({
                        idx: fileIdx,
                        fileReader: reader,
                        fileName: file.name,
                        fileExtension: file.type,
                    })
                );
            }
        }
    }
    // 삭제
    const onRemoveFile = (item) => {
        if(window.confirm(`해당 파일을 삭제하시겠습니까?\n파일명: ${item.file_name}`)) {
            dispatch(
                removeRow({
                    subCode: item.file_type, idx: item.idx
                })
            );
        }
    }
    const onSave = () => {
        doc_type.forEach(_doc => {
            fileList.forEach(_file => {
                if(_doc.code === _file.file_type && _file.file_name) {
                    dispatch(
                        save({
                            doc_id: docNo,
                            files:
                            [
                                {
                                    doc_type: 'CONTRACT',
                                    file_id: _file.file_id,
                                    file_type: _file.file_type,
                                    file_name: _file.file_name,
                                    contenttype: _file.file_extension,
                                    file_data: _file.file_data[0].file_url.split(',').slice(-1)[0],
                                    use_flag: _file.use_flag,
                                }
                            ]
                        })
                    )
                }
            });
        });

        //  부모에게 전달 할 파일목록 :: file_data 변경
        let _fileList = [];
        fileList.forEach(_file => {
            _fileList.push({
                api_yn: _file.api_yn,
                file_data: _file.file_data,
                file_extension: _file.file_extension,
                file_id: _file.file_id,
                file_name: _file.file_name,
                file_type: _file.file_type,
                file_type_name: _file.file_type_name,
                idx: _file.idx,
                use_flag: _file.use_flag,
            })
        });
        onUploadComplete(_fileList);
    }
    useEffect(() => {
        dispatch(getDocType({doc_type: 'CONTRACT'}));
        dispatch(setAlreadyFileList(alreadyFileList));
    }, [dispatch, alreadyFileList])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        doc_type,
        fileList,

        onDocUploadClick,
        onFileChange,
        onRemoveFile,
        onSave,
    }
    return (
        <>
            <CoDocUpload {...propDatas}/>
        </>
    );
}

export default CoDocUploadContainer;