import React from 'react';
import styled from 'styled-components';

const NotFoundBlock = styled.div``;

const NotFound = () => {
    return (
        <NotFoundBlock>
            <h1>NotFound</h1>
        </NotFoundBlock>
    );
}

export default NotFound;