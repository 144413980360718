import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as winnerApi from '../lib/api/winner';
import * as adminAPI from '../lib/api/admin';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { ALERT } from '../lib/dataWord';

// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const INITIALIZE = 'WinnerWork/INITIALIZE';
const CHANGE_INPUT = 'WinnerWork/CHANGE_INPUT';
const CHANGE_TYPEARR = 'WinnerWork/CHANGE_TYPEARR';
const INIT_INPUT = 'WinnerWork/INIT_INPUT';
const SET_INDEX = 'WinnerWork/SET_INDEX';
const CHANGE_SEARCH = 'WinnerWork/CHANGE_SEARCH';
const CHANGE_SEARCH_CHECKBOX = 'WinnerWork/CHANGE_SEARCH_CHECKBOX';
const ADD_MEMBER = 'WinnerWork/ADD_MEMBER';
const DEL_MEMBER = 'WinnerWork/DEL_MEMBER';
const CHANGE_MEMBER = 'WinnerWork/CHANGE_MEMBER';
const SET_PAGING = 'WinnerWork/SET_PAGING';
const SET_SEARCH_VALUE = 'WinnerWork/SET_SEARCH_VALUE';
const CHECK_MRG = 'WinnerWork/CHECK_MRG';
const WIN_REG_RESET = 'WinnerWork/WIN_REG_RESET';




// 엑셀
const SET_EXCEL = 'WinnerWork/SET_EXCEL';
const CHANGE_EXCEL = 'WinnerWork/CHANGE_EXCEL';
const EXCEL_UPLOAD = 'WinnerWork/EXCEL_UPLOAD';
const SET_EXCEL_VALUE_READY = 'WinnerWork/SET_EXCEL_VALUE_READY'; // 데이터 전송하기전 정제작업수행


const [WINNER_WRITE, WINNER_WRITE_SUCCESS, WINNER_WRITE_FAILURE] = createRequestActionTypes('winnerWork/WINNER_WRITE')
const [WINNER_WRITE_EXCEL, WINNER_WRITE_EXCEL_SUCCESS, WINNER_WRITE_EXCEL_FAILURE] = createRequestActionTypes('winnerWork/WINNER_WRITE_EXCEL')
const [WINNER_DETAIL_SEARCH,WINNER_DETAIL_SEARCH_SUCCESS, WINNER_DETAIL_SEARCH_FAILURE] = createRequestActionTypes('winnerWork/WINNER_DETAIL_SEARCH')
const [WINNER_REQ_SEARCH,WINNER_REQ_SEARCH_SUCCESS, WINNER_REQ_SEARCH_FAILURE] = createRequestActionTypes('winnerWork/WINNER_REQ_SEARCH')
const [WINNER_REQUEST,WINNER_REQUEST_SUCCESS, WINNER_REQUEST_FAILURE] = createRequestActionTypes('winnerWork/WINNER_REQUEST')
const [WINNER_DELETE,WINNER_DELETE_SUCCESS, WINNER_DELETE_FAILURE] = createRequestActionTypes('winnerWork/WINNER_DELETE')
const [REQ_APPROVE,REQ_APPROVE_SUCCESS, REQ_APPROVE_FAILURE] = createRequestActionTypes('winnerWork/REQ_APPROVE')
const [SET_VALUE,SET_VALUE_SUCCESS, SET_VALUE_FAILURE] = createRequestActionTypes('winnerWork/SET_VALUE')
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('codeMgt/GET_COMBO');
const [REQ_DELETE, REQ_DELETE_SUCCESS, REQ_DELETE_FAILURE] = createRequestActionTypes('codeMgt/REQ_DELETE');


// const [ACTION, ACTION_SUCCESS, ACTION_FAILURE] = createRequestActionTypes('WinnerWork/ACTION');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('WinnerWork/REACTION');

export const setPaging = createAction(SET_PAGING, (payload) => payload);
export const setSearchValue = createAction(SET_SEARCH_VALUE, (payload) => payload);
export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_INPUT, ({key, value}) => ({key, value}));
export const changeTypeArr = createAction(CHANGE_TYPEARR, ({key, value}) => ({key, value}));
export const initInput = createAction(INIT_INPUT);
export const setIndex = createAction(SET_INDEX, ({idx}) => ({idx}));
export const changeSearch = createAction(CHANGE_SEARCH, ({key, value}) => ({key, value}));
export const changeSearcheckBox = createAction(CHANGE_SEARCH_CHECKBOX, ({checked, key, value}) => ({checked, key, value}));
export const addMember = createAction(ADD_MEMBER);
export const delMember = createAction(DEL_MEMBER, ({targetIdx}) => ({targetIdx}));
export const changeMember = createAction(CHANGE_MEMBER, ({key, value, targetIdx}) => ({key, value, targetIdx}));
export const getCombo = createAction(GET_COMBO);
export const changeMrg = createAction(CHECK_MRG, (body) => (body));
export const reqDelete = createAction(REQ_DELETE, (body) => (body));
export const winRegRest = createAction(WIN_REG_RESET);




export const setExcel = createAction(SET_EXCEL, (body) => (body));
export const changeExcel = createAction(CHANGE_EXCEL, (body) => (body));
export const excelUpload = createAction(EXCEL_UPLOAD, (body) => (body));
export const setExcelReady = createAction(SET_EXCEL_VALUE_READY, (body) => (body));

export const detailSearch = createAction(WINNER_DETAIL_SEARCH, (body) => ({...body}));
export const reqSearch = createAction(WINNER_REQ_SEARCH, (body) => ({...body}));
export const winnerWrite = createAction(WINNER_WRITE, (body) =>  (body));
export const winnerRequest = createAction(WINNER_REQUEST, (body) => ({...body}));
export const winnerDelete = createAction(WINNER_DELETE, (body) => ({...body}));
export const reqApprove = createAction(REQ_APPROVE, ({req_id}) => ({req_id}));
export const setValue = createAction(SET_VALUE, ({winner_id}) => ({winner_id}));
export const winnerWrite_Excel = createAction(WINNER_WRITE_EXCEL, (body) =>  (body));

const winnerWriteSaga = createRequestSaga(WINNER_WRITE, winnerApi.write);
const detailSearchSaga = createRequestSaga(WINNER_DETAIL_SEARCH, winnerApi.detail_search);
const reqSearchSaga = createRequestSaga(WINNER_REQ_SEARCH, winnerApi.req_search);
const winnerRequestSaga = createRequestSaga(WINNER_REQUEST, winnerApi.request);
const winnerDeleteSaga = createRequestSaga(WINNER_DELETE, winnerApi.winner_delete);
const reqApproveSaga = createRequestSaga(REQ_APPROVE, winnerApi.approve);
const setValueSaga = createRequestSaga(SET_VALUE, winnerApi.winner_view);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_search);
const winnerWrite_ExcelSaga = createRequestSaga(WINNER_WRITE_EXCEL, winnerApi.excel_upload);
const reqDeleteSaga = createRequestSaga(REQ_DELETE, winnerApi.req_delete);


// export const action = createAction(ACTION, data => data);

// function* excel_data_uploadSage({type, payload}) {
//     // dispatch 에서 넘긴 값이 담긴곳.
    
//     yield put({
//         type: SET_EXCEL_VALUE_READY,
//         payload: payload.excel
//     })
    // try {
    //     const response =  yield call(winnerApi.write, payload.payload)
    //     yield put({
    //         type: WINNER_WRITE_SUCCESS,
    //         payload: response.data,
    //         meta: response,
    //     })
    // } catch(e) {
    //     yield put({
    //         type: WINNER_WRITE_FAILURE,
    //         payload: e,
    //         error: true,
    //     })
    // }
// }

// const actionSaga = createRequestSaga(ACTION, actionAPI.action);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* WinnerWorkSaga() {
    yield takeLatest(WINNER_WRITE, winnerWriteSaga);
    yield takeLatest(WINNER_DETAIL_SEARCH, detailSearchSaga);
    yield takeLatest(WINNER_REQ_SEARCH, reqSearchSaga);
    yield takeLatest(WINNER_REQUEST, winnerRequestSaga);
    yield takeLatest(WINNER_DELETE, winnerDeleteSaga);
    yield takeLatest(REQ_APPROVE, reqApproveSaga);
    yield takeLatest(SET_VALUE, setValueSaga);
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(WINNER_WRITE_EXCEL, winnerWrite_ExcelSaga);
    yield takeLatest(REQ_DELETE, reqDeleteSaga);
    // yield takeLatest(EXCEL_UPLOAD, excel_data_uploadSage);
    // yield takeLatest(ACTION, actionSaga);
    // yield takeLatest(REACTION, reactionSaga);winnerDeleteSaga
}

// 유형 찾기
const getType = (arr, target, target_dtl) => {
    if(!target && !target_dtl) {
        return '';
    }

    let result = null;
    let result_target = null;

    // 공백을 제거하고 비교 추가
    // target 이랑만 비교
    result_target = arr.find((el) => el.code_name.replaceAll(' ', '') === target.replaceAll(' ', ''));

    // 없으면 target_dtl까지 비교 시작
    if(!result_target) {
        result_target = arr.find((el) => el.code_name.replaceAll(' ', '').search(target.replaceAll(' ', '')) !== -1 && el.code_name.replaceAll(' ', '').search(target_dtl.replaceAll(' ', '')) !== -1);
    }

    if(result_target) {
        result = `${result_target.sub_code}`;
    }
    else {
        result = ``
    }
    return result;
}

const date = new Date();
const year = date.getFullYear();
const month = ("0"+(date.getMonth() + 1)).slice(-2);
const initialState = {

    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    pageCount: null, // 구성할 페이지 수
    max: 5, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1, // 1 2 3 -> 4 5 6 
    currentPage: 1, // 현재 페이지 (page 의 값이 됨.)
    
    winnerList: [],
    winnerReqList: [],
    index: null,
    cboApply: [],
    input: {
        winner_id: "",
        type: "",
        rec_date: "",
        area: "",
        name: "",
        birth: "",
        winner_no: "",
        phone: "",
        tel: "",
        addr_no: "",
        addr: "",
        addr_dtl: "",
        win_type1: "",
        win_rank: "",
        dtl_qualify: "",
        recipient: "",
        final_result: "",
        support_start_date: "",
        support_end_date: "",
        audit_house: "",
        audit_fund: "",
        audit_other_rent: "",
        audit_rent_chk: "",
        remark: "",
        memo: "",
        family_cnt: 1,
        pan_gov: "",
        marital_status: "",
        protect_end_date: "",
        protect_limit: "",
        use_flag: "",
        member_info:[]
    
        // winner_id: "",              
        // type: "",                   
        // rec_date: "",               //-- 접수일자
        // area: "",                   //-- 신청지역
        // name: "",                   //-- 성명
        // birth: "",                  //-- 생년월일
        // winner_no: "",              //-- 주민번호
        // phone: "",                  //-- 핸드폰번호	
        // tel: "",                    //-- 전화번호
        // addr_no: "",                //-- 우편번호
        // addr: "",                   //-- 주소
        // family_cnt: 1,               //-- 세대원수 -> 정수형
        // win_req_date: "",           //-- 자격검증요청일
        // notice_date: "",            //-- 안내문발송일
        // expiration_date: "",        //-- 지원기간만료일
        // audit_house: "",            //-- 자격심사-유주택
        // audit_fund: "",             //-- 자격심사-기금중복
        // audit_contract: "",         //-- 자격심사-중복계약
        // memo: "",                   //-- 비고
        // support_cnt: 1,              //-- 지원대상 -> 정수형
        // protect_end_date: "",       //-- 보호조치종료일
        // use_flag: "",               //-- 사용여부
        // member_info: []             //-- 세대원 정보
    },
    // new Date(search.to_date.split('-')[0], search.to_date.split('-')[1], 0).getDate()
    search: {
        date_type: "REQUEST",
        from_date: `${year - 2}-${month}-01`,
        to_date: `${year}-${month}-${new Date(year, month, 0).getDate()}`,
        req_user: "",
        prc_user: "",
        type: "",
        page: 1,
        status: "",
        status1: "",
        status2: "",
        area:"", 
        name:"", 
        user:""
        // : "",
        // : "",
        // : "",
        // : "",
    },

    // 당첨자 엑셀 관련
    winnerExcel: [],                // 엑셀 데이터
    winnerExcelHeader: [],          // 테이블 표시용
    winnerExcelHeader_true: [],     // 실제 헤더 값
    winnerExcelSend: [],            // 디비에 저장하기 위해 정제된 데이터.
    winnerExcelNodata: {},          // 필수데이터 유무 확인 

    excelReady: false, // 정제가 완료되면 true

    error: null,
    approveSuccess: false,


};
const WinnerWork = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [INIT_INPUT]: (state) => ({
            ...state,
            input: {
                ...initialState.input,
                // type: state.input.type,
            }
        }),
        [SET_INDEX]: (state, {payload : {idx}}) => ({
            ...state,
            index: idx
        }),
        [CHANGE_INPUT]: (state, { payload: {key, value}}) => ({
            ...state,
            input: {...state.input, [key]:value}
        }),
        [CHANGE_TYPEARR]: (state, { payload: {key, value}}) => ({
            ...state,
            cboApply: value,
        }),
        [CHANGE_SEARCH]: (state, { payload: {key, value}}) => ({
            ...state,
            search: {...state.search, [key]:value}
        }),
        [CHANGE_SEARCH_CHECKBOX]: (state, {payload: {value,key,checked}}) => ({
            ...state,
            search: {...state.search, [key]:checked? value : ""}
        }),

        // 
        [WINNER_WRITE_EXCEL_SUCCESS]: (state) => {
            ALERT("당첨자 등록에 성공했습니다.")
            return ({
                ...state,
                excelReady: false,
                error: null,
                winnerExcelUploadSuccess: 'Y'
            })},
        [WINNER_WRITE_EXCEL_FAILURE]: (state, {payload}) => {
            ALERT("당첨자 등록에 실패했습니다.")
            return ({
                ...state,
                excelReady: false,
                error: payload.error,
                winnerExcelUploadSuccess: 'N'
            })},
        
        [WINNER_WRITE_SUCCESS]: (state) => {
            ALERT("당첨자 정보가 저장되었습니다.")
            return ({
                ...state,
                error: null
            })
        },
        [WINNER_WRITE_FAILURE]: (state, {payload}) => {
            ALERT("당첨자 정보 저장을 실패했습니다.")
            return ({
                ...state,
                error: payload.error
            })
        },
        [WINNER_DETAIL_SEARCH_SUCCESS]: (state, {payload}) =>  ({
            ...state,
            winnerList: payload,
            pageCount: payload.length === 0 ? 0 : Math.ceil(payload[0].tot_count / state.maxPageCount),
        }),
        [WINNER_DETAIL_SEARCH_FAILURE]: (state, {payload : error}) => ({
            ...state,
            winnerList: [],
            pageCount: null,
            max: 3,
            step: 1,
            currentPage: 1,
            error
        }),
        [WINNER_REQ_SEARCH_SUCCESS]: (state, {payload}) =>{
        return ({
            ...state,
            winnerReqList: payload,
            pageCount: payload.length === 0 ? 0 : Math.ceil(payload[0].tot_count / state.maxPageCount),
        })},
        [WINNER_REQ_SEARCH_FAILURE]: (state, {payload : error}) => ({
            ...state,
            listArr: [],
            pageCount: null,
            max: 3,
            step: 1,
            currentPage: 1,
            error
        }),

        [WINNER_REQUEST_SUCCESS]:  (state) => {
            ALERT("등록요청에 성공했습니다.")
            return ({
                ...state,
                error: null
            })
        },
        [WINNER_REQUEST_FAILURE]: (state, {payload : error}) => {
            ALERT("등록요청에 실패했습니다.")
            return ({
                ...state,
                error
            })
        },
        [WINNER_DELETE_SUCCESS]: (state) => {
            ALERT("선택 항목이 삭제되었습니다.")
            return ({
                ...state,
                error: null
            })
        }
        ,
        [WINNER_DELETE_FAILURE]: (state, {payload : error}) => {
            ALERT("선택 항목 삭제에 실패했습니다.")
            return ({
                ...state,
                error
            })
        } ,
        [REQ_APPROVE_SUCCESS]: (state) => ({
            ...state,
            error: null,
            approveSuccess: true
        }),
        [REQ_APPROVE_FAILURE]: (state, {payload : error}) => ({
            ...state,
            error,
            approveSuccess: false
        }),
        [ADD_MEMBER]: (state) => ({
            ...state,
            input: {
                ...state.input, 
                family_cnt: state.input.family_cnt + 1,
                member_info: state.input.member_info.concat([{
                    no: "",
                    rel: "",
                    name: ""
            }])}
        }),
        [DEL_MEMBER]: (state, {payload: {targetIdx}}) => ({
            ...state,
            input: {
                ...state.input,
                family_cnt: state.input.family_cnt - 1,
                member_info: state.input.member_info.filter((item, idx) => targetIdx !== idx)
            }
        }),
        [CHANGE_MEMBER]: (state, {payload: {key, value, targetIdx}}) => ({
            ...state,
            input: {
                ...state.input, 
                member_info: state.input.member_info.map((item,idx) => idx === targetIdx? {...item, [key] : value} : item)
            }
        }),
        [SET_VALUE_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                input: { 
                    ...payload.winnerValue[0],
                    dtl_qualify: payload.winnerValue[0].dtl_qualification,
                    create_date: payload.winnerValue[0].create_date? payload.winnerValue[0].create_date.split(' ')[0] : '', // 생성일 (자동생성되서 받음)
                    rec_date: payload.winnerValue[0].rec_date? payload.winnerValue[0].rec_date.split(' ')[0] : '',
                    protect_end_date: payload.winnerValue[0].protect_end_date? payload.winnerValue[0].protect_end_date.split(' ')[0] : '',
                    support_end_date: payload.winnerValue[0].support_end_date? payload.winnerValue[0].support_end_date.split(' ')[0] : '',
                    // : payload.winnerValue[0]..split(' ')[0],
                    // : payload.winnerValue[0]..split(' ')[0],
                    // : payload.winnerValue[0]..split(' ')[0],
                    // : payload.winnerValue[0]..split(' ')[0],

                    // win_req_date: payload.winnerValue[0].win_req_date.split(' ')[0],
                    // notice_date: payload.winnerValue[0].notice_date.split(' ')[0],
                    // expiration_date: payload.winnerValue[0].expiration_date.split(' ')[0],
                    // protect_end_date: payload.winnerValue[0].protect_end_date.split(' ')[0],
                    member_info: payload.familyValue.map((item) => item)
                },
                success: {
                    ...state.success,}
            })
        },
        [SET_VALUE_FAILURE]: (state, {payload}) => {
            return ({
                ...state
            })
        },

        [GET_COMBO_SUCCESS]: (state, {payload}) => {
            let _APPLY_TYPE = [];
            payload.forEach(item => {
                _APPLY_TYPE.push({
                    ...item,
                    checked: true,
                })
            });
            return ({
                ...state,
                cboApply: _APPLY_TYPE
            })
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                cboApply: [],
                error
            }
        },
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                        search: {
                            ...state.search,
                            page: (state.step + 1) * state.max - (state.max - 1)
                        }
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1,
                        search: {
                            ...state.search,
                            page: (state.step - 1) * state.max - (state.max - 1)
                        }
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },
        [SET_SEARCH_VALUE]: (state, {payload}) => ({
            ...state,
            search: payload
        }),
        [CHECK_MRG]: (state, {payload}) => {

            return ({
                ...state
            })
        },
        [WIN_REG_RESET]: (state) => {
            return {
                ...state,
                search: {
                    date_type: "REQUEST",
                    from_date: `${year - 2}-${month}-01`,
                    to_date: `${year}-${month}-${new Date(year, month, 0).getDate()}`,
                    req_user: "",
                    prc_user: "",
                    type: "",
                    page: 1,
                    status: "",
                    status1: "",
                    status2: "",
                    area:"", 
                    name:"", 
                    user:""
                },
            }
        },

        [SET_EXCEL]: (state, {payload: {key, value, header = null}}) => {
            // 엑셀 데이터를 저장 하는 경우 사용자 유형을 찾아서 추가한다.
            if(key === 'winnerExcel') {
                
                return ({
                    ...state,
                    [key]: value.map((item) => ({
                        ...item,
                        type: getType(state.cboApply, item[header[5]], item[header[6]]), // 5 번과 6번 
                    }))
                })
            }
            else {
                return ({
                    ...state,
                    [key]: value
                })
            }
        },
        [CHANGE_EXCEL]: (state, {payload: {key, value, idx}}) => {
            return ({
                ...state,
                winnerExcel: state.winnerExcel.map((item, idxT) =>
                idxT === idx?
                ({
                    ...item,
                    [key]: value
                })
                :
                ({
                    ...item,
                })
                )
            })
        },
        [SET_EXCEL_VALUE_READY]: (state, {payload: {excel, cboApply}}) => {
            let tempArr = [];
            let tempObj = {};

            const header = state.winnerExcelHeader_true;

            /*
            세대원
            성명_1 ~ 성명_11
            신청인과의관계 ~ 신청인과의 관계_10
            주민등록번호 ~ 주민등록번호_10

            -> 배열화 작업
            */
           let target1 = '';
           let target2 = '';
           let target3 = '';
           excel.forEach((el, idx) => {
                tempArr = [];
                for(let i = 0; i <= 10; i++) {
                    target1 = `성명_${i+1}`;
                    target2 = `신청인과의관계${i > 0? `_${i}`: ''}`;
                    target3 = `주민등록번호${i > 0? `_${i}`: ''}`;

                    if(el[target1] && el[target2] && el[target3]) {
                        tempArr.push({
                            no: el[target3].replace('-', ''),
                            rel: el[target2],
                            name: el[target1]
                        })
                    }
                }
                tempObj[idx] = tempArr
            })

            let _winnerExcelSend = [];
            excel.forEach((item, idx) => {
                _winnerExcelSend.push({
                    winner_id: '',
                    type: getType(cboApply, item[header[5]], item[header[6]]), // 5 번과 6번 
                    rec_date: item[header[9]], // 엑셀의 신청일임. 디비에는 접수일이라고 써있음. 9 
                    area: '',
                    name: item[header[0]], // 0
                    winner_no: item[header[1]], // 1
                    birth: item[header[1]], // 1
                    phone: item[header[4]], // 4
                    tel: '',
                    addr_no: '',
                    addr: item[header[2]], // 2
                    addr_dtl: item[header[3]], // 3
                    win_type1: item[header[5]], // 5
                    win_rank: item[header[6]], // 6
                    dtl_qualify: item[header[7]], // 7
                    recipient: item[header[8]], // 8
                    final_result: item[header[10]], // 10
                    support_start_date: '',
                    support_end_date: item[header[11]], // 11
                    audit_house: item[header[13]], // 13
                    audit_fund: item[header[12]], // 12
                    audit_other_rent: item[header[14]], // 14
                    audit_rent_chk: item[header[15]], // 15
                    remark: item[header[16]], // 16
                    memo: item[header[17]], // 17
                    family_cnt: item[header[18]], // 18
                    pan_gov: item[header[19]], // 19
                    marital_status: item[header[20]], // 20
                    protect_end_date: item[header[21]], // 21
                    protect_limit: item[header[22]], // 22
                    use_flag: "Y",
                    member_info: tempObj[idx]
                })
            })

            return({
                ...state,
                excelReady: true,
                winnerExcelSend: _winnerExcelSend,
            })
        },

        [REQ_DELETE_SUCCESS]: (state) => {
            ALERT("현재요청건을 삭제했습니다.");
            return ({
                ...state,
                reqDeleteSuccess: "Y",
            })
        },
        [REQ_DELETE_FAILURE]: (state, { payload: error }) => {
            ALERT('삭제 실패했습니다.')
            return {
                ...state,
                cboApply: [],
                error,
                reqDeleteSuccess: "N",
            }
        },
        
    },
    initialState,
);

export default WinnerWork;