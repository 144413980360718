import client from './client';

export const winner_search = (body) =>
    client.post('/api/entitle/winner_search', body);

export const broker_search = (body) =>
    client.post('/api/entitle/broker_search', body);

export const search = (body) => 
    client.post('/api/entitle/search', body);

export const file_sort = (body) => 
    client.post('/api/entitle/file_sort', body);

export const enwrite_view = (doc_id) => 
    client.get(`/api/entitle/enwrite_view/` + doc_id);

export const enwrite = (body) =>
    client.post('/api/entitle/enwrite', body);

export const enwrite_file = (body) =>
    client.post('/api/entitle/enwrite_file', body);

export const enwrite1 = (body) =>
    client.post('/api/entitle/enwrite1', body);

export const enwrite2 = (body) =>
    client.post('/api/entitle/enwrite2', body);

export const enwrite3 = (body) =>
    client.post('/api/entitle/enwrite3', body);

export const enwrite4 = (body) =>
    client.post('/api/entitle/enwrite4', body);

export const enreq = (body) =>
    client.post('/api/entitle/enreq', body);

export const status = (body) =>
    client.post('/api/entitle/status', body);

export const enprocess = (body) =>
    client.post('/api/entitle/enprocess', body);

export const feedback_req = (body) =>
    client.post('/api/entitle/feedback_req', body);

export const feedback_ans = (body) =>
    client.post('/api/entitle/feedback_ans', body);

export const enprocess_view = (doc_id) => 
    client.get(`/api/entitle/enprocess_view/` + doc_id);

export const enfile_list = (body) => 
    client.post('/api/entitle/enfile_list/', body);

export const feedback_list = (body) =>
    client.post('/api/entitle/feedback_list', body);

export const contract_view = (doc_id) => 
    client.get(`/api/entitle/contract_view/` + doc_id);

export const contract_reg = (body) =>
    client.post('/api/entitle/contract_reg', body);

export const cont_status = (body) =>
    client.post('/api/entitle/cont_status', body);    

export const lhconfirm = (body) =>
    client.post('/api/entitle/lhconfirm', body);

export const fieldchk = (body) =>
    client.post('/api/entitle/fieldchk', body);

export const callmemo = (body) =>
    client.post('/api/entitle/callmemo', body);

export const callmemochk = (body) =>
    client.post('/api/entitle/callmemochk', body);

export const senior_rent = (body) =>
    client.post('/api/entitle/senior_rent', body);

export const feedback_view = (doc_id) => 
    client.get(`/api/entitle/feedback_view/` + doc_id);

export const doc_view = (doc_id) => 
    client.get(`/api/entitle/doc_view/` + doc_id);

export const endelete = (doc_id) => 
    client.delete(`/api/entitle/endelete/` + doc_id);
    
export const enfile_view = (doc_id) => 
    client.get(`/api/entitle/enfile_view/` + doc_id);

export const feedback_cancel = (body) =>
    client.post('/api/entitle/feedback_cancel', body);

export const file_rotation = (body) =>
    client.post('/api/entitle/file_rotation', body);

export const file_view = (body) =>
    client.post('/api/entitle/file_view', body);

export const contract_search = (body) => 
    client.post('/api/entitle/contract_search', body);
    
export const ennotused = (doc_id) => 
    client.delete(`/api/entitle/ennotused/` + doc_id);

export const search_byCust = (data) => 
    client.post('/api/entitle/search_byCust', data);
