import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ApplyDocMgtContainer from '../containers/ApplyDocMgtContainer';
import ApplyMgtContainer from '../containers/ApplyMgtContainer';
import CodeMgtContainer from '../containers/CodeMgtContainer';
import DocMgtContainer from '../containers/DocMgtContainer';
import HeaderContainer from '../containers/HeaderContainer';
import MenuAuthMgtContainer from '../containers/MenuAuthMgtContainer';
import UserMgtContainer from '../containers/UserMgtContainer';
import PrintSortContainer from '../containers/PrintSortContainer';
import OptionMgtContainer from '../containers/OptionMgtContainer';

const AdminPage = () => {
    const CodeMgt = 'CodeMgt';
    const MenuAuthMgt = 'MenuAuthMgt';
    const ApplyMgt = 'ApplyMgt';
    const DocMgt = 'DocMgt';
    const ApplyDocMgt = 'ApplyDocMgt';
    const UserMgt = 'UserMgt';
    const PrintSort = 'PrintSort';
    const OptionMgt = 'OptionMgt';
    const [activeMenu, setActiveMenu] = useState(ApplyMgt);
    const {
        user
    } = useSelector(({user}) => ({
        user: user.user
    }))

    const onMenuClick = (menu) => {
        setActiveMenu(menu);
    }

    return (
        <>
            <HeaderContainer />
            <div className='buttonWrap'>
                {!user ? null : user.user_auth === "ADMIN" && (
                    <>
                        <button onClick={() => onMenuClick(CodeMgt)} className="tab-btn">코드 관리</button>
                        <button onClick={() => onMenuClick(MenuAuthMgt)} className="tab-btn">권한별 메뉴/기능 관리</button>
                    </>
                )}
                <button onClick={() => onMenuClick(ApplyMgt)} className="tab-btn">지원유형 관리</button>
                <button onClick={() => onMenuClick(DocMgt)} className="tab-btn">제출서류 관리</button>
                <button onClick={() => onMenuClick(PrintSort)} className="tab-btn">출력순서 관리</button>
                <button onClick={() => onMenuClick(ApplyDocMgt)} className="tab-btn">유형별 제출서류 관리</button>
                <button onClick={() => onMenuClick(UserMgt)} className="tab-btn">사용자 관리</button>
                <button onClick={() => onMenuClick(OptionMgt)} className="tab-btn">설정 관리</button>
            </div>
            {activeMenu === CodeMgt ? <CodeMgtContainer/> : null}
            {activeMenu === MenuAuthMgt ? <MenuAuthMgtContainer/> : null}
            {activeMenu === ApplyMgt ? <ApplyMgtContainer/> : null}
            {activeMenu === DocMgt ? <DocMgtContainer/> : null}
            {activeMenu === PrintSort ? <PrintSortContainer/> : null}
            {activeMenu === ApplyDocMgt ? <ApplyDocMgtContainer/> : null}
            {activeMenu === UserMgt ? <UserMgtContainer/> : null}
            {activeMenu === OptionMgt ? <OptionMgtContainer /> : null}
        </>
    );
}

export default AdminPage;