import React from 'react';
import styled from 'styled-components';

const EnPriorityHead = styled.div`
    width:1066px;
    position:fixed;
    top:9px;
    z-index:1000;
    height:75px;
    margin-left:194px;
`;

const EnPriorityBlock = styled.div`
        position:absolute;
        top: 0;
        width: 56%;
        height:100%;

    .currentWrapCont{
        background-color: #a1a1a199;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        // display:flex;
        // align-items: center;
        // justify-content: center;
    }

    .currentWrap{
        padding:50px;
        background:#fff;
        border-radius:10px;
        box-shadow: 0 8px 20px rgba(0 0 0 / 16%);
        margin-top:30px;
    }
    .currentWrap h2{
        color: #545454;
        font-size: 26px;
        margin-bottom: 50px;
        text-align: center;
    }

    .currentScroll{
        box-shadow: 0 0.18rem 0.37rem rgb(0 0 0 / 16%);
        max-height: 50vh;
        overflow: auto;
    }

    .currentWrapCont .currentWrap table input{
        text-align: center
    }

    // .popupModal > div:nth-child(2){
    //     width: 80% !important;
    //     padding: 55px 60px;
    //     white-space: nowrap;
    // }

    // .popupModal > div:nth-child(2)>div{
    //     width: 100%;
    // }
    .mandatory {
        background-color: yellow;
    }
    .cantWrite {
        background-color: lightgray;
    }

    .divButtons {
        margin-top: 40px;
    }
    .divButtons button{
        width: 200px;
    }
    

`;

const EnPriority = (props) => {
    const {
        doc_status,
        priority,
        _rent_tot_amt,
        _empty_tot_amt,

        cboEmptyType,

        onCloseClick,
        onDepositSum,
        onSaveClick,
        onAddRow,
        onRemoveRow,
        onRowChange,
        onRowChangeNum,
        onChangeNum,
    } = props;

    return (
        <>
            <EnPriorityHead/>
            <EnPriorityBlock>
                <div className='currentWrapCont'>
                    <div className='currentWrap'>
                        <h2>선순위 임대차 현황</h2>
                        <fieldset disabled={doc_status === 'ENPROCESS' ? false : true} style={{border:'none'}}>
                            <div className='currentScroll'>
                                <table className='tt'>
                                    <thead>
                                        <tr>
                                            <th>{doc_status === 'ENPROCESS' && <button onClick={onAddRow} className="add-btn">추가</button>}</th>
                                            <th>층수</th>
                                            {/* <th>지층</th> */}
                                            <th>호수</th>
                                            <th>공실구분</th>
                                            <th>방개수</th>
                                            <th>보증금</th>
                                            <th>최우선변제보증금</th>
                                            <th>비고</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {priority.map((item, idx) => (
                                            item.use_flag === "Y" &&
                                            <tr key={idx}>
                                                <td>{doc_status === 'ENPROCESS' && <button onClick={onRemoveRow.bind(this, item)} className="del-btn">삭제</button>}</td>
                                                <td><input type="text" name="rent_floor2" id="rent_floor2" placeholder='층수' value={item.rent_floor2} onChange={onRowChange.bind(this, item)} autoComplete="off" className="mandatory" /></td>
                                                {/* <td style={{width:'60px'}}>{item.floor_name}</td> */}
                                                <td><input type="text" name="room_no2" id="room_no2" placeholder='호수' value={item.room_no2} onChange={onRowChange.bind(this, item)} autoComplete="off" className="mandatory" /></td>
                                                <td>
                                                    <select name="empty_type2" id="empty_type2" value={item.empty_type2} onChange={onRowChange.bind(this, item)}>
                                                        {cboEmptyType.map((cbo, idx) => (
                                                            <option key={idx} value={cbo.sub_code}>{cbo.code_name}</option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <input type="number" name="room_cnt2" id="room_cnt2" placeholder='방개수' value={item.room_cnt2} onChange={onRowChange.bind(this, item)}
                                                        autoComplete="off" className={item.empty_type2.indexOf('HOUSE') > -1 ? "mandatory" : "cantWrite"} readOnly={item.empty_type2.indexOf('HOUSE') > -1 ? false : true} />
                                                </td>
                                                <td><input type="text" className="text-r" name="rent_deposit2" id="rent_deposit2" value={item.rent_deposit2} onChange={onRowChangeNum.bind(this, item)} /></td>
                                                <td><input type="text" className="text-r" name="priority_payment2" id="priority_payment2" value={item.priority_payment2} onChange={onRowChangeNum.bind(this, item)} /></td>
                                                <td><input type="text" name="remark2" id="remark2" placeholder='비고' value={item.remark2} onChange={onRowChange.bind(this, item)} autoComplete="off" /></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={2} className="sum">신청한 호수를 제외한 총 보증금</td>
                                            <td colSpan={2}><input type="text" className="text-r" name="_rent_tot_amt" id="_rent_tot_amt" value={_rent_tot_amt} onChange={onChangeNum} /></td>
                                            <td colSpan={2} className="sum">공실 합계</td>
                                            <td colSpan={2}><input type="text" className="text-r" name="_empty_tot_amt" id="_empty_tot_amt" value={_empty_tot_amt} onChange={onChangeNum} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </fieldset>
                        <div className='divButtons'>
                            <button onClick={onCloseClick} className="divB-Cancel">닫기</button>
                            <button onClick={onDepositSum} className="divB-Save">보증금 합계 계산하기</button>
                            {doc_status === 'ENPROCESS' && <button onClick={onSaveClick} className="divB-Save">저장</button>}
                        </div>
                    </div>
                </div>
            </EnPriorityBlock>
        </>
    );
}

export default EnPriority;