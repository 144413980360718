import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import styled from 'styled-components';

const CustomerListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    padding: 40px 50px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }
  
    .address-btn{
        height: 26px !important;
    }

    .cboStDiv {
        position: relative;
        z-index: 999;
    }
    .txtSelectedValues {
        display: flex;
        width: 100%;
        height: 36px;
        padding: 5px 10px;
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .txtSelectedValues div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }
    .cboMultiple {
        position: absolute;
        top: 36px;
        border: 1px solid black;
        list-style: none;
        max-height: 150px;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;
    }
    .cboMultiple li {
        background-color: #ffffff !important;
    }
    .cboMultiple li:hover {
        background-color: #ccc;
    }
    .cboMultiple li input[type="checkbox"] + label:before {
        top: 8px;
        left: 6px;
    }
    .cboMultiple li label {
        background-color: inherit;
        height: auto;
        padding: 5px 5px 5px 25px;
        width: 100%;
        text-align: left;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }
`;

const CustomerList = (props) => {
    const {
        customerList,
        checkedList,
        max, 
        step, 
        currentPage, 
        pageCount,
        search,
        imply,
        next,
        isModal,
        customerDetail,
        cboApply,
        isDaumModal,
        pwdCheck,
        // isShowCboArea,
        // cboAreaData,
        // selectedAreas,

        onSubmit,
        onCheck,
        onCheckAll,
        onChangeSearch,
        onImply,
        onNextWork,
        onClickPrev,
        onClickNum,
        onClickNext,
        onClickList,
        closeModal,
        onImplyAll,
        onNextWorkAll,
        onChangeDetail,
        onComplete,
        onCloseDaum,
        onOpenDaum,
        onClickModi,
        onChangePwdCheck,
        // onShowMultiCbo,
        // onHideMultiCbo,
        // onEnterMultiCbo,
        // onAreaChange,
        // onAreaAll,
        onInitSearch,
    } = props;
    return (
        <CustomerListBlock>
            <h1>고객 리스트</h1>
            <div className='btn-group'>
                {imply? 
                <>
                    <button onClick={onImplyAll}>일괄묵시처리</button>
                    <button onClick={onImply}>선택묵시처리</button>
                </>
                :null}
                {next?
                <>
                    <button onClick={onNextWorkAll}>일괄사후처리</button>
                    <button onClick={onNextWork}>선택사후처리</button>
                </>
                :null}
            </div>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt4'>
                            <div className='st4'>
                                <label htmlFor='date_type'>날짜유형</label>
                                <select name='date_type' id='date_type' value={search.date_type || ''} onChange={onChangeSearch}>
                                    <option value="">전체</option>
                                    <option value="CONTRACT_END_DATE">계약종료일</option>
                                </select>
                                <input name='start_date' id='start_date' value={search.start_date || ''} onChange={onChangeSearch} type='date'/>
                                <span>~</span>
                                <input name='end_date' id='end_date' value={search.end_date || ''} onChange={onChangeSearch} type='date'/>
                            </div>
                            <div className='st4'>
                                <label htmlFor='phone'>핸드폰번호</label>
                                <input name='phone' id='phone' value={search.phone || ''} onChange={onChangeSearch} type='text' className='input-size'/>
                            </div>
                            <div className='st4'>
                                <label htmlFor='name'>이름</label>
                                <input name='name' id='name' value={search.name || ''} onChange={onChangeSearch} type='text' className='input-size'/>
                            </div>
                            {/* <div className='st4'>
                                <label htmlFor='area'>지역</label>
                                <input name='area' id='area' value={search.area || ''} onChange={onChangeSearch} type='text' className='input-size'/>
                            </div> */}

                            {/* 22.04.27 온결 요청으로 고객리스트에서 지역검색 삭제
                                <div className='st4' tabIndex={0} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboArea'>지역</label>
                                <div className='cboStDiv' style={{width:"162px"}}>
                                    <div className='txtSelectedValues' onClick={onShowMultiCbo}>
                                        <div>{selectedAreas || '선택안함'}</div>
                                        ▼
                                    </div>
                                    {isShowCboArea &&
                                        <ul className="cboMultiple" id="cboArea">
                                            <li>
                                                <input type="checkbox" name="areaALL" id="areaALL" checked={cboAreaData.length === cboAreaData.filter(x => x.checked === true).length} onChange={onAreaAll} />
                                                <label htmlFor="areaALL" className='notlabel'>전체 선택</label>
                                            </li>
                                            {cboAreaData.map((item) => (
                                                <li key={item.area}>
                                                    <input type="checkbox" name={item.area} id={item.area} checked={item.checked} onChange={(e) => onAreaChange(item, e)} />
                                                    <label htmlFor={item.area} className='notlabel'>{item.area}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div> */}

                            {/* <div className='st'>
                                <label htmlFor='use_flag'>사용여부</label>
                                <select name='use_flag' id='use_flag' value={search.use_flag || ''} onChange={onChangeSearch}>
                                    <option value="">전체</option>
                                    <option value="Y">사용중</option>
                                    <option value="N">사용종료</option>
                                </select>
                            </div> */}
                        </div>
                        
                         {/* [DEV] 22.03.25 임의로 초기화버튼추가 */}
                        <div className='btn'>
                            <button type='submit' className='green-sch-btn'>검색</button>
                            <button type='button' onClick={onInitSearch} className='reset-btn'>검색조건 초기화</button>
                        </div>
                    </div>
                </form>
            </div>

            <div className='tableCover'>
                <table className='tt'>
                    <thead>
                        <tr>
                            {imply || next? 
                            <th><input id='all' type='checkbox' checked={((customerList.length === checkedList.length) && (checkedList.length > 1)) || ''} onChange={onCheckAll}/><label htmlFor='all'></label></th>
                            : null}
                            <th>번호</th>
                            <th>계약종료일</th>
                            <th>주소</th>
                            <th>생년월일</th>
                            <th>사용자아이디</th>
                            <th>성명</th>
                            <th>핸드폰번호</th>
                            <th>사용자유형</th>
                            <th>권리분석여부</th>
                            <th>묵시대상여부</th>
                            <th>사후관리여부</th>
                            <th>사전대상여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerList.length > 0?
                        <>
                        {customerList.map((item, idx) => {
                            return (
                                <tr key={idx} onClick={(e) => onClickList(e, item)}>
                                    {imply || next? 
                                    <td className='check'><input id={item.cust_id} type='checkbox' checked={checkedList.some((el) => el.cust_id === item.cust_id)? true : false || ''} onChange={(e) => onCheck(e, item)}/><label htmlFor={item.cust_id}></label></td>
                                    : null}
                                    <td>{(30 * (currentPage - 1)) + idx+1}</td>
                                    <td>{item.contract_end_date || '-'}</td>
                                    <td style={{textAlign: "left"}}>{item.cust_addr || '-'}</td>
                                    <td>{item.cust_birth || '-'}</td>
                                    <td>{item.cust_id || '-'}</td>
                                    <td>{item.cust_name || '-'}</td>
                                    <td>{`${item.cust_phone.slice(0,3)}-${item.cust_phone.slice(3,7)}-${item.cust_phone.slice(7)}` || '-'}</td>
                                    <td>{item.type_name || '-'}</td>
                                    <td>{item.entitle_yn || '-'}</td>
                                    <td>{item.imply_yn || '-'}</td>
                                    <td>{item.nextwork_yn || '-'}</td>
                                    <td>{item.prework_yn || '-'}</td>
                                </tr>
                            )
                        })}
                        </>
                        : 
                        <tr>
                            <td colSpan={13}>결과가 없습니다.</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            else {
                                return null;
                            }
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>

                {isModal && customerDetail? 
                <div className='winnerModal'>
                    <div className='backGround' onClick={closeModal}/>
                    <div>
                        <div className='header'>
                            <div/>
                            <h2>사용자 정보 수정</h2>
                        </div>
                        <div className='body'>
                            <h4>사용자정보</h4>
                            <div>
                                <div>
                                    <label htmlFor='cust_name'>이름</label>
                                    <input type='text' name='cust_name' id='cust_name' value={customerDetail.cust_name || ''} placeholder='이름' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='cust_birth'>생년월일</label>
                                    <input type='number' readOnly name='cust_birth' id='cust_birth' value={customerDetail.cust_birth || ''} placeholder='생년월일' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='cust_phone'>핸드폰번호</label>
                                    <input type='number' name='cust_phone' id='cust_phone' value={customerDetail.cust_phone || ''} placeholder='핸드폰번호' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='cust_tel'>전화번호</label>
                                    <input type='number' name='cust_tel' id='cust_tel' value={customerDetail.cust_tel || ''} placeholder='전화번호' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='cust_type'>유형</label>
                                    <select name='cust_type' id='cust_type' value={customerDetail.cust_type || ''} onChange={onChangeDetail} style={{width: "100%"}}>
                                        <option value={''}>선택하세요</option>
                                        {cboApply.length > 0? 
                                        <>
                                        {cboApply.map((item, idx) => {
                                            return <option key={idx} value={item.sub_code}>{item.code_name}</option>
                                        })}
                                        </> : null}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor='contract_end_date'>계약종료일</label>
                                    <input type='date' name='contract_end_date' id='contract_end_date' value={customerDetail.contract_end_date? customerDetail.contract_end_date.split(' ')[0] : ''} placeholder='계약종료일' onChange={onChangeDetail}/>
                                </div>
                            </div>

                            <h4>계좌정보</h4>
                            <div>
                                <div>
                                    <label htmlFor='account_no'>계좌번호</label>
                                    <input type='number' name='account_no' id='account_no' value={customerDetail.account_no || ''} placeholder='계좌번호' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='account_owner'>예금주</label>
                                    <input type='text' name='account_owner' id='account_owner' value={customerDetail.account_owner || ''} placeholder='계좌주' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='bank_name'>은행</label>
                                    <input type='text' name='bank_name' id='bank_name' value={customerDetail.bank_name || ''} placeholder='은행' onChange={onChangeDetail}/>
                                </div>
                            </div>

                            <h4>주소정보</h4>
                            <div>
                                <div>
                                    <div className='label'>주소검색</div>
                                    <button onClick={onOpenDaum} className="address-btn">주소검색</button>
                                </div>
                                <div>
                                    <label htmlFor='addr_no'>우편번호</label>
                                    <input type='number' name='addr_no' readOnly id='addr_no' value={customerDetail.addr_no || ''} placeholder='주소검색을 해주세요' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='addr'>주소</label>
                                    <input type='text' name='addr' className='width2' readOnly id='addr' value={customerDetail.addr || ''} placeholder='주소검색을 해주세요' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='addr_dtl'>상세주소</label>
                                    <input type='text' name='addr_dtl' className='width2' id='addr_dtl' value={customerDetail.addr_dtl || ''} placeholder='상세주소' onChange={onChangeDetail}/>
                                </div>
                            </div>

                            <h4>사용자관리</h4>
                            <div>
                                <div>
                                    <label htmlFor='use_flag'>사용여부</label>
                                    <select name='use_flag' id='use_flag' value={customerDetail.use_flag} onChange={onChangeDetail}>
                                        <option value=''>선택하세요</option>
                                        <option value='Y'>Y</option>
                                        <option value='N'>N</option>
                                    </select>
                                </div>
                            </div>

                            <h4>비밀번호 변경</h4>
                            <div>
                                <div>
                                    <label htmlFor='pwd'>새비밀번호</label>
                                    <input type='text' name='pwd' id='pwd' value={customerDetail.pwd || ''} placeholder='새비밀번호' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label htmlFor='pwd_check'>비밀번호 확인</label>
                                    <input type='text' name='pwd_check' id='pwd_check' value={customerDetail.pwd_check || ''} placeholder='비밀번호 확인' onChange={onChangeDetail}/>
                                </div>
                                <div>
                                    <label>비밀번호 변경 확인</label>
                                    <input type='checkbox' id='pwdCheck' checked={pwdCheck} onChange={onChangePwdCheck}/>
                                    <label htmlFor='pwdCheck'></label>
                                </div>
                            </div>
                        </div>
                        <div className="pop-group-btn">
                            <button onClick={closeModal}>닫기</button>
                            <button onClick={onClickModi}>수정하기</button>
                        </div>
                    </div>
                </div> : null}

                {isDaumModal? 
                <div className='divDaumPostcode'>
                    <DaumPostcode onComplete={onComplete}/>
                    <button className='bdnone-cancel-btn' onClick={onCloseDaum}>닫기</button>
                </div>
            :null}
        </CustomerListBlock>
    );
}

export default CustomerList;