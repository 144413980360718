import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pwValidation } from '../common';
import Mypage from '../components/Mypage';
import { ALERT } from '../lib/dataWord';
import { certChk, certReq, changeRegSuccess, pwdChange, regApi, setTimeValue } from '../modules/auth';
import { custUpdate } from '../modules/customer';
import { add, appuserList, appuserReg, appuserSearch, changeFile, changePwd, changeValue, check, del, down, setTarget, up, changeSearch , initialize, pwdCheck, view, initPwdCheck, getCombo, logout} from '../modules/user';


const MypageContainer = () => {
    const {
        user,
        userInfo,
        result,
        app_check,
        app_normal,
        target,
        user_pwd,
        user_pwd_check,
        pwdChangeSuccess,
        searchApprove,
        loading,
        user_pwd_prev,
        regsuccess,
        pwdCheckResult,
        setTimeStart,
        certSuccess,
        cboApply
    } = useSelector(({user, auth, loading}) => ({
        user: user.user,
        userInfo: user.userInfo,
        result: user.result,
        app_check: user.app_check,
        app_normal: user.app_normal,
        target: user.target,
        user_pwd :user.user_pwd,
        user_pwd_check :user.user_pwd_check,
        pwdChangeSuccess: auth.pwdChangeSuccess,
        successT: user.successT,
        searchApprove: user.searchApprove,
        user_pwd_prev: user.user_pwd_prev,
        regsuccess: auth.regsuccess,
        pwdCheckResult: user.pwdCheckResult,
        setTimeStart: auth.setTimeStart,
        certSuccess: auth.certSuccess,
        cboApply: user.cboApply,
        loading: loading['auth/APPUSER_LIST']
    }))

    const dispatch = useDispatch();
    const location = useLocation();
    const {state: {menu}} = location
    const [isAppAuth, setIsAppAuth] = useState(false);
    const [isPwdModal, setIsPwdModal] = useState(false);
    const [isPhoneModal, setIsPhoneModal] = useState(false);
    const [isShowDaum, setIsShowDaum] = useState(false);

    // 타이머
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const time = useRef(180);
    const interval = useRef(null)


    
    // 비밀번호 변경 모달
    const onClose = useCallback(() => {
        setIsPwdModal(false)
        dispatch(changePwd({key: 'user_pwd_prev', value: ''}))
        dispatch(changePwd({key: 'user_pwd', value: ''}))
        dispatch(changePwd({key: 'user_pwd_check', value: ''}))
    }, [dispatch])

    const onOpen = () => {
        setIsPwdModal(true)
    }

    // 전화번호 변경 모달
    const opOpenPhoneModal = () => {
        setIsPhoneModal(true);
    }

    const onClosePhoneModal = useCallback(() => {
        setIsPhoneModal(false);
        dispatch(changeValue({field: 'userInfo', key: 'new_phone', value: ''}))
        dispatch(changeValue({field: 'userInfo', key: 'cert_num', value: ''}))
    }, [dispatch])

    // 인증번호 발급
    const onClickRequestCert = () => {
        if(!setTimeStart) {
            if(userInfo.new_phone) {
                if(userInfo.new_phone.length !== 11 && userInfo.new_phone.slice(0,3) === '010') {
                    ALERT("핸드폰 번호를 확인하세요")
                }
                else {
                    dispatch(certReq({
                        phone: userInfo.new_phone
                    }))
                    time.current = 180;
                }
            }
            else {
                ALERT("핸드폰 번호를 입력해주세요")
            }
        }
        else {
            ALERT('3분이 지나고 다시 발급받을수 있습니다.');
        }
    }
    //  인증번호 확인
    const onClickCompareCert = () => {
        if(!userInfo.cert_num) {
            ALERT("변경할 휴대전화 번호를 입력해주세요.");
            return;
        }
        if(!userInfo.cert_num) {
            ALERT("수신한 인증번호를 입력해주세요.");
            return;
        }
        if(min <= 0 && sec <= 0) {
            ALERT("인증번호 시간이 만료되었습니다.");
            return;
        }

        dispatch(certChk({
            phone: userInfo.new_phone,
            cert_no: userInfo.cert_num
        }))
    }
    //  휴대전화번호 변경
    const onClickSavePhone = () => {
        if(certSuccess === 'success') {
            dispatch(changeValue({field: 'userInfo', key: 'user_phone', value: userInfo.new_phone}))
            onClosePhoneModal();
        }
        else {
            ALERT("휴대전화 인증을 해주십시오.")
            return;
        }
    }

    const onChange = (e) => {
        const {target: {name, value}} = e
        let valid = true;

        if(name === 'new_phone' && value.length > 11) {valid=false;} 
        if(valid) {
            dispatch(changeValue({field: 'userInfo', key: name, value}));
        }
    }

    // 비밀번호 입력값 변경 함수
    const onChangePwd = (e) => {
        const {target: {name, value}} = e
        dispatch(changePwd({key: name, value}))
    }

    // 기존 비밀번호가 맞으면 비밀번호 변경 진행
    // checkPwd 에서 수행한 기존 비밀번호 확인이 성공하면 실행.
    const ChangePwd = useCallback(() => {
        // 1. 입력값이 있긴 한지
        // 2. 신규 비밀번호, 신규 비밀번호 확인이 맞는지
        // 3. 진짜로 변경할지
        // 4. 변경.

        if(!user_pwd || !user_pwd_check) {
            ALERT('신규 비밀번호를 입력해주세요');
            dispatch(initPwdCheck());
        }
        else {
            if(user_pwd !== user_pwd_check) {
                ALERT('신규 비밀번호와 신규 비밀번호 확인이 일치하지 않습니다.')
                dispatch(initPwdCheck());
            }
            else if(!pwValidation(user_pwd)) {
                ALERT('비밀번호는 영어 대소문자, 숫자, 특수문자로 조합되어야 합니다.\n동일한 문자, 연속된 숫자는 세자리 이상 사용할 수 없습니다.')
                dispatch(initPwdCheck());
            }
            else if(user_pwd_prev === user_pwd) {
                ALERT("기존 비밀번호와 새로운 비밀번호가 동일합니다.\n다른 비밀번호를 입력해주세요.");
                dispatch(initPwdCheck());
            }
            else {
                if(window.confirm('비밀번호를 변경하시겠습니까?')) {
                    dispatch(pwdChange({
                        phone: userInfo.user_id,
                        user_pwd: user_pwd,
                    }))
                    dispatch(initPwdCheck());
                }
                else {
                    dispatch(initPwdCheck());
                }
            }
        }
    }, [dispatch, userInfo, user_pwd_prev, user_pwd, user_pwd_check])
    // 기존 비밀번호 확인
    // 변경하기 버튼 이벤트
    const checkPwd = () => {
        if(user_pwd_prev && pwdCheckResult !== 'OK') {
            dispatch(pwdCheck({
                user_id: userInfo.user_id ,
                user_pwd: user_pwd_prev,
            }));
        }
        else if(!user_pwd_prev){
            ALERT('기존 비밀번호를 입력해주세요')
        }
    }

    // 주소 검색
    const onComplete = (data) => {
        dispatch(changeValue({field: 'userInfo', key: 'addr_no', value: data.zonecode }));
        dispatch(changeValue({field: 'userInfo', key: 'addr', value: data.roadAddress }));

        // 당첨자인 경우 주소 세부 유형 구분.
        if(user.user_auth === "WINNER") {
            dispatch(changeValue({field: 'userInfo', ey: 'sido', value: data.sido }));
            dispatch(changeValue({field: 'userInfo', key: 'sigungu', value: data.sigungu }));
            dispatch(changeValue({field: 'userInfo', key: 'town', value: data.bname }));
            dispatch(changeValue({field: 'userInfo', key: 'jibun_addr', value: (data.jibunAddress ? data.jibunAddress : data.autoJibunAddress) }));
        }
        setIsShowDaum(false)
    }

    const onCloseDaum = () => {
        setIsShowDaum(false)
    }

    const onOpenDaum = () => {
        setIsShowDaum(true)
    }

    // 파일
    const onFileChange = (e) => {
        const file = e.target.files[0];
        const {target: {name}} = e
        if(!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            dispatch(changeFile({
                name,
                file_name: file.name,
                file_data: reader.result,
                contenttype: file.type
            }))
        }
    }


    //----------------------------------------------------------------------
    // 결재라인
    
    // 탭 변경
    const onChangeApp = (e) => {
        const {target: {value}} = e
        dispatch(changeSearch({name: value}))
    }

    // 검색
    const onSearch = (e) => {
        e.preventDefault();
        dispatch(appuserSearch({
            user_id: "", 
            user_name: searchApprove, 
            company:""
        }))
    }

    // 결재자 이동 (상)
    const onUp = () => {
        if(target.item) {
            dispatch(up())
        }
    }

    // 결재자 이동 (하)
    const onDown = () => {
        if(target.item) {
            dispatch(down())
        }
    }

    // 결재자 선택

    const onTarget = (e, idx, item) => {
        const {target: { className }} = e

        // 삭제하는 경우
        if(className) {
            if(window.confirm('삭제하시겠습니까?')) {
                dispatch(del({targetIdx: idx, item: item}));
            }
        }
        // 타겟 설정
        else {
            dispatch(setTarget({item: item, targetIdx: idx}))
        }
    }
    //----------------------------------------------------------------------


    // 정보 변경
    const onSaveUsrInfo = () => {
        const {
            card, 
            business, 
            deduction, 
            use_flag
        } = userInfo

        if(window.confirm("정보를 수정하시겠습니까?")) {
            if(user.user_auth === "WINNER") {
                if(!userInfo.user_phone) {
                    ALERT('핸드폰 번호는 필수 입니다.')
                }
                else  {
                    dispatch(custUpdate({
                        cust_id: '',
                        cust_name: userInfo.user_name || '',
                        phone: userInfo.user_phone || '',
                        tel: userInfo.user_tel || '',
                        type: userInfo.cust_type || '',
                        pwd: userInfo.pwd || '',
                        addr: userInfo.addr || '',
                        addr_dtl: userInfo.addr_dtl || '',
                        sido: userInfo.sido || '',
                        sigungu: userInfo.sigungu || '',
                        town: userInfo.town || '',
                        jibun_addr: userInfo.jibun_addr || '',
                        contract_end_date: userInfo.contract_end_date || '',
                        bank_name: userInfo.bank_name || '',
                        account_no: userInfo.account_no || '',
                        account_owner: userInfo.account_owner || '',
                        use_flag: userInfo.use_flag || '',
                        user_position: userInfo.user_position || '',
                    }))
                    if(certSuccess === 'success') {
                        ALERT('변경내용 저장 후 자동으로 로그인 화면으로 돌아갑니다.\n변경된 휴대전화 번호로 다시 로그인해주세요.');
                        dispatch(logout());
                    }
                }
            }
            else {
                dispatch(regApi({
                    ...userInfo,
                    owner: userInfo.owner? userInfo.owner : userInfo.user_name ,
                    user_phone: userInfo.user_phone,
                    card : {
                        ...card,
                        file_data: card.file_data? card.file_data.indexOf("https://lhws.s3.ap-northeast-2.amazonaws.com/") !== -1? card.file_data : card.file_data.split(',')[1] : '',
                        file_name: card.file_name? card.file_name : '',
                        contenttype: card.contenttype? card.contenttype : '',
                    }, 
                    business: {
                        ...business,
                        file_data: business.file_data? business.file_data.indexOf("https://lhws.s3.ap-northeast-2.amazonaws.com/")!== -1? business.file_data : business.file_data.split(',')[1] : '',
                        file_name: business.file_name? business.file_name : '',
                        contenttype: business.contenttype? business.contenttype : '',
                    }, 
                    deduction: {
                        ...deduction,
                        file_data:  deduction.file_data? deduction.file_data.indexOf("https://lhws.s3.ap-northeast-2.amazonaws.com/")!== -1? deduction.file_data : deduction.file_data.split(',')[1] : '',
                        file_name: deduction.file_name? deduction.file_name : '',
                        contenttype: deduction.contenttype? deduction.contenttype : '',
                    }, 
                    use_flag
                }))
            }
        }
    }
    const onSaveApvInfo = () => {
        if(window.confirm("현재 결재정보를 저장하시겠습니까?")) {
            if(app_normal.length < 1) {
                ALERT("검토자는 한명 이상 등록 되어야 합니다.")
                return;
            }
            
            // 결재확인자 필수 제외
            // else if(app_check.length < 1) {
            //     ALERT("결재확인자는 한명 이상 등록 되어야 합니다.")
            //     return;
            // }
            let _apprList = [], _apprChkList = []; // apprList: 검토자, apprChkList: 결재확인자

            app_normal.forEach((el, idx) => {
                _apprList.push({
                    app_seq: idx + 1,
                    user_id: el.user_id,
                })
            })
            app_check.forEach((el, idx) => {
                _apprChkList.push({
                    app_seq: idx + 1,
                    user_id: el.user_id,
                })
            })
            
            dispatch(appuserReg({
                user_id: userInfo.user_id,
                approval_user: _apprList,
                appcheck_user: _apprChkList,
            }))
        }
    }

    // 결재자, 결재확인자 클릭: 추가
    const onAdd = (target) => {
        dispatch(add({target}))
    }

    useEffect(() => {
        if(!user) {
            dispatch(check());
        }
        else if(user && dispatch){
            dispatch(view(user.user_id))
        }
    }, [user, dispatch])

    // 결재라인 수정권한 여부 확인
    useLayoutEffect(() => {
        if(menu) {
            if(menu.some((el) => el.id === "MENU07" && el.func.length > 0)) {
                setIsAppAuth(true)
            }
            else {
                setIsAppAuth(false)
            }
        }
    }, [menu])

    // 비밀번호 변경
    // 기존 비밀번호가 확인이 된 경우에 이리로 온다.
    useEffect(() => {
        if(isPwdModal) {
            // 기존비밀번호 일치
            if(pwdCheckResult === "OK") {
                ChangePwd();
            }
            // 불일치
            else if(pwdCheckResult === "NG"){
                ALERT("기존 비밀번호가 일치하지않습니다.");
                dispatch(initPwdCheck());
            }
        }
    }, [dispatch, isPwdModal, pwdCheckResult, ChangePwd])

    useEffect(() => {
        if(pwdChangeSuccess === 'success') {
            ALERT('비밀번호가 변경되었습니다.');
            dispatch(initPwdCheck());
            setIsPwdModal(false);
        }
    }, [dispatch, pwdChangeSuccess])

    useEffect(() => {
        dispatch(appuserList());
        dispatch(getCombo({main_code: "APPLY_TYPE"}));

        return () => dispatch(initialize())
    }, [dispatch])

    useEffect(() => {
        if(regsuccess) {
            ALERT('정보변경에 성공했습니다.')
            dispatch(changeRegSuccess({
                value: false
            }))
            onClose();
            dispatch(initPwdCheck());
        }
    },[regsuccess, dispatch, onClose])

    // 타이머
    useEffect(() => {
        if(setTimeStart) {
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
            interval.current = setInterval(() => {
                time.current -= 1;
                if(time.current < 1) {
                    clearInterval(interval.current);
                }
                setMin(parseInt(time.current / 60));
                setSec(time.current % 60);
            }, 1000)
        }
    }, [setTimeStart])

    useEffect(() => {
        return () => clearInterval(interval.current);
    }, [])

    useEffect(() => {
        if(certSuccess) {
            if(certSuccess === 'success') {
                dispatch(setTimeValue({
                    value: false
                }))
                clearInterval(interval.current);
            }            
        }
    }, [certSuccess, dispatch, onClosePhoneModal])
    
    const propDatas = {
        user,
        userInfo,
        result,
        target,
        user_pwd,
        user_pwd_check,
        searchApprove,
        isAppAuth,
        loading,
        isPwdModal,
        user_pwd_prev,
        isShowDaum,
        isPhoneModal,
        setTimeStart,
        min,
        sec,
        certSuccess,
        cboApply,
        app_check,
        app_normal,
        
        onOpen,
        onClose,
        onChange,
        onUp,
        onDown,
        onSaveUsrInfo,
        onSaveApvInfo,
        onAdd,
        onTarget,
        onChangePwd,
        onSearch,
        onFileChange,
        onChangeApp,
        ChangePwd,
        checkPwd,
        onComplete,
        onCloseDaum,
        onOpenDaum,
        opOpenPhoneModal,
        onClosePhoneModal,
        onClickRequestCert,
        onClickCompareCert,
        onClickSavePhone,
    }

    return (
        <>
            <Mypage {...propDatas}/>
        </>
    );
}

export default MypageContainer;