import React from 'react';
import styled from 'styled-components';
import dataWORD from '../lib/dataWord';

const AuditRequestBlock = styled.div`    
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
`;

const AuditRequest = (props) => {
    const {
        implyAuditRequestList,

        handleClickXLSX,
    } = props;
    return (
        <AuditRequestBlock>
            <button onClick={handleClickXLSX}>엑셀변환</button>

            <table className='tt' id='excelData'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>지역</th>
                        <th>지원주택소재지</th>
                        <th>유형</th>
                        <th>입주자명</th>
                        <th>{dataWORD.BIRTHDATE}</th>
                        <th>전화번호</th>
                        <th>계약종료일</th>
                    </tr>
                </thead>
                <tbody>
                    {implyAuditRequestList.length > 0?
                    <>
                        {implyAuditRequestList.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{item.rec_area || ''}</td>
                                    <td>{item.addr || ''}</td>
                                    <td>{item.imply_type || ''}</td>
                                    <td>{item.imply_name || ''}</td>
                                    <td>{item.imply_no? `${item.imply_no.slice(0,6)}-${item.imply_no.slice(6)}` : ''}</td>
                                    <td>{item.phone? `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}`  : ''}</td>
                                    <td>{item.contract_end_date || ''}</td>
                                </tr>
                            )
                        })}
                    </>
                    : 
                    <tr>
                        <td colSpan={7}>결과가 없습니다.</td>    
                    </tr>}
                </tbody>
            </table>
        </AuditRequestBlock>
    );
}

export default AuditRequest;