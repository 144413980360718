import React from 'react';
import styled from 'styled-components';
import dataWORD from '../lib/dataWord';

const ImplyResultBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    padding: 30px 50px;
    box-sizing: border-box;


    .xlUploadIpt {
        // display: none;
    }
    .xlUpload {
        // width: 150px;
        // height: 150px;
        // border: 1px solid black;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // cursor: pointer;
    }

    .resultTbl {
        overflow: auto;
        height: 80vh;
        border: 1px solid black;
        box-sizing: border-box;
    }

    .resultTbl table{
        white-space: nowrap;
        text-align: center;
    }

    .resultTbl table th, 
    .resultTbl table td {
        padding: 0 5px;
    }
`;

const ImplyResult = (props) => {
    const {
        results,
        
        onChange,
        onWrite,
        onClickSearch
    } = props

    return (
        <ImplyResultBlock>
            ImplyResult
            <h3>등본초요청결과 업로드 페이지</h3>
            <h3>{'엑셀 -> 데이터 변환'}</h3>

            <h4>등본초요청결과 업로드</h4>
            <label className='xlUpload' htmlFor='results'>
                엑셀 가져오기
            </label>
            <input className='xlUploadIpt' name='results' id='results' type='file' onChange={onChange}/>

            <h4>미리보기</h4>
            <button onClick={onWrite}>엑셀 업로드하기</button>
            <button onClick={onClickSearch}>등초본조회</button>
            <div className='resultTbl'>
                <table className='tt'>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>지자체</th>
                            <th>주소</th>
                            <th>성명</th>
                            <th>{dataWORD.BIRTHDATE}</th>
                            <th>세대주와의관계</th>
                            <th>요청일자</th>
                            <th>수신일자</th>
                            <th>공동주택명</th>
                            <th>공동주택동명</th>
                            <th>공동주택호명</th>
                            <th>세대구성사유</th>
                            <th>세대구성일자</th>
                            <th>세대주최종변동일자</th>
                            <th>세대주최종변동신고일자</th>
                            <th>세대주최종변동사유</th>
                            <th>전입일자</th>
                            <th>변동일자</th>
                            <th>변동사유</th>
                            <th>등록상태내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.length > 0? 
                        <>
                        {
                            results.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{idx + 1}</td>
                                        <td>{item.area || "-"}</td>
                                        <td>{item.addr || "-"}</td>
                                        <td>{item.name || "-"}</td>
                                        <td>{item.jumin_num || "-"}</td>
                                        <td>{item.relation || "-"}</td>
                                        <td>{item.request_date || "-"}</td>
                                        <td>{item.receive_date || "-"}</td>
                                        <td>{item.joint_house || "-"}</td>
                                        <td>{item.joint_house_dong || "-"}</td>
                                        <td>{item.joint_house_ho || "-"}</td>
                                        <td>{item.com_reason || "-"}</td>
                                        <td>{item.com_date || "-"}</td>
                                        <td>{item.last_change_date || "-"}</td>
                                        <td>{item.report_date || "-"}</td>
                                        <td>{item.last_change_reason || "-"}</td>
                                        <td>{item.move_in_date || "-"}</td>
                                        <td>{item.change_date || "-"}</td>
                                        <td>{item.change_reason || "-"}</td>
                                        <td>{item.reg_status || "-"}</td>
                                    </tr>
                                )
                            })
                        }
                        </>
                        : null}
                    </tbody>
                </table>
            </div>
        </ImplyResultBlock>
    );
}

export default ImplyResult;