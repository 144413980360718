import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import List from "../components/List";
import {
    changeStatusArr,
    changeTypeArr,
    changeValue,
    initialize,
    initSearch,
    initType,
    searchFeedbackList,
    searchList,
    setPaging,
    setSearchValue,
} from "../modules/list";
import dataURL, { dataSTATUS } from "../lib/dataURL";
import FeedbackContainer from "./Modal/FeedbackContainer";
import { qr } from "../common";
import LhConfirmContainer from "./Modal/LhConfirmContainer";
import FieldChkContainer from "./Modal/FieldChkContainer";
import { ALERT } from "../lib/dataWord";
import { dataSystemCode } from "../lib/dataSystemCode";
const ListContainer = () => {
    const [isShowFeedback, setIsShowFeedback] = useState(null);
    const [isFieldChkDocNo, setIsFieldChkDocNo] = useState(null);
    const [isLhConfirmDocNo, setIsLhConfirmDocNo] = useState(null);
    const [isLeaveMultiCbo, setIsLeaveMultiCbo] = useState(false); //  Multipe ComboBox 공통 닫기 Event
    const [isShowCboStatus, setIsShowCboStatus] = useState(false); //  진행상태 Multipe Combo Show/Hide
    const [selectedStatus, setSelectedStatus] = useState(""); //  진행상태 선택된 Text
    const [cboAreaData, setCboAreaData] = useState([]); //  지역 Static Data
    const [isShowCboArea, setIsShowCboArea] = useState(false); //  지역 Multipe Combo Show/Hide
    const [selectedAreas, setSelectedAreas] = useState(""); //  지역 선택된 Text
    const [isShowCboType, setIsShowCboType] = useState(false); //  지원유형 Multipe Combo Show/Hide
    const [selectedTypes, setSelectedTypes] = useState(""); //  지원유형 선택된 Text
    
    const dataContract = dataSystemCode.LEASE_TYPE;     //    계약유형
    const [cboContData, setCboContData] = useState([]); //  계약유형 Static Data
    const [isShowCboCont, setIsShowCboCont] = useState(false); //  계약유형 Multipe Combo Show/Hide
    const [selectedConts, setSelectedConts] = useState(""); //  계약유형 선택된 Text
    const modalCallType = "LIST"; //  모달 호출 위치

    const [isPostback, setIsPostback] = useState(true);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const type = params["*"];
    const {
        state: { listValue, name },
    } = location;

    const [applyTypeArr, setApplyTypeArr] = useState([
        // {
        //     sub_code: "ENWRITE",
        //     code_name: '작성중',
        //     checked: true,
        // },
        // {
        //     sub_code: "ENSAVE",
        //     code_name: '신청서확인',
        //     checked: true,
        // },
        // {
        //     sub_code: "ENREQ",
        //     code_name: '접수대기',
        //     checked: true,
        // },
        // {
        //     sub_code: "ENACK/ENCHGREQ/ENPROCESS/ENAPVREQ/ENAPVFAIL/ENMODIFY",
        //     code_name: '처리중',
        //     checked: true,
        // },
        // {
        //     sub_code: "ENDOCFAIL",
        //     code_name: '서류미비',
        //     checked: true,
        // },
        // {
        //     sub_code: "ENDOCADD",
        //     code_name: '서류보완',
        //     checked: true,
        // },
        // {
        //     sub_code: "ENAPVOK",
        //     code_name: '분석완료',
        //     checked: true,
        // },
        // {
        //     sub_code: "CONTSTRT",
        //     code_name: '계약시작',
        //     checked: true,
        // },
    ]);

    const {
        state: { func },
    } = location;

    const {
        listArr,
        user,
        status,
        applyType,
        search,
        menu,
        statusAll,
        statusArr,
        // initSuccess,
        pageCount,
        max,
        step,
        currentPage,
        feedbackArr,
    } = useSelector(({ list, user }) => ({
        listArr: list.listArr,
        user: user.user,
        status: list.status,
        applyType: list.applyType,
        search: list.search,
        menu: user.menu,
        statusAll: list.statusAll,
        statusArr: list.statusArr,
        // initSuccess: list.initSuccess,
        pageCount: list.pageCount,
        max: list.max,
        step: list.step,
        currentPage: list.currentPage,
        feedbackArr: list.feedbackArr,
    }));

    //----------------------------------------------------------------------
    // 검색
    // 리스트 검색
    const onSubmit = (e) => {
        e.preventDefault();
        navigate(
            encodeURI(
                `${location.pathname}?date_type=${search.date_type}&from_date=${search.from_date}&to_date=${search.to_date}&type=${search.type}&status=${search.status}&user_type=${search.user_type}&user=${search.user}&all_search=${search.all_search}&page=${1}&feedback_status=${search.feedback_status}&area=${search.area}&contract_type=${search.contract_type}&isfirst=N`
            ),
            {
                state: {
                    ...location.state,
                    statusArr,
                    applyTypeArr,
                    cboAreaData,
                    cboContData,
                },
            }
        );
    };

    // 검색값 초기화
    const onInitSearchvalue = () => {
        dispatch(initSearch({ type, all_search: search.all_search }));

        if (type === "apply") {
            setApplyTypeArr(
                applyTypeArr.map((data) => ({ ...data, checked: true }))
            );
        } else {
            dispatch(changeStatusArr({ value: [] }));
        }

        setCboAreaData(cboAreaData.map((data) => ({ ...data, checked: true })));

        dispatch(
            changeTypeArr({
                key: 'applyType',
                value: applyType.map((data) => ({ ...data, checked: true }))
            })
        )

        let _cboContData = [];
        dataContract.forEach(item => {
            if(item.sub_code) {
                _cboContData.push({
                    sub_code: item.sub_code,
                    code_name: item.code_name,
                    checked: true,
                })
            }
        });
        setCboContData(_cboContData);
    };

    // 검색값 변경
    const onChange = (e) => {
        const {
            target: { name, value },
        } = e;
        dispatch(changeValue({ key: name, value }));
    };
    //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // 페이징

    // 숫자 클릭
    const onClickNum = (num) => {
        if (num !== currentPage) {
            dispatch(
                setPaging({
                    key: "currentPage",
                    value: num,
                })
            );
            navigate(
                encodeURI(
                    `${location.pathname}?date_type=${search.date_type}&from_date=${search.from_date}&to_date=${search.to_date}&type=${search.type}&status=${search.status}&user_type=${search.user_type}&user=${search.user}&all_search=${search.all_search}&page=${num}&feedback_status=${search.feedback_status}&area=${search.area}&contract_type=${search.contract_type}&isfirst=N`
                ),
                {
                    state: {
                        ...location.state,
                        statusArr,
                        applyTypeArr,
                        cboAreaData,
                        cboContData,
                    },
                }
            );
        }
    };

    // 다음 화살표
    const onClickNext = () => {
        if (Math.ceil(pageCount / max) >= step + 1) {
            navigate(
                encodeURI(`${location.pathname}?date_type=${search.date_type}&from_date=${search.from_date}&to_date=${search.to_date}&type=${search.type}&status=${search.status}&user_type=${search.user_type}&user=${search.user}&all_search=${search.all_search}&page=${step * max + 1}&feedback_status=${search.feedback_status}&area=${search.area}&contract_type=${search.contract_type}&isfirst=N`
                ),
                {
                    state: {
                        ...location.state,
                        listValue: listValue,
                        statusArr,
                        applyTypeArr,
                        cboAreaData,
                        cboContData,
                    },
                }
            );
            dispatch(
                setPaging({
                    key: "step",
                    value: "next",
                })
            );
        }
    };

    // 이전 화살표
    const onClickPrev = () => {
        if (step - 1 > 0) {
            navigate(
                encodeURI(`${location.pathname}?date_type=${search.date_type}&from_date=${search.from_date}&to_date=${search.to_date}&type=${search.type}&status=${search.status}&user_type=${search.user_type}&user=${search.user}&all_search=${search.all_search}&page=${(step - 2) * max + 1}&feedback_status=${search.feedback_status}&area=${search.area}&contract_type=${search.contract_type}&isfirst=N`
                ),
                {
                    state: {
                        ...location.state,
                        listValue: listValue,
                        statusArr,
                        applyTypeArr,
                        cboAreaData,
                        cboContData,
                    },
                }
            );
            dispatch(
                setPaging({
                    key: "step",
                    value: "prev",
                })
            );
        }
    };
    //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // 리스트 클릭

    // 진행 상태 검색 조건 제한 필요
    const onClickList = (item) => {
        switch (type) {
            // 신청서현황
            case dataSTATUS.APPLY:
                // 신청서 작성 권한이 있으면. 04 -> 한페이지
                if (menu.some((el) => el && el.id === "MENU04")) {
                    navigate(dataURL.EnWriteAdminPage, {
                        state: {
                            docNo: item.doc_id,
                            winner_type: item.winner_type,
                            func: func,
                        },
                    });
                } else if (menu.some((el) => el && el.id === "MENU03")) {
                    navigate(dataURL.EntitleWritePage, {
                        state: {
                            docNo: item.doc_id,
                            winner_type: item.winner_type,
                            func: func,
                        },
                    });
                }

                break;

            // 권리분석요청 현황
            case dataSTATUS.ENTITLE:
                if (
                    menu.some((el) => el.id === "MENU07" && el.func.length > 0)
                ) {
                    navigate(dataURL.EntitleWorkPage, {
                        state: {
                            docNo: item.doc_id,
                            func: func,
                        },
                    });
                }
                break;

            // 피드백
            case dataSTATUS.FEEDBACK:
                if (func.some((el) => el.id === "FUNC082")) {
                    setIsShowFeedback(item.doc_id);
                } else {
                    ALERT("피드백 작성 권한이 없습니다.");
                }
                break;

            // 결재대기
            case dataSTATUS.APPROVE:
                navigate(dataURL.EntitleWorkPage, {
                    state: {
                        docNo: item.doc_id,
                        func: func,
                    },
                });
                break;

            // 계약대기
            case dataSTATUS.CONTRACT:
                navigate(dataURL.ContractPage, {
                    state: {
                        docNo: item.doc_id,
                        func: func,
                    },
                });
                break;

            // 최종확인
            case dataSTATUS.CONFIRM:
                setIsLhConfirmDocNo(item.doc_id);
                break;

            // 현장조사
            case dataSTATUS.FIELDCHK:
                setIsFieldChkDocNo(item.doc_id);
                break;

            default:
                break;
        }
    };
    //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // 리스트 모달 관련

    //  Modal Action 완료 후 리스트 새로 불러옴
    const onRefreshList = (type) => {
        const tempObj = qr(location.search);

        if (type === "feedback") {
            dispatch(searchFeedbackList(tempObj));
        } else {
            dispatch(
                searchList({
                    ...tempObj,
                    list_type: type,
                })
            );
        }
    };
    //----------------------------------------------------------------------

    //  Multiple Check Combo 공통   --------------------------------------------
    const onShowMultiCbo = (type) => {
        if (type === "STATUS") {
            setIsShowCboStatus(!isShowCboStatus);
            setIsShowCboArea(false);
            setIsShowCboType(false);
            setIsShowCboCont(false);
        } else if (type === "AREA") {
            setIsShowCboStatus(false);
            setIsShowCboArea(!isShowCboArea);
            setIsShowCboType(false);
            setIsShowCboCont(false);
        } else if (type === "APPLY_TYPE") {
            setIsShowCboStatus(false);
            setIsShowCboArea(false);
            setIsShowCboType(!isShowCboType);
            setIsShowCboCont(false);
        } else if (type === "CONT_TYPE") {
            setIsShowCboStatus(false);
            setIsShowCboArea(false);
            setIsShowCboType(false);
            setIsShowCboCont(!isShowCboCont);
        }

        if (!isShowCboStatus || !isShowCboArea || !isShowCboType || !isShowCboCont) {
            setIsLeaveMultiCbo(false);
        }
    };
    const onHideMultiCbo = (e) => {
        //  콤보박스 css에 적용된 width:30%로 인해 콤보박스의 너비가 눈에 보이는 것보다 더 넓은 영역을 차지해
        //  mouseLeave Event가 발생하지 않아 콤보박스의 닫힘이 정상작동 하지 않는 이슈가 있음.
        if (e.type === "mouseleave") {
            setIsLeaveMultiCbo(true);
        } else if (isLeaveMultiCbo) {
            setIsShowCboStatus(false);
            setIsShowCboArea(false);
            setIsShowCboType(false);
            setIsShowCboCont(false);
            setIsLeaveMultiCbo(false);
        }
    };
    const onEnterMultiCbo = () => {
        setIsLeaveMultiCbo(false);
    };
    //  --------------------------------------------    Multiple Check Combo 공통

    //  진행상태 Multiple Check Combo   --------------------------------------------
    const onStatusChange = (item, e) => {
        if (type === "apply") {
            setApplyTypeArr(
                applyTypeArr.map((data) =>
                    data.sub_code === item.sub_code
                        ? { ...data, checked: e.target.checked }
                        : data
                )
            );
        } else {
            const selValues = statusArr.map((data) =>
                data.sub_code === item.sub_code
                    ? { ...data, checked: e.target.checked }
                    : data
            );
            dispatch(changeStatusArr({ value: selValues }));
        }
    };
    const onStatusAll = (e) => {
        if (type === "apply") {
            setApplyTypeArr(
                applyTypeArr.map((data) => {
                    return { ...data, checked: e.target.checked };
                })
            );
        } else {
            const selValues = statusArr.map((data) => {
                return { ...data, checked: e.target.checked };
            });
            dispatch(changeStatusArr({ value: selValues }));
        }
    };

    useEffect(() => {
        if ((applyTypeArr.length > 0 || statusArr.length) > 0 && type) {
            const targetArr = type === "apply" ? applyTypeArr : statusArr;
            let selTexts = "",
                selValues = "";

            const filterData = targetArr.filter((x) => x.checked === true);
            filterData.forEach((selected, idx) => {
                if (idx !== filterData.length - 1) {
                    selTexts += selected.code_name + ",";
                    selValues += selected.sub_code + ",";
                } else {
                    selTexts += selected.code_name + ",";
                    selValues += selected.sub_code;
                }
            });

            if (targetArr.length === filterData.length) {
                selTexts = "전체";
                // selValues = "";
            } else {
                selTexts = selTexts.slice(0, -1);
            }

            setSelectedStatus(selTexts);
            dispatch(changeValue({ key: "status", value: selValues }));
        }
    }, [dispatch, applyTypeArr, statusArr, type]);
    //  --------------------------------------------    진행상태 Multiple Check Combo

    useEffect(() => {
    }, [listArr]);

    //  지역 Multiple Check Combo   --------------------------------------------
    const onAreaChange = (item, e) => {
        setCboAreaData(
            cboAreaData.map((data) =>
                data.area === item.area
                    ? { ...data, checked: e.target.checked }
                    : data
            )
        );
    };
    const onAreaAll = (e) => {
        setCboAreaData(
            cboAreaData.map((data) => {
                return { ...data, checked: e.target.checked };
            })
        );
    };

    useEffect(() => {
        if (user) {
            // null 처리
            if (user.auth_area.length > 0) {
                let _areas = [];
                user.auth_area.split(",").forEach((area) => {
                    _areas.push({
                        area: area,
                        checked: true,
                    });
                });
                setCboAreaData(_areas);
            } else {
                setCboAreaData([]);
            }
            //  로그인 유저에 할당된 지역
        }
    }, [user]);

    useEffect(() => {
        if (cboAreaData.length > 0) {
            let selTexts = "",
                selValues = "";

            const filterData = cboAreaData.filter((x) => x.checked === true);
            filterData.forEach((selected) => {
                selTexts += selected.area + ",";
                selValues += selected.area + ",";
            });

            if (cboAreaData.length === filterData.length) {
                selTexts = "전체";
                // selValues = "";
            } else {
                selTexts = selTexts.slice(0, -1);
            }

            setSelectedAreas(selTexts);
            dispatch(changeValue({ key: "area", value: selValues }));
        }
    }, [dispatch, cboAreaData]);
    //  --------------------------------------------    지역 Multiple Check Combo

    //  지원유형 Multiple Check Combo   --------------------------------------------
    const onApplyTypeChange = (item, e) => {
        dispatch(
            changeTypeArr({
                key: 'applyType',
                value: applyType.map((data) => 
                    data.sub_code === item.sub_code
                        ? { ...data, checked: e.target.checked }
                        : data
                )
            })
        )
    };
    const onApplyTypeAll = (e) => {
        dispatch(
            changeTypeArr({
                key: 'applyType',
                value: applyType.map((data) => {
                    return { ...data, checked: e.target.checked }
                })
            })
        )
    };

    useEffect(() => {
        if (applyType.length > 0) {
            let selTexts = "",
                selValues = "";

            const filterData = applyType.filter((x) => x.checked === true);
            filterData.forEach((selected) => {
                selTexts += selected.code_name + ",";
                selValues += selected.sub_code + ",";
            });

            if (applyType.length === filterData.length) {
                selTexts = "전체";
                // selValues = "";
            } else {
                selTexts = selTexts.slice(0, -1);
            }

            setSelectedTypes(selTexts);
            dispatch(changeValue({ key: "type", value: selValues }));
        }
    }, [dispatch, applyType]);
    //  --------------------------------------------    지원유형 Multiple Check Combo

    //  계약유형 Multiple Check Combo   --------------------------------------------
    const onContChange = (item, e) => {
        setCboContData(
            cboContData.map((data) => 
                data.sub_code === item.sub_code
                    ? { ...data, checked: e.target.checked }
                    : data
            )
        )
    };
    const onContAll = (e) => {
        setCboContData(
            cboContData.map((data) => {
                return { ...data, checked: e.target.checked }
            })
        )
    };

    useEffect(() => {
        if (cboContData.length > 0) {
            let selTexts = "",
                selValues = "";

            const filterData = cboContData.filter((x) => x.checked === true);
            filterData.forEach((selected) => {
                selTexts += selected.code_name + ",";
                selValues += selected.sub_code + ",";
            });

            if (cboContData.length === filterData.length) {
                selTexts = "전체";
                // selValues = "";
            } else {
                selTexts = selTexts.slice(0, -1);
            }

            setSelectedConts(selTexts);
            dispatch(changeValue({ key: "contract_type", value: selValues }));
        }
    }, [dispatch, cboContData]);
    //  --------------------------------------------    계약유형 Multiple Check Combo

    //  LH User가 Feedback 리스트에 접속시 마지막 검색조건 자동 부여
    useEffect(() => {
        if(!applyType || applyType.length === 0) {
            return;
        }
        if(!cboContData || cboContData.length === 0) {
            return;
        }
        if(!isPostback) {
            return;
        }

        if(type === "feedback") {
            if(user.search_apply_type) {
                const _arrApplyType = user.search_apply_type.split(',');
                dispatch(
                    changeTypeArr({
                        key: 'applyType',
                        value: applyType.map((data) => 
                                _arrApplyType.find(x => x === data.sub_code)
                                    ? { ...data, checked: true }
                                    : { ...data, checked: false }
                        )
                    })
                )
            }
            if(user.search_contract_type) {
                const _arrContData = user.search_contract_type.split(',');
                setCboContData(
                    cboContData.map((data) => 
                        _arrContData.find(x => x === data.sub_code)
                            ? { ...data, checked: true }
                            : { ...data, checked: false }
                    )
                )
            }
        }
        setIsPostback(false);
    }, [dispatch, isPostback, type, user, applyType, cboContData])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        };
    }, [dispatch]);

    useEffect(() => {
        if (listValue && dispatch && type && status.length === 0) {
            dispatch(
                initType({
                    type,
                    status,
                    statusAll: listValue.types,
                    feedbackArr: listValue.feedback_status,
                })
            );
        }
    }, [dispatch, listValue, type, status]);

    useEffect(() => {
        if (dispatch) {
            let tempObj = qr(location.search);
            tempObj.status = tempObj.status.replaceAll("/", ",");

            //  결재완료는 Default 체크에서 제외
            if (type === "entitle" && tempObj.isfirst === "Y") {
                const arrStatus = tempObj.status.split(",");
                tempObj.status = arrStatus
                    .filter((x) => x !== "ENAPVCHK")
                    .join();
            }

            //  결재대기 또는 계약대기 리스트가 아니라면 date_type = ''
            if (!(type === "approve" || type === "contract")) {
                tempObj.date_type = "";
            }

            dispatch(setSearchValue(tempObj));
            dispatch(
                setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                })
            );
            dispatch(
                setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                })
            );

            if (type === "feedback") {
                dispatch(searchFeedbackList({...tempObj}));
            } else {
                if (type === "apply") {
                    dispatch(
                        searchList({
                            ...tempObj,
                            // 비어있으면 초기값으로
                            status:
                                tempObj.status === "" ? null : tempObj.status,
                            user_type: !tempObj.user ? "" : tempObj.user_type,
                            list_type: "EN_WRITE",
                        })
                    );
                } else if (type === "entitle" || type === "contract") {
                    dispatch(
                        searchList({
                            ...tempObj,
                            user_type: !tempObj.user ? "" : tempObj.user_type,
                            list_type: "ENTITLE",
                        })
                    );
                } else if (type === "approve") {
                    dispatch(
                        searchList({
                            ...tempObj,
                            user_type: !tempObj.user ? "" : tempObj.user_type,
                            list_type: "APPROVAL",
                        })
                    );
                } else if (type === "confirm") {
                    dispatch(
                        searchList({
                            ...tempObj,
                            user_type: !tempObj.user ? "" : tempObj.user_type,
                            list_type: "CONFIRM",
                        })
                    );
                } else if (type === "fieldchk") {
                    dispatch(
                        searchList({
                            ...tempObj,
                            user_type: !tempObj.user ? "" : tempObj.user_type,
                            list_type: "FIELDCHK",
                        })
                    );
                }
            }
        }
    }, [location, dispatch, type]);

    useEffect(() => {
        if (dispatch) {
            const tempObj = qr(location.search);
            const _statusArr = tempObj.status.split(",");
            if (type === "apply") {
                if (location.state.applyTypeArr.length > 0) {
                    setApplyTypeArr(
                        location.state.applyTypeArr.map((data) =>
                            _statusArr.indexOf(data.sub_code) > -1
                                ? { ...data, checked: true }
                                : { ...data, checked: false }
                        )
                    );
                }
                // 빈 배열이면 다시 초기화
                else {
                    setApplyTypeArr([
                        {
                            sub_code: "ENWRITE",
                            code_name: "작성중",
                            checked: true,
                        },
                        {
                            sub_code: "ENSAVE",
                            code_name: "신청서확인",
                            checked: true,
                        },
                        {
                            sub_code: "ENREQ",
                            code_name: "접수대기",
                            checked: true,
                        },
                        {
                            sub_code:
                                "ENACK/ENCHGREQ/ENPROCESS/ENAPVREQ/ENAPVFAIL/ENMODIFY",
                            code_name: "처리중",
                            checked: true,
                        },
                        {
                            sub_code: "ENDOCFAIL",
                            code_name: "서류미비",
                            checked: true,
                        },
                        {
                            sub_code: "ENDOCADD",
                            code_name: "서류보완",
                            checked: true,
                        },
                        {
                            sub_code: "ENAPVOK",
                            code_name: "처리중",
                            checked: true,
                        },
                        {
                            sub_code: "ENAPVCHK",
                            code_name: "분석완료",
                            checked: true,
                        },
                        {
                            sub_code: "CONTSTRT",
                            code_name: "계약시작",
                            checked: true,
                        },
                    ]);
                }
            } else {
                if (location.state.statusArr.length > 0) {
                    const selValues = location.state.statusArr.map((data) =>
                        _statusArr.indexOf(data.sub_code) > -1
                            ? { ...data, checked: true }
                            : { ...data, checked: false }
                    );
                    dispatch(changeStatusArr({ value: selValues }));
                } else {
                    // 빈배열 전달하면 redux 에서 true 로 변경.
                    dispatch(changeStatusArr({ value: [] }));
                }
            }
        }
    }, [location, dispatch, type]);

    useEffect(() => {
        if (dispatch) {
            const tempObj = qr(location.search);
            if (tempObj.area) {
                const _areaArr = tempObj.area.split(",");
                setCboAreaData(
                    location.state.cboAreaData.map((data) =>
                        _areaArr.indexOf(data.area) > -1
                            ? { ...data, checked: true }
                            : { ...data, checked: false }
                    )
                );
            }
        }
    }, [location, dispatch]);

    useEffect(() => {
        let _cboContData = [];
        dataContract.forEach(item => {
            if(item.sub_code) {
                _cboContData.push({
                    sub_code: item.sub_code,
                    code_name: item.code_name,
                    checked: true,
                })
            }
        });
        setCboContData(_cboContData);
    }, [dataContract])

    const propDatas = {
        listArr,
        user,
        search,
        type,
        statusAll,
        statusArr,
        pageCount,
        max,
        step,
        currentPage,
        feedbackArr,
        applyTypeArr,
        name,

        onClickList,
        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,
        onRefreshList,
        onInitSearchvalue,

        //  진행상태 검색 관련
        isShowCboStatus,
        selectedStatus,
        onStatusChange,
        onStatusAll,

        //  지역 검색 관련
        cboAreaData,
        isShowCboArea,
        selectedAreas,
        onAreaChange,
        onAreaAll,

        //  지원유형 검색 관련
        applyType,
        isShowCboType,
        selectedTypes,
        onApplyTypeChange,
        onApplyTypeAll,

        //  계약유형 검색 관련
        cboContData,
        isShowCboCont,
        selectedConts,
        onContChange,
        onContAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        //  LH 최종확인 모달
        isLhConfirmDocNo,
        setIsLhConfirmDocNo,

        //  현장조사 모달
        isFieldChkDocNo,
        setIsFieldChkDocNo,

        //  Feedback 모달
        isShowFeedback,
        setIsShowFeedback,
        modalCallType,
    };

    return (
        <>
            {/* <EntitleSearch /> */}
            <Routes>
                {/* 리스트 */}
                <Route path=":type" element={<List {...propDatas} />} />
            </Routes>

            {isShowFeedback && <FeedbackContainer {...propDatas} />}
            {isFieldChkDocNo && <FieldChkContainer {...propDatas} />}
            {isLhConfirmDocNo && <LhConfirmContainer {...propDatas} />}
        </>
    );
};

export default ListContainer;
