import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ApplyDocMgt from '../components/ApplyDocMgt';
import { addRow, changeValue, saveCode, removeRow, rowChangeValue, getCode, getClass, setMainCode, getCboDoc, initialize, sortRow } from '../modules/applyDocMgt';
import { dataEntitleCalc } from '../lib/dataEntitleCalc'
import { ALERT } from '../lib/dataWord';

const ApplyDocMgtContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {state: {id}} = location;

    const {
        mainCode,
        classList,
        codeList,
        cboDoc,
    } = useSelector(({ applyDocMgt }) => ({
        mainCode: applyDocMgt.mainCode,        
        classList: applyDocMgt.classList,
        codeList: applyDocMgt.codeList,
        cboDoc: applyDocMgt.cboDoc,
    }));

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowChange = (e, item) => {
        dispatch(
            rowChangeValue({
                idx: item.idx,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowClick = (item, e) => {
        dispatch(setMainCode({value:item.sub_code}));
        dispatch(getCode({
            main_code: item.sub_code,
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }));
    }
    const onAddRow = () => {
        dispatch(addRow());
    }
    const onRemoveRow = (item) => {
        dispatch(removeRow({idx: item.idx}));
    }
    const onRowSort = (item, type, e) => {
        dispatch(sortRow({
            display: item.display,
            type: type,
        }));
    }

    const onSave = () => {
        let isDocNull = false;
        codeList.some(function(item) {
            if (!item.sub_code) {
                ALERT('제출서류를 선택해주세요.');
                isDocNull = true;
            }
            return (!item.sub_code)
        })

        if (isDocNull) {
            return;
        }

        dispatch(saveCode(codeList));
        dispatch(getClass({
            main_code: "APPLY_TYPE",
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }))
        dispatch(getCode({
            main_code: mainCode,
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }));
        dispatch(getCboDoc({
            main_code: "DOC_TYPE",
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }))
    }

    useEffect(() => {
        if(dispatch && id) {
            dispatch(getClass({
                main_code: "APPLY_TYPE",
                admin_type: id==="MENU99"? "SYSTEM" : "USER"
            }))
            dispatch(getCboDoc({
                main_code: "DOC_TYPE",
                admin_type: id==="MENU99"? "SYSTEM" : "USER"
            }))
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    const propDatas = {
        classList,
        codeList,
        cboDoc,
        dataEntitleCalc,

        onChange,
        onRowChange,
        onRowClick,
        onAddRow,
        onRemoveRow,
        onRowSort,
        onSave,
    }

    return (
        <>
            <ApplyDocMgt {...propDatas} />
        </>
    );
}

export default ApplyDocMgtContainer;