import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { idValidation } from '../../common';
import CreateBroker from '../../components/Modal/CreateBroker';
import { ALERT } from '../../lib/dataWord';
import { changeValue, initialize, overlapChk, registBroker, sendSms } from '../../modules/Modal/createBroker';

const CreateBrokerContainer = (props) => {
    const {
        setIsShowCreateBroker,
    } = props;

    const {
        broker_id,
        broker_company,
        broker_phone,

        chk_overlap,
        regsuccess,
    } = useSelector(({ createBroker }) => ({ 
        broker_id: createBroker.broker_id,
        broker_company: createBroker.broker_company,
        broker_phone: createBroker.broker_phone,

        chk_overlap: createBroker.chk_overlap,
        regsuccess: createBroker.regsuccess,
    }))
    const dispatch = useDispatch();
    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );

        if(e.target.name === 'broker_id') {
            dispatch(
                changeValue({
                    key: 'chk_overlap',
                    value: 0,
                })
            )
        }
    }
    const onIdCheck = () => {
        if( !broker_id ) {
            ALERT('아이디를 입력하세요');
        } 
        else if (broker_id.length < 6) {
            ALERT('아이디는 최소 6자리 이상이어야 합니다.')
        }
        else if (!idValidation(broker_id)) {
            ALERT('아이디는 영어 소문자, 숫자로만 조합되어야 합니다. 동일한 문자, 연속된 숫자는 다섯자리 이상 사용할 수 없습니다.')
        }
        else {
            dispatch(overlapChk(broker_id));
        }
    }
    const onSave = () => {
        if(chk_overlap !== 1) {
            ALERT('ID 중복확인을 해주세요.');
            return;
        }
        if(!broker_company) {
            ALERT("중개사 상호를 입력해주세요.");
            return;
        }
        if(!broker_phone) {
            ALERT("중개사 휴대전화번호를 입력해주세요.");
            return;
        }

        if(window.confirm("입력하신 정보로 중개사ID를 생성하시겠습니까?")) {
            dispatch(registBroker({
                user_id: broker_id,
                user_name: "",
                user_phone: broker_phone,
                broker_yn: "Y",  
                user_company: broker_company, 
                use_flag: "Y",
                card: "",
                business: "",
                deduction: "",
            }));
        }
    }
    const onClose = () => {
        setIsShowCreateBroker(null);
    }

    useEffect(() => {
        if(regsuccess) {
            dispatch(sendSms({
                sender: "1600-4690",
                receiver: broker_phone,
                msg: "공인중개사 '" + broker_company + "' 의 계정이 " + broker_id + " 로 생성되었습니다.",
                msg_type: "SMS",
            }));

            setIsShowCreateBroker(null);
        }
    }, [dispatch, regsuccess, broker_phone, broker_company, broker_id, setIsShowCreateBroker])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        broker_id,
        broker_company,
        broker_phone,

        chk_overlap,

        onChange,
        onIdCheck,
        onSave,
        onClose,
    }
    return (
        <>
            <CreateBroker {...propDatas} />
        </>
    );
}

export default CreateBrokerContainer;