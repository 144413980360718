import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'chargeFee/INITIALIZE';
const CHANGE_VALUE = 'chargeFee/CHANGE_VALUE';
// const SET_PAGING = 'chargeFee/SET_PAGING';
const [GET_SUMMARY, GET_SUMMARY_SUCCESS, GET_SUMMARY_FAILURE] = createRequestActionTypes('chargeFee/GET_SUMMARY');
const [GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE] = createRequestActionTypes('chargeFee/GET_LIST');
const [SAVE_CONFIRM, SAVE_CONFIRM_SUCCESS, SAVE_CONFIRM_FAILURE] = createRequestActionTypes('chargeFee/SAVE_CONFIRM');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
// export const setPaging = createAction(SET_PAGING, (payload) => payload);
export const getSummary = createAction(GET_SUMMARY);
export const getList = createAction(GET_LIST);
export const saveConfirm = createAction(SAVE_CONFIRM);

const getSummarySaga = createRequestSaga(GET_SUMMARY, adminAPI.expense_summary);
const getListSaga = createRequestSaga(GET_LIST, adminAPI.expense_search);
const saveConfirmSaga = createRequestSaga(SAVE_CONFIRM, adminAPI.expense_confirm);

export function* chargeFeeSaga() {
    yield takeLatest(GET_SUMMARY, getSummarySaga);
    yield takeLatest(GET_LIST, getListSaga);
    yield takeLatest(SAVE_CONFIRM, saveConfirmSaga);
}

const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10);
const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10);

const initialState = {
    from_date: oneMonthAgo,
    to_date: today,
    expense_flag: 'N',

    summary: [],
    list: [],

    sumTotCnt: 0,
    sumTotEnAmt: 0,
    sumTotContAmt: 0,
    sumTotTrip: 0,
    sumTotSum: 0,
    sumTotVat: 0,
    sumTotTot: 0,
    listTotWinnerType: 0,
    listTotEnAmt: 0,
    listTotContAmt: 0,
    listTotTrip: 0,
    listTotSum: 0,
    listTotVat: 0,
    listTotTot: 0,

    chargeFee: null,
    error: null
};

const chargeFee = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        // //  페이지 이동
        // [SET_PAGING]: (state, {payload: {key, value}}) => {
        //     if(key==="currentPage") {
        //         return ({
        //             ...state,
        //             [key]: value
        //         })
        //     }
        //     else if (key === "step") {
        //         if(value === "next") {
        //             return ({
        //                 ...state,
        //                 [key]: state.step + 1,
        //                 search: {
        //                     ...state.search,
        //                     page: (state.step + 1) * state.max - 2
        //                 }
        //             })
        //         }
        //         else if(value === "prev") {
        //             return ({
        //                 ...state,
        //                 [key]: state.step - 1,
        //                 search: {
        //                     ...state.search,
        //                     page: (state.step - 1) * state.max - 2
        //                 }
        //             })
        //         }
        //         else {
        //             return ({
        //                 ...state,
        //                 [key]: Math.ceil(value / state.max)
        //             })
        //         }
        //     }
        // },

        //  총합계 가져오기
        [GET_SUMMARY_SUCCESS]: (state, { payload }) => {
            let _sumTotCnt = 0,_sumTotEnAmt = 0,_sumTotContAmt = 0,_sumTotTrip = 0,_sumTotSum = 0,_sumTotVat = 0,_sumTotTot = 0;
            payload.forEach(item => {
                _sumTotCnt += +item.exp_count;
                _sumTotEnAmt += +item.entitle_amt;
                _sumTotContAmt += +item.contract_amt;
                _sumTotTrip += +item.trip_expense;
                _sumTotSum += +item.sum_amt;
                _sumTotVat += +item.vat_amt;
                _sumTotTot += +item.tot_amt;
            });

            return {
                ...state,
                summary: payload,
                sumTotCnt: _sumTotCnt === 0 ? '-' : _sumTotCnt,
                sumTotEnAmt: _sumTotEnAmt === 0 ? '-' : _sumTotEnAmt,
                sumTotContAmt: _sumTotContAmt === 0 ? '-' : _sumTotContAmt,
                sumTotTrip: _sumTotTrip === 0 ? '-' : _sumTotTrip,
                sumTotSum: _sumTotSum === 0 ? '-' : _sumTotSum,
                sumTotVat: _sumTotVat === 0 ? '-' : _sumTotVat,
                sumTotTot: _sumTotTot === 0 ? '-' : _sumTotTot,
            }
        },
        [GET_SUMMARY_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        //  리스트 가져오기
        [GET_LIST_SUCCESS]: (state, { payload }) => {
            let _listTotWinnerType = 0,_listTotEnAmt = 0,_listTotContAmt = 0,_listTotTrip = 0,_listTotSum = 0,_listTotVat = 0,_listTotTot = 0;
            payload.forEach(item => {
                _listTotWinnerType += 1;
                _listTotEnAmt += +item.entitle_amt;
                _listTotContAmt += +item.contract_amt;
                _listTotTrip += +item.trip_expense;
                _listTotSum += +item.sum_amt;
                _listTotVat += +item.vat_amt;
                _listTotTot += +item.tot_amt;
            });

            return {
                ...state,
                list: payload,
                listTotWinnerType: _listTotWinnerType === 0 ? '-' : _listTotWinnerType,
                listTotEnAmt: _listTotEnAmt === 0 ? '-' : _listTotEnAmt,
                listTotContAmt: _listTotContAmt === 0 ? '-' : _listTotContAmt,
                listTotTrip: _listTotTrip === 0 ? '-' : _listTotTrip,
                listTotSum: _listTotSum === 0 ? '-' : _listTotSum,
                listTotVat: _listTotVat === 0 ? '-' : _listTotVat,
                listTotTot: _listTotTot === 0 ? '-' : _listTotTot,
                // pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount),
            }
        },
        [GET_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                // pageCount: null,
                // max: 3,
                // step: 1,
                // currentPage: 1,
                error
            }
        },

        //  수수료 청구 반영
        [SAVE_CONFIRM_SUCCESS]: (state, { payload }) => {
            ALERT("수수료 청구가 완료되었습니다.");
            return {
                ...state,
            }
        },
        [SAVE_CONFIRM_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default chargeFee;