import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addZoom } from '../../common';
import LhConfirm from '../../components/Modal/LhConfirm';
import { changeValue, doc_selectFolder, doc_selectImg, getFile, initialize, save, setStatus } from '../../modules/Modal/lhConfirm';

const LhConfirmContainer = (props) => {
    const {
        //  모달 완료 후 리스트 재조회
        onRefreshList,

        //  LH최종확인 모달
        isLhConfirmDocNo,
        setIsLhConfirmDocNo,
    } = props;

    const folderDiv = useRef(null);
    const folderSlider = useRef(null);

    const dispatch = useDispatch();
    const {
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        remark,
    } = useSelector(({ lhConfirm }) => ({
        selectFoler: lhConfirm.selectFoler,
        docFolder: lhConfirm.docFolder,
        selectImg: lhConfirm.selectImg,
        docImages: lhConfirm.docImages,
        remark: lhConfirm.remark,
    }));

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(getFile({
            doc_id: isLhConfirmDocNo,
            doc_type: 'CONTRACT',
        }))
    }, [dispatch, isLhConfirmDocNo])

    useEffect(() => {
        addZoom("")
    }, [dispatch, selectImg])

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }

    //  Image Event -------------------------------------------
    const onSelectFolder = (item, e) => {
        dispatch(doc_selectFolder(item));
        
        const firstInFolder = docImages.find(x => x.docCode === item.sub_code);
        dispatch(doc_selectImg(firstInFolder));
        initImgScale();
    }
    const onSelectImg = (item, e) => {
        dispatch(doc_selectImg(item));
        initImgScale();
    }
    const initImgScale = () => {
        const initImg = document.getElementById("selectImg");
        initImg.style.transform = 'scale(1)';
        initImg.style.left = '0px';
        initImg.style.top = '0px';
    }
    //  onPrevClick, onNextClick :: 현재 folderSlider가 차지하는 너비만큼 버튼이 이동될 수 있도록 함.
    //  이전 폴더 Slider
    const onPrevClick = () => {
        let movePx = Math.floor(folderDiv.current.clientWidth / 170) * 170;
        
        if (folderSlider.current.offsetLeft < 0) {
            const nextPostion = folderSlider.current.offsetLeft + movePx;
            folderSlider.current.style = "left:" + nextPostion + "px";
        }
    }
    //  다음 폴더 Slider
    const onNextClick = () => {
        let movePx = Math.floor(folderDiv.current.clientWidth / 170) * 170;
        
        const nextPostion = folderSlider.current.offsetLeft - movePx;
        if (Math.abs(nextPostion) < folderSlider.current.clientWidth) {
            folderSlider.current.style = "left:" + nextPostion + "px";
        }
    }
    //  ------------------------------------------- Image Event
    const onSaveClick = () => {
        if(!window.confirm("최종확인을 완료하시겠습니까?")) {
            return;
        }

        dispatch(save({
            doc_id: isLhConfirmDocNo,
            remark: remark,
        }))
        setIsLhConfirmDocNo(null);
        onRefreshList('CONFIRM');
    }
    const onRejectClick = () => {
        if(!window.confirm("반려하시겠습니까?")) {
            return;
        }
        
        dispatch(
            setStatus({
                doc_id: isLhConfirmDocNo,
                status: "CONTSTRT",
                remark: remark,
                lack_reason: "",
            })
        )
        setIsLhConfirmDocNo(null);
        onRefreshList('CONFIRM');
    }
    const onCloseClick = () => {
        setIsLhConfirmDocNo(null);
    }

    const propDatas = {
        folderDiv,
        folderSlider,

        selectFoler,
        docFolder,
        selectImg,
        docImages,
        remark,

        onChange,
        onSelectFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,
        onSaveClick,
        onRejectClick,
        onCloseClick,
    }
    return (
        <>
            <LhConfirm {...propDatas} />
        </>
    );
}

export default LhConfirmContainer;