import React from 'react';
import styled from "styled-components";

const TermsPopup = styled.div`
    .popupModal > div:nth-child(2){
        width: 800px;
        max-height: 80vh;
        box-sizing: border-box;
        padding: 30px 50px;
        overflow-y: scroll;
    }
    .Buttons{
        text-align: center;
    }
    .cancel-btn{
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .popupModal > div:nth-child(2){
            width: 100%;
            max-height: 100%;
            border-radius: 0;
            padding: 20px 10px;
        }
    }
`;

const PrivacyTermsModal = (props) => {
    const {setIsShowPrivacyTerms} = props;

    return (
        <TermsPopup>
            <div className='popupModal'>
            <div />
            <div>
                <div className='containerDiv'>
                ▶ <b>개인정보 수집 및 이용 안내</b><br />
                집클릭은 권리분석 등 업무 수행과 관련하여 아래와 같은 개인정보를 수집하고 있습니다<br />
                <br />
                ￭ 수집하는 개인정보의 항목<br />
                · 필수적 정보 : 신청인 성명, 생년월일, 주소, 전화번호, 가족관계증명서, 주민등록표등본<br />
                · 해당자에 한함(선택적 정보) :  수급자증명서, 차상위계층증명서, 한부모가족증명서 장애인증명서 등<br />
                <br />
                ￭ 개인정보 수집 및 이용 목적<br />
                · 권리분석 및 계약관리 등 서비스 제공<br />
                <br />
                ￭ 개인정보 보유 및 이용기간<br />
                · 수집/이용 근거 : 정보주체의 동의, 공공주택특별법 제48조의 6, 7, 공공주택 특별법 시행령 제63조<br />
                · 첨부 제출 서류 4개월,  기타 5년<br />
            <div className='Buttons'>
                <button className="cancel-btn" onClick={()=>setIsShowPrivacyTerms(null)}>닫기</button>
            </div>
            </div>
            </div>
            </div>
        </TermsPopup>
    );
};

export default PrivacyTermsModal;