//  천자리 콤마 정규식
export const regAmt = /\B(?=(\d{3})+(?!\d))/g;

// 콤마적용 문자열 반환 함수
export const setComma = (num) => {
  if(num) {
    return num.replace(regAmt, ",")
  }
  else {
    return '0';
  }
}

// \r\n 제거
export const delNewLine = (str) => {
  return str.replace(/(\r\n|\n|\r)/gm, "");
}

// 아이디 조건 확인
// 영어 소문자, 숫자 조합
// 최소 8자리 이상 -> 이건 따로
export const idValidation = (id) => {
  let valid = true;

  // 대문자X
  if(/[A-Z]/.test(id)) {
    valid = false;
  }

  // 영어 소문자는 반드시 포함
  if(! /^(?=.*?[a-z])/.test(id)) {
    valid = false;
  }

  // 특수문자X
  if(/[#?!@$%^&*-,.<>~()_=+]/.test(id)) {
    valid = false;
  }
  
  // 한글 불가능 (+추가)
  if(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(id)) {
    valid = false;
  }

  // 공백 불가능 (+추가)
  if(id.search(/\s/) !== -1) {
    valid = false;
  }

  // 연속되는 5자리 숫자/문자 불가능 (+추가)
  const word = ["123456789", "qwertyuiop", "asdfghjkl", "zxcvbnm"];
  const reverseWord = [...word.map(v => [...v].reverse().join(""))];
  const keyboard = [...word, ...reverseWord];

  for (let i = 0; i < id.length-4; i++) {
    const sliceId = id.substring(i, i + 5);

    // 모든 조건을 한번씩 순회
    if (keyboard.some((code) => code.includes(sliceId))) {
        valid = false;
    }
  }

  return valid;
}

// 비밀번호 조건 확인
// (8자리 이상의 길이 -> 이건 따로)
// 휴대폰번호, 아이디  -> 이건 따로
export const pwValidation = (pw, user_id) => {
  let valid = true;

  // 영문자, 숫자, 특수문자 조합으로 3개 이상의 조합 
  if(! /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-,.<>~()_=+])/.test(pw)) {
    valid = false;
  }

  // 동일문자 4자리 반복포함 불가
  if(/(\w)\1\1\1/.test(pw)) {
    valid = false;
  }
  // 연속된 세자리 숫자 포함 불가
  // let temp1 = '';
  // let temp2 = '';
  // let temp3 = '';
  // for (let i = 0; i < pw.length; i++) {
  //   temp1 = '';
  //   temp2 = '';
  //   temp3 = '';
  //   if(i <= pw.length - 3) {
  //     temp1 = pw.charAt(i);
  //     temp2 = pw.charAt(i + 1);
  //     temp3 = pw.charAt(i + 2);
  //     // 확인하려는 문자가 0 ~ 7 사이의 숫자라면
  //     if(45 <= temp1.charCodeAt(0) && temp1.charCodeAt(0) <= 55) {
  //       // 3,2,1

  //       // 1,2,3
  //       if(
  //         (temp1.charCodeAt(0) - temp2.charCodeAt(0) === -1) && 
  //         (temp2.charCodeAt(0) - temp3.charCodeAt(0) === -1)
  //       ) {
  //           valid = false;
  //       }

  //       else if(
  //         (temp1.charCodeAt(0) - temp2.charCodeAt(0) === 1) && 
  //         (temp2.charCodeAt(0) - temp3.charCodeAt(0) === 1)
  //       ) {
  //           valid = false;
  //       }
  //     }
  //   }
  //   else {
  //     break;
  //   }
  // }

  // 한글 불가능 (+추가)
  if(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(pw)) {
    valid = false;
  }

  // 공백 불가능 (+추가)
  if(pw.search(/\s/) !== -1) {
    valid = false;
  }

  // 사용자 아이디 포함 불가능 (+추가)
  if(user_id && pw.includes(user_id)){
    valid = false;
  }

  // 자판배열 포함 불가능 (+추가)
  const word = ["123456789", "qwertyuiop", "asdfghjkl", "zxcvbnm"];
  const wordAll = [...word, ...word.map(v => v.toUpperCase())];
  const reverseWord = [...wordAll.map(v => [...v].reverse().join(""))];
  const keyboard = [...wordAll, ...reverseWord];

  for (let i = 0; i < pw.length - 3; i++) {
    const slicePw = pw.substring(i, i + 4);

    // 모든 조건을 한번씩 순회
    if (keyboard.some((code) => code.includes(slicePw))) {
        valid = false;
    }
  }

  return valid;
}

export const parseNumber = (value) => {
  let targetValue = value.replace(/,/g, "");
  
  //  첫자리 0 제거
  const arrValue = targetValue.split('');
  for(let i = 0; i < arrValue.length; i++) {
      if(arrValue[i] === "0") {
          arrValue.shift();
          i--;
      }
      else {
          break;
      }
  }
  return targetValue = arrValue.join('');
}

//  22.01.12 Excel의 RoundDown 구현, 출처 MDN
export const decimalAdjust = (type, value, exp) => {
    // exp가 undefined 또는 0인 경우...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // 값이 숫자가 아니거나 정수형이 아닌 경우...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

//  서류 이미지 Hover시 확대
export const addZoom = (target) => {
  // if (!target || !document.getElementById(target)) { return; }
  // // (A) FETCH CONTAINER + IMAGE
  // var container = document.getElementById(target),
  //     imgsrc = container.currentStyle || window.getComputedStyle(container, false),
  //     imgsrc = imgsrc.backgroundImage.slice(4, -1).replace(/"/g, ""),
  //     img = new Image();

  // // (B) LOAD IMAGE + ATTACH ZOOM
  // img.src = imgsrc;
  // img.onload = function () {
  //   // var imgWidth = img.naturalWidth,
  //   //     imgHeight = img.naturalHeight,
  //   var imgWidth = img.naturalWidth / (img.naturalWidth / 1000),
  //       imgHeight = img.naturalHeight / (img.naturalHeight / 1000),
  //       ratio = imgHeight / imgWidth;

  //   // (C) ZOOM ON MOUSE MOVE
  //   container.onmousemove = (e) => {
  //     var boxWidth = container.clientWidth,
  //         rect = e.target.getBoundingClientRect(),
  //         xPos = e.clientX - rect.left,
  //         yPos = e.clientY - rect.top,
  //         xPercent = xPos / (boxWidth / 100) + "%",
  //         yPercent = yPos / ((boxWidth * ratio) / 100) + "%";
          
  //     Object.assign(container.style, {
  //       backgroundPosition: xPercent + ' ' + yPercent,
  //       backgroundSize: imgWidth + 'px'
  //     });
  //   };

  //   // (D) RESET ON MOUSE LEAVE
  //   container.onmouseleave = (e) => {
  //     Object.assign(container.style, {
  //       backgroundPosition: 'center',
  //       backgroundSize: 'contain',
  //       backgroundRepeat: 'no-repeat'
  //     });
  //   };
  // }

  //  ##################################################

  //  이정우 이사님께서 요청하신 단계별 확대/축소
  //  버튼은 너무 불편해서 휠로 대체함.
  const viewElement = document.getElementById("imgView");
  const divRotate = document.getElementById("div-rotate");
  const zoomElement = document.getElementById("selectImg");

  if (!viewElement) { return; }
  if (!divRotate) { return; }
  if (!zoomElement) { return; }

  //  마우스휠로 이미지 축소/확대
  viewElement.onwheel = (e) => {
    const defaultScale = 1;
    const zoomScale = +(zoomElement.style.transform.replace('scale(', '').replace(')', ''));
    const zoomGap = -e.deltaY * 0.005;

    if((zoomScale ? zoomScale : 1) + zoomGap < defaultScale) {
      return;
    }
    zoomElement.style.transform = 'scale(' + (zoomScale ? zoomScale + zoomGap : 1) + ')';
  }
  
  //  마우스 드래그로 이미지 이동 구현
  var img_ele = null, x_img_ele = 0, y_img_ele = 0;
  divRotate.onmousedown = () => {
    img_ele = divRotate;
    
    x_img_ele = window.event.clientX - divRotate.offsetLeft;
    y_img_ele = window.event.clientY - divRotate.offsetTop;
  }
  //  이미지에서 마우스 휠 동작시, 브라우저 스크롤이 움직이는 현상 제어
  divRotate.onmouseover = () => {
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    window.onscroll = function() {
        window.scrollTo(scrollLeft, scrollTop);
    };
  }
  divRotate.onmousemove = () => {
    var x_cursor = window.event.clientX;

    if (img_ele !== null) {
        img_ele.style.left = (x_cursor - x_img_ele) + 'px';
        img_ele.style.top = (window.event.clientY - y_img_ele) + 'px';
    }
  }
  divRotate.onmouseup = () => {
    img_ele = null;
  }
  divRotate.onmouseleave = () => {
    img_ele = null;

    //  이미지 영역을 벗어나면 브라우저 스크롤 정상화
    window.onscroll = function() {};
  }
};

export const numToHan = (num) => {
  num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + ''; // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
  if(num === '0')
    return '영';

  var number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  var unit = ['', '만', '억', '조'];
  var smallUnit = ['천', '백', '십', ''];
  var result = []; //변환된 값을 저장할 배열
  var unitCnt = Math.ceil(num.length / 4); //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
  num = num.padStart(unitCnt * 4, '0') //4자리 값이 되도록 0을 채운다
  var regexp = /[\w\W]{4}/g; //4자리 단위로 숫자 분리
  var array = num.match(regexp);

  //낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
  for(var i = array.length - 1, unitCount = 0; i >= 0; i--, unitCount++) {
    var hanValue = _makeHan(array[i]); //한글로 변환된 숫자
    if(hanValue === '') //값이 없을땐 해당 단위의 값이 모두 0이란 뜻.
    continue;
    result.unshift(hanValue + unit[unitCount]); //unshift는 항상 배열의 앞에 넣는다.
  }

  //여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
  function _makeHan(text) {
    var str = '';
    for(var i = 0; i < text.length; i++) {
      var num = text[i];
      if(num === '0') //0은 읽지 않는다
        continue;
      str += number[num] + smallUnit[i];
    }
    return str;
  }
  return result.join('');
}

export const qr = (queryString) => {
  let tempStr = decodeURI(queryString).substring(1);
  let tempArr = tempStr.split('&')
  let tempObj = {};
  tempArr.forEach((el) => tempObj[el.split("=")[0]] = el.split("=")[1] )

  return tempObj;
}

export const ToLocaleString = n => {
  const num = parseInt(n || '');
  if( isNaN(num) ) return '';
  return num.toLocaleString(undefined, {maximumFractionDigits: 5});
}