import ExcellentExport from 'excellentexport';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AuditRequest from '../components/AuditRequest';
import { implyRequestSearch } from '../modules/Imply';

const AuditRequestContainer = () => {
    const {
        implyAuditRequestList,
    } = useSelector(({
        Imply
    }) => ({
        implyAuditRequestList: Imply.implyAuditRequestList,
    }))

    const dispatch = useDispatch();
    const location = useLocation();

    const {state: {request_id}} = location;

    // 아이디가 excelData 인 테이블의 데이터를 엑셀로 변환
    const handleClickXLSX = () => {
        return ExcellentExport.convert(
            { anchor: this, filename: "등초본요청자료", format: "xlsx", openAsDownload: true },
            [
                { name: "등초본요청리스트", from: { table: "excelData" } },
            ]
        );
    }

    useEffect(() => {
        if(request_id && dispatch) {
            dispatch(implyRequestSearch({
                req_id: request_id
            }))
        }
    }, [request_id, dispatch])

    useEffect(() => {
    }, [implyAuditRequestList])

    const propDatas = {
        implyAuditRequestList,

        handleClickXLSX,
    }
    return (
        <>
            <AuditRequest {...propDatas}/>
        </>
    );
}

export default AuditRequestContainer;