import React from 'react';
import styled from 'styled-components';
import { regAmt } from '../common';
import dataWORD from '../lib/dataWord';
const ImplyWorkBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;

    h1{
        color: #545454;
        font-size: 24px;
        margin: 20px 0;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .implyWork {
        display: grid;
        grid-template-columns: 5fr 2fr;
        gap: 10px;
        width: 100%;
    }

    // .mainContents {
    //     width: 60%;
    // }
    // .implyImg {
    //     width: 20%;
    // }
    .implyWork .implyImg {
        border: 1px solid black;
        height: 60vh;

        position: sticky;
        top: 70px;
    }

    .implyWork .tableCover {
        width: 100%;
        margin-bottom: 3.75rem;
    }
    .implyWork div:first-child {
        // overflow: scroll;
        // white-space:nowrap;
    }

    .tableCover div:first-child, 
    .tableCover div:nth-child(2) {
        overflow-x: scroll;
    } 

    .btn-group {
        position: initial;
    }



    // .tableCover div:first-child .tt {
    //     margin-bottom: 0px;
    //     box-shadow: 0px 0px 0.37rem rgb(0 0 0 / 16%);
    //     border-bottom-left-radius: 0;
    //     border-bottom-right-radius: 0;
    // }

    // .tableCover div:nth-child(2) .tt {
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     margin-bottom: 10px;
    // }

    .implyWork .tt thead {
        position: sticky;
        top: 60px;
        background-color: white;
    }
    .implyWork .tt {
        border-collapse: separate;
        border-spacing: 0px;
    }
    .implyWork .tt thead tr:last-child th{
        border-bottom: 2px solid;
    }
    .implyWork > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .implyWork > div:first-child {
        justify-content: flex-start;
    }
    .implyWork .tt th {
        border: 1px solid #BDBDBD;
    }
    .implyWork .tt th,
    .implyWork .tt td {
        padding: 0 5px;
    }

    .implyWork .tt .on {
        color: red;
    }

    .lastTr:hover {
        background-color: white;
        cursor: default;
    }
    .lastTr {
        border-bottom: 1px solid black;
    }
    .lastTr td{
        // width: 100%;
        // height: 100%;
        // padding-bottom: 20px !important;
    }

    .lastTr >td >div {
        width: 100%;
        height: 100%;
        // display: flex;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: 4fr 1fr;
    }

    .lastTr >td >div > span {
        width: 100%;
    }

    .t19 input {
        width: 100%;
    }

    .t6, .t10 {
        text-align: left;
    }


    .flexStyle > div{
        width: 100%;
        height: 100%;
        display: flex;
        // justify-content: space-evenly;
        justify-content: center;
        align-items: center;
    }

    .tt thead {
        z-index: 10;
    }
    .tt .green {
        color: #00d900;
    }
    .tt .red {
        color: red;
    }
    .tt .gray {
        color: #a3a3a3;
    }

    .imgView {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 10px;
        box-sizing: border-box;
    }
    .selectImg {
        width: 82%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        border: 1px solid #ccc;
        cursor: all-scroll;
    }

    .noneSelectedImg {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .tt .lastTr td{
        border-bottom: 1px solid black;
    }
`;

const ImplyWork = (props) => {
    const {
        pageCount,
        step,
        max,
        currentPage,
        implySearch,
        impWorkList,
        target,
        selectedImg,
        selectedArr,
        isAuthorized,
        noImg,

        onClickPrev,
        onClickNum,
        onClickNext,
        onSubmit,
        onChangeSearch,
        onAuditRequest,
        onAuditCheck,
        onMouseOver,
        onMouseLeave,
        onAuditResult,
        onSave,
        onChangeImply,
        onCheck,
        onRequest,
        onClickList,
        onSelectImg,
        onRequestAll,
        onAuditSearch,
    } = props

    return (
        <ImplyWorkBlock>
            <div>
                <h1>묵시작업 페이지</h1>
                <div className='btn-group'>
                    {isAuthorized?
                    <>
                        <button onClick={onAuditResult}>등초본업로드</button>
                        <button onClick={onAuditRequest}>등초본요청</button>
                        <button onClick={onAuditCheck}>등초본확인</button>
                        <button onClick={onRequestAll}>등기부등본일괄요청</button>
                    </>
                    : null}
                    <button onClick={onAuditSearch}>등초본조회</button>
                </div>
                
                <h2>검색조건</h2>
                {/* 묵시 대상자 검색영역 */}
                <div className='search'>
                    <form onSubmit={onSubmit}>
                        <div className='searchBody'>
                            <div className='searchipt'>
                                <div className='st'>
                                    <label htmlFor='date_type'>날짜유형</label>
                                    <select name='date_type' id='date_type' value={implySearch.date_type} onChange={onChangeSearch}>
                                        <option value={''}>전체</option>
                                        <option value={'REC_DATE'}>신청일자</option>
                                        <option value={'CONTRACT_END_DATE'}>계약종료일</option>
                                    </select>
                                    <input type='date' name='from_date' id='from_date' value={implySearch.from_date} onChange={onChangeSearch}/>
                                    <span>~</span>
                                    <input type='date' name='to_date' id='to_date' value={implySearch.to_date} onChange={onChangeSearch}/>
                                </div>
                                <div className='st'>
                                    <label htmlFor='addr'>주소</label>
                                    <input type='text' name='addr' id='addr' value={implySearch.addr} onChange={onChangeSearch}/>
                                </div>
                                <div className='st'>
                                    <label htmlFor='name'>입주자명</label>
                                    <input type='text' name='name' id='name' value={implySearch.name} onChange={onChangeSearch}/>
                                </div>
                                <div className='st'>
                                    <label htmlFor='req_status'>등초본 요청 여부</label>
                                    <select name='req_status' id='req_status' value={implySearch.req_status} onChange={onChangeSearch}>
                                        <option value={''}>전체</option>
                                        <option value={'Y'}>완료</option>
                                        <option value={'P'}>요청중</option>
                                        <option value={'N'}>미요청</option>
                                    </select>
                                </div>
                                {/* 결재여부(결재했는지), 묵시 OK 여부 */}
                                {/* 결재 리스트는 당첨자 등록처럼 3건 -> 요청 1건 따로 있어야함.  */}
                            </div>

                            <div className='btn'>
                                <button className='green-sch-btn'>검색</button>
                            </div>
                        </div>
                    </form>
                </div>

                {/* 묵시 대상자가 보일 테이블영역 (주소 비교후 등본 요청.)*/}
                <h2>검색결과</h2>
                <div className='implyWork'>
                    <div className='mainContents'>
                        <table className='tt'>
                            <thead>
                                <tr>
                                    <th className={target === 't6'? 'on' : null} colSpan={2}>지원주택소재지</th>
                                    <th className={target === 't1'? 'on' : null}>유형</th>
                                    <th className={target === 't2'? 'on' : null}>입주자명</th>
                                    <th className={target === 't3'? 'on' : null}>{dataWORD.BIRTHDATE}</th>
                                    <th className={target === 't4'? 'on' : null}>전화번호</th>
                                    <th className={target === 't7'? 'on' : null}>계약종료일</th>
                                    <th className={target === 't23'? 'on' : null}>신청일</th>
                                    <th className={target === 't11'? 'on' : null}>전입날짜</th>
                                </tr>
                                <tr>
                                    <th className={target === 't10'? 'on' : null} colSpan={2}>전입주소</th>
                                    <th className={target === 't15'? 'on' : null}>대항력상실여부</th>
                                    <th className={target === 't16'? 'on' : null}>소유자변동</th>
                                    <th className={target === 't17'? 'on' : null}>압류등설정</th>
                                    <th className={target === 't5'? 'on' : null}>임대인</th>
                                    <th className={target === 't8'? 'on' : null}>전세금</th>
                                    <th className={target === 't9'? 'on' : null}>등초본요청</th>
                                    <th className={target === 't18'? 'on' : null}>OK/NG</th>
                                </tr>
                                <tr>
                                    <th className={target === 't12'? 'on' : null}>주소일치여부</th>
                                    <th className={target === 't13'? 'on' : null}>주소선택</th>
                                    <th className={target === 't19'? 'on' : null} colSpan={4}>비고</th>
                                    <th className={target === 't20'? 'on' : null}>저장하기</th>
                                    <th className={target === 't21'? 'on' : null}>등본요청하기</th>
                                    <th className={target === 't22'? 'on' : null}>등본날짜</th>
                                </tr>
                            </thead>
                            <tbody>
                                {impWorkList.length > 0? 
                                <>
                                {impWorkList.map((item, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <tr onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => onClickList(item)}>
                                                <td className='t6' colSpan={2}>{item.addr|| "-"}</td>
                                                <td className='t1'>{item.type_name || "-"}</td>
                                                <td className='t2'>{item.imply_name|| "-"}</td>
                                                <td className='t3'>{item.imply_no? `${item.imply_no.slice(0,6)}-${item.imply_no.slice(6)}` : "-"}</td>
                                                <td className='t4'>{item.phone? `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}` : "-"}</td>
                                                <td className='t7'>{item.contract_end_date|| "-"}</td>
                                                <td className='t23'>{item.rec_date|| "-"}</td>
                                                <td className='t11'>{item.last_change_date|| "-"}</td>
                                            </tr>
                                            <tr onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => onClickList(item)}>
                                                <td className='t10' colSpan={2}>{item.resident_reg_addr|| "-"}</td>

                                                {/* <td className='t14'>{item.addr_approval|| "-"}</td> */}
                                                {/* 
                                                    display: flex;
                                                    justify-content: space-evenly;
                                                    align-items: center;
                                                    t15 t16 t17 에 적용
                                                */}
                                                <td className='t15 flexStyle'>{/*대항력 상실여부item.resistance_loss */}
                                                    <div className='t15'>
                                                        <div className='t15'>
                                                            <div className='t15'>
                                                                <input className='t15' disabled={isAuthorized? false : true} type={'checkbox'} name={'resistance_loss'} id={`resistance_loss${idx}`} checked={item.resistance_loss === "Y"? true : false} onChange={(e) => onCheck(e, idx)}/><label htmlFor={`resistance_loss${idx}`} className='t15'>Y</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='t16 flexStyle'>{/*소유자 변동item.owner_change */}
                                                    <div className='t16'>
                                                        <div className='t16'>
                                                            <div className='t16'>
                                                                <input className='t16' disabled={isAuthorized? false : true} type={'checkbox'} name={'owner_change'} id={`owner_change${idx}`} checked={item.owner_change === "Y"? true : false} onChange={(e) => onCheck(e, idx)}/><label className='t16' htmlFor={`owner_change${idx}`}>Y</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='t17 flexStyle'>{/*압류등설정 item.seizure_setting */}
                                                    <div className='t17'>
                                                        <div className='t17'>
                                                            <div className='t17'>
                                                                <input className='t17' disabled={isAuthorized? false : true} type={'checkbox'} name={'seizure_setting'} id={`seizure_setting${idx}`} checked={item.seizure_setting === "Y"? true : false} onChange={(e) => onCheck(e, idx)}/><label className='t17' htmlFor={`seizure_setting${idx}`}>Y</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className='t5'>{item.house_owner|| "-"}</td>
                                                <td className='t8'>{item.rent_amt.replace(regAmt, ",")|| "-"} 원</td>
                                                {item.audit_req_yn === "Y"? <td className='t9'>요청완료</td> : null}
                                                {item.audit_req_yn === "P"? <td className='t9'>요청중</td> : null}
                                                {item.audit_req_yn === "N"? <td className='t9'>미요청</td> : null}
                                                <td className='t18'>{/* 묵시여부 */}
                                                    <select className='t18' disabled={isAuthorized? false : true} name='imply_ok' id='imply_ok' value={item.imply_ok || ''} onChange={(e) => onChangeImply(e, idx)}>
                                                        <option value={''}>선택하세요</option>
                                                        <option value='OK'>OK</option>
                                                        <option value='NG'>NG</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className='lastTr' onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} onClick={() => onClickList(item)}>
                                                {/* 주소일치 여부 (등초본 요청 상태에 따라 달리 보이도록) */}
                                                {item.audit_req_yn === "Y" && item.addr_compare === '주소일치'? <td className='t12 green'>주소일치</td> : null}
                                                {item.audit_req_yn === "Y" && item.addr_compare === '불일치'? <td className='t12 red'>불일치</td> : null}
                                                {item.audit_req_yn === "P"? <td className='t12 gray'>요청중</td> : null}
                                                {item.audit_req_yn === "N"? <td className='t12 gray'>요청중</td> : null}

                                                {/* 주소선택 (주소가 일치하면 안보이고, 요청완료일때만 선택 가능하도록) */}
                                                <td className='t13'>
                                                    <select className='t13' disabled={isAuthorized? (item.audit_req_yn !== "Y" || item.addr_compare === '주소일치'? true : false) : true} name='confirm_addr' id='confirm_addr' value={item.confirm_addr || ''} onChange={(e) => onChangeImply(e, idx)}>
                                                        <option value=''>선택하세요</option>
                                                        <option value='지원주택소재지'>지원주택소재지</option>
                                                        <option value='전입주소'>전입주소</option>
                                                    </select>
                                                </td>
                                                <td className='t19' colSpan={4}>
                                                {isAuthorized? 
                                                <input className='t19' name='remark' id='remark' value={item.remark || ''} onChange={(e) => onChangeImply(e, idx)}/>
                                                : 
                                                item.remark || '-'
                                                } 
                                                </td>
                                                <td className='t20'>
                                                    <button className='t20 save-btn' onClick={() => onSave(item)} disabled={isAuthorized? false : true}>저장</button>
                                                </td>
                                                <td className='t21'>
                                                    <button className='t21 squre-bdradius-btn' onClick={() => onRequest(item)} disabled={isAuthorized? false : true}>등기부등본요청하기</button>
                                                </td>
                                                {item.api_req_date && !item.api_rec_date? <td className='t22'>{item.api_req_date} (요청)</td> : null}
                                                {item.api_req_date && item.api_rec_date? <td className='t22'>{item.api_rec_date} (수신)</td> : null}
                                                {!item.api_req_date && !item.api_rec_date? <td className='t22 gray'>등기부등본미요청</td> : null}
                                                
                                                {/* api_req_date  api_rec_date  issuing_copy_reg  Y : 요청완료, P:요청중, N: 미요청*/}
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                                </>
                                : 
                                <tr>
                                    <td colSpan={'10'}>결과가 없습니다.</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>

                    {/* 등본 이미지 */}
                    <div className='implyImg'>
                        <div id='imgView' className='imgView'>
                            {selectedImg? 
                            <img id="selectImg" className='selectImg' src={selectedImg} alt='selectedImg' draggable={false}/> 
                            : 
                            <div className='noneSelectedImg'> 
                                <p>등기부등본이미지 미리보기</p>
                                <p>*각 리스트 클릭시 미리볼수 있습니다.</p>
                                {noImg? <p style={{color: 'red'}}>*이미지가 없습니다.</p> : null}
                            </div>
                            }
                        </div>
                        <div className='navImgBox'>
                            {selectedArr.map((item, idx) => (
                                <div key={idx} className='navImg'>
                                    <img src={item} alt="" onClick={(e) => onSelectImg(item)}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* 테이블 페이징 */}
                <div className="pageNation">
                    <div className="page-prev-btn">
                        <span onClick={onClickPrev}>{"<"}</span>
                    </div>
                    <div className="num">
                        <div>
                            {[...Array(pageCount)].map((item,idx) => {
                                if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                    return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                                }
                                return null;
                            })}
                        </div>
                    </div>
                    
                    <div className="page-next-btn">
                        <span onClick={onClickNext}>{">"}</span>
                    </div>
                </div>       
            </div>
        </ImplyWorkBlock>
    );
}

export default ImplyWork; 