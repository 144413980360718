import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { regAmt, addZoom, parseNumber } from '../common';
import EnWriteAdmin from '../components/EnWriteAdmin';
import {
    changeValue, delContent, doc_selectFolder, doc_selectImg,
    doc_upload_complete,
    getCombo,
    getContent,
    getFiles,
    imgRotation,
    initialize,
    s1_addRow, s1_removeRow, s1_rowChangeValue, s1_selectUser, s1_setLastIdx,
    s3_addRow, s3_removeRow, s3_rowChangeValue, s3_setLastIdx, s4_selectBroker, save, setDocID
} from '../modules/enWriteAdmin';
import dataURL from '../lib/dataURL';
import { funEntitleCalc, dataEntitleCalc } from '../lib/dataEntitleCalc';
import { dataEmptyRoomCalc } from '../lib/dataEmptyRoomCalc';
import { dataSystemCode } from '../lib/dataSystemCode';
import waiting from '../waiting.png';
import { ALERT } from '../lib/dataWord';

const EnWriteAdminContainer = () => {
    const [isShowDaumPost, setIsShowDaumPost] = useState(false);
    const folderDiv = useRef(null);
    const folderSlider = useRef(null);
    const [isShowFindUser, setIsShowFindUser] = useState(null);
    const [isShowFindBroker, setIsShowFindBroker] = useState(null);
    const [isShowUpload, setIsShowUpload] = useState(null);
    const [isCalcOK, setIsCalcOK] = useState(true);
    const [isCalcResult, setIsCalcResult] = useState("WAIT") // 지원가능 여부 : WAIT=대기, POSSIBLE=가능, IMPOSSIBLE=불가능
    const [calcComment, setCalcComment] = useState("") // 지원가능 여부 코멘트
    const [possibleRate, setPossibleRate] = useState([]);   // 입주자부담금 비율
    const [possibleAmt, setPossibleAmt] = useState(0);   // 입주자부담금 금액

    const [fileFirstDocID, setFileFirstDocID] = useState(null);    // 신청서에서 문서의 저장보다 파일업로드를 먼저 하였을 때, 임시적으로 갖고 있는 문서번호
    
    const cboLease = dataSystemCode.LEASE_TYPE;      //계약유형 ex) 신규,이주...
    const cboHouseType = dataSystemCode.HOUSING_TYPE;     //주택유형 ex) 아파트,다가구주택...
    const cboEmpty = dataSystemCode.EMPTY_TYPE;     //공실 구분

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state: {docNo, auth, func}} = location;

    const {
        user,
    } = useSelector(({user}) => ({
        user: user.user,
    }))
    const {
        doc_id,
        doc_status,

        //  Common
        cboApply,

        //  Step1
        winnerLastIdx,
        winner,

        //  Step2
        road_addr,
        road_addr_dtl,
        jibun_addr,
        sido,
        sigungu,
        town,
        road_name,
        building_num,
        building_name,
        house_type,
        house_area,
        room_count,
        house_selling,
        house_area_over1,
        house_area_over2,
        house_rent_type,
        rent_amt,
        monthly_rent_amt,
        minor_child_cnt,
        estate_no,
        wallpaper_flag,

        //  Step3
        priorityLastIdx,
        priority,
        rent_tot_amt,

        //  Step4
        broker_id,
        broker_company,
        broker_reg_no,
        broker_telno,
        
        //  서류폴더, 서류이미지
        selectFoler,
        docFolder,
        selectImg,
        docImages,

        originFileList,
    } = useSelector(({ enWriteAdmin }) => ({
        doc_id: enWriteAdmin.doc_id,
        doc_status: enWriteAdmin.doc_status,

        //  Common
        cboApply: Array.isArray(enWriteAdmin.cboApply) ? enWriteAdmin.cboApply.filter(item => item.use_flag === 'Y') : [],    // 사용 플래그 Y인 것들만 사용

        //  Step1
        winnerLastIdx: enWriteAdmin.winnerLastIdx,
        winner: enWriteAdmin.winner.map((item) => {
            return ({
                ...item,
                winner_charges: item.winner_charges === "0" ? "" : String(item.winner_charges).replace(regAmt, ","),
                add_charges: item.add_charges === "0" ? "" : String(item.add_charges).replace(regAmt, ","),
                monthly_rent: item.monthly_rent === "0" ? "" : String(item.monthly_rent).replace(regAmt, ","),
                lh_support_amt: item.lh_support_amt === "0" ? "" : String(item.lh_support_amt).replace(regAmt, ","),
            })
        }),

        //  Step2
        road_addr: !enWriteAdmin.road_addr ? "" : enWriteAdmin.road_addr,
        road_addr_dtl: !enWriteAdmin.road_addr_dtl ? "" : enWriteAdmin.road_addr_dtl,
        jibun_addr: !enWriteAdmin.jibun_addr ? "" : enWriteAdmin.jibun_addr,
        sido: !enWriteAdmin.sido ? "" : enWriteAdmin.sido,
        sigungu: !enWriteAdmin.sigungu ? "" : enWriteAdmin.sigungu,
        town: !enWriteAdmin.town ? "" : enWriteAdmin.town,
        road_name: !enWriteAdmin.road_name ? "" : enWriteAdmin.road_name,
        building_num: !enWriteAdmin.building_num ? "" : enWriteAdmin.building_num,
        building_name: !enWriteAdmin.building_name ? "" : enWriteAdmin.building_name,
        house_type: !enWriteAdmin.house_type ? "TYPE01" : enWriteAdmin.house_type,
        house_area: !enWriteAdmin.house_area ? "" : enWriteAdmin.house_area,
        room_count: !enWriteAdmin.room_count ? "" : enWriteAdmin.room_count,
        house_selling: enWriteAdmin.house_selling,
        house_area_over1: enWriteAdmin.house_area_over1,
        house_area_over2: enWriteAdmin.house_area_over2,
        house_rent_type: enWriteAdmin.house_rent_type,
        rent_amt: !enWriteAdmin.rent_amt ? "" :  String(enWriteAdmin.rent_amt).replace(regAmt, ","),
        monthly_rent_amt: !enWriteAdmin.monthly_rent_amt ? "" : String(enWriteAdmin.monthly_rent_amt).replace(regAmt, ","),
        minor_child_cnt: !enWriteAdmin.minor_child_cnt ? "" : enWriteAdmin.minor_child_cnt,
        estate_no: !enWriteAdmin.estate_no ? "" : enWriteAdmin.estate_no,
        wallpaper_flag: !enWriteAdmin.wallpaper_flag ? "N" : enWriteAdmin.wallpaper_flag,

        //  Step3
        priorityLastIdx: enWriteAdmin.priorityLastIdx,
        priority: enWriteAdmin.priority.map((item) => {
            return ({
                ...item,
                rent_deposit: String(item.rent_deposit).replace(regAmt, ","),
                rent_fee: String(item.rent_fee).replace(regAmt, ","),
                priority_payment: String(item.priority_payment).replace(regAmt, ","),
            })
        }),
        rent_tot_amt: !enWriteAdmin.rent_tot_amt ? "0" : String(enWriteAdmin.rent_tot_amt).replace(regAmt, ","),

        //  Step4
        broker_id: enWriteAdmin.broker_id,
        broker_company: enWriteAdmin.broker_company,
        broker_name: enWriteAdmin.broker_name,
        broker_reg_no: enWriteAdmin.broker_reg_no,
        broker_telno: enWriteAdmin.broker_telno,

        selectFoler: enWriteAdmin.selectFoler,
        docFolder: enWriteAdmin.docFolder,
        selectImg: enWriteAdmin.selectImg,
        docImages: enWriteAdmin.docImages,

        originFileList: enWriteAdmin.originFileList,
    }));

    // 공통 Event -------------------------------------------
    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onChecked = e => {
        if(e.target.name === "house_area_over1" || e.target.name === "house_area_over2") {
            setIsCalcOK(false); // 지원금 계산 여부 변경
            
            if(+house_area < 85) {
                ALERT("면적이 85m² 이상일 경우에만 체크해주세요.");
                return;
            }
        }

        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.checked,
            })
        );
    };
    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const onChangeNum = e => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                changeValue({
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }

    useEffect(() => {
        addZoom("")
    }, [selectImg])

    useEffect(() => {
        if(doc_id) {
            dispatch(getContent(doc_id));
        }
    }, [dispatch, doc_id])

    useEffect(() => {
        dispatch(setDocID(docNo));
    }, [dispatch, docNo])

    useEffect(() => {
        dispatch(getCombo("APPLY_TYPE"));

        return () => {
            //  언마운트시 초기화
            dispatch(initialize());
        };
    }, [dispatch])
    // ------------------------------------------- 공통 Event

    //  신청인정보 Event -------------------------------------------
    const onWinnerChange = (item, e) => {
        dispatch(
            s1_rowChangeValue({
                idx: item.idx,
                key: e.target.name,
                value: e.target.value,
            })
        );

        if(e.target.name === 'winner_type') {
            const selectApply = cboApply.find(x => x.sub_code === e.target.value);
            if(selectApply) {
                dispatch(
                    s1_rowChangeValue({
                        idx: item.idx,
                        key: 'type_calc_code',
                        value: selectApply.memo,
                    })
                );
            }
        }
    }
    const onAddWinner = e => {
        dispatch(s1_setLastIdx({value: winnerLastIdx + 1}));
        dispatch(s1_addRow());
    }
    const onRemoveWinner = (item, e) => {
        dispatch(s1_removeRow({idx: item.idx}));
    }
    const onShowFindUser = (item, e) => {
        if (!isShowFindUser) {
            setIsShowFindUser(item);
        };
    }
    const onCloseFindUser = () => {
        setIsShowFindUser(null);
    }
    const onSelectUser = (item) => {
        dispatch(
            s1_selectUser({
                user: item,
                row: isShowFindUser,
            })
        );
        setIsShowFindUser(null);
    }
    //  ------------------------------------------- 신청인정보 Event

    //  주택정보 Event -------------------------------------------
    const onShowDaumPost = () => {
        setIsShowDaumPost(!isShowDaumPost);
    }
    const handlePostCode = (data) => {
        let _building_num = data.roadAddress;
        _building_num = _building_num.replace(data.sido, '');
        _building_num = _building_num.replace(data.sigungu, '');
        _building_num = _building_num.replace(data.roadname, '').trim();

        dispatch(changeValue({key: 'road_addr', value: data.roadAddress,}));
        dispatch(changeValue({key: 'jibun_addr', value: (data.jibunAddress ? data.jibunAddress : data.autoJibunAddress),}));
        dispatch(changeValue({key: 'sido', value: data.sido,}));
        dispatch(changeValue({key: 'sigungu', value: data.sigungu,}));
        dispatch(changeValue({key: 'town', value: data.bname,}));
        dispatch(changeValue({key: 'road_name', value: data.roadname,}));
        dispatch(changeValue({key: 'building_num', value: _building_num,}));
        dispatch(changeValue({key: 'building_name', value: data.buildingName,}));

        setIsShowDaumPost(!isShowDaumPost);
    }
    //  ------------------------------------------- 주택정보 Event

    //  지원금 계산 Event -------------------------------------------
    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const s2_onChangeNum = e => {
        setIsCalcOK(false); // 지원금 계산 여부 변경
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                changeValue({
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    const s2_onWinnerChange = (item, key, e) => {
        setIsCalcOK(false); // 지원금 계산 여부 변경

        if(key === "charge_rate") {
            dispatch(
                s1_rowChangeValue({
                    idx: item.idx,
                    key: 'charge_rate',
                    value: e.target.value,
                })
            );
        }
        else {
            const re = /^[0-9\b]+$/;
            const value = parseNumber(e.target.value);

            if (value === '' || re.test(value)) {
                dispatch(
                    s1_rowChangeValue({
                        idx: item.idx,
                        key: e.target.name,
                        value: value.replace(regAmt, ","),
                    })
                );
            }
        }
    }
    const onCalcSupportAmt = () => {
        if(!winner || winner.length === 0 || !winner[0].winner_type) {
            ALERT("신청자 정보를 선택해주세요.");
            return;
        }
        if(!rent_amt || rent_amt === "0") {
            ALERT("전세금액을 입력해주세요.");
            return;
        }
        if(house_rent_type === "TYPE02") {  //보증부월세
            if(!monthly_rent_amt || monthly_rent_amt === "0") {
                ALERT("전세유형이 보증부월세인 경우 월세금액은 필수입력입니다.");
                return;
            }
        }

        let _codeIdx = +winner[0].type_calc_code;
        if(house_area_over1 || house_area_over2) {
            // _codeIdx = 4;   // 85m 이상 사유에 체크가 되어있다면 계산유형을 변경.
        }
        const applyType = dataEntitleCalc.find(x => String(x.codeIdx) === String(_codeIdx));
        if(applyType.defaultPay.rate.length > 0) {
            if(!winner[0].charge_rate || winner[0].charge_rate === "0") {
                ALERT("입주자부담금 비율을 선택해주세요.");
                return;
            }
        }

        let _persons = [], totalExtraPay = 0;
        winner.forEach(item => {
            totalExtraPay += +(item.add_charges.replace(/,/g, ""));

            _persons.push({
                codeIdx: +(_codeIdx === 4 ? _codeIdx : item.type_calc_code),    // 85m 이상 사유에 체크가 되어있다면 무조건 4번 유형
                defaultPayRate: (!item.charge_rate || item.charge_rate === '0' ? 0 : +item.charge_rate),
                extraPay: (!item.add_charges || item.add_charges === "0") ? 0 : item.add_charges.replace(/,/g, ""),
                winner_idx: item.idx,
            })
        });

        const retCalcAmt = funEntitleCalc({
            total: rent_amt.replace(/,/g, ""),                      // 전세금액총액(전세보증금)
            totlaExtraPay: totalExtraPay,                           // 추가부담금총액
            guaranteeMonthPay: monthly_rent_amt.replace(/,/g, ""),  // 보증부월세액
            persons: _persons,                                      // 개인별 정보
            childCount: minor_child_cnt,                            // 자식 수
        });

        if(!retCalcAmt) {
            ALERT("죄송합니다. 계산 중 오류가 발생하였습니다.");
            return;
        }
        
        if(retCalcAmt.errorSupportMoney) {
            setIsCalcResult("IMPOSSIBLE");
            setCalcComment(retCalcAmt.errorMessage);
            setIsCalcOK(false);
        }
        else {
            let isOverCharge = false;   //  부담금이 전세금을 초과하는지

            for(let i = 0; i < retCalcAmt.persons.length; i++) {
                const person = retCalcAmt.persons[i];

                if(
                    (!person.monthPay || +person.monthPay <= 0) ||
                    (!person.supportMoney || +person.supportMoney <= 0)
                ) {
                    isOverCharge = true;
                }

                //  추가부담금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'add_charges',
                        value: String(person.extraPay).replace(regAmt, ","),
                    })
                );

                //  입주자부담금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'winner_charges',
                        value: String(person.defaultPay).replace(regAmt, ","),
                    })
                );

                //  월임대료
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'monthly_rent',
                        value: String(person.monthPay).replace(regAmt, ","),
                    })
                );

                //  LH지원금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'lh_support_amt',
                        value: String(person.supportMoney).replace(regAmt, ","),
                    })
                );
            };

            if(!isOverCharge) {
                setIsCalcResult("POSSIBLE");
                setCalcComment("");
                setIsCalcOK(true); // 지원금 계산 여부 변경
            }
            else {
                setIsCalcResult("IMPOSSIBLE");
                setCalcComment("입주자 추가부담금과 입주자 부담금의 합계가 전세금보다 작아야하며, 월임대료가 1원 이상이어야 합니다.");
                setIsCalcOK(false); // 지원금 계산 여부 변경
            }
        }
    }
    //  선택된 지원유형에서 선택가능한 입주자부담금 비율
    useEffect(() => {
        if(!winner || winner.length === 0 || !winner[0].winner_type) {
            return;
        }
        dataEntitleCalc.forEach(applyType => {
            if(String(applyType.codeIdx) === String(winner[0].type_calc_code)) {
                setPossibleRate(applyType.defaultPay.rate);
                setPossibleAmt(applyType.defaultPay.static);
            }
        });
    },[winner])
    //  전세라면 보증부월세 초기화
    useEffect(() => {
        if(house_rent_type === "TYPE01") {
            dispatch(
                changeValue({
                    key: 'monthly_rent_amt',
                    value: '',
                })
            );
        }
    }, [dispatch, house_rent_type])
    //  ------------------------------------------- 지원금 계산 Event

    //  선순위 Event -------------------------------------------
    const onPriorityChange = (item, e) => {
        if(!road_addr) {
            ALERT("주소를 먼저 입력해주세요.");
            return;
        }

        dispatch(
            s3_rowChangeValue({
                idx: item.rent_seq,
                key: e.target.name,
                value: e.target.value,
            })
        );

        //  타입 또는 방개수 변경시, 지역에 맞는 금액을 적용한다.
        if(e.target.name === "empty_type" || e.target.name === "room_cnt") {
            const _priority = priority.find(x => x.rent_seq === item.rent_seq);
            if(!_priority) return;

            const _roomType = e.target.name === "empty_type" ? e.target.value : _priority.empty_type;
            const _roomCount = e.target.name === "room_cnt" ? e.target.value : _priority.room_cnt;

            //  단, 공실 또는 집주인이 사용중인 경우에만 실행한다.
            if((_roomType.indexOf("EMPTY") > -1) || _roomType.indexOf("OWNER") > -1) {
                const priorityData = +(dataEmptyRoomCalc({
                    addressStr: jibun_addr,
                    emptyType: (_roomType.indexOf("HOUSE") > -1 ? 'h' : 's'),
                }))

                //  타입이 주택이라면 방개수 * 금액을 적용
                if(_roomType.indexOf("HOUSE") > -1) {
                    dispatch(
                        s3_rowChangeValue({
                            idx: item.rent_seq,
                            key: 'priority_payment',
                            value: priorityData * _roomCount,
                        })
                    );
                }
                //  타입이 주택이 아니라면 호실당 금액을 적용
                else {
                    dispatch(
                        s3_rowChangeValue({
                            idx: item.rent_seq,
                            key: 'priority_payment',
                            value: priorityData,
                        })
                    );
                }
            }
            else {
                dispatch(
                    s3_rowChangeValue({
                        idx: item.rent_seq,
                        key: 'priority_payment',
                        value: '',
                    })
                );
                //  임대중 이면서 주택이 아니라면 방개수에 0을 적용
                if(_roomType.indexOf("HOUSE") === -1) {
                    dispatch(
                        s3_rowChangeValue({
                            idx: item.rent_seq,
                            key: 'room_cnt',
                            value: '0',
                        })
                    );
                }
            }
        }
    }
    const onPriorityChangeNum = (item, e) => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                s3_rowChangeValue({
                    idx: item.rent_seq,
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    const onAddPriority = e => {
        dispatch(s3_setLastIdx({value: priorityLastIdx + 1}));
        dispatch(s3_addRow());
    }
    const onRemovePriority = (item, e) => {
        dispatch(s3_removeRow({idx: item.rent_seq}));
    }
    //  ------------------------------------------- 선순위 Event

    //  중개사 Event -------------------------------------------
    const onShowFindBroker = (item, e) => {
        if (!isShowFindBroker) {
            setIsShowFindBroker(item);
        };
    }
    const onCloseFindBroker = () => {
        setIsShowFindBroker(null);
    }
    const onSelectBroker = (item) => {
        dispatch(
            s4_selectBroker({
                broker: item,
            })
        );
        setIsShowFindBroker(null);
    }
    //  ------------------------------------------- 중개사 Event

    //  Image Event -------------------------------------------
    const onSelectFolder = (item, e) => {
        dispatch(doc_selectFolder(item));

        const firstInFolder = docImages.find(x => x.file_type === item.code);
        dispatch(doc_selectImg(firstInFolder));
        initImgScale();
    }
    const onSelectImg = (item, e) => {
        dispatch(doc_selectImg(item));
        initImgScale();
    }
    const initImgScale = () => {
        const initImg = document.getElementById("selectImg");
        initImg.style.transform = 'scale(1)';
        const divRotate = document.getElementById("div-rotate");
        divRotate.style.transform = 'rotate(' + selectImg.rotation + ')';
        divRotate.style.left = '0px';
        divRotate.style.top = '0px';
    }
    //  onPrevClick, onNextClick :: 현재 folderSlider가 차지하는 너비만큼 버튼이 이동될 수 있도록 함.
    //  이전 폴더 Slider
    const onPrevClick = () => {
        let movePx = Math.floor(folderDiv.current.clientWidth / 130) * 130;
        
        if (folderSlider.current.offsetLeft < 0) {
            const nextPostion = folderSlider.current.offsetLeft + movePx;
            folderSlider.current.style = "left:" + nextPostion + "px";
        }
    }
    //  다음 폴더 Slider
    const onNextClick = () => {
        let movePx = Math.floor(folderDiv.current.clientWidth / 130) * 130;
        
        const nextPostion = folderSlider.current.offsetLeft - movePx;
        if (Math.abs(nextPostion) < folderSlider.current.clientWidth) {
            folderSlider.current.style = "left:" + nextPostion + "px";
        }
    }
    const onWaiting = (e) => {
        e.target.src = waiting;
    }
    const onImgRotation = () => {
        const divRotate = document.getElementById("div-rotate");

        const regex = /[^0-9]/g;    // 숫자가 아닌 문자열을 선택하는 정규식
        const currRotate = +divRotate.style.transform.replace(regex, "");

        let nextRotate;
        switch(currRotate) {
            case 0: nextRotate = 90; break;
            case 90: nextRotate = 180; break;
            case 180: nextRotate = 270; break;
            case 270: nextRotate = 0; break;
            default: nextRotate = 0; break;
        }
        dispatch(changeValue({
            key: 'selectImg',
            value: {
                ...selectImg,
                rotation: nextRotate,
            }
        }))
    }
    const onImgRotateSave = () => {
        if(!selectImg.file_id) {
            ALERT("첨부 이미지 새로고침 후 다시 시도해주세요.");
            return;
        }

        const divRotate = document.getElementById("div-rotate");
        const regex = /[^0-9]/g;    // 숫자가 아닌 문자열을 선택하는 정규식
        const currRotate = +divRotate.style.transform.replace(regex, "");
        dispatch(
            imgRotation({
                doc_id: docNo,
                file_id: selectImg.file_id,
                file_url: selectImg.src,
                rotation: currRotate,
            })
        )

        //  썸네일 방향 변경
        const _docImages = docImages.map((item) =>
            (item.file_id === selectImg.file_id && item.file_data === selectImg.src) ? { ...item, rotation: currRotate } : item,
        );
        dispatch(changeValue({
            key: 'docImages',
            value: _docImages,
        }))
    }
    const onRefreshImg = () => {
        dispatch(
            getFiles({
                doc_id: docNo,
                doc_type: "EN_WRITE",
            })
        )
    }
    //  ------------------------------------------- Image Event

    //  파일업로드 모달 오픈
    const onFileUploadClick = () => {
        setIsShowUpload('ENWRITEADMIN');
    }
    //  파일업로드 저장
    const onSaveFileUpload = (_doc_id, fileList) => {
        setIsShowUpload(null);
        setFileFirstDocID(_doc_id);

        let _fileList = [];
        fileList.forEach(file => {
            if(file.use_flag === 'Y') {
                _fileList.push({
                    file_type: file.file_type,
                    file_type_name: '기타',
                    file_data: file.file_data,
                    file_id: file.file_id,
                    rotation: file.rotation,
                })
            }
        });

        //  업로드한 파일을 우측에 표현
        dispatch(
            doc_upload_complete(_fileList)
        )

        //  업로드한 파일을 기존파일리스트에 덮어쓰기
        dispatch(changeValue({
            key: 'originFileList',
            value: fileList
        }))
    }
    //  서류분류
    const onDocSortClick = () => {
        navigate(dataURL.DocSortPage, {state : {
            docNo: (fileFirstDocID ? fileFirstDocID : doc_id),
            docType: 'EN_WRITE',
        }});
    }
    //  신청하기
    const onRequestClick = () => {
        if(!isCalcOK) {
            ALERT("[지원금 계산] 후 신청해주세요.");
            return;
        }

        //  신청인 정보 ===================================
        let contractType, calcType;

        for(let i = 0; i < winner.length; i++) {
            const _winner = winner[i];

            if(!_winner.winner_id) {
                ALERT("등록된 당첨자를 선택해주세요.");
                return;
            }

            if(!_winner.contract_type) {
                ALERT("계약유형을 선택해주세요.");
                return;
            }

            if(_winner.contract_type === "NEW" && _winner.is_duplicate === 'Y') {
                ALERT(_winner.winner_name + " 님께서는 기지원자로 중복 신청이 불가합니다.");
                return;
            }

            if(_winner.contract_type === "NEW" && _winner.info_yn === 'Y') {
                ALERT(_winner.winner_name + " 님께서는 기존 고객으로 신규 신청이 불가합니다.\n재계약(이주/증액/동일/감액)으로 신청해주세요.");
                return;
            }

            if(_winner.contract_type !== "NEW" && _winner.info_yn === 'N') {
                ALERT(_winner.winner_name + " 님께서는 신규 지원자로\n재계약(이주/증액/동일/감액)을 신청할 수 없습니다.")
                return;
            }

            //  당첨자들의 계약유형이 다른지 확인.
            if (contractType) {
                if (contractType !== _winner.contract_type) {
                    ALERT("지원 신청자들의 계약유형이 다릅니다.");
                    return;
                }
            }
            contractType = _winner.contract_type;

            //  당첨자의 지원유형이 있는지 확인.
            if (!_winner.winner_type) {
                ALERT("당첨자의 지원유형을 선택해주세요.");
                return;
            }

            //  당첨자들의 지원유형이 다른지 확인.
            if (calcType) {
                if(calcType === "8" || calcType === "9") {
                    if(_winner.type_calc_code !== "8" && _winner.type_calc_code !== "9") {
                        ALERT("지원유형이 [청년]인 경우만 셰어가 가능합니다.");
                        return;
                    }
                }
                else {
                    ALERT("지원유형이 [청년]인 경우만 셰어가 가능합니다.");
                    return;
                }
            }
            
            calcType = _winner.type_calc_code;
        }
        //  =================================== 신청인 정보

        //  주택정보 ===================================
        if(!road_addr || !road_addr_dtl) {
            ALERT("주소를 모두 입력해주세요.");
            return;
        }
        if(!house_area) {
            ALERT("면적을 입력해주세요.");
            return;
        }
        if(!room_count) {
            ALERT("방 개수를 입력해주세요.");
            return;
        }
        if(!house_selling) {
            ALERT("매매중여부를 선택해주세요.");
            return;
        }
        if(house_area > 85) {
            if(!house_area_over1 && !house_area_over2) {
                ALERT("주택 면적 85m² 초과사유를 선택해주세요.");
                return;
            }
        }
        if(!house_rent_type) {
            ALERT("전세유형을 선택해주세요.");
            return;
        }
        if(!rent_amt) {
            ALERT("전세금액을 입력해주세요.");
            return;
        }
        if(house_rent_type === "TYPE02") {  //보증부월세
            if(!monthly_rent_amt) {
                ALERT("전세유형이 보증부월세인 경우 월세금액은 필수입력입니다.");
                return;
            }
        }
        //  =================================== 주택정보

        //  선순위 정보 ===================================
        for(let i = 0; i < priority.length; i++) {
            const item = priority[i];

            if(item.use_flag !== 'Y') {
                continue;
            }

            if(!item.rent_floor) {
                ALERT("층수를 입력해주세요.");
                return;
            }
            if(!item.room_no) {
                ALERT("호수를 입력해주세요.");
                return;
            }
            if(item.empty_type.indexOf('HOUSE') > -1) {
                if(!item.room_cnt) {
                    ALERT("방개수를 입력해주세요.");
                    return;
                }
            }
        };
        //  =================================== 선순위 정보

        //  공인중개사 정보 ===================================
        //  22.04.19 신세기 요청으로 부동산 validation 제거
        // if(!broker_id) {
        //     ALERT("공인중개사를 선택해주세요.");
        //     return;
        // }
        //  =================================== 공인중개사 정보

        for(let i = 0; i < winner.length; i++) {
            const _winner = winner[i];
            //  당첨자 정보를 LH에서 받아오지 않았으면서 지원유형이 없다면 최초 한번만 지원유형 수정이 가능하다.
            if(_winner.select_win_type === 'Y' && _winner.info_yn === 'N') {
                if(!window.confirm('당첨자 ' + _winner.winner_name + ' 님의 지원유형을 확인하셨습니까?\n신청 후 변경할 수 없습니다.')) {
                    return;
                }
            }
        }
        
        if (window.confirm("지원 내용을 신청합니까?")) {
            onSaveClick('Y');
        }
    }
    //  저장
    const onSaveClick = (isReq) => {
        if(!(!doc_status || doc_status === 'ENWRITE' || doc_status === 'ENSAVE' || doc_status === 'ENDOCFAIL')) {
            ALERT("이미 접수된 문서입니다.")
            return;
        }
        if(!road_addr || !road_addr_dtl) {
            ALERT("주소를 모두 입력해주세요.");
            return;
        }
        if(!house_area) {
            ALERT("면적을 입력해주세요.");
            return;
        }
        if(house_area > 85) {
            if(!house_area_over1 && !house_area_over2) {
                ALERT("주택 면적 85m² 초과사유를 선택해주세요.");
                return;
            }
        }
        if(!house_rent_type) {
            ALERT("전세유형을 선택해주세요.");
            return;
        }
        if(!rent_amt) {
            ALERT("전세금액을 입력해주세요.");
            return;
        }

        const contractType = winner.length > 0 ? winner[0].contract_type : "";
        
        let senior_rent_data = [], _empty_tot_amt = 0;
        priority.forEach(item => {
            const _senior_rent_data = {
                rent_seq: item.rent_seq,
                empty_type: item.empty_type,
                rent_floor: item.rent_floor,
                room_no: item.room_no,
                room_cnt: !item.room_cnt ? "0" : item.room_cnt,
                rent_deposit: !item.rent_deposit ? "0" : item.rent_deposit.replace(/,/g, ""),
                rent_fee: !item.rent_fee ? "0" : item.rent_fee.replace(/,/g, ""),
                priority_payment: !item.priority_payment ? "0" : item.priority_payment.replace(/,/g, ""),
                remark: item.remark,
                use_flag: item.use_flag,
            }
            senior_rent_data.push(_senior_rent_data);

            _empty_tot_amt += +(!item.priority_payment ? "0" : item.priority_payment.replace(/,/g, ""));
        });

        let totLhSupport = 0;   // 모든 당첨자가 받는 실지원금 합계액
        winner.forEach(_winner => {
            totLhSupport += +(_winner.lh_support_amt.replace(/,/g, ""));
        });

        const _winner_info = winner.filter(x => x.winner_id);

        dispatch(
            save({
                doc_id: (fileFirstDocID ? fileFirstDocID : doc_id),
                contract_type: contractType,
                road_addr: road_addr,
                road_addr_dtl: road_addr_dtl,
                jibun_addr: jibun_addr,
                sido: sido,
                sigungu: sigungu,
                town: town,
                road_name: road_name,
                building_num: building_num,
                building_name: building_name,
                house_type: house_type,
                house_area: !house_area ? "0" : String(house_area).replace(/,/g, ""),
                room_cnt: !room_count ? "0" : room_count,
                house_selling: house_selling,
                house_area_over1: !house_area_over1 ? "N" : "Y",
                house_area_over2: !house_area_over2 ? "N" : "Y",
                rent_type: house_rent_type,
                rent_amt: !rent_amt ? "0" : rent_amt.replace(/,/g, ""),
                monthly_amt: !monthly_rent_amt ? "0" : monthly_rent_amt.replace(/,/g, ""),
                minor_child_cnt: !minor_child_cnt ? "0" : minor_child_cnt,
                estate_no: estate_no,
                wallpaper_flag: wallpaper_flag,
                support_amt: totLhSupport,
                broker_id: broker_id ? broker_id : user.user_id,
                use_flag: "Y",
                rent_tot_amt: !rent_tot_amt ? "0" : rent_tot_amt.replace(/,/g, ""),
                empty_tot_amt: _empty_tot_amt,
                senior_rent: senior_rent_data,
                winner_name: winner[0].winner_id ? '' : winner[0].winner_name,
                winner_birth: winner[0].winner_id ? '' : winner[0].winner_no,
                winner_info: _winner_info.map((item) => {
                    return {
                        ...item,
                        charge_rate: !item.charge_rate ? "0" : item.charge_rate,
                        family_cnt: !item.family_cnt ? "0" : item.family_cnt,
                        lh_support_amt: !item.lh_support_amt ? "0" : item.lh_support_amt.replace(/,/g, ""),
                        winner_charges: !item.winner_charges ? "0" : item.winner_charges.replace(/,/g, ""),
                        add_charges: !item.add_charges ? "0" : item.add_charges.replace(/,/g, ""),
                        monthly_rent: !item.monthly_rent ? "0" : item.monthly_rent.replace(/,/g, ""),
                    }
                }),
                isReq: isReq,
            })
        )
        
        if(isReq === "Y") {
            dispatch(changeValue({
                key: 'doc_status',
                value: 'ENREQ',
            }))
        }

        setFileFirstDocID(null);
    }
    const onDeleteWrite = () => {
        if(window.confirm("작성중인 신청서를 정말 삭제하시겠습니까?\n삭제 후 되돌릴 수 없습니다.")) {
            if(doc_id) {
                dispatch(delContent(doc_id));
            }

            //  삭제 후 리스트 페이지로 이동시킨다.
            const date = new Date();
            const year = date.getFullYear();
            const month = ("0"+(date.getMonth() + 1)).slice(-2);
            const month_m = ("0"+date.getMonth()).slice(-2);

            const listValue = {
                all_search: func.some((el) => el.id==="FUNC061")? "Y" : "N",
                types: ""
            }

            navigate(`/list/apply?from_date=${`${year - 2}-${month_m}-01`}&to_date=${`${year}-${month}-${new Date(year, month, 0).getDate()}`}&type=${''}&status=${listValue.types}&user_type=${'WINNER'}&user=${''}&all_search=${listValue.all_search}&page=${'1'}&feedback_status=${''}&area=${''}`, {
                replace: true,
                state: {
                    func: func,
                    auth: user.user_auth,
                    listValue: listValue,
                    statusArr: [],
                    applyTypeArr: [],
                }
            })
        }
    }

    const propDatas = {
        doc_status,
        doc_id,
        auth,

        //  Common
        cboLease,
        cboApply,
        cboHouseType,
        cboEmpty,
        
        //  Step1
        winner,
        possibleRate,
        possibleAmt,

        //  Step2
        road_addr,
        road_addr_dtl,
        house_type,
        house_area,
        room_count,
        house_selling,
        house_area_over1,
        house_area_over2,
        house_rent_type,
        rent_amt,
        monthly_rent_amt,
        minor_child_cnt,
        estate_no,
        wallpaper_flag,
        isCalcResult,       //  지원가능 여부
        calcComment,        //  지원가능 여부 코멘트

        //  Step3
        priority,
        rent_tot_amt,

        //  Step4
        broker_company,
        broker_telno,
        broker_reg_no,

        //  서류폴더, 서류이미지
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        onWaiting,
        onImgRotation,
        onImgRotateSave,
        onRefreshImg,

        //  사용자검색 모달
        isShowFindUser,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,

        //  중개사 모달
        isShowFindBroker,

        //  파일업로드 모달
        originFileList,
        isShowUpload,
        setIsShowUpload,
        onSaveFileUpload,

        //  폴더 슬라이드 위치
        folderDiv,
        folderSlider,

        onChange,
        onChecked,
        onChangeNum,
        
        onAddWinner,
        onRemoveWinner,
        onWinnerChange,
        
        onShowDaumPost,
        handlePostCode,

        s2_onChangeNum,
        s2_onWinnerChange,
        onCalcSupportAmt,

        onPriorityChange,
        onPriorityChangeNum,
        onAddPriority,
        onRemovePriority,

        onShowFindUser,
        onCloseFindUser,
        onSelectUser,

        onShowFindBroker,
        onCloseFindBroker,
        onSelectBroker,

        onSelectFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,

        onFileUploadClick,
        onDocSortClick,
        onRequestClick,
        onSaveClick,
        onDeleteWrite,
    }
    return (
        <>
            <EnWriteAdmin {...propDatas}/>
        </>
    );
}

export default EnWriteAdminContainer;