import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as authAPI from '../lib/api/auth';
import * as approvalAPI from '../lib/api/approval';
import * as adminAPI from '../lib/api/admin';
import { takeLatest, call, takeEvery } from 'redux-saga/effects';
import dataMenu from "../lib/dataMenu";
import { ALERT } from '../lib/dataWord';

const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes('user/CHECK');
const [SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE] = createRequestActionTypes('auth/SEARCH');
const [APPUSER_REG, APPUSER_REG_SUCCESS, APPUSER_REG_FAILURE] = createRequestActionTypes('auth/APPUSER_REG');
const [APPUSER_SEARCH, APPUSER_SEARCH_SUCCESS, APPUSER_SEARCH_FAILURE] = createRequestActionTypes('auth/APPUSER_SEARCH');
const [APPUSER_LIST, APPUSER_LIST_SUCCESS, APPUSER_LIST_FAILURE] = createRequestActionTypes('auth/APPUSER_LIST');
const [GET_MAINCOUNT, GET_MAINCOUNT_SUCCESS, GET_MAINCOUNT_FAILURE] = createRequestActionTypes('auth/GET_MAINCOUNT');
const [PWD_CHECK, PWD_CHECK_SUCCESS, PWD_CHECK_FAILURE] = createRequestActionTypes('auth/PWD_CHECK');
const [VIEW, VIEW_SUCCESS, VIEW_FAILURE] = createRequestActionTypes('auth/VIEW');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('auth/GET_COMBO');

const CHANGE_VALUE = 'auth/CHANGE_VALUE';
const CHANGE_SEARCH = 'auth/CHANGE_SEARCH';
const CHANGE_PWD = 'auth/CHANGE_PWD';
const INITIALIZE = 'auth/INITIALIZE';

const TEMP_SET_USER = 'user/TEMP_SET_USER';
const LOGOUT = 'user/LOGOUT';
const GET_MENU = 'user/GET_MENU';
const APPROVE_UP = 'user/APPROVE_UP';
const APPROVE_DOWN = 'user/APPROVE_DOWN';
const APPROVE_ADD = 'user/APPROVE_ADD';
const APPROVE_DEL = 'user/APPROVE_DEL';
const APPROVE_SET_TARGET = 'user/APPROVE_SET_TARGET';
const CHANGE_FILE = 'user/CHANGE_FILE';
const INIT_PWDCHECK = 'user/INIT_PWDCHECK';

export const initialize = createAction(INITIALIZE);
export const tempSetUser = createAction(TEMP_SET_USER, user => user);
export const check = createAction(CHECK);
export const logout = createAction(LOGOUT);
export const getMenu = createAction(GET_MENU);
export const up = createAction(APPROVE_UP, body => body);
export const down = createAction(APPROVE_DOWN, body => body);
export const appuserReg = createAction(APPUSER_REG, body => body);
export const appuserSearch = createAction(APPUSER_SEARCH, body => body);
export const appuserList = createAction(APPUSER_LIST);
export const add = createAction(APPROVE_ADD, ({target}) => ({target}));
export const del = createAction(APPROVE_DEL, body => body);
export const setTarget = createAction(APPROVE_SET_TARGET,  ({item, targetIdx}) => ({item, targetIdx}));
export const search = createAction(SEARCH, (body) => ({...body})) //({...body}));
export const changeValue = createAction(CHANGE_VALUE, (body) => (body));
export const changePwd = createAction(CHANGE_PWD, ({key, value}) => ({key, value}));
export const changeFile = createAction(CHANGE_FILE, ({name, file_name, file_data, contenttype}) => ({name, file_name, file_data, contenttype}));
export const changeSearch = createAction(CHANGE_SEARCH, ({name}) => ({name}));
export const getMainCount = createAction(GET_MAINCOUNT, (body) => (body));
export const pwdCheck = createAction(PWD_CHECK, (body) => (body));
export const view = createAction(VIEW);
export const initPwdCheck = createAction(INIT_PWDCHECK);
export const getCombo = createAction(GET_COMBO);

const checkSaga = createRequestSaga(CHECK, authAPI.check);
const searchSaga = createRequestSaga(SEARCH, authAPI.search);
const appuserRegSaga = createRequestSaga(APPUSER_REG, approvalAPI.appuser_reg);
const appuserSearchSaga = createRequestSaga(APPUSER_SEARCH, authAPI.approver_search);
const appuserListSaga = createRequestSaga(APPUSER_LIST, approvalAPI.appuser_list);
const getMainCountSaga = createRequestSaga(GET_MAINCOUNT, authAPI.mainCount);
const pwdCheckSaga = createRequestSaga(PWD_CHECK, authAPI.pwdCheck);
const viewSaga = createRequestSaga(VIEW, authAPI.view);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_search);

const checkFailureSaga = () => {
    try {
        localStorage.removeItem('user');
    } catch (e) {
    }
}

function* logoutSaga() {
    try {
        yield call(authAPI.logout);
        localStorage.removeItem('user');
        window.location.replace('/');
    } catch (e) {
    }
}

export function* userSaga() {
    yield takeLatest(CHECK, checkSaga);
    yield takeLatest(CHECK_FAILURE, checkFailureSaga);
    yield takeLatest(LOGOUT, logoutSaga);
    yield takeLatest(APPUSER_REG, appuserRegSaga);
    yield takeLatest(APPUSER_SEARCH, appuserSearchSaga);
    yield takeEvery(SEARCH, searchSaga);
    yield takeEvery(APPUSER_LIST, appuserListSaga);
    yield takeEvery(GET_MAINCOUNT, getMainCountSaga);
    yield takeLatest(PWD_CHECK, pwdCheckSaga);
    yield takeLatest(VIEW, viewSaga);
    yield takeLatest(GET_COMBO, getComboSaga);
    // yield takeLatest(TTT, ttt);
}

const initialState = {
    user: null,         // 로그인 체크 (check)
    userInfo: null,     // 사용자 조회 (view) -> 단일 조회
    userList: [],       // 사용자 조회 (search) -> 여러건 조회



    user_pwd_prev: '',
    user_pwd: '',
    user_pwd_check: '',
    menu: null,
    cboApply: [],

    // 결재라인 수정
    searchApprove: "",
    result: [],
    app_normal: [], // 검토자
    app_check: [], // 결재확인자
    target: {
        targetIdx: null,
        targetType: null,
        item: null
    },
    fileList: [],

    checkError: null,
    
    getMenuSuccess: false,

    pwdCheckResult: '',

    winnerWidget: [], // 메인화면 당첨자 위젯 정보

};

const changeArray = (arr, idx, item, direction) => {
    let newArr = [...arr];
    let newIdx = idx;
    let valid = false;

    //  첫번째 아이템에서 Sorting Up 발생을 방지함
    const idxByApprType = arr.filter(x => x.app_auth === item.app_auth).indexOf(arr.find(x => x.user_id === item.user_id));
    if(direction < 1 && idxByApprType === 0) {
        return {newArr, newIdx};
    }

    if(direction < 1 && idx - 1 >= 0) {
        valid = true;
    }
    else if(direction > 0 && idx + 1 <= arr.length - 1){
        valid = true;
    }

    if(valid) {
        newIdx += direction;
        newArr.splice(idx, 1);
        newArr.splice(newIdx, 0, item);
    }
    return {newArr, newIdx};
}

export default handleActions(
    {
        [INITIALIZE]: (state) => ({
            ...state,
            searchApprove: "",
            result: [],
            app_normal: [], 
            app_check: [],
            target: {
                targetIdx: null,
                targetType: null,
                item: null
            },
            fileList: [],
        }),
        [TEMP_SET_USER]: (state, { payload: user }) => ({
            ...state,
            user,
        }),
        [CHANGE_VALUE]: (state, { payload: {field, key, value}}) =>{
            if(field) {
                return ({
                    ...state,
                    [field]:{
                        ...state[field], 
                        [key]:value
                    }
                })
            }
            else {
                return ({
                    ...state,
                    [key]:value
                })
            }
        },



        [GET_COMBO_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                cboApply: payload
            })
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                cboApply: [],
                error
            }
        },
        [CHECK_SUCCESS]: (state, { payload: user }) => ({
            ...state,
            // 이미 user 에 값이 있으면 그대로, 비어있으면 다시 할당
            user: state.user? state.user : user,
            checkSuccess: 'Y'
        }),
        [CHECK_FAILURE]: (state, { payload: error }) => {

            // 로그인, 회원가입 제외하고 나머지 페이지에서 check 실패하면 로그인페이지로 이동
            if(window.location.pathname === '/'
                || window.location.pathname === '/reg'
                || window.location.pathname === '/login/BROKER'
                || window.location.pathname === '/login/STAFF'
                || window.location.pathname === '/login/USER') {
                //
            }
            else if(window.location.pathname !== '/' || window.location.pathname !== '/reg') {
                ALERT("세션이 만료되어 자동 로그아웃 되었습니다.")
                window.location.assign('/')
            }
            // window.history.go('/')
            return ({
                ...state,
                user: null,
                checkError: error,
                checkSuccess: 'N'
            })
        },
        [LOGOUT]: state => ({
            ...state,
            user: null,
            menu: null,
        }),
        [GET_MENU]: (state) => {
            let _func = [1,2,3];
            const menuArr = state.user && state.user.auth_menu ? state.user.auth_menu.split(',') : [];
            let _menu = [];

            dataMenu.menu.forEach((item) => {
                _func = [];
                if(menuArr.find((el) => el=== item.id)) {
                    if(item.func.length > 0) {
                        item.func.forEach((subitem) => {
                            if(menuArr.some((el) => el=== subitem.id)) {
                                _func.push({
                                    name: subitem.name,
                                    id: subitem.id
                                })
                            }
                        })
                    }
                    _menu.push({
                        id: item.id,
                        name: item.name,
                        router: item.router,
                        isHeader: item.isHeader,
                        type: item.type,
                        count: item.count,
                        func: _func,
                        sort: item.sort,
                    })
                }
            })

            //  sort 순서대로 정렬
            _menu.sort((a, b) => {
                return a.sort - b.sort;
            });

            return ({
                ...state,
                menu: state.menu? state.menu : _menu,
                getMenuSuccess: true
            })
        },
        [SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
            ...state,

            userList: payload,
            userInfo: payload,
        })},
        [SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                error: payload,
                userList: [],
            })
        },
        [APPROVE_UP]: (state) => {
            const {item: {app_auth}} = state.target;
            let targetArr = [];
            let targetStr = '';

            // 대상 지정
            // 검토자
            if(app_auth === 'APPROVAL') {
                targetArr = state.app_normal
                targetStr = 'app_normal'
            }
            // 결재확인자
            else if(app_auth === 'APPCHECK') {
                targetArr = state.app_check
                targetStr = 'app_check'
            }
            const {newArr, newIdx} = changeArray(targetArr, state.target.targetIdx, state.target.item, -1)
            
            // 결과값 저장

            return ({
                ...state,
                [targetStr]: newArr,
                target: {
                    ...state.target,
                    targetIdx: newIdx,
                    targetType: app_auth,
                }
            })
        },
        [APPROVE_DOWN]: (state) => {
            const {item: {app_auth}} = state.target;
            let targetArr = [];
            let targetStr = '';

            // 대상 지정
            // 검토자
            if(app_auth === 'APPROVAL') {
                targetArr = state.app_normal
                targetStr = 'app_normal'
            }
            // 결재확인자
            else if(app_auth === 'APPCHECK') {
                targetArr = state.app_check
                targetStr = 'app_check'
            }

            const {newArr, newIdx} = changeArray(targetArr, state.target.targetIdx, state.target.item, 1)
            return ({
                ...state,
                [targetStr]: newArr,
                target: {
                    ...state.target,
                    targetIdx: newIdx,
                    targetType: app_auth,
                }
            })
        },
        [APPROVE_ADD]: (state, {payload: {target}}) => {
            const {app_auth} = target;
            let targetArr = [];
            let targetStr = '';

            // 대상 지정
            // 검토자
            if(app_auth === 'APPROVAL') {
                targetArr = state.app_normal
                targetStr = 'app_normal'
            }
            // 결재확인자
            else if(app_auth === 'APPCHECK') {
                targetArr = state.app_check
                targetStr = 'app_check'
            }

            if(!targetArr.find(x => x.user_id === target.user_id)) {
                targetArr = targetArr.concat({...target, app_name: target.user_name, app_phone: target.user_phone});
            }
            else {
                ALERT('이미 등록되어 있습니다.')
            }

            return ({
                ...state,
                [targetStr]: targetArr
            })
        },
        [APPROVE_SET_TARGET]: (state, {payload: {targetIdx, item}}) => ({
            ...state,
            target: {
                targetIdx: item.app_auth === state.target.targetType && state.target.targetIdx === targetIdx? null : targetIdx,
                item: item.app_auth === state.target.targetType && state.target.targetIdx === targetIdx? null : item,
                targetType: item.app_auth === state.target.targetType && state.target.targetIdx === targetIdx? null : item.app_auth
            }
        }),
        [APPROVE_DEL]: (state, {payload: {targetIdx, item}}) => {
            const {app_auth} = item;
            let targetStr = '';

            // 검토자
            if(app_auth === 'APPROVAL') {
                targetStr = 'app_normal'
            }
            // 결재확인자
            else if(app_auth === 'APPCHECK') {
                targetStr = 'app_check'
            }
            return ({
            ...state,
            [targetStr]: state[targetStr].filter((el, idx) => idx !== targetIdx),
            target: {
                targetIdx: null,
                targetType: null,
                item: null
            },
        })},
        [CHANGE_PWD]: (state, {payload: {key, value}}) => ({
            ...state,
            [key]: value
        }),
        [CHANGE_FILE]: (state, {payload: {name, file_name, file_data, contenttype}}) => ({
            ...state,
            userInfo: {
                ...state.userInfo,
                [name]: {
                    file_name,
                    // file_data: file_data.split(',')[1],
                    file_data: file_data,
                    contenttype
                }
            }
        }),
        [APPUSER_REG_SUCCESS]: (state, {payload}) => {
            ALERT("결재라인이 저장되었습니다.");
            return ({
                ...state
            })
        },
        [APPUSER_REG_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                error: payload
            })
        },
        [APPUSER_SEARCH_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                result: payload
            })
        },
        [APPUSER_SEARCH_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                error: payload
            })
        },
        [APPUSER_LIST_SUCCESS]: (state, {payload}) => {
            let _app_check = [];
            let _app_normal = [];

            payload.forEach((el) => {
                if(el.app_auth === 'APPCHECK') {
                    _app_check.push(el);
                }
                else {
                    _app_normal.push(el);
                }
            })

            return ({
                ...state,
                app_check: _app_check,
                app_normal: _app_normal,
            })
        },
        [APPUSER_LIST_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                error: payload
            })
        },
        [PWD_CHECK_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                pwdCheckResult: payload
            })
        },
        [PWD_CHECK_FAILURE]: (state) => {
            return ({
                ...state,
                pwdCheckResult: "NG"
            })
        },
        [INIT_PWDCHECK]: (state) => ({
            ...state,
            pwdCheckResult: '',
        }),
        [VIEW_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                userInfo: payload.length > 0? {
                    ...payload[0],
                    card: {
                        file_data: payload[0].card,

                    },
                    business: {
                        file_data: payload[0].business
                    },
                    deduction: {
                        file_data: payload[0].deduction
                    },
                } : [],
            })
        },
        [VIEW_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                userInfo: null,
            })
        },
        
        [CHANGE_SEARCH]: (state,{payload: {name}}) => {
            return ({
                ...state,
                searchApprove: name
            })
        },
        [GET_MAINCOUNT_SUCCESS]: (state, {payload: {main_count, winner}}) => {
            let target = null;
            return ({
                ...state,
                menu: state.menu.map((item) => {
                    target = main_count.find((el) => el.type === item.router)
                    if(target) {
                        return({
                            ...item,
                            count: target.count
                        })
                    }
                    else {
                        return ({
                            ...item
                        })
                    }
                }),
                winnerWidget: winner,
                getMenuSuccess: false
            })
        },
        [GET_MAINCOUNT_FAILURE]: (state, {payload}) => {
            return ({
                ...state,
                winnerWidget: [],
                menu: null,
            })
        }
    },
    initialState,
);
