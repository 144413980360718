import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import dataURL from '../lib/dataURL';

const HeaderBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    heigth: 5vh;
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: linear-gradient(to right, #1DBD87 , #0A989D);
    box-shadow: 0 0.18rem 0.37rem rgba(0,0,0, 0.16); 

    .Header {
        height: 3.75rem; //60px
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px;
    }
    .Header > div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 20px;
    }

    .mainLink {
        color: #ffffff;
        font-size: 22px;
        font-weight: 500;
    }
    .myid{
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
    }
    button{
        color: #ffffff;
        font-size: 14px;
        padding: 8px 18px;
        box-sizing: border-box;
        border-radius: 3px;
        border: 1px solid #ffffff;
        background-color: transparent;
        cursor: pointer;
    }
    button:hover{
        color: #0B999C;
        background-color: #ffffff;
        border: 1px solid #ffffff;
        cursor: pointer;
    }
    @media screen and (min-width: 440px) and (max-width: 1023px) {
        max-width: 1023px;
        min-width: auto;
        padding: 0 0;
        
        .Header > div{
            gap: 10px;
        }
        .mainLink, .myid{
            font-size: 15px;
        }
        button{
            padding: 6px 14px;
            font-size: 14px;
        }
    }
    @media screen and (min-width: 360px) and (max-width: 440px){
        padding: 0 0;
        
        .Header > div{
            gap: 8px;
        }
        .mainLink,.myid {
            font-size: 12px;
        }
        button{
            padding: 6px 8px;
            font-size: 12px;
        }
    }
`;

const Header = (props) => {
    const {
        user,
        header,
        menu,
        
        onNavBackClick,
        onClickLogout,
    } = props;
    return (
        <HeaderBlock>
            <div className="Header">
                <div>
                    {
                        window.location.pathname !== '/main' &&
                        <button onClick={onNavBackClick}>뒤로</button>
                    }
                    <Link to={dataURL.MainPage} style={{ textDecoration: 'none' }}>
                        <div className='mainLink'>
                            <div>집클릭</div>
                        </div>
                    </Link> 
                    {header.length > 0? 
                    <>
                        {header.map((item,idx) => {
                            return <button onClick={item.onClick} key={idx}>{item.name}</button>
                        })}
                    </> : null}
                </div>
                {user? 
                    <div>
                        <Link to={`/mypage/${user.user_id}`} state={{menu: menu}} style={{ textDecoration: 'none' }}>
                            <div className='myid'>{user.user_name || '마이페이지'}</div>
                        </Link>
                        <button onClick={onClickLogout}>로그아웃</button>
                    </div>
                : null}
            </div>
        </HeaderBlock>
    );
}

export default Header;