import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as adminAPI from '../lib/api/admin';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import { ALERT } from '../lib/dataWord';

// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const INITIALIZE = 'codeMgt/INITIALIZE';
const CHANGE_VALUE = 'codeMgt/CHANGE_VALUE';
const ROW_CHANGE_VALUE = 'codeMgt/ROW_CHANGE_VALUE';
const ADD_ROW = 'codeMgt/ADD_ROW';
const REMOVE_ROW = 'codeMgt/REMOVE_ROW';
const SET_MAIN_CODE = 'codeMgt/SET_MAIN_CODE';
const [GET_CLASS, GET_CLASS_SUCCESS, GET_CLASS_FAILURE] = createRequestActionTypes('codeMgt/GET_CLASS');
const [GET_CODE, GET_CODE_SUCCESS, GET_CODE_FAILURE] = createRequestActionTypes('codeMgt/GET_CODE');
const [SAVE_CODE, SAVE_CODE_SUCCESS, SAVE_CODE_FAILURE] = createRequestActionTypes('codeMgt/SAVE_CODE');

export const initialize = createAction(INITIALIZE);
export const setMainCode = createAction(SET_MAIN_CODE);
export const getClass = createAction(GET_CLASS, (body) => (body));
export const getCode = createAction(GET_CODE, (body) => (body));
export const saveCode = createAction(SAVE_CODE, (subcodes) => ({subcodes}));
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const addRow = createAction(ADD_ROW);
export const removeRow = createAction(REMOVE_ROW, ({idx}) => ({idx}));

//saga생성
const getClassSaga = createRequestSaga(GET_CLASS, adminAPI.code_search);
const getCodeSaga = createRequestSaga(GET_CODE, adminAPI.code_search);
const saveCodeSaga = createRequestSaga(SAVE_CODE, adminAPI.code_reg);

export function* codeMgtSaga() {
    yield takeLatest(GET_CLASS, getClassSaga);
    yield takeLatest(GET_CODE, getCodeSaga);
    yield takeLatest(SAVE_CODE, saveCodeSaga);
}

const initialState = {
    mainCode: 'ROOT',
    classList: [],
    codeList: [],

    codeMgt: null,
    error: null
};

const codeMgt = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _codeList = state.codeList.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                codeList: _codeList,
            };
        },
        [SET_MAIN_CODE]: (state, { payload: { value }}) => {
            return {
                ...state,
                mainCode: value,
            }
        },
        [ADD_ROW]: (state) => {
            let _idx = 0, _display = 0;
            state.codeList.forEach(item => {
                if(_idx < item.idx) {
                    _idx = item.idx;
                }
                if(_display < item.display) {
                    _display = item.display;
                }
            });
            _idx = _idx + 1;
            _display = _display + 1;

            return {
                ...state,
                codeList: state.codeList.concat({
                    idx: _idx,
                    main_code: state.mainCode,
                    sub_code: '',
                    code_name: '',
                    memo: '',
                    display: _display,
                    use_flag: '',
                    isNewRow: true,
                })
            };
        },
        [REMOVE_ROW]: (state, { payload: {idx}}) => {
            const _codeList = state.codeList.filter((item) => item.idx !== idx);
            return {
                ...state,
                codeList: _codeList,
            };
        },
        [GET_CLASS_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                classList: payload
            }
        },
        [GET_CLASS_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [GET_CODE_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                codeList: payload
            }
        },
        [GET_CODE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
        [SAVE_CODE_SUCCESS]: (state, {payload}) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_CODE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default codeMgt;