import React from 'react';
import { ToLocaleString } from '../../common';

const PriorityPrint = ({priority, contract}) => {
    return (
        <div className='tableWrap winnerRank' style={{pageBreakBefore:'always'}}>
            <div className='viewerContainer borderwrap'>
                <h2 className='text-c'>선순위 임차보증금 내역서</h2>
                <div className='address'>
                    <span>○ 임차목적물 주소지 : </span>
                    <span>{contract && contract.jibun_addr && contract.road_addr_dtl ? (contract.jibun_addr + ', ' + contract.road_addr_dtl) : ''}</span>
                </div>
                <table className='mt10'>
                    <colgroup>
                        <col span="7" style={{width:'13%'}}></col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>층수</th>
                            <th>호수</th>
                            <th>공실구분</th>
                            <th>방개수</th>
                            <th>보증금</th>
                            {/* <th>월세</th> */}
                            <th style={{width:'15%'}}>최우선변제보증금</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {priority.length > 0 && priority.map((item, idx) => (
                            item.use_flag === "Y" &&
                            <>
                            <tr key={idx}>
                                <td>{item.rent_floor2}</td>
                                <td>{item.room_no2}</td>
                                <td>{item.empty_type2_name}</td>
                                <td>{item.room_cnt2}</td>
                                <td>{ToLocaleString(item.rent_deposit2)}</td>
                                <td>{ToLocaleString(item.priority_payment2)}</td>
                                <td>{item.remark2}</td>
                            </tr>
                            </>
                        ))}
                        {priority.length > 0 && (
                            <>
                                <tr>
                                    <td colSpan={7}></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>합계</td>
                                    <td>{priority.length}</td>
                                    <td>{ToLocaleString(priority.reduce((sum, item) => (sum + item.room_cnt2), 0))}</td>
                                    <td style={{fontWeight:'bold'}}>{ToLocaleString(priority[0].rent_tot_amt)}</td>
                                    <td style={{fontWeight:'bold'}}>{ToLocaleString(priority[0].empty_tot_amt)}</td>
                                    <td></td>
                                </tr>
                            </>
                        )}
                        {priority.length <= 0 &&
                            <tr>
                                <td colSpan={8}>데이터가 존재하지 않습니다.</td>
                            </tr>
                        }
                    </tbody>
                </table>
                    <h4 className='mt10 mb10' style={{textAlign:'center'}}>{'<중개대상확인 설명서 9번 기재 내용>'}</h4>
                    <table>
                        <tbody>
                            <tr>
                                <th>㉠ 선순위 임차보증금액</th>
                                <td className='text-r'>{ToLocaleString(priority.length > 0 && (parseInt(priority[0].rent_tot_amt) + parseInt(priority[0].empty_tot_amt)))}</td>
                                <td className='text-l'>원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>㉡ 지원대상주택전체호수</th>
                                <td className='text-r'>{priority.length > 0 && (priority.length - 1)}</td>
                                <td className='text-l'>호</td>
                                <td className='text-l'>(방갯수 {priority.filter(item => item.empty_type2 !== 'HOUSE_WANT').reduce((sum, item) => (sum + item.room_cnt2), 0)}개)</td>
                            </tr>
                            <tr>
                                <th>㉢ 공실전체호수<br/>(집주인거주포함)</th>
                                <td className='text-r'>{priority.length > 0 && (priority.filter(item => ((item.empty_type2 === 'HOUSE_OWNER') || (item.empty_type2 === 'HOUSE_EMPTY') || (item.empty_type2 === 'STORE_OWNER') || (item.empty_type2 === 'STORE_EMPTY'))).length)}</td>
                                <td className='text-l'>호</td>
                                <td className='text-l'>(방갯수 {priority.filter(item => ((item.empty_type2 === 'HOUSE_OWNER') || (item.empty_type2 === 'HOUSE_EMPTY') || (item.empty_type2 === 'STORE_OWNER') || (item.empty_type2 === 'STORE_EMPTY'))).reduce((sum, item) => (sum + item.room_cnt2), 0)}개)</td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    );
};

export default PriorityPrint;