import React from 'react';
import styled from 'styled-components';
import { setComma } from '../common';

const LHCustomerListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    padding: 40px 50px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    margin: 0 auto;



    table {
        white-space: nowrap;
        margin-bottom: 0px;

        border-collapse: separate;
        border-spacing: 0px;
    }
    .tt{
        table-layout: auto;
    }

    table thead{
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 10;
    }

    table .target td{
        border-bottom: 1px solid red;
        border-top: 1px solid red;
    }

    .tableCover table th{
        border: 1px solid #cfcbcb;
    }
    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

`;

const LHCustomerList = (props) => {
    const {
        search,
        customerList_LH,
        checkedList,
        imply,
        next,
        max, 
        step, 
        currentPage, 
        pageCount,
        curIdx,

        onCheck,
        onCheckAll,
        onChangeSearch,
        onSubmit,
        onClickNum,
        onClickNext,
        onClickPrev,
        onImplyAll,
        onImply,
        onNextWorkAll,
        onNextWork,
        onClickList,
        onInitSearchvalue,
    } = props;
    return (
        <LHCustomerListBlock>
            <h1>고객 리스트(LH)</h1>
            <div className='btn-group'>
                {imply? 
                <>
                    <button onClick={onImplyAll}>일괄묵시처리</button>
                    <button onClick={onImply}>선택묵시처리</button>
                </>
                :null}
                {next?
                <>
                    <button onClick={onNextWorkAll}>일괄사후처리</button>
                    <button onClick={onNextWork}>선택사후처리</button>
                </>
                :null}
            </div>
          
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt4'>
                            <div className='st4'>
                                <label htmlFor='date_type'>날짜유형</label>
                                <select name='date_type' id='date_type' value={search.date_type || ''} onChange={onChangeSearch} className="mr10">
                                    <option value="">전체</option>
                                    <option value="CONTRACT_DATE">계약일</option>
                                    <option value="MOVE_IN_DATE">전입일자</option>
                                    <option value="CONFIRM_DATE,">확정일자</option>
                                    <option value="CONT_START_DATE">계약시작일</option>
                                    <option value="CONTRACT_END_DATE">계약종료일</option>
                                </select>
                                <input name='start_date' id='start_date' value={search.start_date || ''} onChange={onChangeSearch} type='date'/>
                                <span>~</span>
                                <input name='end_date' id='end_date' value={search.end_date || ''} onChange={onChangeSearch} type='date'/>
                            </div>
                            <div className='st4'>
                                <label htmlFor='phone'>핸드폰번호</label>
                                <input name='phone' id='phone' value={search.phone || ''} onChange={onChangeSearch} type='number' className='input-size'/>
                            </div>
                            <div className='st4'>
                                <label htmlFor='name'>이름</label>
                                <input name='name' id='name' value={search.name || ''} onChange={onChangeSearch} type='text' className='input-size'/>
                            </div>
                            <div className='st4'>
                                <label htmlFor='area'>지역</label>
                                <input name='area' id='area' value={search.area || ''} onChange={onChangeSearch} type='text' className='input-size'/>
                            </div>
                            <div className='st4'>
                                <label htmlFor='view_count'>조회개수</label>
                                <select name='view_count' id='view_count' value={search.view_count || '10'} onChange={onChangeSearch}>
                                    <option value={'10'}>10</option>
                                    <option value={'30'}>30</option>
                                    <option value={'50'}>50</option>
                                    <option value={'100'}>100</option>
                                </select>
                            </div>
                        </div>

                        {/* [DEV] 22.03.25 임의로 초기화버튼추가 */}
                        <div className='btn'>
                            <button type='submit' className='green-sch-btn'>검색</button>
                            <button type='button' onClick={onInitSearchvalue} className='reset-btn'>검색조건 초기화</button>
                        </div>
                    </div>
                </form>
            </div>
           
            <div className='tableCover'>
                <table className='tt'>
                    <thead>
                        <tr>
                            {imply || next? 
                            <th rowSpan={2}><input id='all' type='checkbox' checked={((customerList_LH.length === checkedList.length) && (checkedList.length > 1)) || ''} onChange={onCheckAll}/><label htmlFor='all'></label></th>
                            : null}
                            <th rowSpan={2}>순번</th>
                            <th rowSpan={2}>사용자ID</th>
                            <th rowSpan={2}>지역본부</th>
                            <th colSpan={3}>전세지원</th>
                            <th colSpan={7}>전세임대계약자정보</th>
                            <th colSpan={2}>채권확보방법</th>
                            <th colSpan={13}>계약정보</th>
                            <th colSpan={2}>시중은행기금대출</th>
                            <th colSpan={9}>대출정보</th>
                            <th colSpan={6}>상환정보</th>
                            <th colSpan={1}>쪽방비닐지원</th>
                            <th colSpan={6}>소유주정보</th>
                            <th colSpan={2}>임대차 계약기간</th>
                            <th colSpan={10}>지원주택정보</th>
                            <th colSpan={5}>보증보험</th>
                            <th colSpan={3}>임차료지급보증</th>
                            <th colSpan={13}>권리분석 채권확보검토 내용</th>
                            <th colSpan={2}>소년소녀</th>
                            <th rowSpan={2}>사용여부</th>
                        </tr>
                        <tr>
                            <th>유형</th>
                            <th>상세유형</th>
                            <th>세부유형</th>
                            <th>지자체</th>
                            <th>입주자명</th>
                            <th>생년월일</th>
                            <th>전화번호</th>
                            <th>핸드폰번호</th>
                            <th>세대원수</th>
                            <th>공동 거주자수</th>
                            <th>보증보험(원)</th>
                            <th>전세권설정(원)</th>
                            <th>계약번호</th>
                            <th>계약차수</th>
                            <th>신규/재계약 여부</th>
                            <th>갱신횟수</th>
                            <th>묵시갱신 여부</th>
                            <th>재계약기준적용 유예여부</th>
                            <th>재계약기준적용 유예처리일자</th>
                            <th>계약일자</th>
                            <th>전입일자</th>
                            <th>확정일자</th>
                            <th>전세유형</th>
                            <th>지원차수</th>
                            <th>이전계약번호</th>
                            <th>대출여부</th>
                            <th>상환여부</th>
                            <th>전세금</th>
                            <th>전세지원금</th>
                            <th>입주자부담금</th>
                            <th>입주자추가부담금</th>
                            <th>보증부월세선납액</th>
                            <th>보증부월세액</th>
                            <th>할증비율(%)</th>
                            <th>월임대료</th>
                            <th>연배상금 부과시작일</th>
                            <th>퇴거일자</th>
                            <th>미상환여부</th>
                            <th>상환완료일</th>
                            <th>총지원금</th>
                            <th>총상환금액</th>
                            <th>대출잔액</th>
                            <th>총지원금액</th>
                            <th>소유주명</th>
                            <th>생년월일</th>
                            <th>소유주연락처</th>
                            <th>
                                <div>지원금가상계좌</div>
                                <div>(우리은행)</div>
                            </th>
                            <th>우편번호</th>
                            <th>주소</th>
                            <th>계약시작일</th>
                            <th>계약종료일</th>
                            <th>우편번호</th>
                            <th>지원주택소재지</th>
                            <th>동</th>
                            <th>호</th>
                            <th>주택유형</th>
                            <th>방갯수</th>
                            <th>계약면적</th>
                            <th>전용면적</th>
                            <th>대지면적</th>
                            <th>건축년면적</th>
                            <th>채권확보방법</th>
                            <th>청약일자</th>
                            <th>전문관리번호</th>
                            <th>증권번호</th>
                            <th>배서번호</th>
                            <th>가입여부</th>
                            <th>가입일자</th>
                            <th>증권번호</th>
                            <th>가격결정방법</th>
                            <th>주택가격</th>
                            <th>결정일자</th>
                            <th>결정가격</th>
                            <th>채권확보가능금액</th>
                            <th>근저당설정금액</th>
                            <th>전세권설정금액</th>
                            <th>압류금액</th>
                            <th>기존임차인금액</th>
                            <th>선순위채권금액</th>
                            <th>채권확보가능금액</th>
                            <th>차액</th>
                            <th>부채비율</th>
                            <th>보호조치종료일자</th>
                            <th>시설퇴소일자</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerList_LH.length > 0?
                        <>
                        {customerList_LH.map((item, idx) => {
                            return (
                                <tr key={idx} className={Number(curIdx) === idx? 'target' : ''} onClick={() => onClickList(idx)}>
                                    {imply || next? 
                                    <td className='check'><input id={item.lh_cust_id} type='checkbox' checked={checkedList.some((el) => el.lh_cust_id === item.lh_cust_id)? true : false || ''} onChange={(e) => onCheck(e, item)}/><label htmlFor={item.lh_cust_id}></label></td>
                                    : null}
                                    <td>{(search.view_count * (currentPage - 1)) + idx+1}</td>
                                    <td>{item.lh_cust_id 	}</td>
                                    <td>{item.area_hq 		}</td>
                                    <td>{item.cust_type 			}</td>
                                    <td>{item.dtl_type 		}</td>
                                    <td>{item.subtype 		}</td>
                                    <td>{item.area 			}</td>
                                    <td>{item.cust_name 		}</td>
                                    <td>{item.cust_no 		}</td>
                                    <td>{item.tel 			}</td>
                                    <td>{item.phone? `${item.phone.slice(0,3)}-${item.phone.slice(3,7)}-${item.phone.slice(7)}` : ''}</td>
                                    <td>{item.family_cnt 	}</td>
                                    <td>{item.residents_num 	}</td>
                                    <td>{setComma(item.guarantee_ins)	}</td>
                                    <td>{setComma(item.rent_set_amt)	}</td>
                                    <td>{item.contract_num	}</td>
                                    <td>{item.contract_order	}</td>
                                    <td>{item.new_yn			}</td>
                                    <td>{item.update_num		}</td>
                                    <td>{item.imply_update_yn}</td>
                                    <td>{item.respite_yn		}</td>
                                    <td>{item.respite_date	}</td>
                                    <td>{item.contract_date	}</td>
                                    <td>{item.move_in_date	}</td>
                                    <td>{item.confirm_date	}</td>
                                    <td>{item.rent_type		}</td>
                                    <td>{item.app_num		}</td>
                                    <td>{item.precont_num	}</td>
                                    <td>{item.loan_yn		}</td>
                                    <td>{item.repay_yn		}</td>
                                    <td>{setComma(item.rent_amt)		}</td>
                                    <td>{setComma(item.support_amt)	}</td>
                                    <td>{setComma(item.winner_charge)	}</td>
                                    <td>{setComma(item.add_charge)	}</td>
                                    <td>{setComma(item.prepay_amt)		}</td>
                                    <td>{setComma(item.guarantee_mon_amt)}</td>
                                    <td>{item.extra_rate > 0? `${item.extra_rate}%` : `${item.extra_rate}`}</td>
                                    <td>{setComma(item.monthly_amt)	}</td>
                                    <td>{item.delay_start_date}</td>
                                    <td>{item.eviction_date	}</td>
                                    <td>{item.non_repay_yn	}</td>
                                    <td>{item.repay_comp_date}</td>
                                    <td>{setComma(item.tot_support)	}</td>
                                    <td>{setComma(item.tot_repay_amt)	}</td>
                                    <td>{setComma(item.loan_balance)	}</td>
                                    <td>{setComma(item.small_room_suppor)}</td>
                                    <td>{item.owner_name		}</td>
                                    <td>{item.owner_no? `${item.owner_no.slice(0,6)}-${item.owner_no.slice(6,7)}******`: ''		}</td>
                                    <td>{item.owner_tel? `${item.owner_tel.slice(0,3)}-${item.owner_tel.slice(3,7)}-${item.owner_tel.slice(7)}`: ''		}</td>
                                    <td>{item.account_no? `${item.account_no.slice(0,4)}-${item.account_no.slice(4,7)}-${item.account_no.slice(7)}`: ''}</td>
                                    <td>{item.owner_addr_no	}</td>
                                    <td>{item.owner_addr		}</td>
                                    <td>{item.cont_start_date}</td>
                                    <td>{item.cont_end_date	}</td>
                                    <td>{item.addr_no		}</td>
                                    <td>{item.addr			}</td>
                                    <td>{item.addr_dong		}</td>
                                    <td>{item.addr_ho		}</td>
                                    <td>{item.house_type		}</td>
                                    <td>{item.room_cnt		}</td>
                                    <td>{item.contract_area	}</td>
                                    <td>{item.dedicated_area	}</td>
                                    <td>{item.land_area		}</td>
                                    <td>{item.building_area	}</td>
                                    <td>{item.securing_bonds	}</td>
                                    <td>{item.subscr_date	}</td>
                                    <td>{item.manage_num		}</td>
                                    <td>{item.ins_policy_num	}</td>
                                    <td>{item.endorsement_num}</td>
                                    <td>{item.pay_guar_join	}</td>
                                    <td>{item.join_date		}</td>
                                    <td>{item.pay_policy_num	}</td>
                                    <td>{item.decide_how		}</td>
                                    <td>{setComma(item.house_amt)}</td>
                                    <td>{item.decide_date	}</td>
                                    <td>{setComma(item.decide_amt)		}</td>
                                    <td>{setComma(item.possible_bond_amt)}</td>
                                    <td>{setComma(item.mortgage_amt)	}</td>
                                    <td>{setComma(item.rent_set_amt2)	}</td>
                                    <td>{setComma(item.seizure_amt)	}</td>
                                    <td>{setComma(item.already_rent_amt)}</td>
                                    <td>{setComma(item.senior_bond_amt)}</td>
                                    <td>{setComma(item.securing_bond_amt)}</td>
                                    <td>{setComma(item.difference_amt)	}</td>
                                    <td>{item.debit_ratio	}%</td>
                                    <td>{item.protect_end_dt	}</td>
                                    <td>{item.discharge_date	}</td>
                                    <td>{item.use_flag		}</td>
                                </tr>
                            )
                        })}
                        </>
                        : 
                        <tr>
                            <td colSpan={25}>결과가 없습니다.</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            else {
                                return null
                            }
                    })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
        </LHCustomerListBlock>
    );
}

export default LHCustomerList;