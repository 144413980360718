import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CodeMgt from '../components/CodeMgt';
import { dataSystemCode } from '../lib/dataSystemCode';
import { addRow, changeValue, saveCode, removeRow, rowChangeValue, getCode, getClass, setMainCode, initialize } from '../modules/codeMgt';

const CodeMgtContainer = () => {
    const cboUseYN = dataSystemCode.USE_YN;

    const dispatch = useDispatch();
    const location = useLocation();
    
    const {state: {id}} = location

    const {
        mainCode,
        classList,
        codeList,

        codeMgt,
        error,
    } = useSelector(({ codeMgt }) => ({
        mainCode: codeMgt.mainCode,        
        classList: codeMgt.classList,
        codeList: codeMgt.codeList,

        codeMgt: codeMgt.codeMgt,
        error: codeMgt.error,
    }));

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowChange = (item, e) => {
        dispatch(
            rowChangeValue({
                idx: item.idx,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowClick = (item, e) => {
        dispatch(setMainCode({value:item.sub_code}));
        dispatch(getCode({
            main_code: item.sub_code,
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }));
    }
    const onAddRow = (e) => {
        dispatch(addRow());
    }
    const onRemoveRow = (item, e) => {
        dispatch(removeRow({idx: item.idx}));
    }

    const onSave = () => {
        dispatch(saveCode(codeList));
        dispatch(getClass({
            main_code: "ROOT",
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }))
        dispatch(getCode({
            main_code: mainCode,
            admin_type: id==="MENU99"? "SYSTEM" : "USER"
        }));
    }

    // useEffect(() => {
    //     if(id) {
    //         admin_type = id==="MENU99"? "SYSTEM" : "USER"
    //     }
    // }, [id])

    useEffect(() => {
        if(dispatch && id) {
            dispatch(getClass({
                main_code: "ROOT",
                admin_type: id==="MENU99"? "SYSTEM" : "USER"
            }))
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => dispatch(initialize())
    }, [dispatch])

    const propDatas = {
        classList,

        codeList,

        cboUseYN,

        codeMgt,
        error,

        onChange,
        onRowChange,
        onRowClick,
        onAddRow,
        onRemoveRow,
        onSave
    }

    return (
        <>
            <CodeMgt {...propDatas}/>
        </>
    );
}

export default CodeMgtContainer;