import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addZoom } from '../../common';
import FieldChk from '../../components/Modal/FieldChk';
import { changeFiles, changeValue, doc_deleteImg, doc_selectImg, initialize, saveFiles, saveRemark } from '../../modules/Modal/fieldChk';
import waiting from '../../waiting.png';

const FieldChkContainer = (props) => {
    const dispatch = useDispatch();

    const {
        //  모달 완료 후 리스트 재조회
        onRefreshList,

        //  현장조사 모달
        isFieldChkDocNo,
        setIsFieldChkDocNo,
    } = props;

    const {
        doc_id,
        fileChooser,
        selectImg,
        docLastIdx,
        docImages,
        remark,
    } = useSelector(({ fieldChk }) => ({
        doc_id: fieldChk.doc_id,
        fileChooser: fieldChk.fileChooser,
        selectImg: fieldChk.selectImg,
        docLastIdx: fieldChk.docLastIdx,
        docImages: fieldChk.docImages,
        remark: fieldChk.remark,
    }));

    useEffect(() => {
        return () => {
            dispatch(initialize());
            dispatch(
                changeValue({
                    key: 'docImages',   //  얘만 이상하게 initialize가 안돼서 임의로 값을 초기화함.
                    value: [],
                })
            );
        }
    }, [dispatch])

    useEffect(() => {
        //  List Page에서 전달받은 DOC_ID가 없다면 return.
        if(!isFieldChkDocNo) {
            return;
        }

        //  List Page에서 전달받은 DOC_ID를 redux에 저장.
        dispatch(
            changeValue({
                key: 'doc_id',
                value: isFieldChkDocNo,
            })
        );
    }, [dispatch, isFieldChkDocNo])

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onChangeFiles = e => {
        const files = e.target.files;
        
        for (let idx = 0; idx < files.length; idx++) {
            let cnt = docLastIdx + idx;
            let _selectImg;
            const file = files[idx];

            if(!file) continue;
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                dispatch(
                    changeFiles({
                        idx: cnt++,
                        fileReader: reader,
                        fileName: file.name,
                        fileExtension: file.type,
                        fileSize: file.size,
                    })
                );

                //  추가할 파일중 첫번째 사진을 확대하여 보여준다.
                if(!_selectImg) {
                    _selectImg = {
                        file_data: reader.result,
                        file_size: file.size,
                    }
                    onSelectImg(_selectImg);
                }
            }
        }
    }
    const onSelectImg = (item, e) => {
        if (item.file_size < 1024000) {
            dispatch(doc_selectImg(item.file_data));
            initImgScale();
            return;
        }

        const image = new Image();
        image.src = item.file_data;
        image.onload = (e) => { 
            const $canvas = document.createElement(`canvas`);
            const ctx = $canvas.getContext(`2d`);
        
            $canvas.width = e.target.width;
            $canvas.height = e.target.height;
            
            ctx.drawImage(e.target, 0, 0);

            // 용량이 줄어든 base64 이미지를 보여준다. 저장시에는 원본을 저장.
            dispatch(doc_selectImg($canvas.toDataURL(`image/jpeg`, 0.5)));
            initImgScale();
        }
    }
    const initImgScale = () => {
        const initImg = document.getElementById("selectImg");
        initImg.style.transform = 'scale(1)';
        const divRotate = document.getElementById("div-rotate");
        divRotate.style.transform = 'rotate(' + 0 + ')';
        divRotate.style.left = '0px';
        divRotate.style.top = '0px';
    }
    const onDeleteImg = (item, e) => {
        dispatch(doc_deleteImg(item.idx));
    }
    const onWaiting = (e) => {
        e.target.src = waiting;
    }
    const onSave = () => {
        if(!window.confirm("입력하신 내용을 저장하시겠습니까?")) {
            return;
        }

        docImages.forEach(_file => {
            if(_file.file_name) {
                dispatch(
                    saveFiles({
                        doc_id: doc_id,
                        files:
                        [
                            {
                                doc_type: 'FIELDCHK',
                                file_id: _file.file_id,
                                file_type: _file.file_type,
                                file_name: _file.file_name,
                                contenttype: _file.file_extension,
                                file_data: _file.file_data.split(',').slice(-1)[0],
                                use_flag: _file.use_flag,
                            }
                        ]
                    })
                )
            }
        });
        dispatch(saveRemark({
            doc_id: doc_id, 
            remark: remark,
        }))

        onRefreshList('FIELDCHK');
        onClose();
    }
    const onClose = () => {
        setIsFieldChkDocNo(null);
    }

    useEffect(() => {
        addZoom("")
    }, [selectImg])

    const propDatas = {
        fileChooser,
        selectImg,
        docImages,
        remark,
        
        onChange,
        onChangeFiles,
        onSelectImg,
        onDeleteImg,
        onSave,
        onClose,
        onWaiting,
    }
    return (
        <>
            <FieldChk {...propDatas} />
        </>
    );
}

export default FieldChkContainer;