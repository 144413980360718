import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PrintSort from '../components/PrintSort';
import { getCode, initialize, rowChangeValue, saveCode, sortRow } from '../modules/printSort';

const PrintSortContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {state: {id}} = location;

    const {
        printList,
    } = useSelector(({ printSort }) => ({
        printList: printSort.printList,
    }));

    const onRowChange = (item, e) => {
        dispatch(
            rowChangeValue({
                idx: item.idx,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowSort = (item, type, e) => {
        dispatch(sortRow({
            display: item.display,
            type: type,
        }));
    }
    const onSave = () => {
        dispatch(saveCode(printList));
        dispatch(getCode());
    }

    useEffect(() => {
        dispatch(getCode());
    }, [dispatch, id])

    useEffect(() => {
        return () => dispatch(initialize())
    }, [dispatch])

    const propDatas = {
        printList,

        onRowChange,
        onRowSort,
        onSave,
    }

    return (
        <>
            <PrintSort {...propDatas}/>
        </>
    );
}

export default PrintSortContainer;