import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ImplyWorkListContainer from '../containers/ImplyWorkListContainer';

const ImplyWorkListPage = () => {
    return (
        <>
            <HeaderContainer/>
            <ImplyWorkListContainer />
        </>
    );
}

export default ImplyWorkListPage;