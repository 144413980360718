import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import NextMaintainContainer from '../containers/NextMaintainContainer';

const NextMaintainPage = () => {
    return (
        <>
            <HeaderContainer />
            <NextMaintainContainer />
        </>
    );
}

export default NextMaintainPage;