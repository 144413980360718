import React from 'react';
import styled from 'styled-components';

const EnwAdminUploadBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 780px;
        padding: 55px 70px;
    }
    .popupModal > div:nth-child(2) h4{
        font-size: 26px;
        color: #545454;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
    }
    .divFileList {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 5px 10px;
        height: 26vh;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        overflow-y: auto;
        margin-bottom: 40px;
    }
    .divFileList > div {
        margin: 5px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .divButtons button{
        margin: 10px 10px;
    }

    .divFileName {
        width: 100%;
        color: #545454;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
    .lblInputFiles {
        color: #ffffff;
        font-size: 19px;
        font-weight: 500;
        padding: 6px 12px;
        box-sizing: border-box;
        margin: 0 10px;
        border-radius: 24px;
        cursor: pointer;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
    }
    .inputFiles {
        display: none;
    }
    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .popupModal > div:nth-child(2){
            width: 100%;
            height: 100%;
            border-radius: 0;
            padding: 100px 30px;
        
        }
        label{
            display: block;
        }
        .divFileName{
            font-size: 12px;
        }
        .lblInputFiles{
            margin: 10px 0;
            border-radius: 3px;
        }
        .divButtons{
            width: 100%;
            position: absolute;
            left: 0;
        }
        .divButtons button{
            width: 50%;
            font-size: 16px;
            margin: 0px;
            border-radius: 0;
        }

    }
`;

const EnwAdminUpload = (props) => {
    const {
        isShowUpload,
        fileList,

        onFileChange,
        onRemoveRow,
        onSave,
        onClose,
    } = props;

    return (
        <EnwAdminUploadBlock>
            <div className='popupModal'> {/* [Dev]22.02.17 popupWrap class 추가 */}
               <div />
               <div>
                    <h2>
                        {isShowUpload === "ENWRITEADMIN" && "지원신청 파일 업로드"}
                        {isShowUpload === "PHOTOREQUEST" && "지원신청 사진첨부"}
                        <label className='lblInputFiles' htmlFor="inputFiles">+파일 추가</label>
                        <input type="file" id="inputFiles" className='inputFiles' onChange={onFileChange} accept=".jpg,.jpeg,.png,.pdf" multiple/>
                    </h2>
                    <div className='divFileList'>
                        {fileList.map((item, idx) => (
                            item.use_flag === "Y" &&
                            <div key={idx}>
                                <div className='divFileName' title={item.file_name}>{item.file_name}</div>
                                <button className='del-btn' onClick={onRemoveRow.bind(this, item)}>삭제</button>
                                {/* [Dev] 22.02.17 del-btn class추가 */}
                            </div>
                        ))}
                    </div>
                    <div className='divButtons'>
                        <button onClick={onSave} className="divB-Save">
                            {isShowUpload === "ENWRITEADMIN" && "저장"}
                            {isShowUpload === "PHOTOREQUEST" && "신청"}
                        </button>
                        <button onClick={onClose} className="divB-Cancel">취소</button>
                    </div>
               </div>
            </div>
        </EnwAdminUploadBlock>
    );
}

export default EnwAdminUpload;