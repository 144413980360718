import React from 'react';
import styled from 'styled-components';

const UploadListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .search{
            display : none;
        }
    }

    .search form {
        overflow: inherit;  // Mutiple Check ComboBox를 보여주기 위해서 overflow:hidden을 제거함.
    }

    // 검색 Multiple checkbox Combo
    .cboStDiv {
        position: relative;
    }
    .txtSelectedValues {
        display: flex;
        width: 100%;
        padding: 5px;
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .txtSelectedValues div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .cboMultiple {
        position: absolute;
        top: 36px;
        border: 1px solid black;
        list-style: none;
        max-height: 150px;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;
    }
    .cboMultiple li {
        background-color: white;
    }
    .cboMultiple li:hover {
        background-color: #ccc;
    }
    .cboMultiple li input[type="checkbox"] + label:before {
        top: 8px;
        left: 6px;
    }
    .cboMultiple li label {
        background-color: inherit;
        height: auto;
        padding: 5px 5px 5px 25px;
        width: 100%;
        text-align: left;
    }
`;

const ViewerPopup = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .popupWrap {
        width: 800px;
        box-sizing: border-box;
        padding: 20px;
    }
    .modal-header {
        text-align: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .modal-img {
        border: 1px solid #ccc;
        overflow: hidden;
        position: relative;
        height: 700px;
    }
    .popupWrap img {
        width: 100%;
        height: 100%;
        position: absolute;
        border: none;
    }
`;

const UploadList = (props) => {
    const {
        addr,
        user_name,

        cboDocType,

        //  선택 파일종류 검색 관련
        isShowCboStatus,
        selectedFileTypes,
        onStatusChange,
        onStatusAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        fileList,
        
        pageCount,
        max,
        step,
        currentPage,
        
        //  File View modal
        isShowViewer,
        viewerImg,
        onShowViewer,
        onClose,

        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,
    } = props;

    return (
        <UploadListBlock>
            <h1>업로드 파일 리스트</h1>
            
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='searchipt'>                            
                            <div className='st'>
                                <label htmlFor='addr'>주소</label>
                                <input name='addr' id='addr' value={addr} onChange={onChange} className="input-user" style={{width:"200px"}} />
                            </div>

                            <div className='st'>
                                <label htmlFor='user_name'>입주자명</label>
                                <input name='user_name' id='user_name' value={user_name} onChange={onChange} className="input-user" style={{width:"150px"}} />
                            </div>

                            <div className='st' style={{alignItems:"flex-start"}} tabIndex={0} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboStatus'>파일종류</label>
                                <div className='cboStDiv' style={{width:"200px"}}>
                                    <div className='txtSelectedValues' onClick={() => onShowMultiCbo('STATUS')}>
                                        <div>{selectedFileTypes}</div>
                                        ▼
                                    </div>
                                    {isShowCboStatus &&
                                        <ul className="cboMultiple" id="cboStatus">
                                            <li>
                                                <input type="checkbox" name="statusALL" id="statusALL" onChange={onStatusAll}
                                                    checked={cboDocType.length === cboDocType.filter(x => x.checked === true).length}
                                                />
                                                <label htmlFor="statusALL">전체 선택</label>
                                            </li>
                                            {cboDocType.map((item) => (
                                                <li key={item.sub_code}>
                                                    <input type="checkbox" name={item.sub_code} id={item.sub_code} checked={item.checked} onChange={onStatusChange.bind(this, item)} />
                                                    <label htmlFor={item.sub_code}>{item.code_name}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>

                            <div className='btn'>
                                <button className='green-sch-btn'>검색</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* <h2>검색결과</h2> */}
            <table className='tt'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>문서ID</th>
                        <th>주소</th>
                        <th>입주자명</th>
                        <th>파일명</th>
                        <th>파일종류</th>
                        <th>API응답일시</th>
                        {/* <th>JSON Text</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        fileList.length === 0 &&
                        <tr>
                            <td colSpan={7}>검색 결과가 없습니다.</td>
                        </tr>
                    }
                    {fileList.map((item,idx) => {
                        return (
                            <tr key={idx} onClick={onShowViewer.bind(this, item)}>
                                <td>{(30 * (currentPage - 1)) + idx + 1}</td>
                                <td>{item.doc_id}</td>
                                <td>{item.addr}</td>
                                <td>{item.cust_name}</td>
                                <td>{item.file_name}</td>
                                <td>{item.file_type_name}</td>
                                <td>{item.api_rec_date}</td>
                                {/* <td>{item.file_text}</td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                    {[...Array(pageCount)].map((item,idx) => {
                        if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                            return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                        }
                        else {
                            return null;
                        }
                    })}
                    </div>
                </div>
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>

            {isShowViewer &&
                <ViewerPopup>
                    <div className='popupWrap'>
                        <div className='modal-header'>
                            <h3>업로드 파일 View</h3>
                            <button onClick={onClose} className="cancel-btn">닫기</button>
                        </div>
                        {
                            viewerImg && 
                            <div id='imgView' className='modal-img imgView'>
                                <img id="selectImg" className='selectImg' src={viewerImg} draggable={false} alt=''/>
                            </div>
                        }
                    </div>
                </ViewerPopup>
            }
        </UploadListBlock>
    );
}

export default UploadList;