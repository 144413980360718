import { createAction, handleActions } from 'redux-actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as entitleAPI from '../lib/api/entitle';
import * as adminAPI from '../lib/api/admin';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const INITIALIZE = 'list/INITIALIZE';
const INITSEARCHSTATUS = 'list/INITSEARCHSTATUS';
const INITSTATUS = 'list/INITSTATUS';
const INITSEARCH = 'list/INITSEARCH';
const CHANGE_VALUE = 'list/CHANGE_VALUE';
const CHANGE_TYPEARR = 'list/CHANGE_TYPEARR';
const CHANGE_STATUSARR = 'list/CHANGE_STATUSARR';
const INIT_TYPE = 'list/INIT_TYPE';
const SET_PAGING = 'list/SET_PAGING';
const INIT_TYPE_SUCCESS = 'list/INIT_TYPE_SUCCESS';
const SET_SEARCH_VALUE = 'list/SET_SEARCH_VALUE';
const SET_FEEDBACk = 'list/SET_FEEDBACk';
const [SEARCH, SEARCH_SUCCESS, SEARCH_FAILURE] = createRequestActionTypes('list/SEARCH');
const [SEARCH_FDBCK, SEARCH_FDBCK_SUCCESS, SEARCH_FDBCK_FAILURE] = createRequestActionTypes('list/SEARCH_FDBCK');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('list/GET_COMBO');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('list/REACTION');

export const initialize = createAction(INITIALIZE);
export const initSearchStatus = createAction(INITSEARCHSTATUS, ({statusAll}) => ({statusAll}));
export const initStatus = createAction(INITSTATUS);
export const initSearch = createAction(INITSEARCH, (body) => (body));
export const initType = createAction(INIT_TYPE, (payload) => payload);
export const setSearchValue = createAction(SET_SEARCH_VALUE, (value) => value);
export const setPaging = createAction(SET_PAGING, (payload) => payload);

export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const changeTypeArr = createAction(CHANGE_TYPEARR, ({key, value}) => ({key, value}));
export const changeStatusArr = createAction(CHANGE_STATUSARR, ({value}) => ({value}));
export const searchList = createAction(SEARCH, (body) => (body));
export const searchFeedbackList = createAction(SEARCH_FDBCK, (body) => (body));
export const getCombo = createAction(GET_COMBO);

const searchSaga = createRequestSaga(SEARCH, entitleAPI.search);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_multi_search);
const searchFeedbackListSaga = createRequestSaga(SEARCH_FDBCK, entitleAPI.feedback_list);
// const searchSaga = createRequestSaga(GET_TYPE, entitleAPI.search);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

function* initTypeSaga(action) {
    const {payload} = action

    // 콤보 배열이 비었으면 
    if(payload.status.length < 1) {
        try {
            const response = yield call(adminAPI.code_multi_search, "APPLY_TYPE,STATUS")
            yield put({
                type: GET_COMBO_SUCCESS,
                payload: response.data,
                meta: response,
            })
        }
        catch(e) {
            yield put({
                type: GET_COMBO_FAILURE,
                payload: e,
                error: true,
            })
        }
    }

    // 검색 전체 범위 설정
    yield put({
        type: INITSEARCHSTATUS,
        payload: { statusAll: payload.statusAll },
    })

    // 검색 조건 배열 설정
    yield put({
        type: INITSTATUS,
    })

    if(payload.type === 'feedback') {
        yield put({
            type: SET_FEEDBACk,
            payload: {
                feedbackArr: payload.feedbackArr
            }
        })
    }

    // SET_FEEDBACk

    // 완료.
    yield put({
        type: INIT_TYPE_SUCCESS,
    })
}

export function* listSaga() {
    yield takeLatest(SEARCH, searchSaga);
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(INIT_TYPE, initTypeSaga);
    yield takeLatest(SEARCH_FDBCK, searchFeedbackListSaga);
}

const toDate = new Date();
let fromDate = new Date();
fromDate.setMonth(fromDate.getMonth() - 2);

const initialState = {
    listArr: [],
    maxPageCount: 10, // 한페이지에 10개씩 출력하도록. (고정값)
    pageCount: null,
    max: 3, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1,
    currentPage: null,

    search: {
        date_type: 'REQ_DATE',
        from_date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDate()}`,
        to_date: `${toDate.getFullYear()}-${toDate.getMonth()}-${toDate.getDate()}`,
        type: '',
        status: '',
        user_type: 'WINNER',
        user: '',
        all_search: "Y",
        page: 1,
        feedback_status: 'P',
        area: '',
        contract_type: '',
    }, // 검색
    statusAll: '',  // 현재 리스트 페이지에 따라 보여줄 리스트의 전체 검색 범위
    status: [],     // 진행상태 배열전체
    statusArr: [],  // 현재 리스트 페이지에 따라 보여줄 진행상태 배열 
    applyType: [],  // 유형 배열
    feedbackArr: [], // 피드백 상태 리스트

    initSuccess: false,
};

const list = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [INITSEARCH]: (state, {payload}) => {
            return ({
                ...state,
                search: {
                    ...initialState.search,
                    all_search: payload.all_search
                }
            })
        },
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => {
            return ({
            ...state,
            search: {
                ...state.search,
                [key]: value,
            }
        })},
        [CHANGE_TYPEARR]: (state, { payload: {key, value}}) => {
            return ({
            ...state,
            applyType: value,
        })},
        [CHANGE_STATUSARR]: (state, { payload: {value}}) => {
            // 빈배열이면 현재 satatusArr 의 checked 를 모두 true로 변경
            if(value.length > 0) {
                return {
                    ...state,
                    statusArr: value,
                }
            }
            else {
                return {
                    ...state,
                    statusArr: state.statusArr.map((item) => {
                        return ({
                            ...item,
                            checked: true,
                        })
                    }),
                }
            }
        },

        [SEARCH_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                listArr: payload,
                pageCount: payload.length === 0 ? 0 :  Math.ceil(payload[0].tot_count / state.maxPageCount)
            }
        },
        [SEARCH_FAILURE]: (state, {payload}) => {
            return {
                ...state,
                listArr: [],
                pageCount: null,
                max: 3,
                step: 1,
                currentPage: null,
                error: payload
            }
        },

        [SEARCH_FDBCK_SUCCESS]: (state, {payload}) => {
            return {
                ...state,
                listArr: payload,
                pageCount: Math.ceil(payload[0].tot_count / state.maxPageCount)
            }
        },
        [SEARCH_FDBCK_FAILURE]: (state, {payload : {error}}) => {
            return {
                ...state,
                listArr: [],
                pageCount: null,
                max: 3,
                step: 1,
                currentPage: null,
                error: error
            }
        },

        
        [GET_COMBO_SUCCESS]: (state, {payload: {APPLY_TYPE, STATUS}}) => {
            let _APPLY_TYPE = [];
            APPLY_TYPE.forEach(item => {
                _APPLY_TYPE.push({
                    ...item,
                    checked: true,
                })
            });
            return {
                ...state,
                status: STATUS,
                applyType: _APPLY_TYPE.concat([{    // 모든 유형에 '유형없음' 항목 추가
                    main_code: 'APPLY_TYPE',
                    sub_code: 'NOTYPE',
                    code_name: '유형없음',
                    display: 1,
                    use_flag: 'Y',
                    checked: true,
                }])
                // listArr: payload
            }
        },
        [GET_COMBO_FAILURE]: (state, {payload : {error}}) => {
            return {
                ...state,
                error: error
            }
        },
        [INITSEARCHSTATUS]: (state, {payload: {statusAll}}) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    // status: statusAll
                },
                statusAll,
            }
        },
        [INITSTATUS]: (state) => {
            let _statusArr = [];
            if(state.statusArr.length === 0) {
                state.status.filter((el) => state.statusAll.search(el.sub_code) >= 0).forEach(item => {
                    _statusArr.push({
                        sub_code: item.sub_code,
                        code_name: item.code_name,
                        checked: (state.search.isfirst === 'Y' && item.sub_code === 'ENAPVOK') ? false : true,    // 결재완료는 Default 체크에서 제외
                    })
                });
            }
            else {
                _statusArr = state.statusArr;
            }

            return {
                ...state,
                statusArr: (state.statusArr.length === 0 && _statusArr.length === 0) ? state.statusArr : _statusArr,
            }
        },
        [INIT_TYPE_SUCCESS]: (state) => ({
            ...state,
            initSuccess: true
        }),
        [SET_SEARCH_VALUE]: (state, {payload}) => {
            return ({
                ...state,
                search: {...payload},
            })
        },
        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },
        [SET_FEEDBACk]: (state, {payload: {feedbackArr}}) => {
            let _arr = feedbackArr.split(',');
            _arr = _arr.map((el) => {
                if(el === "P") {
                    return {
                        code: el,
                        name: '피드백 요청'
                    }
                }
                else if(el === "Y") {
                    return {
                        code: el,
                        name: '피드백 응답'
                    }
                }
                return null;
            })
            return ({
                ...state,
                feedbackArr: _arr
            })
        }
    },
    initialState,
);

export default list;