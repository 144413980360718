import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addZoom } from '../common';
import NextMaintain from '../components/NextMaintain';
import { ALERT } from '../lib/dataWord';
import { changeValue, doc_selectImg, getApi, getList, getView, initialize, saveView, setPaging } from '../modules/nextMaintain';

const NextMaintainContainer = () => {
    const [isPostback, setIsPostback] = useState(true);             //  최초 조회
    const [isLeaveMultiCbo, setIsLeaveMultiCbo] = useState(false);  //  Multipe ComboBox 공통 닫기 Event
    const [cboAreaData, setCboAreaData] = useState([]);             //  지역 Static Data
    const [isShowCboArea, setIsShowCboArea] = useState(false);      //  지역 Multipe Combo Show/Hide
    const [selectedAreas, setSelectedAreas] = useState('');         //  지역 선택된 Text
    const [isShowViewer, setIsShowViewer] = useState(false);        //  상세조회 Modal Show/Hide

    const dispatch = useDispatch();
    const {
        user
    } = useSelector(({user}) => ({
        user: user.user
    }))
    const {
        isSaveOk,

        //  검색조건
        from_date,
        to_date,
        work_type,
        change_flag,
        area,
        page,

        list,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,

        //  Modal Data
        change_id,
        work_name,
        work_addr,
        exec_date,
        change_yn,
        change_reason,
        cert_req_flag,
        api_req_date,
        api_rec_date,
        check_user_name,
        check_date,
        result_text,
        check_memo,
        selectImg,
        docImages,
     } = useSelector(({ nextMaintain }) => ({
        isSaveOk: nextMaintain.isSaveOk,

        from_date: nextMaintain.from_date,
        to_date: nextMaintain.to_date,
        work_type: nextMaintain.work_type,
        change_flag: nextMaintain.change_flag,
        area: nextMaintain.area,
        page: nextMaintain.page,

        list: nextMaintain.list,

        //  페이징
        pageCount: nextMaintain.pageCount,
        max: nextMaintain.max,
        step: nextMaintain.step,
        currentPage: nextMaintain.currentPage,

        //  Modal Data
        change_id: nextMaintain.change_id,
        work_name: nextMaintain.work_name,
        work_addr: nextMaintain.work_addr,
        exec_date: nextMaintain.exec_date,
        change_yn: nextMaintain.change_yn,
        change_reason: nextMaintain.change_reason,
        cert_req_flag: nextMaintain.cert_req_flag,
        api_req_date: nextMaintain.api_req_date,
        api_rec_date: nextMaintain.api_rec_date,
        check_user_name: nextMaintain.check_user_name,
        check_date: nextMaintain.check_date,
        result_text: nextMaintain.result_text,
        check_memo: !nextMaintain.check_memo ? "" : nextMaintain.check_memo,
        selectImg: nextMaintain.selectImg,
        docImages: nextMaintain.docImages,
    }))

    const onChange = (e) => {
        dispatch(changeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const onSearch = useCallback(() => {
        dispatch(getList({
            from_date: from_date,
            to_date: to_date,
            work_type: work_type,
            change_flag: change_flag,
            area: area,
            page: page,
        }))
    }, [dispatch, from_date, to_date, work_type, change_flag, area, page])
    
    const onSubmit = (e) => {
        e.preventDefault();
        
        onSearch();
    }

    // 페이징   --------------------------------------------
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({key: "page", value: num}));
            dispatch(changeValue({key: "currentPage", value: num}));
            
            onSearch();
        }
    }
    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
        }
    }
    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
        }
    }
    //  --------------------------------------------    페이징

    //  Multiple Check Combo 공통   --------------------------------------------
    const onShowMultiCbo = (type) => {
        setIsShowCboArea(!isShowCboArea);

        if(!isShowCboArea) {
            setIsLeaveMultiCbo(false);
        }
    }
    const onHideMultiCbo = (e) => {
        if(e.type === "mouseleave") {
            setIsLeaveMultiCbo(true);
        }
        else if(isLeaveMultiCbo) {
            setIsShowCboArea(false);
            setIsLeaveMultiCbo(false);
        }
    }
    const onEnterMultiCbo = () => {
        setIsLeaveMultiCbo(false);
    }
    //  --------------------------------------------    Multiple Check Combo 공통
    
    //  지역 Multiple Check Combo   --------------------------------------------
    useEffect(() => {
        if(user) {
            // null 처리
            if(user.auth_area) {
                let _areas = []
                user.auth_area.split(',').forEach(area => {
                    _areas.push({
                        area: area,
                        checked: true,
                    })
                });

                setCboAreaData(_areas);
            }
            else {
                setCboAreaData([]);
            }
            //  로그인 유저에 할당된 지역
        }
    }, [user])
    const onAreaChange = (item, e) => {
        setCboAreaData(
            cboAreaData.map((data) => 
                data.area === item.area ? {...data, checked: e.target.checked} : data
            )
        )
    }
    const onAreaAll = (e) => {
        setCboAreaData(
            cboAreaData.map((data) => 
                {return {...data, checked: e.target.checked}}
            )
        )
    }
    useEffect(() => {
        let selTexts = "", selValues = "";

        const filterData = cboAreaData.filter(x => x.checked === true);
        filterData.forEach(selected => {
            selTexts += selected.area + ",";
            selValues += selected.area + ",";
        });

        if(filterData.length === 0) {
            selTexts = "";
            selValues = "NONE";
        }
        else if(cboAreaData.length === filterData.length) {
            selTexts = "전체";
            selValues = "";
        }
        else {
            selTexts = selTexts.slice(0, -1);
        }

        setSelectedAreas(selTexts);
        dispatch(changeValue({key: 'area', value: selValues}))

    }, [dispatch, cboAreaData])
    //  --------------------------------------------    지역 Multiple Check Combo

    const onModalOpen = (item, e) => {
        dispatch(getView(item.change_id));
        setIsShowViewer(true);
    }
    const onModalClose = () => {
        setIsShowViewer(false);
    }
    const onSelectImg = (item, e) => {
        dispatch(doc_selectImg(item.file_data));
    }
    const onModalApiReq = () => {
        if(change_yn !== "Y") {
            ALERT("변동사항이 없습니다.");
            return;
        }
        if(window.confirm("등기부등본 API요청을 하시겠습니까?")) {
            dispatch(getApi({
                api_type: "1",
                doc_id: change_id,
                doc_step: "CHANGE",
            }))
        }
    }
    const onModalSave = () => {
        if(window.confirm("저장하시겠습니까?")) {
            dispatch(saveView({
                change_id: change_id,
                remark: check_memo,
            }));
            setIsShowViewer(false);
            onSearch();
        }
    }

    useEffect(() => {
        if(isSaveOk) {
            onSearch();
        }
    }, [dispatch, isSaveOk, onSearch])

    useEffect(() => {
        if(isPostback) {
            setIsPostback(false);
            onSearch();
        }
    }, [isPostback, onSearch])

    useEffect(() => {
        addZoom("")
    }, [dispatch, selectImg])

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        //  검색조건
        from_date,
        to_date,
        work_type,
        change_flag,

        list,
        
        //  지역 검색 관련
        cboAreaData,
        isShowCboArea,
        selectedAreas,
        onAreaChange,
        onAreaAll,
        
        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,
        
        //  페이징
        pageCount,
        max,
        step,
        currentPage,

        //  Modal Data
        work_name,
        work_addr,
        exec_date,
        change_yn,
        change_reason,
        cert_req_flag,
        api_req_date,
        api_rec_date,
        check_user_name,
        check_date,
        result_text,
        check_memo,
        selectImg,
        docImages,
        onSelectImg,

        onChange,
        onSubmit,
        onClickNum,
        onClickNext,
        onClickPrev,

        //  모달    ---------------------
        isShowViewer,
        onModalOpen,
        onModalClose,
        onModalApiReq,
        onModalSave,
    }
    return (
        <>
            <NextMaintain {...propDatas} />
        </>
    );
}

export default NextMaintainContainer;