import { createAction, handleActions } from 'redux-actions';
import { takeLatest, call, put } from 'redux-saga/effects';
import { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enwAdminUpload/INITIALIZE';
const CHANGE_VALUE = 'enwAdminUpload/CHANGE_VALUE';
const ROW_CHANGE_VALUE = 'enwAdminUpload/ROW_CHANGE_VALUE';
const ADD_ROW = 'enwAdminUpload/ADD_ROW';
const REMOVE_ROW = 'enwAdminUpload/REMOVE_ROW';
const SET_DOC_ID = 'enwAdminUpload/SET_DOC_ID';
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('enwAdminUpload/SAVE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));

export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({idx, fileReader, fileName, fileExtension}) => ({idx, fileReader, fileName, fileExtension}));
export const addRow = createAction(ADD_ROW, (idx) => idx);
export const removeRow = createAction(REMOVE_ROW, ({idx}) => ({idx}));
export const setDocID = createAction(SET_DOC_ID, ({doc_id, originFileList}) => ({doc_id, originFileList}));

export const save = createAction(SAVE, ({docId, files}) => ({docId, files}));

function* savefile(body) {
    try {
        let docid = body.payload.docId;
        const files = body.payload.files;

        for(let i = 0; i < files.length; i++) {
            const file = files[i];

            if(!file.file_id && file.use_flag === "N") {
                continue;
            }

            if(file) {
                const data = {
                    doc_id: docid,
                    files:
                    [
                        {
                            doc_type: 'EN_WRITE',
                            file_id: file.file_id,
                            file_type: file.file_type,
                            file_name: file.file_name,
                            contenttype: file.file_extension,
                            file_data: file.file_data[0].file_url.split(',').slice(-1)[0],
                            use_flag: file.use_flag,
                        }
                    ]
                };

                const result = yield call(entitleAPI.enwrite_file, data);
                
                if(!docid) {
                    docid = result.data;
                }
            }
        };
        yield put({
            type: SAVE_SUCCESS,
            payload: docid,
        });
    }
    catch(e) {
        yield put({
            type: SAVE_FAILURE,
            error: true,
            payload: e
        });
    }
}

export function* enwAdminUploadSaga() {
    yield takeLatest(SAVE, savefile);
}

const initialState = {
    _doc_id: '',
    isCloseModal: false,

    LastIdx: 0,
    fileList: [],

    enwAdminUpload: null,
    error: null
};

const enwAdminUpload = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { idx, fileReader, fileName, fileExtension } }) => {
            const _fileList = state.fileList.map((item) =>
                item.idx === idx ?
                {
                    ...item,
                    file_name: fileName,
                    file_extension: fileExtension,
                    file_data: [{
                        file_url: fileReader.result,
                        rotateion: 0,
                    }]
                } : item,
            );
            return {
                ...state,
                fileList: _fileList,
            };
        },
        [ADD_ROW]: (state) => {
            const _LastIdx = state.LastIdx + 1;
            return {
                ...state,
                LastIdx: _LastIdx,
                fileList: state.fileList.concat({
                            idx: _LastIdx,
                            file_id: '',
                            file_type: 'DOC000',
                            file_name: '',
                            file_extension: '',
                            file_data: '',
                            use_flag: 'Y',
                            rotation: "0",
                        }),
            }
        },
        [REMOVE_ROW]: (state, { payload: {idx}}) => {
            //  use_flag 'N' 처리를 먼저 한 뒤,
            let _fileList = state.fileList.map((item) =>
                item.idx === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );

            //  file_id가 있거나, file_id가 없으면서 use_flag가 'N'인 것만 남긴다.
            _fileList = _fileList.filter(x => (x.file_id || (!x.file_id && x.use_flag === 'Y')));

            return {
                ...state,
                fileList: _fileList,
            };
        },
        [SET_DOC_ID]: (state, { payload: {doc_id, originFileList} }) => {
            let _originFileList, _LastIdx;
            if(originFileList && originFileList.length > 0) {
                _originFileList = originFileList;
                _LastIdx = originFileList.length;
            }
            else {
                _originFileList = state.fileList;
                _LastIdx = 0;
            }

            return {
                ...state,
                _doc_id: doc_id,
                fileList: _originFileList,
                LastIdx: _LastIdx,
            }
        },

        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("첨부파일이 업로드되었습니다.");
            return ({
                ...state,
                _doc_id: payload,
                isCloseModal: true,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
    },
    initialState,
);

export default enwAdminUpload;