import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { qr } from '../common';
import AuditRequestList from '../components/AuditRequestList';
import dataURL from '../lib/dataURL';
import { changeValue, implyAuditRequest, initialize, setPaging, setSearchValue } from '../modules/Imply';

const AuditRequestLlistContainer = () => {
    const {
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        LH_implyAuditList, // -> 변경예정
        LH_implyAuditSearch, // -> 변경예정
    } = useSelector(({
        Imply
    }) => ({
        pageCount: Imply.pageCount,
        max: Imply.max,
        step: Imply.step,
        currentPage: Imply.currentPage,
        maxPageCount: Imply.maxPageCount,
        LH_implyAuditList: Imply.LH_implyAuditList,
        LH_implyAuditSearch: Imply.LH_implyAuditSearch,
    }))

    const [update, setUpdate] = useState(false);
    const [changeUrl, setChangeUrl] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // const {state: {func}} = location;

    //----------------------------------------------------------------------
    // 리스트

    // 리스트 클릭
    const onClickList = (item) => {
        // navigate()
        const {request_id} = item;
        navigate(dataURL.AuditRequestPage, {
            state: {
                request_id
            }
        })
    }
    //----------------------------------------------------------------------


    
    //----------------------------------------------------------------------
    // 검색
    
    // 묵시 대상자 검색
    const onSubmit = (e) => {
        e.preventDefault();
        setChangeUrl(true);
    }

    // 검색값 변경
    const onChangeSearch = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeValue({
            field: 'LH_implyAuditSearch',
            key: name,
            value
        }));
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({field: "LH_implyAuditSearch", key: "page", value: num}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }
    //----------------------------------------------------------------------

    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${dataURL.AuditRequestListPage}?&from_date=${LH_implyAuditSearch.from_date}&to_date=${LH_implyAuditSearch.to_date}&page=${LH_implyAuditSearch.page}`), 
            {
                state: location.state,
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, LH_implyAuditSearch])


    // 변경된 url 로 조회.
    // 최초 한번 수행 후, location 이동에 따라 한번더 실행.
    useEffect(() => {
        if(dispatch && location) {
            if(!location.search) {
                setChangeUrl(true)
            }
            else if(location.search){
                const tempObj = qr(location.search);

                dispatch(setPaging({
                    key: "currentPage",
                    value: Number(tempObj.page),
                }))
                dispatch(setPaging({
                    key: "step",
                    value: Number(tempObj.page),
                }))
                dispatch(implyAuditRequest({
                    ...tempObj
                }))
                dispatch(setSearchValue({
                    search: 'LH_implyAuditSearch',
                    value: tempObj
                }))
            }
        }
    }, [dispatch, location])

    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    useEffect(() => {
        if(update && dispatch) {
            dispatch(implyAuditRequest(LH_implyAuditSearch))
            setUpdate(false)
        }
    }, [dispatch, update, LH_implyAuditSearch])
    
    const propDatas = {
        LH_implyAuditList,
        pageCount,
        max,
        step,
        currentPage,
        maxPageCount,
        LH_implyAuditSearch,

        onClickNum,
        onClickNext,
        onClickPrev,
        onSubmit,
        onChangeSearch,
        onClickList,
    }
    return (
        <>
            <AuditRequestList {...propDatas}/>
        </>
    );
}

export default AuditRequestLlistContainer;