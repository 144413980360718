import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import dataURL from '../lib/dataURL';
import '../index.css'

const ImplyRegisterListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin-bottom: 20px;
    }
    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .request-btn{
        position: absolute;
        top: 50px;
        right: 50px;
    }

    // 검색 Multiple checkbox Combo
    .cboStDiv {
        position: relative;
        z-index: 180;
    }
    .txtSelectedValues {
        display: flex;
        width: 100%;
        height: 36px;
        padding: 5px 10px;
        border: 1px solid #BDBDBD;
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .txtSelectedValues div {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
    }
    .cboMultiple {
        position: absolute;
        top: 36px;
        border: 1px solid black;
        list-style: none;
        max-height: 150px;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1;
    }
    .cboMultiple li {
        background-color: #ffffff !important;
    }
    .cboMultiple li:hover {
        background-color: #ccc;
    }
    .cboMultiple li input[type="checkbox"] + label:before {
        top: 8px;
        left: 6px;
    }
    .cboMultiple li label {
        background-color: inherit !important;
        height: 20px !important;
        padding: 5px 5px 5px 25px;
        width: 100%;
        text-align: left;
        color: #545454;
        font-size: 14px;
        font-weight: 300 !important;
        justify-content: flex-start !important;
    }
`;

const ImplyRegisterList = (props) => {
    const {
        search,
        // maxPageCount,
        pageCount,
        max,
        step,
        currentPage,
        ImplyReqList,
        // dummy1,
        func,

        //  지원유형 검색 관련
        cboApply,
        isShowCboType,
        selectedTypes,
        onTypeChange,
        onTypeAll,

        //  Multiple Check Combo 공통
        onShowMultiCbo,
        onHideMultiCbo,
        onEnterMultiCbo,

        onSubmit,
        onChange,
        onClickNum,
        onClickNext,
        onClickPrev,
        onCheck,
        onClick,
        onInitSearch,
    } = props
    return (
        <ImplyRegisterListBlock>
            <h1>묵시대상자 등록 요청 리스트</h1>
            <Link to={dataURL.ImplyRegisterWorkPage}  state={{func: func}}  style={{ textDecoration: 'none' }} className="request-btn">요청작성</Link>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt'>
                            {/* <h4 className='bg'>기간</h4> */}
                            {/*[Dev] 22.02.22 label className bg 추가*/}
                            <div className='st'>
                                <label htmlFor='date_type' className='bg'>종류</label>
                                <select name="date_type" id="date_type"  value={search.date_type} onChange={(e) => onChange(e, "search")}>
                                    <option value="">선택하세요</option>
                                    <option value="REQUEST">요청일시</option>
                                    <option value="COMPLETE">처리일시</option>
                                </select>
                            </div>

                            {/*[Dev] 22.02.22 st클래스안에 div, class=bg 추가*/}
                            <div className='st'>
                                <div>
                                    <label htmlFor='from_date'>기간</label>
                                    <input name="from_date" id="from_date" value={search.from_date} type="date" onChange={(e) => onChange(e, "search")}/>
                                    <span>~</span>
                                    <input name="to_date" id="to_date" value={search.to_date} type="date" onChange={(e) => onChange(e, "search")}/>
                                </div>
                            </div>

                            {/* <h4 className='bg'>지원유형</h4> */}
                            {/*[Dev] 22.02.22 class=bg 추가*/}
                            {/* <div className='st'>
                                <label htmlFor='type' className='bg'>지원유형</label>
                                <select name="type" id="type" value={search.type || ""} onChange={(e) => onChange(e, "search")} >
                                    <option value={""}>전체</option>
                                    {cboApply.map((item) => (
                                        <option key={item.sub_code} value={item.sub_code}>{item.code_name}</option>
                                    ))}
                                </select>
                            </div> */}
                            <div className='st' tabIndex={0} onBlur={onHideMultiCbo} onMouseLeave={onHideMultiCbo} onMouseEnter={onEnterMultiCbo}>
                                <label htmlFor='cboTyep'>유형</label>
                                <div className='cboStDiv' style={{width:"192px"}}>
                                    <div className='txtSelectedValues' onClick={() => onShowMultiCbo('TYPE')}>
                                        <div>{selectedTypes}</div>
                                        ▼
                                    </div>
                                    {isShowCboType &&
                                        <ul className="cboMultiple" id="cboType">
                                            <li>
                                                <input type="checkbox" name="typeALL" id="typeALL" checked={cboApply.length === cboApply.filter(x => x.checked === true).length} onChange={onTypeAll} />
                                                <label htmlFor="typeALL" className='notlabel'>전체 선택</label>
                                            </li>
                                            {cboApply.map((item) => (
                                                <li key={item.sub_code}>
                                                    <input type="checkbox" name={item.sub_code} id={item.sub_code} checked={item.checked} onChange={onTypeChange.bind(this, item)} />
                                                    <label htmlFor={item.sub_code} className='notlabel'>{item.code_name}</label>
                                                </li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            </div>
                            {/*[Dev] 22.02.22 st클래스안에 div, class=bg 추가*/}
                            {/* <h4 className='bg'>담당자</h4> */}
                            <div className='st'>
                                <label htmlFor='req_user' className='bg'>요청자</label>
                                <input name="req_user" id="req_user" value={search.req_user} type="text" onChange={(e) => onChange(e, "search")}/>
                            </div>
                            <div className='st'>
                                <label htmlFor='prc_user' className='bg'>처리자</label>
                                <input name="prc_user" id="prc_user" value={search.prc_user} type="text" onChange={(e) => onChange(e, "search")}/>
                            </div>


                            {/*[Dev] 22.02.22 st클래스안에 div 추가*/}
                            {/* <h4 className='bg'>상태</h4> */}
                            <div className='checkbox st'>
                                <div>
                                    <input type="checkbox" name="status1" id="status1" value="REQUEST" checked={search.status1 === "REQUEST"? true : false} onChange={onCheck}/>
                                    <label htmlFor='status1'>요청중</label>
                                </div>
                                <div>
                                    <input type="checkbox" name="status2" id="status2" value="COMPLETE" checked={search.status2 === "COMPLETE"? true : false} onChange={onCheck}/>
                                    <label htmlFor='status2'>요청완료</label>
                                </div>
                            </div>
                        </div>
                         {/* [DEV] 22.03.25 임의로 초기화버튼추가 */}
                        <div className='btn'>
                            <button type='submit' className='green-sch-btn'>검색</button>
                            <button type='button' className='reset-btn' onClick={onInitSearch}>검색조건 초기화</button>
                        </div>
                    </div>
                </form>
            </div>

            <div className='tableCover'>
                <table className="tt">
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>요청 ID</th>
                            <th>요청일시</th>
                            <th>요청자</th>
                            <th>처리일시</th>
                            <th>처리자 ID</th>
                            <th>처리자</th>
                            <th>지원유형</th>
                            <th>등록인원수</th>
                            <th>진행상태</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ImplyReqList.length === 0 &&
                            <tr>
                                <td colSpan={11}>검색 결과가 없습니다.</td>
                            </tr>
                        }
                        {ImplyReqList.length > 0? 
                        <>
                            {ImplyReqList.map((item, idx) => {
                                return (
                                    <tr key={idx} onClick={() => onClick(item.req_id, item.status)}>
                                        <td>{(30 * (currentPage - 1)) + idx+1}</td>
                                        <td>{item.req_id}</td>
                                        <td>{item.request_date}</td>
                                        <td>{item.req_user_name}</td>
                                        <td>{item.processing_date}</td>
                                        <td>{item.processor}</td>
                                        <td>{item.processor_name}</td>
                                        <td>{item.type_name}</td>
                                        <td>{item.people_count}</td>
                                        <td>{item.status}</td>
                                        <td>{item.memo}</td>
                                    </tr>
                                )
                            })}
                        </> 
                        : null}
                    </tbody>
                </table>
            </div>

            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>
        </ImplyRegisterListBlock>
    );
}

export default ImplyRegisterList;