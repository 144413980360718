import React from 'react';
import styled from 'styled-components';
import dataWORD from '../lib/dataWord';

const ReportListBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 50px;
    box-sizing: border-box;

    h2{
        color: #545454;
        font-size: 20px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 360px) and (max-width: 1023px) {
        .search{
            display : none;
        }
    }
`;

const ReportPopup = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .popupWrap{
        width: 800px;
        height: 95vh;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        padding: 0 20px 20px;
        position: relative;
    }
    h2{
        color: #545454;
        font-size: 26px;
        text-align: center;
        margin-bottom: 15px;
    }
    h3 {
        margin-top: 20px;
        margin-bottom: 5px;
    }
    h4 {
        color: red;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .p-right {
        text-align: right;
    }
    .divHeader {
        position: sticky;
        top: 0;
        width: 100%;
        padding-top: 10px;
        background-color: white;
        border-bottom: 1px solid #ccc;
    }
    .divHeader button {
        position: absolute;
        top: 15px;
        right: 0px;
    }

    .tbl-summary,
    .tbl {
        width: 100%;
        border-collapse: collapse;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .tbl-summary th,
    .tbl-summary td,
    .tbl th,
    .tbl td {
        border: 1px solid black;
        background-color: white;
        padding: 5px;
        text-align: center;
    }
    .tbl-summary th,
    .tbl th {
        background-color: #ccc;
    }
    .tbl th {
        width: 100px;
    }

    .ng-box > p {
        margin-left: 10px;
    }
    .ng-box > .subRemark {
        margin-left: 20px;
    }
    .ng-box hr {
        margin: 15px 0;
    }
`;

const ReportList = (props) => {
    const {
        //  검색조건
        from_date,
        to_date,

        list,

        //  Modal Data
        rptMain,
        rptDtlOwner,
        rptDtlSeizure,
        rptDtlResistance,
        rptDtlEtc,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,

        onChange,
        onSubmit,
        
        onClickNum,
        onClickNext,
        onClickPrev,
        
        //  Modal
        isShowRpt,
        onClickRow,
        onClose,
    } = props
    return (
        <ReportListBlock>
            <h1>보고서 리스트</h1>

            <div className='search-title'>
                <h2>검색조건</h2>
            </div>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt'>
                            <div className='st'>
                                <div>
                                    <label htmlFor='from_date' className='bg'>시작일</label>
                                    <input type="date" name="from_date" id="from_date" value={from_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                                <div>
                                    <label htmlFor='to_date' className='bg'>종료일</label>
                                    <input type="date" name="to_date" id="to_date" value={to_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                            </div>

                            <div className='btn'>
                                <button className='green-sch-btn'>검색</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <h2>검색결과</h2>
            <table className='tt'>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>작성일</th>
                        <th>요청자</th>
                        <th>시작일</th>
                        <th>종료일</th>
                        <th>묵시대상자수</th>
                        <th>묵시OK건수</th>
                        <th>묵시NG건수</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, idx) => {
                        return (
                            <tr key={idx} onClick={onClickRow.bind(this, item)}>
                                <td>{(30 * (currentPage - 1)) + idx + 1}</td>
                                <td>{item.report_date}</td>
                                <td>{item.user_name}</td>
                                <td>{item.from_date}</td>
                                <td>{item.to_date}</td>
                                <td>{item.total_count}</td>
                                <td>{item.ok_count}</td>
                                <td>{item.ng_count}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div>


            {isShowRpt &&
                <ReportPopup>
                    <div className='popupWrap'>
                        <div className='divHeader'>
                            <h2>묵시적갱신 대상건 검토 보고서</h2>
                            <button className="cancel-btn" onClick={onClose}>닫기</button>
                        </div>

                        <h3>1. 개요</h3>
                        <div>
                            ○ 기 간 : {rptMain.from_date} ~ {rptMain.to_date}<br/>
                            ○ 건 수 : {rptMain.total_count}
                        </div>

                        <h3>2. 총괄표</h3>
                        <table className='tbl-summary'>
                            <tbody>
                                <tr>
                                    <th colSpan={2}>구분</th>
                                    <th>건수</th>
                                    <th>비고</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>합계</td>
                                    <td>{rptMain.total_count}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>묵시적 갱신</td>
                                    <td>{rptMain.ok_count}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td rowSpan={5} style={{borderRight:"none",width:"10px"}}></td>
                                    <td style={{borderLeft:"none"}}>묵시적 갱신 불가</td>
                                    <td>{rptMain.ng_count}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>갱매 · 공매 진행중</td>
                                    <td>{rptMain.owner_change}</td>
                                    <td>첨부1</td>
                                </tr>
                                <tr>
                                    <td>임차권 설정</td>
                                    <td>{rptMain.seizure_setting}</td>
                                    <td>첨부2</td>
                                </tr>
                                <tr>
                                    <td>대항력 상실</td>
                                    <td>{rptMain.resistance_loss}</td>
                                    <td>첨부3 (입주자 사망, 전출 등)</td>
                                </tr>
                                <tr>
                                    <td>기타</td>
                                    <td>{rptMain.etc}</td>
                                    <td>첨부4 (LH 별도 확인 필요)</td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <h3>3. 묵시적갱신 불가 건별 Report: 첨부</h3>
                        <h4>▨ 첨부1: 경공매 진행중으로 묵시적갱신 불가({rptMain.owner_change}건)</h4>
                        {rptDtlOwner.map((item, idx) => (
                            <div key={idx} className='ng-box'>
                                {idx + 1}. 묵시적갱신 불가 건 Report({item.rec_area}, {item.cust_name + (item.cust_no).slice(0, 6)})<br/>
                                <p>1) 계약개요</p>
                                <table className='tbl'>
                                    <tbody>
                                        <tr>
                                            <th>입주자 성명</th>
                                            <td>{item.cust_name}</td>
                                            <th>{dataWORD.BIRTHDATE}</th>
                                            <td>{item.cust_no}</td>
                                        </tr>
                                        <tr>
                                            <th>지원유형</th>
                                            <td>{item.imply_type_name}</td>
                                            <th>연락처</th>
                                            <td>{item.cust_phone}</td>
                                        </tr>
                                        <tr>
                                            <th>주 소</th>
                                            <td colSpan={3}>{item.addr}</td>
                                        </tr>
                                        <tr>
                                            <th>주택소유자</th>
                                            <td>{item.house_owner}</td>
                                            <th>계약만료일</th>
                                            <td>{item.contract_end_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>2) 묵시적갱신 불가 사유</p>
                                {item.remark ? <p className='subRemark'>○ {item.remark}</p> : null}
                                <p>3) 대응(조치)방안</p>
                                <hr />
                            </div>
                        ))}

                        <h4>▨ 첨부2: 임차권 설정으로 묵시적갱신 불가({rptMain.seizure_setting}건)</h4>
                        {rptDtlSeizure.map((item, idx) => (
                            <div key={idx} className='ng-box'>
                                {idx + 1}. 묵시적갱신 불가 건 Report({item.rec_area}, {item.cust_name + (item.cust_no).slice(0, 6)})<br/>
                                <p>1) 계약개요</p>
                                <table className='tbl'>
                                    <tbody>
                                        <tr>
                                            <th>입주자 성명</th>
                                            <td>{item.cust_name}</td>
                                            <th>{dataWORD.BIRTHDATE}</th>
                                            <td>{item.cust_no}</td>
                                        </tr>
                                        <tr>
                                            <th>지원유형</th>
                                            <td>{item.imply_type_name}</td>
                                            <th>연락처</th>
                                            <td>{item.cust_phone}</td>
                                        </tr>
                                        <tr>
                                            <th>주 소</th>
                                            <td colSpan={3}>{item.addr}</td>
                                        </tr>
                                        <tr>
                                            <th>주택소유자</th>
                                            <td>{item.house_owner}</td>
                                            <th>계약만료일</th>
                                            <td>{item.contract_end_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>2) 묵시적갱신 불가 사유</p>
                                {item.remark ? <p className='subRemark'>○ {item.remark}</p> : null}
                                <p>3) 대응(조치)방안</p>
                                <hr />
                            </div>
                        ))}

                        <h4>▨ 첨부3: 주소이전 및 입주자 사망 등으로 대항력 상실 건({rptMain.resistance_loss}건)</h4>
                        {rptDtlResistance.map((item, idx) => (
                            <div key={idx} className='ng-box'>
                                {idx + 1}. 묵시적갱신 불가 건 Report({item.rec_area}, {item.cust_name + (item.cust_no).slice(0, 6)})<br/>
                                <p>1) 계약개요</p>
                                <table className='tbl'>
                                    <tbody>
                                        <tr>
                                            <th>입주자 성명</th>
                                            <td>{item.cust_name}</td>
                                            <th>{dataWORD.BIRTHDATE}</th>
                                            <td>{item.cust_no}</td>
                                        </tr>
                                        <tr>
                                            <th>지원유형</th>
                                            <td>{item.imply_type_name}</td>
                                            <th>연락처</th>
                                            <td>{item.cust_phone}</td>
                                        </tr>
                                        <tr>
                                            <th>주 소</th>
                                            <td colSpan={3}>{item.addr}</td>
                                        </tr>
                                        <tr>
                                            <th>주택소유자</th>
                                            <td>{item.house_owner}</td>
                                            <th>계약만료일</th>
                                            <td>{item.contract_end_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>2) 묵시적갱신 불가 사유</p>
                                {item.remark ? <p className='subRemark'>○ {item.remark}</p> : null}
                                <p>3) 대응(조치)방안</p>
                                <hr />
                            </div>
                        ))}

                        <h4>▨ 첨부4: LH별도 확인 필요 건({rptMain.etc}건)</h4>
                        {rptDtlEtc.map((item, idx) => (
                            <div key={idx} className='ng-box'>
                                {idx + 1}. 묵시적갱신 불가 건 Report({item.rec_area}, {item.cust_name + (item.cust_no).slice(0, 6)})<br/>
                                <p>1) 계약개요</p>
                                <table className='tbl'>
                                    <tbody>
                                        <tr>
                                            <th>입주자 성명</th>
                                            <td>{item.cust_name}</td>
                                            <th>{dataWORD.BIRTHDATE}</th>
                                            <td>{item.cust_no}</td>
                                        </tr>
                                        <tr>
                                            <th>지원유형</th>
                                            <td>{item.imply_type_name}</td>
                                            <th>연락처</th>
                                            <td>{item.cust_phone}</td>
                                        </tr>
                                        <tr>
                                            <th>주 소</th>
                                            <td colSpan={3}>{item.addr}</td>
                                        </tr>
                                        <tr>
                                            <th>주택소유자</th>
                                            <td>{item.house_owner}</td>
                                            <th>계약만료일</th>
                                            <td>{item.contract_end_date}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>2) 묵시적갱신 불가 사유</p>
                                {item.remark ? <p className='subRemark'>○ {item.remark}</p> : null}
                                <p>3) 대응(조치)방안</p>
                                <hr />
                            </div>
                        ))}
                    </div>
                </ReportPopup>
            }
        </ReportListBlock>
    );
}

export default ReportList;