import React from 'react';
import styled from 'styled-components';

const FieldChkBlock = styled.div`

    .popupModal > div:nth-child(2){
        width: 780px;
        padding: 55px 70px;
    }

    .popupModal > div:nth-child(2) h4{
        font-size: 26px;
        color: #545454;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 26px;
    }
    .lblInputFiles {
        color: #ffffff;
        font-size: 19px;
        font-weight: 500;
        padding: 6px 12px;
        box-sizing: border-box;
        margin: 0 10px;
        border-radius: 24px;
        cursor: pointer;
        background: linear-gradient(to bottom, #1EBF86 , #0B999C);
    }
    #inputFiles {
        display: none;
    }
    .modalDivImages {
        position: relative;
        // width: -webkit-calc(100% - 40px);
        height: -webkit-calc(100% - 40px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .modalDivImages > div:first-child {
        width: 100%;
        height: 500px;
        box-sizing: border-box;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
    }
    #div-rotate {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .selectImg {
        border: none;
        width: -webkit-calc(100% - 130px);
    }
    .navImgBox {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        overflow-y: auto;
        opacity: 0.5;
    }
    .navImgBox:hover {
        opacity: 1;
    }
    .navImg {
        width: 120px;
        height: 120px;
        border: 1px solid rgba(255, 0, 0, 0);
    }
    .navImg:hover {
        border: 1px solid red;
    }
    .navImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
    .navImgBox > div {
        position: relative;
    }
    .navImgBox .btnDelImg {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 2px 5px;
        border: 1px solid #ccc;
        border-radius: 50%;
        background-color: white;
        opacity: 0.5;
    }
    .navImgBox .btnDelImg:hover {
        cursor: pointer;
        opacity: 1;
    }
    textarea {
        width: 100%;
        height: 70px;
        margin-top: 10px;
        padding: 10px;
        box-sizing: border-box;
        resize: none;
        border: 1px solid #bdbdbd;
        border-radius: 10px;
    }
    // .modalDivImages .divButtons {
    //     width: 660px;
    //     padding: 5px 0 10px;
    //     text-align: right;
    // }

    .divButtons {
        margin-top: 15px;
    }
`;

const FieldChk = (props) => {
    const {
        fileChooser,
        selectImg,
        docImages,
        remark,
        
        onChange,
        onChangeFiles,
        onSelectImg,
        onDeleteImg,
        onSave,
        onClose,
        onWaiting,
    } = props;

    return (
        <FieldChkBlock>
           <div className='popupModal'>
                <div />
                <div>
                    <h4>
                        현장조사 사진첨부
                        <label className='lblInputFiles' htmlFor='inputFiles'>파일추가</label>
                        <input type="file" id="inputFiles" value={fileChooser} onChange={onChangeFiles} accept=".jpg,.jpeg,.png" multiple/>
                    </h4>
                    <div className='modalDivImages'>
                        <div id='imgView' className='imgView'>
                        {
                            selectImg && 
                            <div id='div-rotate' style={{transform:'rotate(' + 0 + 'deg)'}}>
                                {selectImg && <img id="selectImg" className='selectImg' src={selectImg} draggable={false} onError={onWaiting} alt=''/>}
                            </div>
                        }
                        </div>
                        <div className='navImgBox'>
                            {docImages.map((item, idx) => (
                                <div key={idx}>
                                    <div className='navImg'>
                                        <img src={item.file_data} alt="" onClick={onSelectImg.bind(this, item)} />
                                        <span className='btnDelImg' onClick={onDeleteImg.bind(this, item)}>Ｘ</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <textarea name="remark" id="remark" value={remark} onChange={onChange} placeholder='내용을 입력해주세요.'></textarea>
                        </div>
                    </div>
                    <div className='divButtons'>
                        <button onClick={onSave} className="divB-Save">확인</button>
                        <button onClick={onClose} className="divB-Cancel">닫기</button>
                    </div>
                </div>
           </div>
        </FieldChkBlock>
    );
}

export default FieldChk;