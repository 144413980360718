import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FindBroker from '../../components/Modal/FindBroker';
import { changeValue, getBroker } from '../../modules/Modal/findBroker';

const FindBrokerContainer = (props) => {
    const {
        onCloseFindBroker,
        onSelectBroker,
    } = props;

    const dispatch = useDispatch();
    const {
        brokerName,
        brokerNumber,
        brokerTelno,
        brokers,
    } = useSelector(({ findBroker }) => ({
        brokerName: findBroker.brokerName,
        brokerNumber: findBroker.brokerNumber,
        brokerTelno: findBroker.brokerTelno,
        brokers: findBroker.brokers,
    }));

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onSearch = () => {
        dispatch(
            getBroker({
                comp_name: brokerName,
                reg_no: brokerNumber,
                tel: brokerTelno,
            })
        )
    }
    const onSelected = (item, e) => {
        onSelectBroker(item);
    }


    const propDatas = {
        brokerName,
        brokerNumber,
        brokerTelno,
        brokers,
        onCloseFindBroker,

        onChange,
        onSearch,
        onSelected,
    }
    return (
        <>
            <FindBroker {...propDatas}/>
        </>
    );
}

export default FindBrokerContainer;