import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { idValidation, pwValidation } from '../common';
import RegisterForm from '../components/RegisterForm';
import { ALERT } from '../lib/dataWord';
import { changeFile, changeValue, initialize, regApi, certReq, setTimeValue, certChk, overlapChk, getCombo } from '../modules/auth';

const RegisterContainer = () => {
    const {
        register,
        error,
        regsuccess,
        setTimeStart,
        certSuccess,
        user_id,
        user_pwd,
        user_pwd_chk,
        chk_overlap,
        cboAllowText,
    } = useSelector(({ auth }) => ({
        register: auth.register,
        error: auth.error,
        regsuccess: auth.regsuccess,
        setTimeStart: auth.setTimeStart,
        certSuccess: auth.certSuccess,
        user_id: auth.register.user_id,
        user_pwd: auth.register.user_pwd,
        user_pwd_chk: auth.register.user_pwd_chk,
        chk_overlap: auth.register.chk_overlap,
        cboAllowText: auth.cboAllowText,
    }));

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [isShowDaum, setIsShowDaum] = useState(false);
    const [isShowTermsModal, setIsShowTermsModal] = useState({
        show: false,
    });
    const workString = "register";
    // 타이머
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const time = useRef(180);
    const interval = useRef(null)

    let isBroker = false;
    if( location.state && location.state.isBroker ) {
        isBroker = location.state.isBroker;
    }
    const [pwdMsg, setPwdMsg] = useState({
        msg: '',
        error: false,
    })
    const pwdRef = useRef();

    // 인증번호 받기
    const sendCode = () => {
        if(!setTimeStart) {
            if(register.user_phone) {
                if(register.user_phone.length !== 11 && register.user_phone.slice(0,3) === '010') {
                    ALERT("핸드폰 번호를 확인하세요")
                }
                else {
                    dispatch(certReq({
                        phone: register.user_phone
                    }))
                }
            }
            else {
                ALERT('핸드폰 번호를 입력해주세요')
            }
        }
        else {
            ALERT('3분이 지나고 다시 발급받을수 있습니다.');
        }
    }

    // 인증번호 확인
    const checkCode = () => {
        //  22.03.24 성명규. certSuccess의 값이 'success' 라면 전화번호와 인증번호 관련된 input box와 button이 모두 disabled 처리되도록 변경함.
        if(register.user_code_chk) {
            dispatch(certChk({
                phone:  register.user_phone,
                cert_no:register.user_code_chk
            }))
        }
        else {
            ALERT('인증번호를 입력하세요')
        }
    }
    const checkIpt = () => {
        let valid = true;
        let msg = '';

        // 입력값 확인
        // 공통
        if(!register.user_id){ 
            valid=false;
            msg+= '아이디, ';
        }
        if(!register.user_pwd){ 
            valid=false;
            msg+= '비밀번호, ';
        }
        if(!register.user_phone){ 
            valid=false;
            msg+= '전화번호, ';
        }
        if(!register.user_code_chk){  // 인증번호 확인
            valid=false;
            msg+= '인증번호, ';
        }
        if(!register.user_name){ 
            valid=false;
            msg+= isBroker? '중개사 성명, ' : '성명, ';
        }
        if(!register.user_company){ 
            valid=false;
            msg+= isBroker? '중개사 상호, ' : '소속, ';
        }

        // 비밀번호 동일 여부 확인
        if(register.user_pwd !== register.user_pwd_chk) {
            valid = false;
            msg+= '비밀번호 확인, ';
        }

        // 중개사
        if(isBroker) {
            if(!register.reg_no || register.reg_no.length < 10){ 
                valid=false;
                msg+= '사업자등록번호, ';
            }
            if(!register.user_tel){ 
                valid=false;
                msg+= '사무실번호, ';
            }
            if(!register.addr_no){ 
                valid=false;
                msg+= '우편번호, ';
            }
            if(!register.addr){ 
                valid=false;
                msg+= '주소, ';
            }
            if(!register.addr_dtl){ 
                valid=false;
                msg+= '상세주소 ';
            }
            // if(!register.business.file_data){ // 사업자등록증
            // valid=false;
            // }
            // if(!register.deduction.file_data){ // 공제증서
            // valid=false;
            // }
        }
        if(!valid) msg+='는(은) 필수입력입니다.'
        return {valid, msg}
    }

    // 회원가입
    const onRegister = () => {
        // 필수입력값 확인
        const {valid, msg} = checkIpt();

        if(valid) {
            if(!user_pwd || !user_pwd_chk) {
                ALERT('비밀번호를 입력하세요');
                pwdRef.current.focus();
            }
            else if (user_pwd.length < 8 || user_pwd_chk.length < 8) {
                ALERT('비밀번호는 8자리 이상으로 조합해야 합니다.');
                pwdRef.current.focus();
            }
            else if(user_id && user_pwd.includes(user_id)){
                ALERT('비밀번호는 아이디가 포함될 수 없습니다.');
                pwdRef.current.focus();
            }
            else if(!pwValidation(user_pwd)) {
                ALERT('비밀번호는 영어 대소문자, 숫자, 특수문자(#?!@$%^&*-,.<>~()_=+)로 조합되어야 합니다.\n동일한 문자, 연속된 숫자는 네자리 이상 사용할 수 없습니다.');
                pwdRef.current.focus();
            }
            else if (certSuccess !== 'success') {
                ALERT("핸드폰 인증이 필요합니다.");
            }
            else if(chk_overlap !== 1) {
                ALERT('아이디 중복확인이 필요합니다.')
            }
            else if(!register.terms_age || !register.terms_service || !register.terms_privacy){
                ALERT('필수 이용약관 항목에 동의해주세요.');
            }
            else if( chk_overlap === 1 ) {
                // Test
                //     ...register,
                //     broker_yn: isBroker? "Y" : "N", 
                //     owner: register.owner? register.owner : register.user_name // 기존 
                // })
                dispatch(regApi({
                    ...register,
                    broker_yn: isBroker? "Y" : "N", 
                    owner: register.owner? register.owner : register.user_name // 기존 
                }))
            }
        }
        else {
            ALERT(msg)
        }
    }

    // 주소 검색
    const onComplete = (data) => {
        dispatch(
            changeValue({
                field: workString,
                key: 'addr_no',
                value: data.zonecode      
            })
        );
        dispatch(
            changeValue({
                field: workString,
                key: 'addr',
                value: data.roadAddress    
            })
        );
        setIsShowDaum(false)
    }

    const onCloseDaum = () => {
        setIsShowDaum(false)
    }

    const onOpenDaum = () => {
        setIsShowDaum(true)
    }

    // 파일
    const onFileChange = (e) => {
        const file = e.target.files[0];
        const {target: {name}} = e
        if(!file) return;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            dispatch(changeFile({
                name,
                file_name: file.name,
                file_data: reader.result,
                contenttype: file.type
            }))
        }
    }

    const onImgCancle = (target) => {
        dispatch(changeFile({
            name: target,
            file_name: '',
            file_data: '',
            contenttype: ''
        }))
    }

    const onChange = e => {
        const {name, value} = e.target;
        let valid = true;

        if(name==="user_phone" && value.length > 11 ) valid = false;
        if(name==="reg_no" && value.length > 10 ) valid = false;

        if(valid) {
            dispatch(
                changeValue({
                    field: workString,
                    key: name,
                    value      
                })
            );
        }
    }

    // 약관 동의
    const onChangeAgree = e => {
        const {name, checked} = e.target;
        if(name === 'terms_agree_all'){
            if(checked) {
                dispatch(changeValue ({
                    field: workString,
                    key: 'terms_age',
                    value: true
                }));
                dispatch(changeValue ({
                    field: workString,
                    key: 'terms_service',
                    value: true
                }));
                dispatch(changeValue ({
                    field: workString,
                    key: 'terms_privacy',
                    value: true
                }));
            }
            else {
                dispatch(changeValue ({
                    field: workString,
                    key: 'terms_age',
                    value: false
                }));
                dispatch(changeValue ({
                    field: workString,
                    key: 'terms_service',
                    value: false
                }));
                dispatch(changeValue ({
                    field: workString,
                    key: 'terms_privacy',
                    value: false
                }));
            }

        }else{
            if(!checked){
                dispatch(changeValue({
                    field: workString,
                    key: 'terms_agree_all',
                    value: false
                }));
            }
        }

        dispatch(
            changeValue({
                field: workString,
                key: name,
                value: checked
            })
        )
    }

    //헤더컴포넌트를 빼서 뒤로가기버튼이 없어서 임의로넣음
    const onNavBackClick = () => {
        navigate(-1);
    }

    // 아이디 중복체크
    // 아이디 유효성 체크 여기서 하도록 변경
    const onIdCheck = () => {
        //  관계자 회원가입의 경우, 코드MST에 정해진 문자로 시작하지 않는다면 '아이디 구조가 맞지 않음' return
        let isAllowID = false;
        if(isBroker) {
            isAllowID = true;
        }
        else {
            for(let i = 0; i < cboAllowText.length; i++) {
                const item = cboAllowText[i];
                const allowText = item.sub_code.toLowerCase();

                if(user_id.substring(0, allowText.length) === allowText) {
                    isAllowID = true;
                }
            }
        }

        if( !user_id ) {
            ALERT('아이디를 입력하세요');
        } 
        else if (user_id.length < 6) {
            ALERT('아이디는 최소 6자리 이상이어야 합니다.')
        }
        else if(!isAllowID) {
            ALERT("아이디 구조가 맞지 않습니다.");
        }
        else if (!idValidation(user_id)) {
            ALERT('아이디는 영어 소문자, 숫자로만 조합되어야 합니다. 연속된 숫자, 문자는 다섯자리 이상 사용할 수 없습니다.')
        }
        else {
            dispatch(overlapChk(user_id));
        }
    }

    useEffect(() => {
        dispatch(getCombo('ALLOW_TEXT'));

        return () => dispatch(initialize()); 
    }, [dispatch])

    // 비밀번호
    useEffect(() => {
        if(user_pwd && user_pwd_chk) {
            if(user_pwd_chk !== user_pwd) {
                setPwdMsg({
                    msg: '비밀번호가 일치하지 않습니다.',
                    error: true,
                })
            }
            else if (user_pwd.length < 8 || user_pwd_chk.length < 8) {
                setPwdMsg({
                    msg: '비밀번호는 8자리 이상으로 조합해야 합니다.',
                    error: true,
                })
            }else if(user_id && user_pwd.includes(user_id)){
                // 사용자 아이디 포함 불가능 (+추가)
                setPwdMsg({
                    msg: '비밀번호는 아이디를 포함할 수 없습니다.',
                    error: true,
                })
            }
            else if(!pwValidation(user_pwd, user_id)) {
                setPwdMsg({
                    msg: '비밀번호는 영어, 숫자, 특수문자(#?!@$%^&*-,.<>~()_=+)가 모두 포함되어야 합니다. 동일한 문자, 연속된 숫자는 네자리 이상 사용할 수 없습니다.',
                    error: true,
                })
            }
            else {
                setPwdMsg({
                    msg: '비밀번호가 일치합니다.',
                    error: false,
                })
            }
        }
    }, [user_pwd,user_pwd_chk,user_id])

    useEffect(() => {
        if(regsuccess) {
            ALERT('회원가입에 성공했습니다. 로그인화면으로 이동합니다.')
            navigate('/', {replace: true})
        }
    }, [regsuccess, navigate])


    useEffect(() => {
        if(setTimeStart) {
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
            interval.current = setInterval(() => {
                time.current -= 1;
                setMin(parseInt(time.current / 60));
                setSec(time.current % 60);
            }, 1000)
        }
    }, [setTimeStart])

    useEffect(() => {
        if(time.current < 0) {
            ALERT("인증번호의 만료시간이 지났습니다. 다시 발급해주세요");
            clearInterval(interval.current);
            dispatch(setTimeValue({
                value: false
            }))

            time.current = 180;
            setMin(parseInt(time.current / 60));
            setSec(time.current % 60);
        }
    }, [sec, dispatch])

    useEffect(() => {
        if(certSuccess) {
            if(certSuccess === 'success') {
                dispatch(setTimeValue({
                    value: false
                }))
                clearInterval(interval.current);
            }            
        }
    }, [certSuccess, dispatch])

    useEffect(() => {
        return () => clearInterval(interval.current);
    }, [])


    const propDatas = {
        register,
        error,
        isBroker,
        pwdMsg,
        isShowDaum,
        pwdRef,
        min,
        sec,
        setTimeStart,
        certSuccess,
        isShowTermsModal,

        onRegister,
        onChange,
        onFileChange,
        onChangeAgree,
        onImgCancle,
        onNavBackClick,  //임의로넣은거 위와동일
        onComplete,
        onCloseDaum,
        onOpenDaum,
        sendCode,
        checkCode,
        onIdCheck,
        setIsShowTermsModal
    }

    return (
        <>
            <RegisterForm {...propDatas}/>
        </>
    );
}

export default RegisterContainer;