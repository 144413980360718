import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseNumber, regAmt } from '../../common';
import Feedback from '../../components/Modal/Feedback';
import { ALERT } from '../../lib/dataWord';
import { feedbackAns, feedbackView, initialize, rowChangeValue } from '../../modules/Modal/feedback';

const FeedbackContainer = (props) => {
    const {
        isShowFeedback,
        setIsShowFeedback,
        onRefreshList,
        modalCallType,  //  LIST, ENTITLE
    } = props;

    const dispatch = useDispatch();
    const {
        feedback_user,
        isAnswer,
        feedback_ans,
    } = useSelector(({ feedback }) => ({
        isAnswer: feedback.isAnswer,
        feedback_user: feedback.feedback_user.map((item) => {
            return ({
                ...item,
                lh_rent_amt: String(item.lh_rent_amt).replace(regAmt, ","),
                lh_support_amt: String(item.lh_support_amt).replace(regAmt, ","),
                lh_win_charge: String(item.lh_win_charge).replace(regAmt, ","),
                lh_add_charge: String(item.lh_add_charge).replace(regAmt, ","),
                req_cnt: String(item.req_cnt).replace(regAmt, ","),
                contract_area: item.contract_area,
                dedicated_area: String(item.dedicated_area).replace(regAmt, ","),
                rent_amt: String(item.rent_amt).replace(regAmt, ","),
                support_amt: String(item.support_amt).replace(regAmt, ","),
                winner_charge: String(item.winner_charge).replace(regAmt, ","),
                add_charge: String(item.add_charge).replace(regAmt, ","),
                guarantee_mon_amt: String(item.guarantee_mon_amt).replace(regAmt, ","),
                unpaid_amt: String(item.unpaid_amt).replace(regAmt, ","),
                create_user_name: String(item.create_user_name).replace(regAmt, ","),
            })
        }),
        feedback_ans: feedback.feedback_ans,
    }));

    const onRowChange = (item, e) => {
        dispatch(
            rowChangeValue({
                id: item.winner_id,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onRowChangeCheck = (item, e) => {
        dispatch(
            rowChangeValue({
                id: item.winner_id,
                key: e.target.name.slice(0, -1),
                value: e.target.value,
            })
        );
    }
    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const onRowChangeNum = (item, e) => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                rowChangeValue({
                    id: item.winner_id,
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    const onSave = (type) => {
        let _feedback_user = [];
        for(let i = 0; i < feedback_user.length; i++) {
            const _user = feedback_user[i];

            if( type !== 'temp_save' ) {
                if( !(_user.contract_yn     === 'Y' || _user.contract_yn        === 'N') ) { ALERT(`'계약가능여부'를 선택하세요.`); return; }
                if( !(_user.house_owner_yn  === 'Y' || _user.house_owner_yn     === 'N') ) { ALERT(`'주택소유여부'를 선택하세요.`); return; }
                if( !(_user.other_rent_yn   === 'Y' || _user.other_rent_yn      === 'N') ) { ALERT(`'타임대여부'를 선택하세요.`); return; }
                if( !(_user.fund_loan_yn    === 'Y' || _user.fund_loan_yn       === 'N') ) { ALERT(`'기금대출여부'를 선택하세요.`); return; }
                if( !(_user.renewal_delay_yn === 'Y' || _user.renewal_delay_yn  === 'N') ) { ALERT(`'갱신유예유무'를 선택하세요.`); return; }
                if( !(_user.lawsuit_yn      === 'Y' || _user.lawsuit_yn         === 'N') ) { ALERT(`'소송'을 선택하세요.`); return; }
                if( !(_user.wallpaper_yn    === 'Y' || _user.wallpaper_yn       === 'N') ) { ALERT(`'도배장판지원가능여부'를 선택하세요.`); return; }
                if( !(_user.duplication_yn  === 'Y' || _user.duplication_yn     === 'N') ) { ALERT(`'입주주택중복여부'를 선택하세요.`); return; }
            }

            _feedback_user.push({
                winner_id: _user.winner_id,
                house_member: _user.house_member,
                house_owner_yn: _user.house_owner_yn,
                fund_loan_yn: _user.fund_loan_yn,
                rent_surcharge: _user.rent_surcharge,
                renewal_delay_yn: _user.renewal_delay_yn,
                duplication_yn: _user.duplication_yn,
                cust_type: _user.dup_cust_type,
                cust_name: _user.dup_cust_name,
                cust_phone: _user.dup_cust_phone,
                lh_rent_amt: !_user.lh_rent_amt ? "0" : String(_user.lh_rent_amt).replace(/,/g, ""),
                lh_support_amt: !_user.lh_support_amt ? "0" : String(_user.lh_support_amt).replace(/,/g, ""),
                lh_win_charge: !_user.lh_win_charge ? "0" : String(_user.lh_win_charge).replace(/,/g, ""),
                lh_add_charge: !_user.lh_add_charge ? "0" : String(_user.lh_add_charge).replace(/,/g, ""),
                lh_guarantee_mon_amt: !_user.lh_guarantee_mon_amt ? "0" : String(_user.lh_guarantee_mon_amt).replace(/,/g, ""),
                cont_end_date: _user.cont_end_date,
                req_cnt: !_user.req_cnt ? "0" : String(_user.req_cnt).replace(/,/g, ""),
                current_cont_end_date: _user.current_cont_end_date,
                owner_name: !_user.owner_name? '' : _user.owner_name,
                owner_tel: !_user.owner_tel ? '' : _user.owner_tel,
                contract_area: !_user.contract_area ? "0" : String(_user.contract_area).replace(/,/g, ""),
                dedicated_area: !_user.dedicated_area ? "0" : String(_user.dedicated_area).replace(/,/g, ""),
                rent_amt: !_user.rent_amt ? "0" : String(_user.rent_amt).replace(/,/g, ""),
                support_amt: !_user.support_amt ? "0" : String(_user.support_amt).replace(/,/g, ""),
                winner_charge: !_user.winner_charge ? "0" : String(_user.winner_charge).replace(/,/g, ""),
                add_charge: !_user.add_charge ? "0" : String(_user.add_charge).replace(/,/g, ""),
                guarantee_mon_amt: !_user.guarantee_mon_amt ? "0" : String(_user.guarantee_mon_amt).replace(/,/g, ""),
                wallpaper_yn: _user.wallpaper_yn,
                lawsuit_yn: _user.lawsuit_yn,
                contract_yn: !_user.contract_yn? '' : _user.contract_yn,
                other_rent_yn: !_user.other_rent_yn? '' : _user.other_rent_yn,
                unpaid_amt: !_user.unpaid_amt ? "0" : String(_user.unpaid_amt).replace(/,/g, ""),
                bank_name: _user.bank_name,
                account_no: _user.account_no,
                account_owner: _user.account_owner,
                remark: _user.remark,
            })
        }

        const confirmMsg = (type === 'temp_save') ? "작성한 피드백 내용을 임시저장 하시겠습니까?" : "피드백 요청을 응답하시겠습니까?";
        if(!window.confirm(confirmMsg)) {
            return;
        }

        dispatch(
            feedbackAns({
                doc_id: isShowFeedback,
                feedback_user: _feedback_user,
                save_type: type,
            })
        )
    }
    const onClose = () => {
        setIsShowFeedback(null);
    }

    useEffect(() => {
        if(isAnswer === 'done') {
            onRefreshList('feedback');
            setIsShowFeedback(null);
        }
    }, [isAnswer, onRefreshList, setIsShowFeedback])

    useEffect(() => {
        if(isShowFeedback) {
            dispatch(feedbackView(isShowFeedback));
        }
    }, [dispatch, isShowFeedback])

    useEffect(() => {
        
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        feedback_user,
        modalCallType,
        feedback_ans,

        onRowChange,
        onRowChangeCheck,
        onRowChangeNum,
        onSave,
        onClose,
    }
    return (
        <>
            <Feedback {...propDatas}/>
        </>
    );
}

export default FeedbackContainer;