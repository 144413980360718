import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ImplyAuditWork from '../components/ImplyAuditWork';
import { ALERT } from '../lib/dataWord';
import { changeValue, implyApprDtlList, implyApprove, initialize } from '../modules/Imply';

const ImplyAuditWorkContainer = () => {
    const {
        implyApprove_implyList,
        implyApprove_approvalList,
        implyApprove_memo,
        implyApproveSuccess
    } = useSelector(({
        Imply
    }) => ({
        implyApprove_implyList: Imply.implyApprove_implyList,
        implyApprove_approvalList: Imply.implyApprove_approvalList,
        implyApprove_memo: Imply.implyApprove_memo,
        implyApproveSuccess: Imply.implyApproveSuccess,
    }))
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isModal, setIsModal] = useState(false);
    const [approveType, setApproveType] = useState(null);
    const [isAuth, setIsAuth] = useState(false);

    const {state: {id, status}} = location;

    //----------------------------------------------------------------------
    // 결재 기능

    // 결재처리
    const onApprove = () => {
        // 승인
        if(approveType === '1') {
            if(window.confirm(`승인하시겠습니까?`)) {
                dispatch(implyApprove({
                    app_id: id,
                    app_status: 'Y',
                    app_opinion: implyApprove_memo
                }))
            }
        }
        // 반려
        else if(approveType === '2') {
            if(implyApprove_memo.length < 1) {
                ALERT("사유를 입력하세요")
            }
            else {
                if(window.confirm(`반려 하시겠습니까?`)) {
                    dispatch(implyApprove({
                        app_id: id,
                        app_status: 'N',
                        app_opinion: implyApprove_memo
                    }))
                }
            }
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 모달

    // 모달 열기
    const openMadal = (type) => {
        setIsModal(true);
        setApproveType(type);
    }

    // 모달 닫기
    const closeModal = () => {
        setIsModal(false);
        setApproveType(null);
    }

    // 메모 값 변경
    const onChange = (e) => {
        const {target: {name, value}} = e;
        dispatch(changeValue({
            filed: null,
            key: name,
            value
        }))
    }
    //----------------------------------------------------------------------

    useEffect(() => {
        if(id && dispatch) {
            dispatch(implyApprDtlList({
                appr_id: id,
            }))
        }
    }, [id, dispatch])

    useEffect(() => {
        if(implyApproveSuccess && navigate) {
            if(implyApproveSuccess === "Y") {
                ALERT("결재 했습니다.")
                closeModal();
                navigate(-1);
            }
        }
    }, [implyApproveSuccess, navigate])

    useLayoutEffect(() => {
        if(status) {
            if(status === '반려' || status === '결재완료') {
                setIsAuth(false);
            }
            else {
                setIsAuth(true);
            }
        }
    }, [status])
    useEffect(() => {
        return () => dispatch(initialize());
    }, [dispatch])

    const propDatas = {
        implyApprove_implyList,
        implyApprove_approvalList,
        isModal,
        approveType,
        implyApprove_memo,
        isAuth,

        openMadal,
        closeModal,
        onApprove,
        onChange,
    }

    return (
        <>
            <ImplyAuditWork {...propDatas}/>
        </>
    );
}

export default ImplyAuditWorkContainer;