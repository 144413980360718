import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import DocSortContainer from '../containers/DocSortContainer';

const DocSortPage = () => {
    return (
        <>
            <HeaderContainer />
            <DocSortContainer/>
        </>
    );
}

export default DocSortPage;