import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'feedback/INITIALIZE';
const CHANGE_VALUE = 'feedback/CHANGE_VALUE';
const [FEEDBACK_VIEW, FEEDBACK_VIEW_SUCCESS, FEEDBACK_VIEW_FAILURE] = createRequestActionTypes('feedback/FEEDBACK_VIEW');
const [FEEDBACK_ANS, FEEDBACK_ANS_SUCCESS, FEEDBACK_ANS_FAILURE] = createRequestActionTypes('feedback/FEEDBACK_ANS');
const ROW_CHANGE_VALUE = 'feedback/ROW_CHANGE_VALUE';

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const feedbackView = createAction(FEEDBACK_VIEW);
export const feedbackAns = createAction(FEEDBACK_ANS);
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({id, key, value}) => ({id, key, value}));

const feedbackViewSaga = createRequestSaga(FEEDBACK_VIEW, entitleAPI.feedback_view);
const feedbackAnsSaga = createRequestSaga(FEEDBACK_ANS, entitleAPI.feedback_ans);

export function* feedbackSaga() {
    yield takeLatest(FEEDBACK_VIEW, feedbackViewSaga);
    yield takeLatest(FEEDBACK_ANS, feedbackAnsSaga);
}

const initialState = {
    feedback_user: [],
    feedback_ans: '',
    
    feedback: null,
    error: null,
    isAnswer: '',
};

const feedback = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [FEEDBACK_VIEW_SUCCESS]: (state, { payload }) => {
            let _feedback_user = [];
            payload.forEach(item => {
                _feedback_user.push({
                    winner_id: item.winner_id,
                    to_lh: item.to_lh,
                    feedback_date: item.feedback_date,
                    winner_type_name: item.winner_type_name,
                    contract_type_name: item.contract_type_name,
                    cust_name: item.cust_name,
                    cust_birth: item.cust_birth,
                    sigungu: item.sigungu,
                    house_member: item.house_member? item.house_member : item.cust_name, // 세대원 값이 없으면 성명을 디폴트로 설정함
                    house_addr: item.house_addr,
                    cust_addr: item.cust_addr,
                    house_owner_yn: (item.house_owner_yn === 'Y' || item.house_owner_yn === 'N') ? item.house_owner_yn : 'N',
                    fund_loan_yn: (item.fund_loan_yn === 'Y' || item.fund_loan_yn === 'N') ? item.fund_loan_yn : 'N',
                    rent_surcharge: item.rent_surcharge,
                    renewal_delay_yn: (item.renewal_delay_yn === 'Y' || item.renewal_delay_yn === 'N') ? item.renewal_delay_yn : 'N',
                    duplication_yn: (item.duplication_yn === 'Y' || item.duplication_yn === 'N') ? item.duplication_yn : 'N',
                    dup_cust_type: item.dup_cust_type,
                    dup_cust_name: item.dup_cust_name,
                    dup_cust_phone: item.dup_cust_phone,
                    lh_support_amt: item.lh_support_amt,
                    lh_win_charge: item.lh_win_charge,
                    lh_add_charge: item.lh_add_charge,
                    cont_end_date: item.cont_end_date,
                    req_cnt: item.req_cnt,
                    current_cont_end_date: item.current_cont_end_date,
                    owner_name: item.owner_name,
                    owner_tel: item.owner_tel,
                    contract_area: item.contract_area,
                    dedicated_area: item.dedicated_area,
                    rent_amt: item.rent_amt,
                    support_amt: item.support_amt,
                    winner_charge: item.winner_charge,
                    add_charge: item.add_charge,
                    guarantee_mon_amt: item.guarantee_mon_amt,
                    wallpaper_yn: (item.wallpaper_yn === 'Y' || item.wallpaper_yn === 'N') ? item.wallpaper_yn : 'N',
                    lawsuit_yn: (item.lawsuit_yn === 'Y' || item.lawsuit_yn === 'N') ? item.lawsuit_yn : 'N',
                    unpaid_amt: item.unpaid_amt,
                    bank_name: item.bank_name,
                    account_no: item.account_no,
                    account_owner: item.account_owner,
                    remark: item.remark,
                    lh_rent_amt: item.lh_rent_amt,
                    lh_guarantee_mon_amt: item.lh_guarantee_mon_amt,
                    contract_yn: item.contract_yn,
                    other_rent_yn: (item.other_rent_yn === 'Y' || item.other_rent_yn === 'N') ? item.other_rent_yn : 'N',
                    feedback_ans: item.feedback_ans,
                    create_user: item.create_user,
                    create_user_name: item.create_user_name,
                    update_user_name: item.update_user_name,
                })
            });
            const _feedback_ans = _feedback_user[0].feedback_ans;

            return ({
                ...state,
                feedback_user: _feedback_user,
                feedback_ans: _feedback_ans,
            })
        },
        [FEEDBACK_VIEW_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { id, key, value } }) => {
            const _feedback_user = state.feedback_user.map((item) =>
                item.winner_id === id ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                feedback_user: _feedback_user,
            };
        },
        [FEEDBACK_ANS_SUCCESS]: (state, { payload }) => {
            ALERT("피드백 응답(저장)이 완료되었습니다.");
            return ({
                ...state,
                error: null,
                isAnswer: 'done'
            })
        },
        [FEEDBACK_ANS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default feedback;