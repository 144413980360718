import React from 'react';
import DobaeUserListContainer from '../containers/DobaeUserListContainer';
import HeaderContainer from '../containers/HeaderContainer';

const DobaeUserListPage = () => {
    return (
        <>
            <HeaderContainer />
            <DobaeUserListContainer />
        </>
    );
}

export default DobaeUserListPage;