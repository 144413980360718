import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';

const INITIALIZE = 'fieldChk/INITIALIZE';
const CHANGE_VALUE = 'fieldChk/CHANGE_VALUE';
const CHANGE_FILES = 'fieldChk/CHANGE_FILES';
const DOC_SELECT_IMG = 'fieldChk/DOC_SELECT_IMG';
const DOC_DELETE_IMG = 'fieldChk/DOC_DELETE_IMG';
const [SAVE_FILES, SAVE_FILES_SUCCESS, SAVE_FILES_FAILURE] = createRequestActionTypes('fieldChk/SAVE_FILES');
const [SAVE_REMARK, SAVE_REMARK_SUCCESS, SAVE_REMARK_FAILURE] = createRequestActionTypes('fieldChk/SAVE_REMARK');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const changeFiles = createAction(CHANGE_FILES, ({idx, fileReader, fileName, fileExtension, fileSize}) => ({idx, fileReader, fileName, fileExtension, fileSize}));
export const doc_selectImg = createAction(DOC_SELECT_IMG, (value) => value);
export const doc_deleteImg = createAction(DOC_DELETE_IMG, (value) => value);
export const saveFiles = createAction(SAVE_FILES, (body) => (body));
export const saveRemark = createAction(SAVE_REMARK, (body) => (body));

const saveFilesSaga = createRequestSaga(SAVE_FILES, entitleAPI.enwrite4);
const saveRemarkSaga = createRequestSaga(SAVE_REMARK, entitleAPI.fieldchk);

export function* fieldChkSaga() {
    yield takeLatest(SAVE_FILES, saveFilesSaga);
    yield takeLatest(SAVE_REMARK, saveRemarkSaga);
}

const initialState = {
    doc_id: '',

    fileChooser: "",
    selectImg: null,
    docLastIdx: 0,
    docImages: [],
    remark: '',

    fieldChk: null,
    error: null
};

const fieldChk = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [CHANGE_FILES]: (state, { payload: {idx, fileReader, fileName, fileExtension, fileSize}}) => {
            state.docImages.push({
                idx: idx,
                file_name: fileName,
                file_extension: fileExtension,
                file_data: fileReader.result,
                file_size: fileSize,
                isNewRow: true,
            });
            return {
                ...state,
                docLastIdx: idx,
            }
        },
        [DOC_SELECT_IMG]: (state, { payload }) => {
            return {
                ...state,
                selectImg: payload,
            };
        },
        [DOC_DELETE_IMG]: (state, { payload }) => {
            const _docImages = state.docImages.filter((item) => item.idx !== payload);
            return {
                ...state,
                docImages: _docImages,
            };
        },
        [SAVE_FILES_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FILES_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
        [SAVE_REMARK_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_REMARK_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },
    },
    initialState,
);

export default fieldChk;