import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';

const INITIALIZE = 'uploadList/INITIALIZE';
const CHANGE_VALUE = 'uploadList/CHANGE_VALUE';
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('uploadList/GET_COMBO');
const [GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE] = createRequestActionTypes('uploadList/GET_LIST');
const SET_PAGING = 'uploadList/SET_PAGING';

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getCombo = createAction(GET_COMBO);
export const getList = createAction(GET_LIST, (body) => (body));
export const setPaging = createAction(SET_PAGING, (payload) => payload);

const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_multi_search);
const getListSaga = createRequestSaga(GET_LIST, adminAPI.file_search);

export function* uploadListSaga() {
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(GET_LIST, getListSaga);
}

const initialState = {
    maxPageCount: 30, // 한페이지에 30개씩 출력하도록. (고정값)
    pageCount: null,
    max: 3, // 한번에 페이지 몇개씩 출력할지 (3은 테스트)
    step: 1,
    currentPage: null,

    cboDocType: [],

    addr: '',
    user_name: '',
    file_type: '',

    fileList: [],

    uploadList: null,
    error: null
};

const uploadList = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [GET_COMBO_SUCCESS]: (state, { payload: {DOC_TYPE} }) => {
            let _DOC_TYPE = [];
            DOC_TYPE.forEach(item => {
                _DOC_TYPE.push({
                    sub_code: item.sub_code,
                    code_name: item.code_name,
                    checked: true,
                })
            });
            return {
                ...state,
                cboDocType: _DOC_TYPE,
            }
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [GET_LIST_SUCCESS]: (state, { payload }) => {
            return {
                ...state,
                fileList: payload,
                pageCount: payload.length > 0 ? Math.ceil(payload[0].tot_count / state.maxPageCount) : null,
                currentPage: state.currentPage ? state.currentPage : 1,
            }
        },
        [GET_LIST_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                fileList: [],
                pageCount: null,
                max: 3,
                step: 1,
                currentPage: null,
                error
            }
        },

        [SET_PAGING]: (state, {payload: {key, value}}) => {
            if(key==="currentPage") {
                return ({
                    ...state,
                    [key]: value
                })
            }
            else if (key === "step") {
                if(value === "next") {
                    return ({
                        ...state,
                        [key]: state.step + 1,
                    })
                }
                else if(value === "prev") {
                    return ({
                        ...state,
                        [key]: state.step - 1
                    })
                }
                else {
                    return ({
                        ...state,
                        [key]: Math.ceil(value / state.max)
                    })
                }
            }
        },
    },
    initialState,
);

export default uploadList;