import React from 'react';
import styled from 'styled-components';

const EnDocUploadBlock = styled.div`
    .popupModal > div:nth-child(2){
        width: 50% !important;
        padding: 30px 40px 30px;
        white-space: nowrap;
    }

    // .popupWrap > h4 {
    //     font-size: 26px;
    //     color: #545454;
    //     text-align: center;
    //     margin-bottom: 26px;
    // }
    
    .divScroll {
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #BDBDBD;
        border-radius: 10px;
        padding: 0 10px;
        box-sizing: border-box;
    }
    
    .filesBox {
        width: 100%;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: space-between;
    }
    .filesBox > div {
        position: relative;
        padding-bottom: 30px;
    }
    .filesBox .req-btn{
        position: absolute;
        right: 0px;
        top: 30px;
        color: #0B999C;
        padding: 1px 10px;
        margin-left: 4px;
        border: 1px solid #0B999C;
        background-color: white;
        border-radius: 4px;
        cursor: pointer;
        height: 28px;
        font-weight: 600;
    }

    .filesBox .req-btn:hover,
    .lblInputFiles:hover{
        color: white;
        background-color: #0B999C;
    }
    .filesBox:not(:last-child){
        border-bottom: 1px solid #BDBDBD;
    }

    .filesBox .filesList {
        display: flex;
        flex-direction: column;
        flex-wrap: no-wrap;
        align-items: flex-end
    }
    .filesBox .filesList > div {
        color: #545454;
        margin-bottom: 5px;
        display: flex;
        // justify-content: space-between;
        justify-content: flex-end;
        gap: 5px;
        width: 100%;
    }
    .filesBox .filesList > div .del-btn{
        margin-left: 5px;
    }
    .filesBox .filesList .red{
        border-color: red;
        color: red;
    }
    .filesBox .filesList .red:hover{
        background-color: red;
        color: white;
    }

    .filesBox .filesList .green{
        border-color: green;
        color: green;
    }
    .filesBox .filesList .green:hover{
        background-color: white;
        color: green;
        cursor: default;
    }
    .filesBox .filesList .gray:hover{
        background-color: white;
        color: #808080;
        cursor: default;
    }
    .filesBox .filesList .red:hover{
        color: white;
    }
    
    .filesBox .filesList .filesDate {
        display: flex;
        gap: 5px;
        font-size: 13px;
        align-items: center;
        color: #afafaf;
    }
    
    .lblInputFiles {
        color: #0B999C;
        padding: 1px 10px;
        margin-left: 4px;
        border: 1px solid #0B999C;
        border-radius: 4px;
        cursor: pointer;
    }

    .ctrlFile {
        display: none;
    }

    .divButtons {
        margin-top: 20px;
    }
    .divButtons button{
        width: 33%;
        color:#ffffff;
        padding:8px 0; 
        border-radius: 3px; 
    }
    .divButtons button:nth-child(1){
        font-weight: 500;
        background-color:#1EBF86 !important; 
    }
    .divButtons button:nth-child(2){
        background: #0B999C !important;
    }
    .divButtons button:nth-child(3){
        background-color: #808080 !important;
    }

    .dis-btn{
        opacity: 0.5;
    }

    .addrInfo {
        padding: 10px 0 15px;
    }
    .addrInfo > div {
        display: flex;
    }
    .addrInfo > div > h4 {
        width: 120px;
    }
    .addrInfo .reqAddr {
        margin-bottom: 10px;
        align-items: center;
    }
    .addrInfo .reqAddr > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .addrInfo .resultAddr > div {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .addrInfo .resultAddr div .addrList {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;

const EnDocUpload = (props) => {
    const {
        doc_type,
        fileList,
        reqAddr,
        resultAddrs,
        buildingType,

        cantApiReq,
        
        onReqAddr,
        onAddrChecked,
        onCloseClick,
        onDocAutoReceiveClick,
        onFileChange,
        onRemoveFile,
        onSave,
        onRequestApi,
    } = props;
    
    return (
        <EnDocUploadBlock>
            <div className='popupModal'>
               <div />
               <div>
                    <h2>참조 파일 업로드</h2>
                    <div className='divScroll' style={{marginBottom:"20px"}}>
                        <div className='addrInfo'>
                            <div className='reqAddr'>
                                <h4>신청주소 : </h4>
                                <div>
                                    <label>{reqAddr}</label>
                                    <button className='save-btn' onClick={onReqAddr}>주소요청</button>
                                </div>
                            </div>
                            <div className='resultAddr'>
                                <h4>건물 구분 : </h4>
                                <div>{!buildingType ? "" : buildingType === "2" ? "[ 건물 ]" : "[ 집합건물 ]"}</div>
                            </div>
                            <div className='resultAddr'>
                                <h4>정제건물주소 : </h4>
                                <div>
                                    {resultAddrs.map((item) => (
                                        item.building_type !== '1' &&
                                        <div className='addrList' key={item.id}>
                                            <label>[ {item.real_estate_no} ] {item.real_estate_address}</label>
                                            <div>
                                                <input type="checkbox" name="rstAddr" id={"rstAddr" + item.id} checked={item.is_use_yn === "Y"} onChange={onAddrChecked.bind(this, item)} />
                                                <label htmlFor={"rstAddr" + item.id}>선택</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='resultAddr'>
                                <h4>정제토지주소 : </h4>
                                <div>
                                    {resultAddrs.map((item) => (
                                        item.building_type === '1' &&
                                        <div className='addrList' key={item.id}>
                                            <label>[ {item.real_estate_no} ] {item.real_estate_address}</label>
                                            <div>
                                                <input type="checkbox" name="rstAddr" id={"rstAddr" + item.id} checked={item.is_use_yn === "Y"} onChange={onAddrChecked.bind(this, item)} />
                                                <label htmlFor={"rstAddr" + item.id}>선택</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='divScroll'>
                        {doc_type.map((doc, docIdx) => (
                            <div key={docIdx} className='filesBox'>
                                <div>
                                    {doc.name}
                                    <label className='lblInputFiles' htmlFor={'file'+docIdx}>파일 추가</label>
                                    <input type="file" name="file" id={"file"+docIdx} className='ctrlFile' onChange={onFileChange.bind(this, doc)} accept=".jpg,.jpeg,.png,.pdf" multiple/>
                                    {
                                        doc.code !== "DOC018" &&   //  검토서 기타
                                        <button onClick={() => onRequestApi(doc)} disabled={cantApiReq} className={"req-btn" + (cantApiReq ? " dis-btn" : "")}>요청</button>
                                    }
                                </div>

                                <div className='filesList'>
                                    {fileList.map((files, idx) => (
                                        doc.code === files.file_type && files.use_flag === "Y" &&
                                        <div key={idx}>
                                            {files.api_yn === "Y"? 
                                            <span className='filesDate'>
                                                {files.api_req_date && files.api_rec_date? <span>수신일 {files.api_rec_date.split(" ")[0]}</span> : null}
                                                {files.api_req_date && !files.api_rec_date? <span>요청일 {files.api_req_date.split(" ")[0]}</span> : null}
                                            </span> : null}
                                            <p>{files.file_name}</p>
                                            <button onClick={() => onRemoveFile(files)} className={files.api_yn === 'N'? 'del-btn red' : files.api_req_date && files.api_rec_date? 'green del-btn' : 'gray del-btn'}>
                                                {files.api_yn === "N"? '삭제' : 
                                                    files.api_req_date && files.api_rec_date? '요청완료' : '요청중'}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='divButtons'>
                        <button onClick={onDocAutoReceiveClick} disabled={cantApiReq} className={"save-btn" + (cantApiReq ? " dis-btn" : "")}>일괄 요청</button>
                        <button onClick={onSave}>저장</button>
                        <button onClick={onCloseClick}>취소</button>
                    </div>
               </div>
            </div>
        </EnDocUploadBlock>
    );
}

export default EnDocUpload;