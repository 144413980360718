import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { regAmt } from '../common';
import ChargeFee from '../components/ChargeFee';
import { changeValue, getList, getSummary, initialize, saveConfirm } from '../modules/chargeFee';

const ChargeFeeContainer = () => {
    const [isPostback, setIsPostback] = useState(true); //  최초 조회
    const [summaryToggle, setSummaryToggle] = useState(true);

    const dispatch = useDispatch();
    const {
        from_date,
        to_date,
        expense_flag,
        // page,

        summary,
        list,

        sumTotCnt,
        sumTotEnAmt,
        sumTotContAmt,
        sumTotTrip,
        sumTotSum,
        sumTotVat,
        sumTotTot,
        listTotWinnerType,
        listTotEnAmt,
        listTotContAmt,
        listTotTrip,
        listTotSum,
        listTotVat,
        listTotTot,

        // //  페이징
        // pageCount,
        // max,
        // step,
        // currentPage,
    } = useSelector(({chargeFee}) => ({
        from_date: chargeFee.from_date,
        to_date: chargeFee.to_date,
        expense_flag: chargeFee.expense_flag,
        // page: chargeFee.page,

        summary: chargeFee.summary.map((item) => {
            return ({
                ...item,
                exp_count: String(item.exp_count).replace(regAmt, ","),
                entitle_amt: String(item.entitle_amt).replace(regAmt, ","),
                contract_amt: String(item.contract_amt).replace(regAmt, ","),
                trip_expense: String(item.trip_expense).replace(regAmt, ","),
                sum_amt: String(item.sum_amt).replace(regAmt, ","),
                vat_amt: String(item.vat_amt).replace(regAmt, ","),
                tot_amt: String(item.tot_amt).replace(regAmt, ","),
            })
        }),
        list: chargeFee.list.map((item) => {
            return ({
                ...item,
                entitle_amt: String(item.entitle_amt).replace(regAmt, ","),
                contract_amt: String(item.contract_amt).replace(regAmt, ","),
                trip_expense: String(item.trip_expense).replace(regAmt, ","),
                sum_amt: String(item.sum_amt).replace(regAmt, ","),
                vat_amt: String(item.vat_amt).replace(regAmt, ","),
                tot_amt: String(item.tot_amt).replace(regAmt, ","),
            })
        }),

        sumTotCnt: !chargeFee.sumTotCnt ? '-' : String(chargeFee.sumTotCnt).replace(regAmt, ","),
        sumTotEnAmt: !chargeFee.sumTotEnAmt ? '-' : String(chargeFee.sumTotEnAmt).replace(regAmt, ","),
        sumTotContAmt: !chargeFee.sumTotContAmt ? '-' : String(chargeFee.sumTotContAmt).replace(regAmt, ","),
        sumTotTrip: !chargeFee.sumTotTrip ? '-' : String(chargeFee.sumTotTrip).replace(regAmt, ","),
        sumTotSum: !chargeFee.sumTotSum ? '-' : String(chargeFee.sumTotSum).replace(regAmt, ","),
        sumTotVat: !chargeFee.sumTotVat ? '-' : String(chargeFee.sumTotVat).replace(regAmt, ","),
        sumTotTot: !chargeFee.sumTotTot ? '-' : String(chargeFee.sumTotTot).replace(regAmt, ","),
        listTotWinnerType: !chargeFee.listTotWinnerType ? '-' : String(chargeFee.listTotWinnerType).replace(regAmt, ","),
        listTotEnAmt: !chargeFee.listTotEnAmt ? '-' : String(chargeFee.listTotEnAmt).replace(regAmt, ","),
        listTotContAmt: !chargeFee.listTotContAmt ? '-' : String(chargeFee.listTotContAmt).replace(regAmt, ","),
        listTotTrip: !chargeFee.listTotTrip ? '-' : String(chargeFee.listTotTrip).replace(regAmt, ","),
        listTotSum: !chargeFee.listTotSum ? '-' : String(chargeFee.listTotSum).replace(regAmt, ","),
        listTotVat: !chargeFee.listTotVat ? '-' : String(chargeFee.listTotVat).replace(regAmt, ","),
        listTotTot: !chargeFee.listTotTot ? '-' : String(chargeFee.listTotTot).replace(regAmt, ","),

        // //  페이징
        // pageCount: chargeFee.pageCount,
        // max: chargeFee.max,
        // step: chargeFee.step,
        // currentPage: chargeFee.currentPage,
    }))

    const onChange = (e) => {
        dispatch(changeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const onSearch = useCallback(() => {
        dispatch(getSummary({
            from_date: from_date,
            to_date: to_date,
            expense_flag: expense_flag,
        }))
        dispatch(getList({
            from_date: from_date,
            to_date: to_date,
            expense_flag: expense_flag,
        }))
    }, [dispatch, from_date, to_date, expense_flag])
    
    const onSubmit = (e) => {
        e.preventDefault();
        
        onSearch();
    }

    const onApplyClick = () => {
        if(window.confirm("선택한 검색 조건에 일치하는 항목 전체의 수수료를 청구합니다.")) {
            dispatch(saveConfirm({
                from_date: from_date,
                to_date: to_date,
                expense_flag: expense_flag,
            }))
        }
    }

    const onSummaryToggle = () => {
        setSummaryToggle(!summaryToggle);
    }

    // // 페이징   --------------------------------------------
    // const onClickNum = (num) => {
    //     if(currentPage !== num) {
    //         dispatch(changeValue({key: "page", value: num}));
    //         dispatch(changeValue({key: "currentPage", value: num}));
            
    //         onSearch();
    //     }
    // }
    // const onClickNext = () => {
    //     if(Math.ceil(pageCount / max) >= step + 1 ) {
    //         dispatch(setPaging({
    //             key: "step",
    //             value: "next",
    //         }))
    //     }
    // }
    // const onClickPrev = () => {
    //     if(step -1 > 0) {
    //         dispatch(setPaging({
    //             key: "step",
    //             value: "prev",
    //         }))
    //     }
    // }
    // //  --------------------------------------------    페이징

    useEffect(() => {
        if(isPostback) {
            setIsPostback(false);
            onSearch();
        }
    }, [isPostback, onSearch])

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        //  검색조건
        from_date,
        to_date,
        expense_flag,

        summaryToggle,

        //  List
        summary,
        list,

        //  합계
        sumTotCnt,
        sumTotEnAmt,
        sumTotContAmt,
        sumTotTrip,
        sumTotSum,
        sumTotVat,
        sumTotTot,
        listTotWinnerType,
        listTotEnAmt,
        listTotContAmt,
        listTotTrip,
        listTotSum,
        listTotVat,
        listTotTot,

        // //  페이징
        // pageCount,
        // max,
        // step,
        // currentPage,

        onSummaryToggle,
        onChange,
        onSubmit,
        onApplyClick,
        
        // onClickNum,
        // onClickNext,
        // onClickPrev,
    }
    return (
        <>
            <ChargeFee {...propDatas}/>
        </>
    );
}

export default ChargeFeeContainer;