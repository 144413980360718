import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ImplyWorkContainer from '../containers/ImplyWorkContainer';

const ImplyWorkpage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyWorkContainer/>
        </>
    );
}

export default ImplyWorkpage;