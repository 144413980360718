import React, { useEffect, useState } from 'react';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import WinnerRegisterWork from '../components/WinnerRegisterWork';
import { initialize as initHeader } from '../modules/header';
import { addMember, changeMember, changeSearch, changeValue, delMember, detailSearch, getCombo, initialize,initInput, reqApprove, reqDelete, setIndex, setPaging, setSearchValue, setValue, winnerDelete, winnerRequest, winnerWrite } from '../modules/WinnerWork';
import dataURL from '../lib/dataURL';
import { qr } from '../common';
import { ALERT } from '../lib/dataWord';

const WinnerRegisterWorkContainer = () => {
    const { 
        winnerList, 
        input, 
        cboApply, 
        search, 
        approveSuccess,
        maxPageCount,
        pageCount, 
        max,
        step, 
        currentPage, 
        reqDeleteSuccess,
     } = useSelector(({WinnerWork}) => ({
        winnerList: WinnerWork.winnerList,
        input: WinnerWork.input,
        cboApply: WinnerWork.cboApply,
        search: WinnerWork.search,
        approveSuccess: WinnerWork.approveSuccess,
        
        maxPageCount: WinnerWork.maxPageCount,
        pageCount: WinnerWork.pageCount, 
        max: WinnerWork.max,
        step: WinnerWork.step, 
        currentPage: WinnerWork.currentPage, 
        reqDeleteSuccess: WinnerWork.reqDeleteSuccess, 
    }))
    const location = useLocation();
    const { state: {func, id, status, type}} = location;
    const [isShow, setIsShow] = useState(false);
    const [isModi, setIsModi] = useState(false);
    const [checkedArr, setCheckedArr] = useState([]);
    const [selectedType, setSelectedType] = useState([]);
    const [update, setUpdate] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [isRequest, setIsRequest] = useState(false)
    const [changeUrl, setChangeUrl] = useState(false);

    // 필수 입력값
         
    const [value1, setvalue1] = useState(false);    //신청유형
    const [value2, setvalue2] = useState(false);    //접수일자
    const [value3, setvalue3] = useState(false);    //신청지역
    const [value4, setvalue4] = useState(false);    //성명
    const [value5, setvalue5] = useState(false);    //생년월일
    const [value6, setvalue6] = useState(false);    //주민번호
    const [value7, setvalue7] = useState(false);    //핸드폰번호
    const [value8, setvalue8] = useState(false);    //전화번호
    const [value9, setvalue9] = useState(false);    //우편번호
    const [value10, setvalue10] = useState(false);  //주소
    const [value11, setvalue11] = useState(false);  //상세주소
    const [value12, setvalue12] = useState(false);  //신청인유형
    const [value13, setvalue13] = useState(false);  //신청순위
    const [value14, setvalue14] = useState(false);  //상세자격요건
    const [value15, setvalue15] = useState(false);  //수급자자격
    const [value16, setvalue16] = useState(false);  //최종결과
    const [value17, setvalue17] = useState(false);  //지원기간시작일
    const [value18, setvalue18] = useState(false);  //지원기간종료일
    const [value19, setvalue19] = useState(false);  //유주택
    const [value20, setvalue20] = useState(false);  //기금중복
    const [value21, setvalue21] = useState(false);  //타임대
    const [value22, setvalue22] = useState(false);  //타임대체크
    const [value23, setvalue23] = useState(false);  //비고(대외용)
    const [value24, setvalue24] = useState(false);  //메모(대내용)
    const [value25, setvalue25] = useState(false);  //세대원수
    
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const checkInput = () => {
        let valid = true;
        let msg = '';

        // 여기서 '없다'의 기준은 예시 엑셀엔 없지만 db엔 저장 공간이 있는경우임.
        /*
        1. 엑셀에서 받아올땐 빈값으로 저장 
        2. 따라서 저장후 조회할땐 해당 값을 빈 채로 받음
        3. 예외) 지원가능기간은 엑셀에선 from ~ to 로 받는데 to 에 해당하는 날짜만 전달. 시작일에 대한 값은 저장하고 있지 않지만 백엔드에 공간이 존재함.
        */
        if(input.type === ''){ valid = false; setvalue1(true);} //신청유형
        else {setvalue1(false)}

        // if(input.rec_date === '') {valid = false; setvalue2(true);} //접수일자(신청일)
        // else {setvalue2(false)}

        // if(input.name === '') {valid = false; setvalue3(true);} //신청지역 -> 없음.
        // else {setvalue3(false)}

        if(input.name === '') {valid = false; setvalue4(true);} //성명
        else {setvalue4(false)}

        // if(input.birth === '') {valid = false; setvalue5(true);} //생년월일
        // else {setvalue5(false)}

        if(input.winner_no === '' || input.winner_no.length < 6) {
            valid = false; 
            setvalue6(true); 
            msg += '주민등록번호는 최소 6자리(생년월일)를 입력해야 합니다.\n'
        } //주민번호
        else {setvalue6(false)}

        if(input.phone === '' || input.phone.length < 11) {
            valid = false; 
            setvalue7(true); 
            msg += '핸드폰번호는 11자리 모두 입력해야 합니다.\n'
        } //핸드폰번호
        else {setvalue7(false)}

        // if(input.name === '') {valid = false; setvalue8(true);} //전화번호 -> 없음.
        // else {setvalue8(false)}

        // if(input.name === '') {valid = false; setvalue9(true);} //우편번호 -> 없음.
        // else {setvalue9(false)}

        if(input.addr === '') {valid = false; setvalue10(true);} //주소
        else {setvalue10(false)}

        // if(input.addr_dtl === '') {valid = false; setvalue11(true);} //상세주소
        // else {setvalue11(false)}

        if(input.win_type1 === '') {valid = false; setvalue12(true);} //신청유형(공급유형)
        else {setvalue12(false)}

        // if(input.win_rank === '') {valid = false; setvalue13(true);} //신청순위
        // else {setvalue13(false)}

        // if(input.dtl_qualify === '') {valid = false; setvalue14(true);} //상세자격요건
        // else {setvalue14(false)}

        // if(input.recipient === '') {valid = false; setvalue15(true);}//수급자자격
        // else {setvalue15(false)}

        if(input.final_result === '') {valid = false; setvalue16(true);} //최종결과
        else {setvalue16(false)}

        // if(input.name === '') {valid = false; setvalue17(true);} //지원기간시작일 -> 없음
        // else {setvalue17(false)}

        if(input.support_end_date === '') {valid = false; setvalue18(true);} //지원기간종료일
        else {setvalue18(false)}

        if(input.audit_house === '') {valid = false; setvalue19(true);} //유주택
        else {setvalue19(false)}

        if(input.audit_fund === '') {valid = false; setvalue20(true);} //기금중복
        else {setvalue20(false)}

        if(input.audit_other_rent === '') {valid = false; setvalue21(true);} //타임대
        else {setvalue21(false)}

        // if(input.audit_rent_chk === '') {valid = false; setvalue22(true);} //타임대체크
        // else {setvalue22(false)}

        // if(input.remark === '') {valid = false; setvalue23(true);} //비고(대외용)
        // else {setvalue23(false)}

        // if(input.memo === '') {valid = false; setvalue24(true);} //메모(대내용)
        // else {setvalue24(false)}

        if(input.family_cnt === '') {valid = false; setvalue25(true);} //세대원수
        else {setvalue25(false)}

        if(!valid) msg += '필수 입력값이 모두 입력되지 않았습니다.';

        return {valid, msg};
    }

    //----------------------------------------------------------------------
    // 직접추가 모달 관련

    // 저장후 닫기
    const saveClose = () => {
        const {valid, msg} = checkInput();
        
        if(valid) {
            dispatch(winnerWrite({...input}))
            setUpdate(true)
            closeState();
        }
        else {
            ALERT(msg)
        }
    }

    // 저장후 계속작성 -> 지원 유형은 그대로 유지 되어야 함.
    const saveWrite = () => {
        const {valid, msg} = checkInput();

        if(valid) {
            dispatch(winnerWrite({...input}))
            // dispatch(winnerWrite({input}))
            dispatch(initInput());
            setUpdate(true)
        }
        else {
            ALERT(msg)
        }
    }

    // 수정하기
    const onClickModi = () => {
        const {valid, msg} = checkInput();

        if(valid) {
            dispatch(winnerWrite({...input}))
            setUpdate(true)
            closeModal();
        }
        else {
            ALERT(msg)
        }
    }

    // 모달 관련 변수 비활성화
    const closeState = () => {
        setIsShow(false);
        setIsModi(false);
    }

    // 모달 닫기
    const closeModal = () => {
        closeState();
        dispatch(initInput());

        setvalue1(false)
        setvalue2(false)
        setvalue3(false)
        setvalue4(false)
        setvalue5(false)
        setvalue6(false)
        setvalue7(false)
        setvalue8(false)
        setvalue9(false)
        setvalue10(false)
        setvalue11(false)
        setvalue12(false)
        setvalue13(false)
        setvalue14(false)
        setvalue15(false)
        setvalue16(false)
        setvalue17(false)
        setvalue18(false)
        setvalue19(false)
        setvalue20(false)
        setvalue21(false)
        setvalue22(false)
        setvalue23(false)
        setvalue24(false)
        setvalue25(false)
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 직접추가하기 관련

    // 입력값 변경
    const onChangeInput = (e) => {
        const {name, value} = e.target

        let valid = true;
        if(name === "birth" && value.length > 6) { valid = false; }
        else if(name === "winner_no" && value.length > 13) { valid = false; }
        else if(name === "phone" && value.length > 11) { valid = false; }
        else if(name === "addr_no" && value.length > 5) { valid = false; }
        
        if(valid) {
            dispatch(
                changeValue({
                    key: name,
                    value
                })
            );
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 상단 기능 버튼 관련
    // 등록요청하기
    const onRequest = () => {
        if(checkedArr.length <= 0) {
            ALERT("요청할 항목이 없습니다.")
        }
        else {
            let typeCounter = 0;
            let arr = [];
            selectedType.forEach((item) => {
                if(item.count > 0) {
                    typeCounter += 1;
                    arr.push({type: item.type, name: item.name, count: item.count})
                }
            })

            if(typeCounter > 1) {
                ALERT("동일한 지원유형끼리 신청이 가능합니다.")
            }
            else {
                if(window.confirm(`선택항목을 등록 요청하시겠습니까?`)) {
                    if(arr[0]) {
                        dispatch(winnerRequest({
                            type: arr[0].type,
                            count: arr[0].count,
                            winner_id: checkedArr.toString()
                        }))
                        setUpdate(true);
                        setCheckedArr([]);
                        setSelectedType(cboApply.map((item) => {
                            return ({
                                type: item.sub_code,
                                name: item.code_name,
                                count: 0
                            })
                        }))
                    }
                    else {
                        ALERT('신청유형 값이 있어야 등록요청할 수 있습니다.')
                    }
                }
            }
        }
    }

    // 최종 반영하기
    const onApprove = () => {
        if(!type) {
            ALERT("유형이 없으면 최종반영 할 수 없습니다.")
        }
        else {
            if(window.confirm('최종반영 하시겠습니까?')) {
                dispatch(reqApprove({req_id: id}))
            }
        }
    }

    // 요청 건 삭제하기 (요청중인거만 가능함)
    const onDel = () => {
        if(window.confirm('현재 요청 건을 삭제 하시겠습니까?')) {
            dispatch(reqDelete({
                request_id: id
            }))
        }
    }

    // 리스트 개별 삭제
    const deleteList = () => {
        // 이 부분이 action 으로
        if(checkedArr.length > 0) {
            if(window.confirm(`총 ${checkedArr.length}건을 삭제하시겠습니까?`)) {
                dispatch(winnerDelete({winner_id: checkedArr.toString()}))
                setCheckedArr([]);
                setUpdate(true);
            }
        }
        else {
            ALERT("삭제할 항목이 없습니다.")
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 특정 리스트 클릭 (일반 항목 / 체크박스 / 버튼 -> 분기)
    const onClickList = (e,winner_id,idx) => {
        const { target: {children} } = e
        const {target: {tagName} } = e
        
        if(tagName === "TD" && children.length === 0) {
            setIsShow(true);
            setIsModi(true);
            dispatch(setValue({winner_id}))
            dispatch(
                setIndex({idx})
            );
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 체크박스
    // 전체 체크 활성, 비활성
    const onCheck = (e) => {
        const {target: {checked}} = e;
        if(checked) {
            winnerList.forEach((el) => {
                if(el.type) {
                    setCheckedArr((checkedArr) => checkedArr.some((subEl) => subEl === el.winner_id)? checkedArr : checkedArr.concat(el.winner_id))
                }
            })
            winnerList.forEach((item1) => {
                setSelectedType((selectedType) => selectedType.map((item2) => {
                    if(item1.type === item2.type) {
                        return ({
                            ...item2,
                            count: item2.count + 1
                        })
                    }
                    else {
                        return item2
                    }
                }))
            })
        }
        else {
            setCheckedArr([])
            setSelectedType(selectedType.map((item) => {
                return ({
                    ...item,
                    count: 0
                })
            }))
        }
    }

    // 체크박스 개별 선택
    const onChangeCheck = (e, select) => {
        const {target: {checked}} = e;
        if(checked) {
            if(select.type) {
                setCheckedArr(checkedArr.concat(select.winner_id));
                setSelectedType(selectedType.map((item) => {
                    if(item.type === select.type) {
                        return ({
                            ...item,
                            count: item.count + 1
                        })
                    }else {
                        return item
                    }
                }))
            }
            else {
                ALERT("신청유형이 없습니다.")
            }
        }
        else {
            setCheckedArr(checkedArr.filter((el) => el !== select.winner_id));
            setSelectedType(selectedType.map((item) => {
                if(item.type === select.type) {
                    return ({
                        ...item,
                        count: item.count - 1
                    })
                }else {
                    return item
                }
            }))
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 가족정보 추가, 삭제, 수정

    // 행 추가
    const onAddRow = () => {
        dispatch(addMember());
    }
    // 행 삭제
    const onDelRow = (idx) => {
        dispatch(delMember({targetIdx: idx}))
    }
    const onChangeRow = (e, idx) => {
        const {target: {name, value}} = e

        let valid = true;

        if(name === 'no' && value.length > 13) { valid = false }
        if(valid) {
            dispatch(changeMember({
                key: name, 
                value, 
                targetIdx: idx
            }))
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 엑셀버튼 클릭 (업로드 작업 페이지로 이동)
    const onClickXl = () => {
        navigate(dataURL.WinnerExcelWorkPage);
    }
    //----------------------------------------------------------------------

    //----------------------------------------------------------------------
    // 페이징
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeSearch({key: "page", value: String(num)}))
            setChangeUrl(true);
        }
    }

    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
            setChangeUrl(true);
        }
    }

    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
            setChangeUrl(true);
        }
    }

    // 쿼리 스트링 구성, 변경, 이동
    useEffect(() => {
        if(changeUrl) {
            navigate(encodeURI(`${location.pathname}?page=${search.page}`), 
            {
                state: {
                    ...location.state
                },
                replace: location.search? false : true
            })
            setChangeUrl(false);
        }
    }, [navigate, changeUrl, location, search.page, status])

    // 변경된 url 로 조회.
    useEffect(() => {
        if(!location.search) {
            setChangeUrl(true)
        }
        else {
            const tempObj = qr(location.search);
            dispatch(setSearchValue(tempObj))
            
            dispatch(setPaging({
                key: "currentPage",
                value: Number(tempObj.page),
            }))
            dispatch(setPaging({
                key: "step",
                value: Number(tempObj.page),
            }))

            dispatch(detailSearch({
                req_id: location.state.id? location.state.id : "",
                page: tempObj.page
            }));
        }
    }, [dispatch, location])

    // 추가, 수정 후 리스트 업데이트
    useEffect(() => {
        if(dispatch && update && search) {
            const tempObj = qr(location.search);
            dispatch(detailSearch({
                req_id: id? id : "",
                page: tempObj.page
            }));
            setUpdate(false)
        }
    }, [dispatch, update, id, search, location.search]);
    
  
    useEffect(() => {
        if(cboApply) {
            setSelectedType(cboApply.map((item) => {
                return ({
                    type: item.sub_code,
                    name: item.code_name,
                    count: 0
                })
            }))
        }
    },[cboApply])

    // 권한별 버튼 여부.
    useLayoutEffect(() => {
        // 요청 대기리스트인 경우
        if(func && status) {
            // 등록 요청을 대기중인 리스트인경우
            if(status === 'request') {
                if(func.some((el)=> el.id === "FUNC012")) {
                    setIsApprove(true);
                }
            }
            // 등록 완료된 리스트인경우
            else if (status === 'complete') {
                setIsApprove(false);
                setIsRequest(false);
            }
        }
        // 등록 요청 작성중인 리스트인경우
        else if (func && !status) {
            if(func.some((el) => el.id === "FUNC011")) {
                setIsRequest(true)
            }
        }
    }, [status, func])
    
    // 콤보 가져오기
    useEffect(() => {
        if(cboApply.length < 1) {
            dispatch(getCombo({main_code: "APPLY_TYPE"}))
        }
    }, [dispatch, cboApply])

    useEffect(() => {
        return () => {
            dispatch(initialize());
            dispatch(initHeader());
        }
    }, [dispatch])

    // 최종반영하면 뒤로가기
    useEffect(() => {
        if(navigate) {
            if(approveSuccess) {
                ALERT("최종반영에 성공했습니다.");
                navigate(-1)
            }
        }
    }, [approveSuccess, navigate])


    useEffect(() => {
        if(navigate) {
            if(reqDeleteSuccess) {
                if(reqDeleteSuccess === "Y") {
                    navigate(-1)
                }
            }
        }
    }, [reqDeleteSuccess, navigate])

    const propDatas = {
        winnerList,
        isShow,
        input,
        isModi,
        checkedArr,
        cboApply,
        id,
        isApprove,
        isRequest,
        status,

        value1, 
        value2, 
        value3, 
        value4, 
        value5, 
        value6, 
        value7, 
        value8, 
        value9, 
        value10,
        value11,
        value12,
        value13,
        value14,
        value15,
        value16,
        value17,
        value18,
        value19,
        value20,
        value21,
        value22,
        value23,
        value24,
        value25,

        maxPageCount,
        pageCount, 
        max,
        step, 
        currentPage, 

        setIsShow,
        onChangeInput,
        closeModal,
        deleteList,
        saveClose,
        saveWrite,
        onClickList,
        onClickModi,
        onRequest,
        onCheck,
        onChangeCheck,
        onApprove,
        onAddRow,
        onDelRow,
        onChangeRow,
        onClickXl,
        onClickNum,
        onClickNext,
        onClickPrev,
        onDel,
    }
    return (
        <>
            <WinnerRegisterWork {...propDatas}/>
        </>
    );
}

export default WinnerRegisterWorkContainer;