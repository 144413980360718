import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DobaeUserListSearchView from '../components/DobaeUserListSearchView';
import DobaeUserListView from '../components/DobaeUserListView';
import { dobaeuserlistChangeValue, dobaeuserlistInitialize, dobaeuserlistSearch } from '../modules/dobaeuserlist';

const DobaeUserListContainer = () => {
    const dispatch = useDispatch();
    const {
        cust_name,
        birth,
        dobaeuserlist,
        error,
        loading,
    } = useSelector(({dobaeuserlist, loading}) => ({
        cust_name: dobaeuserlist.cust_name,
        birth: dobaeuserlist.birth,
        dobaeuserlist: dobaeuserlist.dobaeuserlist,
        error: dobaeuserlist.error,
        loading: loading['dobaeuserlist/SEARCH'],
    }));
    //----------------------------------------------------------------- Working Function Area Start
    const changeValue = e => {
        dispatch(dobaeuserlistChangeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const searchDobaeUserList = () => {
        if( cust_name === '' && birth === '' ) {
            dispatch(dobaeuserlistInitialize());
        } else {
            dispatch(dobaeuserlistSearch({cust_name, birth}));
        }
    }

    const onKeyPress = e => {
        if( e.key === 'Enter' ) {
            searchDobaeUserList();
        }
    }
    //----------------------------------------------------------------- Working Function Area End
    //----------------------------------------------------------------- useEffect Area Start
    useEffect(() => {
        return () => {
            dispatch(dobaeuserlistInitialize());
        }
    }, [dispatch])
    //----------------------------------------------------------------- useEffect Area End
    //----------------------------------------------------------------- Header Button Area Start
    //----------------------------------------------------------------- Header Button Area End

    const propDatas = {
        cust_name,
        birth,
        dobaeuserlist,
        error,
        loading,

        changeValue,
        searchDobaeUserList,
        onKeyPress,
    }
    return (
        <>
            <DobaeUserListSearchView    {...propDatas}/>
            <DobaeUserListView          {...propDatas}/>
        </>
    );
}

export default DobaeUserListContainer;