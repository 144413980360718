import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import * as approvalAPI from '../../lib/api/approval';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enDocFail/INITIALIZE';
const CHANGE_VALUE = 'enDocFail/CHANGE_VALUE';
const [SET_STATUS_API, SET_STATUS_API_SUCCESS, SET_STATUS_API_FAILURE] = createRequestActionTypes('enDocFail/SET_STATUS_API');
const [SAVE_APPROVAL, SAVE_APPROVAL_SUCCESS, SAVE_APPROVAL_FAILURE] = createRequestActionTypes('enDocFail/SAVE_APPROVAL');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const setStatusApi = createAction(SET_STATUS_API, (body) => (body));
export const saveApproval = createAction(SAVE_APPROVAL);

const setStatusApiSaga = createRequestSaga(SET_STATUS_API, entitleAPI.status);
const saveApprovalSaga = createRequestSaga(SAVE_APPROVAL, approvalAPI.approve);

export function* enDocFailSaga() {
    yield takeLatest(SET_STATUS_API, setStatusApiSaga);
    yield takeLatest(SAVE_APPROVAL, saveApprovalSaga);
}

const initialState = {
    lack_reason: '',

    enDocFail: null,
    error: null
};

const enDocFail = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        //  Status 변경   =======================================================================================
        [SET_STATUS_API_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SET_STATUS_API_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  결재 반려   =======================================================================================
        [SAVE_APPROVAL_SUCCESS]: (state, { payload }) => {
            ALERT("결재를 반려하였습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_APPROVAL_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default enDocFail;