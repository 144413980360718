import React from 'react';
import styled from 'styled-components';
import { ShowTelDash } from '../lib/dataUTIL';

const DobaeUserListViewBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    position: relative;

    table {
        white-space: nowrap;
        margin-bottom: 0px;

        border-collapse: separate;
        border-spacing: 0px;
    }
    .tt{
        table-layout: auto;
    }
    .tt.tableLayout thead tr th:first-child{
        width:auto;
        padding-left:5px;
    }

    .tt.tableLayout thead tr th:last-child{
        width:auto;
        padding-right:5px;
    }

    table thead{
        position: sticky;
        top: 0px;
        background-color: white;
        z-index: 10;
    }
    

    table .target td{
        border-bottom: 1px solid red;
        border-top: 1px solid red;
    }

    .tableCover table th{
        border: 1px solid #cfcbcb;
    }

`;

const DobaeUserListView = (props) => {
    const {
        dobaeuserlist,
        error,
        loading,
    } = props;

    if( ((!Array.isArray(dobaeuserlist)) && (error === null)) || loading ) return <></>;

    return (
        <DobaeUserListViewBlock>
            {/* {dobaeuserlist.length > 0 ? (
                <table>
                    {
                        dobaeuserlist.map(item => {
                            return (<>
                                <tr><th>성명</th>               <td>{item.cust_name}</td></tr>
                                <tr><th>생년월일</th>           <td>{item.cust_birth}</td></tr>
                                <tr><th>연락처</th>             <td>{ShowTelDash(item.cust_phone)}</td></tr>
                                <tr><th>신청주소</th>           <td>{item.address}</td></tr>
                                <tr><th>입주유형</th>           <td>{item.winner_type_name}</td></tr>
                                <tr><th>요청일</th>             <td>{item.req_date}</td></tr>
                                <tr><th>요청유형</th>           <td>{item.contract_type_name}</td></tr>
                                <tr><th>상태</th>               <td>{item.status}</td></tr>
                                <tr><th>검토서 도배지원가능여부</th>    <td>{item.wallpaper_flag === 'Y' ? '가능' : '불가'}</td></tr>
                                <tr><th>피드백 도배지원가능여부</th>    <td>{item.feedback_wallpaper_yn === 'Y' ? '가능' : '불가'}</td></tr>
                            </>)
                        })
                    }
                </table>
            ) : (<>검색 내용이 없습니다.</>)
            } */}
            {dobaeuserlist.length > 0 ? (
                <div className='tableCover'>
                <table className='tt tableLayout'>
                    <thead>
                        <tr>
                            <th colSpan={9}>입주자 정보</th>
                            <th colSpan={2}>도배지원가능여부</th>
                        </tr>
                        <tr>
                            <th>성명</th>
                            <th>생년월일</th>
                            <th>연락처</th>                            
                            <th>신청주소</th>
                            <th>입주유형</th>
                            <th>전용면적</th>
                            <th>요청일</th>
                            <th>요청유형</th>
                            <th>상태</th>
                            <th>검토서</th>
                            <th>피드백</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        dobaeuserlist.map(item => {
                            return (
                            <tr key={item.doc_id}>
                                <td>{item.cust_name}</td>
                                <td>{item.cust_birth}</td>
                                <td>{ShowTelDash(item.cust_phone)}</td>
                                <td>{item.address}</td>
                                <td>{item.winner_type_name}</td>
                                <td>{item.house_area}</td>
                                <td>{item.req_date}</td>
                                <td>{item.contract_type_name}</td>
                                <td>{item.status}</td>
                                <td>{item.wallpaper_flag === 'Y' ? '가능' : item.wallpaper_flag === 'N' ? '불가' : '-'}</td>
                                <td>{item.feedback_wallpaper_yn === 'Y' ? '가능' : item.feedback_wallpaper_yn === 'N' ? '불가' : '-'}</td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            ) : (<>검색 내용이 없습니다.</>)
            }
        </DobaeUserListViewBlock>
    );
}

export default DobaeUserListView;