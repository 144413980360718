import React from 'react';
import styled from 'styled-components';
import dataWORD from '../../lib/dataWord';

const BuildsMngViewBlock = styled.div`
.bd-title {
    margin-left: 1em;
    float: left;
    vertical-align : top;
}
.bd-datas {
    margin-right: 1em;
    float: right;
    vertical-align : bottom;
}
`;

const BuildsMngPrint = ({_doc015}) => {
    if( !_doc015 ) {
        return (
            <>
                <div style={{pageBreakBefore:'always'}}>
                    API 오류
                </div>
            </>
        )
    }
    let jeonu = [];
    let gongyong = [];
    if( _doc015.RESP_DATA && Array.isArray(_doc015.RESP_DATA) ) {
        jeonu = _doc015.RESP_DATA.filter(item => item.BUILD_PYO_GUBUN === '전유부분');
        gongyong = _doc015.RESP_DATA.filter(item => item.BUILD_PYO_GUBUN === '공용부분');
    }

    return (
        <BuildsMngViewBlock>
        <div className='tableWrap build' style={{pageBreakBefore:'always'}}>
                <div className='viewerContainer borderwrap bd0'>
                    <h4 className='mt0 text-c' style={{display: 'block'}}>집합건축물대장(전유부, 갑)</h4>
                    <div style={{fontSize:"12px"}}>
                        ■ 건축물대장의 기재 및 관리 등에 관한 규칙 [별지 제5호서식](개정 2021. 07. 12)
                    </div>
                    <table className='bulidNum'>
                        <thead>
                            <tr>
                                <th rowSpan={2} style={{width: '10%'}}>고유번호</th>
                                <td className='vertical-m text-c' rowSpan={2} style={{width:'50%'}}>{_doc015.PRIMARY_NUM}</td>
                                <th className='bdb0' style={{width: '20%'}}>명칭</th>
                                <th className='bdb0' style={{width: '20%'}}>호명칭</th>
                            </tr>
                            <tr>
                                <td className='bdt0' style={{width: '20%'}}>{_doc015.DESIGNATION}</td>
                                <td className='bdt0' style={{width: '20%'}}>{_doc015.HO_NAME}</td>
                            </tr>
                            </thead>
                    </table>
                    <table className='bulidNum'>
                        <thead>
                            <tr>
                                <th className='bdt0' rowSpan={2} style={{width: '10%'}}>대지위치</th>
                                <td className='vertical-m text-c bdt0' rowSpan={2} style={{width:'30%'}}>{_doc015.GROUND_LOCATION}</td>
                                <th className='bdb0 bdt0' style={{width: '20%'}}>지번</th>
                                <th className='bdt0 bdt0' rowSpan={2} style={{width: '10%'}}>도로명주소</th>
                                <td className='vertical-m text-c bdt0' rowSpan={2} style={{width:'30%'}}>{_doc015.ROAD_ADDRESS}</td>
                            </tr>
                            <tr>
                                <td className='bdt0'>{_doc015.JIBEON}</td>
                            </tr>
                        </thead>
                    </table>
                    {/* <table>
                        <tbody>
                            <tr className='bd-tr'>
                                <td colSpan={2}>
                                    <div className='bd-title'>고유번호</div>
                                    <div className='bd-datas'>{_doc015.PRIMARY_NUM}</div>
                                </td>
                                <td>
                                    <div className='bd-title'>명칭</div>
                                    <div className='bd-datas'>{_doc015.DESIGNATION}</div>
                                </td>
                                <td>
                                    <div className='bd-title'>호명칭</div>
                                    <div className='bd-datas'>{_doc015.HO_NAME}</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='bd-title'>대지위치</div>
                                    <div className='bd-datas'>{_doc015.GROUND_LOCATION}</div>
                                </td>
                                <td>
                                    <div className='bd-title'>지번</div>
                                    <div className='bd-datas'>{_doc015.JIBEON}</div>
                                </td>
                                <td colSpan={2}>
                                    <div className='bd-title'>도로명주소</div>
                                    <div className='bd-datas'>{_doc015.ROAD_ADDRESS}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    <div className='bulidReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={5}>전유부분</th>
                                </tr>
                                <tr>
                                    <th>구분</th>
                                    <th>층별</th>
                                    <th>구조</th>
                                    <th>용도</th>
                                    <th>면적</th>
                                </tr>
                            </thead>
                            {
                                (jeonu.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            jeonu.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.GUBUN}</td>
                                                        <td>{item.LAYER}</td>
                                                        <td>{item.RESCUE}</td>
                                                        <td>{item.USAGE}</td>
                                                        <td>{item.AREA}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                    <div className='bulidReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={5}>공용부분</th>
                                </tr>
                                <tr>
                                    <th>구분</th>
                                    <th>층별</th>
                                    <th>구조</th>
                                    <th>용도</th>
                                    <th>면적</th>
                                </tr>
                            </thead>
                            {
                                (gongyong.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            gongyong.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.GUBUN}</td>
                                                        <td>{item.LAYER}</td>
                                                        <td>{item.RESCUE}</td>
                                                        <td>{item.USAGE}</td>
                                                        <td>{item.AREA}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                    <div className='ownerReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={6}>소유자현황</th>
                                </tr>
                                <tr>
                                    <th>성명(명칭)</th>
                                    <th>{dataWORD.BIRTHDATE}</th>
                                    <th>주소</th>
                                    <th>소유권지분</th>
                                    <th>변동일</th>
                                    <th>변동원인</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA2 && Array.isArray(_doc015.RESP_DATA2) && (_doc015.RESP_DATA2.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA2.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.OWNER_NAME}</td>
                                                        <td>{item.OWNER_REG}</td>
                                                        <td>{item.OWNER_ADD}</td>
                                                        <td>{item.OWNER_SHARE}</td>
                                                        <td>{item.OWNER_CHANGE_DATE}</td>
                                                        <td>{item.OWNER_CHANGE_CAUSE}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    
                    <div className='change mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>변동사항</th>
                                </tr>
                                <tr>
                                    <th>변동일</th>
                                    <th>변동내용 및 원인</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA4 && Array.isArray(_doc015.RESP_DATA4) && (_doc015.RESP_DATA4.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA4.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.CHANGES_DATA}</td>
                                                        <td className='text-l pl10'>{item.CHANGES_CAUSE}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    <div className='etc mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th>그 밖의 기재사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{_doc015.ETC}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
        </BuildsMngViewBlock>
    );
}

export default BuildsMngPrint;