import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ImplyApproveContainer from '../containers/ImplyApproveContainer';

const ImplyApprovePage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyApproveContainer />
        </>
    );
}

export default ImplyApprovePage;