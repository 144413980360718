import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ImplyRegisterListContainer from '../containers/ImplyRegisterListContainer';

const ImplyRegisterListPage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyRegisterListContainer />
        </>
    );
}

export default ImplyRegisterListPage;