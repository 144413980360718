import React from 'react';
import UserTypeSelectContainer from '../containers/UserTypeSelectContainer';

const UserTypeSelectPage = () => {
    return (
        <>
            <UserTypeSelectContainer/>
        </>
    );
}

export default UserTypeSelectPage;