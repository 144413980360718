import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnwAdminUpload from '../../components/Modal/EnwAdminUpload';
import { ALERT } from '../../lib/dataWord';
import { addRow, initialize, removeRow, rowChangeValue, save, setDocID } from '../../modules/Modal/enwAdminUpload';

const EnwAdminUploadContainer = (props) => {
    const {
        doc_id,
        isShowUpload,   //ENWRITEADMIN : 한페이지 신청, PHOTOREQUEST : 사진으로 신청하기
        setIsShowUpload,
        onSaveFileUpload,
        originFileList,
    } = props;

    const dispatch = useDispatch();
    const {
        _doc_id,
        isCloseModal,
        LastIdx,
        fileList,
    } = useSelector(({ enwAdminUpload }) => ({
        _doc_id: enwAdminUpload._doc_id,
        isCloseModal: enwAdminUpload.isCloseModal,
        LastIdx: enwAdminUpload.LastIdx,
        fileList: enwAdminUpload.fileList,
    }));
    
    const onFileChange = (e) => {
        // const fileIdx = LastIdx + 1;
        // dispatch(setLastIdx({value: fileIdx}));
        // dispatch(addRow());

        // const file = e.target.files[0];
        // if(!file) return;
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = () => {
        //     dispatch(
        //         rowChangeValue({
        //             idx: fileIdx,
        //             fileReader: reader,
        //             fileName: file.name,
        //             fileExtension: file.type,
        //         })
        //     );
        // }

        
        for (let idx = 0; idx < e.target.files.length; idx++) {
            let fileIdx = LastIdx + idx + 1;
            const file = e.target.files[idx];
            if(!file) return;

            dispatch(addRow());

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                dispatch(
                    rowChangeValue({
                        idx: fileIdx,
                        fileReader: reader,
                        fileName: file.name,
                        fileExtension: file.type,
                    })
                );
            }
        }
    }
    const onRemoveRow = (item, e) => {
        if(item.file_name) {
            if(window.confirm("정말 삭제합니까?")) {
                dispatch(removeRow({idx: item.idx}));
            }
        }
        else {
            dispatch(removeRow({idx: item.idx}));
        }
    }
    const onSave = () => {
        if (isShowUpload === "ENWRITEADMIN") {
            dispatch(
                save({
                    docId: _doc_id,
                    files: fileList,
                })
            )
        }
        else if (isShowUpload === "PHOTOREQUEST") {
            const _fileList = fileList.filter(x => x.use_flag === "Y");
            if(_fileList.length === 0) {
                ALERT("지원신청 사진을 첨부해주세요.");
                return;
            }

            dispatch(
                save({
                    docId: _doc_id,
                    files: fileList,
                })
            )
        }
    }

    const onClose = useCallback(() => {
        setIsShowUpload(null);
    }, [setIsShowUpload])
    
    useEffect(() => {
        if (isCloseModal && isShowUpload === "ENWRITEADMIN") {
            setTimeout(() => {
                //  업로드한 마지막 파일이 작성 페이지 우측에 나타나지 않음ㅠㅠ sync를 맞춰야하는데...
                onSaveFileUpload(_doc_id, fileList);
            }, 500);
        }
        else if (isCloseModal && isShowUpload === "PHOTOREQUEST") {
            ALERT("신청이 완료되었습니다.");
            onClose();
        }
    }, [dispatch, isCloseModal, _doc_id, fileList, isShowUpload, onSaveFileUpload, onClose])

    useEffect(() => {
        dispatch(setDocID({doc_id: doc_id, originFileList: originFileList}));
    }, [dispatch, doc_id, originFileList])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        isShowUpload,
        fileList,

        onFileChange,
        onRemoveRow,
        onSave,
        onClose,
    }
    return (
        <>
            <EnwAdminUpload {...propDatas}/>
        </>
    );
}

export default EnwAdminUploadContainer;