import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as entitleAPI from '../lib/api/entitle';
import * as scheAPI from '../lib/api/sche';
import { ALERT } from '../lib/dataWord';
import { ToLocaleString } from '../common';

const INITIALIZE = 'contract/INITIALIZE';
const CHANGE_VALUE = 'contract/CHANGE_VALUE';

const LESSOR_ADD_ROW = 'contract/LESSOR_ADD_ROW';
const LESSOR_REMOVE_ROW = 'contract/LESSOR_REMOVE_ROW';
const LESSOR_SET_LAST_IDX = 'contract/LESSOR_SET_LAST_IDX';
const LESSOR_CHANGE_VALUE = 'contract/LESSOR_CHANGE_VALUE';

const WINNER_CHANGE_VALUE = 'contract/WINNER_CHANGE_VALUE';

const DOC_SELECT_FOLDER = 'contract/DOC_SELECT_FOLDER';
const DOC_SELECT_IMG = 'contract/DOC_SELECT_IMG';
const UPLOAD_COMPLETE = 'contract/UPLOAD_COMPLETE';

const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('contract/GET_CONTENT');
const [GET_FILES, GET_FILES_SUCCESS, GET_FILES_FAILURE] = createRequestActionTypes('contract/GET_FILES');
const [SET_STATUS_API, SET_STATUS_API_SUCCESS, SET_STATUS_API_FAILURE] = createRequestActionTypes('contract/SET_STATUS_API');
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('contract/SAVE');
const [SAVEONLY, SAVEONLY_SUCCESS, SAVEONLY_FAILURE] = createRequestActionTypes('contract/SAVEONLY');
const [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE] = createRequestActionTypes('contract/SEND_SMS');
const [IMG_ROTATION, IMG_ROTATION_SUCCESS, IMG_ROTATION_FAILURE] = createRequestActionTypes('contract/IMG_ROTATION');
const [REMOVE_ENTITLE, REMOVE_ENTITLE_SUCCESS, REMOVE_ENTITLE_FAILURE] = createRequestActionTypes('contract/REMOVE_ENTITLE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));

export const lessorAddRow = createAction(LESSOR_ADD_ROW, (idx) => idx);
export const lessorRemoveRow = createAction(LESSOR_REMOVE_ROW, ({idx}) => ({idx}));
export const lessorSetLastIdx = createAction(LESSOR_SET_LAST_IDX, ({value}) => ({value}));
export const lessorChangeValue = createAction(LESSOR_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));

export const winnerChangeValue = createAction(WINNER_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));

export const doc_selectFolder = createAction(DOC_SELECT_FOLDER, (value) => value);
export const doc_selectImg = createAction(DOC_SELECT_IMG, (value) => value);
export const uploadComplete = createAction(UPLOAD_COMPLETE, (value) => value);

export const getContent = createAction(GET_CONTENT);
export const getFiles = createAction(GET_FILES);
export const setStatusApi = createAction(SET_STATUS_API, (body) => (body));
export const save = createAction(SAVE, (body) => (body));
export const saveOnly = createAction(SAVEONLY, (body) => (body));
export const sendSms = createAction(SEND_SMS, (body) => (body));
export const imgRotation = createAction(IMG_ROTATION, (body) => (body));
export const removeEntitle = createAction(REMOVE_ENTITLE);

const getContentSaga = createRequestSaga(GET_CONTENT, entitleAPI.contract_view);
const getFilesSaga = createRequestSaga(GET_FILES, entitleAPI.file_view);
const setStatusApiSaga = createRequestSaga(SET_STATUS_API, entitleAPI.status);
const saveSaga = createRequestSaga(SAVE, entitleAPI.contract_reg);
const saveOnlySaga = createRequestSaga(SAVEONLY, entitleAPI.cont_status);
const sendSmsSaga = createRequestSaga(SEND_SMS, scheAPI.smsSend);
const imgRotationSaga = createRequestSaga(IMG_ROTATION, entitleAPI.file_rotation);
const removeEntitleSaga = createRequestSaga(REMOVE_ENTITLE, entitleAPI.ennotused);

export function* contractSaga() {
    yield takeLatest(GET_CONTENT, getContentSaga);
    yield takeLatest(GET_FILES, getFilesSaga);
    yield takeLatest(SET_STATUS_API, setStatusApiSaga);
    yield takeLatest(SAVE, saveSaga);
    yield takeLatest(SAVEONLY, saveOnlySaga);
    yield takeLatest(SEND_SMS, sendSmsSaga);
    yield takeLatest(IMG_ROTATION, imgRotationSaga);
    yield takeLatest(REMOVE_ENTITLE, removeEntitleSaga);
}

const initialState = {
    doc_status: '',

    lessorLastIdx: 0,
    lessor: [],

    lessee_name: '',
    lessee_addr: '',
    lessee_reg_no: '',
    lessee_tel: '',

    winner: [],

    broker1_name: '',
    broker1_owner: '',
    broker1_addr: '',
    broker1_reg_no: '',
    broker1_tel: '',
    broker2_name: '',
    broker2_owner: '',
    broker2_addr: '',
    broker2_reg_no: '',
    broker2_tel: '',

    contract_date: '',
    contract_location: '',
    origin_contract_date: '',
    origin_contract_location: '',
    real_cont_date: '',
    cont_status: '',

    confirm_date: '',
    against_date: '',
    delivery_date: '',
    cont_break: '',
    wallpaper_flag: '',

    road_addr: '',
    jibun_addr: '',
    house_type: '',
    house_area: '',
    ground_area: '',
    share_area: '',
    room_count: '',
    welfare_type: '',
    collateral_flag: '',
    collateral_type: '',
    collateral_amt: '',
    collateral_date: '',

    rent_amt: '',
    rent_amt_Han: '',
    monthly_rent_amt: '',
    monthly_rent_amt_Han: '',
    month_amt_pay_day: '',
    lease_start_date: '',
    lease_end_date: '',
    down_payment: '',
    down_payment_Han: '',
    interim_payment: '',
    interim_payment_Han: '',
    interim_payment_date: '',
    balance1: '',
    balance1_Han: '',
    balance2: '',
    balance2_Han: '',
    balance_payment_date: '',
    account_num: '',
    bank_name: '',
    account_holder: '',

    remark: '',

    //  서류 이미지
    selectFoler: '',
    docFolder: [],
    selectImg: null,
    docImages: [],
    // selectTxtFolder: 'TXT01',
    txtFolder: [
        {
            code: 'TXT01',
            name: '지원신청서',
        },
        {
            code: 'TXT02',
            name: '권리분석 검토서',
        },
        // {
        //     code: 'TXT03',
        //     name: '등기부등본',
        // },
        {
            code: 'TXT04',
            name: '건축물관리대장',
        },
        {
            code: 'TXT05',
            name: '주택가격정보',
        },
        {
            code: 'TXT06',
            name: '신/구 주소',
        },
    ],

    alreadyFileList: [],

    feedback: [],
    rent: [],

    contract: null,
    approval: null,
    error: null
};

const contract = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),

        [LESSOR_SET_LAST_IDX]: (state, { payload: {value}}) => ({
            ...state,
            lessorLastIdx: value,
        }),
        [LESSOR_ADD_ROW]: (state) => {
            let _lessor = state.lessor;

            _lessor.push({
                lessor_seq: state.lessorLastIdx,
                lessor_name: '',
                lessor_addr: '',
                lessor_no: '',
                lessor_tel: '',
                use_flag: 'Y',
                sort: state.lessorLastIdx,
            })

            //  순서 재정의
            let rowNum = 0;
            _lessor.filter(x => x.use_flag === 'Y').forEach(item => {
                item.sort = rowNum++;
            });

            return {
                ...state,
                lessor: _lessor,
            }
        },
        [LESSOR_REMOVE_ROW]: (state, { payload: {idx}}) => {
            const _lessor = state.lessor.map((item) =>
                item.lessor_seq === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );

            //  순서 재정의
            let rowNum = 0;
            _lessor.filter(x => x.use_flag === 'Y').forEach(item => {
                item.sort = rowNum++;
            });

            return {
                ...state,
                lessor: _lessor,
                // lessorLastIdx: rowNum - 1,
            };
        },
        [LESSOR_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _lessor = state.lessor.map((item) =>
                item.lessor_seq === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                lessor: _lessor,
            };
        },
        [WINNER_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _winner = state.winner.map((item) =>
                item.winner_id === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                winner: _winner,
            };
        },

        [DOC_SELECT_FOLDER]: (state, { payload }) => {
            return {
                ...state,
                selectFoler: payload.code,
            };
        },
        [DOC_SELECT_IMG]: (state, { payload }) => {
            return {
                ...state,
                selectImg: {
                    src: payload.imgSrc,
                    file_id: payload.file_id,
                    rotation: !payload.rotation ? 0 : payload.rotation,
                    api_yn: payload.api_yn,
                }
            };
        },
        [UPLOAD_COMPLETE]: (state, { payload }) => {
            //  Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.forEach(file => {
                if(file.use_flag === "Y" && file.file_data) {
                    //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                    if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                        _docFolder.push({
                            name: file.file_type_name,
                            code: file.file_type,
                        })
                    }

                    //  문서종류 버튼의 첫번째 버튼을 자동선택
                    if(!_selectFoler) {
                        _selectFoler = file.file_type;
                    }

                    file.file_data.forEach(item => {
                        _docImages.push({
                            docCode: file.file_type,
                            imgSrc: item.file_url,
                            file_id: file.file_id,
                            rotation: item.rotation,
                            api_yn: file.api_yn,
                        })
                    });

                    // const arrFile = file.file_data.split(',');
                    // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                    // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                    //     arrFile.forEach(item => {
                    //         _docImages.push({
                    //             docCode: file.file_type,
                    //             imgSrc: item,
                    //             file_id: file.file_id,
                    //             rotation: file.rotation,
                    //             api_yn: file.api_yn,
                    //         })
                    //     });
                    // }
                    // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                    // else {
                    //     _docImages.push({
                    //         docCode: file.file_type,
                    //         imgSrc: file.file_data,
                    //         file_id: file.file_id,
                    //         rotation: file.rotation,
                    //         api_yn: file.api_yn,
                    //     })
                    // }
                }

                if(_docImages.length > 0) {
                    _selectImg = {
                        src: _docImages[0].imgSrc,
                        file_id: _docImages[0].file_id,
                        rotation: _docImages[0].rotation,
                        api_yn: _docImages[0].api_yn,
                    }
                }
            });

            return ({
                ...state,
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                alreadyFileList: payload,
                selectImg: _selectImg,
            })
        },
        [IMG_ROTATION_SUCCESS]: (state, { payload }) => {
            ALERT("선택한 이미지의 방향이 저장되었습니다.");
            return {
                ...state,
            };
        },
        [IMG_ROTATION_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error,
            };
        },
        [GET_FILES_SUCCESS]: (state, { payload }) => {
            //  Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.forEach(file => {
                if(file.use_flag === "Y" && file.file_data) {
                    //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                    if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                        _docFolder.push({
                            name: file.file_type_name,
                            code: file.file_type,
                        })
                    }

                    //  문서종류 버튼의 첫번째 버튼을 자동선택
                    if(!_selectFoler) {
                        _selectFoler = file.file_type;
                    }

                    file.file_data.forEach(item => {
                        _docImages.push({
                            docCode: file.file_type,
                            imgSrc: item.file_url,
                            file_id: file.file_id,
                            rotation: item.rotation,
                            api_yn: file.api_yn,
                        })
                    });

                    // const arrFile = file.file_data.split(',');
                    // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                    // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                    //     arrFile.forEach(item => {
                    //         _docImages.push({
                    //             docCode: file.file_type,
                    //             imgSrc: item,
                    //             file_id: file.file_id,
                    //             rotation: file.rotation,
                    //             api_yn: file.api_yn,
                    //         })
                    //     });
                    // }
                    // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                    // else {
                    //     _docImages.push({
                    //         docCode: file.file_type,
                    //         imgSrc: file.file_data,
                    //         file_id: file.file_id,
                    //         rotation: file.rotation,
                    //         api_yn: file.api_yn,
                    //     })
                    // }
                }

                if(_docImages.length > 0) {
                    _selectImg = {
                        src: _docImages[0].imgSrc,
                        file_id: _docImages[0].file_id,
                        rotation: _docImages[0].rotation,
                        api_yn: _docImages[0].api_yn,
                    }
                }
            });

            return ({
                ...state,
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                alreadyFileList: payload,
                selectImg: _selectImg,
            })
        },
        [GET_FILES_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  계약서 조회   =======================================================================================
        [GET_CONTENT_SUCCESS]: (state, { payload }) => {
            //  임대인 초기 세팅 및 LastIndex 설정
            let _lessor, _lessorLastIdx = 0, loopCnt = 0;
            payload.lessor.forEach(item => {
                if(_lessorLastIdx < item.lessor_seq) {
                    _lessorLastIdx = item.lessor_seq;
                }
                item.sort = loopCnt++;
            });
            if(payload.lessor.length === 0) {
                _lessor = [
                    {
                        lessor_seq: 0,
                        lessor_name: '',
                        lessor_addr: '',
                        lessor_no: '',
                        lessor_tel: '',
                        use_flag: 'Y',
                        sort: 0,
                    }
                ]
            }
            else {
                _lessor = payload.lessor;
            }

            //  입주자 금액정보 세팅
            let _winner = [];
            payload.winner.forEach(item => {
                _winner.push({
                    winner_id: item.winner_id,
                    winner_name: item.winner_name,
                    winner_addr: item.winner_addr,
                    winner_no: item.winner_no,
                    winner_phone: item.winner_phone,
                    lh_support_amt: item.lh_support_amt,
                    lh_support_amt_Han: '',
                    winner_charges: item.winner_charges,
                    winner_charges_Han: '',
                    add_charges: item.add_charges,
                    add_charges_Han: '',
                    rent_amt_deadline: !item.rent_amt_deadline ? '' : item.rent_amt_deadline,
                    support_amt_deadline: !item.support_amt_deadline ? '' : item.support_amt_deadline,
                    winner_charge_deadline: !item.winner_charge_deadline ? '' : item.winner_charge_deadline,
                    add_charge_deadline: !item.add_charge_deadline ? '' : item.add_charge_deadline,
                    month_amt_pay_day: item.month_amt_pay_day,
                    monthly_rent: item.monthly_rent,
                    monthly_rent_Han: '',
                })
            });

            // feedback 정보 세팅
            let _feedback = [];
            payload.feedback.forEach(item => {
                _feedback.push({
                    account_no: item.account_no,
                    account_owner: item.account_owner,
                    add_charge: ToLocaleString(item.add_charge),
                    bank_name: item.bank_name,
                    cont_end_date: item.cont_end_date,
                    contract_area: item.contract_area,
                    contract_type: item.contract_type,
                    contract_type_name: item.contract_type_name,
                    current_cont_end_date: item.current_cont_end_date,
                    cust_addr: item.cust_addr,
                    cust_birth: item.cust_birth,
                    cust_name: item.cust_name,
                    dedicated_area: item.dedicated_area,
                    dup_cust_name: item.dup_cust_name,
                    dup_cust_phone: item.dup_cust_phone,
                    dup_cust_type: item.dup_cust_type,
                    duplication_yn: item.duplication_yn,
                    contract_yn: item.contract_yn,
                    other_rent_yn: item.other_rent_yn,
                    feedback_ans: item.feedback_ans,
                    feedback_date: item.feedback_date,
                    fund_loan_yn: item.fund_loan_yn,
                    guarantee_mon_amt: ToLocaleString(item.guarantee_mon_amt),
                    house_addr: item.house_addr,
                    house_member: item.house_member,
                    house_owner_yn: item.house_owner_yn,
                    lawsuit_yn: item.lawsuit_yn,
                    lh_add_charge: item.lh_add_charge,
                    lh_support_amt: ToLocaleString(item.lh_support_amt),
                    lh_win_charge: ToLocaleString(item.lh_win_charge),
                    owner_name: item.owner_name,
                    owner_tel: item.owner_tel,
                    remark: item.remark,
                    renewal_delay_yn: item.renewal_delay_yn,
                    rent_amt: ToLocaleString(item.rent_amt),
                    rent_surcharge: ToLocaleString(item.rent_surcharge),
                    req_cnt: item.req_cnt,
                    sigungu: item.sigungu,
                    support_amt: ToLocaleString(item.support_amt),
                    to_lh: item.to_lh,
                    unpaid_amt: ToLocaleString(item.unpaid_amt),
                    wallpaper_yn: item.wallpaper_yn,
                    winner_charge: ToLocaleString(item.winner_charge),
                    winner_id: item.winner_id,
                    winner_type: item.winner_type,
                    winner_type_name: item.winner_type_name,
                    lh_rent_amt: ToLocaleString(item.lh_rent_amt),
                })
            })

            let _rent = [];
            payload.rent.forEach(item => {
                _rent.push({
                    empty_type2: item.empty_type2,
                    empty_type2_name: item.empty_type2_name,
                    floor_name: item.floor_name,
                    priority_payment2: item.priority_payment2,
                    remark2: item.remark2,
                    rent_deposit2: item.rent_deposit2,
                    rent_fee2: item.rent_fee2,
                    rent_floor2: item.rent_floor2,
                    rent_seq: item.rent_seq,
                    room_cnt2: item.room_cnt2,
                    room_no2: item.room_no2,
                    use_flag: item.use_flag,
                    rent_tot_amt: item.rent_tot_amt,
                    empty_tot_amt: item.empty_tot_amt,
                })
            })

            //  Get폴더 & Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.files.forEach(file => {
                if(file.file_data) {
                    //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                    if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                        _docFolder.push({
                            name: file.file_type_name,
                            code: file.file_type,
                        })
                    }

                    //  문서종류 버튼의 첫번째 버튼을 자동선택
                    if(!_selectFoler) {
                        _selectFoler = file.file_type;
                    }

                    file.file_data.forEach(item => {
                        _docImages.push({
                            docCode: file.file_type,
                            imgSrc: item.file_url,
                            file_id: file.file_id,
                            rotation: item.rotation,
                            api_yn: file.api_yn,
                        })
                    });

                    // const arrFile = file.file_data.split(',');
                    // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                    // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                    //     arrFile.forEach(item => {
                    //         _docImages.push({
                    //             docCode: file.file_type,
                    //             imgSrc: item,
                    //             file_id: file.file_id,
                    //             rotation: file.rotation,
                    //             api_yn: file.api_yn,
                    //         })
                    //     });
                    // }
                    // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                    // else {
                    //     _docImages.push({
                    //         docCode: file.file_type,
                    //         imgSrc: file.file_data,
                    //         file_id: file.file_id,
                    //         rotation: file.rotation,
                    //         api_yn: file.api_yn,
                    //     })
                    // }

                    if(_docImages.length > 0) {
                        _selectImg = {
                            src: _docImages[0].imgSrc,
                            file_id: _docImages[0].file_id,
                            rotation: _docImages[0].rotation,
                            api_yn: _docImages[0].api_yn,
                        }
                    }
                }
            });

            return {
                ...state,
                contract: payload.contract[0],
                approval: payload.approval,

                doc_status: payload.contract[0].status,

                lessorLastIdx: _lessorLastIdx,
                lessor: _lessor,

                // lessee_name: payload.contract[0].lessee_name,
                // lessee_addr: payload.contract[0].lessee_addr,
                // lessee_reg_no: payload.contract[0].lessee_reg_no,
                // lessee_tel: payload.contract[0].lessee_tel,

                lessee_name: "한국토지주택공사 서울지역본부장",
                lessee_addr: "서울특별시 강남구 선릉로 121길 12 (서울시 강남구 논현동 254번지 서울본부)",
                lessee_reg_no: "135671 0033355",
                lessee_tel: "1670-2591",

                winner: _winner,

                broker1_name: payload.contract[0].broker_company,
                broker1_owner: payload.contract[0].broker_name,
                broker1_addr: payload.contract[0].broker_addr,
                broker1_reg_no: payload.contract[0].broker_reg_no,
                broker1_tel: payload.contract[0].broker_phone,
                broker2_name: payload.contract[0].broker2_name,
                broker2_owner: payload.contract[0].broker2_owner,
                broker2_addr: payload.contract[0].broker2_addr,
                broker2_reg_no: payload.contract[0].broker2_reg_no,
                broker2_tel: payload.contract[0].broker2_tel,

                contract_date: !payload.contract[0].contract_date ? '' : payload.contract[0].contract_date.replace(' ', 'T'),
                contract_location: payload.contract[0].contract_location,
                origin_contract_date: !payload.contract[0].contract_date ? '' : payload.contract[0].contract_date.replace(' ', 'T'),
                origin_contract_location: payload.contract[0].contract_location,
                real_cont_date: !payload.contract[0].real_cont_date ? '' : payload.contract[0].real_cont_date,
                cont_status: !payload.contract[0].cont_status ? '' : payload.contract[0].cont_status,

                confirm_date: !payload.contract[0].confirm_date ? '' : payload.contract[0].confirm_date,
                against_date: !payload.contract[0].against_date ? '' : payload.contract[0].against_date,
                delivery_date: !payload.contract[0].delivery_date ? '' : payload.contract[0].delivery_date,
                cont_break: payload.contract[0].cont_break,
                wallpaper_flag: payload.contract[0].wallpaper_flag,

                road_addr: payload.contract[0].road_addr,
                jibun_addr: payload.contract[0].jibun_addr,
                house_type: payload.contract[0].house_type,
                house_area: payload.contract[0].house_area,
                ground_area: payload.contract[0].ground_area,
                share_area: payload.contract[0].share_area,
                room_count: payload.contract[0].room_count,
                welfare_type: payload.contract[0].welfare_type,
                collateral_flag: payload.contract[0].collateral_flag,
                collateral_type: payload.contract[0].collateral_type,
                collateral_amt: payload.contract[0].collateral_amt,
                collateral_date: !payload.contract[0].collateral_date ? '' : payload.contract[0].collateral_date,

                rent_amt: payload.contract[0].rent_amt,
                monthly_rent_amt: payload.contract[0].monthly_rent_amt,
                month_amt_pay_day: payload.contract[0].month_amt_pay_day,
                lease_start_date: !payload.contract[0].lease_start_date ? '' : payload.contract[0].lease_start_date,
                lease_end_date: !payload.contract[0].lease_end_date ? '' : payload.contract[0].lease_end_date,
                down_payment: payload.contract[0].down_payment,
                interim_payment: payload.contract[0].interim_payment,
                interim_payment_date: !payload.contract[0].interim_payment_date ? '' : payload.contract[0].interim_payment_date,
                balance1: payload.contract[0].balance1,
                balance2: payload.contract[0].balance2,
                balance_payment_date: !payload.contract[0].balance_payment_date ? '' : payload.contract[0].balance_payment_date,
                account_num: payload.contract[0].account_num,
                bank_name: payload.contract[0].bank_name,
                account_holder: payload.contract[0].account_holder,

                rent_amt_deadline: !payload.contract[0].rent_amt_deadline ? '' : payload.contract[0].rent_amt_deadline,
                support_amt_deadline: !payload.contract[0].support_amt_deadline ? '' : payload.contract[0].support_amt_deadline,
                winner_charge_deadline: !payload.contract[0].winner_charge_deadline ? '' : payload.contract[0].winner_charge_deadline,
                add_charge_deadline: !payload.contract[0].add_charge_deadline ? '' : payload.contract[0].add_charge_deadline,

                remark: !payload.contract[0].remark ? '' : payload.contract[0].remark,

                feedback: _feedback,
                rent: _rent,

                //  우측 이미지
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                selectImg: _selectImg,
                
                alreadyFileList: payload.files, // 기등록된 파일정보
                use_address: payload.use_address && Array.isArray(payload.use_address) && payload.use_address.length > 0 ? payload.use_address[0] : null,
            }
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        //  Status 변경   =======================================================================================
        [SET_STATUS_API_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                doc_status: payload,
                error: null,
            })
        },
        [SET_STATUS_API_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  계약서 저장   =======================================================================================
        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("저장되었습니다.");
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [SAVEONLY_SUCCESS]: (state) => ({
            ...state,
        }),
        [SAVEONLY_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        [SEND_SMS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SEND_SMS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  검토서 삭제
        [REMOVE_ENTITLE_SUCCESS]: (state, { payload }) => {
            ALERT("권리분석 검토서가 삭제되었습니다.");
            return {
                ...state,
            }
        },
        [REMOVE_ENTITLE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default contract;