import React from 'react';
import NotFound from '../components/NotFound';

const NotFoundContainer = () => {
    return (
        <>
            <NotFound />
        </>
    );
}

export default NotFoundContainer;