import React from 'react';
import styled from 'styled-components';

const OptionMgtBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 20px 50px 0;
    box-sizing: border-box;

    .containerDiv {
        display: flex;
        align-items: flex-start;
        height: 82vh;
        gap: 45px;
        overflow-y: auto;
        border: 1px solid #ccc;
        padding: 10px;
    }
    .option-box {
        display: flex;
        gap: 10px;
    }
`;

const OptionMgt = (props) => {
    const {
        decide_date,
        onDecideDateSave,

        onChange,
    } = props;

    return (
        <OptionMgtBlock>
            <div className='containerDiv'>
                <div className='option-box'>
                    <h4>권리분석 검토서 가격결정일자 기본값 설정</h4>
                    <input type="date" name="decide_date" id="decide_date" value={decide_date} onChange={onChange} max="9999-12-31"/>
                    <button onClick={onDecideDateSave} className='dkgreen-btn'>저장</button>
                </div>
            </div>
        </OptionMgtBlock>
    );
}

export default OptionMgt;