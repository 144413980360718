import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ImplyResult from '../components/ImplyResult';
import dataURL from '../lib/dataURL';
import { ALERT } from '../lib/dataWord';
import { impResultWrite, initialize, setResult } from '../modules/Imply';

const ImplyResultContainer = () => {
    const {
        results
    } = useSelector(({Imply}) => ({
        results: Imply.results
    }))
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const xlHeader = [
        'no',
        'area', // 병합된 셀
        'contranct_no',
        'addr',
        'empty',
        'name',
        'jumin_num',
        'relation',
        'request_date',
        'receive_date',
        'error',
        'joint_house',
        'joint_house_dong',
        'joint_house_ho',
        'com_reason',
        'com_date',
        'last_change_date',
        'report_date',
        'last_change_reason',
        'move_in_date',
        'change_date',
        'change_reason',
        'reg_status'
    ];
    const opt = {
        header: xlHeader, 
        defval: "-", 
        raw: false, 
        dateNF: "yyyy-mm-dd",
        range: 6
    }

    //----------------------------------------------------------------------
    // 상단 버튼

    // 조회로 이동
    const onClickSearch = () => {
        navigate(dataURL.AuditListPage)
    }

    // 등본요청 결과 업로드
    const onWrite = () => {
        if(window.confirm('등본결과를 업로드 하사겠습니까?')) {
            if(results.length > 0) {
                dispatch(impResultWrite({
                    results
                }))
            }
            else {
                ALERT("엑셀이 업로드 되지 않았습니다.")
            }
        }
    }
    //----------------------------------------------------------------------


    //----------------------------------------------------------------------
    // 엑셀

    // 엑셀 업로드, 변환
    const onChange = (e) => {
        const input = e.target;

        const reader = new FileReader();
        reader.onload = (e) => {
            let fileData = reader.result;
            let wb = XLSX.read(fileData, {type: 'binary'});

            if(wb.SheetNames.some((el) => el === '등본요청결과')) {
                wb.SheetNames.forEach((el) => {
                    if(el === '등본요청결과') {
                        let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[el], opt);

                        dispatch(setResult({results: rowObj}));
                    }
                })
            }
            else {
                ALERT('엑셀에 등본요청결과시트가 감지되지 않았습니다.')
            }
        }
        reader.readAsBinaryString(input.files[0]);
    }
    //----------------------------------------------------------------------



    useEffect(() => {
        return () => dispatch(initialize())
    }, [dispatch])

    const propDatas = {
        results,

        onChange,
        onWrite,
        onClickSearch
    }
    return (
        <>
            <ImplyResult {...propDatas}/>
        </>
    );
}

export default ImplyResultContainer;