import React from 'react';
import styled from 'styled-components';

const ApplyDocMgtBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 20px 50px 0;
    box-sizing: border-box;

    .dkgreen-btn{
        margin-bottom: 15px;
    }

    .containerDiv{
        display: flex;
        align-items: flex-start;
        height: 78vh;
        gap: 20px;
    }
    .containerDiv div {
        height: 100%;
        overflow-y: auto;
        border: 1px solid #ccc;
    }
    .containerDiv div:first-child{
        width: 30%;
    }
    .containerDiv div:last-child{
        width: 70%;
    }
`;

const ApplyDocMgt = (props) => {
    const {
        classList,
        codeList,
        cboDoc,
        dataEntitleCalc,

        onRowChange,
        onRowClick,
        onAddRow,
        onRemoveRow,
        onRowSort,
        onSave,
    } = props;

    return (
        <ApplyDocMgtBlock>
            <button onClick={onSave} className="dkgreen-btn">저장</button>

            <div className='containerDiv'>
                <div>
                    <table className='border-tt'>
                        <thead>
                            <tr>
                                <th colSpan={2}>지원유형</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classList.map((item) => (
                                <tr key={item.sub_code} onClick={onRowClick.bind(this, item)}>
                                    <td>{item.code_name}</td>
                                    <td>
                                        <select name="memo" id="memo" value={item.memo} disabled={true}>
                                            {dataEntitleCalc.map((item) => (
                                                <option value={item.codeIdx} key={item.codeIdx}>{item.typeName}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr> 
                            ))}
                        </tbody>
                    </table>
                </div>
                
                <div>
                    <table className='border-tt'>
                        <thead>
                            <tr>
                                <th><button onClick={onAddRow} className="add-btn">추가</button></th>
                                <th>지원유형</th>
                                <th>제출서류</th>
                                <th>비고</th>
                                <th>순서</th>
                                <th>사용여부</th>
                            </tr>
                        </thead>
                        <tbody>
                            {codeList.map((item) => (
                                <tr key={item.idx}>
                                    <td>{item.isNewRow ? <button onClick={() => onRemoveRow(item)} className="del-btn">삭제</button> : null}</td>
                                    <td><input type="text" name="main_code" id="main_code" value={item.main_code || ""} disabled={true} /></td>
                                    <td>
                                        <select name="sub_code" id="sub_code" value={item.sub_code} onChange={(e) => onRowChange(e, item)} disabled={item.isNewRow ? false : true}>
                                            {cboDoc.map((doc) => (
                                                <option value={doc.sub_code} key={doc.sub_code}>{doc.code_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td><input type="text" name="memo" id="memo" value={item.memo || ""} onChange={(e) => onRowChange(e, item)} /></td>
                                    <td>
                                        <button onClick={onRowSort.bind(this, item, 'UP')} className="up-btn">▲</button>
                                        <button onClick={onRowSort.bind(this, item, 'DOWN')} className="down-btn">▼</button>
                                    </td>
                                    <td>
                                        <select name="use_flag" id="use_flag" value={item.use_flag} onChange={(e) => onRowChange(e, item)}>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </ApplyDocMgtBlock>
    );
}

export default ApplyDocMgt;