import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import WinnerRegisterListContainer from '../containers/WinnerRegisterListContainer';

const WinnerRegisterListPage = () => {
    return (
        <>
            <HeaderContainer />
            <WinnerRegisterListContainer />
        </>
    );
}

export default WinnerRegisterListPage;