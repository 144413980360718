import React from 'react';
import styled from 'styled-components';
import AddressHistoryPrint from '../components/DocPrint/AddressHistoryPrint';
import ApplicationFormPrint from '../components/DocPrint/ApplicationFormPrint';
import BuildingMngPrint from '../components/DocPrint/BuildingMngPrint';
import BuildsMngPrint from '../components/DocPrint/BuildsMngPrint';
import BuildPricePrint from '../components/DocPrint/BuildPricePrint';
// import CopyRegisterPrint from '../components/DocPrint/CopyRegisterPrint';
import LandPricePrint from '../components/DocPrint/LandPricePrint';
import MultiBPricePrint from '../components/DocPrint/MultiBPricePrint';
import RightsReviewPrint from '../components/DocPrint/RightsReviewPrint';
import ContractPrintSetModal from '../components/Modal/ContractPrintSetModal';
import FeedbackPrint from './DocPrint/FeedbackPrint';
import PriorityPrint from './DocPrint/PriorityPrint';
import { Helmet } from 'react-helmet';

const ImgWrawpper = styled.div`
    position:relative;
    height : 1020px;
    page-break-before:'always';

    img{
        display: block;
        max-width: 100vw;
        max-height: 100vh;
        transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%));
    }
    
    .rotate-90,
    .rotate-270 {
        max-width: 100vh;
        max-height: 100vw;
    }
    
    .rotate-90 { transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(90deg); }
    .rotate-180 { transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(180deg); }
    .rotate-270 { transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%)) rotate(270deg); }
`;

const ContractDocPrint = (props) => {
    const {
        contractPrintModal,
        docPrintRef,
        docImages,
        compo,

        winner,
        feedback,
        rent,
        write,
        work,
        // rentWrite,
        // work,
        // rentWork,
        // doc014, //  등기부등본
        doc015, //  건축물관리대장
        doc016, //  주택가격
        doc017, //  신.구주소
        contract,
    } = props;

    const docType = (doc) => {
        switch(doc) {
            case 'DOC005' : case'TXT02':
                //권리분석 검토서
                return <RightsReviewPrint {...props}/>;
            case 'DOC006' : case 'TXT01':
                //지원 신청서
                return <ApplicationFormPrint winner={winner} write={write} priority={rent} work={work}/>;
            case 'DOC015' : case 'TXT04':
                //건축물 관리대장
                return (doc015 && Array.isArray(doc015) && doc015[0] &&
                        (doc015[0].HO_COUNT === '' ?
                        <BuildsMngPrint  _doc015={doc015[0]}/>
                        :
                        <BuildingMngPrint _doc015={doc015[0]}/>))
            case 'DOC016' : case 'TXT05':
                // 주택가격정보
                return (doc016 && doc016.map((_doc016, idx) => {
                    if( _doc016 && _doc016.sub_type ) {
                        switch (_doc016.sub_type) {
                            case '3':
                                return <MultiBPricePrint _doc016={_doc016.jsonData} work={work} key={idx}/>
                            case '4':
                                return <BuildPricePrint _doc016={_doc016.jsonData} work={work} key={idx}/>
                            case '5':
                                return <LandPricePrint _doc016={_doc016.jsonData} work={work} key={idx}/>
                            default:
                                return '';
                        }
                    }
                    return '';
                }))
            case 'DOC017' : case 'TXT06':
                // 신/구 주소
                return (
                    doc017 && doc017.map((_doc017, idx) => (
                        <AddressHistoryPrint _doc017={_doc017} key={idx}/>
                    ))
                )
            case 'DOC021' : 
                // 피드백 요청서
                return (
                    feedback && feedback.map((_feedback, idx)=> (
                        <FeedbackPrint feedback={_feedback} key={idx} />
                    ))
                )
            case 'DOC022' :
                // 선순위 임차보증금
                return (
                    <PriorityPrint priority={rent} contract={contract}/>
                )
            default :
                return (docImages.map((img, idx) => {
                        if(img.docCode === doc){
                            return (
                                <ImgWrawpper key={'print-img'+idx}>
                                    <img src={img.imgSrc} alt="" className={'rotate-' + img.rotation} />
                                </ImgWrawpper>
                            )
                        }else{
                            return '';
                        }
                    }
                ))
        }
    }

    return (
        <>
            {contractPrintModal ?
                <>
                    <Helmet>
                        <title>{winner && Array.isArray(winner) && (winner.length > 0) && contract && contract.sigungu ? `${contract.sigungu}_${winner[0].winner_name}${winner[0].winner_no}` : '권리분석'}</title>
                    </Helmet>
                    <ContractPrintSetModal {...props} />
                </>
                :
                <Helmet>
                    <title>집클릭</title>
                </Helmet>
            }
            <div className="qqqqq" style={{display: 'none'}}>
                <div ref={docPrintRef}>
                    {
                        compo.map((c, idx)=>(
                            <div key={idx}>
                                {docType(c)}
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default ContractDocPrint;