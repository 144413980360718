import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportList from '../components/ReportList';
import { changeValue, getContent, getList, initialize, setPaging } from '../modules/reportList';

const ReportListContainer = () => {
    const [isPostback, setIsPostback] = useState(true);     //  최초 조회
    const [isShowRpt, setIsShowRpt] = useState(false);       //  Report Popup

    const dispatch = useDispatch();
    const {
        isSaveOk,

        //  검색조건
        from_date,
        to_date,
        page,

        list,

        //  Modal Data
        rptMain,
        rptDtlOwner,
        rptDtlSeizure,
        rptDtlResistance,
        rptDtlEtc,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,
     } = useSelector(({ reportList }) => ({
        isSaveOk: reportList.isSaveOk,

        from_date: reportList.from_date,
        to_date: reportList.to_date,
        page: reportList.page,

        list: reportList.list,

        //  Modal Data
        rptMain: reportList.rptMain,
        rptDtlOwner: reportList.rptDtlOwner,
        rptDtlSeizure: reportList.rptDtlSeizure,
        rptDtlResistance: reportList.rptDtlResistance,
        rptDtlEtc: reportList.rptDtlEtc,

        //  페이징
        pageCount: reportList.pageCount,
        max: reportList.max,
        step: reportList.step,
        currentPage: reportList.currentPage,
    }))

    const onChange = (e) => {
        dispatch(changeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }

    const onSearch = useCallback(() => {
        dispatch(getList({
            from_date: from_date,
            to_date: to_date,
            page: page,
        }))
    }, [dispatch, from_date, to_date, page])
    
    const onSubmit = (e) => {
        e.preventDefault();
        
        onSearch();
    }

    // 페이징   --------------------------------------------
    const onClickNum = (num) => {
        if(currentPage !== num) {
            dispatch(changeValue({key: "page", value: num}));
            dispatch(changeValue({key: "currentPage", value: num}));
            
            onSearch();
        }
    }
    const onClickNext = () => {
        if(Math.ceil(pageCount / max) >= step + 1 ) {
            dispatch(setPaging({
                key: "step",
                value: "next",
            }))
        }
    }
    const onClickPrev = () => {
        if(step -1 > 0) {
            dispatch(setPaging({
                key: "step",
                value: "prev",
            }))
        }
    }
    //  --------------------------------------------    페이징

    const onClickRow = (item, e) => {
        setIsShowRpt(true);
        dispatch(getContent(item.report_id));
    }
    const onClose = () => {
        setIsShowRpt(false);
    }

    useEffect(() => {
        if(isSaveOk) {
            onSearch();
        }
    }, [dispatch, isSaveOk, onSearch])

    useEffect(() => {
        if(isPostback) {
            setIsPostback(false);
            onSearch();
        }
    }, [isPostback, onSearch])

    useEffect(() => {
        return () => {
            dispatch(initialize())
        }
    }, [dispatch])

    const propDatas = {
        //  검색조건
        from_date,
        to_date,

        list,

        //  Modal Data
        rptMain,
        rptDtlOwner,
        rptDtlSeizure,
        rptDtlResistance,
        rptDtlEtc,

        //  페이징
        pageCount,
        max,
        step,
        currentPage,

        onChange,
        onSubmit,
        
        onClickNum,
        onClickNext,
        onClickPrev,
        
        isShowRpt,
        onClickRow,
        onClose,
    }
    return (
        <>
            <ReportList {...propDatas}/>
        </>
    );
}

export default ReportListContainer;