import React from 'react';

const AddressHistoryPrint = ({_doc017}) => {
    if(!_doc017){
        return (
            <>
            <div style={{pageBreakBefore:'always'}}>
                API 정상동작 되지 않음 ({_doc017})
            </div>
            </>
        )
    }else{
    return (
        <div className='tableWrap address' style={{pageBreakBefore:'always'}}>
            <div className='viewerContainer borderwrap'>
                <div className='title'>검색어 <span>"{_doc017.jibunAddr}"</span> 대한 검색 결과입니다.</div>
                <div className='adTable'>
                    <div><span>우편번호 :</span> {_doc017.zipNo} </div>
                    <div><span>신주소 :</span> {_doc017.roadAddr}</div>
                    <div><span>구주소 :</span> {_doc017.jibunAddr}</div>
                </div>
                {/* <table>
                    <tbody>
                        <tr>
                            <td>admCd</td>
                            <td>{_doc017.admCd}</td>
                        </tr>
                        <tr>
                            <td>bdKdcd</td>
                            <td>{_doc017.bdKdcd}</td>
                        </tr>
                        <tr>
                            <td>bdMgtSn</td>
                            <td>{_doc017.bdMgtSn}</td>
                        </tr>
                        <tr>
                            <td>bdNm</td>
                            <td>{_doc017.bdNm}</td>
                        </tr>
                        <tr>
                            <td>buldMnnm</td>
                            <td>{_doc017.buldMnnm}</td>
                        </tr>
                        <tr>
                            <td>buldSlno</td>
                            <td>{_doc017.buldSlno}</td>
                        </tr>
                        <tr>
                            <td>detBdNmList</td>
                            <td>{_doc017.detBdNmList}</td>
                        </tr>
                        <tr>
                            <td>dong</td>
                            <td>{_doc017.dong}</td>
                        </tr>
                        <tr>
                            <td>emdNm</td>
                            <td>{_doc017.emdNm}</td>
                        </tr>
                        <tr>
                            <td>emdNo</td>
                            <td>{_doc017.emdNo}</td>
                        </tr>
                        <tr>
                            <td>engAddr</td>
                            <td>{_doc017.engAddr}</td>
                        </tr>
                        <tr>
                            <td>floor</td>
                            <td>{_doc017.floor}</td>
                        </tr>
                        <tr>
                            <td>hemdNm</td>
                            <td>{_doc017.hemdNm}</td>
                        </tr>
                        <tr>
                            <td>ho</td>
                            <td>{_doc017.ho}</td>
                        </tr>
                        <tr>
                            <td>hstryYn</td>
                            <td>{_doc017.hstryYn}</td>
                        </tr>
                        <tr>
                            <td>jibunAddr</td>
                            <td>{_doc017.jibunAddr}</td>
                        </tr>
                        <tr>
                            <td>jibunMakeAddr</td>
                            <td>{_doc017.jibunMakeAddr}</td>
                        </tr>
                        <tr>
                            <td>liNm</td>
                            <td>{_doc017.liNm}</td>
                        </tr>
                        <tr>
                            <td>lnbrMnnm</td>
                            <td>{_doc017.lnbrMnnm}</td>
                        </tr>
                        <tr>
                            <td>lnbrSlno</td>
                            <td>{_doc017.lnbrSlno}</td>
                        </tr>
                        <tr>
                            <td>mtYn</td>
                            <td>{_doc017.mtYn}</td>
                        </tr>
                        <tr>
                            <td>relJibun</td>
                            <td>{_doc017.relJibun}</td>
                        </tr>
                        <tr>
                            <td>rn</td>
                            <td>{_doc017.rn}</td>
                        </tr>
                        <tr>
                            <td>rnMgtSn</td>
                            <td>{_doc017.rnMgtSn}</td>
                        </tr>
                        <tr>
                            <td>roadAddr</td>
                            <td>{_doc017.roadAddr}</td>
                        </tr>
                        <tr>
                            <td>roadAddrPart1</td>
                            <td>{_doc017.roadAddrPart1}</td>
                        </tr>
                        <tr>
                            <td>roadAddrPart2</td>
                            <td>{_doc017.roadAddrPart2}</td>
                        </tr>
                        <tr>
                            <td>sggNm</td>
                            <td>{_doc017.sggNm}</td>
                        </tr>
                        <tr>
                            <td>siNm</td>
                            <td>{_doc017.siNm}</td>
                        </tr>
                        <tr>
                            <td>udrtYn</td>
                            <td>{_doc017.udrtYn}</td>
                        </tr>
                        <tr>
                            <td>zipNo</td>
                            <td>{_doc017.zipNo}</td>
                        </tr>
                    </tbody>
                </table> */}
                {
                //<h4 className='mb40'>신/구 주소</h4>
                //<div style={{textAlign: 'center', marginBottom:'40px'}}>검색어<span style={{fontSize:'24px'}}>"화곡동 391-54"</span>에 대한 검색결과 총 1건 입니다.</div>
                //    _doc017 &&
                //    (
                //        _doc017.map((item, idx) => (
                //            <div key={idx} className="apiTable">
                //                {/* <div className='mb40'>{idx === 0 ? "도로명 주소" : "지번 주소"}</div> */}
                //                <div>
                //                    {/* <input type='radio'/>
                //                    <label>도로명주소</label>
                //                    <input type='radio'/>
                //                    <label>지번주소</label> */}
                //                </div>
                //                <div className='mb40'>입력한 키워드가 선택한 항목에 포함되는 주소정보가 우선정렬 되고, 도로명주소순으로 정렬됩니다.</div>
                //                <div>
                //                    <div>
                //                        <div>07759</div>
                //                    </div>
                //                    <div>
                //                        <div>서울특별시 강서구 곰달래로27길 5(화곡동, 현대하이츠빌라)</div>
                //                    </div>
                //                    <div>
                //                        <div>{item.REAL_ESTATE_ADDRESS}</div>
                //                    </div>
                //                </div>
                //                <div>
                //                    <div>상세주소(동·층·호)보기</div>
                //                    <div style={{border: '1px solid black', padding:'10px'}}>
                //                        <div style={{border: '1px solid black'}}>
                //                            <div className="pl10" style={{borderBottom: '1px solid black'}}>"동" 표기없음</div>
                //                            <div className="pl10" style={{borderBottom: '1px solid black'}}>지하 1층</div>
                //                            <div className="pl10">02호</div>
                //                        </div>
                //                        <div>{item.REAL_ESTATE_ADDRESS}</div>
                //                        <div>※ 위 주소는 '호'에 '층' 정보가 포함되어 있어, 층을 생략하여 표기할 수 있습니다.</div>
                //                    </div>
                //                </div>
                //            </div>
                //        ))
                //    )
                }
                {/* 신/구 주소 변경 전 */}
                {/* {
                    _doc017 &&
                    (
                        _doc017.map((item, idx) => (
                            <table key={idx} className="apiTable">
                                <tbody>
                                    <tr>
                                        <th colSpan={2}>{idx === 0 ? "도로명 주소" : "지번 주소"}</th>
                                    </tr>
                                    <tr>
                                        <th>REAL_ESTATE_STATE</th>
                                        <td>{item.REAL_ESTATE_STATE}</td>
                                    </tr>
                                    <tr>
                                        <th>REAL_ESTATE_ADDRESS</th>
                                        <td>{item.REAL_ESTATE_ADDRESS}</td>
                                    </tr>
                                    <tr>
                                        <th>REAL_ESTATE_NO</th>
                                        <td>{item.REAL_ESTATE_NO}</td>
                                    </tr>
                                    <tr>
                                        <th>REAL_ESTATE_OWNER</th>
                                        <td>{item.REAL_ESTATE_OWNER}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ))
                    )
                } */}
            {/* 신/구 주소 변경 전 */}
            </div>
        </div>
    );
    }

};

export default AddressHistoryPrint;