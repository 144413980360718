import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import * as authAPI from '../../lib/api/auth';
import * as scheAPI from '../../lib/api/sche';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enWriteAgree/INITIALIZE';
const CHANGE_VALUE = 'enWriteAgree/CHANGE_VALUE';
const CHANGE_ROW_VALUE = 'enWriteAgree/CHANGE_ROW_VALUE';
const INIT_AGREE_WINNER = 'enWriteAgree/INIT_AGREE_WINNER';
const [CERT_REQ, CERT_REQ_SUCCESS, CERT_REQ_FAILURE] = createRequestActionTypes('enWriteAgree/CERT_REQ');
const [CERT_CHK, CERT_CHK_SUCCESS, CERT_CHK_FAILURE] = createRequestActionTypes('enWriteAgree/CERT_CHK');
const [SAVE_AGREE, SAVE_AGREE_SUCCESS, SAVE_AGREE_FAILURE] = createRequestActionTypes('enWriteAgree/SAVE_AGREE');
const [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE] = createRequestActionTypes('enWriteAgree/SEND_SMS');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const changeRowValue = createAction(CHANGE_ROW_VALUE, ({phone, key, value}) => ({phone, key, value}));
export const initAgreeWinner = createAction(INIT_AGREE_WINNER, (data) => data);
export const certReq = createAction(CERT_REQ, (body) => (body));
export const certChk = createAction(CERT_CHK, (body) => (body));
export const saveAgree = createAction(SAVE_AGREE, (body) => (body));
export const sendSms = createAction(SEND_SMS, (body) => (body));

const certReqSaga = createRequestSaga(CERT_REQ, authAPI.cert_req);
const certChkSaga = createRequestSaga(CERT_CHK, authAPI.cert_chk);
const saveAgreeSaga = createRequestSaga(SAVE_AGREE, entitleAPI.enreq);
const sendSmsSaga = createRequestSaga(SEND_SMS, scheAPI.smsSend);

export function* enWriteAgreeSaga() {
    yield takeLatest(CERT_REQ, certReqSaga);
    yield takeLatest(CERT_CHK, certChkSaga);
    yield takeLatest(SAVE_AGREE, saveAgreeSaga);
    yield takeLatest(SEND_SMS, sendSmsSaga);
}

const initialState = {
    isSendOK: false,

    agreeWinner: [],

    enWriteAgree: null,
    error: null
};

const enWriteAgree = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [INIT_AGREE_WINNER]: (state, { payload }) => {
            let _agreeWinner = [];
            payload.forEach(item => {
                _agreeWinner.push({
                    idx: item.idx,
                    name: item.winner_name,
                    phone: item.winner_phone,
                    agree_code: "",
                    agree_complete: false,
                });
            });
            return {
                ...state,
                agreeWinner: _agreeWinner,
            }
        },
        [CHANGE_ROW_VALUE]: (state, { payload: {phone, key, value}}) => {
            const _agreeWinner = state.agreeWinner.map((item) =>
                item.phone === phone ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                agreeWinner: _agreeWinner,
            };
        },

        [SAVE_AGREE_SUCCESS]: (state, { payload }) => {
            ALERT("지원서 신청이 완료되었습니다.", payload);
            return ({
                ...state,
                error: null,
            })
        },
        [SAVE_AGREE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        [CERT_REQ_SUCCESS]: (state, { payload }) => {
            const _agreeWinner = state.agreeWinner.map((item) =>
                item.phone === payload ? { ...item, agree_complete: false } : item,
            );
            return ({
                ...state,
                agreeWinner: _agreeWinner,
                error: null,
            })
        },
        [CERT_REQ_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        [CERT_CHK_SUCCESS]: (state, { payload }) => {
            const _agreeWinner = state.agreeWinner.map((item) =>
                item.phone === payload ? { ...item, agree_complete: true } : item,
            );
            return ({
                ...state,
                agreeWinner: _agreeWinner,
                error: null,
            })
        },
        [CERT_CHK_FAILURE]: (state, { payload: error }) => {
            ALERT('인증코드가 일치하지 않습니다.');
            return ({
                ...state,
                error,
            })
        },

        [SEND_SMS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                isSendOK: true,
                error: null,
            })
        },
        [SEND_SMS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default enWriteAgree;