import React from 'react';
import dataWORD from '../../lib/dataWord';

const BuildingMngPrint = ({_doc015}) => {
    if( !_doc015 ) {
        return (
            <>
            <div style={{pageBreakBefore:'always'}}>
                API 오류
            </div>
            </>
        )
    }
    if( _doc015.ERRCODE !== '00000000' ) {
        return <div style={{pageBreakBefore:'always'}}>{`${_doc015.ERRMSG}(ERRCODE: ${_doc015.ERRCODE})`}</div>
    }
    return (
        <div className='tableWrap build' style={{pageBreakBefore:'always'}}>
            <div className='viewerContainer borderwrap bd0'>
            <h4 className='text-c' style={{display: 'block'}}>일반건축물대장</h4>
                <table className='bulidNum mt10'>
                        <thead>
                            <tr>
                                <th rowSpan={2} style={{width : '8%'}}>고유번호</th>
                                <td className='vertical-m text-c' rowSpan={2} style={{width : '37%'}}>{_doc015.PRIMARY_NUM}</td>
                                {/* <th>민원24접수번호</th>
                                <td>{_doc015.RECEIPT_NUM}</td> */}
                                <th className='bdb0' style={{width : '15%'}}>명칭</th>
                                <th className='bdb0' style={{width : '15%'}}>호수/가구수/세대수</th>
                            </tr>
                            <tr>
                                <td className='bdt0' style={{width : '15%'}}>{_doc015.DESIGNATION}</td>
                                <td className='bdt0' style={{width : '15%'}}>{_doc015.HO_COUNT}/{_doc015.GAGU_COUNT}/{_doc015.GENERATION_COUNT}</td>
                            </tr>
                        </thead>
                    </table>
                    <table className='bulidNum mt5'>
                        <thead>
                            <tr>
                                <th className='bdb0' rowSpan={2} style={{width : '5%'}}>대지위치</th>
                                <td className='vertical-m text-c bdb0' rowSpan={2} style={{width : '15%'}}>{_doc015.GROUND_LOCATION}</td>
                                <th className='bdb0' style={{width:'10%'}}>지번</th>
                                <th className='bdb0' rowSpan={2} style={{width : '5%'}}>도로명주소</th>
                                <td className='vertical-m text-c bdb0' rowSpan={2} style={{width : '15%'}}>{_doc015.ROAD_ADDRESS}</td>
                            </tr>
                            <tr>
                                <td className='bdt0 bdb0' style={{width:'10%'}}>{_doc015.JIBEON}</td>
                            </tr>
                        </thead>
                    </table>
                    <table className='bulidType'>
                        <tbody>
                            <tr>
                                <th className="bdb0" style={{width:'20%'}}>※ 대지면적</th> 
                                <th className="bdb0" style={{width:'20%'}}>연면적</th>
                                <th className="bdb0" style={{width:'20%'}}>※ 지역</th>
                                <th className="bdb0" style={{width:'20%'}}>※ 지구</th>
                                <th className="bdb0" style={{width:'20%'}}>※ 구역</th>
                            </tr>
                            <tr>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.EARTH_AREA ? _doc015.EARTH_AREA : <span>m²</span>}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.YEONG_AREA ? _doc015.YEONG_AREA : <span>m²</span>}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.AREA}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.EARTH}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.ZONE}</td>
                            </tr>
                            <tr>
                                <th>건축면적</th>
                                <th>용적률 산정용 연면적</th>
                                <th>주구조</th>
                                <th>주용도</th>
                                <th>층수</th>
                            </tr>
                            <tr>
                                <td>{_doc015.BUILDING_AREA ? _doc015.BUILDING_AREA : <span>m²</span>}</td>
                                <td>{_doc015.FIOOR_RATIO_AREA ? _doc015.FIOOR_RATIO_AREA : <span>m²</span>}</td>
                                <td>{_doc015.STRUCTURE}</td>
                                <td>{_doc015.PURPOSE}</td>
                                <td>{_doc015.FIOORS_NUM}</td>
                            </tr>
                            <tr>
                                <th>※ 건폐율</th>
                                <th>※ 용적률</th>
                                <th>높이</th>
                                <th>지붕</th>
                                <th>부속건축물</th>
                            </tr>
                            <tr>
                                <td>{_doc015.BUILDING_RATIO ? _doc015.BUILDING_RATIO : <span>%</span>}</td>
                                <td>{_doc015.FIOOR_RATIO ? _doc015.FIOOR_RATIO : <span>%</span>}</td>
                                <td>{_doc015.HEIGHT ? _doc015.HEIGHT : <span>m²</span>}</td>
                                <td>{_doc015.ROOF}</td>
                                <td>{_doc015.ANNEXED_BUILDING}</td>
                            </tr>
                            <tr>
                                <th>※ 조경면적</th>
                                <th>※ 공개 공지ㆍ공간 면적</th>
                                <th>※ 건축선 후퇴면적</th>
                                <th>건축선 후퇴 거리</th>
                            </tr>
                            <tr>
                                <td>{_doc015.LANDSCAPE ? _doc015.LANDSCAPE : <span>m²</span>}</td>
                                <td>{_doc015.PUBLIC_NOTICE ? _doc015.PUBLIC_NOTICE : _doc015.SPACE_AREA ? <span>m² ㆍ</span> : ''}{_doc015.SPACE_AREA ? _doc015.SPACE_AREA : <span>m²</span>}</td>
                                <td>{_doc015.BUILDING_LINE_RETREAT_ARE ? _doc015.BUILDING_LINE_RETREAT_AREA : <span>m²</span>}</td>
                                <td colSpan={3}>{_doc015.BUILDING_LINE_RETREAT_LEN ? _doc015.BUILDING_LINE_RETREAT_LEN : <span>m</span>}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='bulidReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={5}>건축물현황</th>
                                </tr>
                                <tr>
                                    <th>구분</th>
                                    <th>층별</th>
                                    <th>구조</th>
                                    <th>용도</th>
                                    <th>면적</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA && Array.isArray(_doc015.RESP_DATA) && (_doc015.RESP_DATA.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.GUBUN}</td>
                                                        <td>{item.LAYER}</td>
                                                        <td>{item.RESCUE}</td>
                                                        <td>{item.USAGE}</td>
                                                        <td>{item.AREA}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    <div className='ownerReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={6}>소유자현황</th>
                                </tr>
                                <tr>
                                    <th>성명(명칭)</th>
                                    <th>{dataWORD.BIRTHDATE}</th>
                                    <th>주소</th>
                                    <th>소유권지분</th>
                                    <th>변동일</th>
                                    <th>변동원인</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA2 && Array.isArray(_doc015.RESP_DATA2) && (_doc015.RESP_DATA2.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA2.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.OWNER_NAME}</td>
                                                        <td>{item.OWNER_REG}</td>
                                                        <td>{item.OWNER_ADD}</td>
                                                        <td>{item.OWNER_SHARE}</td>
                                                        <td>{item.OWNER_CHANGE_DATE}</td>
                                                        <td>{item.OWNER_CHANGE_CAUSE}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    
                    <div className='change mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>변동사항</th>
                                </tr>
                                <tr>
                                    <th>변동일</th>
                                    <th>변동내용 및 원인</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA4 && Array.isArray(_doc015.RESP_DATA4) && (_doc015.RESP_DATA4.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA4.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.CHANGES_DATA}</td>
                                                        <td className='text-l pl10'>{item.CHANGES_CAUSE}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    <div className='etc mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th>그 밖의 기재사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{_doc015.ETC}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>





            {/* <div>
                <div>
                    <span>고유번호</span>
                    <span>{_doc015.PRIMARY_NUM}</span>
                </div>
                <div>
                    <span>민원24접수번호</span>
                    <span>{_doc015.RECEIPT_NUM}</span>
                </div>
                <div>
                    <span>명칭</span>
                    <span>{_doc015.DESIGNATION}</span>
                </div>
                <div>
                    <span>호수/가구수/세대수</span>
                    <span>{_doc015.HO_COUNT}</span>/
                    <span>{_doc015.GAGU_COUNT}</span>/
                    <span>{_doc015.GENERATION_COUNT}</span>
                </div>
            </div>
            <div>
                <div>
                    <span>대지위치</span>
                    <span>{_doc015.GROUND_LOCATION}</span>
                </div>
                <div>
                    <span>지번</span>
                    <span>{_doc015.JIBEON}</span>
                </div>
                <div>
                    <span>도로명주소</span>
                    <span>{_doc015.ROAD_ADDRESS}</span>
                </div>
            </div>
            <div>
                <div>대지면적</div>
                <div>연면적</div>
                <div>지역</div>
                <div>지구</div>
                <div>구역</div>
                <div>건축면적</div>
                <div>용적률 산정용 연면적</div>
                <div>주구조</div>
                <div>주용도</div>
                <div>층수</div>
                <div>건폐율</div>
                <div>용적률</div>
                <div>높이</div>
                <div>지붕</div>
                <div>부속건축물</div>
                <div>조경면적</div>
                <div>공개 공지 공간 면적</div>
                <div>건축선 후퇴면적</div>
                <div>건축선 후퇴거리</div>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <td colSpan={5}>건축물현황</td>
                        </tr>
                        <tr>
                            <td>구분</td>
                            <td>층별</td>
                            <td>구조</td>
                            <td>용도</td>
                            <td>면적</td>
                        </tr>
                    </thead>
                    {
                        _doc015.RESP_DATA && Array.isArray(_doc015.RESP_DATA) && (_doc015.RESP_DATA.length > 0) &&
                        (
                            <tbody>
                                {
                                    _doc015.RESP_DATA.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{item.GUBUN}</td>
                                                <td>{item.LAYER}</td>
                                                <td>{item.RESCUE}</td>
                                                <td>{item.USAGE}</td>
                                                <td>{item.AREA}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        )

                    }
                </table>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <td colSpan={6}>소유자현황</td>
                        </tr>
                        <tr>
                            <td>성명(명칭)</td>
                            <td>주민등록번호</td>
                            <td>주소</td>
                            <td>소유권지분</td>
                            <td>변동일</td>
                            <td>변동원인</td>
                        </tr>
                    </thead>
                    {
                        _doc015.RESP_DATA2 && Array.isArray(_doc015.RESP_DATA2) && (_doc015.RESP_DATA2.length > 0) &&
                        (
                            <tbody>
                                {
                                    _doc015.RESP_DATA2.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{item.OWNER_NAME}</td>
                                                <td>{item.OWNER_REG}</td>
                                                <td>{item.OWNER_ADD}</td>
                                                <td>{item.OWNER_SHARE}</td>
                                                <td>{item.OWNER_CHANGE_DATE}</td>
                                                <td>{item.OWNER_CHANGE_CAUSE}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        )

                    }
                </table>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <td colSpan={2}>변동사항</td>
                        </tr>
                        <tr>
                            <td>변동일</td>
                            <td>변동내용 및 원인</td>
                        </tr>
                    </thead>
                    {
                        _doc015.RESP_DATA4 && Array.isArray(_doc015.RESP_DATA4) && (_doc015.RESP_DATA4.length > 0) &&
                        (
                            <tbody>
                                {
                                    _doc015.RESP_DATA4.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{item.CHANGES_DATA}</td>
                                                <td>{item.CHANGES_CAUSE}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        )

                    }
                </table>
            </div>
            <div>
                <span>그 밖의 기재사항</span>
                <span>{_doc015.ETC}</span>
            </div> */}
            </div>
        </div>
    );
}

export default BuildingMngPrint;