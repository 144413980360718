import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import MaintainRegisterContainer from '../containers/MaintainRegisterContainer';

const MaintainRegisterPage = () => {
    return (
        <>
            <HeaderContainer />
            <MaintainRegisterContainer/>
        </>
    );
}

export default MaintainRegisterPage;