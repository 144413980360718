import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ImplyAuditRequestContainer from '../containers/ImplyAuditRequestContainer';

const ImplyAuditRequestPage = () => {
    return (
        <>
            <HeaderContainer/>
            <ImplyAuditRequestContainer />
        </>
    );
}

export default ImplyAuditRequestPage;