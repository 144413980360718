import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addZoom, regAmt, numToHan, parseNumber } from '../common';
import Contract from '../components/Contract';
import { ALERT } from '../lib/dataWord';
import { changeValue, doc_selectFolder, doc_selectImg, getContent, getFiles, imgRotation, initialize, lessorAddRow, lessorChangeValue, lessorRemoveRow, lessorSetLastIdx, removeEntitle, save, saveOnly, setStatusApi, uploadComplete, winnerChangeValue } from '../modules/contract';
import waiting from '../waiting.png';

const ContractContainer = () => {
    const [isShowBroker2, setIsShowBroker2] = useState(false);
    const [isShowDocUpload, setIsShowDocUpload] = useState(false);
    const [isShowDaumPost, setIsShowDaumPost] = useState(false);
    const [isShowEnChange, setIsShowEnChange] = useState(false);
    const [refType, setRefType] = useState(true);
    const [contractPrintModal, setContractPrintModal] = useState(false);
    const [addrLessorIdx, setAddrLessorIdx] = useState(null);   // 임대인 주소찾기시 사용되는 임대인Index
    
    const imgFolderDiv = useRef(null);
    const imgFolderSlider = useRef(null);
    const txtFolderDiv = useRef(null);
    const txtFolderSlider = useRef(null);

    const [selectTxtFolder, setSelectTxtFolder] = useState('TXT01');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state: {docNo}} = location;

    const {
        user
    } = useSelector(({user}) => ({
        user: user.user
    }))
    const {
        doc_status,

        lessorLastIdx,
        lessor,

        lessee_name,
        lessee_addr,
        lessee_reg_no,
        lessee_tel,

        winner,

        broker1_name,
        broker1_owner,
        broker1_addr,
        broker1_reg_no,
        broker1_tel,
        broker2_name,
        broker2_owner,
        broker2_addr,
        broker2_reg_no,
        broker2_tel,

        contract_date,
        contract_location,
        origin_contract_date,
        origin_contract_location,
        real_cont_date,
        cont_status,

        confirm_date,
        against_date,
        delivery_date,
        cont_break,
        wallpaper_flag,

        road_addr,
        jibun_addr,
        house_type,
        house_area,
        ground_area,
        share_area,
        room_count,
        welfare_type,
        collateral_flag,
        collateral_type,
        collateral_amt,
        collateral_date,

        rent_amt,
        rent_amt_Han,
        monthly_rent_amt,
        monthly_rent_amt_Han,
        month_amt_pay_day,
        lease_start_date,
        lease_end_date,
        down_payment,
        down_payment_Han,
        interim_payment,
        interim_payment_Han,
        interim_payment_date,
        balance1,
        balance1_Han,
        balance2,
        balance2_Han,
        balance_payment_date,
        account_num,
        bank_name,
        account_holder,

        remark,

        selectFoler,
        docFolder,
        selectImg,
        docImages,
        // selectTxtFolder,
        txtFolder,

        alreadyFileList,
    } = useSelector(({ contract }) => ({
        doc_status: contract.doc_status,

        lessorLastIdx: contract.lessorLastIdx,
        lessor: contract.lessor,

        lessee_name: contract.lessee_name,
        lessee_addr: contract.lessee_addr,
        lessee_reg_no: contract.lessee_reg_no,
        lessee_tel: contract.lessee_tel,

        winner: contract.winner.map((item) => {
            return ({
                ...item,
                lh_support_amt: String(item.lh_support_amt).replace(regAmt, ","),
                lh_support_amt_Han: !item.lh_support_amt ? "" : numToHan(item.lh_support_amt),
                winner_charges: String(item.winner_charges).replace(regAmt, ","),
                winner_charges_Han: !item.winner_charges ? "" : numToHan(item.winner_charges),
                add_charges: String(item.add_charges).replace(regAmt, ","),
                add_charges_Han: !item.add_charges ? "" : numToHan(item.add_charges),
                monthly_rent: String(item.monthly_rent).replace(regAmt, ","),
                monthly_rent_Han: !item.monthly_rent ? "" : numToHan(item.monthly_rent),
                month_amt_pay_day: !item.month_amt_pay_day ? "" : item.month_amt_pay_day,
            })
        }),

        broker1_name: contract.broker1_name,
        broker1_owner: contract.broker1_owner,
        broker1_addr: contract.broker1_addr,
        broker1_reg_no: contract.broker1_reg_no,
        broker1_tel: contract.broker1_tel,
        broker2_name: !contract.broker2_name ? "" : contract.broker2_name,
        broker2_owner: !contract.broker2_owner ? "" : contract.broker2_owner,
        broker2_addr: !contract.broker2_addr ? "" : contract.broker2_addr,
        broker2_reg_no: !contract.broker2_reg_no ? "" : contract.broker2_reg_no,
        broker2_tel: !contract.broker2_tel ? "" : contract.broker2_tel,

        contract_date: contract.contract_date,
        contract_location: !contract.contract_location ? "" : contract.contract_location,
        origin_contract_date: contract.origin_contract_date,
        origin_contract_location: !contract.origin_contract_location ? "" : contract.origin_contract_location,
        real_cont_date: contract.real_cont_date,
        cont_status: contract.cont_status,

        confirm_date: contract.confirm_date,
        against_date: contract.against_date,
        delivery_date: contract.delivery_date,
        cont_break: !contract.cont_break ? "N" : contract.cont_break,
        wallpaper_flag: !contract.wallpaper_flag ? "N" : contract.wallpaper_flag,

        road_addr: contract.road_addr,
        jibun_addr: contract.jibun_addr,
        house_type: contract.house_type,
        house_area: contract.house_area,
        ground_area: contract.ground_area,
        share_area: contract.share_area,
        room_count: contract.room_count,
        welfare_type: !contract.welfare_type ? "" : contract.welfare_type,
        collateral_flag: !contract.collateral_flag ? "N" : contract.collateral_flag,
        collateral_type: !contract.collateral_type ? "" : contract.collateral_type,
        collateral_amt: !contract.collateral_amt ? "" : String(contract.collateral_amt).replace(regAmt, ","),
        collateral_date: contract.collateral_date,

        rent_amt: !contract.rent_amt ? "" : String(contract.rent_amt).replace(regAmt, ","),
        rent_amt_Han: !contract.rent_amt ? "" : numToHan(contract.rent_amt),
        monthly_rent_amt: !contract.monthly_rent_amt ? "" : String(contract.monthly_rent_amt).replace(regAmt, ","),
        monthly_rent_amt_Han: !contract.monthly_rent_amt ? "" : numToHan(contract.monthly_rent_amt),
        month_amt_pay_day: !contract.month_amt_pay_day ? "" : contract.month_amt_pay_day,
        lease_start_date: contract.lease_start_date,
        lease_end_date: contract.lease_end_date,
        down_payment: !contract.down_payment ? "" : String(contract.down_payment).replace(regAmt, ","),
        down_payment_Han: !contract.down_payment ? "" : numToHan(contract.down_payment),
        interim_payment: !contract.interim_payment ? "" : String(contract.interim_payment).replace(regAmt, ","),
        interim_payment_Han: !contract.interim_payment ? "" : numToHan(contract.interim_payment),
        interim_payment_date: contract.interim_payment_date,
        balance1: !contract.balance1 ? "" : String(contract.balance1).replace(regAmt, ","),
        balance1_Han: !contract.balance1 ? "" : numToHan(contract.balance1),
        balance2: !contract.balance2 ? "" : String(contract.balance2).replace(regAmt, ","),
        balance2_Han: !contract.balance2 ? "" : numToHan(contract.balance2),
        balance_payment_date: contract.balance_payment_date,
        account_num: !contract.account_num ? "" : contract.account_num,
        bank_name: !contract.bank_name ? "" : contract.bank_name,
        account_holder: !contract.account_holder ? "" : contract.account_holder,

        remark: !contract.remark ? "" : contract.remark,

        selectFoler: contract.selectFoler,
        docFolder: contract.docFolder,
        selectImg: contract.selectImg,
        docImages: contract.docImages,
        // selectTxtFolder: contract.selectTxtFolder,
        txtFolder: contract.txtFolder,

        alreadyFileList: contract.alreadyFileList,
    }));

    useEffect(() => {
        dispatch(getContent(docNo));
    }, [dispatch, docNo])

    useEffect(() => {
        if(broker2_name) {
            setIsShowBroker2(true);
        }
    }, [dispatch, doc_status, broker2_name])

    const onChange = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onChangeSave = e => {
        dispatch(
            changeValue({
                key: e.target.name,
                value: e.target.value,
            })
        );

        dispatch(saveOnly({
            doc_id: docNo,
            cont_status: e.target.value,
        }));
    }

    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const onChangeNum = (hanTarget, e) => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                changeValue({
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }

        //  입력된 숫자(금액)을 한글로 변환
        if (hanTarget) {
            dispatch(
                changeValue({
                    key: hanTarget,
                    value: value ? numToHan(value.replace(regAmt, ",")) : "",
                })
            );
        }
    }
    const onChangeRessor = (item, e) => {
        dispatch(
            lessorChangeValue({
                idx: item.lessor_seq,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }
    const onAddRessor = (e) => {
        dispatch(lessorSetLastIdx({value: lessorLastIdx + 1}));
        dispatch(lessorAddRow());
    }
    const onRemoveRessor = (item, e) => {
        dispatch(lessorRemoveRow({idx: item.lessor_seq}));
    }
    const onShowDaumPost = (item, e) => {
        setAddrLessorIdx(item ? item.lessor_seq : null);
        setIsShowDaumPost(!isShowDaumPost);
    }
    const handlePostCode = (data) => {
        if(addrLessorIdx !== null) {
            // dispatch(
            //     lessorChangeValue({
            //         idx: addrLessorIdx,
            //         key: 'lessor_addr',
            //         value: data.roadAddress,
            //     })
            // );
        }
        else {
            dispatch(
                changeValue({
                    key: 'contract_location',
                    value: data.roadAddress,
                })
            );
        }
        setIsShowDaumPost(!isShowDaumPost);
    }
    const onShowBroker2 = () => {
        setIsShowBroker2(!isShowBroker2);
    }
    const onChangeWinner = (item, e) => {
        dispatch(
            winnerChangeValue({
                idx: item.winner_id,
                key: e.target.name,
                value: e.target.value,
            })
        );
    }

    //  계약진행 시작
    const onStartClick = () => {
        if(!window.confirm("계약진행을 시작하시겠습니까?")) {
            return;
        }
        dispatch(
            setStatusApi({
                doc_id: docNo,
                status: "CONTSTRT",
                remark: "",
                lack_reason: '',
            })
        )
    }
    //  출력
    const onPrintClick = () => {
        setContractPrintModal(!contractPrintModal);
    }

    //  파일 업로드
    const onDocUploadClick = () => {
        setIsShowDocUpload(!isShowDocUpload);
    }
    //  파일 업로드 완료
    const onUploadComplete = (uploadFiles) => {
        dispatch(uploadComplete(uploadFiles));
        setIsShowDocUpload(false);
    }
    //  저장
    const onSaveClick = () => {
        // for(let i = 0; i < lessor.length; i++) {
        //     const _lessor = lessor[i];

        //     if(_lessor.use_flag === "N") continue;

        //     if(!_lessor.lessor_name) {
        //         ALERT("입대인" + i + " 성명을 입력해주세요.");
        //         return;
        //     }
        //     if(!_lessor.lessor_addr) {
        //         ALERT("입대인" + i + " 주소를 입력해주세요.");
        //         return;
        //     }
        //     if(!_lessor.lessor_no) {
        //         ALERT("입대인" + i + " 주민등록번호(법인등록번호)를 입력해주세요.");
        //         return;
        //     }
        //     if(!_lessor.lessor_tel) {
        //         ALERT("입대인" + i + " 전화번호를 입력해주세요.");
        //         return;
        //     }
        // }

        // if(isShowBroker2) {
        //     if(!broker2_name) {
        //         ALERT("중개사2 사무소 명칭을 입력해주세요.");
        //         return;
        //     }
        //     if(!broker2_owner) {
        //         ALERT("중개사2 대표자 성명을 입력해주세요.");
        //         return;
        //     }
        //     if(!broker2_addr) {
        //         ALERT("중개사2 사무소 소재지를 입력해주세요.");
        //         return;
        //     }
        //     if(!broker2_reg_no) {
        //         ALERT("중개사2 등록번호를 입력해주세요.");
        //         return;
        //     }
        //     if(!broker2_tel) {
        //         ALERT("중개사2 전화번호를 입력해주세요.");
        //         return;
        //     }
        // }

        // if(!contract_location) {
        //     ALERT("계약예정장소를 입력해주세요.");
        //     return;
        // }
        // if(!contract_date) {
        //     ALERT("계약예정일시을 입력해주세요.");
        //     return;
        // }

        let _winner = [];
        for(let i = 0; i < winner.length; i++) {
            const item = winner[i];

            // if(!item.rent_amt_deadline_y || !item.rent_amt_deadline_m || !item.rent_amt_deadline_d) {
            //     ALERT("입주자 " + item.winner_name + " 님의 전세보증금 지급기한을 모두 입력해주세요.");
            //     return;
            // }
            // if(!item.support_amt_deadline_y || !item.support_amt_deadline_m || !item.support_amt_deadline_d) {
            //     ALERT("입주자 " + item.winner_name + " 님의 전세임대대여금 지급기한을 모두 입력해주세요.");
            //     return;
            // }
            // if(item.winner_charges && +String(item.winner_charges).replace(/,/g, "") > 0) { //  기본부담금이 있다면
            //     if(!item.winner_charge_deadline_y || !item.winner_charge_deadline_m || !item.winner_charge_deadline_d) {
            //         ALERT("입주자 " + item.winner_name + " 님의 기본부담금 지급기한을 모두 입력해주세요.");
            //         return;
            //     }
            // }
            // if(item.add_charges && +String(item.add_charges).replace(/,/g, "") > 0) { //  추가부담금이 있다면
            //     if(!item.add_charge_deadline_y || !item.add_charge_deadline_m || !item.add_charge_deadline_d) {
            //         ALERT("입주자 " + item.winner_name + " 님의 추가부담금 지급기한을 모두 입력해주세요.");
            //         return;
            //     }
            // }
            // if(!item.month_amt_pay_day || item.month_amt_pay_day == "0") {
            //     ALERT("입주자 " + item.winner_name + " 님의 월임대료 지급기한을 모두 입력해주세요.");
            //     return;
            // }

            _winner.push({
                winner_id: item.winner_id,
                rent_amt_deadline: item.rent_amt_deadline,
                support_amt_deadline: item.support_amt_deadline,
                winner_charge_deadline: item.winner_charge_deadline,
                add_charge_deadline: item.add_charge_deadline,
                month_amt_pay_day: item.month_amt_pay_day,
            })
        }

        dispatch(
            save({
                doc_id: docNo,
                contract_date: contract_date.replace('T', ' '),
                contract_location: contract_location,
                real_cont_date: real_cont_date,
                confirm_date: confirm_date,
                against_date: against_date,
                delivery_date: delivery_date,
                cont_break: cont_break,
                wallpaper_flag: wallpaper_flag,
                lessee_name: lessee_name,
                lessee_addr: lessee_addr,
                lessee_reg_no: lessee_reg_no,
                lessee_tel: lessee_tel,
                broker_name: broker2_name,
                broker_owner: broker2_owner,
                broker_addr: broker2_addr,
                broker_reg_no: broker2_reg_no,
                broker_tel: broker2_tel,
                lease_start_date: lease_start_date,
                lease_end_date: lease_end_date,
                down_payment: !down_payment ? "0" : String(down_payment).replace(/,/g, ""),
                interim_payment: !interim_payment ? "0" : String(interim_payment).replace(/,/g, ""),
                interim_pay_date: interim_payment_date,
                balance1: !balance1 ? "0" : String(balance1).replace(/,/g, ""),
                balance2: !balance2 ? "0" : String(balance2).replace(/,/g, ""),
                balance_pay_date: balance_payment_date,
                month_amt_pay_day: month_amt_pay_day,
                account_num: account_num,
                bank_name: bank_name,
                account_holder: account_holder,
                welfare_type: welfare_type,
                collateral_flag: collateral_flag,
                collateral_type: collateral_type,
                collateral_amt: !collateral_amt? "0" : String(collateral_amt).replace(/,/g, ""),
                collateral_date: collateral_date,
                remark: remark,
                lessor_info: lessor,
                winner_info: _winner,
                use_flag: "Y",
                cont_status: cont_status,    // 계약관련 상태 설정 변수
            })
        )

        //  계약일 또는 계약장소가 변경되었을 경우 SMS발송
        if(origin_contract_date !== contract_date || origin_contract_location !== contract_location) {
            // let _receiver = broker1_tel + "," + (broker2_tel ? broker2_tel + "," : "");
            // for(let i = 0; i < winner.length; i++) {
            //     const _winner = winner[i];
            //     _receiver += _winner.winner_phone + ",";
            // }
            // dispatch(sendSms({
            //     sender: "1600-4690",
            //     receiver: _receiver,
            //     msg: "전세임대지원 예정 계약일 및 계약장소가 지정되었습니다.",
            //     msg_type: "SMS",
            // }));
            // dispatch(sendSms({
            //     sender: "1600-4690",
            //     receiver: _receiver,
            //     msg: "전세임대지원 금일 " + contract_date.replace('T', ' ').slice(-5) + "시에 " + contract_location + "에서 계약예정입니다.",
            //     msg_type: "SMS",
            //     rdate: contract_date.split('T')[0].replace(/-/g, ""),
            //     rtime: contract_date.split('T')[1].replace(/:/g, ""),
            // }));
            dispatch(
                changeValue({
                    key: 'origin_contract_date',
                    value: contract_date,
                })
            );
            dispatch(
                changeValue({
                    key: 'origin_contract_location',
                    value: contract_location,
                })
            );
        }
    }
    //  계약취소
    const onCancelClick = () => {
        if(!window.confirm("계약을 취소하시겠습니까?")) {
            return;
        }
        dispatch(
            setStatusApi({
                doc_id: docNo,
                status: "CONTFAIL",
                remark: "",
                lack_reason: '',
            })
        )
    }
    //  계약완료
    const onCompleteClick = () => {
        if(lessor.filter(x => x.use_flag === "Y").length === 0) {
            ALERT("임대인 정보를 입력해주세요.");
            return;
        }
        for(let i = 0; i < lessor.length; i++) {
            const _lessor = lessor[i];

            if(_lessor.use_flag !== "Y") continue;

            if(!_lessor.lessor_name) {
                ALERT("임대인 성명을 입력해주세요.");
                return;
            }
            // if(!_lessor.lessor_addr) {
            //     ALERT("임대인 주소를 입력해주세요.");
            //     return;
            // }
            // if(!_lessor.lessor_no) {
            //     ALERT("임대인 주민등록번호를 입력해주세요.");
            //     return;
            // }
            // if(!_lessor.lessor_tel) {
            //     ALERT("임대인 전화번호를 입력해주세요.");
            //     return;
            // }
        };

        // if(!contract_location) {
        //     ALERT("계약예정장소를 입력해주세요.");
        //     return;
        // }
        if(!contract_date) {
            ALERT("계약예정일을 입력해주세요.");
            return;
        }
        if(!real_cont_date) {
            ALERT("계약일을 입력해주세요.");
            return;
        }
        if(!lease_start_date || !lease_end_date) {
            ALERT("임차대 계약기간을 모두 입력해주세요.");
            return;
        }

        // if((!balance1 || balance1 === "0") && (!balance2 || balance2 === "0")) {
        //     ALERT("잔금을 입력해주세요.");
        //     return;
        // }
        // if(!balance_payment_date) {
        //     ALERT("잔금 지불일을 입력해주세요.");
        //     return;
        // }

        onSaveClick();

        if(!window.confirm("계약을 완료하시겠습니까?")) {
            return;
        }
        dispatch(
            setStatusApi({
                doc_id: docNo,
                status: "CONTOK",
                remark: "",
                lack_reason: '',
            })
        )
    }
    const onEnChangeClick = () => {
        setIsShowEnChange(true);
    }
    const onEnChangeComplete = () => {
        // dispatch(
        //     changeValue({
        //         key: 'doc_status',
        //         value: 'ENMODIFY',
        //     })
        // );
        navigate(-1);
    }
    //  신세기관리자 전용 검토서 삭제
    const onRemoveEntitle = () => {
        if(window.confirm("해당 권리분석 검토서를 삭제합니까?\n삭제 후 되돌릴 수 없습니다.")) {
            dispatch(removeEntitle(docNo))
            navigate(-1);
        }
    }

    //  Image Event -------------------------------------------
    const onSelectFolder = (item, e) => {
        dispatch(doc_selectFolder(item));
        
        const firstInFolder = docImages.find(x => x.docCode === item.code);
        dispatch(doc_selectImg(firstInFolder));
        initImgScale();
    }
    const onSelectImg = (item, e) => {
        dispatch(doc_selectImg(item));
        initImgScale();
    }
    const initImgScale = () => {
        const initImg = document.getElementById("selectImg");
        initImg.style.transform = 'scale(1)';
        const divRotate = document.getElementById("div-rotate");
        divRotate.style.transform = 'rotate(' + selectImg.rotation + 'deg)';
        divRotate.style.left = '0px';
        divRotate.style.top = '0px';
    }
    //  onPrevClick, onNextClick :: 현재 folderSlider가 차지하는 너비만큼 버튼이 이동될 수 있도록 함.
    //  이전 폴더 Slider
    const onPrevClick = (type) => {
        if(type === "IMG") {
            let movePx = Math.floor(imgFolderDiv.current.clientWidth / 170) * 170;
            
            if (imgFolderSlider.current.offsetLeft < 0) {
                const nextPostion = imgFolderSlider.current.offsetLeft + movePx;
                imgFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
        else {
            let movePx = Math.floor(txtFolderDiv.current.clientWidth / 170) * 170;
            
            if (txtFolderSlider.current.offsetLeft < 0) {
                const nextPostion = txtFolderSlider.current.offsetLeft + movePx;
                txtFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
    }
    //  다음 폴더 Slider
    const onNextClick = (type) => {
        if(type === "IMG") {
            let movePx = Math.floor(imgFolderDiv.current.clientWidth / 170) * 170;
            
            const nextPostion = imgFolderSlider.current.offsetLeft - movePx;
            if (Math.abs(nextPostion) < imgFolderSlider.current.clientWidth) {
                imgFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
        else {
            let movePx = Math.floor(txtFolderDiv.current.clientWidth / 170) * 170;
            
            const nextPostion = txtFolderSlider.current.offsetLeft - movePx;
            if (Math.abs(nextPostion) < txtFolderSlider.current.clientWidth) {
                txtFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
    }
    const onRefChange = (type) => {
        setRefType(type);
    }
    const onSelectTxtFolder = (item, e) => {
        // dispatch(
        //     changeValue({
        //         key: 'selectTxtFolder',
        //         value: item.code,
        //     })
        // )
        setSelectTxtFolder(item.code);
    }
    const onWaiting = (e) => {
        e.target.src = waiting;
    }
    const onImgRotation = () => {
        const divRotate = document.getElementById("div-rotate");

        const regex = /[^0-9]/g;    // 숫자가 아닌 문자열을 선택하는 정규식
        const currRotate = +divRotate.style.transform.replace(regex, "");

        let nextRotate;
        switch(currRotate) {
            case 0: nextRotate = 90; break;
            case 90: nextRotate = 180; break;
            case 180: nextRotate = 270; break;
            case 270: nextRotate = 0; break;
            default: nextRotate = 0; break;
        }
        dispatch(changeValue({
            key: 'selectImg',
            value: {
                ...selectImg,
                rotation: nextRotate,
            }
        }))
    }
    const onImgRotateSave = () => {
        if(!selectImg.file_id) {
            ALERT("첨부 이미지 새로고침 후 다시 시도해주세요.");
            return;
        }

        const divRotate = document.getElementById("div-rotate");
        const regex = /[^0-9]/g;    // 숫자가 아닌 문자열을 선택하는 정규식
        const currRotate = +divRotate.style.transform.replace(regex, "");
        dispatch(
            imgRotation({
                doc_id: docNo,
                file_id: selectImg.file_id,
                file_url: selectImg.src,
                rotation: currRotate,
            })
        )

        //  썸네일 방향 변경
        const _docImages = docImages.map((item) =>
            (item.file_id === selectImg.file_id && item.imgSrc === selectImg.src) ? { ...item, rotation: currRotate } : item,
        );
        dispatch(changeValue({
            key: 'docImages',
            value: _docImages,
        }))

        //  업로드 모달과 연동할 초기데이터 변경
        const _alreadyFileList = alreadyFileList.map((item) =>
            item.file_id === selectImg.file_id ? { ...item, rotation: currRotate } : item,
        );
        dispatch(changeValue({
            key: 'alreadyFileList',
            value: _alreadyFileList
        }))
    }
    const onRefreshImg = () => {
        dispatch(
            getFiles({
                doc_id: docNo,
                doc_type: "CONTRACT",
            })
        )
    }
    //  ------------------------------------------- Image Event

    useEffect(() => {
        addZoom("")
    }, [selectImg])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        user,
        docNo,
        doc_status,

        lessorLastIdx,
        lessor,

        lessee_name,
        lessee_addr,
        lessee_reg_no,
        lessee_tel,

        winner,

        broker1_name,
        broker1_owner,
        broker1_addr,
        broker1_reg_no,
        broker1_tel,
        broker2_name,
        broker2_owner,
        broker2_addr,
        broker2_reg_no,
        broker2_tel,

        contract_date,
        contract_location,
        real_cont_date,

        confirm_date,
        against_date,
        delivery_date,
        cont_break,
        wallpaper_flag,
        cont_status,

        road_addr,
        jibun_addr,
        house_type,
        house_area,
        ground_area,
        share_area,
        room_count,
        welfare_type,
        collateral_flag,
        collateral_type,
        collateral_amt,
        collateral_date,

        rent_amt,
        rent_amt_Han,
        monthly_rent_amt,
        monthly_rent_amt_Han,
        month_amt_pay_day,
        lease_start_date,
        lease_end_date,
        down_payment,
        down_payment_Han,
        interim_payment,
        interim_payment_Han,
        interim_payment_date,
        balance1,
        balance1_Han,
        balance2,
        balance2_Han,
        balance_payment_date,
        account_num,
        bank_name,
        account_holder,

        remark,

        //  중개사2 Show/Hide
        isShowBroker2,
        
        //  모달
        isShowDocUpload,
        onUploadComplete,

        //  서류폴더, 서류이미지
        refType,
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        selectTxtFolder,
        txtFolder,
        alreadyFileList,
        onWaiting,
        onImgRotation,
        onImgRotateSave,
        onRefreshImg,

        //  폴더 슬라이드 위치
        imgFolderDiv,
        imgFolderSlider,
        txtFolderDiv,
        txtFolderSlider,
        
        //  다음 주소찾기 API 모달
        isShowDaumPost,

        // 전체출력 모달
        contractPrintModal,
        setContractPrintModal,

        onChange,
        onChangeSave,
        onChangeNum,

        onAddRessor,
        onRemoveRessor,
        onChangeRessor,
        onShowDaumPost,
        handlePostCode,

        onShowBroker2,

        onChangeWinner,

        //  검토서 변경요청
        isShowEnChange,
        setIsShowEnChange,
        onEnChangeComplete,

        onStartClick,
        onPrintClick,
        onDocUploadClick,
        onSaveClick,
        onCancelClick,
        onCompleteClick,
        onEnChangeClick,
        onRemoveEntitle,

        onRefChange,
        onSelectFolder,
        onSelectTxtFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,

        setSelectTxtFolder
    }
    return (
        <>
            <Contract {...propDatas}/>
        </>
    );
}

export default ContractContainer;