import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as entitleAPI from '../../lib/api/entitle';
import { ALERT } from '../../lib/dataWord';

const INITIALIZE = 'enPriority/INITIALIZE';
const CHANGE_VALUE = 'enPriority/CHANGE_VALUE';
const ADD_ROW = 'enPriority/ADD_ROW';
const REMOVE_ROW = 'enPriority/REMOVE_ROW';
const ROW_CHANGE_VALUE = 'enPriority/ROW_CHANGE_VALUE';
const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('enPriority/SAVE');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const addRow = createAction(ADD_ROW);
export const removeRow = createAction(REMOVE_ROW, ({rent_seq}) => ({rent_seq}));
export const rowChangeValue = createAction(ROW_CHANGE_VALUE, ({rent_seq, key, value}) => ({rent_seq, key, value}));
export const save = createAction(SAVE, (body) => (body));

const saveSaga = createRequestSaga(SAVE, entitleAPI.senior_rent);

export function* enPrioritySaga() {
    yield takeLatest(SAVE, saveSaga);
}

const initialState = {
    priorityLastIdx: 0,
    priority: [],
    _rent_tot_amt: '',
    _empty_tot_amt: '',

    isSave: false,

    enPriority: null,
    error: null
};

const enPriority = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [ROW_CHANGE_VALUE]: (state, { payload: { rent_seq, key, value } }) => {
            const _priority = state.priority.map((item) =>
                item.rent_seq === rent_seq ? { ...item, [key]: value } : item,
            );

            //  합계 계산
            let calc_empty_tot_amt = 0;
            _priority.forEach(item => {
                if(item.use_flag === 'Y') {
                    // if(item.empty_type2.indexOf('EMPTY') > -1 || item.empty_type2.indexOf('OWNER') > -1) {
                        calc_empty_tot_amt += +(String(item.priority_payment2).replace(/,/g, ""));
                    // }
                }
            });
            
            return {
                ...state,
                priority: _priority,
                _empty_tot_amt: calc_empty_tot_amt,
            };
        },
        [ADD_ROW]: (state) => {
            const _priorityLastIdx = state.priorityLastIdx + 1;

            return {
                ...state,
                priorityLastIdx: _priorityLastIdx,
                priority: state.priority.concat({
                    rent_seq: _priorityLastIdx,
                    empty_type2: 'HOUSE_RENT',
                    rent_floor2: '',
                    floor_name: '',
                    room_no2: '',
                    room_cnt2: '',
                    rent_deposit2: '',
                    rent_fee2: '',
                    priority_payment2: '',
                    remark2: '',
                    use_flag: 'Y',
                }),
            }
        },
        [REMOVE_ROW]: (state, { payload: {rent_seq}}) => {
            const _priority = state.priority.map((item) =>
                item.rent_seq === rent_seq ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );

            //  합계 계산
            let calc_empty_tot_amt = 0;
            _priority.forEach(item => {
                if(item.use_flag === 'Y') {
                    // if(item.empty_type2.indexOf('EMPTY') > -1 || item.empty_type2.indexOf('OWNER') > -1) {
                        calc_empty_tot_amt += +(String(item.priority_payment2).replace(/,/g, ""));
                    // }
                }
            });

            return {
                ...state,
                priority: _priority,
                _empty_tot_amt: calc_empty_tot_amt,
            };
        },
        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("저장되었습니다.");
            return {
                ...state,
                isSave: true,
            }
        },
        [SAVE_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },
    },
    initialState,
);

export default enPriority;