import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EnChangeReq from '../../components/Modal/EnChangeReq';
import { ALERT } from '../../lib/dataWord';
import { changeValue, initialize, setStatus } from '../../modules/Modal/enChangeReq';

const EnChangeReqContainer = (props) => {
    const {
        docNo,
        doc_status,
        setIsShowEnChange,
        onEnChangeComplete,
    } = props;
    
    const dispatch = useDispatch();

    const {
        isSave,
        remark,
    } = useSelector(({ enChangeReq }) => ({
        isSave: enChangeReq.isSave,
        remark: enChangeReq.remark,
    }));

    const onChange = (e) => {
        dispatch(changeValue({
            key: e.target.name,
            value: e.target.value,
        }))
    }
    const onSave = () => {
        if(!remark) {
            ALERT("사유를 입력해주세요.");
            return;
        }

        if(window.confirm("검토서 변경을 요청하시겠습니까?")) {
            dispatch(setStatus({
                doc_id: docNo,
                status: "ENMODIFY",
                remark: remark,
                lack_reason: '',
            }))
        }
    }
    const onClose = () => {
        setIsShowEnChange();
    }

    useEffect(() => {
        if(isSave) {
            onEnChangeComplete();
            setIsShowEnChange();
        }
    }, [dispatch, isSave, onEnChangeComplete, setIsShowEnChange])

    useEffect(() => {
        return () => {
            dispatch(initialize());
        }
    }, [dispatch])

    const propDatas = {
        doc_status,

        remark,

        onChange,
        onSave,
        onClose,
    }
    return (
        <>
            <EnChangeReq {...propDatas}/>
        </>
    );
}

export default EnChangeReqContainer;