import React, { useEffect,useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCode } from '../modules/printSort';
import ContractDocPrint from '../components/ContractDocPrint';
import { ALERT } from '../lib/dataWord';

const printDocCodeList = ['DOC005', 'DOC006', 'DOC015', 'DOC016', 'DOC017', 'DOC021', 'DOC022', 'DOC000'];
const ContractDocPrintContainer = (props) => {
    const {
        winner,
        write,
        // rentWrite,
        work,
        rentWork,
        doc014, //  등기부등본
        doc015, //  건축물관리대장
        doc016, //  주택가격
        doc017, //  신.구주소

        printList,
        docFolder,
        txtFolder,
        docImages,
        feedback,
        rent,
        contract,
        approval,
        use_address,
    } = useSelector(({ entitleViewer, contract, printSort }) => ({
        winner: entitleViewer.winner,
        write: entitleViewer.write,
        rentWrite: entitleViewer.rentWrite,
        work: entitleViewer.work,
        rentWork: entitleViewer.rentWork,
        doc014: entitleViewer.doc014,
        doc015: entitleViewer.doc015,
        doc016: entitleViewer.doc016,
        doc017: entitleViewer.doc017,
        printList: printSort.printList,
        docFolder: contract.docFolder,
        txtFolder: contract.txtFolder,
        docImages: contract.docImages,
        feedback: contract.feedback,
        rent: contract.rent,
        contract: contract.contract,
        approval: contract.approval,
        use_address: contract.use_address,
    }));
    const {contractPrintModal, setContractPrintModal} = props;
        
    const docPrintRef = useRef(null);
    const dispatch = useDispatch();
    const printImgCodeList = [];
    const [compo, setCompo] = useState([]);

    const onClickCancel = () => {
        setContractPrintModal(!contractPrintModal);
    }

    const handleBeforeGetContent = () => {
        const checkedInput = document.querySelectorAll('#contract-print-list input[type=checkbox]:checked');
        const arr = [];

        if(checkedInput.length <= 0){
            ALERT('출력할 항목을 한가지 이상 선택해주세요.');
            return Promise.reject();
        }

        [...checkedInput].forEach((el)=> {
            arr.push(el.dataset.docCode);
        })
        setCompo(arr);
        return Promise.resolve();
    }

    const onClickAllCheck = () => {
        const checkAry = [...document.querySelectorAll('#contract-print-list input[type=checkbox]')];
        let isChecked = false;
        for(let i=0; i<checkAry.length; i++) {
            if( checkAry[i].checked ) {
                isChecked = true;
                break;
            }
        }

        const checkAll = [...document.querySelectorAll('#print-check-all')];
        if( checkAll.length > 0 ) {
            checkAll[0].checked = !isChecked;
        }

        checkAry.forEach(el => el.checked = !isChecked);
    }

    useEffect(()=>{
        dispatch(getCode());
    }, [dispatch])

    useEffect(()=>{
        if(docFolder.length !== 0 && txtFolder.length !== 0){
            printDocCodeList.push(...docFolder.map((doc) => doc.code));
            printDocCodeList.push(...txtFolder.map((doc) => doc.code));
        }
    }, [docFolder, txtFolder]);

    const dataProps = {
        onClickCancel,
        printDocCodeList,
        printList,
        docFolder,
        contractPrintModal,
        docPrintRef,
        printImgCodeList,
        docImages,
        compo,
        handleBeforeGetContent,
        onClickAllCheck,

        winner,
        feedback,
        rent,
        write,
        // rentWrite,
        work,
        rentWork,
        doc014, //  등기부등본
        doc015, //  건축물관리대장
        doc016, //  주택가격
        doc017, //  신.구주소
        contract,
        approval,
        use_address,
    }
    return (
        <>
        {printList.length > 0 && 
            <>
                <ContractDocPrint {...dataProps}/>
            </>
        }
        </>
    );
};

export default ContractDocPrintContainer;