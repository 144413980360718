import React from 'react';
import styled from 'styled-components';

const CodeMgtBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 20px 50px 0;
    box-sizing: border-box;

    .dkgreen-btn{
        margin-bottom: 15px;
    }
    
    .containerDiv{
        display: flex;
        align-items: flex-start;
        height: 78vh;
        gap: 20px;
    }
    .containerDiv div {
        height: 100%;
        overflow-y: auto;
        border: 1px solid #ccc;
    }
    .containerDiv div:first-child{
        width: 30%;
    }
    .containerDiv div:last-child{
        width: 70%;
    }
`;

const CodeMgt = (props) => {
    const {
        classList,

        codeList,

        cboUseYN,

        onRowChange,
        onRowClick,
        onAddRow,
        onRemoveRow,
        onSave
    } = props;

    return (
        <CodeMgtBlock>
            <button onClick={onSave} className="dkgreen-btn">저장</button>

            <div className='containerDiv'>
                <div>
                    <table className='border-tt'>
                        <thead>
                            <tr>
                                <th>코드 분류</th>
                                <th>코드명</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classList.map((item) => (
                                <tr key={item.sub_code} onClick={onRowClick.bind(this, item)}>
                                    <td>{item.sub_code}</td>
                                    <td>{item.code_name}</td>
                                </tr> 
                            ))}
                        </tbody>
                    </table>
                </div>

                <div>
                    <table  className='border-tt'>
                        <thead>
                            <tr>
                                <th><button onClick={onAddRow} className="add-btn">+추가</button></th>
                                <th>코드 분류</th>
                                <th>코드</th>
                                <th>코드명</th>
                                <th>비고</th>
                                <th>순서</th>
                                <th>사용여부</th>
                            </tr>
                        </thead>
                        <tbody>
                            {codeList.map((item,idx) => (
                                <tr key={idx}>
                                    <td>{item.isNewRow ? <button onClick={onRemoveRow.bind(this, item)} className="del-btn">ㅡ삭제</button> : null}</td>
                                    <td><input type="text" name="main_code" id="main_code" value={item.main_code || ""} disabled={true} /></td>
                                    <td><input type="text" name="sub_code" id="sub_code" value={item.sub_code || ""} onChange={onRowChange.bind(this, item)} disabled={item.isNewRow ? false : true} /></td>
                                    <td><input type="text" name="code_name" id="code_name" value={item.code_name || ""} onChange={onRowChange.bind(this, item)} /></td>
                                    <td><input type="text" name="memo" id="memo" value={item.memo || ""} onChange={onRowChange.bind(this, item)} /></td>
                                    <td><input type="number" name="display" id="display" value={item.display || ""} onChange={onRowChange.bind(this, item)} /></td>
                                    <td>
                                        <select name="use_flag" id="use_flag" value={item.use_flag} onChange={onRowChange.bind(this, item)}>
                                            {cboUseYN.map((useyn) => (
                                                <option value={useyn.sub_code} key={useyn.sub_code}>{useyn.code_name}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </CodeMgtBlock>
    );
}

export default CodeMgt;