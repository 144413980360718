import React from 'react';

const LandPriceView = ({ _doc016, work }) => {
    if( !Array.isArray(_doc016) ) {
        return (
            <div>
                토지가격정보 - API 동작 오류 ({_doc016})
            </div>
        )
    }
    return (
        <div className='tableWrap price'>
            <div className='viewerContainer borderwrap bd0'>
                {/* <h4>토지가격정보</h4> */}
                
                <div className='titleOpen'>
                    개별공시지가(열람용)
                </div>
               
                <div className='titleAd'>
                    <div>토지소재지</div>
                    <div>{work && work.jibun_addr ? work.jibun_addr : ''}</div>
                </div>

                <table className="apiTable">
                    <colgroup>
                        <col width="90"></col>
                        <col width="150"></col>
                        <col width="50"></col>
                        <col width="70"></col>
                        <col width="70"></col>
                        <col width="70"></col>
                        <col width="70"></col>
                        
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan={3}>신청대상 토지</th>
                            <th colSpan={4} className="last">확인내용</th>
                        </tr>
                        <tr>
                            <th>가격기준연도</th>
                            <th>토지소재지</th>
                            <th>지번</th>
                            <th>개별공시지가</th>
                            <th>기준일자</th>
                            <th>공시일자</th>
                            <th className="last">비고</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        _doc016 &&
                        (
                            _doc016.map((item, idx) => (	
                                <tr key={idx}>
                                    <td>{item.PRICE_BASIC_DATE}</td>
                                    <td>{item.LOCATION}</td>
                                    <td>{item.JIBUN_BLDG_NO}</td>
                                    <td>{item.OFFICIAL_LAND_PRICE}</td>
                                    <td>{item.BASIC_DATE}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))
                        )
                    }
                    </tbody>
                </table>
                <div className='exp1'>※ 단위면적(m²)당 산정가격임.</div>
                {/* 주택가격정보 변경 전 */}
                    {/* {
                        _doc016 &&
                        (
                            _doc016.map((item, idx) => (
                                <table key={idx} className="apiTable">
                                    <tbody>
                                        <tr>
                                            <th>LAND_AREA</th>
                                            <td>{item.LAND_AREA}</td>
                                        </tr>
                                        <tr>
                                            <th>BUILDING_AREA</th>
                                            <td>{item.BUILDING_AREA}</td>
                                        </tr>
                                        <tr>
                                            <th>LOCATION</th>
                                            <td>{item.LOCATION}</td>
                                        </tr>
                                        <tr>
                                            <th>BUILDING_AREA_CALCULATION</th>
                                            <td>{item.BUILDING_AREA_CALCULATION}</td>
                                        </tr>
                                        <tr>
                                            <th>LAND_AREA_CALCULATION</th>
                                            <td>{item.LAND_AREA_CALCULATION}</td>
                                        </tr>
                                        <tr>
                                            <th>JIBUN_BLDG_NO</th>
                                            <td>{item.JIBUN_BLDG_NO}</td>
                                        </tr>
                                        <tr>
                                            <th>PRICE_BASIC_DATE</th>
                                            <td>{item.PRICE_BASIC_DATE}</td>
                                        </tr>
                                        <tr>
                                            <th>OFFICIAL_DATEE</th>
                                            <td>{item.OFFICIAL_DATEE}</td>
                                        </tr>
                                        <tr>
                                            <th>BASIC_DATE</th>
                                            <td>{item.BASIC_DATE}</td>
                                        </tr>
                                        <tr>
                                            <th>OFFICIAL_LAND_PRICE</th>
                                            <td>{item.OFFICIAL_LAND_PRICE}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))
                        )
                } */}
                {/* 주택가격정보 변경 전 */}
            </div>
        </div>
    );
}

export default LandPriceView;