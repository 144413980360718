import React from 'react';
import styled from 'styled-components';
import dataWORD from '../../lib/dataWord';

const BuildingMngViewBlock = styled.div`
    .tableWrap.build .viewerContainer .bulidNum{
        width:100%;
    }
    .tableWrap.build .viewerContainer .bulidType{
        width:100%;
    }
    .tableWrap.build .viewerContainer table th,
    .tableWrap.build .viewerContainer table td{
        font-size: 11px;
        background-color: transparent;
        height: 10px;
    }
    .tableWrap.build .viewerContainer .bulidNum th,
    .tableWrap.build .viewerContainer .bulidType th{
        vertical-align : top;
        text-align : left;
        padding : 0 5px;
        border-right:0;
        font-weight:normal
    }
    .tableWrap.build .viewerContainer .bulidNum td,
    .tableWrap.build .viewerContainer .bulidType td{
        border-left:0;
    }
    .tableWrap.build .viewerContainer .bulidNum tr th:first-child,
    .tableWrap.build .viewerContainer .bulidType tr th:first-child{
        border-left: 0;
    }
    .tableWrap.build .viewerContainer .bulidNum tr td:last-child,
    .tableWrap.build .viewerContainer .bulidType tr td:last-child{
        border-right: 0;
    }

    .tableWrap.build .viewerContainer .bulidNum td,
    .tableWrap.build .viewerContainer .bulidType td{
        vertical-align : bottom;
        text-align : right;
        padding : 0 5px;
    }

    .tableWrap.build .viewerContainer .bulidType tr:nth-child(n+3) th {
        border-bottom:0
    }
    .tableWrap.build .viewerContainer .bulidType tr:nth-child(n+3) td{
        border-top:0
    }
    
    .tableWrap.build .viewerContainer .bulidReport tr th:first-child,
    .tableWrap.build .viewerContainer .bulidReport tr td:first-child{
        border-left:0
    }

    .tableWrap.build .viewerContainer .bulidReport thead tr th{
        padding : 5px 0;
        font-weight:normal
    }
    .tableWrap.build .viewerContainer .bulidReport thead tr:first-child th,
    .tableWrap.build .viewerContainer .bulidReport thead tr:last-child th,
    .tableWrap.build .viewerContainer .bulidReport tbody tr td:last-child{
        border-right:0
    }
    
    .tableWrap.build .viewerContainer .ownerReport tr th:first-child,
    .tableWrap.build .viewerContainer .ownerReport tr td:first-child{
        border-left:0
    }

    .tableWrap.build .viewerContainer .ownerReport thead tr th{
        padding : 5px 0;
        font-weight:normal
    }
    .tableWrap.build .viewerContainer .ownerReport thead tr:first-child th,
    .tableWrap.build .viewerContainer .ownerReport thead tr:last-child th,
    .tableWrap.build .viewerContainer .ownerReport tbody tr td:last-child{
        border-right:0
    }

    .tableWrap.build .viewerContainer .change tr th:first-child,
    .tableWrap.build .viewerContainer .change tr td:first-child{
        border-left:0
    }

    .tableWrap.build .viewerContainer .change thead tr th{
        padding : 5px 0;
        font-weight:normal
    }
    .tableWrap.build .viewerContainer .change thead tr:first-child th,
    .tableWrap.build .viewerContainer .change thead tr:last-child th,
    .tableWrap.build .viewerContainer .change tbody tr td:last-child{
        border-right:0
    }
    .tableWrap.build .viewerContainer .etc tr th:first-child,
    .tableWrap.build .viewerContainer .etc tr td:first-child{
        border-left:0
    }

    .tableWrap.build .viewerContainer .etc thead tr th{
        padding : 5px 0;
        font-weight:normal
    }
    .tableWrap.build .viewerContainer .etc thead tr:first-child th,
    .tableWrap.build .viewerContainer .etc thead tr:last-child th,
    .tableWrap.build .viewerContainer .etc tbody tr td:last-child{
        border-right:0
    }
    
`;

const BuildingMngView = ({_doc015}) => {
    if( !_doc015 ) {
        return (
            <>
            API 오류
            </>
        )
    }
    if( _doc015.ERRCODE !== '00000000' ) {
        return <div>{`${_doc015.ERRMSG}(ERRCODE: ${_doc015.ERRCODE})`}</div>
    }

    return (
        <BuildingMngViewBlock>
            <div className='tableWrap build'>
                <div className='viewerContainer borderwrap bd0'>
                    <h4 className='mt0 text-c' style={{display: 'block'}}>일반건축물대장</h4>
                    <table className='bulidNum'>
                        <thead>
                            <tr>
                                <th rowSpan={2} style={{width : '8%'}}>고유번호</th>
                                <td className='vertical-m text-c' rowSpan={2} style={{width : '37%'}}>{_doc015.PRIMARY_NUM}</td>
                                <th className='bdb0' style={{width : '15%'}}>명칭</th>
                                <th className='bdb0' style={{width : '15%'}}>호수/가구수/세대수</th>
                            </tr>
                            <tr>
                                <td className='bdt0' style={{width : '15%'}}>{_doc015.DESIGNATION}</td>
                                <td className='bdt0' style={{width : '15%'}}>{_doc015.HO_COUNT}/{_doc015.GAGU_COUNT}/{_doc015.GENERATION_COUNT}</td>
                            </tr>
                        </thead>
                    </table>
                    <table className='bulidNum mt5'>
                        <thead>
                            <tr>
                                <th className='bdb0' rowSpan={2} style={{width : '5%'}}>대지위치</th>
                                <td className='vertical-m text-c bdb0' rowSpan={2} style={{width : '15%'}}>{_doc015.GROUND_LOCATION}</td>
                                <th className='bdb0' style={{width:'10%'}}>지번</th>
                                <th className='bdb0' rowSpan={2} style={{width : '5%'}}>도로명주소</th>
                                <td className='vertical-m text-c bdb0' rowSpan={2} style={{width : '15%'}}>{_doc015.ROAD_ADDRESS}</td>
                            </tr>
                            <tr>
                                <td className='bdt0 bdb0' style={{width:'10%'}}>{_doc015.JIBEON}</td>
                            </tr>
                        </thead>
                    </table>
                    <table className='bulidType'>
                        <tbody>
                            <tr>
                                <th className="bdb0" style={{width:'20%'}}>※ 대지면적</th> 
                                <th className="bdb0" style={{width:'20%'}}>연면적</th>
                                <th className="bdb0" style={{width:'20%'}}>※ 지역</th>
                                <th className="bdb0" style={{width:'20%'}}>※ 지구</th>
                                <th className="bdb0" style={{width:'20%'}}>※ 구역</th>
                            </tr>
                            <tr>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.EARTH_AREA ? _doc015.EARTH_AREA : <span>m²</span>}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.YEONG_AREA ? _doc015.YEONG_AREA : <span>m²</span>}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.AREA}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.EARTH}</td>
                                <td className="bdt0" style={{width:'20%'}}>{_doc015.ZONE}</td>
                            </tr>
                            <tr>
                                <th>건축면적</th>
                                <th>용적률 산정용 연면적</th>
                                <th>주구조</th>
                                <th>주용도</th>
                                <th>층수</th>
                            </tr>
                            <tr>
                                <td>{_doc015.BUILDING_AREA ? _doc015.BUILDING_AREA : <span>m²</span>}</td>
                                <td>{_doc015.FIOOR_RATIO_AREA ? _doc015.FIOOR_RATIO_AREA : <span>m²</span>}</td>
                                <td>{_doc015.STRUCTURE}</td>
                                <td>{_doc015.PURPOSE}</td>
                                <td>{_doc015.FIOORS_NUM}</td>
                            </tr>
                            <tr>
                                <th>※ 건폐율</th>
                                <th>※ 용적률</th>
                                <th>높이</th>
                                <th>지붕</th>
                                <th>부속건축물</th>
                            </tr>
                            <tr>
                                <td>{_doc015.BUILDING_RATIO ? _doc015.BUILDING_RATIO : <span>%</span>}</td>
                                <td>{_doc015.FIOOR_RATIO ? _doc015.FIOOR_RATIO : <span>%</span>}</td>
                                <td>{_doc015.HEIGHT ? _doc015.HEIGHT : <span>m²</span>}</td>
                                <td>{_doc015.ROOF}</td>
                                <td>{_doc015.ANNEXED_BUILDING}</td>
                            </tr>
                            <tr>
                                <th>※ 조경면적</th>
                                <th>※ 공개 공지ㆍ공간 면적</th>
                                <th>※ 건축선 후퇴면적</th>
                                <th>건축선 후퇴 거리</th>
                            </tr>
                            <tr>
                                <td>{_doc015.LANDSCAPE ? _doc015.LANDSCAPE : <span>m²</span>}</td>
                                <td>{_doc015.PUBLIC_NOTICE ? _doc015.PUBLIC_NOTICE : _doc015.SPACE_AREA ? <span>m² ㆍ</span> : ''}{_doc015.SPACE_AREA ? _doc015.SPACE_AREA : <span>m²</span>}</td>
                                <td>{_doc015.BUILDING_LINE_RETREAT_ARE ? _doc015.BUILDING_LINE_RETREAT_AREA : <span>m²</span>}</td>
                                <td colSpan={3}>{_doc015.BUILDING_LINE_RETREAT_LEN ? _doc015.BUILDING_LINE_RETREAT_LEN : <span>m</span>}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='bulidReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={5}>건축물현황</th>
                                </tr>
                                <tr>
                                    <th>구분</th>
                                    <th>층별</th>
                                    <th>구조</th>
                                    <th>용도</th>
                                    <th>면적</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA && Array.isArray(_doc015.RESP_DATA) && (_doc015.RESP_DATA.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.GUBUN}</td>
                                                        <td>{item.LAYER}</td>
                                                        <td>{item.RESCUE}</td>
                                                        <td>{item.USAGE}</td>
                                                        <td>{item.AREA}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    <div className='ownerReport mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={6}>소유자현황</th>
                                </tr>
                                <tr>
                                    <th>성명(명칭)</th>
                                    <th>{dataWORD.BIRTHDATE}</th>
                                    <th>주소</th>
                                    <th>소유권지분</th>
                                    <th>변동일</th>
                                    <th>변동원인</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA2 && Array.isArray(_doc015.RESP_DATA2) && (_doc015.RESP_DATA2.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA2.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.OWNER_NAME}</td>
                                                        <td>{item.OWNER_REG}</td>
                                                        <td>{item.OWNER_ADD}</td>
                                                        <td>{item.OWNER_SHARE}</td>
                                                        <td>{item.OWNER_CHANGE_DATE}</td>
                                                        <td>{item.OWNER_CHANGE_CAUSE}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    
                    <div className='change mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>변동사항</th>
                                </tr>
                                <tr>
                                    <th>변동일</th>
                                    <th>변동내용 및 원인</th>
                                </tr>
                            </thead>
                            {
                                _doc015.RESP_DATA4 && Array.isArray(_doc015.RESP_DATA4) && (_doc015.RESP_DATA4.length > 0) &&
                                (
                                    <tbody>
                                        {
                                            _doc015.RESP_DATA4.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item.CHANGES_DATA}</td>
                                                        <td className='text-l pl10'>{item.CHANGES_CAUSE}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                )

                            }
                        </table>
                    </div>
                    <div className='etc mt5'>
                        <table>
                            <thead>
                                <tr>
                                    <th>그 밖의 기재사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{_doc015.ETC}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>
        </BuildingMngViewBlock>
    );
}

export default BuildingMngView;