import React from 'react';
import styled from 'styled-components';

const ImplyApproveBlock = styled.div``;

const ImplyApprove = () => {
    return (
        <ImplyApproveBlock>
            ImplyApprove
        </ImplyApproveBlock>
    );
}

export default ImplyApprove;