import React from 'react';
import ImplyExcelWorkContainer from '../containers/ImplyExcelWorkContainer';
import HeaderContainer from '../containers/HeaderContainer';

const ImplyExcelWorkPage = () => {
    return (
        <>
            <HeaderContainer />
            <ImplyExcelWorkContainer />
        </>
    );
}

export default ImplyExcelWorkPage;