import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import MainContainer from '../containers/MainContainer';


const MainPage = () => {

    return (
        <>
            <HeaderContainer />
            <MainContainer />
        </>
    );
}

export default MainPage;