import React from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import ContractContainer from '../containers/ContractContainer';

const ContractPage = () => {
    return (
        <>
            <HeaderContainer />
            <ContractContainer/>
        </>
    );
}

export default ContractPage;