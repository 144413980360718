import React from 'react';
import LHCustomerListContainer from '../containers/LHCustomerListContainer';
import HeaderContainer from '../containers/HeaderContainer';

const LHCustomerListPage = () => {
    return (
        <>
            <HeaderContainer />
            <LHCustomerListContainer />
        </>
    );
}

export default LHCustomerListPage;