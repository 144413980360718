import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as adminAPI from '../lib/api/admin';
import * as entitleAPI from '../lib/api/entitle';
import * as scheAPI from '../lib/api/sche';
import { ALERT } from '../lib/dataWord';

const INITIALIZE = 'enWriteAdmin/INITIALIZE';
const CHANGE_VALUE = 'enWriteAdmin/CHANGE_VALUE';

const [GET_CONTENT, GET_CONTENT_SUCCESS, GET_CONTENT_FAILURE] = createRequestActionTypes('enWriteAdmin/GET_CONTENT');
const [GET_FILES, GET_FILES_SUCCESS, GET_FILES_FAILURE] = createRequestActionTypes('enWriteAdmin/GET_FILES');
const [DELETE_CONTENT, DELETE_CONTENT_SUCCESS, DELETE_CONTENT_FAILURE] = createRequestActionTypes('enWriteAdmin/DELETE_CONTENT');
const [GET_COMBO, GET_COMBO_SUCCESS, GET_COMBO_FAILURE] = createRequestActionTypes('enWriteAdmin/GET_COMBO');
const SET_DOC_ID = 'enWriteAdmin/SET_DOC_ID';

const S1_ADD_ROW = 'enWriteAdmin/S1_ADD_ROW';
const S1_REMOVE_ROW = 'enWriteAdmin/S1_REMOVE_ROW';
const S1_SET_LAST_IDX = 'enWriteAdmin/S1_SET_LAST_IDX';
const S1_ROW_CHANGE_VALUE = 'enWriteAdmin/S1_ROW_CHANGE_VALUE';
const S1_SELECT_USER = 'enWriteAdmin/S1_SELECT_USER';

const S3_ADD_ROW = 'enWriteAdmin/S3_ADD_ROW';
const S3_REMOVE_ROW = 'enWriteAdmin/S3_REMOVE_ROW';
const S3_SET_LAST_IDX = 'enWriteAdmin/S3_SET_LAST_IDX';
const S3_ROW_CHANGE_VALUE = 'enWriteAdmin/S3_ROW_CHANGE_VALUE';
const S3_PRIORITY_CHANGED = 'entitleWrite/S3_PRIORITY_CHANGED';

const S4_SELECT_BROKER = 'enWriteAdmin/S4_SELECT_BROKER';

const DOC_SELECT_FOLDER = 'enWriteAdmin/DOC_SELECT_FOLDER';
const DOC_SELECT_IMG = 'enWriteAdmin/DOC_SELECT_IMG';
const DOC_UPLOAD_COMPLETE = 'enWriteAdmin/DOC_UPLOAD_COMPLETE';
const [IMG_ROTATION, IMG_ROTATION_SUCCESS, IMG_ROTATION_FAILURE] = createRequestActionTypes('enWriteAdmin/IMG_ROTATION');

const [SAVE, SAVE_SUCCESS, SAVE_FAILURE] = createRequestActionTypes('enWriteAdmin/SAVE');
const [REQ_ENTITLE, REQ_ENTITLE_SUCCESS, REQ_ENTITLE_FAILURE] = createRequestActionTypes('enWriteAdmin/REQ_ENTITLE');
const [SEND_SMS, SEND_SMS_SUCCESS, SEND_SMS_FAILURE] = createRequestActionTypes('enWriteAdmin/SEND_SMS');

export const initialize = createAction(INITIALIZE);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
export const getContent = createAction(GET_CONTENT);
export const getFiles = createAction(GET_FILES);
export const delContent = createAction(DELETE_CONTENT);
export const getCombo = createAction(GET_COMBO);
export const setDocID = createAction(SET_DOC_ID, (value) => (value));

export const s1_addRow = createAction(S1_ADD_ROW, (idx) => idx);
export const s1_removeRow = createAction(S1_REMOVE_ROW, ({idx}) => ({idx}));
export const s1_setLastIdx = createAction(S1_SET_LAST_IDX, ({value}) => ({value}));
export const s1_rowChangeValue = createAction(S1_ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const s1_selectUser = createAction(S1_SELECT_USER, ({user, row}) => ({user, row}));

export const s3_addRow = createAction(S3_ADD_ROW, (idx) => idx);
export const s3_removeRow = createAction(S3_REMOVE_ROW, ({idx}) => ({idx}));
export const s3_setLastIdx = createAction(S3_SET_LAST_IDX, ({value}) => ({value}));
export const s3_rowChangeValue = createAction(S3_ROW_CHANGE_VALUE, ({idx, key, value}) => ({idx, key, value}));
export const s3_priorityChanged = createAction(S3_PRIORITY_CHANGED, ({idx, value}) => ({idx, value}));

export const s4_selectBroker = createAction(S4_SELECT_BROKER, ({broker}) => ({broker}));

export const doc_selectFolder = createAction(DOC_SELECT_FOLDER, (value) => value);
export const doc_selectImg = createAction(DOC_SELECT_IMG, (value) => value);
export const doc_upload_complete = createAction(DOC_UPLOAD_COMPLETE, (value) => value);
export const imgRotation = createAction(IMG_ROTATION, (body) => (body));

export const save = createAction(SAVE, (body) => (body));
export const reqEntitle = createAction(REQ_ENTITLE, (body) => (body));
export const sendSms = createAction(SEND_SMS, (body) => (body));

const getContentSaga = createRequestSaga(GET_CONTENT, entitleAPI.enwrite_view);
const getFilesSaga = createRequestSaga(GET_FILES, entitleAPI.file_view);
const delContentSaga = createRequestSaga(DELETE_CONTENT, entitleAPI.endelete);
const getComboSaga = createRequestSaga(GET_COMBO, adminAPI.code_multi_search);
const saveSaga = createRequestSaga(SAVE, entitleAPI.enwrite);
const reqEntitleSaga = createRequestSaga(REQ_ENTITLE, entitleAPI.enreq);
const sendSmsSaga = createRequestSaga(SEND_SMS, scheAPI.smsSend);
const imgRotationSaga = createRequestSaga(IMG_ROTATION, entitleAPI.file_rotation);

export function* enWriteAdminSaga() {
    yield takeLatest(GET_COMBO, getComboSaga);
    yield takeLatest(GET_CONTENT, getContentSaga);
    yield takeLatest(GET_FILES, getFilesSaga);
    yield takeLatest(DELETE_CONTENT, delContentSaga);
    yield takeLatest(SAVE, saveSaga);
    yield takeLatest(REQ_ENTITLE, reqEntitleSaga);
    yield takeLatest(SEND_SMS, sendSmsSaga);
    yield takeLatest(IMG_ROTATION, imgRotationSaga);
}

const initialState = {
    doc_id: '',
    doc_status: '',

    //  Common.
    cboApply: [],

    // Step1.
    winnerLastIdx: 0,
    winner: [{
        idx: 0,
        contract_type: '',
        winner_type: '',
        winner_type_name: '',
        winner_id: '',
        winner_name: '',
        winner_no: '',
        winner_addr: '',
        winner_tel: '',
        winner_phone: '',
        family_cnt: 0,
        charge_rate: null,
        winner_charges: '',
        add_charges: '',
        monthly_rent: '',
        lh_support_amt: '',
        type_calc_code: '',
        is_duplicate: '',
        info_yn: '',
        select_win_type: '',
    }],

    // Step2.
    road_addr: '',
    road_addr_dtl: '',
    jibun_addr: '',
    sido: '',
    sigungu: '',
    town: '',
    road_name: '',
    building_num: '',
    building_name: '',
    house_type: '',
    house_area: '',
    room_count: '',
    house_selling: '',
    house_area_over1: '',
    house_area_over2: '',
    house_rent_type: 'TYPE01',  //Default: 전세
    rent_amt: '',
    monthly_rent_amt: '',
    minor_child_cnt: '',
    estate_no: '',
    wallpaper_flag: '',

    //  Step3.
    priorityLastIdx: 0,
    priority: [],
    // priority: [{
    //     rent_seq: 0,
    //     empty_type: 'HOUSE_RENT',    //Default: 주택 임대중
    //     rent_floor: '',
    //     room_no: '',
    //     room_cnt: '',
    //     rent_deposit: '',
    //     rent_fee: '',
    //     priority_payment: '',
    //     remark: '',
    //     use_flag: 'Y',
    // }],
    rent_tot_amt: '',

    //  Step4.
    broker_id: '',
    broker_company: '',
    broker_name: '',
    broker_reg_no: '',
    broker_telno: '',

    selectFoler: '',
    docFolder: [],
    selectImg: null,
    docImages: [],

    originFileList: [],

    enWriteAdmin: null,
    error: null
};

const enWriteAdmin = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [GET_COMBO_SUCCESS]: (state, { payload: { APPLY_TYPE } }) => {
            //  지원유형 첫번째 item으로 [선택] 항목 추가
            const _APPLY_TYPE = APPLY_TYPE;
            _APPLY_TYPE.unshift({
                admin_type: null,
                code_name: "선택해주세요.",
                display: -1,
                idx: -1,
                main_code: "APPLY_TYPE",
                memo: "",
                sub_code: "",
                use_flag: "Y",
            });

            return {
                ...state,
                cboApply: _APPLY_TYPE,
            }
        },
        [GET_COMBO_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [GET_CONTENT_SUCCESS]: (state, { payload }) => {
            let _winner = [];
            if(payload.winner.length === 0) {
                _winner.push({
                    idx: 0,
                    contract_type: payload.entitle[0].contract_type,
                    winner_type: '',
                    winner_type_name: '',
                    winner_id: '',
                    winner_name: payload.entitle[0].winner_name,
                    winner_no: payload.entitle[0].winner_birth,
                    winner_addr: '',
                    winner_tel: '',
                    winner_phone: '',
                    family_cnt: 0,
                    charge_rate: null,
                    winner_charges: '',
                    add_charges: '',
                    monthly_rent: '',
                    lh_support_amt: '',
                    type_calc_code: '',
                    is_duplicate: '',
                    info_yn: '',
                    select_win_type: '',
                })
            }
            else {
                _winner = payload.winner.map((item) => {
                    return {
                        ...item,
                        winner_type: !item.winner_type ? "" : item.winner_type,
                    }
                });
            }
            
            let _priorityLastIdx = 0;
            payload.rent.forEach(item => {
                if(item.use_flag === 'Y') {
                    if(_priorityLastIdx < item.rent_seq) {
                        _priorityLastIdx = item.rent_seq;
                    }
                }
            });

            //  Get폴더 & Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.files.forEach(file => {
                if(file.file_data) {
                    //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                    if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                        _docFolder.push({
                            name: file.file_type_name,
                            code: file.file_type,
                        })
                    }
                    
                    //  문서종류 버튼의 첫번째 버튼을 자동선택
                    if(!_selectFoler) {
                        _selectFoler = file.file_type;
                    }

                    file.file_data.forEach(item => {
                        _docImages.push({
                            file_type: file.file_type,
                            file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                            file_data: item.file_url,
                            file_id: file.file_id,
                            rotation: item.rotation,
                        })
                    });

                    // const arrFile = file.file_data.split(',');
                    // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                    // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                    //     arrFile.forEach(item => {
                    //         _docImages.push({
                    //             file_type: file.file_type,
                    //             file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                    //             file_data: item,
                    //             file_id: file.file_id,
                    //             rotation: file.rotation,
                    //         })
                    //     });
                    // }
                    // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                    // else {
                    //     _docImages.push({
                    //         file_type: file.file_type,
                    //         file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                    //         file_data: file.file_data,
                    //         file_id: file.file_id,
                    //         rotation: file.rotation,
                    //     })
                    // }

                    if(_docImages.length > 0) {
                        _selectImg = {
                            src: _docImages[0].file_data,
                            file_id: _docImages[0].file_id,
                            rotation: _docImages[0].rotation,
                        }
                    }
                }
            });

            return {
                ...state,
                doc_status: payload.entitle[0].status,

                winnerLastIdx: _winner.length,
                winner: _winner,

                broker_id: payload.entitle[0].broker_id,
                broker_company: payload.entitle[0].broker_company,
                broker_name: payload.entitle[0].broker_name,
                broker_telno: payload.entitle[0].broker_phone,
                broker_reg_no: payload.entitle[0].broker_reg_no,
                road_addr: payload.entitle[0].road_addr,
                road_addr_dtl: payload.entitle[0].road_addr_dtl,
                jibun_addr: payload.entitle[0].jibun_addr,
                sido: payload.entitle[0].sido,
                sigungu: payload.entitle[0].sigungu,
                town: payload.entitle[0].town,
                road_name: payload.entitle[0].road_name,
                building_num: payload.entitle[0].building_num,
                building_name: payload.entitle[0].building_name,
                house_type: payload.entitle[0].house_type,
                house_area: payload.entitle[0].house_area,
                room_count: payload.entitle[0].room_count,
                house_selling: payload.entitle[0].house_selling,
                house_area_over1: payload.entitle[0].house_area_over1 === "Y" ? true : false,
                house_area_over2: payload.entitle[0].house_area_over2 === "Y" ? true : false,
                house_rent_type: payload.entitle[0].rent_type,
                rent_amt: payload.entitle[0].rent_amt,
                monthly_rent_amt: payload.entitle[0].monthly_rent_amt,
                minor_child_cnt: payload.entitle[0].minor_child_cnt,
                estate_no: payload.entitle[0].estate_no,
                wallpaper_flag: payload.entitle[0].wallpaper_flag,

                priorityLastIdx: _priorityLastIdx + 1,
                priority: payload.rent,

                rent_tot_amt: payload.entitle[0].rent_tot_amt,

                originFileList: payload.files,

                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                selectImg: _selectImg,
            }
        },
        [GET_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [DELETE_CONTENT_SUCCESS]: (state, { payload }) => {
            ALERT("신청서가 삭제되었습니다.");
            return {
                ...state,
            }
        },
        [DELETE_CONTENT_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error
            }
        },

        [SET_DOC_ID]: (state, { payload }) => {
            return {
                ...state,
                doc_id: payload,
            }
        },

        //  Step1   =======================================================================================
        [S1_SET_LAST_IDX]: (state, { payload: {value}}) => ({
            ...state,
            winnerLastIdx: value,
        }),
        [S1_ADD_ROW]: (state) => {
            return {
                ...state,
                winner: state.winner.concat({
                            idx: state.winnerLastIdx,
                            contract_type: '',
                            winner_type: '',
                            winner_type_name: '',
                            winner_id: '',
                            winner_name: '',
                            winner_no: '',
                            winner_addr: '',
                            winner_tel: '',
                            winner_phone: '',
                            family_cnt: 0,
                            charge_rate: null,
                            winner_charges: '',
                            add_charges: '',
                            monthly_rent: '',
                            lh_support_amt: '',
                            type_calc_code: '',
                            is_duplicate: '',
                            info_yn: '',
                            select_win_type: '',
                        }),
            }
        },
        [S1_REMOVE_ROW]: (state, { payload: {idx}}) => {
            const _winner = state.winner.filter((item) => item.idx !== idx);

            //  순서 재정의
            let rowNum = 0;
            _winner.forEach(item => {
                item.idx = rowNum++;
            });

            return {
                ...state,
                winner: _winner,
                winnerLastIdx: rowNum - 1,
            };
        },
        [S1_ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _winner = state.winner.map((item) =>
                item.idx === idx ? { ...item, [key]: value } : item,
            );
            return {
                ...state,
                winner: _winner,
            };
        },
        [S1_SELECT_USER]: (state, { payload: {user, row} }) => {
            const _winner = state.winner.map((item) =>
                item.idx === row.idx ? { ...item,
                    contract_type: '',
                    winner_type: !user.type ? '' : user.type,
                    winner_type_name: user.type_name,
                    winner_id: user.winner_id,
                    winner_name: user.name,
                    winner_no: user.winner_no,
                    winner_addr: user.addr,
                    winner_tel: '',
                    winner_phone: user.phone,
                    family_cnt: user.childrun_cnt,
                    charge_rate: null,
                    winner_charges: '',
                    add_charges: '',
                    monthly_rent: '',
                    lh_support_amt: '',
                    type_calc_code: user.type_calc_code,
                    is_duplicate: user.is_duplicate,
                    info_yn: user.info_yn,
                    select_win_type: user.select_win_type,
                } : item,
            );
            return {
                ...state,
                winner: _winner,
            };
        },

        //  Step3   =======================================================================================
        [S3_ROW_CHANGE_VALUE]: (state, { payload: { idx, key, value } }) => {
            const _priority = state.priority.map((item) =>
                item.rent_seq === idx ? { ...item, [key]: value } : item,
            );

            // //  합계 계산
            // let _empty_tot_amt = 0;
            // _priority.map((item) => {
            //     if(item.use_flag === 'Y') {
            //         _empty_tot_amt += +(item.priority_payment.replace(/,/g, ""));
            //     }
            // });

            return {
                ...state,
                priority: _priority,
            };
        },
        [S3_PRIORITY_CHANGED]: (state, { payload: { idx, value } }) => {
            const _priority = state.priority.map((item) =>
                item.rent_seq === idx ? {
                    ...item,
                    priority_payment: String(value),
                } : item,
            );

            return {
                ...state,
                priority: _priority,
            };
        },
        [S3_SET_LAST_IDX]: (state, { payload: {value}}) => ({
            ...state,
            priorityLastIdx: value,
        }),
        [S3_ADD_ROW]: (state) => {
            return {
                ...state,
                priority: state.priority.concat({
                            rent_seq: state.priorityLastIdx,
                            empty_type: 'HOUSE_RENT',
                            rent_floor: '',
                            room_no: '',
                            room_cnt: '',
                            rent_deposit: '',
                            rent_fee: '',
                            priority_payment: '',
                            remark: '',
                            use_flag: 'Y',
                        }),
            }
        },
        [S3_REMOVE_ROW]: (state, { payload: {idx}}) => {
            // const _priority = state.priority.filter((item) => item.rent_seq !== idx);
            const _priority = state.priority.map((item) =>
                item.rent_seq === idx ?
                {
                    ...item,
                    use_flag: 'N',
                } : item,
            );

            return {
                ...state,
                priority: _priority,
            };
        },
        
        //  Step4   =======================================================================================
        [S4_SELECT_BROKER]: (state, { payload: {broker} }) => {
            return {
                ...state,
                broker_id: broker.user_id,
                broker_company: broker.comp_name,
                broker_name: broker.name,
                broker_telno: broker.phone,
                broker_reg_no: broker.reg_no,
            };
        },
        
        //  서류이미지   =======================================================================================
        [DOC_SELECT_FOLDER]: (state, { payload }) => {
            return {
                ...state,
                selectFoler: payload.code,
            };
        },
        [DOC_SELECT_IMG]: (state, { payload }) => {
            return {
                ...state,
                selectImg: {
                    src: payload.file_data,
                    file_id: payload.file_id,
                    rotation: !payload.rotation ? 0 : payload.rotation,
                    api_yn: payload.api_yn,
                }
            };
        },
        [DOC_UPLOAD_COMPLETE]: (state, { payload }) => {
            //  Get폴더 & Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.forEach(file => {
                if(file.file_data) {
                    //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                    if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                        _docFolder.push({
                            name: file.file_type_name,
                            code: file.file_type,
                        })
                    }
                    
                    //  문서종류 버튼의 첫번째 버튼을 자동선택
                    if(!_selectFoler) {
                        _selectFoler = file.file_type;
                    }

                    file.file_data.forEach(item => {
                        _docImages.push({
                            file_type: file.file_type,
                            file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                            file_data: item.file_url,
                            file_id: file.file_id,
                            rotation: item.rotation,
                        })
                    });

                    // const arrFile = file.file_data.split(',');
                    // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                    // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                    //     arrFile.forEach(item => {
                    //         _docImages.push({
                    //             file_type: file.file_type,
                    //             file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                    //             file_data: item,
                    //             file_id: file.file_id,
                    //             rotation: file.rotation,
                    //         })
                    //     });
                    // }
                    // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                    // else {
                    //     _docImages.push({
                    //         file_type: file.file_type,
                    //         file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                    //         file_data: file.file_data,
                    //         file_id: file.file_id,
                    //         rotation: file.rotation,
                    //     })
                    // }

                    if(_docImages.length > 0) {
                        _selectImg = {
                            src: _docImages[0].file_data,
                            file_id: _docImages[0].file_id,
                            rotation: _docImages[0].rotation,
                        }
                    }
                }
            });

            return {
                ...state,
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                selectImg: _selectImg,
            }
        },
        [IMG_ROTATION_SUCCESS]: (state, { payload }) => {
            ALERT("선택한 이미지의 방향이 저장되었습니다.");
            return {
                ...state,
            };
        },
        [IMG_ROTATION_FAILURE]: (state, { payload: error }) => {
            return {
                ...state,
                error,
            };
        },
        [GET_FILES_SUCCESS]: (state, { payload }) => {
            //  Get폴더 & Get이미지
            let _docFolder = [], _docImages = [];
            let _selectFoler, _selectImg;
            payload.forEach(file => {
                if(file.file_data) {
                    //  우측 이미지 영역 하단의 문서종류 버튼에 추가
                    if(_docFolder.findIndex(i => i.code === file.file_type) < 0) {
                        _docFolder.push({
                            name: file.file_type_name,
                            code: file.file_type,
                        })
                    }
                    
                    //  문서종류 버튼의 첫번째 버튼을 자동선택
                    if(!_selectFoler) {
                        _selectFoler = file.file_type;
                    }

                    file.file_data.forEach(item => {
                        _docImages.push({
                            file_type: file.file_type,
                            file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                            file_data: item.file_url,
                            file_id: file.file_id,
                            rotation: item.rotation,
                        })
                    });

                    // const arrFile = file.file_data.split(',');
                    // //  file_data에 여러개의 이미지 파일이 한번에 온다면 file_data 분리해서 docImages에 추가
                    // if(arrFile.length > 1 && file.file_data.indexOf('data:') === -1) {
                    //     arrFile.forEach(item => {
                    //         _docImages.push({
                    //             file_type: file.file_type,
                    //             file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                    //             file_data: item,
                    //             file_id: file.file_id,
                    //             rotation: file.rotation,
                    //         })
                    //     });
                    // }
                    // //  단일 이미지 파일이라면 file_data 그대로 docImages에 추가
                    // else {
                    //     _docImages.push({
                    //         file_type: file.file_type,
                    //         file_type_name: !file.file_type_name ? '기타' : file.file_type_name,
                    //         file_data: file.file_data,
                    //         file_id: file.file_id,
                    //         rotation: file.rotation,
                    //     })
                    // }

                    if(_docImages.length > 0) {
                        _selectImg = {
                            src: _docImages[0].file_data,
                            file_id: _docImages[0].file_id,
                            rotation: _docImages[0].rotation,
                        }
                    }
                }
            });

            return {
                ...state,
                docFolder: _docFolder,
                selectFoler: _selectFoler,
                docImages: _docImages,
                selectImg: _selectImg,
            }
        },
        [GET_FILES_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),

        //  저장   =======================================================================================
        [SAVE_SUCCESS]: (state, { payload }) => {
            ALERT("저장되었습니다");
            return ({
                ...state,
                error: null,
                doc_id: payload,
            })
        },
        [SAVE_FAILURE]: (state, { payload: error }) => {
            return ({
                ...state,
                error,
            })
        },

        [REQ_ENTITLE_SUCCESS]: (state, { payload }) => {
            ALERT("지원서 신청이 완료되었습니다.", payload);
            return ({
                ...state,
                error: null,
            })
        },
        [REQ_ENTITLE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [SEND_SMS_SUCCESS]: (state, { payload }) => {
            return ({
                ...state,
                error: null,
            })
        },
        [SEND_SMS_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default enWriteAdmin;