import React, { useEffect, useRef, useState } from 'react';
import EntitleWork from '../components/EntitleWork';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { regAmt, parseNumber, decimalAdjust, addZoom } from '../common';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveApproval, changeNumber, changeValue, doc_selectFolder, doc_selectImg, getCombo, getContent, initialize, save, setStatusApi, setStatus, uploadComplete, feedback, s1_rowChangeValue, reqApproval, sendSms, savePriority, save2, pwdCheck, feedbackCancel, imgRotation, getFiles, removeEntitle } from '../modules/entitleWork';
import dataURL from '../lib/dataURL';
import { funEntitleCalc, dataEntitleCalc } from '../lib/dataEntitleCalc';
import { dataEmptyRoomCalc, dataEmptyRoomTable } from '../lib/dataEmptyRoomCalc';
import { dataSystemCode } from '../lib/dataSystemCode';
import waiting from '../waiting.png';
import { ALERT } from '../lib/dataWord';
// import { save as savePriority } from '../modules/Modal/enPriority';

const EntitleWorkContainer = () => {
    const [isShowDocUpload, setIsShowDocUpload] = useState(false);
    const [isShowDocFail, setIsShowDocFail] = useState(null);
    // const [isShowApprove, setIsShowApprove] = useState(false);
    const [isCanChange, setIsCanChange] = useState(false);
    const [isShowMemo, setIsShowMemu] = useState(false);
    const [isShowPriority, setIsShowPriority] = useState(false);
    const [refType, setRefType] = useState(true);
    const [isNeedApiRequest, setIsNeedApiRequest] = useState('');
    const [cboRentSido, setCboRentSido] = useState([]);
    const [isChangeApi, setIsChangeApi] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowFeedback, setIsShowFeedback] = useState(false);
    const [isShowWinner, setIsShowWinner] = useState(false);
    const [isApprove, setIsApprove] = useState(false);  //결재 승인 action 여부
    const [isShowEnModify, setIsShowEnModify] = useState(false);  //검토서 변경요청 사유 모달
    const [possibleRate, setPossibleRate] = useState([]);   // 입주자부담금 비율
    const [possibleAmt, setPossibleAmt] = useState(0);   // 입주자부담금 금액

    const cboContract = dataSystemCode.LEASE_TYPE;      //계약유형 ex) 신규,이주...
    const cboRent = dataSystemCode.RENT_TYPE;           //전세유형 ex) 전세,보증부월세...
    const cboHousing = dataSystemCode.HOUSING_TYPE;     //주택유형 ex) 아파트,다가구주택...
    const cboUseYN = dataSystemCode.USE_YN;
    const cboPricing = dataSystemCode.CRITERIA_PRICING; //가격결정기준 ex) 공시지가, KB시세...
    const cboEmptyType = dataSystemCode.EMPTY_TYPE;     //공실 구분

    const imgFolderDiv = useRef(null);
    const imgFolderSlider = useRef(null);
    const txtFolderDiv = useRef(null);
    const txtFolderSlider = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state: {docNo, func}} = location;

    const {
        user
    } = useSelector(({user}) => ({
        user: user.user
    }))
    const {
        loginUserId,
        doc_status,
        app_user,
        appcheck_user,
        isFeedback,
        feedback_answer,
        call_memo,
        call_check,
        entitle_user,
        // entitle_user_name,
        // appr_complete_date,

        approval,
        reject_reason,

        winner,

        winner_type,
        winner_type_name,
        contract_type,
        contract_type_name,
        rent_type,
        // rent_type_name,
        req_cnt,
        end_date,
        wallpaper_flag,
        minor_child_cnt,

        addr_duplicate,
        house_type,
        house_owner,
        req_duplicate,
        road_addr,
        road_addr_dtl,
        address_memo,
        jibun,
        sido,
        sigungu,
        town,
        house_area,
        rent_amt,
        support_amt,
        ground_area,
        share_area,
        winner_charges,
        winner_add_charges,
        small_room_support,
        monthly_rent,
        guarantee_monthly_rent,
        securing_bonds,
        broker_company,
        broker_telno,
        family_cnt,
        estate_no,
        room_count,
        senior_rent_yn,
        
        house_amt,
        decide_how,
        decide_date,
        decide_amt,
        possible_bond_amt,
        first_bond_amt,
        mortgage_amt,
        pre_deposit_total,
        pre_rent_loans,
        pre_charge_amt,
        pre_interest_free,
        rent_set_amt,
        seizure_amt,
        total_room_cnt,
        rent_room_cnt,
        already_rent_amt,
        empty_room_cnt,
        securing_bond_amt,
        remark,
        difference_amt,
        debt_ratio,
        suitable_flag,

        //  API 요청 State
        addr_req,
        building_state_no,
        use_yn,

        //  검토서 변경요청 데이터
        work_change_date,
        work_change_req,
        work_change_user,

        //  서류폴더, 서류이미지
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        selectTxtFolder,
        txtFolder,
        
        //  선순위
        rent,
        senior_sido,
        rent_tot_amt,
        empty_tot_amt,
        
        cboApply,

        fileDataAll,

        //  등기부등본 주소
        use_address,

        //  저장완료 flag
        isSaveOK,

        //  권리분석 패스워드 일치 여부
        user_pwd,
        pwdCheckResult,
        
        work,
        error,
        loading,
    } = useSelector(({ entitleWork, loading }) => ({
        loading: loading['entitleWork/GET_CONTENT'],
        loginUserId: entitleWork.loginUserId,
        doc_status: entitleWork.doc_status,
        app_user: entitleWork.app_user,
        appcheck_user: entitleWork.appcheck_user,
        isFeedback: entitleWork.isFeedback,
        feedback_answer: !entitleWork.feedback_answer ? "" : entitleWork.feedback_answer,
        call_memo: entitleWork.call_memo,
        call_check: entitleWork.call_check,
        entitle_user: entitleWork.entitle_user,
        // entitle_user_name: entitleWork.entitle_user_name,
        // appr_complete_date: entitleWork.appr_complete_date,

        approval: entitleWork.approval,
        reject_reason: entitleWork.reject_reason,

        winner: entitleWork.winner.map((item) => {
            return ({
                ...item,
                lh_support_amt: String(item.lh_support_amt).replace(regAmt, ","),
                winner_charges: String(item.winner_charges).replace(regAmt, ","),
                add_charges: String(item.add_charges).replace(regAmt, ","),
                monthly_rent: String(item.monthly_rent).replace(regAmt, ","),
            })
        }),

        winner_type: entitleWork.winner_type,
        winner_type_name: entitleWork.winner_type_name,
        contract_type: entitleWork.contract_type,
        contract_type_name: entitleWork.contract_type_name,
        rent_type: entitleWork.rent_type,
        // rent_type_name: entitleWork.rent_type_name,
        req_cnt: !entitleWork.req_cnt ? "" : String(entitleWork.req_cnt).replace(regAmt, ","),
        end_date: !entitleWork.end_date ? "" : entitleWork.end_date,
        wallpaper_flag: entitleWork.wallpaper_flag,
        minor_child_cnt: entitleWork.minor_child_cnt,

        addr_duplicate: entitleWork.addr_duplicate,
        house_type: entitleWork.house_type,
        house_owner: !entitleWork.house_owner ? "" : entitleWork.house_owner,
        req_duplicate: !entitleWork.req_duplicate ? "N" : entitleWork.req_duplicate,
        road_addr: entitleWork.road_addr,
        road_addr_dtl: !entitleWork.road_addr_dtl ? "" : entitleWork.road_addr_dtl,
        address_memo: !entitleWork.address_memo ? "" : entitleWork.address_memo,
        jibun: !entitleWork.jibun ? "" : entitleWork.jibun,
        sido: entitleWork.sido,
        sigungu: entitleWork.sigungu,
        town: entitleWork.town,
        house_area: !entitleWork.house_area ? "" : entitleWork.house_area,
        rent_amt: !entitleWork.rent_amt ? "" : String(entitleWork.rent_amt).replace(regAmt, ","),
        support_amt: !entitleWork.support_amt ? "" : String(entitleWork.support_amt).replace(regAmt, ","),
        ground_area: !entitleWork.ground_area ? "" : entitleWork.ground_area,
        share_area: !entitleWork.share_area ? "" : entitleWork.share_area,
        winner_charges: !entitleWork.winner_charges ? "" : String(entitleWork.winner_charges).replace(regAmt, ","),
        winner_add_charges: !entitleWork.winner_add_charges ? "" : String(entitleWork.winner_add_charges).replace(regAmt, ","),
        small_room_support: !entitleWork.small_room_support ? "" : String(entitleWork.small_room_support).replace(regAmt, ","),
        monthly_rent: !entitleWork.monthly_rent ? "" : String(entitleWork.monthly_rent).replace(regAmt, ","),
        guarantee_monthly_rent: !entitleWork.guarantee_monthly_rent ? "" : String(entitleWork.guarantee_monthly_rent).replace(regAmt, ","),
        securing_bonds: !entitleWork.securing_bonds ? "" : entitleWork.securing_bonds,
        broker_company: !entitleWork.broker_company ? "" : entitleWork.broker_company,
        broker_telno: !entitleWork.broker_telno ? "" : entitleWork.broker_telno,
        family_cnt: !entitleWork.family_cnt ? "0" : entitleWork.family_cnt,
        estate_no: !entitleWork.estate_no ? "" : entitleWork.estate_no,
        room_count: !entitleWork.room_count ? "" : entitleWork.room_count,
        senior_rent_yn: !entitleWork.senior_rent_yn ? "Y" : entitleWork.senior_rent_yn,
        
        house_amt: !entitleWork.house_amt ? "" : String(entitleWork.house_amt).replace(regAmt, ","),
        decide_how: !entitleWork.decide_how ? "TYPE01" : entitleWork.decide_how,
        decide_date: !entitleWork.decide_date ? (
            //  오늘이 6월 이후라면 현재 년 1월1일, 6월 이전이라면 작년 1월1일이 Default.
            // new Date().getMonth() + 1 >= 6 ? new Date().getFullYear() + '-01-01' : new Date().getFullYear() - 1 + '-01-01'
            '2022-01-01'    // 우선은 공식 없이 강제로 입력
        ) : entitleWork.decide_date,
        decide_amt: !entitleWork.decide_amt ? "" : String(entitleWork.decide_amt).replace(regAmt, ","),
        possible_bond_amt: !entitleWork.possible_bond_amt ? "" : String(entitleWork.possible_bond_amt).replace(regAmt, ","),
        first_bond_amt: !entitleWork.first_bond_amt ? "" : String(entitleWork.first_bond_amt).replace(regAmt, ","),
        mortgage_amt: !entitleWork.mortgage_amt ? "" : String(entitleWork.mortgage_amt).replace(regAmt, ","),
        pre_deposit_total: !entitleWork.pre_deposit_total ? "" : String(entitleWork.pre_deposit_total).replace(regAmt, ","),
        pre_rent_loans: !entitleWork.pre_rent_loans ? "" : String(entitleWork.pre_rent_loans).replace(regAmt, ","),
        pre_charge_amt: !entitleWork.pre_charge_amt ? "" : String(entitleWork.pre_charge_amt).replace(regAmt, ","),
        pre_interest_free: !entitleWork.pre_interest_free ? "" : String(entitleWork.pre_interest_free).replace(regAmt, ","),
        rent_set_amt: !entitleWork.rent_set_amt ? "" : String(entitleWork.rent_set_amt).replace(regAmt, ","),
        seizure_amt: !entitleWork.seizure_amt ? "" : String(entitleWork.seizure_amt).replace(regAmt, ","),
        total_room_cnt: !entitleWork.total_room_cnt ? "" : String(entitleWork.total_room_cnt).replace(regAmt, ","),
        rent_room_cnt: !entitleWork.rent_room_cnt ? "" : String(entitleWork.rent_room_cnt).replace(regAmt, ","),
        already_rent_amt: !entitleWork.already_rent_amt ? "" : String(entitleWork.already_rent_amt).replace(regAmt, ","),
        empty_room_cnt: !entitleWork.empty_room_cnt ? "" : String(entitleWork.empty_room_cnt).replace(regAmt, ","),
        securing_bond_amt: !entitleWork.securing_bond_amt ? "" : String(entitleWork.securing_bond_amt).replace(regAmt, ","),
        remark: !entitleWork.remark ? "" : entitleWork.remark,
        difference_amt: !entitleWork.difference_amt ? "" : String(entitleWork.difference_amt).replace(regAmt, ","),
        debt_ratio: !entitleWork.debt_ratio ? "" : String(entitleWork.debt_ratio).replace(regAmt, ","),
        suitable_flag: entitleWork.suitable_flag,

        //  API 요청 State
        addr_req: entitleWork.addr_req,
        building_state_no: entitleWork.building_state_no,
        use_yn: entitleWork.use_yn,

        //  검토서 변경요청 데이터
        work_change_date: entitleWork.work_change_date,
        work_change_req: entitleWork.work_change_req,
        work_change_user: entitleWork.work_change_user,

        selectFoler: entitleWork.selectFoler,
        docFolder: entitleWork.docFolder,
        selectImg: entitleWork.selectImg,
        docImages: entitleWork.docImages,
        selectTxtFolder: entitleWork.selectTxtFolder,
        txtFolder: entitleWork.txtFolder,

        cboApply: Array.isArray(entitleWork.cboApply) ? entitleWork.cboApply.filter(item => item.use_flag === 'Y') : [],    // 사용 플래그 Y인 것들만 사용

        fileDataAll: entitleWork.fileDataAll,

        rent: entitleWork.rent,
        senior_sido: !entitleWork.senior_sido ? "서울 강남구" : entitleWork.senior_sido,
        rent_tot_amt: !entitleWork.rent_tot_amt ? "" : String(entitleWork.rent_tot_amt).replace(regAmt, ","),
        empty_tot_amt: !entitleWork.empty_tot_amt ? "" : String(entitleWork.empty_tot_amt).replace(regAmt, ","),

        use_address: entitleWork.use_address,

        isSaveOK: entitleWork.isSaveOK,

        //  권리분석 패스워드 일치 여부
        user_pwd: entitleWork.user_pwd,
        pwdCheckResult: entitleWork.pwdCheckResult,
        
        work: entitleWork.work,
        error: entitleWork.error,
    }));
    
    const onChange = e => {
        const {target: {name, value}} = e;

        if(name === 'senior_sido') {
            let _senior_rent = [];  //
            let _rent = [];
            let _empty_tot_amt = 0;
            rent.forEach((el) => {
                //  단, 공실 또는 집주인이 사용중인 경우에만 실행한다.
                if((el.empty_type2.indexOf("EMPTY") > -1) || el.empty_type2.indexOf("OWNER") > -1) {
                    const priorityData = +(dataEmptyRoomCalc({
                        addressStr: value,
                        emptyType: (el.empty_type2.indexOf("HOUSE") > -1 ? 'h' : 's'),
                    }))

                    if(el.empty_type2.indexOf("HOUSE") > -1) {
                        _senior_rent.push({
                            rent_seq: el.rent_seq,
                            empty_type: el.empty_type2,
                            rent_floor: el.rent_floor2,
                            room_no: el.room_no2,
                            room_cnt: el.room_cnt2? el.room_cnt2 : 0,
                            rent_deposit: "0",
                            rent_fee: "0",
                            priority_payment: String(priorityData * (el.room_cnt2? +el.room_cnt2 : 0)),
                            remark: el.remark2,
                            use_flag: el.use_flag,
                        })
                        _rent.push({
                            empty_type2: el.empty_type2,
                            empty_type2_name: el.empty_type2_name,
                            priority_payment2: String(priorityData * (el.room_cnt2? +el.room_cnt2 : 0)),
                            remark2: el.remark2,
                            rent_deposit2: el.rent_deposit2,
                            rent_fee2: el.rent_fee2,
                            rent_floor2: el.rent_floor2,
                            rent_seq: el.rent_seq,
                            room_cnt2: el.room_cnt2,
                            room_no2: el.room_no2,
                            use_flag: el.use_flag,
                        })
                        _empty_tot_amt += priorityData * (el.room_cnt2? +el.room_cnt2 : 0);
                    }
                    else {
                        _senior_rent.push({
                            rent_seq: el.rent_seq,
                            empty_type: el.empty_type2,
                            rent_floor: el.rent_floor2,
                            room_no: el.room_no2,
                            room_cnt: el.room_cnt2? el.room_cnt2 : 0,
                            rent_deposit: "0",
                            rent_fee: "0",
                            priority_payment: String(priorityData),
                            remark: el.remark2,
                            use_flag: el.use_flag,
                        })
                        _rent.push({
                            empty_type2: el.empty_type2,
                            empty_type2_name: el.empty_type2_name,
                            priority_payment2: String(priorityData),
                            remark2: el.remark2,
                            rent_deposit2: el.rent_deposit2,
                            rent_fee2: el.rent_fee2,
                            rent_floor2: el.rent_floor2,
                            rent_seq: el.rent_seq,
                            room_cnt2: el.room_cnt2,
                            room_no2: el.room_no2,
                            use_flag: el.use_flag,
                        })
                        _empty_tot_amt += priorityData;
                    }
                }
                else {
                    _senior_rent.push({
                        rent_seq: el.rent_seq,
                        empty_type: el.empty_type2,
                        rent_floor: el.rent_floor2,
                        room_no: el.room_no2,
                        room_cnt: el.room_cnt2 !== ''? el.room_cnt2 : '0',
                        rent_deposit: "0",
                        rent_fee: "0",
                        priority_payment: !el.priority_payment ? "0" : el.priority_payment,
                        remark: el.remark2,
                        use_flag: el.use_flag,
                    });
                    _rent.push({
                        empty_type2: el.empty_type2,
                        empty_type2_name: el.empty_type2_name,
                        priority_payment2: !el.priority_payment2 ? "0" : el.priority_payment2.replace(/,/g, ""),
                        remark2: el.remark2,
                        rent_deposit2: el.rent_deposit2,
                        rent_fee2: el.rent_fee2,
                        rent_floor2: el.rent_floor2,
                        rent_seq: el.rent_seq,
                        room_cnt2: el.room_cnt2,
                        room_no2: el.room_no2,
                        use_flag: el.use_flag,
                    })
                    _empty_tot_amt += Number(el.priority_payment2.replace(/,/g, ""));
                }
            })
    
            dispatch(
                savePriority({
                    doc_id: docNo,
                    rent_tot_amt: String(rent_tot_amt).replace(/,/g, "") !== '' ? String(rent_tot_amt).replace(/,/g, "")  : '0',
                    empty_tot_amt: String(_empty_tot_amt) !== '' ?  String(_empty_tot_amt) : '',
                    senior_rent: _senior_rent,
                    senior_sido: value,
                })
            )
            dispatch(
                changeValue({
                    key: 'rent',
                    value: _rent,
                })
            )
            dispatch(
                changeValue({
                    key: 'empty_tot_amt',
                    value: _empty_tot_amt,
                })
            )
        }

        if(name === 'senior_rent_yn') {
            if(value === 'Y') {
                //  선순위계산
                let roomCnt = 0, emptyCnt = 0, rentCnt = 0;

                rent.forEach(item => {
                    if(item.use_flag === 'Y') {
                        //  주택의 경우 방개수를, 주택이 아닌 경우 0로 계산하여 집계한다.
                        roomCnt += item.empty_type2.indexOf('HOUSE') > -1 ? +item.room_cnt2 : 0;
                        
                        //  주택의 경우 방개수를, 주택이 아닌 경우 0로 계산하여 집계한다.
                        if(item.empty_type2.indexOf('EMPTY') > -1 || item.empty_type2.indexOf('OWNER') > -1) {
                            emptyCnt += item.empty_type2.indexOf('HOUSE') > -1 ? +item.room_cnt2 : 0;
                        }

                        //  22.05.04 온결 요청 :: 임차 방개수는 선순위에 입력된 '입주희망' 호실의 방개수로 적용한다.
                        if(item.empty_type2 === 'HOUSE_WANT') {
                            rentCnt = +item.room_cnt2;
                        }
                    }
                });

                dispatch(
                    changeValue({
                        key: 'total_room_cnt',
                        value: roomCnt,
                    })
                )
                dispatch(
                    changeValue({
                        key: 'empty_room_cnt',
                        value: emptyCnt,
                    })
                )
                dispatch(
                    changeValue({
                        key: 'rent_room_cnt',
                        value: rentCnt,
                    })
                )
            }
            else {
                dispatch(
                    changeValue({
                        key: 'total_room_cnt',
                        value: room_count,
                    })
                )
                dispatch(
                    changeValue({
                        key: 'rent_room_cnt',
                        value: room_count,
                    })
                )
                dispatch(
                    changeValue({
                        key: 'empty_room_cnt',
                        value: '0',
                    })
                )
            }
        }

        if(name === 'rent_type') {
            if(value === 'TYPE01') {
                dispatch(
                    changeValue({
                        key: 'guarantee_monthly_rent',
                        value: '0',
                    })
                );
            }
        }

        dispatch(
            changeValue({
                key: name,
                value,
            })
        );
    }
    //  금액변경 :: 숫자만 입력가능하며 천자리 콤마 삽입
    const onChangeNum = e => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);

        if (value === '' || re.test(value)) {
            dispatch(
                changeNumber({
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );

            if(e.target.name === 'room_count' && senior_rent_yn === 'N') {
                dispatch(
                    changeValue({
                        key: 'total_room_cnt',
                        value: value.replace(regAmt, ","),
                    })
                )
                dispatch(
                    changeValue({
                        key: 'rent_room_cnt',
                        value: value.replace(regAmt, ","),
                    })
                )
            }
        }
    }
    
    //  Image Event -------------------------------------------
    const onSelectFolder = (item, e) => {
        dispatch(doc_selectFolder(item));
        
        const firstInFolder = docImages.find(x => x.docCode === item.code);
        dispatch(doc_selectImg(firstInFolder));
        initImgScale();
    }
    const onSelectImg = (item, e) => {
        dispatch(doc_selectImg(item));
        initImgScale();
    }
    const initImgScale = () => {
        const initImg = document.getElementById("selectImg");
        initImg.style.transform = 'scale(1)';
        const divRotate = document.getElementById("div-rotate");
        divRotate.style.transform = 'rotate(' + selectImg.rotation + 'deg)';
        divRotate.style.left = '0px';
        divRotate.style.top = '0px';
    }
    //  onPrevClick, onNextClick :: 현재 imgFolderSlider가 차지하는 너비만큼 버튼이 이동될 수 있도록 함.
    //  이전 폴더 Slider
    const onPrevClick = (type) => {
        if(type === "IMG") {
            let movePx = Math.floor(imgFolderDiv.current.clientWidth / 130) * 130;
            
            if (imgFolderSlider.current.offsetLeft < 0) {
                const nextPostion = imgFolderSlider.current.offsetLeft + movePx;
                imgFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
        else {
            let movePx = Math.floor(txtFolderDiv.current.clientWidth / 130) * 130;
            
            if (txtFolderSlider.current.offsetLeft < 0) {
                const nextPostion = txtFolderSlider.current.offsetLeft + movePx;
                txtFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
    }
    //  다음 폴더 Slider
    const onNextClick = (type) => {
        if(type === "IMG") {
            let movePx = Math.floor(imgFolderDiv.current.clientWidth / 130) * 130;
            
            const nextPostion = imgFolderSlider.current.offsetLeft - movePx;
            if (Math.abs(nextPostion) < imgFolderSlider.current.clientWidth) {
                imgFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
        else {
            let movePx = Math.floor(txtFolderDiv.current.clientWidth / 130) * 130;
            
            const nextPostion = txtFolderSlider.current.offsetLeft - movePx;
            if (Math.abs(nextPostion) < txtFolderSlider.current.clientWidth) {
                txtFolderSlider.current.style = "left:" + nextPostion + "px";
            }
        }
    }
    const onRefChange = (type) => {
        setRefType(type);
    }
    const onSelectTxtFolder = (item, e) => {
        dispatch(
            changeValue({
                key: 'selectTxtFolder',
                value: item.code,
            })
        )
    }
    const onWaiting = (e) => {
        e.target.src = waiting;
    }
    const onImgRotation = () => {
        const divRotate = document.getElementById("div-rotate");

        const regex = /[^0-9]/g;    // 숫자가 아닌 문자열을 선택하는 정규식
        const currRotate = +divRotate.style.transform.replace(regex, "");

        let nextRotate;
        switch(currRotate) {
            case 0: nextRotate = 90; break;
            case 90: nextRotate = 180; break;
            case 180: nextRotate = 270; break;
            case 270: nextRotate = 0; break;
            default: nextRotate = 0; break;
        }
        dispatch(changeValue({
            key: 'selectImg',
            value: {
                ...selectImg,
                rotation: nextRotate,
            }
        }))
    }
    const onImgRotateSave = () => {
        if(!selectImg.file_id) {
            ALERT("첨부 이미지 새로고침 후 다시 시도해주세요.");
            return;
        }

        const divRotate = document.getElementById("div-rotate");
        const regex = /[^0-9]/g;    // 숫자가 아닌 문자열을 선택하는 정규식
        const currRotate = +divRotate.style.transform.replace(regex, "");
        dispatch(
            imgRotation({
                doc_id: docNo,
                file_id: selectImg.file_id,
                file_url: selectImg.src,
                rotation: currRotate,
            })
        )

        //  썸네일 방향 변경
        const _docImages = docImages.map((item) =>
            (item.file_id === selectImg.file_id && item.imgSrc === selectImg.src) ? { ...item, rotation: currRotate } : item,
        );
        dispatch(changeValue({
            key: 'docImages',
            value: _docImages,
        }))
    }
    const onRefreshImg = () => {
        dispatch(
            getFiles({
                doc_id: docNo,
                doc_type: "EN_WRITE,ENTITLE",
            })
        )
    }
    //  ------------------------------------------- Image Event

    //  콜센터 메모
    const onMemoClick = () => {
        setIsShowMemu(!isShowMemo);
    }
    //  콜센터 메모 확인
    const onMemoComplete = (yn, memo) => {
        dispatch(
            changeValue({
                key: 'call_check',
                value: yn,
            })
        )
        dispatch(
            changeValue({
                key: 'call_memo',
                value: memo,
            })
        )
    }
    //  검토서 변경요청 사유 확인 모달
    const onEnModifyReasonClick = () => {
        setIsShowEnModify(!isShowEnModify);
    }
    //  접수
    const onRegistClick = () => {
        if (window.confirm("접수하시겠습니까?")) {
            dispatch(
                setStatusApi({
                    doc_id: docNo,
                    status: "ENACK",
                    remark: "",
                    lack_reason: "",
                })
            )
            dispatch(
                changeValue({
                    key: 'entitle_user',
                    value: user.user_id,    //접수자 ID 변경
                })
            )

            // let _receiver = broker_telno + ',';
            // for(let i = 0; i < winner.length; i++) {
            //     const _winner = winner[i];
            //     _receiver += _winner.winner_phone + ",";
            // }
            // dispatch(sendSms({
            //     sender: "1600-4690",
            //     receiver: _receiver,
            //     msg: "전세임대지원 신청서가 접수되었습니다.",
            //     msg_type: "SMS",
            // }));
        }
    }
    //  검토파일 업로드
    const onDocUploadClick = () => {
        setIsShowDocUpload(!isShowDocUpload);
    }
    //  검토파일 업로드 완료
    const onUploadComplete = (uploadFiles) => {
        dispatch(uploadComplete(uploadFiles));
        setIsShowDocUpload(false);
    }
    //  서류분류
    const onDocSortClick = () => {
        navigate(dataURL.DocSortPage, {state : {docNo: docNo, docType: 'EN_WRITE,ENTITLE'}});
    }
    //  서류미비
    const onDocFailClick = () => {
        setIsShowDocFail('ENDOCFAIL');
    }
    //  서류미비 완료 스테이터스 변경
    const onDocFailComplete = (lack_reason) => {
        dispatch(setStatus('ENDOCFAIL'));

        let _receiver = broker_telno + ',';
        for(let i = 0; i < winner.length; i++) {
            const _winner = winner[i];
            _receiver += _winner.winner_phone + ",";
        }
        dispatch(sendSms({
            sender: "1600-4690",
            receiver: _receiver,
            msg: "서류미비 내용 : " + lack_reason,
            msg_type: "SMS",
        }));
    }
    //  서류확인
    const onDocCheckClick = () => {
        let confirmText;

        if(doc_status === "ENDOCFAIL") {
            confirmText = "미비서류가 보완되지 않았습니다.\n정말 이대로 검토하시겠습니까?";
        }
        else {
            confirmText = "제출서류를 모두 확인하셨습니까?";
        }

        if (window.confirm(confirmText)) {
            dispatch(
                setStatusApi({
                    doc_id: docNo,
                    status: "ENPROCESS",
                    remark: "",
                    lack_reason: "",
                })
            )
            // dispatch(sendSms({
            //     sender: "1600-4690",
            //     receiver: "01020471046",
            //     msg: "테스트 / 검토서 처리 시작",
            //     msg_type: "SMS",
            // }));
        }
    }
    //  담당자 변경요청 모달
    const onUserChangeClick = () => {
        setIsShowDocFail('ENCHGREQ');
    }
    //  담당자 변경요청 완료 스테이터스 변경
    const onUserChangeComplete = () => {
        dispatch(setStatus('ENCHGREQ'));
    }
    //  피드백요청
    const onFeedBackClick = () => {
        setIsShowDocFail('FEEDBACK');
    }
    //  피드백취소
    const onFeedBackCancel = () => {
        if(window.confirm('피드백 요청을 취소하시겠습니까?')) {
            dispatch(feedbackCancel({
                doc_id: docNo,
            }))
        }
    }
    //  피드백요청 완료 처리
    const onFeedbackComplete = (lack_reason) => {
        dispatch(
            feedback({
                doc_id: docNo,
                remark: lack_reason,
            })
        )
    }
    const onRefreshList = () => {
        //  Feedback 모달에 필요.
    }
    //  선순위 등록 모달
    const onPriorityClick = () => {
        setIsShowPriority(!isShowPriority);
    }
    //  선순위 등록 완료
    const onPriorityComplete = (pPriority, pRent_tot_amt, pEmpty_tot_amt) => {
        let roomCnt = 0, emptyCnt = 0, rentCnt = 0;

        pPriority.forEach(item => {
            if(item.use_flag === 'Y') {
                //  주택의 경우 방개수를, 주택이 아닌 경우 0로 계산하여 집계한다.
                roomCnt += item.empty_type2.indexOf('HOUSE') > -1 ? +item.room_cnt2 : 0;
                
                //  주택의 경우 방개수를, 주택이 아닌 경우 0로 계산하여 집계한다.
                if(item.empty_type2.indexOf('EMPTY') > -1 || item.empty_type2.indexOf('OWNER') > -1) {
                    emptyCnt += item.empty_type2.indexOf('HOUSE') > -1 ? +item.room_cnt2 : 0;
                }

                //  22.05.04 온결 요청 :: 임차 방개수는 선순위에 입력된 '입주희망' 호실의 방개수로 적용한다.
                if(item.empty_type2 === 'HOUSE_WANT') {
                    rentCnt = +item.room_cnt2;
                }
            }
        });

        dispatch(
            changeValue({
                key: 'total_room_cnt',
                value: pPriority.length === 0 ? room_count : roomCnt,
            })
        )
        dispatch(
            changeValue({
                key: 'empty_room_cnt',
                value: emptyCnt,
            })
        )
        dispatch(
            changeValue({
                key: 'rent_room_cnt',
                value: rentCnt,
            })
        )
        dispatch(
            changeValue({
                key: 'rent',
                value: pPriority,
            })
        )
        dispatch(
            changeValue({
                key: 'rent_tot_amt',
                value: pRent_tot_amt,
            })
        )
        dispatch(
            changeValue({
                key: 'empty_tot_amt',
                value: pEmpty_tot_amt,
            })
        )
    }
    //  저장
    const onSaveClick = () => {
        // let _winner_info = [];
        // winner.forEach(item => {
        //     _winner_info.push({
        //         winner_id: item.winner_id,
        //         winner_type: item.winner_type,
        //         charge_rate: !item.charge_rate ? "0" : item.charge_rate,
        //         winner_charges: String(winner_charges).replace(/,/g, ""),
        //         add_charges: String(winner_add_charges).replace(/,/g, ""),
        //         monthly_rent: String(monthly_rent).replace(/,/g, ""),
        //         guarantee_mon_amt: String(guarantee_monthly_rent).replace(/,/g, ""),
        //     })
        // });

        dispatch(
            save({
                doc_id: docNo,
                // road_addr: road_addr,
                road_addr_dtl: road_addr_dtl,
                address_memo: address_memo,
                sido: sido,
                sigungu: sigungu,
                town: town,
                contract_type: contract_type,
                house_type: house_type,
                house_area: house_area,
                rent_type: rent_type,
                rent_amt: !rent_amt ? "0" : String(rent_amt).replace(/,/g, ""),
                monthly_amt: !guarantee_monthly_rent ? "0" : String(guarantee_monthly_rent).replace(/,/g, ""),
                support_amt: !support_amt ? "0" : String(support_amt).replace(/,/g, ""),
                family_cnt: family_cnt,
                broker_company: broker_company,
                broker_phone: broker_telno,
                estate_no: estate_no,
                req_cnt: !req_cnt ? "0" : req_cnt,
                end_date: end_date,
                wallpaper_flag: wallpaper_flag,
                req_duplicate: req_duplicate,
                house_owner: house_owner,
                ground_area: ground_area,
                share_area: share_area,
                small_room_support: !small_room_support ? "0" : small_room_support,
                securing_bonds: securing_bonds,
                room_count: !room_count ? "" : String(room_count).replace(/,/g, ""),
                senior_rent_yn: senior_rent_yn,

                house_amt: !house_amt ? "0" : String(house_amt).replace(/,/g, ""),
                decide_how: decide_how,
                decide_date: decide_date,
                decide_amt: !decide_amt ? "0" : String(decide_amt).replace(/,/g, ""),
                possible_bond_amt: !possible_bond_amt ? "0" : String(possible_bond_amt).replace(/,/g, ""),
                first_bond_amt: !first_bond_amt ? "0" : String(first_bond_amt).replace(/,/g, ""),
                mortgage_amt: !mortgage_amt ? "0" : String(mortgage_amt).replace(/,/g, ""),
                rent_set_amt: !rent_set_amt ? "0" : String(rent_set_amt).replace(/,/g, ""),
                seizure_amt: !seizure_amt ? "0" : String(seizure_amt).replace(/,/g, ""),
                already_rent_amt: !already_rent_amt ? "0" : String(already_rent_amt).replace(/,/g, ""),
                securing_bond_amt: !securing_bond_amt ? "0" : String(securing_bond_amt).replace(/,/g, ""),
                difference_amt: !difference_amt ? "0" : String(difference_amt).replace(/,/g, ""),
                debt_ratio: !debt_ratio ? "0" : String(debt_ratio).replace(/,/g, ""),
                suitable_flag: suitable_flag,
                pre_deposit_total: !pre_deposit_total ? "0" : String(pre_deposit_total).replace(/,/g, ""),
                pre_rent_loans: !pre_rent_loans ? "0" : String(pre_rent_loans).replace(/,/g, ""),
                pre_charge_amt: !pre_charge_amt ? "0" : String(pre_charge_amt).replace(/,/g, ""),
                pre_interest_free: !pre_interest_free ? "0" : String(pre_interest_free).replace(/,/g, ""),
                total_room_cnt: !total_room_cnt ? "0" : total_room_cnt,
                rent_room_cnt: !rent_room_cnt ? "0" : rent_room_cnt,
                empty_room_cnt: !empty_room_cnt ? "0" : empty_room_cnt,
                remark: remark,
                use_flag: "Y",
                winner_info: winner.map((item) => {
                    return {
                        ...item,
                        lh_support_amt: !item.lh_support_amt ? "0" : item.lh_support_amt.replace(/,/g, ""),
                        winner_charges: !item.winner_charges ? "0" : item.winner_charges.replace(/,/g, ""),
                        add_charges: !item.add_charges ? "0" : item.add_charges.replace(/,/g, ""),
                        monthly_rent: !item.monthly_rent ? "0" : item.monthly_rent.replace(/,/g, ""),
                    }
                }),
                senior_sido: senior_sido,
                minor_child_cnt: minor_child_cnt,
            })
        )
    }
    //  결재요청
    const onReqApproveClick = () => {
        if(!decide_amt || +decide_amt === 0) {
            ALERT("주택 가격을 입력해주세요.");
            return;
        }

        if(isFeedback === 'N') {
            if (!window.confirm("피드백 요청 내용이 없습니다.\n그래도 결재를 요청하시겠습니까?")) {
                return;
            }
        }
        else if (!window.confirm("결재를 요청하시겠습니까?")) {
            return;
        }
        
        // let _winner_info = [];
        // winner.forEach(item => {
        //     _winner_info.push({
        //         winner_id: item.winner_id,
        //         winner_type: item.winner_type,
        //         charge_rate: !item.charge_rate ? "0" : item.charge_rate,
        //         winner_charges: String(winner_charges).replace(/,/g, ""),
        //         add_charges: String(winner_add_charges).replace(/,/g, ""),
        //         monthly_rent: String(monthly_rent).replace(/,/g, ""),
        //         guarantee_mon_amt: String(guarantee_monthly_rent).replace(/,/g, ""),
        //     })
        // });

        dispatch(
            save2({
                doc_id: docNo,
                // road_addr: road_addr,
                road_addr_dtl: road_addr_dtl,
                address_memo: address_memo,
                sido: sido,
                sigungu: sigungu,
                town: town,
                contract_type: contract_type,
                house_type: house_type,
                house_area: house_area,
                rent_type: rent_type,
                rent_amt: !rent_amt ? "0" : String(rent_amt).replace(/,/g, ""),
                monthly_amt: !guarantee_monthly_rent ? "0" : String(guarantee_monthly_rent).replace(/,/g, ""),
                support_amt: !support_amt ? "0" : String(support_amt).replace(/,/g, ""),
                family_cnt: family_cnt,
                estate_no: estate_no,
                broker_company: broker_company,
                broker_phone: broker_telno,
                room_count: !room_count ? "" : String(room_count).replace(/,/g, ""),
                senior_rent_yn: senior_rent_yn,
                req_cnt: !req_cnt ? "0" : req_cnt,
                end_date: end_date,
                wallpaper_flag: wallpaper_flag,
                req_duplicate: req_duplicate,
                house_owner: house_owner,
                ground_area: ground_area,
                share_area: share_area,
                small_room_support: !small_room_support ? "0" : small_room_support,
                securing_bonds: securing_bonds,

                house_amt: !house_amt ? "0" : String(house_amt).replace(/,/g, ""),
                decide_how: decide_how,
                decide_date: decide_date,
                decide_amt: !decide_amt ? "0" : String(decide_amt).replace(/,/g, ""),
                possible_bond_amt: !possible_bond_amt ? "0" : String(possible_bond_amt).replace(/,/g, ""),
                first_bond_amt: !first_bond_amt ? "0" : String(first_bond_amt).replace(/,/g, ""),
                mortgage_amt: !mortgage_amt ? "0" : String(mortgage_amt).replace(/,/g, ""),
                rent_set_amt: !rent_set_amt ? "0" : String(rent_set_amt).replace(/,/g, ""),
                seizure_amt: !seizure_amt ? "0" : String(seizure_amt).replace(/,/g, ""),
                already_rent_amt: !already_rent_amt ? "0" : String(already_rent_amt).replace(/,/g, ""),
                securing_bond_amt: !securing_bond_amt ? "0" : String(securing_bond_amt).replace(/,/g, ""),
                difference_amt: !difference_amt ? "0" : String(difference_amt).replace(/,/g, ""),
                debt_ratio: !debt_ratio ? "0" : String(debt_ratio).replace(/,/g, ""),
                suitable_flag: suitable_flag,
                pre_deposit_total: !pre_deposit_total ? "0" : String(pre_deposit_total).replace(/,/g, ""),
                pre_rent_loans: !pre_rent_loans ? "0" : String(pre_rent_loans).replace(/,/g, ""),
                pre_charge_amt: !pre_charge_amt ? "0" : String(pre_charge_amt).replace(/,/g, ""),
                pre_interest_free: !pre_interest_free ? "0" : String(pre_interest_free).replace(/,/g, ""),
                total_room_cnt: !total_room_cnt ? "0" : total_room_cnt,
                rent_room_cnt: !rent_room_cnt ? "0" : rent_room_cnt,
                empty_room_cnt: !empty_room_cnt ? "0" : empty_room_cnt,
                remark: remark,
                use_flag: "Y",
                winner_info: winner.map((item) => {
                    return {
                        ...item,
                        lh_support_amt: !item.lh_support_amt ? "0" : item.lh_support_amt.replace(/,/g, ""),
                        winner_charges: !item.winner_charges ? "0" : item.winner_charges.replace(/,/g, ""),
                        add_charges: !item.add_charges ? "0" : item.add_charges.replace(/,/g, ""),
                        monthly_rent: !item.monthly_rent ? "0" : item.monthly_rent.replace(/,/g, ""),
                    }
                }),
                senior_sido: senior_sido,
                minor_child_cnt: minor_child_cnt,
            })
        )
        dispatch(reqApproval({doc_id: docNo}))

        // dispatch(sendSms({
        //     sender: "1600-4690",
        //     receiver: "01020471046",
        //     msg: "테스트 / 검토서 결재요청",
        //     msg_type: "SMS",
        // }));
    }
    //  결재요청 완료 스테이터스 변경
    // const onReqApproveComplete = (apprList) => {
    //     setIsShowApprove(!isShowApprove);
    //     dispatch(setStatus('ENAPVREQ'));    //상태변경
    //     dispatch(
    //         changeValue({
    //             key: 'approval',
    //             value: apprList,
    //         })
    //     );  //선택한 결재라인 보여주기
    // }
    //  부적합 만료 팝업
    const onNonConfClick = () => {
        setIsShowDocFail('NONCONF');
    }
    //  부적합 만료 완료 스테이터스 변경
    const onNonConfComplete = () => {
        dispatch(setStatus('NONCONF'));
    }
    //  부적합 만료 복원
    const onNonConfRecoverClick = () => {
        if(window.confirm("정말 부적합 만료된 검토서를 복원합니까?")) {
            dispatch(
                setStatusApi({
                    doc_id: docNo,
                    status: "ENPROCESS",
                    remark: "",
                    lack_reason: "",
                })
            )
            ALERT("검토서가 복원되었습니다.");
        }
    }
    //  승인
    const onApproveClick = (type) => {
        if (window.confirm("해당 요청건의 결재를 승인합니까?")) {
            if(!decide_amt || +decide_amt === 0) {
                ALERT("주택 가격을 입력해주세요.");
                return;
            }
            // let _winner_info = [];
            // winner.forEach(item => {
            //     _winner_info.push({
            //         winner_id: item.winner_id,
            //         winner_type: item.winner_type,
            //         winner_charges: String(winner_charges).replace(/,/g, ""),
            //         add_charges: String(winner_add_charges).replace(/,/g, ""),
            //         monthly_rent: String(monthly_rent).replace(/,/g, ""),
            //         guarantee_mon_amt: String(guarantee_monthly_rent).replace(/,/g, ""),
            //     })
            // });
            if( type === 'APPROVAL' ) { // 승인의 경우에만 저장
                dispatch(
                    save2({
                        doc_id: docNo,
                        // road_addr: road_addr,
                        road_addr_dtl: road_addr_dtl,
                        address_memo: address_memo,
                        sido: sido,
                        sigungu: sigungu,
                        town: town,
                        contract_type: contract_type,
                        house_type: house_type,
                        house_area: house_area,
                        rent_type: rent_type,
                        rent_amt: !rent_amt ? "0" : String(rent_amt).replace(/,/g, ""),
                        monthly_amt: !guarantee_monthly_rent ? "0" : String(guarantee_monthly_rent).replace(/,/g, ""),
                        support_amt: !support_amt ? "0" : String(support_amt).replace(/,/g, ""),
                        family_cnt: family_cnt,
                        estate_no: estate_no,
                        broker_company: broker_company,
                        broker_phone: broker_telno,
                        room_count: !room_count ? "" : String(room_count).replace(/,/g, ""),
                        senior_rent_yn: senior_rent_yn,
                        req_cnt: !req_cnt ? "0" : req_cnt,
                        end_date: end_date,
                        wallpaper_flag: wallpaper_flag,
                        req_duplicate: req_duplicate,
                        house_owner: house_owner,
                        ground_area: ground_area,
                        share_area: share_area,
                        small_room_support: !small_room_support ? "0" : small_room_support,
                        securing_bonds: securing_bonds,
        
                        house_amt: !house_amt ? "0" : String(house_amt).replace(/,/g, ""),
                        decide_how: decide_how,
                        decide_date: decide_date,
                        decide_amt: !decide_amt ? "0" : String(decide_amt).replace(/,/g, ""),
                        possible_bond_amt: !possible_bond_amt ? "0" : String(possible_bond_amt).replace(/,/g, ""),
                        first_bond_amt: !first_bond_amt ? "0" : String(first_bond_amt).replace(/,/g, ""),
                        mortgage_amt: !mortgage_amt ? "0" : String(mortgage_amt).replace(/,/g, ""),
                        rent_set_amt: !rent_set_amt ? "0" : String(rent_set_amt).replace(/,/g, ""),
                        seizure_amt: !seizure_amt ? "0" : String(seizure_amt).replace(/,/g, ""),
                        already_rent_amt: !already_rent_amt ? "0" : String(already_rent_amt).replace(/,/g, ""),
                        securing_bond_amt: !securing_bond_amt ? "0" : String(securing_bond_amt).replace(/,/g, ""),
                        difference_amt: !difference_amt ? "0" : String(difference_amt).replace(/,/g, ""),
                        debt_ratio: !debt_ratio ? "0" : String(debt_ratio).replace(/,/g, ""),
                        suitable_flag: suitable_flag,
                        pre_deposit_total: !pre_deposit_total ? "0" : String(pre_deposit_total).replace(/,/g, ""),
                        pre_rent_loans: !pre_rent_loans ? "0" : String(pre_rent_loans).replace(/,/g, ""),
                        pre_charge_amt: !pre_charge_amt ? "0" : String(pre_charge_amt).replace(/,/g, ""),
                        pre_interest_free: !pre_interest_free ? "0" : String(pre_interest_free).replace(/,/g, ""),
                        total_room_cnt: !total_room_cnt ? "0" : total_room_cnt,
                        rent_room_cnt: !rent_room_cnt ? "0" : rent_room_cnt,
                        empty_room_cnt: !empty_room_cnt ? "0" : empty_room_cnt,
                        remark: remark,
                        use_flag: "Y",
                        winner_info: winner.map((item) => {
                            return {
                                ...item,
                                lh_support_amt: !item.lh_support_amt ? "0" : item.lh_support_amt.replace(/,/g, ""),
                                winner_charges: !item.winner_charges ? "0" : item.winner_charges.replace(/,/g, ""),
                                add_charges: !item.add_charges ? "0" : item.add_charges.replace(/,/g, ""),
                                monthly_rent: !item.monthly_rent ? "0" : item.monthly_rent.replace(/,/g, ""),
                            }
                        }),
                        senior_sido: senior_sido,
                        minor_child_cnt: minor_child_cnt,
                    })
                )
            }
            dispatch(saveApproval({
                doc_id: docNo,
                app_auth: type,
                app_status: "Y",
                app_opinion: "",
            }))
            setIsApprove(true);
        }
    }
    //  반려 팝업
    const onRejectClick = () => {
        setIsShowDocFail('REJECT');
    }
    //  결재확인 반려
    const onAppCheckRejectClick = () => {
        setIsShowDocFail('APPREJECT');
    }
    //  (결재확인)반려 완료 스테이터스 변경
    const onRejectComplete = (lack_reason) => {
        dispatch(setStatus('ENAPVFAIL'));

        //  반려 Data Dispatch.
        const _approval = approval.map((item) =>
            item.user_id === loginUserId ? {
                ...item,
                app_status: 'R',
                app_date: new Date((new Date()).getTime() - ((new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10).slice(-5).replace('-', '.'),
                app_opinion: lack_reason,
            } : item,
        );
        dispatch(
            changeValue({
                key: 'approval',
                value: _approval,
            })
        );
        dispatch(
            changeValue({
                key: 'reject_reason',
                value: lack_reason,
            })
        )
    }
    //  반려확인
    const onRejectCheckClick = () => {
        setIsShowDocFail('REJECT_CHECK');
    }
    //  반려확인 완료 스테이터스 변경
    const onRejectCheckComplete = () => {
        dispatch(setStatus('ENACK'));

        let _approval = approval.find(x => x.app_status === 'R');
        _approval.app_date = '';
        _approval.user_id = '';
        _approval.app_name = '';
        _approval.app_phone = '';
        _approval.app_status = 'N';

        dispatch(
            changeValue({
                key: 'approval',
                value: [_approval],
            })
        );
    }
    //  권리분석 취소 Modal Show/Hide
    const onCancelClick = () => {
        dispatch(changeValue({ key: 'user_pwd', value: '', }))
        dispatch(changeValue({ key: 'pwdCheckResult', value: '', }))
        setIsShowPassword(!isShowPassword);
    }
    //  권리분석 취소 접수자 패스워드 확인
    const onCancelPwChk = () => {
        if(!user_pwd) {
            ALERT("비밀번호를 입력해주세요.");
            return;
        }

        dispatch(pwdCheck({
            user_id: user.user_id,
            user_pwd: user_pwd,
        }))
    }
    //  권리분석 취소 실행
    const onExecCancel = () => {
        if(pwdCheckResult !== 'OK') {
            ALERT("비밀번호 확인 후 다시 시도해주세요.");
            return;
        }
        if(window.confirm("정말 권리분석을 취소합니까?")) {
            dispatch(
                setStatusApi({
                    doc_id: docNo,
                    status: "ENCANCEL",
                    remark: "",
                    lack_reason: "",
                })
            )
            ALERT("권리분석이 취소되었습니다.");
            navigate(-1);
        }
    }
    //  취소된 권리분석 복원
    const onRestartClick = () => {
        if(window.confirm("취소된 해당 권리분석을 복원합니까?")) {
            dispatch(
                setStatusApi({
                    doc_id: docNo,
                    status: "ENREQ",
                    remark: "",
                    lack_reason: "",
                })
            )
            ALERT("취소된 권리분석이 복원되었습니다.");
        }
    }
    //  신세기관리자 전용 검토서 삭제
    const onRemoveEntitle = () => {
        if(window.confirm("해당 권리분석 검토서를 삭제합니까?\n삭제 후 되돌릴 수 없습니다.")) {
            dispatch(removeEntitle(docNo))
            navigate(-1);
        }
    }

    useEffect(() => {
        return () => {
            //  언마운트시 초기화
            dispatch(initialize());
        };
    }, [dispatch])

    useEffect(() => {
        addZoom("")
    }, [dispatch, selectImg])

    useEffect(() => {
        if(!user) return;

        dispatch(
            changeValue({
                key: 'loginUserId',
                value: user.user_id,
            })
        );
    }, [dispatch, user])

    useEffect(() => {
        //  문서의 상태가 권리분석처리, 결재요청 중 일때만 검토서 항목을 수정할 수 있음.
        setIsCanChange((doc_status === 'ENPROCESS' || doc_status === 'ENAPVREQ') ? false : true);

        if(doc_status === 'ENAPVCHK' && isApprove) {
            let _receiver = '';
            for(let i = 0; i < winner.length; i++) {
                _receiver += winner[i].winner_phone + ",";
            }
            dispatch(sendSms({
                sender: "1600-4690",
                receiver: _receiver,
                msg: `"LH전세임대 신청하신 주택의 권리분석 완료."
신청물량 폭증으로 일정조율관련 연락이 다소 지연될 수 있으니 양해부탁드립니다.
https://cafe.naver.com/hongbj13/94`,
                msg_type: "SMS",
            }));
            setIsApprove(false);
        }
    }, [dispatch, doc_status, isApprove, winner])
    
    useEffect(() => {
        if(dispatch) {
            dispatch(getCombo("APPLY_TYPE"))
            dispatch(getContent(docNo));
        }
    }, [dispatch, docNo])

    useEffect(() => {
        if(dataEmptyRoomTable) {
            let _cboSido = [];
            dataEmptyRoomTable.forEach(item => {
                let _name = '';
                if(item.areaStr.length === 0) {
                    _name = "이 외 지역";
                }
                else {
                    if((item.areaMain === "서울") || (item.areaMain === "서울(2023)")) {
                        _name = "전체";
                    }
                    else {
                        _name = item.areaStr.join();
                    }
                }

                _cboSido.push({
                    code: item.areaMain + ' ' + (item.areaStr.length > 0 ? item.areaStr[0] : "이 외 지역"),
                    name: item.areaMain + ' ' + _name,
                });
            });
            setCboRentSido([...new Set(_cboSido)]);
        }
    }, [dispatch])

    //  선택된 지원유형에서 선택가능한 입주자부담금 비율
    useEffect(() => {
        if(!winner || winner.length === 0 || !winner[0].winner_type) {
            return;
        }
        dataEntitleCalc.forEach(applyType => {
            if(String(applyType.codeIdx) === String(winner[0].type_calc_code)) {
                setPossibleRate(applyType.defaultPay.rate);
                setPossibleAmt(applyType.defaultPay.static);
            }
        });
    },[winner])

    const onWinnerChange = (item, key, e) => {
        const _cboApply = cboApply.find(x => x.sub_code === e.target.value);
        if(winner.length > 1) {
            if(_cboApply.memo !== '8' && _cboApply.memo !== '9') {
                ALERT("지원유형이 [청년]인 경우만 셰어가 가능합니다.");
                return;
            }
        }

        if(key === 'winner_type') {
            dispatch(
                s1_rowChangeValue({
                    idx: item.idx,
                    key: 'type_calc_code',
                    value: _cboApply.memo,
                })
            );
            dispatch(
                s1_rowChangeValue({
                    idx: item.idx,
                    key: 'charge_rate',
                    value: null,
                })
            );
        }

        dispatch(
            s1_rowChangeValue({
                idx: item.idx,
                key: key,
                value: e.target.value,
            })
        );
    }
    const onWinnerAmtChange = (item, e) => {
        const re = /^[0-9\b]+$/;
        const value = parseNumber(e.target.value);
        
        if (value === '' || re.test(value)) {
            dispatch(
                s1_rowChangeValue({
                    idx: item.idx,
                    key: e.target.name,
                    value: value.replace(regAmt, ","),
                })
            );
        }
    }
    const onCalcSupportAmt = () => {
        if(!rent_amt || rent_amt === "0") {
            ALERT("전세금액을 입력해주세요.");
            return;
        }
        if(rent_type === "TYPE02") {  //보증부월세
            if(!guarantee_monthly_rent || guarantee_monthly_rent === "0") {
                ALERT("전세유형이 보증부월세인 경우 월세금액은 필수입력입니다.");
                return;
            }
        }

        let _codeIdx = +winner[0].type_calc_code;
        if(house_area && +house_area >= 85) {
            // _codeIdx = 4;   // 85m 이상이라면 계산유형을 변경.
        }
        const applyType = dataEntitleCalc.find(x => String(x.codeIdx) === String(_codeIdx));
        if(applyType.defaultPay.rate.length > 0) {
            if(!winner[0].charge_rate || winner[0].charge_rate === "0") {
                ALERT("입주자부담금 비율을 선택해주세요.");
                return;
            }
        }

        let _persons = [], totalExtraPay = 0;
        winner.forEach(item => {
            totalExtraPay += +(item.add_charges.replace(/,/g, ""));

            _persons.push({
                codeIdx: +(_codeIdx === 4 ? _codeIdx : item.type_calc_code),    // 85m 이상이라면 무조건 4번 유형
                defaultPayRate: (!item.charge_rate || item.charge_rate === '0' ? 0 : +item.charge_rate),
                extraPay: (!item.add_charges || item.add_charges === "0") ? 0 : item.add_charges.replace(/,/g, ""),
                winner_idx: item.idx,
            })
        });

        const retCalcAmt = funEntitleCalc({
            total: rent_amt.replace(/,/g, ""),                  // 전세금액총액(전세보증금)
            totlaExtraPay: totalExtraPay,                       // 추가부담금총액
            guaranteeMonthPay: guarantee_monthly_rent.replace(/,/g, ""),  // 보증부월세액
            persons: _persons,                                  // 개인별 정보
            childCount: minor_child_cnt,                        // 자식 수
        });

        if(!retCalcAmt) {
            ALERT("죄송합니다. 계산 중 오류가 발생하였습니다.");
            return;
        }
        
        if (retCalcAmt.errorSupportMoney) {
            ALERT(retCalcAmt.errorMessage);
            // setIsCalcResult("IMPOSSIBLE");
            // setCalcComment(retCalcAmt.errorMessage);
            return;
        }
        else {
            // setIsCalcResult("POSSIBLE");
            // setCalcComment("");
            // setIsCalcOK(true); // 지원금 계산 여부 변경

            let total_lh_support = 0;   //  LH 지원금 합계
            let isOverCharge = false;   //  부담금이 전세금을 초과하는지

            for(let i = 0; i < retCalcAmt.persons.length; i++) {
                const person = retCalcAmt.persons[i];

                if(
                    (!person.monthPay || +person.monthPay <= 0) ||
                    (!person.supportMoney || +person.supportMoney <= 0)
                ) {
                    isOverCharge = true;
                }

                //  추가부담금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'add_charges',
                        value: String(person.extraPay).replace(regAmt, ","),
                    })
                );

                //  입주자부담금
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'winner_charges',
                        value: String(person.defaultPay).replace(regAmt, ","),
                    })
                );

                //  월임대료
                dispatch(
                    s1_rowChangeValue({
                        idx: person.winner_idx,
                        key: 'monthly_rent',
                        value: String(person.monthPay).replace(regAmt, ","),
                    })
                );

                //  LH지원금 합계 계산
                total_lh_support += person.supportMoney;
            };

            //  LH지원금
            dispatch(
                changeValue({
                    key: 'support_amt',
                    value: String(total_lh_support).replace(regAmt, ","),
                })
            );

            if(isOverCharge) {
                ALERT("입주자 추가부담금과 입주자 부담금의 합계가 전세금보다 큽니다.\n금액을 확인해주세요.");
            }
        }
    }

    //  주택가격 & 2.채권확보가능금액 계산 :: 변경기준 = [공시가격]
    useEffect(() => {
        let v_decide_amt = +(decide_amt.replace(/,/g, ""));

        //  주택가격선정 타입별 가격산정비율
        let housePrice = cboPricing.find(x => x.sub_code === decide_how);
        housePrice = housePrice ? housePrice.price_rate : 0;

        const calcPrice = (!v_decide_amt ? 0 : v_decide_amt) * housePrice;

        let amt = decimalAdjust('round', calcPrice, 0);
        amt = String(amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'house_amt',
                value: amt,
            })
        );

        let bond_amt = decimalAdjust('floor', (!v_decide_amt ? 0 : v_decide_amt) * housePrice * 0.9, 0);
        bond_amt = String(bond_amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'possible_bond_amt',
                value: bond_amt,
            })
        );
    }, [dispatch, decide_amt, cboPricing, decide_how])

    //  3.선순위채권금액 계산 :: 변경기준 = [근저당설정금액, 전세권설정금액, (가)압류금액, 기존임차인금액]
    useEffect(() => {
        let v_mortgage_amt = +(mortgage_amt.replace(/,/g, ""));
        let v_rent_set_amt = +(rent_set_amt.replace(/,/g, ""));
        let v_seizure_amt = +(seizure_amt.replace(/,/g, ""));
        let v_already_rent_amt = +(already_rent_amt.replace(/,/g, ""));

        let amt = decimalAdjust('floor', v_mortgage_amt + v_rent_set_amt + v_seizure_amt + v_already_rent_amt, 0);
        amt = String(amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'first_bond_amt',
                value: amt,
            })
        );
    }, [dispatch, mortgage_amt, rent_set_amt, seizure_amt, already_rent_amt])

    // //  전세금총액 계산 :: 변경기준 = [LH지원금, 입주자부담금]   22.04.04 온결 요청으로 계산식은 일단 적용하지 않음.
    // useEffect(() => {
    //     let v_pre_rent_loans = +(pre_rent_loans.replace(/,/g, ""));
    //     let v_pre_charge_amt = +(pre_charge_amt.replace(/,/g, ""));
        
    //     let amt = decimalAdjust('floor', v_pre_rent_loans + v_pre_charge_amt, 1);
    //     amt = String(amt).replace(regAmt, ",");
    //     dispatch(
    //         changeValue({
    //             key: 'pre_deposit_total',
    //             value: amt,
    //         })
    //     );
    // }, [dispatch, pre_rent_loans, pre_charge_amt])

    //  3-4) 기존임차인금액 계산 :: 변경기준 = [전체 호실 총 보증금, 공실 합계]
    useEffect(() => {
        let v_rent_tot_amt = +(rent_tot_amt.replace(/,/g, ""));
        let v_empty_tot_amt = +(empty_tot_amt.replace(/,/g, ""));
        
        let amt = decimalAdjust('floor', v_rent_tot_amt + v_empty_tot_amt, 0);
        amt = String(amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'already_rent_amt',
                value: amt,
            })
        )
    }, [dispatch, rent_tot_amt, empty_tot_amt])

    //  4.채권확보금액 계산 :: 변경기준 = [채권확보가능금액, 선순위채권금액]
    useEffect(() => {
        let v_possible_bond_amt = +(possible_bond_amt.replace(/,/g, ""));
        let v_first_bond_amt = +(first_bond_amt.replace(/,/g, ""));

        let amt = decimalAdjust('floor', v_possible_bond_amt - v_first_bond_amt, 0);
        amt = String(amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'securing_bond_amt',
                value: amt,
            })
        );
    }, [dispatch, possible_bond_amt, first_bond_amt])

    //  5.차액 계산 :: 변경기준 = [채권확보금액, 전세보증금]
    useEffect(() => {
        let v_securing_bond_amt = +(securing_bond_amt.replace(/,/g, ""));
        let v_rent_amt = +(rent_amt.replace(/,/g, ""));

        let amt = decimalAdjust('floor', v_securing_bond_amt - v_rent_amt, 0);
        amt = String(amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'difference_amt',
                value: amt,
            })
        );
    }, [dispatch, securing_bond_amt, rent_amt])

    //  부채비율 계산 :: 변경기준 = [주택가격, 선순위채권금액, 전세보증금]
    useEffect(() => {
        let v_first_bond_amt = +(first_bond_amt.replace(/,/g, ""));
        let v_rent_amt = +(rent_amt.replace(/,/g, ""));
        let v_house_amt = +(house_amt.replace(/,/g, ""));
        
        let amt = decimalAdjust('floor', ((v_first_bond_amt + v_rent_amt) / v_house_amt) * 100, -3);
        amt = String(amt).replace(regAmt, ",");
        dispatch(
            changeValue({
                key: 'debt_ratio',
                value: isNaN(amt) ? "" : amt,
            })
        );
    }, [dispatch, first_bond_amt, rent_amt, house_amt])

    //  적합여부 계산 :: 변경기준 [부채비율]
    useEffect(() => {
        let v_debt_ratio = +(debt_ratio.replace(/,/g, ""));

        let result;
        if (!v_debt_ratio) {
            result = "";
        }
        else if (v_debt_ratio > 90) {
            result = "부적합";
        }
        else {
            result = "적합";
        }

        dispatch(
            changeValue({
                key: 'suitable_flag',
                value: result,
            })
        );
    }, [dispatch, debt_ratio])

    useEffect(() => {
        if(addr_req === 'N') {
            setIsNeedApiRequest('black');
        }
        else if(addr_req === 'Y') {
            setIsNeedApiRequest('gray');
        }

        if(addr_req === 'Y' && (building_state_no === 5 || building_state_no === 6 || building_state_no === 7)) {
            setIsNeedApiRequest('red');
        }
        else if(addr_req === 'Y' && use_yn === 'N') {
            setIsNeedApiRequest('orange');
        }
        else if(addr_req === 'Y' && use_yn === 'Y') {
            setIsNeedApiRequest('blue');
        }

        // if(addr_req === 'Y' && use_yn === 'Y') {
        //     const apiFile = fileDataAll.filter(x => x.api_yn === 'Y');
        //     if(apiFile.length === 0) {
        //         setIsNeedApiRequest('blue');
        //     }
        // }
    }, [addr_req, building_state_no, use_yn])

    useEffect(() => {
        if(fileDataAll) {
            let el = null;
            for (let index = 0; index < fileDataAll.length; index++) {
                el = fileDataAll[index];
                if(el.api_yn === "N") {
                    continue;
                }
                // api 요청한것중에서
                else if(el.api_yn === "Y") {
                    // 모두 받은 경우
                    if(el.api_req_date && el.api_rec_date) {

                        //  요청중 상태에서 모두 요청완료로 변경되었다면, Viewer를 새로고침한다.
                        if(isNeedApiRequest === 'yellow') {
                            setIsChangeApi(true);
                        }
                        setIsNeedApiRequest('green');
                    }
                    // 요청한것중 하나라도 수신받지 못한경우
                    else if(el.api_req_date && !el.api_rec_date) {
                        setIsNeedApiRequest('yellow');
                        break;
                    }
                }
            }
        }
    }, [fileDataAll, isNeedApiRequest])

    useEffect(() => {
        if(isSaveOK) {
            dispatch(getContent(docNo));
        }
    }, [dispatch, isSaveOK, docNo])

    const propDatas = {
        user,
        loading,
        docNo,
        doc_status,
        isCanChange,
        app_user,
        appcheck_user,
        isFeedback,
        feedback_answer,
        call_memo,
        call_check,
        entitle_user,
        // entitle_user_name,
        // appr_complete_date,

        approval,
        reject_reason,

        winner,
        possibleRate,
        possibleAmt,

        winner_type,
        winner_type_name,
        contract_type,
        contract_type_name,
        rent_type,
        // rent_type_name,
        req_cnt,
        end_date,
        wallpaper_flag,
        minor_child_cnt,

        addr_duplicate,
        house_type,
        house_owner,
        req_duplicate,
        road_addr,
        road_addr_dtl,
        address_memo,
        jibun,
        house_area,
        rent_amt,
        support_amt,
        ground_area,
        share_area,
        winner_charges,
        winner_add_charges,
        small_room_support,
        monthly_rent,
        guarantee_monthly_rent,
        securing_bonds,
        broker_company,
        broker_telno,
        family_cnt,
        estate_no,
        room_count,
        senior_rent_yn,
        
        house_amt,
        decide_how,
        decide_date,
        decide_amt,
        possible_bond_amt,
        first_bond_amt,
        mortgage_amt,
        pre_deposit_total,
        pre_rent_loans,
        pre_charge_amt,
        pre_interest_free,
        rent_set_amt,
        seizure_amt,
        total_room_cnt,
        rent_room_cnt,
        already_rent_amt,
        empty_room_cnt,
        securing_bond_amt,
        remark,
        difference_amt,
        debt_ratio,
        suitable_flag,

        //  검토서 변경요청 데이터
        work_change_date,
        work_change_req,
        work_change_user,
        isShowEnModify,
        onEnModifyReasonClick,

        //  서류폴더, 서류이미지
        refType,
        selectFoler,
        docFolder,
        selectImg,
        docImages,
        selectTxtFolder,
        txtFolder,
        onWaiting,
        onImgRotation,
        onImgRotateSave,
        onRefreshImg,

        //  선순위 합계
        rent,
        cboRentSido,
        senior_sido,
        rent_tot_amt,
        empty_tot_amt,
        
        cboApply,
        cboContract,
        cboRent,
        cboHousing,
        cboUseYN,
        cboPricing,
        cboEmptyType,

        //  폴더 슬라이드 위치
        imgFolderDiv,
        imgFolderSlider,
        txtFolderDiv,
        txtFolderSlider,

        // 검토파일 자동받기 버튼 색상
        isNeedApiRequest,
        setIsNeedApiRequest,

        //  API Viewer
        isChangeApi,
        setIsChangeApi,

        //  모달
        isShowDocUpload,
        isShowDocFail,
        // isShowApprove,
        isShowMemo,
        isShowPriority,
        isShowPassword,
        isShowFeedback,
        isShowWinner,
        setIsShowDocFail,
        setIsShowMemu,
        setIsShowPriority,
        setIsShowFeedback,
        setIsShowWinner,
        onDocFailComplete,
        // onReqApproveComplete,
        onRejectComplete,
        onRejectCheckComplete,
        onMemoComplete,
        onPriorityComplete,
        onNonConfComplete,
        onUserChangeComplete,
        onFeedbackComplete,
        onRefreshList,

        use_address,

        //  권리분석 취소 패스워드 확인 관련
        user_pwd,
        pwdCheckResult,
        
        work,
        error,
        func,
        
        onChange,
        onChangeNum,

        onRefChange,
        onSelectFolder,
        onSelectTxtFolder,
        onSelectImg,
        onPrevClick,
        onNextClick,
        onUploadComplete,

        onWinnerChange,
        onWinnerAmtChange,
        onCalcSupportAmt,

        onMemoClick,
        onRegistClick,
        onDocUploadClick,
        // onDocAutoReceiveClick,
        onDocSortClick,
        onDocFailClick,
        onUserChangeClick,
        onDocCheckClick,
        onFeedBackClick,
        onFeedBackCancel,
        onPriorityClick,
        onSaveClick,
        onReqApproveClick,
        onNonConfClick,
        onNonConfRecoverClick,
        onApproveClick,
        onRejectClick,
        onRejectCheckClick,
        onAppCheckRejectClick,
        onCancelClick,
        onCancelPwChk,
        onRemoveEntitle,
        onExecCancel,
        onRestartClick,
    }

    return (
        <>
            <EntitleWork {...propDatas}/>
        </>
    );
}

export default EntitleWorkContainer;