import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as adminAPI from '../lib/api/admin';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
// import { takeLatest, call } from 'redux-saga/effects';
// import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const INITIALIZE = 'main/INITIALIZE';
const CHANGE_VALUE = 'main/CHANGE_VALUE';
const [GET_STATUS, GET_STATUS_SUCCESS, GET_STATUS_FAILURE] = createRequestActionTypes('main/GET_STATUS');
// const [REACTION, REACTION_SUCCESS, REACTION_FAILURE] = createRequestActionTypes('main/REACTION');

export const initialize = createAction(INITIALIZE);
export const getStatus = createAction(GET_STATUS);
export const changeValue = createAction(CHANGE_VALUE, ({key, value}) => ({key, value}));
// export const action = createAction(ACTION, data => data);

const getStatusSaga = createRequestSaga(GET_STATUS, adminAPI.code_search);
// function reactionSaga() {
//     try {
//         yield call(actionAPI.reaction);
//     } catch (e) {
//         console.error(e);
//     }
// }

export function* mainSaga() {
    yield takeLatest(GET_STATUS, getStatusSaga);
    // yield takeLatest(REACTION, reactionSaga);
}

const initialState = {
    main: [],
    status: [
        {
            code: ["ENWRITE"],
            name: "작성중", 
        },
        {
            code: ["ENREQ"],
            name: "접수대기", 
        },
        {
            code: ["ENDOCFAIL"],
            name: "서류 미비", 
        },
        {
            code: ["ENDOCADD"],
            name: "서류 보완", 
        },
        {
            code: ["ENACK", "ENCHGREQ","ENPROCESS","ENAPVREQ", "ENAPVFAIL"],
            name: "검토중", 
        },
        {
            code: ["ENAPVOK"],
            name: "검토완료", 
        },
        {
            code: ["CONTSTRT"],
            name: "계약진행", 
        },
        {
            code: ["CONTFAIL"],
            name: "계약취소", 
        },
        {
            code: ["CONTOK"],
            name: "계약완료", 
        },
    ],

    // main: [
    //     {
    //         title: "권리분석 신청서 작성중",
    //         count: 2,
    //         to: "/list/entitleWrite"
    //     },
    //     {
    //         title: "권리분석 신청 이력",
    //         count: 1,
    //         to: "/list/entitleHistory"
    //     },
    //     {
    //         title: "요청중인 권리분석",
    //         count: 3,
    //         to: "/list/entitleRequest"
    //     }
    // ],
    error: null
};

const main = handleActions(
    {
        [INITIALIZE]: () => initialState,
        [CHANGE_VALUE]: (state, { payload: {key, value}}) => ({
            ...state,
            [key]: value,
        }),
        [GET_STATUS_SUCCESS]: (state, {payload}) => {
            return ({
                ...state,
                // status: payload,
            })
        },
        [GET_STATUS_FAILURE]: (state, {payload}) => {
            return ({
                ...state
            })
        }
    },
    initialState,
);

export default main;