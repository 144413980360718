import client from './client';

export const appuser_reg = (body) =>
    client.post('/api/approval/appuser_reg', body);
    
export const app_request = (body) =>
    client.post('/api/approval/app_request', body);
    
export const approve = (body) =>
    client.post('/api/approval/approve', body);

export const appuser_list = () =>
    client.get('/api/approval/appuser_list');