import React from 'react';
import styled from 'styled-components';
import ExcellentExport from 'excellentexport';

const ChargeFeeBlock = styled.div`
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
    position: relative;

    h1{
        color: #545454;
        font-size: 24px;
        margin: 20px 0;
    }
    h2{
        color: #545454;
        font-size: 20px;
    }

    .search-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .tt {
        margin-top: 10px;
    }

    .btn-area {
        display: flex;
        gap: 10px;
    }
`;

const ChargeFee = (props) => {
    const {
        from_date,
        to_date,
        expense_flag,

        summaryToggle,

        summary,
        list,

        sumTotCnt,
        sumTotEnAmt,
        sumTotContAmt,
        sumTotTrip,
        sumTotSum,
        sumTotVat,
        sumTotTot,
        listTotWinnerType,
        listTotEnAmt,
        listTotContAmt,
        listTotTrip,
        listTotSum,
        listTotVat,
        listTotTot,
        
        // //  페이징
        // pageCount,
        // max,
        // step,
        // currentPage,

        onSummaryToggle,
        onChange,
        onSubmit,
        onApplyClick,
        
        // onClickNum,
        // onClickNext,
        // onClickPrev,
    } = props

    const handleClickXLSX = (e) => {
        return ExcellentExport.convert(
            { anchor: this, filename: "수수료청구", format: "xlsx", openAsDownload: true },
            [
                { name: "총합계", from: { table: "tblSummary" } },
                { name: "상세", from: { table: "tblList" } },
            ]
        );
      }
    
    return (
        <ChargeFeeBlock>
            <h1>수수료 청구</h1>

            <div className='search-title'>
                <h2>검색조건</h2>
            </div>
            <div className='search'>
                <form onSubmit={onSubmit}>
                    <div className='searchBody'>
                        <div className='ipt'>
                            {/* <h4 className='bg'>기간</h4> */}
                            {/*[Dev] 22.02.15 h4 className bg 추가*/}
                            <div className='st'>
                                <label htmlFor='expense_flag' className='bg' style={{width:"120px"}}>비용청구여부</label>
                                <select name="expense_flag" id="expense_flag" value={expense_flag} onChange={onChange} style={{width:"100px"}}>
                                    <option value="N">아니오</option>
                                    <option value="Y">예</option>
                                </select>
                            </div>
                            
                            {/*[Dev] 22.02.22 st클래스안에 div, class=bg 추가*/}
                            <div className='st'>
                                <div>
                                    <label htmlFor='from_date' className='bg'>시작일</label>
                                    <input type="date" name="from_date" id="from_date" value={from_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                                <div>
                                    <label htmlFor='to_date' className='bg'>종료일</label>
                                    <input type="date" name="to_date" id="to_date" value={to_date} onChange={onChange} max="9999-12-31"/>
                                </div>
                            </div>

                            {/* [DEV] 22.02.15 검색 버튼 이동 */}
                            <div className='btn btn-area'>
                                <a className='save-btn' href="#!" onClick={handleClickXLSX} style={{textDecoration:"none"}}>Excel</a>
                                <button className='save-btn' onClick={onApplyClick}>반영</button>
                                <button className='green-sch-btn'>검색</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className='search-title'>
                <h2>총합계</h2>
                <div className='request-btn' onClick={onSummaryToggle} style={{width:"150px"}}>Hide / Show</div>
            </div>
            {
                summaryToggle &&
                <table id="tblSummary" className='tt'>
                    <thead>
                        <tr>
                            <th rowSpan={2}>총합계</th>
                            <th>건수</th>
                            <th>권리분석</th>
                            <th>계약수수료</th>
                            <th>교통비</th>
                            <th>합계</th>
                            <th>부가세</th>
                            <th>총합계</th>
                        </tr>
                        <tr>
                            <th>{sumTotCnt}</th>
                            <th>{sumTotEnAmt}</th>
                            <th>{sumTotContAmt}</th>
                            <th>{sumTotTrip}</th>
                            <th>{sumTotSum}</th>
                            <th>{sumTotVat}</th>
                            <th>{sumTotTot}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summary.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{item.winner_type_name}</td>
                                    <td>{item.exp_count}</td>
                                    <td>{item.entitle_amt}</td>
                                    <td>{item.contract_amt}</td>
                                    <td>{item.trip_expense}</td>
                                    <td>{item.sum_amt}</td>
                                    <td>{item.vat_amt}</td>
                                    <td>{item.tot_amt}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }

            <h2>검색결과</h2>
            <table id="tblList" className='tt'>
                <thead>
                    <tr>
                        {/* Excel Down시 Colspan 미적용 현상 방지 */}
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{listTotWinnerType}</th>
                        <th>{listTotEnAmt}</th>
                        <th>{listTotContAmt}</th>
                        <th>{listTotTrip}</th>
                        <th>{listTotSum}</th>
                        <th>{listTotVat}</th>
                        <th>{listTotTot}</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>번호</th>
                        <th>계약일</th>
                        <th>요일</th>
                        <th>잔금일</th>
                        <th>임대인</th>
                        <th>임차인</th>
                        <th>주민번호</th>
                        <th>주소</th>
                        <th>지원유형</th>
                        <th>세부유형</th>
                        <th>권리분석</th>
                        <th>계약서작성</th>
                        <th>교통비</th>
                        <th>합계</th>
                        <th>부가세</th>
                        <th>총합계</th>
                        <th>납품일</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, idx) => {
                        return (
                            <tr key={idx}>
                                {/* <td>{(30 * (currentPage - 1)) + idx + 1}</td> */}
                                <td>{idx + 1}</td>
                                <td>{item.contract_date}</td>
                                <td>{item.day_name}</td>
                                <td>{item.balance_payment_date}</td>
                                <td>{item.lessor_name}</td>
                                <td>{item.cust_name}</td>
                                <td>{item.curt_birth}</td>
                                <td>{item.addr}</td>
                                <td>{item.contract_type_name}</td>
                                <td>{item.winner_type_name}</td>
                                <td>{item.entitle_amt}</td>
                                <td>{item.contract_amt}</td>
                                <td>{item.trip_expense}</td>
                                <td>{item.sum_amt}</td>
                                <td>{item.vat_amt}</td>
                                <td>{item.tot_amt}</td>
                                <td>{item.delivery_date}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* <div className="pageNation">
                <div className="page-prev-btn">
                    <span onClick={onClickPrev}>{"<"}</span>
                </div>
                <div className="num">
                    <div>
                        {[...Array(pageCount)].map((item,idx) => {
                            if(step * max >= idx + 1 && idx + 1 > (step - 1) * max ) {
                                return <span className={currentPage === idx + 1? 'on' : ""} key={item? idx : idx} onClick={() => onClickNum(idx + 1)}>{idx + 1}</span>
                            }
                            return null;
                        })}
                    </div>
                </div>
                
                <div className="page-next-btn">
                    <span onClick={onClickNext}>{">"}</span>
                </div>
            </div> */}
        </ChargeFeeBlock>
    );
}

export default ChargeFee;